import React from "react";
import { Icon } from "react-icons-kit";
import { check } from "react-icons-kit/feather/check";
import { x } from "react-icons-kit/feather/x";

export const ContentBlockButtons = (props: {
    isDisabled?: boolean;
    showButtons: boolean;
    handleDiscard: () => boolean;
    handleSave: () => void;
}) => {
    const { isDisabled, showButtons, handleDiscard, handleSave } = props;

    return (
        <div className="w-[176px]">
            {showButtons && (
                <div className="flex h-full flex-row items-center">
                    <div className={"mr-2"}>
                        <button
                            type="button"
                            className="flex rounded border border-green-600 bg-green-600 px-2 py-1 text-center text-xs font-medium text-white hover:border-green-500 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 dark:border-green-600 dark:bg-green-600 dark:text-white dark:hover:border-green-500 dark:hover:bg-green-500 dark:hover:text-white dark:focus:ring-green-500"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleSave();
                            }}
                            disabled={isDisabled}
                        >
                            <span style={{ paddingRight: "4px" }}>
                                <Icon
                                    icon={check}
                                    size={16}
                                    style={{ display: "flex" }}
                                />
                            </span>
                            <span>SAVE</span>
                        </button>
                    </div>
                    <div className={"mr-2"}>
                        <button
                            type="button"
                            className="flex rounded border border-red-700 px-2 py-1 text-center text-xs font-medium text-red-700 hover:bg-red-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-300 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-600 dark:hover:text-white dark:focus:ring-red-900"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDiscard();
                            }}
                            disabled={isDisabled}
                        >
                            <span style={{ paddingRight: "4px" }}>
                                <Icon
                                    icon={x}
                                    size={16}
                                    style={{ display: "flex" }}
                                />
                            </span>
                            <span>DISCARD</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
