import React from 'react'
import { Tenant } from '../../models'

type Props = {
    tenant: Tenant
}

export default function Detail({tenant}: Props) {
  return (
    <div><pre>{JSON.stringify(tenant, null, 4)}</pre></div>
  )
}
