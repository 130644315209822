import React from "react";
import { ScrollableWrapper } from "./WidgetWrappers";

const DefaultTableWrapperComponent = ({
    header,
    columnLabels,
    children,
}: {
    header: string;
    columnLabels: string[];
    children: React.ReactNode;
}) => {
    return (
        <div className="m-4 text-slate-600 transition-all dark:text-slate-400">
            <ScrollableWrapper>
                <table className="w-full table-auto text-left text-sm">
                    <thead className="">
                        <tr className="uppercase text-slate-300 transition-all dark:text-slate-700">
                            <th className="px-1 py-3">{header}</th>
                            {columnLabels?.map((label, index) => (
                                <th
                                    key={`${label}-${index}`}
                                    className="border-l border-slate-200 px-3 py-2 text-right transition-all
                                        dark:border-slate-800"
                                >
                                    {label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </table>
            </ScrollableWrapper>
        </div>
    );
};

export const DefaultTableWrapper = React.memo(DefaultTableWrapperComponent);
