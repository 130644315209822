import React, { useEffect, useState } from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormOption,
    EditorFormSelectSmall,
} from "./StyledEditorComponents";
import { ThemeObject, ResponsiveOption } from "../ThemeObjects";

export const FormReponsiveProperty = (props: {
    value: string | number | string[];
    label: string;
    themeObjectKey: keyof ThemeObject;
    responsiveOptions: ResponsiveOption[];
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
}) => {
    const { value, label, themeObjectKey, responsiveOptions, handleChange } =
        props;

    const [, setValueType] = useState<string>();

    useEffect(() => {
        setValueType(typeof value);
    }, [value]);

    // TO DO:
    // create form elements for each breakpoint
    // and form controls for applying selections to multiple breakpoints
    // (e.g., smallest and up).

    return (
        <EditorFormGroup>
            <EditorFormLabel>{label}</EditorFormLabel>
            <EditorFormSelectSmall
                value={value && typeof value == "object" ? value[0] : value}
                onChange={(e) => {
                    let valueToSave =
                        !isNaN(parseFloat(e.target.value)) &&
                        !e.target.value.toString().includes("%")
                            ? Number(e.target.value)
                            : e.target.value;
                    handleChange(themeObjectKey, valueToSave);
                }}
            >
                {responsiveOptions !== undefined &&
                    responsiveOptions.map((option) => (
                        <EditorFormOption
                            key={option.label}
                            value={option.value || "auto"}
                            className="bg-[#29303e]"
                        >
                            {option.label}
                        </EditorFormOption>
                    ))}
            </EditorFormSelectSmall>
        </EditorFormGroup>
    );
};
