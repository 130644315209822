import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import route from "ziggy-js";
import { Link } from "@inertiajs/react";

const CentralAdminWrapper: React.FC<SapienPageProps> = ({
    auth,
    children,
}: SapienPageProps) => {
    return (
        <div>
            <nav className="bg-white shadow">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img
                                    className="block lg:hidden h-8 w-auto"
                                    src="https://images.squarespace-cdn.com/content/v1/5773e82bd482e9d3fe229130/1538638228283-WUAEMOHTWZWX1PE0H3UU/LOGO+gradation+BLUE-GREY+transparent.png?format=1500w"
                                    alt="Workflow"
                                />
                                <img
                                    className="hidden lg:block h-8 w-auto"
                                    src="https://images.squarespace-cdn.com/content/v1/5773e82bd482e9d3fe229130/1538638228283-WUAEMOHTWZWX1PE0H3UU/LOGO+gradation+BLUE-GREY+transparent.png?format=1500w"
                                    alt="Workflow"
                                />
                            </div>
                            <div className="hidden sm:block sm:ml-6">
                                <div className="flex space-x-4">
                                    <Link
                                        href={route("tenant.index")}
                                        className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                    >
                                        Tenants
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                <a
                                    className="block px-4 py-2 text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex={-1}
                                    id="user-menu-item-2"
                                    href="#"
                                >
                                    {auth?.user?.name}
                                </a>
                                <Link
                                    className="block px-4 py-2 text-sm text-gray-700"
                                    role="menuitem"
                                    tabIndex={-1}
                                    id="user-menu-item-2"
                                    href={route("central.logout")}
                                >
                                    Sign out
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="flex justify-center">{children}</main>
        </div>
    );
};

export { CentralAdminWrapper };
