import {
    PromptType,
    Round,
    SubmoduleBlockType,
    QuestionSubmoduleBlockType,
    RoundSubmoduleBlockType,
    MetricSubmoduleBlockType,
    SubmoduleTreeArchetype,
} from "@/models";
import React, { useEffect, useMemo, useState } from "react";

type ArchetypeModelFormProps = {
    rounds: Round[];
};

type ButtonProps = {
    text: string;
    handler: () => void;
    color?: string;
};
type StepProps = {
    stepNumber?: number;
    title: string;
    description: string;
    leftButtonProps?: ButtonProps;
    rightButtonProps?: ButtonProps;
    children: React.ReactNode;
};
const Step: React.FC<StepProps> = ({
    stepNumber,
    title,
    description,
    leftButtonProps,
    rightButtonProps,
    children,
}) => {
    return (
        <div className="p-6">
            <h2 className="mb-4 text-xl font-semibold">
                {!stepNumber ? `${title}` : `Step ${stepNumber}: ${title}`}
            </h2>
            <div>
                <p className="mb-4">{description}</p>
                {children}
                <div className="flex justify-between">
                    <div>
                        {!!leftButtonProps && (
                            <button
                                onClick={leftButtonProps.handler}
                                className={
                                    "rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
                                }
                            >
                                {leftButtonProps.text}
                            </button>
                        )}
                    </div>
                    <div>
                        {!!rightButtonProps && (
                            <button
                                onClick={rightButtonProps.handler}
                                className={
                                    rightButtonProps.text
                                        .toLowerCase()
                                        .includes("save")
                                        ? "rounded bg-lime-300 px-4 py-2 text-black hover:bg-lime-400"
                                        : "rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
                                }
                            >
                                {rightButtonProps.text}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

enum OutputType {
    "Percentage Score" = "PERCENTAGE_SCORE",
    "Key Metric" = "KEY_METRIC",
    "Branching Pathway" = "BRANCHING_PATHWAY",
}
enum SourceType {
    "Simulation Questions" = "SIMULATION_QUESTIONS",
    "Existing Model Components" = "EXISTING_MODEL_COMPONENTS",
    // "Custom Inputs" = "CUSTOM_INPUTS",
}
type GuidedFormMetadata = {
    // setup
    outputType: keyof typeof OutputType;
    sourceType: keyof typeof SourceType;
    designStrategy: string;
    // properties
    submoduleTreeArchetype?: SubmoduleTreeArchetype;
    metricSubmoduleBlockType?: MetricSubmoduleBlockType;
    submoduleTreeLabel?: string;
    submoduleTreeDescription?: string;
    // variable/relationship
    is_financial_metric?: boolean;
    is_time_series?: boolean;
    is_iterative?: boolean;
    is_bounded_metric?: boolean;
    uses_round_effect_time_index?: boolean;
    // round
    groups_by_round?: boolean;
    selected_round_ids?: string[];
    weights_by_round?: boolean;
    roundSubmoduleBlockType?: RoundSubmoduleBlockType;
    // question
    included_question_types?: PromptType[];
    selected_prompt_ids?: string[];
    weights_by_question?: boolean;
    questionSubmoduleBlockType?: QuestionSubmoduleBlockType;
    // potential abstract approach
    inputSubmoduleBlockType?: SubmoduleBlockType;
    intermediateSubmoduleBlockType?: SubmoduleBlockType;
    outputSubmoduleBlockType?: SubmoduleBlockType;
};

const setupQuestions: {
    question: string;
    options: string[];
    metadataKey: keyof GuidedFormMetadata;
}[] = [
    {
        question: "What would you like to create?",
        options: Object.keys(OutputType),
        metadataKey: "outputType",
    },
    {
        question: "Which data source would you like to use?",
        options: Object.keys(SourceType),
        metadataKey: "sourceType",
    },
    {
        question:
            "Select one of the following strategies for generating a model design.",
        options: [
            "Use questions from multiple rounds to create round-level output and simulation-level output",
            "Use questions from multiple rounds to create simulation-level output",
            "Use questions from a single round to create round-level output",
        ],
        metadataKey: "designStrategy",
    },
];

export const ArchetypeModelForm: React.FC<ArchetypeModelFormProps> = ({
    rounds,
}) => {
    const [guidedFormMetadata, setGuidedFormMetaData] =
        useState<GuidedFormMetadata>({
            outputType: "Percentage Score",
            sourceType: "Simulation Questions",
            designStrategy: "",
            selected_round_ids: [],
            selected_prompt_ids: [],
        });
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedRoundIds, setSelectedRoundIds] = useState<string[]>([]);
    const [selectedPromptIds, setSelectedPromptIds] = useState<string[]>([]);

    const handleNextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setter: React.Dispatch<React.SetStateAction<string[]>>,
    ) => {
        const { value, checked } = e.target;
        setter((prev) => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter((item) => item !== value);
            }
        });
    };

    const roundsWithFilteredPrompts = useMemo(() => {
        return rounds
            .map(
                (round, i) =>
                    ({
                        ...round,
                        prompts: round.prompts.filter(
                            (prompt) =>
                                prompt.prompt_type !==
                                    PromptType["Short Text"] &&
                                prompt.prompt_type !== PromptType["Long Text"],
                        ),
                    }) as Round,
            )
            .filter((round) => round.prompts.length > 0);
    }, [rounds]);

    const handleAnswer = (answer: string, key: keyof GuidedFormMetadata) => {
        setGuidedFormMetaData({
            ...guidedFormMetadata,
            [key]: answer,
        });
    };
    const canAdvance = useMemo(() => {
        return setupQuestions.every(
            ({ metadataKey }) => guidedFormMetadata[metadataKey] !== "",
        );
    }, [guidedFormMetadata]);
    const isSingleRound = useMemo(() => {
        return (
            guidedFormMetadata.designStrategy ===
            setupQuestions.find(
                (question) => question.metadataKey === "designStrategy",
            ).options[2]
        );
    }, [guidedFormMetadata]);

    useEffect(() => {
        // to do: remove any prompt ids if round is deselected
        // also need to block change from multiple to single round strategy if multiple rounds are selected
        if (selectedRoundIds.some((roundId) => false)) {
            setSelectedPromptIds([...selectedPromptIds]);
        }
    }, [selectedRoundIds]);

    useEffect(() => {
        setGuidedFormMetaData({
            ...guidedFormMetadata,
            selected_prompt_ids: selectedPromptIds,
            selected_round_ids: selectedRoundIds,
        });
    }, [selectedPromptIds, selectedRoundIds]);

    return (
        <div className="mx-auto max-w-4xl rounded-lg border border-gray-200 bg-white p-6">
            {currentStep === 1 && (
                <Step
                    stepNumber={1}
                    title="Initial Setup"
                    description=""
                    rightButtonProps={
                        canAdvance && {
                            text: "Next",
                            handler: handleNextStep,
                        }
                    }
                >
                    <div className="mb-4">
                        <div className="space-y-5">
                            {setupQuestions.map(
                                ({ question, options, metadataKey }) => (
                                    <div key={metadataKey} className="">
                                        <h2>{question}</h2>
                                        {options.map((option) => (
                                            <button
                                                key={option}
                                                onClick={() =>
                                                    handleAnswer(
                                                        option,
                                                        metadataKey,
                                                    )
                                                }
                                                className={`m-2 rounded border px-4 py-2 disabled:cursor-default disabled:opacity-50 ${
                                                    guidedFormMetadata &&
                                                    guidedFormMetadata[
                                                        metadataKey
                                                    ] === option
                                                        ? "border-indigo-900 bg-indigo-900 text-white"
                                                        : "border-neutral-400 text-neutral-600 hover:bg-neutral-100 disabled:hover:bg-white"
                                                } ${metadataKey == "designStrategy" ? "w-full text-left" : ""}`}
                                                disabled={
                                                    option ==
                                                        "Existing Model Components" ||
                                                    option ==
                                                        "Branching Pathway"
                                                }
                                            >
                                                {option}
                                            </button>
                                        ))}
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                </Step>
            )}
            {currentStep === 2 && (
                <Step
                    stepNumber={2}
                    title={isSingleRound ? "Select Round" : "Select Rounds"}
                    description=""
                    leftButtonProps={{
                        text: "Back",
                        handler: handlePreviousStep,
                    }}
                    rightButtonProps={
                        guidedFormMetadata?.selected_round_ids?.length > 0 && {
                            text: "Next",
                            handler: handleNextStep,
                        }
                    }
                >
                    <div className="mb-4">
                        {roundsWithFilteredPrompts?.map((round) => (
                            <label key={round.id} className="mb-2 block">
                                <input
                                    type={isSingleRound ? "radio" : "checkbox"}
                                    value={round.id}
                                    checked={selectedRoundIds?.includes(
                                        round.id,
                                    )}
                                    onChange={(e) =>
                                        isSingleRound
                                            ? setSelectedRoundIds([round.id])
                                            : handleInputChange(
                                                  e,
                                                  setSelectedRoundIds,
                                              )
                                    }
                                    className="mr-2"
                                />
                                {round.title}
                            </label>
                        ))}
                    </div>
                </Step>
            )}
            {currentStep === 3 && (
                <Step
                    stepNumber={3}
                    title="Select Questions"
                    description=""
                    leftButtonProps={{
                        text: "Back",
                        handler: handlePreviousStep,
                    }}
                    rightButtonProps={
                        guidedFormMetadata?.selected_prompt_ids?.length > 0 && {
                            text: "Next",
                            handler: handleNextStep,
                        }
                    }
                >
                    {roundsWithFilteredPrompts
                        ?.filter((round) =>
                            guidedFormMetadata?.selected_round_ids?.includes(
                                round.id,
                            ),
                        )
                        .map((round) => (
                            <div key={round.id} className="mb-4">
                                <h3 className="text-lg font-semibold">
                                    {round.title}
                                </h3>
                                {round.prompts?.map((prompt) => (
                                    <label
                                        key={prompt.id}
                                        className="mb-2 block"
                                    >
                                        <input
                                            type="checkbox"
                                            value={prompt.id}
                                            checked={selectedPromptIds?.includes(
                                                prompt.id,
                                            )}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    setSelectedPromptIds,
                                                )
                                            }
                                            className="mr-2"
                                        />
                                        {prompt.content}
                                    </label>
                                ))}
                            </div>
                        ))}
                </Step>
            )}
            {currentStep === 4 && (
                <Step
                    stepNumber={4}
                    title="Final Review"
                    description=""
                    leftButtonProps={{
                        text: "Back",
                        handler: handlePreviousStep,
                    }}
                    rightButtonProps={{
                        text: "Save Block",
                        handler: () => alert("Block creation complete!"),
                    }}
                >
                    <div className="mb-4">
                        {Object.keys(guidedFormMetadata).map((key) => (
                            <div key={key}>
                                {`${key}: `}
                                {guidedFormMetadata[key]}
                            </div>
                        ))}
                        {/* {JSON.stringify(guidedFormMetadata, null, 4)} */}
                    </div>
                </Step>
            )}
        </div>
    );
};
