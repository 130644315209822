import React, { useCallback, useEffect, useState } from "react";
import { Undo } from "lucide-react";
import { deleteContentBlock } from "../content-block-management/useDeleteContentBlock";
import { ContentBlockShape } from "@/models";

export default function DeleteContentBlockOverlay({
    contentBlock,
    cancelDelete,
}: {
    contentBlock: ContentBlockShape;
    cancelDelete: () => void;
}) {
    const [timeRemaining, setTimeRemaining] = useState(5);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;

        interval = setInterval(() => {
            setTimeRemaining((prev) => prev - 1);
        }, 1000);

        if (timeRemaining < 0) {
            deleteContentBlock(contentBlock.id);
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timeRemaining]);

    const handleCancel = useCallback(() => {
        cancelDelete();
    }, [contentBlock, deleteContentBlock, setTimeRemaining]);

    return (
        <div
            className="pointer-events-auto absolute inset-0 z-[99999] flex flex-col items-center
                justify-center bg-white/75"
        >
            <div
                className="flex w-96 flex-col items-center justify-center gap-6 rounded-md bg-sapien-blue
                    px-2 py-4"
            >
                <div className="flex items-center space-x-3 text-white">
                    <p>Deleting content block</p>
                    <button
                        className="flex justify-center space-x-2 rounded-md border bg-slate-200 px-4 py-1
                            text-black hover:bg-slate-300"
                        onClick={handleCancel}
                    >
                        <span>Cancel</span>
                        <Undo className="h-5 w-5" />
                    </button>
                </div>
                <div className="h-2 w-72 overflow-hidden rounded-xl bg-slate-900 p-0 shadow-sm">
                    <div className="relative flex h-2 w-full items-center justify-between bg-white/50">
                        <div
                            className="absolute bottom-0 left-0 top-0 w-full rounded-lg bg-white transition-all
                                duration-1000 ease-linear"
                            style={{
                                width: `${(timeRemaining / 5) * 100}%`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
