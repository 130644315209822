import { useAppDispatch, useAppSelector } from "@/redux-state";
import { useCallback } from "react";
import {} from "@/redux-state/contentBlockDataSlice";
import {
    setDisplayRules,
    setSelectedDisplayRuleModelVariableIds,
} from "@/redux-state/displayRuleSlice";
import { ContentBlock, ContentBlockShape } from "@/models";

export const useDisplayRules = () => {
    const dispatch = useAppDispatch();

    const _displayRulesByContentBlockId = useAppSelector(
        (state) => state?.displayRuleStore?.displayRulesByContentBlockId
    );

    const _setDisplayRules = useCallback(
        (contentBlocks: (ContentBlock | ContentBlockShape)[]) => {
            dispatch(setDisplayRules(contentBlocks));
            dispatch(
                setSelectedDisplayRuleModelVariableIds(
                    contentBlocks
                        .filter((contentBlock) => !!contentBlock.displayRule)
                        .map(
                            (contentBlock) =>
                                contentBlock.displayRule.model_variable_id
                        )
                )
            );
        },
        [dispatch]
    );

    // const _selectedDisplayRules = useAppSelector(
    //     (state) => state.displayRuleStore.displayRulesByContentBlockId
    // );

    const _selectedDisplayRuleModelVariableIds = useAppSelector(
        (state) => state.displayRuleStore.selectedDisplayRuleModelVariableIds
    );

    const _setSelectedDisplayRuleModelVariableIds = useCallback(
        (ids: string[]) => {
            dispatch(setSelectedDisplayRuleModelVariableIds(ids));
        },
        [dispatch]
    );

    const modelVariablesForDisplayRules = useAppSelector((state) => {
        if (
            !state?.modelBuilderStore?.modelVariableStore?.modelVariables ||
            !state?.displayRuleStore?.displayRulesByContentBlockId
        ) {
            console.log(
                "no model variables or display rules",
                state?.modelBuilderStore?.modelVariableStore?.modelVariables,
                state?.displayRuleStore?.displayRulesByContentBlockId
            );
            return [];
        }
        return Object.keys(
            state.modelBuilderStore.modelVariableStore.modelVariables
        )
            .filter((modelVariableId) =>
                Object.values(
                    state.displayRuleStore.displayRulesByContentBlockId
                ).some(
                    (displayRule) =>
                        displayRule.model_variable_id === modelVariableId
                )
            )
            .map(
                (modelVariableId) =>
                    state.modelBuilderStore.modelVariableStore.modelVariables[
                        modelVariableId
                    ]
            );
    });

    const hiddenContentBlockIds = useAppSelector((state) => {
        if (
            !state?.displayRuleStore?.selectedDisplayRuleModelVariableIds ||
            !state?.displayRuleStore?.displayRulesByContentBlockId
        )
            return [];
        const variableIds =
            state.displayRuleStore.selectedDisplayRuleModelVariableIds;
        return Object.keys(
            state.displayRuleStore.displayRulesByContentBlockId
        ).filter(
            (contentBlockId) =>
                !variableIds.includes(
                    state.displayRuleStore.displayRulesByContentBlockId[
                        contentBlockId
                    ]?.model_variable_id
                )
        );
    });

    return {
        displayRulesByContentBlockId: _displayRulesByContentBlockId,
        modelVariablesForDisplayRules,
        setSelectedDisplayRuleModelVariableIds:
            _setSelectedDisplayRuleModelVariableIds,
        setDisplayRules: _setDisplayRules,
        selectedDisplayRuleModelVariableIds:
            _selectedDisplayRuleModelVariableIds,
        hiddenContentBlockIds,
    };
};
