import { ContentBlockShape, ContentBlockType } from "@/models";
import {
    defaultTopDrawerTheme,
    defaultBoxTheme,
    defaultSubmissionCountTheme,
} from "@/styles";

const defaultChildren = [
    {
        content_block_type: ContentBlockType.Box,
        theme: {
            ...defaultBoxTheme,
            pt: 0,
            pr: 0,
            pb: 0,
            pl: 0,
            mt: 0,
            mr: 0,
            mb: 0,
            ml: 0,
        },
        weight: 0,
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Submission Count"],
                theme: defaultSubmissionCountTheme,
                weight: 0,
                content:
                    "players on your team are ready for the next team challenge",
            },
        ],
    },
];
export function buildTopDrawer(
    contentBlocks: ContentBlockShape[] = []
): ContentBlockShape {
    return {
        content_block_type: ContentBlockType["Top Drawer"],
        theme: defaultTopDrawerTheme,
        //Top Drawer is always at the top of the page
        //set the weight accordingly, so that when it's position is set to relative
        //in the editor, it will be at the top of the page
        weight: -9999,
        contentBlocks: contentBlocks?.length ? contentBlocks : defaultChildren,
    };
}

export const TopDrawerLayout = buildTopDrawer();
