import React from "react";
import { ScrollableWrapper } from "./WidgetWrappers";

const CompactTableWrapperComponent = ({
    header,
    columnLabels,
    colSpanValues,
    alignRight,
    children,
}: {
    header: string;
    columnLabels: string[];
    colSpanValues?: number[];
    alignRight?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <div className="bg-white transition-all dark:bg-slate-800">
            <ScrollableWrapper>
                <table
                    className="w-full table-auto border-2 border-slate-300 text-left text-xs text-slate-700
                        transition-all dark:border-slate-600 dark:text-slate-300"
                >
                    <thead
                        className="border-b-2 border-slate-300 text-xs uppercase text-slate-700 transition-all
                            dark:border-slate-600 dark:text-slate-300"
                    >
                        <tr className="border border-slate-300 transition-all dark:border-slate-600">
                            <th className="px-1 py-3">{header}</th>
                            {columnLabels?.map((label, index) => (
                                <th
                                    key={`${label}-${index}`}
                                    className={`border-slate-300 px-1 py-3 transition-all dark:border-slate-600 ${
                                        label == "" ? "" : "border-l"
                                    } ${alignRight ? "text-right" : ""}`}
                                    colSpan={
                                        !!colSpanValues
                                            ? colSpanValues[index]
                                            : 1
                                    }
                                >
                                    {label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </table>
            </ScrollableWrapper>
        </div>
    );
};

export const CompactTableWrapper = React.memo(CompactTableWrapperComponent);
