import React from "react";
import { ChevronDoubleUpIcon, XMarkIcon } from "@heroicons/react/24/solid";

const BottomDrawerWrapperComponent = ({
    isDrawerOpen,
    setIsDrawerOpen,
    children,
}: {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (isDrawerOpen: boolean) => void;
    children: React.ReactNode;
}) => {
    return (
        <div
            className={`border-shadow-2xl fixed bottom-0 left-11 right-0 z-[9999] flex flex-col
            items-center justify-center border-0 border-slate-300 bg-slate-200 p-2
            text-slate-700 transition-all duration-500 sm:left-20 lg:flex-row
            dark:border-slate-700 dark:bg-slate-800 dark:text-slate-300 ${
                !isDrawerOpen ? "translate-y-[100%]" : "-translate-y-0"
            }`}
        >
            {children}
            <div
                className={`absolute -top-8 mr-2.5 flex flex-col items-center justify-center rounded-t-md
                bg-slate-200 p-2 text-slate-700 transition-all dark:bg-slate-800
                dark:text-slate-300 ${isDrawerOpen ? "" : "bg-opacity-75 dark:bg-opacity-75"}`}
            >
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setIsDrawerOpen(!isDrawerOpen);
                    }}
                >
                    {isDrawerOpen ? (
                        <XMarkIcon className="h-4 w-4" />
                    ) : (
                        <ChevronDoubleUpIcon className="h-4 w-4" />
                    )}
                </button>
            </div>
        </div>
    );
};

export const BottomDrawerWrapper = React.memo(BottomDrawerWrapperComponent);
