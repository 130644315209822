import React from "react";
import {
    ContentBlockShape,
    ContentBlockType,
    PromptShape,
    PromptType,
} from "@/models";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/Select";
import { useMutation } from "@tanstack/react-query";
import { sapienAxios } from "@/inertia-utils/hooks";
import { useContentBlockState } from "../../../useContentBlockState";
type Props = { contentBlock: ContentBlockShape };

const questionTypes = {
    [PromptType["Multiple Choice"]]: "Multiple Choice",
    [PromptType["Multiple Select"]]: "Multiple Select",
    [PromptType["Numerical Slider"]]: "Slider",
};

export default function QuestionDropdown({ contentBlock }: Props) {
    const questionBlock = contentBlock?.contentBlocks
        ?.find((child) => child.content_block_type === ContentBlockType.Form)
        ?.contentBlocks?.find(
            (child) => child.content_block_type === ContentBlockType.Question,
        );

    const { setContentBlock } = useContentBlockState();
    const { mutate } = useMutation({
        mutationFn: async (prompt: PromptShape) => {
            await sapienAxios.put<{}, "e-learning.prompt-type.update">(
                "e-learning.prompt-type.update",
                {
                    prompt_type: prompt.prompt_type,
                    prompt_id: prompt.id,
                },
            );
        },
    });

    if (!questionBlock) {
        return null;
    }
    return (
        <Select
            onValueChange={(value) => {
                const prompt = {
                    ...questionBlock.prompt,
                    prompt_type: value as PromptType,
                };
                setContentBlock({ ...questionBlock, prompt });
                mutate(prompt);
            }}
        >
            <SelectTrigger className="w-56 bg-white shadow-md">
                <SelectValue
                    placeholder={
                        questionTypes[questionBlock?.prompt?.prompt_type] ||
                        "Multiple Select"
                    }
                />
            </SelectTrigger>
            <SelectContent className="cursor-pointer bg-white pl-0">
                {Object.keys(questionTypes).map((key) => (
                    <SelectItem
                        value={key}
                        key={key}
                        className="relative flex w-full flex-row"
                    >
                        <span>{questionTypes[key]}</span>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}
