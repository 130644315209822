import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

type Props = {
    leftButtonHandler: () => void;
    rightButtonHandler: () => void | null;
    isLastStep: boolean;
    isFirstStep: boolean;
    hideLeftButton?: boolean;
};

export default function QuestionModalFooter({
    leftButtonHandler,
    rightButtonHandler,
    isLastStep,
    isFirstStep,
    hideLeftButton = false,
}: Props) {
    return (
        <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
            <div className="flex items-center justify-center">
                {!hideLeftButton && (
                    <button
                        data-testid="question-left-button"
                        onClick={() => {
                            leftButtonHandler();
                        }}
                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                        {isFirstStep ? (
                            <>Cancel</>
                        ) : (
                            <>
                                <ArrowLeftIcon className="mr-2 h-3 w-3" />
                                Back
                            </>
                        )}
                    </button>
                )}
            </div>
            <div className="flex items-center justify-center">
                <button
                    disabled={!rightButtonHandler}
                    onClick={() => {
                        rightButtonHandler();
                    }}
                    className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                >
                    {!isLastStep ? (
                        <>
                            Next <ArrowRightIcon className="ml-2 h-3 w-3" />
                        </>
                    ) : (
                        <>Save</>
                    )}
                </button>
            </div>
        </div>
    );
}
