import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType, PromptType } from "@/models";
import { TypographyType } from "@/styles";

export const TrendsAssessmentContentBlocks: FragmentContentBlock[] = [
    {
        content_block_type: ContentBlockType.Page,
        title: "Page",
        route: "/",
        theme: { px: 0 },
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Hero,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Typography,
                        content: "Trends",
                        theme: {
                            color: "#ffffff",
                            px: 7,
                        },
                    },
                ],
            },
            {
                content_block_type: ContentBlockType.Section,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Typography,
                        content: "Trends Assessment",
                        theme: {
                            typographyType: "HEADING_6",
                        },
                    },
                ],
            },
            {
                content_block_type: ContentBlockType.Question,
                content: "Question content block",
                prompt: {
                    prompt_type: PromptType["Multiple Choice"],
                    content: "Prompt content",
                    options: [
                        { content: "Option 1 content" },
                        { content: "Option 2 content" },
                    ],
                },
            },
        ],
    },
];
