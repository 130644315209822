import DropdownAvatar from "@/Layouts/DropdownAvatar";
import { Auth } from "@/inertia-utils/types";
import React from "react";

type Props = {
    auth: Auth;
    children: React.ReactNode | React.ReactNode[];
};

export default function AuthenticatedHeader({ auth, children }: Props) {
    return (
        <>
            <div
                className="fixed top-0 z-[99999] flex h-16 w-full items-center justify-between border-b
                    border-gray-200 bg-white px-8 shadow-md"
            >
                {children}
                <div className="flex-1">
                    <DropdownAvatar
                        auth={auth}
                        logoutRoute="e-learning.participant.logout"
                    />
                </div>
            </div>
            <div className="h-16"></div>
        </>
    );
}
