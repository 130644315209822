import React, { useCallback, useEffect, useState } from "react";
import { useEditedContentBlock } from "../../useEditedContentBlock";
import { AsideContents } from "../../Aside";
import { ContentBlockShape, RichText } from "@/models";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../../../useContentBlockState";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import LessonRichText from "@/Pages/ELearning/LessonDisplay/LessonRichText";
import { useModalQueryParams } from "@/hooks";
import { createFlipCard } from "../../../content-block-management/content-block-functions/createFlipCard";
import { PlusIcon, Trash } from "lucide-react";
import DeleteContentBlockOverlay from "../../DeleteContentBlockOverlay";

function FlipCardSideEditor({
    flipCardSide,
    index,
}: {
    flipCardSide: ContentBlockShape;
    index: number;
}) {
    const [editedBlock, setEditedBlock] = useState(flipCardSide);

    useEffect(() => {
        // setEditedBlock(flipCardSide);
    }, [flipCardSide?.image_url]);

    const variant: "text" | "image-full" | "image-top" =
        editedBlock.theme?.variant || "text";

    const side = index % 2 === 0 ? "Front" : "Back";

    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();
    const { openModal } = useModalQueryParams();

    const saveAndUpdate = useCallback(
        (contentBlock: ContentBlockShape) => {
            setContentBlock(contentBlock);
            mutate(contentBlock);
        },
        [editedBlock.id],
    );

    const updateRichText = useCallback(
        (rich_text: RichText) => {
            saveAndUpdate({
                ...editedBlock,
                rich_text,
            });
        },
        [editedBlock.id],
    );

    return (
        <div className="relative flex flex-col gap-2">
            <div className="flex justify-between">
                <h3 className="font-bold">{side} of Card</h3>
            </div>
            <select
                className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1
                    ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                    sm:leading-6"
                value={variant}
                onChange={(e) => {
                    saveAndUpdate({
                        ...editedBlock,
                        theme: {
                            ...editedBlock.theme,
                            variant: e.target.value as any,
                        },
                    });
                }}
            >
                <option value="text">Text</option>
                <option value="image-full">Image Full</option>
                <option value="image-top">Image Top</option>
            </select>

            {variant !== "text" && (
                <div
                    key={editedBlock.id}
                    className="mt-4 flex justify-between space-y-2"
                >
                    <img
                        className="w-32 object-cover"
                        src={editedBlock.image_url}
                        alt={editedBlock.title}
                    />
                    <div className="flex items-center">
                        <button
                            className="rounded-md border border-slate-500 px-4 py-1"
                            onClick={() =>
                                openModal({
                                    modalComponent: "ImageModal",
                                    imageUrl: editedBlock.image_url,
                                    imageContentBlockId: editedBlock.id,
                                })
                            }
                        >
                            Change Image
                        </button>
                    </div>
                </div>
            )}

            <div className="mt-4 flex items-start justify-start rounded-md border border-slate-200 p-6">
                <LessonRichText
                    contentBlock={editedBlock}
                    editable={true}
                    content={editedBlock.rich_text}
                    saveOverride={updateRichText}
                    delayMilliseconds={1000}
                />
            </div>
        </div>
    );
}

function FlipCardEditor({
    flipCard,
    index,
}: {
    flipCard: ContentBlockShape;
    index: number;
}) {
    const cardSides =
        useGetContentBlockWithChildren(flipCard.id)?.contentBlocks?.sort(
            (a, b) => a.weight - b.weight,
        ) || [];
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <div className="relative flex flex-col gap-4 px-4 py-6 odd:bg-slate-100 even:border">
            {!!isDeleting && (
                <DeleteContentBlockOverlay
                    contentBlock={flipCard}
                    cancelDelete={() => setIsDeleting(false)}
                />
            )}
            <h2 className="flex justify-between text-lg font-bold">
                Card {index + 1}
                <button onClick={() => setIsDeleting(true)}>
                    <span className="sr-only">Delete Card {index + 1}</span>
                    <Trash className="h-6 w-6" />
                </button>
            </h2>

            {cardSides.map((cb, index) => (
                <FlipCardSideEditor
                    key={cb.id}
                    flipCardSide={cb}
                    index={index}
                />
            ))}
        </div>
    );
}

export default function FlashcardSidebar() {
    const { editedContentBlock } = useEditedContentBlock();
    const { mutate } = useSaveContentBlock();
    return (
        <AsideContents title="Flahscards">
            <div className="relative mt-2 flex flex-col pb-24">
                {editedContentBlock?.contentBlocks.map((cb, index) => (
                    <FlipCardEditor key={cb.id} flipCard={cb} index={index} />
                ))}
                <div className="fixed bottom-0 left-0 flex w-full max-w-[41.5rem] border-t bg-white p-4">
                    <button
                        className="flex w-full items-center justify-center gap-2 rounded-md border
                            border-sapien-blue bg-white px-4 py-1 text-sapien-blue transition-colors
                            hover:bg-sapien-blue hover:text-white"
                        onClick={() => {
                            const newCard = createFlipCard(
                                editedContentBlock.round_id,
                                editedContentBlock.contentBlocks.length,
                            );
                            console.log("new card", newCard);
                            mutate({
                                ...newCard,
                                parent_content_block_id: editedContentBlock.id,
                            });
                        }}
                    >
                        <span>Add Card</span>
                        <PlusIcon size={16} />
                    </button>
                </div>
            </div>
        </AsideContents>
    );
}
