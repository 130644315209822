import { Popover, Transition } from "@headlessui/react";
import { BoltIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import React, {  useState } from "react";


export function OptionPopover() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover className="relative">
            <Popover.Button
                onClick={() => {
                    setIsOpen((wasOpen) => !wasOpen);
                }}
                className={`group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-opacity-75 text-white hover:text-opacity-100 focus:outline-none ${
                    isOpen ? "" : "text-opacity-90"
                }`}
            >
                <BoltIcon className="h-5 w-5 text-white" />
            </Popover.Button>
            <Transition
                as={"div"}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                static
            >
                {isOpen && (
                    <Popover.Panel className="absolute left-1/2 z-100 mt-1 w-screen max-w-xs bg-white rounded-md shadow-lg text-black -translate-x-72 transform px-4 sm:px-0">
                        <Popover.Panel>
                            <div className="flex flex-col p-4 items-center justify-center space-y-2">
                                <InformationCircleIcon className="w-6 h-6 text-blue-600" />
                                <p>
                                    This option has model connections and cannot
                                    be deleted
                                </p>
                            </div>
                        </Popover.Panel>
                    </Popover.Panel>
                )}
            </Transition>
        </Popover>
    );
}
