import React from "react";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";

interface EditButtonProps {
    showEditButton: boolean;
    editFunction: () => void | Promise<void>;
    isDisabled?: boolean;
    editType: "edit" | "picture" | "video";
}

export const EditButton = (props: EditButtonProps) => {
    const { showEditButton, editFunction, isDisabled, editType } = props;

    return showEditButton ? (
        <ContentBlockButtonContainer
            onClick={() => !isDisabled && editFunction()}
            hoverColor={"bg-blue-600 hover:text-white"}
        >
            <>
                <span className="sr-only">Edit Button</span>
                {editType === "edit" && (
                    <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                    </svg>
                )}{" "}
                {editType === "picture" && (
                    <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                )}
                {editType === "video" && (
                    <svg
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                )}
            </>
        </ContentBlockButtonContainer>
    ) : null;
};
