import React, { useRef } from "react";
import styled from "styled-components";
import { CaptionSpan } from "@/components";
import { ContentBlockMetadata } from "@/model-configs";
import { Icon } from "react-icons-kit";
import { trash } from "react-icons-kit/feather/trash";

type Props = {
    label: string;
    contentBlockMeta: ContentBlockMetadata;
    isSelected: boolean;
    othersAreSelected: boolean;
    showLabel?: boolean;
    callback: (contentBlockMeta: ContentBlockMetadata) => void;
};

const StyledSelectionButton = styled.div<{
    isSelected: boolean;
    isFullWidth: boolean;
    othersAreSelected: boolean;
}>`
    align-items: stretch;
    transition: all 0.25s;
    &:hover {
        background: rgba(255, 255, 255, 0.2);
        color: white;
    }
    background-color: ${({ isSelected, othersAreSelected }) => {
        if (isSelected) {
            return "rgba(255,255,255,.2)";
        }

        if (othersAreSelected) {
            return "rgba(255,255,255,.05)";
        }

        return "rgba(255,255,255,.1)";
    }};
    color: ${({ isSelected, othersAreSelected }) => {
        if (isSelected || !othersAreSelected) {
            return "white";
        }
        return "rgba(255,255,255,.25)";
    }};

    > * {
        transition: all 0.25s;
        svg,
        img {
            max-width: 90%;
            max-height: 108px;
            opacity: ${({ othersAreSelected }) =>
                othersAreSelected ? ".8" : "1"};
        }
        * {
            font-size: ${({ isSelected, isFullWidth }) =>
                isSelected && !isFullWidth ? "8px" : "12px"};
        }
        &:first-child {
            width: ${({ isSelected }) => (isSelected ? "50%" : "100%")};
        }
        &:last-child {
            width: ${({ isSelected }) => (isSelected ? "50%" : "0%")};
        }
        > div:last-child {
            transition: all 0.25s;
            justify-content: ${({ isSelected }) =>
                isSelected ? "center" : "center"};
        }
    }
`;
function toKebabCase(str: string): string {
    return (
        str
            // Handle camelCase or PascalCase by adding a space before capital letters
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            // Replace underscores, spaces, and multiple hyphens with a single hyphen
            .replace(/[_\s]+/g, "-")
            // Convert to lowercase
            .toLowerCase()
    );
}
export default function ContentBlockSelectorButton({
    label,
    contentBlockMeta,
    isSelected,
    othersAreSelected,
    showLabel,
    callback,
}: Props) {
    //TODO:figure out css only solution

    const ref = useRef<HTMLButtonElement>();

    // const siblingHeight = useMemo(() => {
    //     if (ref?.current) {
    //         return ref?.current.getBoundingClientRect().height;
    //     }
    //     return 0;
    // }, [label, isSelected, ref]);

    if (!label || !contentBlockMeta) return <></>;

    const keyClassName = `add-${toKebabCase(label)}-button`;
    return !contentBlockMeta.isFullWidth ? (
        <StyledSelectionButton
            isSelected={isSelected}
            othersAreSelected={othersAreSelected}
            isFullWidth={false}
            className={`flex h-24 overflow-hidden rounded-md py-0 hover:shadow`}
            id={`${isSelected ? "selected-content-block-button" : ""}`}
        >
            <button
                ref={ref}
                className={`dodo flex h-24 w-full flex-col items-center justify-around py-0 ${keyClassName}`}
                onClick={() => {
                    callback(contentBlockMeta);
                }}
            >
                <div className="flex flex-col items-center justify-center p-[1px] pb-[2px] text-white">
                    {contentBlockMeta?.icon}
                </div>
                {!showLabel ? (
                    <span className="sr-only">{label}</span>
                ) : (
                    <span>{label}</span>
                )}
            </button>
            <button
                className={`flex h-24 flex-col items-stretch justify-center bg-red-600 ${
                    isSelected ? "px-2" : "px-0"
                }`}
                onClick={() => {
                    callback(null);
                }}
            >
                {isSelected && (
                    <>
                        <span className="sr-only">{"Cancel"}</span>
                        <div className="flex flex-col items-center justify-center p-px pb-0.5">
                            <Icon icon={trash} />
                        </div>
                        <div className="flex w-full flex-row pt-1">
                            <CaptionSpan>Cancel</CaptionSpan>
                        </div>
                    </>
                )}
            </button>
        </StyledSelectionButton>
    ) : (
        <StyledSelectionButton
            isSelected={isSelected}
            othersAreSelected={othersAreSelected}
            isFullWidth={contentBlockMeta.isFullWidth}
            className={`${keyClassName} flex overflow-hidden rounded-md hover:shadow ${
                contentBlockMeta.isFullWidth ? "h-48" : "h-24 py-0"
            }`}
        >
            <button
                ref={ref}
                className={` ${keyClassName} flex flex-col  justify-around ${
                    contentBlockMeta.isFullWidth ? "h-full" : "h-24 py-0"
                } w-full items-center px-2`}
                onClick={() => {
                    callback(contentBlockMeta);
                }}
            >
                <div className="flex flex-col items-center justify-center p-[1px] pb-[2px] text-white">
                    {contentBlockMeta?.icon}
                </div>
                {!isSelected && (
                    <div className="flex w-full flex-row justify-center pt-1">
                        <CaptionSpan>{label}</CaptionSpan>
                    </div>
                )}
            </button>
            <button
                className={`flex flex-col items-stretch bg-red-600 ${
                    contentBlockMeta.isFullWidth
                        ? "h-full justify-center"
                        : "h-24 justify-between"
                } ${isSelected ? "px-2" : "px-0"}`}
                onClick={() => {
                    callback(null);
                }}
            >
                {isSelected && (
                    <>
                        <div className="flex flex-col items-center justify-center p-px pb-0.5 pt-6">
                            <Icon icon={trash} />
                        </div>
                        <div className="flex w-full flex-row pt-1">
                            <CaptionSpan>Cancel</CaptionSpan>
                        </div>
                    </>
                )}
            </button>
        </StyledSelectionButton>
    );
}
