import { ContentBlockShape, ContentBlockType } from "@/models";
import React from "react";
import { getType } from "../getType";
import { AccordionColorMenu, AccordionSpacingMenu } from "./AccordionStyling";
import { TabsSpacing } from "../TabsStyling";
import { FormColorStyling, FormSpacingStyling } from "./FormStyling";
import { ButtonColorMenu, ButtonSpacingMenu } from "./ButtonStyles";
import { ChartContainerColorStyling, ChartContainerSpacingMenu } from "./ChartContainerColorStyling";
import { TableColorStyling, TableSpacingDropdown } from "./tables/TableDropdowns";

type Props = {
    contentBlock: ContentBlockShape;
};

export function SpacingMenuByType({ contentBlock }: Props) {
    const type = getType(contentBlock);

    switch (type) {
        case ContentBlockType.Accordion:
            return <AccordionSpacingMenu contentBlock={contentBlock} />;
        case ContentBlockType["Chart Container"]:
            return <ChartContainerSpacingMenu contentBlock={contentBlock} />;
        case ContentBlockType.Question:
            return (
                <>
                    <FormSpacingStyling contentBlock={contentBlock} />
                    <ButtonSpacingMenu contentBlock={contentBlock} />
                </>
            );
        case ContentBlockType.Table:
            return <TableSpacingDropdown contentBlock={contentBlock} />;
        case ContentBlockType.Tabs:
            return <TabsSpacing contentBlock={contentBlock} />;
        default:
            return <></>;
    }
}

export function ColorMenuByType({ contentBlock }: Props) {
    const type = getType(contentBlock);

    switch (type) {
        case ContentBlockType.Accordion:
        case ContentBlockType.Tabs:
            return <AccordionColorMenu contentBlock={contentBlock} />;
        case ContentBlockType["Chart Container"]:
            return <ChartContainerColorStyling contentBlock={contentBlock} />;
        case ContentBlockType.Question:
            return (
                <>
                    <FormColorStyling contentBlock={contentBlock} />
                    <ButtonColorMenu contentBlock={contentBlock} />
                </>
            );
        case ContentBlockType.Table:
            return <TableColorStyling contentBlock={contentBlock} />;
        // case ContentBlockType.Tabs:
        //     return <TabsSpacing contentBlock={contentBlock} />;
        default:
            return <></>;
    }
}
