import React, { useCallback, useLayoutEffect, useState } from "react";
import { useEditorActiveLineage, useFindContentBlockMethods } from "@/hooks";
import {
    useDiscardActiveContentBlock,
    useGetActiveContentBlock,
    useSelectActiveContentBlock,
} from "@/hooks/activeContentBlock";

type Props = {};

export default function EditorLineageBreadcrumbs({}: Props) {
    const { activeLineage } = useEditorActiveLineage();
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { getContentBlock } = useFindContentBlockMethods();
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();

    const [breadcrumbIds, setBreadcrumbIds] = useState([]);

    useLayoutEffect(() => {
        if (activeContentBlock) {
            setBreadcrumbIds([
                ...Object.keys(activeLineage),
                activeContentBlock.id,
            ]);
        } else {
            setBreadcrumbIds([]);
        }
    }, [activeContentBlock, activeLineage]);

    const handleLayerClick = useCallback(
        (contentBlockId: string) => {
            if (activeContentBlock?.id === contentBlockId) {
                discardActiveContentBlock();
                return;
            }
            setActiveContentBlock(contentBlockId);
        },
        [getContentBlock, breadcrumbIds, setActiveContentBlock],
    );

    return (
        <div className="flex h-full flex-row items-center justify-start bg-[#1F2A37] px-1 text-white">
            {!activeContentBlock && (
                <div className="px-1 opacity-50">No element selected</div>
            )}
            {!!activeContentBlock &&
                breadcrumbIds.length > 0 &&
                breadcrumbIds.map((id, index) => (
                    <div
                        key={`${id}-${index}`}
                        onClick={() => handleLayerClick(id)}
                        className={`flex h-full flex-row items-center justify-center px-1 py-[1px] ${
                            activeContentBlock.id !== id
                                ? "text-sm text-gray-400 transition hover:cursor-pointer hover:text-white"
                                : "text-sm text-gray-100 transition hover:cursor-pointer hover:text-white"
                        }
                        `}
                    >
                        <span
                            style={{
                                paddingRight: "2px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: "normal",
                                letterSpacing: "0.25px",
                            }}
                        >
                            {getContentBlock(id)?.title ||
                                getContentBlock(
                                    id,
                                )?.content_block_type.toLowerCase()}
                        </span>
                        <svg
                            data-icon="BreadcrumbPartLeft"
                            aria-hidden="true"
                            focusable="false"
                            width="9"
                            height="36px"
                            viewBox="0 0 9 36"
                            className="bem-Svg right notch"
                            style={{
                                display: "block",
                                color: "#1f2a37",
                            }}
                        >
                            <path
                                fill={"currentColor"}
                                d="M.5 0l8 18-8 18H9V0z"
                            ></path>
                            <path
                                fill={"white"}
                                d="M1 0H0l8 18-8 18h1l8-18z"
                            ></path>
                        </svg>
                    </div>
                ))}
        </div>
    );
}
