import { ContentBlockShape } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { defaultChartContainerTheme } from "@/styles";
import { defaultLineSeriesTheme } from "@/styles/themes/charts/themes";
//

export const fillableTypes: ChartType[] = ["pie", "doughnut", "area", "bar"];
export const timeSeriesTypes: ChartType[] = [
    "line",
    "area",
    "bar",
    "multi-mode",
];

export type ChartSeries = {
    id?: string;
    modelVariableId: string;
    label: string;
    theme: typeof defaultLineSeriesTheme;
    chartType?: ChartType;
    chart_data?: FormattedChartValue[];
};
export type ChartWizardSubmissionData = {
    id?: string;
    series: ChartSeries[];
    parentContentBlockId: string;
    deletedSeries: number[];
    chartType: ChartType;
    theme: typeof defaultChartContainerTheme;
    timeHorizonIds: string[];
    maximumValue?: number;
};

// actionTypes.ts
export enum ActionTypes {
    INCREMENT = "INCREMENT",
    DECREMENT = "DECREMENT",
    SET_SERIES = "SET_SERIES",
    DELETE_SERIES = "DELETE_SERIES",
    RESTORE_DELETED_SERIES = "RESTORE_DELETED_SERIES",
    SET_ALL_FROM_CONTENT_BLOCK = "SET_ALL_FROM_CONTENT_BLOCK",
    SET_CHART_TYPE = "SET_CHART_TYPE",
    ADD_SERIES = "ADD_SERIES",
    SELECT_SERIES_TO_EDIT = "SELECT_SERIES_TO_EDIT",
    UPDATE_EDITED_SERIES = "UPDATE_EDITED_SERIES",
    UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES = "UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES",
    CANCEL_EDIT_SERIES = "CANCEL_EDIT_SERIES",
    COMMIT_EDITS = "COMMIT_EDITS",
    SET_TIME_HORIZONS = "SET_TIME_HORIZONS",
    SET_DATA_SOURCE = "SET_DATA_SOURCE",
    SET_ADVANCED_OPTIONS = "SET_ADVANCED_OPTIONS",
}

export const ChartTypes = {
    LINE: "line",
    AREA: "area",
    BAR: "bar",
    PIE: "pie",
    DOUGHNUT: "doughnut",
    MULTI_MODE: "multi-mode",
} as const;

export type ChartType = (typeof ChartTypes)[keyof typeof ChartTypes];

export interface IncrementAction {
    type: ActionTypes.INCREMENT;
}

export interface DecrementAction {
    type: ActionTypes.DECREMENT;
}

export type SetSeriesAction = {
    type: ActionTypes.SET_SERIES;
    payload: { chartSeries: ChartSeries; index: number };
};

export type AddSeriesAction = {
    type: ActionTypes.ADD_SERIES;
};

export type DeleteSeriesAction = {
    type: ActionTypes.DELETE_SERIES;
    payload: { index: number };
};
export type RestoreDeletedSeriesAction = {
    type: ActionTypes.RESTORE_DELETED_SERIES;
    payload: { index: number };
};
export type SetAllFromContentBlockAction = {
    type: ActionTypes.SET_ALL_FROM_CONTENT_BLOCK;
    payload: ContentBlockShape;
};

export type SetChartTypeAction = {
    type: ActionTypes.SET_CHART_TYPE;
    payload: ChartType;
};

export type EditSeriesAction = {
    type: ActionTypes.SELECT_SERIES_TO_EDIT;
    payload: number;
};

export type FinishEditSeriesAction = {
    type: ActionTypes.COMMIT_EDITS;
};

export type CancelEditSeriesAction = {
    type: ActionTypes.CANCEL_EDIT_SERIES;
};

export type SetTimeHorizonsAction = {
    type: ActionTypes.SET_TIME_HORIZONS;
    payload: string[];
};

export type SetDataSourceAction = {
    type: ActionTypes.SET_DATA_SOURCE;
    payload: "model" | "manual";
};

export type UpdatedEditedSeriesAction = {
    type: ActionTypes.UPDATE_EDITED_SERIES;
    payload: ChartSeries;
};

export type UpdatedEditedSeriesAndCommitAction = {
    type: ActionTypes.UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES;
    payload: { chartSeries: ChartSeries; index: number };
};

export type SetAdvancedThemeOptionsAction = {
    type: ActionTypes.SET_ADVANCED_OPTIONS;
    payload: Partial<ChartWizardSubmissionData>;
};

export type Action =
    | IncrementAction
    | DecrementAction
    | SetSeriesAction
    | DeleteSeriesAction
    | RestoreDeletedSeriesAction
    | SetAllFromContentBlockAction
    | SetChartTypeAction
    | AddSeriesAction
    | EditSeriesAction
    | FinishEditSeriesAction
    | CancelEditSeriesAction
    | SetTimeHorizonsAction
    | SetDataSourceAction
    | UpdatedEditedSeriesAction
    | UpdatedEditedSeriesAndCommitAction
    | SetAdvancedThemeOptionsAction;

const steps = [1, 2, 3];
export interface State {
    editedIndex: number;
    editedItem: ChartSeries;
    step: number;
    chartWizardSubmissionData: ChartWizardSubmissionData;
    dataSource: "model" | "manual";
    contentBlocksWithModelDataSources?: ContentBlockShape[];
}

export const initialState: State = {
    editedIndex: -1,
    editedItem: {
        modelVariableId: "",
        label: "",
        theme: defaultLineSeriesTheme,
    },
    contentBlocksWithModelDataSources: [],
    step: 1,
    chartWizardSubmissionData: {
        series: [],
        id: "",
        parentContentBlockId: "",
        chartType: undefined,
        deletedSeries: [],
        theme: defaultChartContainerTheme,
        timeHorizonIds: [],
    },
    dataSource: "model",
};

function getChartType(series: ChartSeries[]) {
    const chartTypes = series.map((s) => s.chartType);

    if (chartTypes.some((ct) => ct === ChartTypes.AREA)) {
        return ChartTypes.AREA;
    }
    if (chartTypes.some((ct) => ct === ChartTypes.LINE)) {
        return ChartTypes.LINE;
    }
    if (chartTypes.every((ct) => ct === ChartTypes.BAR)) {
        return ChartTypes.BAR;
    }
    if (chartTypes.some((ct) => ct === ChartTypes.DOUGHNUT)) {
        return ChartTypes.DOUGHNUT;
    }
    if (chartTypes.some((ct) => ct === ChartTypes.PIE)) {
        return ChartTypes.PIE;
    }

    return ChartTypes.MULTI_MODE;
}

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionTypes.INCREMENT:
            if (state.step + 1 <= steps[steps.length - 1])
                return { ...state, step: state.step + 1 };
            return state;
        case ActionTypes.DECREMENT:
            if (state.step - 1 >= steps[0])
                return { ...state, step: state.step - 1 };
            return state;
        case ActionTypes.SELECT_SERIES_TO_EDIT:
            return {
                ...state,
                editedIndex: action.payload,
                editedItem:
                    state.chartWizardSubmissionData.series[action.payload],
            };
        case ActionTypes.UPDATE_EDITED_SERIES:
            return {
                ...state,
                editedItem: action.payload,
            };
        case ActionTypes.UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES:
            console.log(
                "UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES",
                action.payload,
                state.chartWizardSubmissionData
            );
            return {
                ...state,
                editedItem: action.payload.chartSeries,
                editedIndex: action.payload.index,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    series:
                        state.chartWizardSubmissionData.series?.length > 0
                            ? state.chartWizardSubmissionData.series.map(
                                  (s, i) =>
                                      i === action.payload.index
                                          ? action.payload.chartSeries
                                          : s
                              )
                            : [action.payload.chartSeries],
                },
            };

        case ActionTypes.COMMIT_EDITS:
            const chartWizardSubmissionData = {
                ...state.chartWizardSubmissionData,
            };
            const index = Math.max(state.editedIndex, 0);
            chartWizardSubmissionData.series[index] = state.editedItem;
            return {
                ...state,
                editedIndex: -1,
                editedItem: {
                    modelVariableId: "",
                    label: "",
                    theme: defaultLineSeriesTheme,
                    id: "",
                },
                chartWizardSubmissionData,
            };

        case ActionTypes.ADD_SERIES:
            return {
                ...state,
                editedIndex: state.chartWizardSubmissionData.series.length || 0,
                editedItem: {
                    modelVariableId: "",
                    label: "",
                    theme: defaultLineSeriesTheme,
                    chartType: state.chartWizardSubmissionData.chartType,
                    chart_data: [
                        {
                            label: "",
                            y: 0,
                            x: 0,
                            theme: defaultLineSeriesTheme,
                        },
                    ],
                },
            };

        case ActionTypes.SET_CHART_TYPE:
            return {
                ...state,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    chartType: action.payload,
                },
                editedItem: {
                    ...state.editedItem,
                    chartType: action.payload,
                },
            };
        case ActionTypes.DELETE_SERIES:
            return {
                ...state,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    deletedSeries:
                        state.chartWizardSubmissionData.deletedSeries.concat(
                            action.payload.index
                        ),
                },
            };
        case ActionTypes.RESTORE_DELETED_SERIES:
            const idx = state.chartWizardSubmissionData.deletedSeries.indexOf(
                action.payload.index
            );
            return {
                ...state,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    deletedSeries:
                        state.chartWizardSubmissionData.deletedSeries.filter(
                            (_, i) => i !== idx
                        ),
                },
            };
        case ActionTypes.SET_ALL_FROM_CONTENT_BLOCK:
            const series: ChartSeries[] = action.payload?.contentBlocks.map(
                (contentBlock) => {
                    const cbType = contentBlock.content_block_type;
                    const chartType = cbType
                        .split("_")[0]
                        ?.toLowerCase() as ChartType;
                    const modelDataSource =
                        contentBlock?.contentBlockModelDataSources?.length > 0
                            ? contentBlock.contentBlockModelDataSources[0]
                            : undefined;

                    return {
                        id: contentBlock.id,
                        modelVariableId: modelDataSource?.model_variable_id,
                        label:
                            contentBlock?.chart_data?.[0]?.label ||
                            modelDataSource?.label,
                        theme:
                            typeof contentBlock.theme === "string"
                                ? JSON.parse(contentBlock.theme)
                                : contentBlock.theme,
                        chartType,
                        chart_data: contentBlock.chart_data,
                    };
                }
            );

            let timeHorizonIds = [];
            if (
                action?.payload?.contentBlocks[0]
                    ?.contentBlockModelDataSources[0]?.timeHorizons?.length > 0
            ) {
                timeHorizonIds =
                    action.payload?.contentBlocks[0]?.contentBlockModelDataSources[0]?.timeHorizons?.map(
                        (th) => th.id
                    );
            }

            return {
                ...state,
                step: action.payload?.id ? 3 : 1,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    series: series,
                    id: action.payload.id,
                    parentContentBlockId:
                        action.payload.parent_content_block_id,
                    chartType: action.payload.id
                        ? getChartType(series)
                        : undefined,
                    theme:
                        typeof action.payload.theme === "string"
                            ? JSON.parse(action.payload.theme)
                            : action.payload.theme,
                    timeHorizonIds,
                },
                dataSource:
                    !action.payload?.contentBlocks[0]
                        ?.contentBlockModelDataSources?.length &&
                    action.payload?.contentBlocks[0]?.chart_data?.length
                        ? "manual"
                        : "model",
            };

        case ActionTypes.SET_TIME_HORIZONS:
            return {
                ...state,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    timeHorizonIds: action.payload,
                },
            };

        case ActionTypes.SET_DATA_SOURCE:
            return {
                ...state,
                dataSource: action.payload,
            };

        case ActionTypes.SET_ADVANCED_OPTIONS:
            return {
                ...state,
                chartWizardSubmissionData: {
                    ...state.chartWizardSubmissionData,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
