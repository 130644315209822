import React, { useMemo } from "react";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { ChartSeries, ChartType } from "./ChartWizardReducer";
import { LineOrAreaChart, PieOrDonutChart } from "@/modules/shared/charts";
import { defaultChartContainerTheme } from "@/styles";
import { ColumnChart } from "@/modules/shared/charts/ColumnChart";

function RenderChart({
    chartValues,
    theme,
    chartType,
}: {
    chartValues: { [index: string]: FormattedChartValue[] };
    theme: typeof defaultChartContainerTheme;
    chartType: ChartType;
}) {

    switch (chartType) {
        case "area":
        case "line":
            return (
                <LineOrAreaChart
                    theme={theme}
                    formattedChartValues={chartValues}
                />
            );
        case "pie":
        case "doughnut":
            return (
                <PieOrDonutChart
                    theme={theme}
                    formattedChartValues={chartValues}
                />
            );
        case "bar":
            return (
                <ColumnChart theme={theme} formattedChartValues={chartValues} />
            );
        default:
            return (
                <PieOrDonutChart
                    theme={theme}
                    formattedChartValues={chartValues}
                />
            );
    }
}
type Props = {
    chartValues: { [index: string]: FormattedChartValue[] };
    chartType: ChartType;
    dataSource: "manual" | "model";
    theme: typeof defaultChartContainerTheme;
    loading: boolean;
};
export default function ChartPreview({
    chartValues,
    chartType,
    dataSource,
    theme,
    loading,
}: Props) {
    return (
        <div className="flex justify-center items-center space-x-4 bg-white rounded-md">
            {!!Object.keys(chartValues)?.length ? (
                <RenderChart
                    chartValues={chartValues}
                    theme={theme}
                    chartType={chartType}
                />
            ) : (
                <div className="text-gray-900">
                    {loading ? (
                        <div>Loading preview</div>
                    ) : dataSource === "model" ? (
                        <div>Select model variable to see preview</div>
                    ) : (
                        <div>Enter values to see preview</div>
                    )}
                </div>
            )}
        </div>
    );
}
