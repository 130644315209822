import React, { Fragment, useState } from "react";
import {
    BodyContentContainer,
    BodyInnerWrapperWide,
    H4Span,
    H4ThinSpan,
    H5Span,
} from "@/components";
import { sapienRoute } from "@/inertia-utils/hooks";
import { SapienPageProps } from "@/inertia-utils/types";
import { AdminContainer } from "@/Layouts/admin";
import ReflectionJournalEntryDisplay from "@/Layouts/facilitation/ReflectionJournalEntryDisplay";
import { ReflectionJournalEntry, SimulationShape } from "@/models";
import { Transition } from "@headlessui/react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";
import { BookOpenIcon } from "@heroicons/react/24/solid";
import { Link } from "@inertiajs/react";
import { CohortWithProgressData } from "./FacilitationContainer";

type Props = {
    cohort: CohortWithProgressData | null;
    simulation: SimulationShape;
    reflectionJournalEntriesByUser: {
        [index: string]: ReflectionJournalEntry[];
    } & SapienPageProps;
};

export default function ReflectionJournalEntries({
    reflectionJournalEntriesByUser,
    cohort,
    simulation,
}: Props) {
    const [openEntryKeys, setOpenEntryKeys] = useState([]);

    return (
        <AdminContainer>
            <div style={{ backgroundColor: "#F3F4F6", width: "100%" }}>
                <BodyInnerWrapperWide>
                    <BodyContentContainer>
                        <div className="flex flex-row items-center text-[#6B7280]"></div>
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <H4Span>{`${simulation.title}`}</H4Span>
                            </div>
                            <div>
                                <H4ThinSpan>Facilitation</H4ThinSpan>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <H5Span>Reflection Journal Entries</H5Span>
                            </div>
                            <div>
                                <H5Span>
                                    <Link
                                        href={sapienRoute(
                                            "admin.projects.facilitate.show",
                                            {
                                                simulationSlug: simulation.slug,
                                                cohortId: cohort.id,
                                            },
                                        )}
                                        className="flex items-center space-x-2 text-blue-500 transition-all hover:text-blue-800"
                                    >
                                        <ChevronDoubleLeftIcon className="h-6 w-6" />
                                        Back to {cohort.title}
                                    </Link>
                                </H5Span>
                            </div>
                        </div>
                    </BodyContentContainer>
                </BodyInnerWrapperWide>
            </div>

            <BodyInnerWrapperWide>
                <div className="mt-8 flow-root  w-full max-w-screen-xl">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            {Object.keys(reflectionJournalEntriesByUser)
                                .length > 0 ? (
                                <div className="flex flex-col space-y-8">
                                    {Object.keys(
                                        reflectionJournalEntriesByUser,
                                    ).map((person) => (
                                        <div
                                            key={person}
                                            className={`w-full rounded-md bg-white p-6 shadow`}
                                        >
                                            <div
                                                role="button"
                                                onClick={() => {
                                                    setOpenEntryKeys(
                                                        (oldKeys) =>
                                                            oldKeys.includes(
                                                                person,
                                                            )
                                                                ? oldKeys.filter(
                                                                      (key) =>
                                                                          key !==
                                                                          person,
                                                                  )
                                                                : [
                                                                      ...oldKeys,
                                                                      person,
                                                                  ],
                                                    );
                                                }}
                                                className="flex justify-between whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3"
                                            >
                                                {person}
                                                <svg
                                                    className={`-mr-0.5 ml-2 h-5 w-5 transition-all ${
                                                        openEntryKeys.includes(
                                                            person,
                                                        )
                                                            ? "rotate-180"
                                                            : "rotate-0"
                                                    }`}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <Transition.Root
                                                show={openEntryKeys.includes(
                                                    person,
                                                )}
                                                as={Fragment}
                                            >
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="transition transition-[max-height] duration-200 ease-in"
                                                    enterFrom="transformmax-h-[0px] opacity-0"
                                                    enterTo="transform max-h-[9999px] opacity-100"
                                                    leave="transition transition-[max-height] duration-400 ease-out"
                                                    leaveFrom="transform max-h-[9999px] opacity-100"
                                                    leaveTo="transform max-h-[0px] opacity-0"
                                                >
                                                    <div className="flex flex-col px-3 py-4 text-sm text-gray-500">
                                                        {reflectionJournalEntriesByUser[
                                                            person
                                                        ].map((entry) => (
                                                            <ReflectionJournalEntryDisplay
                                                                reflectionJournalEntry={
                                                                    entry
                                                                }
                                                            />
                                                        ))}
                                                    </div>
                                                </Transition.Child>
                                            </Transition.Root>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <BookOpenIcon className="mx-auto h-12 w-12 text-gray-400" />
                                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                                        No entries yet
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </BodyInnerWrapperWide>
        </AdminContainer>
    );
}
