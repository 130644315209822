import React from "react";
import { Icon } from "react-icons-kit";

export const PrimaryButtonOutline = (props: {
    icon?: any;
    text: string;
    handler?: () => void;
    isDisabled?: boolean;
    type?: "submit";
}) => {
    const { icon, text, handler, isDisabled, type } = props;
    return (
        <button
            type={type || "button"}
            className="m-1 inline-flex items-center border border-blue-700 text-blue-700 hover:bg-blue-800 hover:bg-opacity-5 focus:outline-none focus:ring-0 focus:ring-blue-300 font-medium rounded-full text-sm px-3.5 py-2 text-center"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                !!handler && handler();
            }}
            disabled={isDisabled}
        >
            {!!icon && (
                <span
                    style={{
                        marginRight: "0.25rem",
                    }}
                >
                    <Icon
                        icon={icon}
                        size={16}
                        style={{
                            display: "flex",
                        }}
                    />
                </span>
            )}
            <span>{text}</span>
        </button>
    );
};
