import React, {
    useCallback,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import { ModalContainer } from "@/modules/shared";
import { useModalQueryParams, useSelectedSimulation } from "@/hooks";
import { ContentBlockShape, Tag } from "@/models";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useGetContentBlockTemplateCreationData } from "./ContentBlockTemplateQueries";
import { saveTemplate } from "./TemplateCreationQueries";
import ContentBlocksToWireframe from "./ContentBlocksToWireframe";
import Skeleton from "react-loading-skeleton";

function nestBlocks(
    contentBlocks: ContentBlockShape[],
    rootContentBlock: ContentBlockShape
): ContentBlockShape {
    const rootContentBlockChildren = contentBlocks?.filter(
        (contentBlock) =>
            contentBlock.parent_content_block_id === rootContentBlock.id
    );

    if (!rootContentBlockChildren.length) {
        return rootContentBlock;
    }

    const nestedChildren = rootContentBlockChildren.map((child) =>
        nestBlocks(contentBlocks, child)
    );

    return {
        ...rootContentBlock,
        contentBlocks: nestedChildren,
    };
}

// Define the interface for the form data
interface FormData {
    // screenshotData: string;
    label: string;
    content_block: string | null;
    description: string;
    simulation_id: string;
    content_block_id: string;
    tags: Tag[];
    html_string: string;
}

// Define the initial state
const initialState: FormData = {
    // screenshotData: "",
    label: "",
    content_block: null,
    description: "",
    simulation_id: "",
    content_block_id: "",
    tags: [],
    html_string: "",
};

// Define the action types and interfaces
type ActionType = "SET_DATA";

interface SetDataAction {
    type: ActionType;
    payload: {
        updates: Partial<FormData>;
    };
}

type Action = SetDataAction;

// Define the reducer function
const reducer = (state: FormData, action: Action): FormData => {
    switch (action.type) {
        case "SET_DATA":
            return {
                ...state,
                ...action.payload.updates,
            };
        default:
            return state;
    }
};

function ContentBlockTemplateCreationContent() {
    const { queryParams, openModal, closeModal } = useModalQueryParams(
        "ContentBlockTemplateModal"
    );

    const { contentBlockId } = queryParams;

    const { selectedSimulation } = useSelectedSimulation();

    const {
        data: { tags, contentBlock },
    } = useGetContentBlockTemplateCreationData(contentBlockId);

    const [data, dispatch] = useReducer(reducer, initialState);

    const setData = (
        keyOrUpdates: keyof FormData | Partial<FormData>,
        value?: FormData[keyof FormData]
    ) => {
        if (typeof keyOrUpdates === "string" && value !== undefined) {
            // Individual update
            dispatch({
                type: "SET_DATA",
                payload: {
                    updates: {
                        [keyOrUpdates]: value,
                    },
                },
            });
        } else {
            // Object representing updates
            dispatch({
                type: "SET_DATA",
                payload: {
                    updates: keyOrUpdates as Partial<FormData>,
                },
            });
        }
    };

    const [query, setQuery] = useState("");
    const filteredTags = useMemo(() => {
        if (!tags) return [];
        return query === ""
            ? tags || []
            : tags?.filter((tag) => {
                  return tag.name?.en
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });
    }, [query, tags]);

    const ref = useRef<HTMLInputElement>();
    const addTag = useCallback(
        (tag: Tag) => {
            if (data?.tags.find((t) => t.name.en === tag.name.en)) return;
            setData("tags", [...data?.tags, tag]);
            setQuery(() => "");
            if (ref?.current) ref.current.value = "";
        },
        [query, data]
    );

    const nestedBlocks = React.useMemo(() => {
        return nestBlocks(contentBlock?.contentBlocks || [], contentBlock);
    }, [contentBlock]);
    return (
        <div
            className="p-6 text-white"
            data-testid="content-block-template-creation-modal"
        >
            <div className={`px-6 flex justify-between`}>
                <h2 className="text-2xl font-bold">Create Template</h2>
                <span className="inline-flex items-center rounded-full bg-green-100/70 px-3 py-0.5 text-sm font-medium text-green-800">
                    {contentBlock?.title || contentBlock?.content_block_type}
                </span>
            </div>
            <div className="mt-6 py-4 px-6 border-t border-[#374151] grid grid-cols-2 gap-4">
                <div className="flex flex-col space-y-2">
                    <div className="flex flex-col space-y-2">
                        <label>Label</label>
                        <input
                            placeholder="Template Label"
                            autoFocus={true}
                            type="text"
                            className={`w-full max-w-3xl block rounded-md border-gray-300 sm:text-xs bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            value={data.label}
                            onChange={(e) => {
                                setData("label", e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label>Tags</label>
                        <Combobox
                            value={data?.tags}
                            onChange={(value) => {
                                setData("tags", value);
                            }}
                            multiple
                            nullable
                        >
                            {({ open }) => (
                                <>
                                    <div className="relative mt-1">
                                        <div className="relative flex items-center">
                                            <ul className="absolute flex right-1 z-100">
                                                {data?.tags?.map((tag) => (
                                                    <li
                                                        key={tag.name.en}
                                                        className="flex list-none bg-amber-100 text-amber-900 rounded-md px-2 py-1 mr-1"
                                                    >
                                                        <button
                                                            className="mr-1 cursor-pointer"
                                                            onClick={() => {
                                                                setData(
                                                                    "tags",
                                                                    data?.tags.filter(
                                                                        (t) =>
                                                                            t
                                                                                .name
                                                                                .en !==
                                                                            tag
                                                                                .name
                                                                                .en
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <XMarkIcon className="h-4 w-4" />
                                                        </button>
                                                        <span>
                                                            {tag.name.en}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Combobox.Input
                                                ref={ref}
                                                value={query}
                                                onChange={(e) => {
                                                    setQuery(e.target.value);
                                                }}
                                                autoComplete="off"
                                                data-testid="tag-input"
                                                className="relative h-10 w-full max-w-3xl block rounded-md border-gray-300 sm:text-xs bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200 text-sm"
                                            />
                                        </div>
                                        <Transition
                                            show={open && !!query.length}
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                        >
                                            <Combobox.Options
                                                data-testid="tag-list"
                                                className="absolute pl-0 ml-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                            >
                                                {query.length > 0 && (
                                                    <Combobox.Option
                                                        value={{
                                                            id: null,
                                                            name: {
                                                                en: query,
                                                            },
                                                        }}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative cursor-default select-none list-none py-2 pl-10 pr-4 ${
                                                                active
                                                                    ? "bg-amber-100 text-amber-900"
                                                                    : "text-gray-900"
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            addTag({
                                                                name: {
                                                                    en: query,
                                                                },
                                                                type: "content_block_template",
                                                            });
                                                        }}
                                                    >
                                                        Create "{query}"
                                                    </Combobox.Option>
                                                )}
                                                {filteredTags.map((tag) => (
                                                    <Combobox.Option
                                                        key={tag.id}
                                                        value={tag}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative cursor-default select-none py-2 list-none pl-10 pr-4 ${
                                                                active
                                                                    ? "bg-amber-100 text-amber-900"
                                                                    : "text-gray-900"
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            addTag(tag);
                                                        }}
                                                    >
                                                        {tag.name.en}
                                                    </Combobox.Option>
                                                ))}
                                            </Combobox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Combobox>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label>Description</label>
                        <textarea
                            rows={5}
                            className={`w-full max-w-3xl block rounded-md border-gray-300 sm:text-xs bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            value={data.description}
                            onChange={(e) => {
                                setData("description", e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div
                    data-testid="am-i-here"
                    className="flex flex-col space-y-2 p-4 text-white bg-black/30 rounded-md"
                >
                    <label>Preview</label>
                    {!contentBlock ? (
                        <Skeleton count={5} />
                    ) : (
                        <div>
                            <ContentBlocksToWireframe
                                contentBlock={nestedBlocks}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                <div className="flex justify-center items-center">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            //use native browser event to reload state, since we've altered the DOM manually
                            // window.location.reload();
                            closeModal();
                        }}
                        className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                    >
                        Cancel
                    </button>
                </div>
                <div className="flex justify-center items-center">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            saveTemplate(
                                data,
                                nestedBlocks,
                                selectedSimulation.id
                            ).then(({ template }) => {
                                //open template publish modal
                                openModal({
                                    modalComponent: "PublishTemplateModal",
                                    templateToPublishId: template.id,
                                });
                            });
                        }}
                        disabled={!data.label}
                        data-testid="save-template-button"
                        className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function ContentBlockTemplateCreationModal() {
    const { isAtModalUrl, closeModal } = useModalQueryParams(
        "ContentBlockTemplateModal"
    );
    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {
                closeModal();
            }}
            size="l"
            backgroundColor="#111928"
        >
            {isAtModalUrl && <ContentBlockTemplateCreationContent />}
        </ModalContainer>
    );
}
