import React, { useEffect, useMemo, useState } from "react";
import { PromptType, Round, TimeHorizon } from "@/models";
import { displayPromptType } from "@/util";

const NumericalSlider = ({
    rangeMin,
    rangeMax,
    rangeStep,
    isDisabled,
    value,
    handleOnChange,
    handleOnClick,
}: {
    rangeMin: number;
    rangeMax: number;
    rangeStep: number;
    isDisabled: boolean;
    value: number;
    handleOnChange: (value: number) => void;
    handleOnClick: (value?: number) => void;
}) => {
    return (
        <span className="mr-4 inline-flex items-center">
            <input
                className="h-1 w-full min-w-[160px]"
                // cursor-pointer appearance-none rounded-md bg-neutral-200 bg-opacity-75 accent-blue-400"
                type="range"
                min={rangeMin}
                max={rangeMax}
                step={rangeStep}
                disabled={isDisabled}
                value={value}
                onChange={(e) => {
                    e.stopPropagation();
                    handleOnChange(Number(e.target.value));
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleOnClick();
                }}
            />
        </span>
    );
};

type ArchetypeModelDemoProps = {
    rounds: Round[];
    timeHorizons: TimeHorizon[];
};

const defaultMetricObject = {
    "Score 1": 30,
    "Score 2": 20,
    "Score 3": 40,
    "Score 4": 10,
};

export const ArchetypeModelDemo: React.FC<ArchetypeModelDemoProps> = ({
    rounds,
    timeHorizons,
}) => {
    const [roundMetricObject, setRoundMetricObject] = useState({});
    const [metricObject, setMetricObject] = useState(defaultMetricObject);

    const roundsWithFilteredPrompts = useMemo(() => {
        return rounds
            .map(
                (round, i) =>
                    ({
                        ...round,
                        round_number: i + 1,
                        prompts: round.prompts.filter(
                            (prompt) =>
                                prompt.prompt_type !==
                                    PromptType["Short Text"] &&
                                prompt.prompt_type !== PromptType["Long Text"],
                        ),
                    }) as Round,
            )
            .filter((round) => round.prompts.length > 0);
    }, [rounds]);

    useEffect(() => {
        if (roundsWithFilteredPrompts?.length > 0) {
            const defaultWeight = Math.floor(
                100 / roundsWithFilteredPrompts.length,
            );
            const defaultRoundMetrics = Object.keys(metricObject).reduce(
                (map, key) => ({
                    ...map,
                    [key]: defaultWeight,
                }),
                {},
            );
            const defaultRoundMetricObject = roundsWithFilteredPrompts.reduce(
                (map, round) => ({ ...map, [round.id]: defaultRoundMetrics }),
                {},
            );
            setRoundMetricObject(defaultRoundMetricObject);
        }
    }, [roundsWithFilteredPrompts]);

    const metricTotal = useMemo(() => {
        return Object.values(metricObject).reduce(
            (total, value) => total + value,
            0,
        );
    }, [metricObject]);

    const roundMetricTotals = useMemo(() => {
        return Object.keys(metricObject).reduce(
            (map, key) => ({
                ...map,
                [key]: Object.values(roundMetricObject)?.reduce(
                    (total, rObj) => total + rObj[key],
                    0,
                ),
            }),
            {},
        );
    }, [roundMetricObject]);

    const rowCountByRoundId = useMemo(() => {
        return roundsWithFilteredPrompts.reduce(
            (map, round) => ({
                ...map,
                [round.id]: round.prompts.reduce(
                    (rowCount, prompt) =>
                        rowCount + Math.max(1, prompt.options.length) + 2,
                    0,
                ),
            }),
            {},
        );
    }, [roundsWithFilteredPrompts]);

    return (
        <div className="">
            <div className="text-lg font-semibold"></div>
            <div className="mb-12 overflow-auto text-neutral-700">
                <table className="border text-left align-top text-xs">
                    <thead className="border">
                        <tr className="border bg-neutral-100">
                            <th className="text-nowrap border px-2 py-1">
                                {"Metric"}
                            </th>
                            <th className="text-nowrap border px-2 py-1">
                                {"Metric Weight (for Overall Score)"}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="border">
                        {Object.keys(metricObject).map((key) => (
                            <tr key={key} className="border">
                                <td className="border px-2 py-1">{key}</td>
                                <td className="border px-2 py-1">
                                    <span className="flex items-center justify-end">
                                        <NumericalSlider
                                            rangeMin={0}
                                            rangeMax={100}
                                            rangeStep={1}
                                            isDisabled={false}
                                            value={metricObject[key]}
                                            handleOnChange={(value: number) => {
                                                console.log("change");
                                            }}
                                            handleOnClick={() => {
                                                console.log("click");
                                            }}
                                        />
                                        <span>{metricObject[key]}</span>
                                    </span>
                                </td>
                            </tr>
                        ))}
                        <tr className="border font-bold">
                            <td className="border bg-neutral-100 px-2 py-1 text-right">
                                {"Total"}
                            </td>
                            <td
                                className={`border px-2 py-1 text-right ${metricTotal == 100 ? "bg-lime-50" : "bg-red-50"}`}
                            >
                                {metricTotal}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-12 overflow-auto text-neutral-700">
                <table className="w-full border text-left align-top text-xs">
                    <thead className="border">
                        <tr className="border bg-neutral-100">
                            <th className="text-nowrap border px-2 py-1">
                                {"Round"}
                            </th>
                            {Object.keys(metricObject).map((key) => (
                                <th
                                    key={key}
                                    className="text-nowrap border px-2 py-1"
                                >
                                    {`Round Weight: ${key}`}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="border">
                        {!!roundMetricObject &&
                            roundsWithFilteredPrompts?.map((round) => (
                                <tr key={round.id} className="border">
                                    <td className="border px-2 py-1">
                                        {round.title}
                                    </td>
                                    {!!roundMetricObject[round.id] &&
                                        Object.keys(metricObject).map((key) => (
                                            <td
                                                key={key}
                                                className="border px-2 py-1 text-right"
                                            >
                                                <span className="flex items-center justify-end">
                                                    <NumericalSlider
                                                        rangeMin={0}
                                                        rangeMax={100}
                                                        rangeStep={1}
                                                        isDisabled={false}
                                                        value={
                                                            roundMetricObject[
                                                                round.id
                                                            ][key] ?? 0
                                                        }
                                                        handleOnChange={(
                                                            value: number,
                                                        ) =>
                                                            console.log(
                                                                "change",
                                                            )
                                                        }
                                                        handleOnClick={() => {
                                                            console.log(
                                                                "click",
                                                            );
                                                        }}
                                                    />
                                                    <span>
                                                        {roundMetricObject[
                                                            round.id
                                                        ][key] ?? 0}
                                                    </span>
                                                </span>
                                            </td>
                                        ))}
                                </tr>
                            ))}
                        <tr className="border font-bold">
                            <td className="border bg-neutral-100 px-2 py-1 text-right">
                                {"Total"}
                            </td>
                            {!!roundMetricTotals &&
                                Object.keys(metricObject).map((key) => (
                                    <td
                                        key={key}
                                        className={`border px-2 py-1 text-right ${
                                            roundMetricTotals[key] == 100
                                                ? "bg-lime-50"
                                                : "bg-red-50"
                                        }`}
                                    >
                                        {roundMetricTotals[key] ?? 0}
                                    </td>
                                ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-12 overflow-auto text-neutral-700">
                <table className="w-full border text-left align-top text-xs">
                    <thead className="border">
                        <tr className="border bg-neutral-100">
                            <th className="text-nowrap border px-2 py-1">
                                {"Round"}
                            </th>
                            <th className="text-nowrap border px-2 py-1">
                                {"Q#"}
                            </th>
                            <th className="text-nowrap border px-2 py-1">
                                {"Type"}
                            </th>
                            <th className="text-nowrap border px-2 py-1">
                                {"Scope"}
                            </th>
                            <th className="text-nowrap border px-2 py-1">
                                {"Question"}
                            </th>
                            {Object.keys(metricObject).map((key) => (
                                <th
                                    key={key}
                                    className="text-nowrap border px-2 py-1"
                                >
                                    {`Question Weight: ${key}`}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="border align-top">
                        {roundsWithFilteredPrompts?.map((round, i) => (
                            <React.Fragment key={round.id}>
                                <tr
                                    key={round.id}
                                    className="border border-t-2 border-t-neutral-400"
                                >
                                    <td
                                        className={`border bg-opacity-50 px-2 py-1 ${
                                            i % 2 == 0
                                                ? "bg-neutral-50"
                                                : "bg-neutral-100"
                                        }`}
                                        rowSpan={round.prompts.length + 2}
                                    >
                                        {round.title}
                                    </td>
                                </tr>
                                {round.prompts.map((prompt, j) => (
                                    <tr
                                        key={prompt.id}
                                        className={`${j == 0 ? "border-t-2 border-t-neutral-400" : "border"}`}
                                    >
                                        <td className="border px-2 py-1">
                                            {`${j + 1}`}
                                        </td>
                                        <td className="text-nowrap border px-2 py-1">
                                            {`${displayPromptType(prompt.prompt_type)}`}
                                        </td>
                                        <td className="border px-2 py-1">
                                            {`${prompt.scope == "Participant" ? "User" : prompt.scope}`}
                                        </td>
                                        <td className="border px-2 py-1">
                                            {prompt.content}
                                        </td>
                                        {Object.keys(metricObject).map(
                                            (key) => (
                                                <td
                                                    key={key}
                                                    className="border px-2 py-1 text-right"
                                                >
                                                    <span className="flex items-center justify-end">
                                                        <NumericalSlider
                                                            rangeMin={0}
                                                            rangeMax={100}
                                                            rangeStep={1}
                                                            isDisabled={false}
                                                            value={Math.floor(
                                                                100 /
                                                                    round
                                                                        .prompts
                                                                        .length,
                                                            )}
                                                            handleOnChange={(
                                                                value: number,
                                                            ) =>
                                                                console.log(
                                                                    "change",
                                                                )
                                                            }
                                                            handleOnClick={() => {
                                                                console.log(
                                                                    "click",
                                                                );
                                                            }}
                                                        />
                                                        <span>
                                                            {Math.floor(
                                                                100 /
                                                                    round
                                                                        .prompts
                                                                        .length,
                                                            )}
                                                        </span>
                                                    </span>
                                                </td>
                                            ),
                                        )}
                                    </tr>
                                ))}
                                <tr className="border font-bold">
                                    <td
                                        className={`border bg-opacity-50 px-2 py-1 text-right ${
                                            i % 2 == 0
                                                ? "bg-neutral-50"
                                                : "bg-neutral-100"
                                        }`}
                                        colSpan={4}
                                    >
                                        {"Total"}
                                    </td>
                                    {Object.keys(metricObject).map((key) => (
                                        <td
                                            key={key}
                                            className={`border px-2 py-1 text-right ${
                                                Math.floor(
                                                    100 / round.prompts.length,
                                                ) *
                                                    round.prompts.length ==
                                                100
                                                    ? "bg-lime-50"
                                                    : "bg-red-50"
                                            }`}
                                        >
                                            {Math.floor(
                                                100 / round.prompts.length,
                                            ) * round.prompts.length}
                                        </td>
                                    ))}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="">
                {!!roundsWithFilteredPrompts.length && (
                    <div className="mb-4 overflow-auto text-neutral-700">
                        <table className="w-full border text-left align-top text-xs">
                            <thead className="border">
                                <tr className="border bg-neutral-100">
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Round"}
                                    </th>
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Q#"}
                                    </th>
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Type"}
                                    </th>
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Scope"}
                                    </th>
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Prompt"}
                                    </th>
                                    <th className="text-nowrap border px-2 py-1">
                                        {"Option"}
                                    </th>
                                    {Object.keys(metricObject).map((key) => (
                                        <th
                                            key={key}
                                            className="text-nowrap border px-2 py-1"
                                        >
                                            {`Effect: ${key}`}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="border">
                                {roundsWithFilteredPrompts.map((round, i) => (
                                    <React.Fragment key={round.id}>
                                        {round.prompts.map((prompt, j) => (
                                            <React.Fragment key={prompt.id}>
                                                <tr
                                                    key={prompt.id}
                                                    className={`border align-top ${i === 0 && j === 0 ? "border-t-2 border-t-neutral-400" : ""}
                                                    ${j === 0 ? "border-t-2 border-t-neutral-300" : ""}`}
                                                >
                                                    {j === 0 && (
                                                        <>
                                                            <td
                                                                className="border px-2 py-1"
                                                                rowSpan={
                                                                    rowCountByRoundId[
                                                                        round.id
                                                                    ]
                                                                }
                                                            >
                                                                {`${round.title}`}
                                                            </td>
                                                        </>
                                                    )}
                                                    <td
                                                        className="border px-2 py-1"
                                                        rowSpan={
                                                            prompt.options
                                                                .length + 2
                                                        }
                                                    >
                                                        {`${j + 1}`}
                                                    </td>
                                                    <td
                                                        className="text-nowrap border px-2 py-1"
                                                        rowSpan={
                                                            prompt.options
                                                                .length + 2
                                                        }
                                                    >
                                                        {`${displayPromptType(prompt.prompt_type)}`}
                                                    </td>
                                                    <td
                                                        className="border px-2 py-1"
                                                        rowSpan={
                                                            prompt.options
                                                                .length + 2
                                                        }
                                                    >
                                                        {`${prompt.scope == "Participant" ? "User" : prompt.scope}`}
                                                    </td>
                                                    <td
                                                        className="border px-2 py-1"
                                                        rowSpan={
                                                            prompt.options
                                                                .length + 2
                                                        }
                                                    >
                                                        {`${prompt.content}`}
                                                    </td>
                                                    {/* <td className="border px-2 py-1 text-neutral-500">
                                                        {"total"}
                                                    </td> */}
                                                </tr>
                                                {prompt.options.map(
                                                    (option, k) => (
                                                        <tr
                                                            key={option.id}
                                                            className={`border align-top ${
                                                                i === 0 &&
                                                                j === 0 &&
                                                                k === 0
                                                                    ? "border-t-2 border-t-neutral-400"
                                                                    : ""
                                                            } ${
                                                                j === 0 &&
                                                                k === 0
                                                                    ? "border-t-2 border-t-neutral-300"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <td className="border px-2 py-1 text-neutral-500">
                                                                {prompt.prompt_type ===
                                                                    PromptType[
                                                                        "Numerical Input"
                                                                    ] ||
                                                                prompt.prompt_type ===
                                                                    PromptType[
                                                                        "Numerical Slider"
                                                                    ]
                                                                    ? `${prompt.min < prompt.max ? `range: ${prompt.min ?? 0} to ${prompt.max ?? 0}` : "number"}`
                                                                    : `${option.content}`}
                                                            </td>
                                                            {Object.keys(
                                                                metricObject,
                                                            ).map((key) => (
                                                                <td
                                                                    key={key}
                                                                    className="border px-2 py-1 text-right"
                                                                >
                                                                    <span className="flex items-center justify-end">
                                                                        <NumericalSlider
                                                                            rangeMin={
                                                                                0
                                                                            }
                                                                            rangeMax={
                                                                                100
                                                                            }
                                                                            rangeStep={
                                                                                1
                                                                            }
                                                                            isDisabled={
                                                                                false
                                                                            }
                                                                            value={
                                                                                0
                                                                            }
                                                                            handleOnChange={(
                                                                                value: number,
                                                                            ) =>
                                                                                console.log(
                                                                                    "change",
                                                                                )
                                                                            }
                                                                            handleOnClick={() => {
                                                                                console.log(
                                                                                    "click",
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span>
                                                                            {0}
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ),
                                                )}
                                                <tr>
                                                    <td className="border bg-neutral-100 px-2 py-1 font-bold text-neutral-500">
                                                        {"Total"}
                                                    </td>
                                                    {Object.keys(
                                                        metricObject,
                                                    ).map((key) => (
                                                        <td
                                                            key={key}
                                                            className={`border px-2 py-1 text-right ${"bg-red-50"}`}
                                                        >
                                                            {0}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};
