import React from "react";
import { ChartSeries } from "./ChartWizardReducer";
import ChartSeriesColorForm from "./ChartSeriesColorForm";

type Props = {
    chartSeries: ChartSeries;
    updateEditedSeries: (chartSeries: ChartSeries) => void;
    chartType: "pie" | "doughnut";
};

export default function ManualPieOrDonutChartEntry({
    chartSeries,
    updateEditedSeries,
    chartType,
}: Props) {
    const dataPoint = chartSeries?.chart_data?.[0];

    return (
        <div className="flex flex-col space-y-4 w-full">
            <label className="capitalize">Manual Chart Value Entry</label>
            <div className="flex flex-col space-y-4">
                <div className="flex space-x-2">
                    <div className="w-1/2">
                        <input
                            type="text"
                            className="w-full block rounded-md  sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                            value={dataPoint?.label || ""}
                            placeholder="Enter a label for this series"
                            onChange={(e) => {
                                updateEditedSeries({
                                    ...chartSeries,
                                    chartType,
                                    chart_data: [
                                        {
                                            ...dataPoint,
                                            label: e.target.value,
                                            x: 0,
                                        },
                                    ],
                                });
                            }}
                        />
                    </div>
                    <div className="w-1/2">
                        <input
                            type="number"
                            className="w-full block rounded-md  sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                            value={dataPoint?.y || ""}
                            placeholder="Enter a value for this series"
                            onChange={(e) => {
                                updateEditedSeries({
                                    ...chartSeries,
                                    chart_data: [
                                        {
                                            ...dataPoint,
                                            y: Number(e.target.value),
                                            x: 0,
                                            chartType,
                                        },
                                    ],
                                });
                            }}
                        />
                    </div>
                </div>
                <ChartSeriesColorForm
                    chartSeries={chartSeries}
                    colorProperty={"fill"}
                    index={0}
                    updateEditedSeries={updateEditedSeries}
                />
            </div>
        </div>
    );
}
