import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { SimulationShape } from "@/models";
import {
    addSimVariant,
    setSelectedVariantIndex,
    setVariantSimulation,
    updateSimVariant,
} from "@/redux-state/simVariantSlice";

export const useSimVariantStore = () => {
    const dispatch = useAppDispatch();

    const simVariants = useAppSelector(
        (state) => state.simVariantState?.simVariants
    );

    const _variantSimulation = useAppSelector(
        (state) => state.simVariantState?.variantSimulation
    );

    const _selectedVariantIndex = useAppSelector(
        (state) => state.simVariantState?.selectedVariantIndex
    );
    const getSimVariantsBySimId = (simulationId: string) =>
        useAppSelector(
            (state) => state?.simVariantState?.simVariants[simulationId]
        );

    const _addSimVariant = useCallback(
        (simulation: SimulationShape) => {
            dispatch(addSimVariant(simulation));
        },
        [simVariants]
    );

    const _setVariantSimulation = useCallback(
        (variantSimulation: SimulationShape) => {
            dispatch(setVariantSimulation(variantSimulation));
        },
        [dispatch]
    );

    const _updateVariant = useCallback(
        (variantSimulation: SimulationShape) => {
            dispatch(updateSimVariant(variantSimulation));
        },
        [dispatch]
    );
    const _setSelectedVariantIndex = useCallback(
        (index: number) => {
            dispatch(setSelectedVariantIndex(index));
        },
        [dispatch]
    );

    return {
        addSimVariant: _addSimVariant,
        getSimVariantsBySimId,
        simVariants,
        setVariantSimulation: _setVariantSimulation,
        variantSimulation: _variantSimulation,
        setSelectedVariantIndex: _setSelectedVariantIndex,
        selectedVariantIndex: _selectedVariantIndex,
        updateVariant: _updateVariant,
    };
};
