import { router } from "@inertiajs/react";
import React from "react";
import { Tenant } from "../../models";

type Props = {
    tenants: Tenant[];
};

export default function Index({ tenants }: Props) {
    function deleteTenant(tenant: Tenant) {
        router.delete(`/tenants/${tenant.id}`, {
            preserveState: false,
        });
    }

    return (
        <ul>
            {tenants.map((tenant) => (
                <li className="flex space-x-2">
                    <a href={`/tenants/${tenant.id}`}>{tenant.tenant_name}</a>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            deleteTenant(tenant);
                        }}
                    >
                        X
                    </button>
                </li>
            ))}
        </ul>
    );
}
