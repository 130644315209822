import React, { useMemo } from "react";
import { useFormatVariableValue } from "@/hooks";
import { CompactVariableDataTableRow } from "./CompactVariableDataTableRow";
import { DataTableProps } from "./props";
import { AccordionTableRow, CompactTableWrapper } from "../shared";

const CompactDataTableComponent = ({
    modelBlocks,
    modelVariables,
    valuesMap,
    showResampleFunction,
    modelBlockDisplayMap,
    setModelBlockDisplayMap,
}: DataTableProps) => {
    const formatVariableValue = useFormatVariableValue();

    const blockColSpan = useMemo(() => {
        return (
            1 +
            ((Object.keys(valuesMap)?.length > 0 &&
                Object.values(valuesMap)[0]?.length) ||
                0)
        );
    }, [valuesMap]);

    return (
        <CompactTableWrapper
            header={"variable"}
            columnLabels={
                Object.keys(valuesMap)?.length > 0
                    ? Object.values(valuesMap)[0]?.map((_, index) =>
                          (index + 1).toString(),
                      )
                    : []
            }
            alignRight={true}
        >
            {!!modelVariables &&
                modelBlocks?.length > 0 &&
                modelBlocks
                    .filter((block) => !!modelVariables[block.id])
                    .map((block) => (
                        <React.Fragment key={block.id}>
                            <AccordionTableRow
                                key={block.id}
                                modelBlockLabel={block.label}
                                handleSetModelBlockDisplayMap={() =>
                                    setModelBlockDisplayMap({
                                        ...modelBlockDisplayMap,
                                        [block.id]:
                                            !modelBlockDisplayMap[block.id],
                                    })
                                }
                                isExpanded={!!modelBlockDisplayMap[block.id]}
                                colSpan={blockColSpan}
                            />
                            {!!modelBlockDisplayMap[block.id] &&
                                modelVariables[block.id].map(
                                    (modelVariable, i) => (
                                        <CompactVariableDataTableRow
                                            key={modelVariable.id}
                                            modelVariable={modelVariable}
                                            variableValues={
                                                (Object.keys(valuesMap)
                                                    ?.length > 0 &&
                                                    valuesMap[
                                                        modelVariable.id
                                                    ]) ||
                                                []
                                            }
                                            index={i}
                                            showResampleFunction={
                                                showResampleFunction
                                            }
                                            formatVariableValue={
                                                formatVariableValue
                                            }
                                        />
                                    ),
                                )}
                        </React.Fragment>
                    ))}
        </CompactTableWrapper>
    );
};

export const CompactDataTable = React.memo(CompactDataTableComponent);
