import React from "react";
import { InteractiveCharts } from "./InteractiveCharts";
import { useInterfaceStateDesign } from "../useInterfaceState";
import { ShowHideButton } from "../shared";

const InteractiveSectionDesignComponent = ({}: {}) => {
    const { showDesignChartControls, setShowDesignChartControls } =
        useInterfaceStateDesign();

    return (
        <div className="flex w-full flex-col space-y-6">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Charts"}
                </h1>
                <ShowHideButton
                    show={showDesignChartControls}
                    setShow={setShowDesignChartControls}
                    label={"Chart Controls"}
                />
            </div>
            <div>
                <InteractiveCharts />
            </div>
        </div>
    );
};

export const InteractiveSectionDesign = React.memo(
    InteractiveSectionDesignComponent,
);
