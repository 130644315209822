import React from "react";
import { AsideContents } from "../Aside";
import { PromptType, promptTypes } from "@/models";
import { useCreatePrompt } from "../../content-block-management/useCreatePrompt";

const allowedTypes: PromptType[] = [
    PromptType["Multiple Choice"],
    PromptType["Multiple Select"],
    PromptType["Numerical Input"],
    PromptType["Numerical Slider"],
    PromptType["Rank Order"],
    PromptType["Long Text"],
];

const allowedTypeKeys = allowedTypes.map((type) => {
    return Object.keys(promptTypes).find((key) => {
        return promptTypes[key] === type;
    });
});

export default function QuestionSidebar() {
    const { mutate: saveQuestion } = useCreatePrompt();

    return (
        <AsideContents>
            <div className="flex flex-col divide-y-2">
                {allowedTypeKeys.map((key) => (
                    <button key={key} onClick={() => saveQuestion(PromptType[key])}>
                        {key}
                    </button>
                ))}
            </div>
        </AsideContents>
    );
}
