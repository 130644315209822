import React, { FC } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface SvgProps {
    svg: string;
    color: string;
    className?: string
}
const ToastSvg: FC<SvgProps> = ({ svg, color, className }) => {
    let component: JSX.Element | null = null;

    switch (svg) {
        case "eye":
            component = (
                <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.00038 0.333374C8.59145 0.333374 10.1129 1.16791 11.5307 2.53904C12.0144 3.00682 12.452 3.50622 12.8373 4.00582C13.0697 4.3073 13.2348 4.54445 13.3251 4.68574L13.5261 5.00004L13.3251 5.31434C13.2348 5.45563 13.0697 5.69278 12.8373 5.99426C12.452 6.49387 12.0144 6.99326 11.5307 7.46104C10.1129 8.83218 8.59145 9.66671 7.00038 9.66671C5.40931 9.66671 3.8879 8.83218 2.47004 7.46104C1.98633 6.99326 1.54873 6.49387 1.16349 5.99426C0.931033 5.69278 0.765989 5.45563 0.675626 5.31434L0.474609 5.00004L0.675626 4.68574C0.765989 4.54445 0.931033 4.3073 1.16349 4.00582C1.54873 3.50622 1.98633 3.00682 2.47004 2.53904C3.8879 1.16791 5.40931 0.333374 7.00038 0.333374ZM11.9134 4.71822C11.5607 4.26079 11.1596 3.80316 10.7197 3.3777C9.49851 2.19676 8.22834 1.50004 7.00038 1.50004C5.77242 1.50004 4.50225 2.19676 3.28107 3.3777C2.84112 3.80316 2.44011 4.26079 2.0874 4.71822C2.01122 4.81701 1.94083 4.91127 1.87648 5.00004C1.94083 5.08881 2.01122 5.18307 2.0874 5.28186C2.44011 5.73929 2.84112 6.19692 3.28107 6.62238C4.50225 7.80332 5.77242 8.50004 7.00038 8.50004C8.22834 8.50004 9.49851 7.80332 10.7197 6.62238C11.1596 6.19692 11.5607 5.73929 11.9134 5.28186C11.9895 5.18307 12.0599 5.08881 12.1243 5.00004C12.0599 4.91127 11.9895 4.81701 11.9134 4.71822ZM4.66705 5.00004C4.66705 6.2887 5.71172 7.33337 7.00038 7.33337C8.28904 7.33337 9.33371 6.2887 9.33371 5.00004C9.33371 3.71138 8.28904 2.66671 7.00038 2.66671C5.71172 2.66671 4.66705 3.71138 4.66705 5.00004ZM8.16705 5.00004C8.16705 5.64437 7.64471 6.16671 7.00038 6.16671C6.35605 6.16671 5.83371 5.64437 5.83371 5.00004C5.83371 4.35571 6.35605 3.83337 7.00038 3.83337C7.64471 3.83337 8.16705 4.35571 8.16705 5.00004Z"
                        fill={color}
                    />
                </svg>
            );
            break;
        case "notification":
            component = (
                <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.666992 7.49999C0.666992 11.5501 3.95024 14.8333 8.00033 14.8333C12.0504 14.8333 15.3337 11.5501 15.3337 7.49999C15.3337 3.4499 12.0504 0.166656 8.00033 0.166656C3.95024 0.166656 0.666992 3.4499 0.666992 7.49999ZM14.0003 7.49999C14.0003 10.8137 11.314 13.5 8.00033 13.5C4.68662 13.5 2.00033 10.8137 2.00033 7.49999C2.00033 4.18628 4.68662 1.49999 8.00033 1.49999C11.314 1.49999 14.0003 4.18628 14.0003 7.49999ZM8.00055 11.4989C8.36886 11.4989 8.66743 11.2004 8.66743 10.8322C8.66743 10.464 8.36886 10.1656 8.00055 10.1656C7.63223 10.1656 7.33366 10.464 7.33366 10.8322C7.33366 11.2004 7.63223 11.4989 8.00055 11.4989ZM8.6694 3.49889H7.33563V8.83222H8.6694V3.49889Z"
                        fill={color}
                        className={className}

                    />
                </svg>
            );
            break;
        case "bell":
            component = (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.25 7.5C14.25 4.45562 12.5712 2.34262 10.399 1.70828C10.3204 1.50477 10.1961 1.31558 10.0271 1.15682C9.4498 0.614392 8.5502 0.614392 7.97289 1.15682C7.80429 1.31523 7.68016 1.50394 7.60148 1.70694C5.42651 2.33852 3.75 4.44689 3.75 7.5C3.75 9.47542 3.35174 10.5823 2.61447 11.3845C2.49122 11.5186 2.15732 11.8258 1.93109 12.0339L1.93101 12.0339L1.931 12.034L1.93098 12.034C1.82395 12.1324 1.74104 12.2087 1.71597 12.234L1.5 12.453V15.0106H6.06598C6.22038 15.8071 6.65257 16.34 7.43415 16.817C8.39561 17.4037 9.60439 17.4037 10.5659 16.817C11.2556 16.396 11.7181 15.7491 11.9057 15.0106H16.5V12.453L16.284 12.234C16.2613 12.2109 16.1817 12.1375 16.0778 12.0416C15.8522 11.8335 15.5117 11.5194 15.3865 11.3827C14.6488 10.5768 14.25 9.46848 14.25 7.5ZM10.3025 15.0106H7.63396C7.73993 15.2077 7.92053 15.3565 8.21551 15.5365C8.6972 15.8305 9.3028 15.8305 9.78449 15.5365C10.0075 15.4004 10.1818 15.2203 10.3025 15.0106ZM14.2801 12.3956C14.438 12.568 14.7762 12.8831 15 13.0889V13.5106H3V13.0893C3.22205 12.8858 3.56133 12.5709 3.71886 12.3995C4.72067 11.3096 5.25 9.83835 5.25 7.5C5.25 4.66492 6.99958 3.00929 9 3.00929C10.9948 3.00929 12.75 4.67347 12.75 7.5C12.75 9.83085 13.2795 11.3025 14.2801 12.3956ZM9.00025 11.9988C9.4146 11.9988 9.75049 11.663 9.75049 11.2488C9.75049 10.8345 9.4146 10.4988 9.00025 10.4988C8.5859 10.4988 8.25 10.8345 8.25 11.2488C8.25 11.663 8.5859 11.9988 9.00025 11.9988ZM9.75271 5.24876H8.25222V9.74876H9.75271V5.24876Z"
                        fill={color}
                        className={className}

                    />
                </svg>
            );
            break;
        case "error":
            component = (
                <ExclamationTriangleIcon className={`w-12 ${className}`} color={color} />
            );
            break;
    }
    return component;
};

export default ToastSvg;
