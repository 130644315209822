import React from "react";
import styled from "styled-components";

type HasButtonProps = {
    onClick?: (_) => any | void;
    children: JSX.Element;
    hoverColor: string;
};

type NoButtonProps = {
    children: JSX.Element;
    hoverColor: string;
};

type Props = NoButtonProps | HasButtonProps;

const ButtonWrapper = styled.li<{ hoverColor: string }>`
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
    :hover {
        color: white !important;
        background-color: ${({ hoverColor }) => hoverColor} !important;
        * {
            color: white !important;
            background-color: ${({ hoverColor }) => hoverColor} !important;
        }
    }

    * {
        --tw-text-opacity: 1;
        color: rgb(17 24 39 / var(--tw-text-opacity));
        :hover {
            color: white !important;
            background-color: ${({ hoverColor }) => hoverColor} !important;
        }
    }
`;

export default function ContentBlockButtonContainer(props: Props) {
    const { children } = props;

    const hoverColor = props?.hoverColor;
    const onClick = (props as HasButtonProps)?.onClick;
    const hasButton = !!onClick;

    return (
        <ButtonWrapper
            hoverColor={hoverColor}
            className={`flex text-gray-900 min-w-[1.5rem] items-center justify-center rounded-md hover:cursor-pointer hover:${hoverColor} hover:text-white transition-all`}
        >
            {hasButton ? (
                <button className={`rounded-md p-1.5`} onClick={onClick}>
                    {children}
                </button>
            ) : (
                children
            )}
        </ButtonWrapper>
    );
}
