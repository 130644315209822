import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function Droppable({
    children,
    id,
    weight,
    className,
    containerId,
    overStyles,
}: {
    children: JSX.Element | JSX.Element[];
    id: string;
    weight: number;
    className: string;
    containerId?: string;
    overStyles?: React.CSSProperties;
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: id,
        data: { weight, containerId },
    });

    const style = isOver
        ? overStyles || {
              color: isOver ? "green" : undefined,
          }
        : {};

    return (
        <div ref={setNodeRef} style={style} className={className}>
            {children}
        </div>
    );
}
