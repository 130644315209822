import React from "react";
import { DiscoveryMenuButton } from "./DiscoveryMenuButton";

export const DiscoveryMenuHeader = ({
    isDropdownMenuOpen,
    setIsDropdownMenuOpen,
    dropdownMenuSection,
    setDropdownMenuSection,
    toolbarHeight,
}: {
    isDropdownMenuOpen: boolean;
    setIsDropdownMenuOpen: (isDropdownMenuOpen: boolean) => void;
    dropdownMenuSection: string;
    setDropdownMenuSection: (dropdownMenuSection: string) => void;
    toolbarHeight: number;
}) => {
    return (
        <div
            className="discovery-middle flex flex-row justify-center items-center px-3 border-b border-[#c3c3c3] border-box text-sm cursor-pointer hover:bg-neutral-50"
            style={{
                height: `${toolbarHeight}px`,
            }}
            onClick={(e) => {
                e.stopPropagation();
                setIsDropdownMenuOpen(!isDropdownMenuOpen);
            }}
        >
            <DiscoveryMenuButton
                label={`Source Filters`}
                isSelected={
                    dropdownMenuSection == "source" && isDropdownMenuOpen
                }
                handleClick={() => {
                    if (dropdownMenuSection !== "source") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("source");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
            <DiscoveryMenuButton
                label={`Data Filters`}
                isSelected={dropdownMenuSection == "data" && isDropdownMenuOpen}
                handleClick={() => {
                    if (dropdownMenuSection !== "data") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("data");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
            <DiscoveryMenuButton
                label={`Display Options`}
                isSelected={
                    dropdownMenuSection == "display" && isDropdownMenuOpen
                }
                handleClick={() => {
                    if (dropdownMenuSection !== "display") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("display");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
        </div>
    );
};
