import React from "react";
import Authenticated from "../../Layouts/Authenticated";
import { Head, Link as InertiaLink } from "@inertiajs/react";
import { Round, Simulation, Team, TeamUserRoundPivot } from "../../models";
import { SapienParticipantPageProps } from "../../inertia-utils/types";
import route from "ziggy-js";
import {
    ArrowRightIcon,
    Square3Stack3DIcon,
    StarIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";

type Props = {
    teams: Team[];
    currentTeamUserRound: TeamUserRoundPivot & { round: Round };
} & SapienParticipantPageProps;

function getSimRoute(simulation: Simulation) {
    return route("sim-content", {
        simulationSlug: simulation.slug,
    }) as unknown as string;
}

export default function Dashboard({
    auth,
    teams,
    errors,
    impersonating_user,
    currentTeamUserRound,
}: Props) {
    return (
        <Authenticated
            auth={auth}
            errors={errors}
            impersonatingUser={impersonating_user}
        >
            <>
                <Head title="Dashboard" />
                <div className="mx-auto my-0 w-full max-w-6xl">
                    <div className="p-8">
                        <div>
                            <h2 className="pb-6 text-xl font-semibold leading-tight text-gray-800">
                                Simulations
                            </h2>
                            <div className="">
                                <ul
                                    role="list"
                                    className="grid grid-cols-1 gap-6 p-0 lg:grid-cols-2"
                                >
                                    {teams
                                        .filter((team) => !!team.simulation)
                                        .map((team) => (
                                            <li
                                                key={team.id}
                                                className="col-span-1 list-none rounded-md border border-gray-100 bg-white px-6 py-4"
                                            >
                                                <div className="flex flex-row items-center justify-between">
                                                    <div
                                                        className={`inline-flex items-center rounded-md border border-indigo-600 px-2 py-0.5 text-xs
                                                            font-medium text-indigo-600`}
                                                    >
                                                        <span>
                                                            <Square3Stack3DIcon className="mr-1.5 h-3 w-3" />
                                                        </span>
                                                        <span>{`${
                                                            team?.simulation
                                                                ?.rounds_count
                                                        } ${
                                                            team.simulation
                                                                ?.rounds_count ===
                                                            1
                                                                ? "round"
                                                                : "rounds"
                                                        }`}</span>
                                                    </div>
                                                    <div>
                                                        {!!currentTeamUserRound &&
                                                        currentTeamUserRound.team_id ===
                                                            team?.id ? (
                                                            <span>
                                                                <SolidStarIcon className="h-5 w-5 text-indigo-600" />
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <StarIcon className="h-5 w-5 text-indigo-200" />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center justify-between">
                                                    <div>
                                                        <h3 className="mt-2 flex items-center font-bold text-gray-800">
                                                            {
                                                                team?.simulation
                                                                    ?.title
                                                            }{" "}
                                                        </h3>
                                                        <div className="mt-4 text-sm font-medium text-gray-600">
                                                            {team?.name}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {
                                                                team?.cohort
                                                                    ?.title
                                                            }
                                                        </div>
                                                    </div>
                                                    {!!team?.simulation && (
                                                        <div>
                                                            <InertiaLink
                                                                href={
                                                                    // to do: fix issue with team user round
                                                                    !!currentTeamUserRound &&
                                                                    currentTeamUserRound.team_id ===
                                                                        team.id
                                                                        ? getSimRoute(
                                                                              team.simulation,
                                                                          )
                                                                        : undefined
                                                                }
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center rounded-full bg-indigo-600 px-3.5 py-2 text-center
                                                                        text-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-0
                                                                        disabled:bg-indigo-700"
                                                                    disabled={
                                                                        !!currentTeamUserRound &&
                                                                        currentTeamUserRound.team_id !==
                                                                            team.id
                                                                    }
                                                                >
                                                                    <span>
                                                                        PLAY
                                                                    </span>
                                                                    <span>
                                                                        <ArrowRightIcon className="ml-1.5 h-4 w-4" />
                                                                    </span>
                                                                </button>
                                                            </InertiaLink>
                                                        </div>
                                                    )}
                                                </div>
                                                {!!currentTeamUserRound &&
                                                    currentTeamUserRound.team_id ===
                                                        team?.id && (
                                                        <div className="mt-3 border-t border-gray-200 pt-3 text-sm">
                                                            {`Current Round: ${currentTeamUserRound.round.title}`}
                                                        </div>
                                                    )}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Authenticated>
    );
}
