import React, { useState } from "react";
import { PieChart } from "lucide-react";
import { CreateChartSidebarWrapper } from "../content-block-editing/sidebars/charts/CreateChartSidebar";

type Props = {
    roundId: string;
    weight: number;
};

export default function CreateChartButton({ roundId, weight }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <button
            onClick={() => setIsOpen(true)}
            className="flex h-16 flex-col items-center justify-center rounded-md p-2 px-4
                transition-colors hover:bg-slate-200 disabled:cursor-not-allowed
                disabled:bg-slate-200 disabled:text-slate-700"
        >
            <PieChart className="h-6 w-6" />
            <p>Chart</p>
            <CreateChartSidebarWrapper
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                roundId={roundId}
                weight={weight}
            />
        </button>
    );
}
