import ApplicationLogo from "@/components/ApplicationLogo";
import { Head } from "@inertiajs/react";
import React from "react";

type Props = { subdomain: string; home_link: string };

export default function index({ subdomain, home_link }: Props) {
    return (
        <div className="min-h-screen flex min-h-[100vh] flex-col items-center bg-white pt-6 sm:justify-center sm:pt-0">
            <Head title="No Tenant Found" />
            <div className="group flex h-[90vh] w-full max-w-fit flex-col items-center justify-center space-y-6">
                <div className="w-48">
                    <a
                        href="https://sapienexperience.com"
                        className="block drop-shadow-none saturate-150 transition-all group-hover:saturate-100"
                    >
                        <ApplicationLogo />
                    </a>
                </div>
                <div className="flex max-w-fit flex-col space-y-4 rounded-lg bg-gray-50 px-4 py-6 shadow">
                    {!!subdomain ? (
                        <h1 className="text-center text-lg text-[#3f5974]">
                            It doesn't look like anyone is building experiences
                            at <span className="capitalize">{subdomain}</span>{" "}
                            yet.
                        </h1>
                    ) : (
                        <h1 className="text-center text-xl font-bold text-[#3f5974]">
                            It doesn't look like anyone is here yet.
                        </h1>
                    )}

                    <div className="flex w-full self-stretch sm:flex-col sm:space-x-0 sm:space-y-2 md:space-x-4 md:space-y-0 md:flex-row">
                        <a
                            href="https://sapienexperience.com"
                            className="w-full max-w-fit text-[#3f5974cc] underline transition-all hover:text-[#3f5974] sm:text-center lg:text-left"
                        >
                            Learn More about Sapien
                        </a>
                        <a
                            href={home_link}
                            className="w-full max-w-fit text-[#3f5974cc] underline transition-all hover:text-[#3f5974] sm:text-center lg:text-left"
                        >
                            Log in to another tenant
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
