import React from "react";

const ButtonGroupMenuComponent = ({
    selectedIndex,
    setSelectedIndex,
    buttonItems,
    isPrimary,
}: {
    selectedIndex: number;
    setSelectedIndex: (selectedIndex: number) => void;
    buttonItems: string[];
    isPrimary?: boolean;
}) => {
    return (
        <div
            className={`inline-flex space-x-1 rounded-full bg-slate-100 p-1 transition-all
            dark:bg-slate-800/50 ${isPrimary ? "font-semibold" : ""}`}
        >
            {!!isPrimary &&
                buttonItems?.map((item, index) => (
                    <button
                        key={item}
                        onClick={() => setSelectedIndex(index)}
                        className={`rounded-full px-2 py-1 text-xs text-slate-500 transition-all
                            data-[state=selected]:bg-slate-200 data-[state=selected]:text-blue-700
                            hover:bg-slate-200 focus:outline-none dark:text-slate-500
                            data-[state=selected]:dark:bg-slate-700/25
                            data-[state=selected]:dark:text-blue-700 dark:hover:bg-slate-700/50`}
                        data-state={
                            selectedIndex === index ? "selected" : "not"
                        }
                    >
                        {item}
                    </button>
                ))}
            {!isPrimary &&
                buttonItems?.map((item, index) => (
                    <button
                        key={item}
                        onClick={() => setSelectedIndex(index)}
                        className={`rounded-full px-2 py-1 text-xs text-slate-500 transition-all
                            data-[state=selected]:bg-slate-200 data-[state=selected]:text-slate-700
                            hover:bg-slate-200 focus:outline-none dark:text-slate-500
                            data-[state=selected]:dark:bg-slate-700/25
                            data-[state=selected]:dark:text-slate-300 dark:hover:bg-slate-700/50`}
                        data-state={
                            selectedIndex === index ? "selected" : "not"
                        }
                    >
                        {item}
                    </button>
                ))}
        </div>
    );
};

export const ButtonGroupMenu = React.memo(ButtonGroupMenuComponent);
