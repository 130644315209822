import React, { useEffect, useState } from "react";
import {
    CurrencyType,
    ModelVariableScope,
    Simulation,
    defaultSimConfig,
} from "@/models";
import { FormContainer, FormFieldMap, FormFieldType } from "@/forms";
import FileSelector from "@/Pages/Admin/editor/uploads/FileSelector";

interface SimulationFormProps {
    simulation: Simulation;
    saveSimulation: (simulation: Simulation) => Promise<void> | void;
    cancel: () => void;
}

const simulationFormObjectProps = {
    title: {
        formFieldType: FormFieldType.Input,
        label: "Title",
        validationRules: { required: true },
        hours: 0,
        minutes: 0,
    },
};

function secondsToDuration(sec: number): string {
    const roundedSeconds = Math.round(sec / 900) * 900; // 900 seconds = 15 minutes
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds - hours * 3600) / 60);

    let duration = "";

    // if (hours > 0) {
    duration += hours + " hour" + (hours > 1 ? "s" : "") + " - ";
    // }

    // if (minutes > 0 || duration === "") {
    // always display minutes if no hours are present
    duration +=
        minutes + " minute" + (minutes !== 15 && minutes > 1 ? "s" : "") + " ";
    // }

    return duration.trim();
}

function hoursAndMinutesToSeconds(hours: number, minutes: number): number {
    return hours * 3600 + minutes * 60;
}

export const SimulationForm = ({
    simulation,
    saveSimulation,
    cancel,
}: SimulationFormProps) => {
    const [isBusy, setIsBusy] = useState(false);

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [simConfig, setSimConfig] = useState(
        simulation?.config
            ? { ...defaultSimConfig, ...simulation.config }
            : defaultSimConfig,
    );

    useEffect(() => {
        if (simulation?.estimated_time) {
            const duration = secondsToDuration(simulation.estimated_time);
            const [hours, minutes] = duration.split(" - ");
            setHours(parseInt(hours));
            setMinutes(parseInt(minutes));
        }
    }, [simulation]);

    return (
        <div>
            <FormContainer
                defaultValues={simulation}
                onSubmit={async (simFormData: Partial<Simulation>) => {
                    setIsBusy(true);

                    const simToSave = {
                        ...simulation,
                        ...simFormData,
                        estimated_time: hoursAndMinutesToSeconds(
                            hours,
                            minutes,
                        ),
                        config: simConfig,
                    } as Simulation;

                    await saveSimulation(simToSave);
                    setIsBusy(false);
                    cancel();
                }}
            >
                <FormFieldMap
                    formObject={simulation}
                    formObjectProps={simulationFormObjectProps}
                />
                <div className="rounded-md border border-gray-900/10 p-4 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Estimated time
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        The approximate time it will take to play this
                        simulation, to the nearest 15 minutes
                    </p>

                    <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Hours (optional)
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="first-name"
                                    value={hours}
                                    onChange={(e) => {
                                        setHours(parseInt(e.target.value));
                                    }}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                                        ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                        focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Minutes (optional)
                            </label>
                            <div className="mt-2">
                                <input
                                    value={minutes}
                                    onChange={(e) => {
                                        setMinutes(parseInt(e.target.value));
                                    }}
                                    type="number"
                                    name="minutes"
                                    autoComplete="minutes"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                                        ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                        focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <FileSelector imageUrl={simulation?.theme?.image_url} />
                </div>

                <div className="mb-6 grid grid-cols-3 gap-x-6">
                    <div className="">
                        <label
                            htmlFor="prompt_scope"
                            className="mr-4 block text-sm font-medium text-gray-700"
                        >
                            Default Prompt Scope
                        </label>
                        <select
                            onChange={(e) =>
                                setSimConfig({
                                    ...simConfig,
                                    defaultPromptScope: e.target.value as
                                        | "Participant"
                                        | "Team",
                                })
                            }
                            value={simConfig.defaultPromptScope}
                            id="prompt_scope"
                            name="prompt_scope"
                            className="relative mt-1 block w-full rounded-md border-gray-300 bg-transparent focus:z-10
                                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value={""} disabled>
                                Select
                            </option>
                            {["Participant", "Team"].map((scope) => (
                                <option value={scope} key={scope}>
                                    {scope}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="">
                        <label
                            htmlFor="variable_scope"
                            className="mr-4 block text-sm font-medium text-gray-700"
                        >
                            Default Model Variable Scope
                        </label>
                        <select
                            onChange={(e) =>
                                setSimConfig({
                                    ...simConfig,
                                    defaultModelVariableScope: e.target
                                        .value as ModelVariableScope,
                                })
                            }
                            value={simConfig.defaultModelVariableScope}
                            id="variable_scope"
                            name="variable_scope"
                            className="relative mt-1 block w-full rounded-md border-gray-300 bg-transparent focus:z-10
                                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value={""} disabled>
                                Select
                            </option>
                            {Object.keys(ModelVariableScope)
                                .filter((key) => key == "User" || key == "Team")
                                .map((key) => (
                                    <option
                                        value={ModelVariableScope[key]}
                                        key={key}
                                    >
                                        {key == "User" ? "Participant" : key}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="">
                        <label
                            htmlFor="currency"
                            className="mr-4 block text-sm font-medium text-gray-700"
                        >
                            Currency
                        </label>
                        <select
                            onChange={(e) =>
                                setSimConfig({
                                    ...simConfig,
                                    currency: e.target.value as CurrencyType,
                                })
                            }
                            value={simConfig.currency}
                            id="currency"
                            name="currency"
                            className="relative mt-1 block w-full rounded-md border-gray-300 bg-transparent focus:z-10
                                focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value={""} disabled>
                                Select
                            </option>
                            {Object.keys(CurrencyType).map((key) => (
                                <option value={CurrencyType[key]} key={key}>
                                    {key}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* <div className="py-4 px-2 border border-gray-200 mb-4 sm:overflow-hidden sm:rounded-md space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                        Configuration
                    </label>
                    <FormFieldMap
                        formObject={
                            simulation?.config || { allowSelfAdvance: true }
                        }
                        formObjectProps={configFormObjectProps}
                    />
                </div> */}
                <button
                    type="submit"
                    value="Submit"
                    className="mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white
                        hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                    disabled={isBusy}
                >
                    Save
                </button>
                <button
                    type="button"
                    className="mb-2 mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium
                        text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4
                        focus:ring-red-300"
                    disabled={isBusy}
                    onClick={(e) => {
                        e.preventDefault();
                        cancel();
                    }}
                >
                    Cancel
                </button>
            </FormContainer>
        </div>
    );
};
