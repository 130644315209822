import { ContentBlockShape, ContentBlockType } from "@/models";

export function createAccordionItem(round_id: string, weight: number) {
    return {
        content_block_type: ContentBlockType.AccordionItem,
        round_id,
        theme: {
            tailwindClasses: [
                "flex",
                "flex-wrap",
                "flex-col",
                "justify-center",
                "items-start",
                "gap-8",
                "px-6",
                "py-8"
            ],
            variant: "accordion"
        },
        weight,
        content: `Item ${weight + 1}`,
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Rich Text"],
                round_id,
                theme: {
                    tailwindClasses: ["w-full"],
                },
                weight: 0,
                rich_text: {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                lineHeight: "100%",
                                indent: 0,
                                textAlign: "left",
                                typographyType: "PARAGRAPH",
                                default: null,
                            },
                            content: [
                                {
                                    type: "text",
                                    marks: [
                                        {
                                            type: "bold",
                                        },
                                        {
                                            type: "textStyle",
                                        },
                                    ],
                                    text: `Item ${weight + 1}`,
                                },
                            ],
                        },
                    ],
                },
            },
            {
                content_block_type: ContentBlockType.Image,
                round_id,
                theme: {
                    tailwindClasses: ["w-full"],
                },
                weight: 1,
                image_url:
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
            }
        ],
    };
}

function createAccordionBlock(round_id: string): ContentBlockShape {
    return {
        content_block_type: ContentBlockType.Accordion,
        round_id,
        theme: {
            accordionType: "single",
            tailwindClasses: ["w-full"],
        },
        contentBlocks: [
            createAccordionItem(round_id, 0) as ContentBlockShape,
            createAccordionItem(round_id, 1) as ContentBlockShape,
            createAccordionItem(round_id, 2) as ContentBlockShape,
        ],
    };
}

export function createAccordion(round_id: string, weight: number) {
    return {
        content_block_type: ContentBlockType.Section,
        round_id,
        theme: {
            tailwindClasses: [
                "flex",
                "flex-wrap",
                "flex-row",
                "justify-center",
                "items-center",
                "gap-4",
                "p-4",
            ],
        },
        weight,
        contentBlocks: [createAccordionBlock(round_id)],
    };
}
