import React from "react";
import { ModalContainer } from "@/modules/shared";
import { useForm } from "@inertiajs/react";
import { ContentBlockType } from "@/models";
import {
    useFindContentBlockMethods,
    useGotContentBlock,
    useModalQueryParams,
    useSelectedRoundId,
    useSelectedSimulation,
} from "@/hooks";
import { buildCarousel } from "@/model-configs/content-block-layouts/CarouselLayout";
import { ContentBlockConfig, getConfigByType } from "@/model-configs";
import { CarouselConfig } from "@/model-configs/content-block-configs/CarouselConfig";
import { LayoutConfigObject } from "@/model-configs/LayoutConfigObject";
import { createContentBlock } from "@/Pages/Admin/editor/content-block-buttons/create-content-block-button/createContentBlock";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";

function buildBlock(type: ContentBlockType) {
    const config = getConfigByType(type);
    if (!config) return null;
    const layout = LayoutConfigObject[type];
    if (!layout) {
        const blockConfig = config as ContentBlockConfig;
        return {
            content_block_type: type,
            theme: blockConfig.defaultTheme,
        };
    }

    return layout?.layoutContentBlock;
}

function CarouselModalContent() {
    const { closeModal, queryParams } = useModalQueryParams("CarouselModal");
    const { getContentBlockWithChildren } = useFindContentBlockMethods();
    const { selectedSimulation } = useSelectedSimulation();
    const { data, setData } = useForm({
        numberOfChildBlocksToCreate: 3,
        contentBlockType: ContentBlockType.Section,
    });
    const { selectedRoundId: round_id } = useSelectedRoundId();
    const { gotContentBlock } = useGotContentBlock();
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    return (
        <form
            data-testid="carousel-modal-form"
            className="py-6 text-white"
            onSubmit={async (e) => {
                e.preventDefault();

                const contentBlocks = [
                    ...Array(data?.numberOfChildBlocksToCreate),
                ].map((_, i) => {
                    const block = buildBlock(data?.contentBlockType);
                    block.weight = i;
                    round_id && (block.round_id = round_id);
                    return block;
                });

                const carousel = buildCarousel(contentBlocks);
                carousel.parent_content_block_id =
                    queryParams?.parentContentBlockId;
                carousel.round_id = round_id;
                const parentBlock = getContentBlockWithChildren(
                    queryParams.parentContentBlockId,
                );
                carousel.weight = parentBlock?.contentBlocks?.length || 0;

                const { contentBlock } = await createContentBlock({
                    body: {
                        ...carousel,
                        position: "after",
                    } as (typeof LaravelRequestBodyShapes)["creator.design.store"],
                    selectedSimulation,
                });

                gotContentBlock(contentBlock);
                setActiveContentBlock(contentBlock.id);
                closeModal();
            }}
        >
            <div className={`flex flex-col space-y-4 px-6`}>
                <div className="flex flex-col justify-between space-y-2">
                    <label>
                        What kind of content blocks would you like to fill your
                        carousel?
                    </label>
                    <div className="flex space-x-2">
                        <div className="w-full">
                            <select
                                onChange={(e) => {
                                    setData(
                                        "contentBlockType",
                                        e.target.value as ContentBlockType,
                                    );
                                }}
                                value={
                                    data?.contentBlockType ||
                                    ContentBlockType.Section
                                }
                                className="relative block w-full rounded-md border-gray-300 text-gray-700 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                {CarouselConfig?.addableChildBlocks?.map(
                                    (type) => (
                                        <option
                                            className="bg-[#29303e] capitalize text-white"
                                            key={type}
                                            value={type}
                                        >
                                            {type.toLowerCase()}
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className="flex flex-col justify-between space-y-2">
                    <label>How many cards would you like to create?</label>
                    <div className="flex space-x-2">
                        <div className="relative mt-1 flex h-10 w-full flex-row rounded-lg bg-transparent">
                            <button
                                disabled={
                                    Number(data?.numberOfChildBlocksToCreate) <=
                                    1
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        Number(
                                            data?.numberOfChildBlocksToCreate,
                                        ) <= 1
                                    )
                                        return;
                                    setData(
                                        "numberOfChildBlocksToCreate",
                                        Number(
                                            data?.numberOfChildBlocksToCreate,
                                        ) - 1,
                                    );
                                }}
                                data-action="decrement"
                                className=" text-md md:text-basecursor-default flex w-12 items-center rounded-l border-gray-300 bg-white/10 text-center font-semibold text-white focus:outline-[#1d4ed8] sm:text-sm"
                            >
                                <span className="m-auto text-2xl font-thin">
                                    −
                                </span>
                            </button>
                            <input
                                type="number"
                                className="text-md md:text-basecursor-default flex w-24 items-center border-gray-300 bg-white/10 text-center font-semibold text-white focus:outline-[#1d4ed8] sm:text-sm"
                                name="custom-input-number"
                                value={data?.numberOfChildBlocksToCreate}
                                min={1}
                                max={9}
                            />
                            <button
                                disabled={
                                    Number(data?.numberOfChildBlocksToCreate) >=
                                    9
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        Number(
                                            data?.numberOfChildBlocksToCreate,
                                        ) >= 9
                                    )
                                        return;
                                    setData(
                                        "numberOfChildBlocksToCreate",
                                        Number(
                                            data?.numberOfChildBlocksToCreate,
                                        ) + 1,
                                    );
                                }}
                                data-action="increment"
                                className="text-md md:text-basecursor-default flex w-12 items-center rounded-r border-gray-300 bg-white/10 text-center font-semibold text-white focus:outline-[#1d4ed8] sm:text-sm"
                            >
                                <span className="m-auto text-2xl font-thin">
                                    +
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                    <div className="flex items-center justify-center">
                        <button
                            data-testid="card-deck-modal-cancel-button"
                            onClick={closeModal}
                            className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            data-testid="card-deck-modal-save-button"
                            type="submit"
                            className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default function CardDeckModal({
    currentPageId,
}: {
    currentPageId?: string;
}) {
    const { closeModal, isAtModalUrl: isOpen } =
        useModalQueryParams("CarouselModal");

    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={closeModal}
            size="xl"
            backgroundColor="#111928"
            classNames="transform-y-1/2"
        >
            {isOpen && <CarouselModalContent />}
        </ModalContainer>
    );
}
