import { useEffect } from "react";
import { activeContentBlockAtom } from "./useGetActiveContentBlock";
import { ContentBlockShape } from "@/models/ContentBlock";
import { useAtom } from "jotai";
import { useGotContentBlock } from "../useContentBlockStore";

export function useHandleSavedContentBlock(
    savedBlock: ContentBlockShape | undefined
) {
    const [activeContentBlock, setActiveContentBlock] = useAtom(
        activeContentBlockAtom
    );
    const { gotContentBlock } = useGotContentBlock();
    useEffect(() => {
        if (!savedBlock) return;
        gotContentBlock(savedBlock);
        if (
            !activeContentBlock ||
            !activeContentBlock.id ||
            activeContentBlock.id !== savedBlock.id
        ) {
            setTimeout(() => {
                setActiveContentBlock(savedBlock);
            }, 1);
            return;
        }
        setActiveContentBlock(false);
    }, [savedBlock]);
}
