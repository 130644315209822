import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockTemplateShape } from "@/models";
import axios from "axios";

export async function getTemplateById(templateId: string) {
    const route = sapienRoute("creator.design.templates.show", {
        template: templateId,
    });

    const { data } = await axios.get<{ template: ContentBlockTemplateShape }>(
        route
    );

    return data?.template;
}
