import React from "react";
import { arrowLeft } from "react-icons-kit/feather/arrowLeft";
import { AdminContainer } from "@/Layouts/admin";
import { Subtitle2Span, H4Span, H4ThinSpan } from "@/components";
import { AdminPageSection } from "@/components/admin-components";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation, VariableRelationship } from "@/models";
import { Link } from "@inertiajs/react";
import Icon from "react-icons-kit";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = SapienPageProps & {
    variableRelationships: VariableRelationship[];
    simulation: Simulation;
};

export default function ModelVariableDetail({
    variableRelationships,
    simulation,
}: Props) {
    return (
        simulation !== undefined &&
        variableRelationships && (
            <AdminContainer>
                <AdminPageSection backgroundColor="#F3F4F6">
                    <div className="flex flex-row items-center text-[#6B7280]">
                        <Link
                            href={sapienRoute("admin.projects.model-builder", {
                                simulationSlug: simulation.slug,
                            })}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Icon
                                icon={arrowLeft}
                                size={14}
                                style={{
                                    display: "flex",
                                }}
                            />
                            <Subtitle2Span
                                style={{
                                    marginLeft: "0.25rem",
                                }}
                            >
                                Back to Model Builder
                            </Subtitle2Span>
                        </Link>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <H4Span>{simulation.title}</H4Span>
                        </div>
                        <div>
                            <H4ThinSpan>
                                {
                                    variableRelationships[0]?.sourceVariable
                                        ?.label
                                }
                            </H4ThinSpan>
                        </div>
                    </div>
                </AdminPageSection>
                <AdminPageSection>
                    <div
                        className="mb-2 flex w-full flex-wrap justify-between py-2"
                        style={{
                            borderBottom: "1px solid #6B7280",
                        }}
                    >
                        <pre>
                            {JSON.stringify(variableRelationships, null, 4)}
                        </pre>
                        {/* <NestedDisplay
                            modelVariable={modelVariable}
                            isMainVariable={true}
                        /> */}
                    </div>
                </AdminPageSection>
            </AdminContainer>
        )
    );
}

// const NestedDisplay = ({
//     modelVariable,
//     isMainVariable,
// }: {
//     modelVariable: ModelVariable;
//     isMainVariable?: boolean;
// }) => {
//     return (
//         <div className="ml-0">
//             <div className="pl-3 border-l">
//                 {modelVariable?.targetVariables?.map((variable) => (
//                     <NestedDisplay modelVariable={variable} />
//                 ))}
//             </div>
//             {(!isMainVariable ||
//                 modelVariable?.targetVariables?.length === 0) && (
//                 <div className="">{modelVariable.label}</div>
//             )}
//             <div className="pl-3 border-l">
//                 {modelVariable?.sourceVariables?.map((variable) => (
//                     <NestedDisplay modelVariable={variable} />
//                 ))}
//             </div>
//         </div>
//     );
// };
