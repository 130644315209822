import { sapienAxios } from "@/inertia-utils/hooks";
import { Course } from "../../types";
import { useMutation } from "@tanstack/react-query";

const isLocal = import.meta?.env?.DEV ===  true;

async function downloadScormExport(course: Course) {
    console.log(
        "Downloading SCORM export for course with ID:",
        course
    )
    try {
        const response = await sapienAxios.post<BlobPart>(
            "e-learning.embed.store",
            [],
            {
                course: course.id,
            },
            {
                responseType: "blob", // Important to handle binary data
                // https: isLocal ? false : true,
            },
        );

        return response?.data;
    } catch (error) {
        console.error("Error downloading the file:", error);
        // Handle the error appropriately in your UI
    }
}

export function useDownloadScormExport(course: Course) {
    return useMutation({
        mutationFn: () => downloadScormExport(course),
        onSuccess: (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute("download", "package.zip");

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up and remove the link
            document.body.removeChild(link);
        },
    });
}
