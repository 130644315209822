import React, { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { InteractiveDesignChart } from "./InteractiveDesignChart";
import { ChartBlock, InteractiveModuleChartType } from "../types";
import { useAuthStore } from "@/hooks/store";
import { deleteChartBlock, useSaveChartBlock } from "./useMutateCharts";
import { ChartCreationButtonMenu } from "./ChartCreationButtonMenu";
import {
    useChartBlocks,
    useSetChartBlocks,
    useModelTimespan,
    useOrderedKeyMetricTargetVariables,
    useModelBlockId,
    useInputVariables,
    useChartVariablesMap,
    useChartValuesMap,
} from "../state";
import { useInterfaceStateDesign } from "../useInterfaceState";
import { ModelVariableDataType } from "@/models";

const InteractiveChartsComponent = ({}: {}) => {
    const { showDesignChartControls, setShowDesignChartControls } =
        useInterfaceStateDesign();

    const chartBlocks = useChartBlocks();
    const setChartBlocks = useSetChartBlocks();
    const modelTimespan = useModelTimespan();
    const orderedKeyMetricTargetVariables =
        useOrderedKeyMetricTargetVariables();
    const modelBlockId = useModelBlockId();
    const inputVariables = useInputVariables();
    const chartVariablesMap = useChartVariablesMap();
    const chartValuesMap = useChartValuesMap();

    const { isNotParticipant } = useAuthStore();
    const { mutateAsync } = useSaveChartBlock();

    const availableVariables = useMemo(() => {
        if (
            inputVariables?.length > 0 &&
            orderedKeyMetricTargetVariables?.length > 0
        ) {
            return [
                ...inputVariables,
                ...orderedKeyMetricTargetVariables,
            ].filter(
                (variable) =>
                    variable.data_type === ModelVariableDataType.Number,
            );
        } else {
            return [];
        }
    }, []);

    const handleDeleteChartBlock = useCallback(
        (chartBlock: ChartBlock) => {
            if (isNotParticipant && !!chartBlock.created_at) {
                deleteChartBlock(chartBlock.id);
            }
            setChartBlocks(
                Object.values(chartBlocks)
                    .filter(
                        (existingBlock) => chartBlock.id !== existingBlock.id,
                    )
                    .reduce((map, existingBlock) => {
                        return {
                            ...map,
                            [existingBlock.id]: existingBlock,
                        };
                    }, {}),
            );
        },
        [chartBlocks, setChartBlocks, isNotParticipant],
    );

    const handleSetChartBlock = useCallback(
        (updatedChartBlock: ChartBlock) =>
            setChartBlocks(
                Object.values(chartBlocks).reduce((map, chartBlock) => {
                    return {
                        ...map,
                        [chartBlock.id]:
                            updatedChartBlock.id === chartBlock.id
                                ? updatedChartBlock
                                : chartBlock,
                    };
                }, {}),
            ),
        [chartBlocks, setChartBlocks, isNotParticipant],
    );

    const handleCreateChartBlock = useCallback(
        async (newChartBlockType: InteractiveModuleChartType) => {
            let newChart = {
                // id: uuidv4(),
                label: `New Chart`,
                chart_type: newChartBlockType,
                time_increment: "year",
                time_index: Math.max(modelTimespan - 1, 0),
                stacked: false,
                interpolation: "linear",
                variableIds: [],
                weight:
                    Object.values(chartBlocks)?.filter(
                        (block) => block.model_block_id === modelBlockId,
                    ).length + 1 || 0,
            } as ChartBlock;

            if (isNotParticipant) {
                newChart.model_block_id = modelBlockId;
                newChart = await mutateAsync(newChart);
            } else {
                newChart.id = uuidv4();
            }

            setChartBlocks(
                [newChart, ...Object.values(chartBlocks)].reduce(
                    (map, chartBlock) => ({
                        ...map,
                        [chartBlock.id]: chartBlock,
                    }),
                    {},
                ),
            );
            setShowDesignChartControls(true);
        },
        [chartBlocks, setChartBlocks, isNotParticipant],
    );

    return (
        <div className="">
            {/* {showDesignChartControls && ( */}
            <div
                className="flex flex-col items-start justify-between space-y-2 pb-3 text-lg font-semibold
                    uppercase sm:flex-row sm:items-center sm:space-y-0"
            >
                <ChartCreationButtonMenu
                    handleCreateChartBlock={handleCreateChartBlock}
                />
            </div>
            {/* )} */}
            <div className={"grid grid-cols-1 gap-4 md:grid-cols-2"}>
                {availableVariables?.length > 0 &&
                    Object.keys(chartVariablesMap)?.length > 0 &&
                    Object.values(chartBlocks).map((chartBlock, index) => (
                        <InteractiveDesignChart
                            key={chartBlock.id}
                            availableVariables={availableVariables}
                            selectedVariables={
                                chartVariablesMap[chartBlock.id] ?? []
                            }
                            modelTimespan={modelTimespan}
                            index={index}
                            chartBlock={chartBlock}
                            setChartBlock={(updatedChartBlock) =>
                                handleSetChartBlock(updatedChartBlock)
                            }
                            deleteChartBlock={() =>
                                handleDeleteChartBlock(chartBlock)
                            }
                            showChartControls={showDesignChartControls}
                            isNotParticipant={isNotParticipant}
                            chartValues={chartValuesMap[chartBlock.id] ?? {}}
                        />
                    ))}
            </div>
        </div>
    );
};

export const InteractiveCharts = React.memo(InteractiveChartsComponent);
