import { sapienAxios } from "@/inertia-utils/hooks";
import { ModelVariable, VariableValue } from "@/models";
import { InputDataItem } from "./types";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import {
    useComparisonArchetypeId,
    useEnableGetArchetypeValues,
    useInputDataObject,
    useModelBlockId,
    useSetComparisonArchetypePythonValuesYearly,
    useSetEnableGetArchetypeValues,
    useSetInputDataObject,
    useSetPythonValuesMonthly,
    useSetPythonValuesYearly,
    useSetTargetVariables,
} from "./state";

type InteractiveModelCalculationResponse = {
    inputDataObject: Record<string, InputDataItem>;
    targetVariables: ModelVariable[];
    valuesMonthly: Record<string, VariableValue[]>;
    valuesYearly: Record<string, VariableValue[]>;
    time: number;
    success: boolean;
};

// single input data item change

type InputItemChangeCalculationPayload = {
    model_block_id: string;
    model_variable_id: string;
    inputDataObject: Record<string, InputDataItem>;
};

async function calculateItemChange(body: InputItemChangeCalculationPayload) {
    const { data } = await sapienAxios.post<
        InteractiveModelCalculationResponse,
        "interactive.calculate-model"
    >(
        "interactive.calculate-model",
        body as InputItemChangeCalculationPayload,
        {},
    );
    return data;
}

export function useCalculateInputItemChangeMutation() {
    const modelBlockId = useModelBlockId();
    const inputDataObject = useInputDataObject();
    const setInputDataObject = useSetInputDataObject();
    const setPythonValuesMonthly = useSetPythonValuesMonthly();
    const setPythonValuesYearly = useSetPythonValuesYearly();

    return useMutation({
        mutationFn: async (inputDataItem: InputDataItem) => {
            const data = await calculateItemChange({
                model_block_id: modelBlockId,
                model_variable_id: inputDataItem.model_variable_id,
                inputDataObject: {
                    ...inputDataObject,
                    [inputDataItem.model_variable_id]: inputDataItem,
                },
            });
            return {
                ...data,
                modelVariableId: inputDataItem.model_variable_id,
            };
        },
        onSuccess: (data) => {
            if (!data) return;
            const {
                modelVariableId,
                inputDataObject: newInputDataObject,
                valuesMonthly,
                valuesYearly,
            } = data;
            setInputDataObject({
                ...inputDataObject,
                [modelVariableId]: newInputDataObject[modelVariableId],
            });
            setPythonValuesMonthly(valuesMonthly);
            setPythonValuesYearly(valuesYearly);
        },
    });
}

// full input data object change

type InputObjectChangeCalculationPayload = {
    model_block_id: string;
    inputDataObject: Record<string, InputDataItem>;
    from_cache?: boolean;
};

async function calculateObjectChange(
    body: InputObjectChangeCalculationPayload,
) {
    const { data } = await sapienAxios.post<
        InteractiveModelCalculationResponse,
        "interactive.calculate-model"
    >(
        "interactive.calculate-model",
        body as InputObjectChangeCalculationPayload,
        {},
    );
    return data;
}

export function useCalculateInputObjectChangeMutation() {
    const setInputDataObject = useSetInputDataObject();
    const setTargetVariables = useSetTargetVariables();
    const setPythonValuesMonthly = useSetPythonValuesMonthly();
    const setPythonValuesYearly = useSetPythonValuesYearly();

    return useMutation({
        mutationFn: calculateObjectChange,
        onSuccess: (data) => {
            if (!data) return;
            const {
                inputDataObject,
                targetVariables,
                valuesMonthly,
                valuesYearly,
            } = data;
            setInputDataObject(inputDataObject);
            setTargetVariables(targetVariables);
            setPythonValuesMonthly(valuesMonthly);
            setPythonValuesYearly(valuesYearly);
        },
    });
}

// previous useQuery approach

// type InteractiveModelCalculationPayload = {
//     model_block_id: string;
//     model_variable_id?: string;
//     inputDataObject: Record<string, InputDataItem>;
//     from_cache?: boolean;
// };

// async function calculateInteractiveModel({
//     body,
// }: {
//     body: Partial<InteractiveModelCalculationPayload>;
// }) {
//     const { data } = await sapienAxios.post<
//         InteractiveModelCalculationResponse,
//         "interactive.calculate-model"
//     >(
//         "interactive.calculate-model",
//         body as Partial<InteractiveModelCalculationPayload>,
//         {},
//     );

//     return data;
// }

// const handleCalculateOnInputDataItemChange = (
//     modelBlockId: string,
//     modelVariableId: string,
//     inputDataObject: Record<string, InputDataItem>,
//     isEnabled: boolean,
// ) => {
//     const { data } = useQuery({
//         queryKey: [
//             "interactiveModelByVariable",
//             inputDataObject[modelVariableId],
//         ],
//         queryFn: async () => {
//             return await calculateInteractiveModel({
//                 body: {
//                     model_block_id: modelBlockId,
//                     model_variable_id: modelVariableId,
//                     inputDataObject: inputDataObject,
//                 },
//             });
//         },
//         select: (data) => data,
//         placeholderData: keepPreviousData,
//         enabled: isEnabled,
//         refetchInterval: false,
//         refetchOnWindowFocus: false,
//     });

//     return data;
// };

// export const useCalculateOnInputDataItemChange = () => {
//     const modelBlockId = useModelBlockId();
//     const inputDataObject = useInputDataObject();
//     const editedInputDataItemVariableId = useEditedInputDataItemVariableId();
//     const setPythonValuesMonthly = useSetPythonValuesMonthly();
//     const setPythonValuesYearly = useSetPythonValuesYearly();

//     const isEnabled = useMemo(() => {
//         return (
//             !!modelBlockId &&
//             !!inputDataObject &&
//             Object.values(inputDataObject).length > 0 &&
//             !!editedInputDataItemVariableId
//         );
//     }, [modelBlockId, inputDataObject, editedInputDataItemVariableId]);

//     const data = handleCalculateOnInputDataItemChange(
//         modelBlockId,
//         editedInputDataItemVariableId,
//         inputDataObject,
//         isEnabled,
//     );

//     useEffect(() => {
//         if (!data) return;
//         const { valuesMonthly, valuesYearly } = data;
//         setPythonValuesMonthly(valuesMonthly);
//         setPythonValuesYearly(valuesYearly);
//     }, [data]);

//     return {};
// };

// const handleCalculateInteractiveModel = (
//     modelBlockId: string,
//     inputDataObject: Record<string, InputDataItem>,
//     fromCache: boolean,
//     isEnabled: boolean,
// ) => {
//     const { data } = useQuery({
//         queryKey: ["interactiveModel", inputDataObject],
//         queryFn: async () => {
//             return await calculateInteractiveModel({
//                 body: {
//                     model_block_id: modelBlockId,
//                     inputDataObject: inputDataObject,
//                     from_cache: fromCache,
//                 },
//             });
//         },
//         select: (data) => data,
//         placeholderData: keepPreviousData,
//         enabled: isEnabled,
//         refetchInterval: false,
//         refetchOnWindowFocus: false,
//     });

//     return data;
// };

// export const useCalculateInteractiveModel = () => {
//     const modelBlockId = useModelBlockId();
//     const inputDataObject = useInputDataObject();
//     const setTargetVariables = useSetTargetVariables();
//     const setPythonValuesMonthly = useSetPythonValuesMonthly();
//     const setPythonValuesYearly = useSetPythonValuesYearly();
//     const editedInputDataItemVariableId = useEditedInputDataItemVariableId();
//     const fromCache = useFromCache();
//     const setFromCache = useSetFromCache();

//     const isEnabled = useMemo(() => {
//         return (
//             !!modelBlockId &&
//             !!inputDataObject &&
//             Object.values(inputDataObject).length > 0 &&
//             !editedInputDataItemVariableId
//         );
//     }, [modelBlockId, inputDataObject, editedInputDataItemVariableId]);

//     const data = handleCalculateInteractiveModel(
//         modelBlockId,
//         inputDataObject,
//         fromCache,
//         isEnabled,
//     );

//     useEffect(() => {
//         if (!data) return;
//         const { targetVariables, valuesMonthly, valuesYearly } = data;
//         setTargetVariables(targetVariables);
//         setPythonValuesMonthly(valuesMonthly);
//         setPythonValuesYearly(valuesYearly);
//         setFromCache(true);
//     }, [data]);

//     return {};
// };

// comparison archetype change

type ArchetypeCalculationPayload = {
    id: string;
};

type ArchetypeCalculationResponse = {
    archetypeValuesYearly: Record<string, VariableValue[]>;
};

async function calculateArchetypeModel({
    body,
}: {
    body: Partial<ArchetypeCalculationPayload>;
}) {
    const { data } = await sapienAxios.post<
        ArchetypeCalculationResponse,
        "interactive.calculate-archetype"
    >(
        "interactive.calculate-archetype",
        body as Partial<ArchetypeCalculationPayload>,
        {},
    );

    return data;
}

const handleCalculateArchetypeModel = (
    interactiveModuleArchetypeId: string,
    isEnabled: boolean,
    callback: () => void,
) => {
    const { data } = useQuery({
        queryKey: ["archetypeValues", interactiveModuleArchetypeId],
        queryFn: async () => {
            const data = await calculateArchetypeModel({
                body: {
                    id: interactiveModuleArchetypeId,
                },
            });
            callback();
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled: isEnabled,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useGetArchetypeValues = () => {
    const modelBlockId = useModelBlockId();
    const comparisonArchetypeId = useComparisonArchetypeId();
    const setComparisonArchetypePythonValuesYearly =
        useSetComparisonArchetypePythonValuesYearly();
    const enableGetArchetypeValues = useEnableGetArchetypeValues();
    const setEnableGetArchetypeValues = useSetEnableGetArchetypeValues();

    const isArchetypeEnabled = useMemo(() => {
        return (
            !!modelBlockId &&
            !!comparisonArchetypeId &&
            enableGetArchetypeValues
        );
    }, [modelBlockId, comparisonArchetypeId, enableGetArchetypeValues]);

    const archetypeData = handleCalculateArchetypeModel(
        comparisonArchetypeId,
        isArchetypeEnabled,
        () => setEnableGetArchetypeValues(false),
    );

    useEffect(() => {
        if (!archetypeData) return;
        const { archetypeValuesYearly } = archetypeData;
        setComparisonArchetypePythonValuesYearly(archetypeValuesYearly);
        // setEnableGetArchetypeValues(false);
    }, [archetypeData]);

    return {};
};
