import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/Tabs";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { ContentBlockShape } from "@/models";
import { BrushIcon } from "lucide-react";
import React from "react";
import { DefaultSpacingMenu } from "./DefaultStyleMenu";
import ColorForm from "./ColorForm";
import { SpacingMenuByType, ColorMenuByType } from "./MenusByType";

type Props = { contentBlock: ContentBlockShape };

export default function StyleDropdown({ contentBlock }: Props) {
    return (
        <Popover>
            <PopoverTrigger className="rounded-md bg-white p-2 hover:bg-slate-200">
                <BrushIcon className="h-4 w-4" />
            </PopoverTrigger>
            <PopoverContent
                align="end"
                className="ml-4 mt-2 rounded-md bg-white p-2 py-2 e-learning"
            >
                <Tabs
                    defaultValue="spacing"
                    className="flex w-[400px] flex-col space-y-2"
                >
                    <TabsList className="grid w-full grid-cols-2 bg-gray-100">
                        <TabsTrigger
                            value="spacing"
                            className="rounded-md data-[state='active']:bg-white"
                        >
                            Spacing
                        </TabsTrigger>
                        <TabsTrigger
                            value="colors"
                            className="rounded-md data-[state='active']:bg-white"
                        >
                            Colors
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="spacing" className="p-4">
                        <DefaultSpacingMenu contentBlock={contentBlock} />
                        <SpacingMenuByType contentBlock={contentBlock} />
                    </TabsContent>
                    <TabsContent value="colors">
                        <ColorForm contentBlock={contentBlock} />
                        <ColorMenuByType contentBlock={contentBlock} />
                    </TabsContent>
                </Tabs>
            </PopoverContent>
        </Popover>
    );
}
