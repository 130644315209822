import { buildHero } from "@/model-configs/content-block-layouts/HeroLayout";
import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType } from "@/models";

export const OrganizationOverviewContentBlocks: FragmentContentBlock[] = [
    {
        content_block_type: ContentBlockType.Page,
        title: "Page",
        route: "/",
        contentBlocks: [
            buildHero(
                "Hey look I work",
                "Yeah, but so do I",
                "https://d19a0dukzx7q7p.cloudfront.net/phpNYaWQU.jpg"
            ),
            // {
            //     ...LayoutConfigObject[LayoutType.Hero].layoutContentBlock,
            //     image_url:
            //         "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
            // },
        ],
    },
];
