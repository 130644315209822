import React, { useEffect, useMemo, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Round, Selection } from "@/models";
import { FormContentBlockDefaultValuesObject } from "@/styles";
import { groupBy } from "lodash";
import { getQuestionData, getSelectionData } from "@/util";
import { FormContainer } from "@/forms";
import { QuestionContentBlockFormField } from "@/styles/themes/Question/QuestionContentBlockFormField";
import {
    CalculateSandboxDataBody,
    calculateSandboxData,
} from "./calculateSandboxData";

export const SandboxQuestions = ({
    rounds,
    selections,
}: {
    rounds: Round[];
    selections: Selection[];
}) => {
    const [questionData, setQuestionData] =
        useState<FormContentBlockDefaultValuesObject>({});

    const filteredRounds = useMemo(() => {
        return rounds
            .map(
                (round) =>
                    ({
                        ...round,
                        prompts: round.prompts?.filter(
                            (prompt) => prompt.has_model_effects,
                        ),
                    }) as Round,
            )
            .filter((round) => round.prompts?.length > 0);
    }, [rounds]);

    const prompts = useMemo(() => {
        return filteredRounds.reduce(
            (map, round) => [...map, ...round.prompts],
            [],
        );
    }, [filteredRounds]);

    const groupedSelections = useMemo(() => {
        if (!selections) return {};
        return groupBy(selections, "prompt_id");
    }, [selections]);

    useEffect(() => {
        if (groupedSelections && prompts) {
            let newQuestionFormObject =
                {} as FormContentBlockDefaultValuesObject;
            prompts.forEach((prompt) => {
                let data = getQuestionData(
                    prompt,
                    prompt.options || [],
                    groupedSelections[prompt.id] || [],
                );

                newQuestionFormObject = {
                    ...newQuestionFormObject,
                    [prompt.id]: data,
                } as FormContentBlockDefaultValuesObject;
            });
            setQuestionData(newQuestionFormObject);
        }
    }, [groupedSelections, prompts]);

    useEffect(() => {
        if (questionData) {
            console.log("question data");
            console.log(questionData);
        }
    }, [questionData]);

    useEffect(() => {
        if (selections) {
            console.log("selections changed");
        }
    }, [selections]);

    return (
        <div>
            <div className="mb-4 mt-2 text-lg">Questions by Round</div>
            <div className="mx-4 mb-4">
                <FormContainer
                    defaultValues={questionData}
                    onSubmit={async (formData) => {
                        const selectionData = getSelectionData(
                            prompts,
                            selections,
                            formData,
                            filteredRounds[0]?.simulation_id,
                        );
                        console.log(selectionData);
                        await calculateSandboxData({
                            body: {
                                simulation_id: filteredRounds[0]?.simulation_id,
                                selections: selectionData,
                            } as CalculateSandboxDataBody,
                        });
                    }}
                >
                    <button
                        type="submit"
                        value="Submit"
                        className="mb-4 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                    >
                        Update Sandbox Data
                    </button>
                    <Accordion
                        allowZeroExpanded={true}
                        allowMultipleExpanded={true}
                    >
                        {filteredRounds.map((round) => (
                            <AccordionItem key={round.id}>
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        style={{
                                            padding: "12px",
                                            width: "auto",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        <div className="w-full overflow-hidden">
                                            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                {round.title}
                                            </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel
                                    style={{
                                        padding: "0px",
                                    }}
                                >
                                    <div style={{ overflowX: "hidden" }}>
                                        <div className="p-3">
                                            {round.prompts.map((prompt) => (
                                                <div
                                                    key={prompt.id}
                                                    className="py-2"
                                                >
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                "1rem",
                                                        }}
                                                    >
                                                        {prompt.content}
                                                    </div>
                                                    <QuestionContentBlockFormField
                                                        key={prompt.id}
                                                        promptId={prompt.id}
                                                        prompt={prompt}
                                                        optionRenderMap={{}}
                                                        isInDesignContext={true}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </FormContainer>
            </div>
        </div>
    );
};
