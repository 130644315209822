import React, { useMemo } from "react";
import { ModelBlock, ModelVariable, TimeHorizon } from "../../models";
import { DesignVariableTableDisplay } from "./DesignVariableTableDisplay";

const DesignModelBlockDisplayComponent = ({
    modelBlock,
    ancestry,
    modelVariables,
    timeHorizons,
    modelBlockAccordionMap,
    setModelBlockAccordionMap,
}: {
    modelBlock: ModelBlock;
    ancestry: string[];
    modelVariables: { [modelBlockId: string]: ModelVariable[] };
    timeHorizons: TimeHorizon[];
    modelBlockAccordionMap: {
        [modelBlockId: string]: boolean;
    };
    setModelBlockAccordionMap: (modelBlockAccordionMap: {
        [modelBlockId: string]: boolean;
    }) => void;
}) => {
    const isExpanded = useMemo(() => {
        return (
            !!modelBlockAccordionMap &&
            !!modelBlock &&
            !!modelBlockAccordionMap[modelBlock.id]
        );
    }, [modelBlockAccordionMap]);

    return (
        <div
            key={modelBlock.id}
            className={`pl-${ancestry.length * 2} ml-${ancestry.length * 2}`}
        >
            <div key={modelBlock.id} className={`w-full text-sm`}>
                <div
                    className={`py-0.5 my-0.5 cursor-pointer ${
                        isExpanded
                            ? "bg-indigo-200 bg-opacity-75"
                            : "bg-indigo-100"
                    }`}
                    onClick={() =>
                        setModelBlockAccordionMap({
                            ...modelBlockAccordionMap,
                            [modelBlock.id]: !isExpanded,
                        })
                    }
                >
                    <div className="flex items-center justify-between mr-2">
                        <div className="flex items-center">
                            <svg
                                className={`ml-2 -mr-0.5 h-5 w-5 transition-all ${
                                    isExpanded ? "rotate-0" : "-rotate-90"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <div className="py-1.5 px-1.5 block">
                                <div
                                    className={
                                        modelBlock.modelBlocks?.length > 0
                                            ? "font-bold"
                                            : ""
                                    }
                                >
                                    {modelBlock.label}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!!isExpanded &&
                    !!modelVariables &&
                    !!modelVariables[modelBlock.id]?.length && (
                        <div className="-mt-0.5 mb-2 border border-box border-neutral-200">
                            <DesignVariableTableDisplay
                                modelVariables={modelVariables[modelBlock.id]}
                                timeHorizons={timeHorizons}
                                isEditable={true}
                            />
                        </div>
                    )}
            </div>
            {!!isExpanded &&
                modelBlock.modelBlocks &&
                modelBlock.modelBlocks.length !== 0 &&
                modelBlock.modelBlocks.map((child) => (
                    <DesignModelBlockDisplay
                        key={child.id}
                        modelBlock={child}
                        ancestry={[...ancestry, modelBlock.label]}
                        modelVariables={modelVariables}
                        timeHorizons={timeHorizons}
                        modelBlockAccordionMap={modelBlockAccordionMap}
                        setModelBlockAccordionMap={setModelBlockAccordionMap}
                    />
                ))}
        </div>
    );
};

export const DesignModelBlockDisplay = React.memo(
    DesignModelBlockDisplayComponent
);
