import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import Icon from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { TopNavLinkDiv } from "@/components";
import LogoImage from "@/components/LogoImage";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";
import { InteractiveColorModeButton } from "./InteractiveColorModeButton";
import { useAuthStore } from "@/hooks/store";

const InteractiveModuleTopNavComponent = ({
    simulationTitle,
    simulationSlug,
    modelBlockLabel,
    colorModeClass,
    setColorModeClass,
}: {
    simulationTitle: string;
    simulationSlug: string;
    modelBlockLabel?: string;
    colorModeClass: "" | "dark";
    setColorModeClass: (colorModeClass: "" | "dark") => void;
}) => {
    const { user, isNotParticipant } = useAuthStore();

    return (
        <div className="interactive-top flex h-11">
            <div className="interactive-top-logo flex h-11 w-11 min-w-11 justify-center bg-slate-950">
                <TopNavLinkDiv style={{ padding: 0 }}>
                    {!!isNotParticipant ? (
                        <InertiaLink href={route("admin.index")}>
                            <LogoImage size={"22"} opacity={"0.9"} />
                        </InertiaLink>
                    ) : (
                        <LogoImage size={"22"} opacity={"0.9"} />
                    )}
                </TopNavLinkDiv>
            </div>
            <div
                className="interactive-top-center flex h-11 w-[calc(100%-44px)] min-w-[calc(100%-44px)]
                    items-center justify-between bg-slate-950 text-white transition-all"
            >
                <div className="flex flex-row flex-wrap items-center">
                    <div
                        className={`hidden items-center ${!modelBlockLabel ? "sm:flex" : "md:flex"}`}
                    >
                        <TopNavLinkDiv
                            style={{
                                padding: 0,
                            }}
                            className=""
                        >
                            {!!isNotParticipant ? (
                                <InertiaLink
                                    href={
                                        !!simulationSlug &&
                                        route("admin.projects.show", {
                                            sim: simulationSlug,
                                        })
                                    }
                                >
                                    <div className="px-1 text-sm font-bold text-white sm:px-3">
                                        {`${simulationTitle}`}
                                    </div>
                                </InertiaLink>
                            ) : (
                                <div className="px-1 text-sm font-bold text-white sm:px-3">
                                    {`${simulationTitle}`}
                                </div>
                            )}
                        </TopNavLinkDiv>
                        <div className="">
                            <Icon
                                icon={chevronRight}
                                size={12}
                                className="flex text-white opacity-60"
                            />
                        </div>
                    </div>
                    {!modelBlockLabel && (
                        <div className="px-1 text-sm font-semibold sm:px-3">
                            Interactive Models
                        </div>
                    )}
                    {!!modelBlockLabel && (
                        <>
                            <div className="hidden sm:inline-flex">
                                <TopNavLinkDiv
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <InertiaLink
                                        href={
                                            !!simulationSlug &&
                                            route("interactive.index", {
                                                simulationSlug: simulationSlug,
                                            })
                                        }
                                    >
                                        <div className="px-1 text-sm font-semibold text-white sm:px-3">
                                            Interactive Models
                                        </div>
                                    </InertiaLink>
                                </TopNavLinkDiv>
                                <Icon
                                    icon={chevronRight}
                                    size={12}
                                    className="flex text-white opacity-60"
                                />
                            </div>
                            <div className="px-1 text-sm font-normal sm:px-3">
                                {`${modelBlockLabel}`}
                            </div>
                        </>
                    )}
                </div>
                <div
                    className="discovery-top-right-links flex h-full items-center bg-slate-950 text-white
                        transition-all"
                >
                    <InteractiveColorModeButton
                        colorModeClass={colorModeClass}
                        setColorModeClass={setColorModeClass}
                    />
                    {!!user && <UserNavDropdown userName={user.name} />}
                </div>
            </div>
        </div>
    );
};

export const InteractiveModuleTopNav = React.memo(
    InteractiveModuleTopNavComponent,
);
