import React from "react";
import {
    ChartBlock,
    FormattedChartValue,
    InteractiveModuleChartType,
} from "../types";
import { RenderChart } from "../shared";

const ChartDisplayComponent = ({
    modelTimespan,
    chartBlock,
    setChartTimeIndex,
    chartValues,
}: {
    modelTimespan: number;
    chartBlock: ChartBlock;
    setChartTimeIndex: (chartTimeIndex: number) => void;
    chartValues: Record<string, FormattedChartValue[]>;
}) => {
    return (
        <div className="flex flex-col">
            {!!modelTimespan &&
            chartBlock.chart_type === InteractiveModuleChartType.PIE ? (
                <div
                    className="m-2 mb-0 flex flex-wrap items-center justify-center text-sm font-semibold
                        text-slate-700 transition-all dark:text-slate-300"
                >
                    <div className="mr-2">{chartBlock.label}</div>
                    <select
                        className="my-1 min-w-20 rounded-md border-orange-100 bg-white bg-opacity-50 px-2 py-1
                            text-xs text-slate-700 transition-all marker:border focus:border-orange-200
                            focus:outline-none focus:ring-0 dark:border-slate-700 dark:bg-opacity-5
                            dark:text-slate-300 dark:focus:border-slate-600"
                        value={chartBlock.time_index}
                        onChange={(e) => {
                            e.stopPropagation();
                            setChartTimeIndex(Number(e.target.value));
                        }}
                    >
                        <option key={""} value={""} disabled>
                            time
                        </option>
                        {Array(modelTimespan)
                            .fill(0)
                            .map((_, i) => (
                                <option key={i} value={i}>
                                    {`${chartBlock.time_increment} ${i + 1}`}
                                </option>
                            ))}
                    </select>
                </div>
            ) : (
                <div
                    className="m-2 mb-4 text-center text-sm font-semibold text-slate-700 transition-all sm:mb-2
                        md:mb-6 2xl:mb-4 dark:text-slate-300"
                >
                    {chartBlock.label}
                </div>
            )}

            <div
                className="flex h-full w-full flex-col justify-center text-slate-700 transition-all
                    dark:text-slate-300"
            >
                {Object.keys(chartValues)?.length > 0 && (
                    <RenderChart
                        chartValues={chartValues}
                        chartType={chartBlock.chart_type}
                        stacked={chartBlock.stacked}
                        interpolation={chartBlock.interpolation}
                    />
                )}
            </div>
        </div>
    );
};

export const ChartDisplay = React.memo(ChartDisplayComponent);
