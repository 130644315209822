import React from "react";
import { AddContentBlockMenu } from "./add-content-block-menu";
import { useAddContentBlockMenuState } from "@/hooks";
import { PanelLayers } from "./panel";

type Props = {
    leftMenuSection: "create" | "layers" | "assets";
    isOpen: boolean;
    currentPageId: string;
    selectedRoundId: string;
    width: number;
};

type MenuProps = Omit<Props, "isOpen" | "width">;

function EditorLeftMenuContents({
    leftMenuSection,
    currentPageId,
    selectedRoundId,
}: MenuProps) {
    const {
        selectedCategory,
        setSelectedCategory,
        openSubcategories,
        setOpenSubcategories,
    } = useAddContentBlockMenuState();
    return (
        <>
            {leftMenuSection === "create" && (
                <>
                    <div className="px-4 py-2">
                        <h6 className="text-xl">{`Create`}</h6>
                    </div>
                    {!!selectedRoundId && (
                        <div className=" pb-2">
                            <AddContentBlockMenu
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                openSubcategories={openSubcategories}
                                setOpenSubcategories={setOpenSubcategories}
                                currentPageId={currentPageId}
                            />
                        </div>
                    )}
                </>
            )}
            {leftMenuSection === "layers" && (
                <>
                    <div className="px-4 py-2">
                        <h6 className="text-xl">{`Layers`}</h6>
                    </div>
                    {!!selectedRoundId && (
                        <div
                            data-testid="this-is-layers"
                            className="bg-slate-900 pb-2 backdrop-opacity-75"
                        >
                            <PanelLayers currentPageId={currentPageId} />
                        </div>
                    )}
                </>
            )}
            {leftMenuSection === "assets" && (
                <>
                    <div className="px-4 py-2">
                        <h6 className="text-xl">{`Assets`}</h6>
                    </div>
                </>
            )}
        </>
    );
}

export default function EditorLeftMenu({
    leftMenuSection,
    isOpen,
    currentPageId,
    selectedRoundId,
    width,
}: Props) {
    return (
        <>
            <div
                data-open={isOpen}
                className="EDITOR-LEFT-DETAIL-MENU hidden h-full overflow-auto bg-[#111928] text-white
                    scrollbar scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2
                    data-[open=false]:w-0 data-[open=true]:w-80 data-[open=true]:min-w-[20rem]
                    lg:block"
            >
                <EditorLeftMenuContents
                    leftMenuSection={leftMenuSection}
                    currentPageId={currentPageId}
                    selectedRoundId={selectedRoundId}
                />
            </div>
            <div
                data-open={isOpen}
                className={`EDITOR-LEFT-DETAIL-MENU absolute left-[36px] z-[10000] block h-full
                    overflow-auto bg-[#111928] text-white scrollbar scrollbar-thumb-gray-500
                    scrollbar-thumb-rounded-full scrollbar-w-2 data-[open=false]:w-0
                    data-[open=true]:w-[360px] lg:hidden`}
            >
                <EditorLeftMenuContents
                    leftMenuSection={leftMenuSection}
                    currentPageId={currentPageId}
                    selectedRoundId={selectedRoundId}
                />
            </div>
        </>
    );
}
