import React, { useCallback, useMemo, useState } from "react";
import { H6Span } from "@/components";
import { ModalContainer } from "@/modules/shared";
import {
    useModalQueryParams,
    useSelectedSimulation,
    useUploadStore,
} from "@/hooks";
import { useDiscardActiveContentBlock } from "@/hooks/activeContentBlock";
import { SapienInertia } from "@/inertia-utils/hooks";
import FileSelector from "./FileSelector";
import ImageOrVideoSearch from "./ImageOrVideoSearch";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useGetContentBlockById } from "../modals/shared-queries/useGetContentBlockById";

type Props = ReturnType<typeof useModalQueryParams<"ImageModal">>;

function ImageModalContent({ queryParams, closeModal }: Props) {
    const { imageContentBlockId, backgroundImageContentBlockId } = queryParams;
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();
    const { selectedSimulation } = useSelectedSimulation();
    const { uploadedFileUrl, setUploadedFileUrl } = useUploadStore();

    const mediaType = useMemo(() => {
        return backgroundImageContentBlockId ? "backgroundImage" : "image";
    }, [imageContentBlockId, backgroundImageContentBlockId]);

    const [imageSourceType, setImageSourceType] = React.useState<
        "upload" | "api"
    >("api");

    const id = imageContentBlockId || backgroundImageContentBlockId;
    const { data: contentBlockToEdit } = useGetContentBlockById(id);

    const [isSaving, setIsSaving] = useState(false);
    const handleSave = useCallback(() => {
        if (!contentBlockToEdit) return;

        const contentBlockToSave = !!imageContentBlockId
            ? {
                  ...contentBlockToEdit,
                  theme: JSON.stringify(contentBlockToEdit.theme),
                  image_url: uploadedFileUrl,
              }
            : !!backgroundImageContentBlockId
              ? {
                    ...contentBlockToEdit,
                    theme: JSON.stringify({
                        ...contentBlockToEdit.theme,
                        backgroundImage: uploadedFileUrl,
                        backgroundPosition:
                            contentBlockToEdit.theme?.backgroundPosition ||
                            "center",
                    }),
                }
              : null;

        setIsSaving(true);
        if (imageSourceType === "upload") {
            SapienInertia.put(
                "admin.design.update",
                {
                    ...contentBlockToSave,
                } as unknown as (typeof LaravelRequestBodyShapes)["admin.design.update"],
                {
                    simulationSlug: selectedSimulation.slug,
                    roundId: contentBlockToSave.round_id,
                    contentBlock: contentBlockToSave.id,
                },
                {
                    preserveState: true,
                    onSuccess: () => {
                        setUploadedFileUrl("");
                        setIsSaving(false);
                        closeModal();
                    },
                },
            );
        } else {
            SapienInertia.post(
                "creator.design.file-search.store",
                {
                    contentBlockId: contentBlockToSave?.id,
                    property: !!backgroundImageContentBlockId
                        ? "backgroundImage"
                        : "image_url",
                    url: uploadedFileUrl,
                },
                {},
                {
                    onSuccess: () => {
                        setIsSaving(false);
                        setUploadedFileUrl("");
                        closeModal();
                    },
                    onError: (err) => {
                        console.log("error", err);
                    },
                },
            );
        }
    }, [
        imageContentBlockId,
        backgroundImageContentBlockId,
        uploadedFileUrl,
        imageSourceType,
        contentBlockToEdit,
    ]);

    const imageUrl = useMemo(() => {
        if (!contentBlockToEdit) return "";
        let url = "";
        if (uploadedFileUrl && uploadedFileUrl !== "") url = uploadedFileUrl;
        else if (imageContentBlockId && contentBlockToEdit?.image_url != "")
            url = contentBlockToEdit.image_url;
        else url = contentBlockToEdit?.theme?.backgroundImage;

        if (url && url.startsWith("url("))
            url = url.replace("url(", "").replace(")", "");

        return url;
    }, [
        imageContentBlockId,
        backgroundImageContentBlockId,
        contentBlockToEdit,
    ]);

    return (
        <div className="p-6 text-white" data-testid="image-modal">
            <div
                className="flex flex-row items-center justify-between border-b border-[#374151] py-6
                    text-white"
            >
                <H6Span>Edit Image</H6Span>
            </div>
            {!!contentBlockToEdit && (
                <>
                    <div className="border-b border-[#374151] md:grid md:grid-cols-2 md:gap-2">
                        <div
                            className={`flex items-center px-6 py-4 ${imageSourceType === "api" && "bg-[#1F2A37]"}`}
                        >
                            <input
                                data-testid="select-image-search"
                                type="radio"
                                value=""
                                name="select-image-search"
                                className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={imageSourceType === "api"}
                                onChange={(_) => setImageSourceType("api")}
                            />
                            <label
                                htmlFor="copy-old-prompt"
                                className="ml-3.5 flex w-full flex-col text-sm font-medium"
                            >
                                Search
                                <span className="mt-1 text-[#6B7280]">
                                    Search millions of images
                                </span>
                            </label>
                        </div>
                        <div
                            className={`flex items-center px-6 py-4 ${imageSourceType === "upload" && "bg-[#1F2A37]"}`}
                        >
                            <input
                                id="create-new"
                                type="radio"
                                value=""
                                name="create-new"
                                className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={imageSourceType === "upload"}
                                onChange={(_) => setImageSourceType("upload")}
                            />
                            <label
                                htmlFor="create-new"
                                className="ml-3.5 flex w-full flex-col text-sm font-medium"
                            >
                                Upload
                                <span className="mt-1 text-[#6B7280]">
                                    Choose an image from your device
                                </span>
                            </label>
                        </div>
                    </div>
                    {imageSourceType === "upload" && (
                        <FileSelector imageUrl={imageUrl} />
                    )}
                    {imageSourceType === "api" && (
                        <ImageOrVideoSearch mediaType={mediaType} />
                    )}
                </>
            )}
            <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                <div className="flex items-center justify-center">
                    <button
                        onClick={closeModal}
                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                            text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                            focus:ring-2 focus:ring-blue-300"
                    >
                        Cancel
                    </button>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        disabled={!uploadedFileUrl || isSaving}
                        onClick={() => {
                            handleSave();
                            discardActiveContentBlock();
                        }}
                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                            py-2.5 text-center text-sm font-medium text-white transition-all
                            hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                            disabled:cursor-not-allowed disabled:opacity-75"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export const ImageEditor = () => {
    const modalQueryParams = useModalQueryParams("ImageModal");
    const { isAtModalUrl } = modalQueryParams;

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            size="l"
            backgroundColor="#111928"
            classNames={"z-100"}
        >
            {isAtModalUrl && <ImageModalContent {...modalQueryParams} />}
        </ModalContainer>
    );
};
