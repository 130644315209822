import React, { useCallback, useMemo } from "react";
import { InputDataItem, InputDataSettingType } from "../types";

const path = (d: string) => (
    <path
        d={d}
        role="presentation"
        shapeRendering="auto"
        style={{
            fill: "transparent",
            stroke: "rgb(29, 78, 216)",
            strokeWidth: 12,
        }}
    />
);

const paths = {
    constant:
        "M50,150L88.889,150L127.778,150L166.667,150L205.556,150L244.444,150L283.333,150L322.222,150L361.111,150L400,150",
    step: "M50,250L69.444,250L69.444,250L108.333,250L108.333,200L147.222,200L147.222,200L186.111,200L186.111,150L225,150L225,150L263.889,150L263.889,100L302.778,100L302.778,100L341.667,100L341.667,50L380.556,50L380.556,50L400,50",
    linearIncrease: "M50,250L400,50",
    linearDecrease: "M50,50L400,250",
    powerIncreaseExponent0:
        "M50,250L56.481,249.642C62.963,249.284,75.926,248.568,88.889,247.58C101.852,246.591,114.815,245.33,127.778,243.65C140.741,241.97,153.704,239.871,166.667,237.156C179.63,234.441,192.593,231.11,205.556,226.901C218.519,222.692,231.481,217.606,244.444,211.306C257.407,205.006,270.37,197.493,283.333,188.341C296.296,179.189,309.259,168.399,322.222,155.442C335.185,142.486,348.148,127.363,361.111,109.429C374.074,91.494,387.037,70.747,393.519,60.373L400,50",
    powerIncreaseExponent1:
        "M50,250L56.481,248.99C62.963,247.98,75.926,245.96,88.889,243.266C101.852,240.572,114.815,237.205,127.778,233.165C140.741,229.125,153.704,224.411,166.667,219.024C179.63,213.636,192.593,207.576,205.556,200.842C218.519,194.108,231.481,186.7,244.444,178.62C257.407,170.539,270.37,161.785,283.333,152.357C296.296,142.929,309.259,132.828,322.222,122.054C335.185,111.279,348.148,99.832,361.111,87.71C374.074,75.589,387.037,62.795,393.519,56.397L400,50",
    powerIncreaseExponent2:
        "M50,250L56.481,239.109C62.963,228.218,75.926,206.435,88.889,190.897C101.852,175.359,114.815,166.065,127.778,157.851C140.741,149.638,153.704,142.505,166.667,135.932C179.63,129.359,192.593,123.345,205.556,117.689C218.519,112.034,231.481,106.735,244.444,101.691C257.407,96.647,270.37,91.857,283.333,87.26C296.296,82.662,309.259,78.257,322.222,74.005C335.185,69.753,348.148,65.653,361.111,61.677C374.074,57.702,387.037,53.851,393.519,51.925L400,50",
    powerIncreaseExponent3:
        "M50,250L56.481,230.897C62.963,211.795,75.926,173.589,88.889,150.808C101.852,128.028,114.815,120.671,127.778,114.527C140.741,108.383,153.704,103.45,166.667,99.076C179.63,94.702,192.593,90.887,205.556,87.402C218.519,83.917,231.481,80.763,244.444,77.83C257.407,74.897,270.37,72.186,283.333,69.635C296.296,67.084,309.259,64.693,322.222,62.424C335.185,60.155,348.148,58.009,361.111,55.958C374.074,53.908,387.037,51.954,393.519,50.977L400,50",
    powerDecreaseExponent0:
        "M50,50L56.481,60.373C62.963,70.747,75.926,91.494,88.889,109.429C101.852,127.363,114.815,142.486,127.778,155.442C140.741,168.399,153.704,179.189,166.667,188.341C179.63,197.493,192.593,205.006,205.556,211.306C218.519,217.606,231.481,222.692,244.444,226.901C257.407,231.11,270.37,234.441,283.333,237.156C296.296,239.871,309.259,241.97,322.222,243.65C335.185,245.33,348.148,246.591,361.111,247.58C374.074,248.568,387.037,249.284,393.519,249.642L400,250",
    powerDecreaseExponent1:
        "M50,50L56.481,56.397C62.963,62.795,75.926,75.589,88.889,87.71C101.852,99.832,114.815,111.279,127.778,122.054C140.741,132.828,153.704,142.929,166.667,152.357C179.63,161.785,192.593,170.539,205.556,178.62C218.519,186.7,231.481,194.108,244.444,200.842C257.407,207.576,270.37,213.636,283.333,219.024C296.296,224.411,309.259,229.125,322.222,233.165C335.185,237.205,348.148,240.572,361.111,243.266C374.074,245.96,387.037,247.98,393.519,248.99L400,250",
    powerDecreaseExponent2:
        "M50,50L56.481,51.925C62.963,53.851,75.926,57.702,88.889,61.677C101.852,65.653,114.815,69.753,127.778,74.005C140.741,78.257,153.704,82.662,166.667,87.26C179.63,91.857,192.593,96.647,205.556,101.691C218.519,106.735,231.481,112.034,244.444,117.689C257.407,123.345,270.37,129.359,283.333,135.932C296.296,142.505,309.259,149.638,322.222,157.851C335.185,166.065,348.148,175.359,361.111,190.897C374.074,206.435,387.037,228.218,393.519,239.109L400,250",
    powerDecreaseExponent3:
        "M50,50L56.481,50.977C62.963,51.954,75.926,53.908,88.889,55.958C101.852,58.009,114.815,60.155,127.778,62.424C140.741,64.693,153.704,67.084,166.667,69.635C179.63,72.186,192.593,74.897,205.556,77.83C218.519,80.763,231.481,83.917,244.444,87.402C257.407,90.887,270.37,94.702,283.333,99.076C296.296,103.45,309.259,108.383,322.222,114.527C335.185,120.671,348.148,128.028,361.111,150.808C374.074,173.589,387.037,211.795,393.519,230.897L400,250",
};

const InputChartPathWrapperComponent = ({ d }: { d: string }) => {
    return (
        <div className={"min-w-12 max-w-12 rounded-sm"}>
            <div className="h-full w-full">
                <svg
                    width="450"
                    height="300"
                    role="img"
                    viewBox="0 0 450 300"
                    className="h-full w-full"
                >
                    <g className="h-full w-full">{path(d)}</g>
                </svg>
            </div>
        </div>
    );
};
const InputChartPathWrapper = React.memo(InputChartPathWrapperComponent);

const IconConstant = React.memo(() => (
    <InputChartPathWrapper d={paths["constant"]} />
));
const IconStepInterval = React.memo(() => (
    <InputChartPathWrapper d={paths["step"]} />
));
const IconLinearIncrease = React.memo(() => (
    <InputChartPathWrapper d={paths["linearIncrease"]} />
));
const IconLinearDecrease = React.memo(() => (
    <InputChartPathWrapper d={paths["linearDecrease"]} />
));
const IconPowerIncreaseExponent0 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerIncreaseExponent0"]} />
));
const IconPowerIncreaseExponent1 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerIncreaseExponent1"]} />
));
const IconPowerIncreaseExponent2 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerIncreaseExponent2"]} />
));
const IconPowerIncreaseExponent3 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerIncreaseExponent3"]} />
));
const IconPowerDecreaseExponent0 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerDecreaseExponent0"]} />
));
const IconPowerDecreaseExponent1 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerDecreaseExponent1"]} />
));
const IconPowerDecreaseExponent2 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerDecreaseExponent2"]} />
));
const IconPowerDecreaseExponent3 = React.memo(() => (
    <InputChartPathWrapper d={paths["powerDecreaseExponent3"]} />
));

type InputSettingKey =
    | "constant"
    | "step"
    | "linearIncrease"
    | "linearDecrease"
    | "powerIncreaseExponent0"
    | "powerIncreaseExponent1"
    | "powerIncreaseExponent2"
    | "powerIncreaseExponent3"
    | "powerDecreaseExponent0"
    | "powerDecreaseExponent1"
    | "powerDecreaseExponent2"
    | "powerDecreaseExponent3";

const exponents = [3, 2, 0.5, 0.33];

const getPropsByInputSettingKey: (key: InputSettingKey) => {
    settingType: InputDataSettingType;
    increases?: boolean;
    exponent?: number;
} = (key: InputSettingKey) => {
    switch (key) {
        case "constant":
            return {
                settingType: InputDataSettingType.constant,
            };
        case "step":
            return {
                settingType: InputDataSettingType["step interval"],
            };
        case "linearIncrease":
            return {
                settingType: InputDataSettingType["linear interval"],
                increases: true,
            };
        case "linearDecrease":
            return {
                settingType: InputDataSettingType["linear interval"],
                increases: false,
            };
        case "powerIncreaseExponent0":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: true,
                exponent: exponents[0],
            };
        case "powerIncreaseExponent1":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: true,
                exponent: exponents[1],
            };
        case "powerIncreaseExponent2":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: true,
                exponent: exponents[2],
            };
        case "powerIncreaseExponent3":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: true,
                exponent: exponents[3],
            };
        case "powerDecreaseExponent0":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: false,
                exponent: exponents[0],
            };
        case "powerDecreaseExponent1":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: false,
                exponent: exponents[1],
            };
        case "powerDecreaseExponent2":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: false,
                exponent: exponents[2],
            };
        case "powerDecreaseExponent3":
            return {
                settingType: InputDataSettingType["power interval"],
                increases: false,
                exponent: exponents[3],
            };
    }
};

const getKeyForInputDataItem = (
    settingType: InputDataSettingType,
    increases: boolean,
    exponent: number,
) => {
    switch (settingType) {
        case InputDataSettingType.constant:
            return "constant";
        case InputDataSettingType["step interval"]:
            return "step";
        case InputDataSettingType["linear interval"]:
            switch (increases) {
                case true:
                    return "linearIncrease";
                case false:
                    return "linearDecrease";
            }
        case InputDataSettingType["power interval"]:
            switch (increases) {
                case true:
                    switch (exponent) {
                        case exponents[0]:
                            return "powerIncreaseExponent0";
                        case exponents[1]:
                            return "powerIncreaseExponent1";
                        case exponents[2]:
                            return "powerIncreaseExponent2";
                        case exponents[3]:
                            return "powerIncreaseExponent3";
                    }
                case false:
                    switch (exponent) {
                        case exponents[0]:
                            return "powerDecreaseExponent0";
                        case exponents[1]:
                            return "powerDecreaseExponent1";
                        case exponents[2]:
                            return "powerDecreaseExponent2";
                        case exponents[3]:
                            return "powerDecreaseExponent3";
                    }
            }
    }
};

const getNewInputDataItem = (
    previousInputDataItem: InputDataItem,
    newSetting: InputDataSettingType,
    previousIncreases?: boolean,
    newIncreases?: boolean,
    newExponent?: number,
) => {
    let newInputDataItem = {
        ...previousInputDataItem,
        setting_type: newSetting,
        exponent:
            newSetting === InputDataSettingType["power interval"]
                ? newExponent ?? 2
                : previousInputDataItem.exponent,
        step_values:
            newSetting === InputDataSettingType["step interval"]
                ? previousInputDataItem.step_values.map((value) =>
                      Number(value),
                  )
                : previousInputDataItem.step_values,
    } as InputDataItem;
    if (
        newIncreases !== undefined &&
        previousIncreases !== undefined &&
        !(newIncreases == previousIncreases)
    ) {
        // switch initial/final
        newInputDataItem = {
            ...newInputDataItem,
            initial: newInputDataItem.final,
            final: newInputDataItem.initial,
        };
    }
    return newInputDataItem;
};

export const useInputSettingKey = (
    inputDataItem: InputDataItem,
    setInputDataItem: (inputDataItem: InputDataItem) => Promise<void>,
) => {
    const inputSettingKey = useMemo<InputSettingKey>(() => {
        return getKeyForInputDataItem(
            inputDataItem.setting_type,
            inputDataItem.initial <= inputDataItem.final,
            inputDataItem.exponent,
        );
    }, [inputDataItem]);

    const handleSetInputKey = useCallback(
        (key: InputSettingKey) => {
            if (inputSettingKey !== key) {
                const newProps = getPropsByInputSettingKey(key);
                const newItem = getNewInputDataItem(
                    inputDataItem,
                    newProps.settingType,
                    inputDataItem.initial <= inputDataItem.final,
                    newProps.increases,
                    newProps.exponent,
                );
                setInputDataItem(newItem);
            }
        },
        [inputSettingKey, inputDataItem, setInputDataItem],
    );

    return { inputSettingKey, handleSetInputKey };
};

const InputChartIconWrapperComponent = ({
    isSelected,
    isDisabled,
    clickHandler,
    children,
}: {
    isSelected?: boolean;
    isDisabled: boolean;
    clickHandler?: () => void;
    children: React.ReactNode;
}) => {
    return (
        <button
            className={`rounded-sm ${
                isSelected
                    ? "border border-blue-700"
                    : "border border-transparent"
            } bg-slate-200 transition-all hover:cursor-pointer
            hover:bg-slate-300 focus:border-blue-700 focus:outline-none focus:ring-1
            disabled:cursor-auto disabled:hover:bg-slate-200 dark:bg-slate-950
            dark:hover:bg-slate-950/50 dark:disabled:hover:bg-slate-950`}
            onClick={() => clickHandler()}
            disabled={isDisabled}
        >
            {children}
        </button>
    );
};

const InputChartIconWrapper = React.memo(InputChartIconWrapperComponent);

const InputChartIconMenuComponent = ({
    inputSettingKey,
    handleSetInputKey,
    isDisabled,
}: {
    inputSettingKey: InputSettingKey;
    handleSetInputKey: (key: InputSettingKey) => void;
    isDisabled: boolean;
}) => {
    return (
        <div className="flex flex-col space-y-1">
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "constant"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("constant")}
                >
                    <IconConstant />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "linearIncrease"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("linearIncrease")}
                >
                    <IconLinearIncrease />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "linearDecrease"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("linearDecrease")}
                >
                    <IconLinearDecrease />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "step"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("step")}
                >
                    <IconStepInterval />
                </InputChartIconWrapper>
            </div>
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerIncreaseExponent0"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent0")
                    }
                >
                    <IconPowerIncreaseExponent0 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerIncreaseExponent1"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent1")
                    }
                >
                    <IconPowerIncreaseExponent1 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerIncreaseExponent2"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent2")
                    }
                >
                    <IconPowerIncreaseExponent2 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerIncreaseExponent3"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent3")
                    }
                >
                    <IconPowerIncreaseExponent3 />
                </InputChartIconWrapper>
            </div>
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerDecreaseExponent0"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent0")
                    }
                >
                    <IconPowerDecreaseExponent0 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerDecreaseExponent1"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent1")
                    }
                >
                    <IconPowerDecreaseExponent1 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerDecreaseExponent2"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent2")
                    }
                >
                    <IconPowerDecreaseExponent2 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "powerDecreaseExponent3"}
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent3")
                    }
                >
                    <IconPowerDecreaseExponent3 />
                </InputChartIconWrapper>
            </div>
        </div>
    );
};

const SimpleInputChartIconMenuComponent = ({
    inputSettingKey,
    handleSetInputKey,
    isDisabled,
}: {
    inputSettingKey: InputSettingKey;
    handleSetInputKey: (key: InputSettingKey) => void;
    isDisabled: boolean;
}) => {
    return (
        <div className="flex flex-col space-y-1">
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "linearIncrease"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("linearIncrease")}
                >
                    <IconLinearIncrease />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "linearDecrease"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("linearDecrease")}
                >
                    <IconLinearDecrease />
                </InputChartIconWrapper>
            </div>
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={
                        inputSettingKey === "powerIncreaseExponent0" ||
                        inputSettingKey === "powerIncreaseExponent1"
                    }
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent0")
                    }
                >
                    <IconPowerIncreaseExponent0 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={
                        inputSettingKey === "powerDecreaseExponent0" ||
                        inputSettingKey === "powerDecreaseExponent1"
                    }
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent0")
                    }
                >
                    <IconPowerDecreaseExponent0 />
                </InputChartIconWrapper>
            </div>
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={
                        inputSettingKey === "powerIncreaseExponent2" ||
                        inputSettingKey === "powerIncreaseExponent3"
                    }
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerIncreaseExponent3")
                    }
                >
                    <IconPowerIncreaseExponent3 />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={
                        inputSettingKey === "powerDecreaseExponent2" ||
                        inputSettingKey === "powerDecreaseExponent3"
                    }
                    isDisabled={isDisabled}
                    clickHandler={() =>
                        handleSetInputKey("powerDecreaseExponent3")
                    }
                >
                    <IconPowerDecreaseExponent3 />
                </InputChartIconWrapper>
            </div>
            <div className="flex space-x-1">
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "constant"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("constant")}
                >
                    <IconConstant />
                </InputChartIconWrapper>
                <InputChartIconWrapper
                    isSelected={inputSettingKey === "step"}
                    isDisabled={isDisabled}
                    clickHandler={() => handleSetInputKey("step")}
                >
                    <IconStepInterval />
                </InputChartIconWrapper>
            </div>
        </div>
    );
};

export const InputChartIconMenu = React.memo(InputChartIconMenuComponent);
export const SimpleInputChartIconMenu = React.memo(
    SimpleInputChartIconMenuComponent,
);

// const iconChartExponents = [6, 2, 0.5, 0.25];

// const stepData = [10, 10, 30, 30, 50, 50, 70, 70, 90, 90]?.map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const constantData = [50, 50, 50, 50, 50, 50, 50, 50, 50, 50]?.map(
//     (value, i) => ({
//         x: i,
//         y: value,
//     }),
// );
// const linearIncreaseData = [
//     {
//         x: 0,
//         y: 0,
//     },
//     {
//         x: 9,
//         y: 100,
//     },
// ];
// const linearDecreaseData = [
//     {
//         x: 0,
//         y: 100,
//     },
//     {
//         x: 9,
//         y: 0,
//     },
// ];
// const powerIncreaseExponent0Data = powspace(
//     1,
//     100,
//     iconChartExponents[0],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerIncreaseExponent1Data = powspace(
//     1,
//     100,
//     iconChartExponents[1],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerIncreaseExponent2Data = powspace(
//     1,
//     100,
//     iconChartExponents[2],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerIncreaseExponent3Data = powspace(
//     1,
//     100,
//     iconChartExponents[3],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerDecreaseExponent0Data = powspace(
//     100,
//     1,
//     iconChartExponents[0],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerDecreaseExponent1Data = powspace(
//     100,
//     1,
//     iconChartExponents[1],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerDecreaseExponent2Data = powspace(
//     100,
//     1,
//     iconChartExponents[2],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));
// const powerDecreaseExponent3Data = powspace(
//     100,
//     1,
//     iconChartExponents[3],
//     10,
// ).map((value, i) => ({
//     x: i,
//     y: value,
// }));

// const InputChartIconComponent = ({
//     data,
//     interpolation,
// }: {
//     data: {
//         x: number;
//         y: number;
//     }[];
//     interpolation: string;
// }) => {
//     return (
//         <div
//             className={`min-w-12 max-w-12 rounded-sm bg-slate-200 hover:cursor-pointer
//                 hover:bg-slate-300 dark:bg-slate-950 dark:hover:bg-slate-950/50`}
//         >
//             <VictoryLine
//                 style={{
//                     data: {
//                         stroke: "#1d4ed8",
//                         strokeWidth: 12,
//                     },
//                 }}
//                 interpolation={interpolation as InterpolationPropType}
//                 data={data}
//             />
//         </div>
//     );
// };
// const InputChartIcon = React.memo(InputChartIconComponent);

// icons by setting type and properties
// const IconConstant = React.memo(() => (
//     <InputChartIcon data={constantData} interpolation="linear" />
// ));
// const IconStepInterval = React.memo(() => (
//     <InputChartIcon data={stepData} interpolation="step" />
// ));
// const IconLinearIncrease = React.memo(() => (
//     <InputChartIcon data={linearIncreaseData} interpolation="linear" />
// ));
// const IconLinearDecrease = React.memo(() => (
//     <InputChartIcon data={linearDecreaseData} interpolation="linear" />
// ));
// const IconPowerIncreaseExponent0 = React.memo(() => (
//     <InputChartIcon data={powerIncreaseExponent0Data} interpolation="basis" />
// ));
// const IconPowerIncreaseExponent1 = React.memo(() => (
//     <InputChartIcon data={powerIncreaseExponent1Data} interpolation="basis" />
// ));
// const IconPowerIncreaseExponent2 = React.memo(() => (
//     <InputChartIcon data={powerIncreaseExponent2Data} interpolation="basis" />
// ));
// const IconPowerIncreaseExponent3 = React.memo(() => (
//     <InputChartIcon data={powerIncreaseExponent3Data} interpolation="basis" />
// ));
// const IconPowerDecreaseExponent0 = React.memo(() => (
//     <InputChartIcon data={powerDecreaseExponent0Data} interpolation="basis" />
// ));
// const IconPowerDecreaseExponent1 = React.memo(() => (
//     <InputChartIcon data={powerDecreaseExponent1Data} interpolation="basis" />
// ));
// const IconPowerDecreaseExponent2 = React.memo(() => (
//     <InputChartIcon data={powerDecreaseExponent2Data} interpolation="basis" />
// ));
// const IconPowerDecreaseExponent3 = React.memo(() => (
//     <InputChartIcon data={powerDecreaseExponent3Data} interpolation="basis" />
// ));
