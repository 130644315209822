import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";
import { ContentBlockType } from "@/models";
import { TextEditor, toRichText } from "@/modules/text-editor";
import React from "react";
import { createPortal } from "react-dom";

const richTextTypes = [
    ContentBlockType["Rich Text"],
    ContentBlockType["Button"],
    ContentBlockType["Link"],
];

export function TextEditorWrapper() {
    const { data: activeContentBlock } = useGetActiveContentBlock();

    return !!activeContentBlock &&
        !!document.querySelector(
            `[data-editor-portal-id="${activeContentBlock.id}"]`
        ) &&
        richTextTypes.includes(activeContentBlock.content_block_type)
        ? createPortal(
              <TextEditor
                  theme={activeContentBlock.theme}
                  richText={toRichText(activeContentBlock).rich_text}
                  editedId={activeContentBlock.id}
              />,
              document.querySelector(
                  `[data-editor-portal-id="${activeContentBlock.id}"]`
              )
          )
        : null;
}
