import { sapienAxios } from "@/inertia-utils/hooks";
import { InteractiveModuleArchetype } from "../types";
import { useMutation } from "@tanstack/react-query";

async function saveArchetype(
    archetype: InteractiveModuleArchetype & { simple_update: boolean },
) {
    if (!!archetype.id) {
        const { data } = await sapienAxios.put<
            { archetype: InteractiveModuleArchetype },
            "module-archetype.update"
        >("module-archetype.update", archetype, {
            module_archetype: archetype.id,
        });
        return data?.archetype;
    } else {
        const { data } = await sapienAxios.post<
            { archetype: InteractiveModuleArchetype },
            "module-archetype.store"
        >("module-archetype.store", archetype);
        return data?.archetype;
    }
}

export function useSaveArchetype() {
    return useMutation({
        mutationFn: saveArchetype,
    });
}

async function toggleArchetypePublishedAt(
    archetype: InteractiveModuleArchetype,
) {
    if (!!archetype.published_at) {
        const { data } = await sapienAxios.put<
            { archetype: InteractiveModuleArchetype },
            "module-archetype.unpublish"
        >(
            "module-archetype.unpublish",
            { id: archetype.id },
            { interactiveModuleArchetype: archetype.id },
        );
        return data?.archetype;
    } else {
        const { data } = await sapienAxios.put<
            { archetype: InteractiveModuleArchetype },
            "module-archetype.publish"
        >(
            "module-archetype.publish",
            { id: archetype.id },
            { interactiveModuleArchetype: archetype.id },
        );
        return data?.archetype;
    }
}

export function useToggleArchetypePublishedAt() {
    return useMutation({
        mutationFn: toggleArchetypePublishedAt,
    });
}
