import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { LaravelRoutes } from "@/ziggy-shims";

export function useGetContentBlockWithModelDataSources(
    contentBlockId: string,
    overrideRoute?: keyof LaravelRoutes,
    callback?: (data: {
        contentBlockWithModelDataSources: ContentBlockShape;
    }) => { contentBlockWithModelDataSources: ContentBlockShape }
) {
    const getInitialDefaultVariableValues = async (contentBlockId: string) => {
        if (!contentBlockId)
            return Promise.resolve({
                contentBlockWithModelDataSources: {
                    contentBlocks: [],
                } as ContentBlockShape,
            });
        const route =
            overrideRoute ||
            sapienRoute(
                overrideRoute || "creator.design.chart.variable-values.edit",
                {
                    contentBlockId,
                }
            );

        const { data } = await axios.get<{
            contentBlockWithModelDataSources: ContentBlockShape;
        }>(route);

        const { contentBlockWithModelDataSources } = data;

        return { contentBlockWithModelDataSources };
    };

    return useQuery({
        queryKey: ["getInitialContentBlock", contentBlockId],
        queryFn: () => getInitialDefaultVariableValues(contentBlockId),
        initialData: {
            contentBlockWithModelDataSources: {
                contentBlocks: [],
            } as ContentBlockShape,
        },
        select:
            "function" === typeof callback
                ? callback
                : ({ contentBlockWithModelDataSources }) => ({
                      contentBlockWithModelDataSources,
                  }),
        enabled: !!contentBlockId,
    });
}
