import React, { useMemo } from "react";
import { InputDataItem, InputDataSettingType } from "../types";
import {
    DomainTuple,
    ForAxes,
    InterpolationPropType,
    VictoryAxis,
    VictoryChart,
    VictoryLine,
} from "victory";
import { powspace, tickFormat } from "../utils";

type SettingsChartProps = {
    data: {
        x: number;
        y: number;
    }[];
    maxY: number;
    domain: {
        x: number[];
        y: number[];
    };
    interpolation: string;
};

export const getSettingsVariableChartProps = ({
    modelVariableMin,
    modelVariableMax,
    inputDataItem,
    settingType,
}: {
    modelVariableMin: number;
    modelVariableMax: number;
    inputDataItem: InputDataItem;
    settingType: InputDataSettingType;
}) => {
    const data = useMemo(() => {
        if (
            settingType === InputDataSettingType["boolean constant"] ||
            settingType === InputDataSettingType["boolean series"]
        ) {
            return [];
        }
        return settingType === InputDataSettingType["step interval"]
            ? inputDataItem?.step_values?.map((value, i) => ({
                  x: i + 1,
                  y: value,
              }))
            : settingType === InputDataSettingType.constant
              ? inputDataItem?.step_values?.map((value, i) => ({
                    x: i + 1,
                    y: inputDataItem.numerical_value,
                }))
              : settingType === InputDataSettingType["linear interval"]
                ? [
                      {
                          x: 1,
                          y: inputDataItem.initial,
                      },
                      {
                          x: inputDataItem?.step_values?.length,
                          y: inputDataItem.final,
                      },
                  ]
                : powspace(
                      inputDataItem.initial,
                      inputDataItem.final,
                      inputDataItem.exponent,
                      inputDataItem?.step_values?.length,
                  ).map((value, i) => ({
                      x: i + 1,
                      y: value,
                  }));
    }, [inputDataItem, settingType]);

    const maxY = useMemo(() => {
        return data?.length > 0 ? Math.max(...data.map((value) => value.y)) : 0;
    }, [data]);

    const domain = useMemo(() => {
        return {
            x: [1, inputDataItem?.step_values?.length],
            y: [modelVariableMin, modelVariableMax],
        };
    }, [inputDataItem, modelVariableMin, modelVariableMax]);

    const interpolation = useMemo(() => {
        return settingType === InputDataSettingType["step interval"]
            ? "step"
            : settingType === InputDataSettingType["power interval"]
              ? "natural"
              : "linear";
    }, [settingType]);

    return { data, maxY, domain, interpolation } as SettingsChartProps;
};

const SettingsVariableChartDisplayComponent = ({
    data,
    maxY,
    domain,
    interpolation,
    color,
}: SettingsChartProps & {
    color: string;
}) => {
    return (
        <VictoryChart domain={domain as ForAxes<DomainTuple>}>
            <VictoryLine
                style={{
                    data: {
                        stroke: "#0075ff",
                        strokeWidth: 8,
                    },
                }}
                interpolation={interpolation as InterpolationPropType}
                data={data}
            />
            <VictoryAxis
                dependentAxis
                style={{
                    tickLabels: {
                        padding: 5,
                        fontSize: 14,
                        fontWeight: 300,
                        stroke: color,
                        fill: color,
                    },
                    axis: {
                        strokeOpacity: 0.6,
                        stroke: color,
                    },
                    ticks: {
                        strokeOpacity: 0.6,
                        stroke: color,
                    },
                }}
                tickFormat={maxY > 100000 ? tickFormat : null}
            />
            <VictoryAxis
                style={{
                    axisLabel: { padding: 20, fontSize: 14 },
                    tickLabels: {
                        padding: 0,
                        fontSize: 14,
                        fontWeight: 300,
                        stroke: color,
                        fill: color,
                    },
                    grid: { strokeOpacity: 0.1 },
                    axis: {
                        strokeOpacity: 0.6,
                        stroke: color,
                    },
                    ticks: {
                        strokeOpacity: 0.6,
                        stroke: color,
                    },
                }}
            />
        </VictoryChart>
    );
};

const SettingsVariableChartDisplay = React.memo(
    SettingsVariableChartDisplayComponent,
);

const SettingsVariableChartComponent = ({
    data,
    maxY,
    domain,
    interpolation,
    colorModeClass,
}: SettingsChartProps & { colorModeClass: "" | "dark" }) => {
    return (
        <>
            {colorModeClass !== "dark" ? (
                <SettingsVariableChartDisplay
                    data={data}
                    maxY={maxY}
                    domain={domain}
                    interpolation={interpolation}
                    color={"#334155"}
                />
            ) : (
                <SettingsVariableChartDisplay
                    data={data}
                    maxY={maxY}
                    domain={domain}
                    interpolation={interpolation}
                    color={"#94a3b8"}
                />
            )}
        </>
    );
};

export const SettingsVariableChart = React.memo(SettingsVariableChartComponent);
