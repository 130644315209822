import { useMutation } from "@tanstack/react-query";
import { ChartBlock } from "../types";
import { sapienAxios } from "@/inertia-utils/hooks";

async function saveChartBlock(chartBlock: ChartBlock) {
    if (!!chartBlock.created_at) {
        const { data } = await sapienAxios.put<
            { chart: ChartBlock },
            "module-chart.update"
        >("module-chart.update", chartBlock, { module_chart: chartBlock.id });
        return data?.chart;
    } else {
        delete chartBlock.id;
        const { data } = await sapienAxios.post<
            { chart: ChartBlock },
            "module-chart.store"
        >("module-chart.store", chartBlock);
        return data?.chart;
    }
}

export function useSaveChartBlock() {
    return useMutation({
        mutationFn: saveChartBlock,
    });
}

export async function deleteChartBlock(chartBlockId: string) {
    const { data } = await sapienAxios.delete("module-chart.destroy", {
        module_chart: chartBlockId,
    });
    return data;
}
