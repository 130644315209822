import React, { useCallback, useEffect, useState } from "react";
import { ModelVariable, TimeHorizon } from "@/models";
import { FormLabel, FormInputRadioCheckbox } from "@/components";
import { TimeHorizonSelectionFormElement } from "../TimeHorizonSelectionFormElement";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/feather/plus";
import { x } from "react-icons-kit/feather/x";
import ModelVariableComboBox from "./ModelVariableComboBox";

export const ModelDataSelectionForm = ({
    modelVariables,
    timeHorizons,
    selectedModelVariables,
    setSelectedModelVariables,
    selectedTimeHorizons,
    setSelectedTimeHorizons,
    isSingleModelVariableSelection,
    isSingleTimeHorizonSelection,
    hideTimeHorizons,
    label,
    hasError = false,
}: {
    modelVariables: ModelVariable[];
    timeHorizons: TimeHorizon[];
    selectedModelVariables: ModelVariable[];
    setSelectedModelVariables: (modelVariables: ModelVariable[]) => void;
    selectedTimeHorizons: TimeHorizon[];
    setSelectedTimeHorizons: (timeHorizons: TimeHorizon[]) => void;
    isSingleModelVariableSelection: boolean;
    isSingleTimeHorizonSelection: boolean;
    hideTimeHorizons?: boolean;
    label?: string;
    hasError?: boolean;
}) => {
    const [showTimeBasedVariables, setShowTimeBasedVariables] =
        useState<boolean>();

    const [filteredVariables, setFilteredVariables] =
        useState<ModelVariable[]>();

    const [variableMap, setVariableMap] = useState<{
        [index: string]: ModelVariable;
    }>({});

    useEffect(() => {
        if (
            !isSingleModelVariableSelection &&
            !!selectedModelVariables?.length
        ) {
            if (selectedModelVariables[0].uses_time) {
                if (!showTimeBasedVariables) {
                    setShowTimeBasedVariables(true);
                }
            } else if (showTimeBasedVariables) {
                setShowTimeBasedVariables(false);
            }
        }
    }, []);

    useEffect(() => {
        if (!isSingleModelVariableSelection && !!modelVariables?.length) {
            setFilteredVariables(
                modelVariables.filter(
                    (variable) => variable.uses_time === showTimeBasedVariables,
                ),
            );
        }
    }, [
        isSingleModelVariableSelection,
        showTimeBasedVariables,
        modelVariables,
    ]);

    useEffect(() => {
        if (!!selectedModelVariables?.length) {
            setVariableMap(
                selectedModelVariables.reduce(
                    (map, variable, index) => ({
                        ...map,
                        [variable.id || index]: variable,
                    }),
                    {},
                ),
            );
        }
    }, [selectedModelVariables]);

    const handleSetSingleModelVariableSelection = useCallback(
        (modelVariable: ModelVariable | null) => {
            if (!!modelVariable) {
                setSelectedModelVariables([modelVariable]);

                // update selected time horizons based on variable
                if (modelVariable.uses_time) {
                    if (!selectedTimeHorizons.length) {
                        // variable uses time and no THs are selected,
                        // so automatically add the first one for convenience
                        // (this works whether isSingleTimeHorizonSelection or not)
                        setSelectedTimeHorizons(
                            timeHorizons.filter(
                                (timeHorizon) => timeHorizon.time_index === 0,
                            ),
                        );
                    }
                } else {
                    // variable doesn't use time, so deselect any THs
                    setSelectedTimeHorizons([]);
                }
            } else {
                setSelectedModelVariables([]);
                setSelectedTimeHorizons([]);
            }
        },
        [
            setSelectedModelVariables,
            timeHorizons,
            selectedTimeHorizons,
            setSelectedTimeHorizons,
        ],
    );

    return (
        <div className="p-4">
            {!modelVariables?.length && (
                <div className="rounded-md bg-gray-100 bg-opacity-5 p-5 text-center italic text-gray-600">
                    {"No model variables found"}
                </div>
            )}

            {/* single MV form element */}
            {isSingleModelVariableSelection && !!modelVariables?.length && (
                <ModelVariableComboBox
                    modelVariables={modelVariables}
                    selectedModelVariables={selectedModelVariables}
                    handleSelectModelVariable={
                        handleSetSingleModelVariableSelection
                    }
                    hasError={hasError}
                />
            )}

            {/* if multiple MVs possible, must choose either uses_time or not so all match */}
            {!isSingleModelVariableSelection && !!modelVariables?.length && (
                <div className="flex flex-row items-center">
                    <span>{"Show model variables that"}</span>
                    <FormLabel
                        style={{
                            display: "inline-flex",
                            margin: "0 0 0 0.5rem",
                        }}
                    >
                        <FormInputRadioCheckbox
                            type="radio"
                            checked={showTimeBasedVariables === true}
                            onChange={() => {
                                if (
                                    !showTimeBasedVariables &&
                                    (!selectedModelVariables?.length ||
                                        selectedModelVariables.every(
                                            (variable) => !variable.id,
                                        ) ||
                                        window.confirm(
                                            "Discard selected variables that do not use time horizons?",
                                        ))
                                ) {
                                    setSelectedModelVariables([
                                        {
                                            id: "",
                                            label: "",
                                        } as ModelVariable,
                                    ]);
                                    setShowTimeBasedVariables(true);
                                    setSelectedTimeHorizons(
                                        timeHorizons?.filter(
                                            (timeHorizon) =>
                                                timeHorizon.time_index === 0,
                                        ) || [],
                                    );
                                }
                            }}
                            disabled={false}
                        />
                        {"use time horizons"}
                    </FormLabel>
                    <FormLabel
                        style={{
                            display: "inline-flex",
                            margin: "0 0 0 0.5rem",
                        }}
                    >
                        <FormInputRadioCheckbox
                            type="radio"
                            checked={showTimeBasedVariables === false}
                            onChange={() => {
                                if (
                                    showTimeBasedVariables !== false &&
                                    (!selectedModelVariables?.length ||
                                        selectedModelVariables.every(
                                            (variable) => !variable.id,
                                        ) ||
                                        window.confirm(
                                            "Discard selected variables that use time horizons?",
                                        ))
                                ) {
                                    setSelectedModelVariables([
                                        {
                                            id: "",
                                            label: "",
                                        } as ModelVariable,
                                    ]);
                                    setShowTimeBasedVariables(false);
                                    setSelectedTimeHorizons([]);
                                }
                            }}
                            disabled={false}
                        />
                        {"do not use time horizons"}
                    </FormLabel>
                </div>
            )}

            {/* multiple MVs form element */}
            {!isSingleModelVariableSelection && !!filteredVariables?.length && (
                <div className="mt-4">
                    <div className="flex flex-row items-center">
                        <div className="font-medium">{"Model Variables"}</div>
                        <button
                            type="button"
                            className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-gray-800 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 focus:ring-blue-300"
                            disabled={false}
                            onClick={() =>
                                setSelectedModelVariables([
                                    ...selectedModelVariables.filter(
                                        (variable) => !!variable.id,
                                    ),
                                    {
                                        id: "",
                                        label: "",
                                    } as ModelVariable,
                                ])
                            }
                        >
                            <Icon
                                icon={plus}
                                size={16}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </button>
                    </div>
                    {!!selectedModelVariables?.length &&
                        selectedModelVariables.map((selectedVariable) => (
                            <div
                                className="mt-2 flex flex-row items-center"
                                key={selectedVariable.id}
                            >
                                <ModelVariableComboBox
                                    modelVariables={modelVariables}
                                    selectedModelVariables={
                                        selectedModelVariables
                                    }
                                    handleSelectModelVariables={
                                        setSelectedModelVariables
                                    }
                                    hasError={hasError}
                                />

                                <button
                                    type="button"
                                    className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-gray-800 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 focus:ring-blue-300"
                                    disabled={
                                        selectedModelVariables?.length === 1
                                    }
                                    onClick={() =>
                                        setSelectedModelVariables(
                                            selectedModelVariables.filter(
                                                (variable) =>
                                                    variable.id !==
                                                    selectedVariable.id,
                                            ),
                                        )
                                    }
                                >
                                    <Icon
                                        icon={x}
                                        size={16}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </button>
                            </div>
                        ))}
                </div>
            )}

            {/* single or multiple TH form element */}
            {/* (only show if we have THs and selected MVs using time) */}
            {/* (NB: this form assumes multiple THs means continuous ranges) */}
            {!!timeHorizons?.length &&
                !hideTimeHorizons &&
                !!selectedModelVariables?.length &&
                (selectedModelVariables[0].uses_time ||
                    (!isSingleModelVariableSelection &&
                        showTimeBasedVariables)) && (
                    <TimeHorizonSelectionFormElement
                        timeHorizons={timeHorizons}
                        selectedTimeHorizons={selectedTimeHorizons}
                        setSelectedTimeHorizons={setSelectedTimeHorizons}
                        isSingleTimeHorizonSelection={
                            isSingleTimeHorizonSelection
                        }
                    />
                )}
        </div>
    );
};
