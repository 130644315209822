import React from "react";
import usePageTransition from "./usePageTransition";

export default function LayoutWithPageTransition({children}) {
    const {className, direction} = usePageTransition(children.props.lesson);

    return (
        <div className={`with-transition transition  w-full ${className} ${direction}`}>
            {children}
        </div>
    );
}
