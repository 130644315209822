import React, { useEffect } from "react";
import { ChartSeries, ChartType, fillableTypes } from "./ChartWizardReducer";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import ChartSeriesColorForm from "./ChartSeriesColorForm";
type Props = {
    chartSeries: ChartSeries;
    updateEditedSeries: (chartSeries: ChartSeries) => void;
    editedIndex: number;
};

export default function ManualChartValueEntry({
    chartSeries,
    updateEditedSeries,
    editedIndex,
}: Props) {


    const colorProperty = fillableTypes.includes(chartSeries?.chartType)
        ? "fill"
        : "stroke";

    useEffect(() => {
        if (!chartSeries.chart_data) {
            updateEditedSeries({
                ...chartSeries,
                chart_data: [
                    {
                        label: chartSeries.label,
                        theme: chartSeries.theme,
                        x: 0,
                        y: undefined,
                        chartType: chartSeries.chartType,
                    },
                ],
            });
        }
    }, [chartSeries]);

    return (
        <>
            <div className="flex flex-col space-y-4 w-full">
                <label className="capitalize">Manual Chart Value Entry</label>
                <ChartSeriesColorForm
                    chartSeries={chartSeries}
                    colorProperty={colorProperty}
                    index={editedIndex}
                    updateEditedSeries={updateEditedSeries}
                />
                <div className="flex flex-col justify-center space-y-2 items-start w-full">
                    <label>Label</label>
                    <div className="w-full flex flex-col max-w-3xl">
                        <input
                            type="text"
                            className="w-full  max-w-3xl block rounded-md  sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                            value={chartSeries?.chart_data?.[0]?.label || ""}
                            placeholder="Enter a label for this series"
                            onChange={(e) => {
                                updateEditedSeries({
                                    ...chartSeries,
                                    label: e.target.value,
                                    chart_data: chartSeries.chart_data.map(
                                        (dataPoint) => {
                                            return {
                                                ...dataPoint,
                                                label: e.target.value,
                                            };
                                        }
                                    ),
                                });
                            }}
                        />
                    </div>
                </div>
                {chartSeries?.chart_data?.map((dataPoint, index) => {
                    return (
                        <div className="w-full flex items-stretch justify-center self-stretch max-w-[15rem]">
                            <input
                                type="number"
                                className="w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                                value={dataPoint.y}
                                placeholder="Enter a value for this data point"
                                onChange={(e) => {
                                    updateEditedSeries({
                                        ...chartSeries,
                                        chart_data: chartSeries.chart_data.map(
                                            (dataPoint, i) => {
                                                if (i === index) {
                                                    return {
                                                        ...dataPoint,
                                                        y: Number(
                                                            e.target.value
                                                        ),
                                                        theme: {
                                                            ...dataPoint.theme,
                                                            labelProps: {
                                                                ...dataPoint
                                                                    .theme
                                                                    .labelProps,
                                                                y: Number(
                                                                    e.target
                                                                        .value
                                                                ),
                                                            },
                                                        },
                                                    };
                                                }
                                                return dataPoint;
                                            }
                                        ),
                                    });
                                }}
                            />
                        </div>
                    );
                })}

                <div className="flex">
                    <button
                        onClick={() => {
                            updateEditedSeries({
                                ...chartSeries,
                                chart_data: [
                                    ...chartSeries?.chart_data,
                                    {
                                        label: chartSeries.label,
                                        theme: chartSeries.theme,
                                        x: chartSeries.chart_data.length,
                                    } as FormattedChartValue,
                                ],
                            });
                        }}
                    >
                        Add Data Point
                    </button>
                </div>
            </div>
        </>
    );
}
