import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { useAuthStore } from "@/hooks/store";
import { SapienInertia, usePage } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import { ContentBlockTemplateShape } from "@/models";
import { useModalQueryParams, useSelectedSimulation } from "@/hooks";
import ContentBlockTemplateCard from "./ContentBlockTemplateCard";
import { SapienAdminPage } from "@/inertia-utils/types";
import { useQuery } from "@tanstack/react-query";
import { getTemplateById } from "./getTemplateById";
import { queryClient } from "../QueryClient";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function PublishTemplateModalContent() {
    const { queryParams, closeModal } = useModalQueryParams(
        "PublishTemplateModal"
    );
    const { templateToPublishId } = queryParams;

    const { isLoading, data: templateToPublish } = useQuery({
        queryKey: ["PublishTemplateModal", templateToPublishId],
        queryFn: () => getTemplateById(templateToPublishId),
        enabled: !!templateToPublishId,
    });

    const { user } = useAuthStore();
    const { selectedSimulation } = useSelectedSimulation();

    const { tenant } = usePage<SapienAdminPage>()?.props;

    const [areSwitchesDisabled, setAreSwitchesDisabled] = useState(false);

    if (isLoading)
        return (
            <div className="p-4 flex flex-col animate-pulse gap-2 py-4" >
                <div className="border-b flex justify-between py-8">
                    <div className="h-12 w-full bg-white/80 rounded-md" />
                </div>
                <div className="border-b flex gap-2 py-8">
                    <div className="h-8 w-24 bg-white/80 rounded-md" />
                    <div className="h-8 w-24 bg-white/80 rounded-md" />
                    <div className="h-8 w-24 bg-white/80 rounded-md" />
                </div>
                <div className="grid grid-cols-3 gap-4 flex-wrap">
                    {[...Array(6).keys()].map((i) => (
                        <div className="h-64 w-full bg-white/80 rounded-md"></div>
                    ))}
                </div>
                <div className="border-t grid gap-2 grid-cols-2 py-4 justify-between">
                    <div className="h-12 rounded-xl bg-white/80" />
                    <div className="h-12 rounded-xl bg-white/80" />
                </div>
            </div>
        );
    return (
        <div
            className="flex flex-col max-h-[95vh] p-6"
            data-testid="publish-template-modal-wrapper"
        >
            {!!tenant && !!templateToPublish && (
                <div
                    className="grid grid-cols-2 p-2 max-h-[90vh] overflow-auto relative text-white"
                    data-testid="publish-template-modal"
                >
                    <div className="flex flex-col space-y-4 p-4 text-black">
                        <ContentBlockTemplateCard
                            template={templateToPublish}
                            isSelected={true}
                            handleClickAdd={function (
                                template: ContentBlockTemplateShape
                            ): void {}}
                        />
                    </div>
                    <div className="flex flex-col space-y-4 p-4">
                        {/* <Link
                    href={sapienRoute(
                        "admin.design.create.from-template",
                        {
                            parentContentBlockId:
                                parentContentBlockId,
                        }
                    )}
                    className="text-white absolute top-0 right-0 focus:outline-none"
                >
                    <XMarkIcon className="w-4 h-4" />
                </Link> */}
                        <Switch.Group
                            as="div"
                            className="flex items-center justify-between"
                        >
                            <span className="flex flex-grow flex-col">
                                <Switch.Label
                                    as="span"
                                    className="text-sm font-medium leading-6 "
                                    passive
                                >
                                    Publish to Simulation
                                </Switch.Label>
                                <Switch.Description
                                    as="span"
                                    className="text-sm text-gray-400"
                                >
                                    Share this template with anyone working on
                                    this simulation.
                                </Switch.Description>
                            </span>
                            <Switch
                                disabled={
                                    areSwitchesDisabled ||
                                    !!templateToPublish.published_to_simulation_at
                                }
                                checked={
                                    !!templateToPublish.published_to_simulation_at
                                }
                                onChange={() => {
                                    SapienInertia.post(
                                        "admin.design.content-block-template.publish.simulation",
                                        {
                                            content_block_template_id:
                                                templateToPublish.id,
                                            simulation_id:
                                                selectedSimulation?.id,
                                        },
                                        {},
                                        {
                                            onStart: () => {
                                                setAreSwitchesDisabled(true);
                                            },
                                            onFinish: () => {
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        "PublishTemplateModal",
                                                        templateToPublishId,
                                                    ],
                                                });
                                                setAreSwitchesDisabled(false);
                                            },
                                            only: ["locationInfo"],
                                        }
                                    );
                                }}
                                className={classNames(
                                    !!templateToPublish.published_to_simulation_at
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        !!templateToPublish.published_to_simulation_at
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                />
                            </Switch>
                        </Switch.Group>

                        <Switch.Group
                            as="div"
                            className="flex items-center justify-between"
                        >
                            <span className="flex flex-grow flex-col">
                                <Switch.Label
                                    as="span"
                                    className="text-sm font-medium leading-6 "
                                    passive
                                >
                                    Publish to everyone at {tenant?.tenant_name}
                                </Switch.Label>
                                <Switch.Description
                                    as="span"
                                    className="text-sm text-gray-400"
                                >
                                    Share this template with all creators at{" "}
                                    {tenant?.tenant_name}
                                </Switch.Description>
                            </span>
                            <Switch
                                disabled={
                                    areSwitchesDisabled ||
                                    !!templateToPublish.published_to_all_at
                                }
                                checked={
                                    !!templateToPublish.published_to_all_at
                                }
                                onChange={() => {
                                    SapienInertia.post(
                                        "admin.design.content-block-template.publish.tenant",
                                        {
                                            content_block_template_id:
                                                templateToPublish.id,
                                        },
                                        {},
                                        {
                                            onStart: () => {
                                                setAreSwitchesDisabled(true);
                                            },
                                            onFinish: () => {
                                                setAreSwitchesDisabled(false);
                                                queryClient.invalidateQueries({
                                                    queryKey: [
                                                        "PublishTemplateModal",
                                                        templateToPublishId,
                                                    ],
                                                });
                                            },
                                            only: ["locationInfo"],
                                        }
                                    );
                                }}
                                className={classNames(
                                    templateToPublish.published_to_all_at
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                )}
                            >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        templateToPublish.published_to_all_at
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                />
                            </Switch>
                        </Switch.Group>
                        {user.roles?.some(
                            (role) => role.name === "Sapien Super Admin"
                        ) && (
                            <Switch.Group
                                as="div"
                                className="flex items-center justify-between"
                            >
                                <span className="flex flex-grow flex-col">
                                    <Switch.Label
                                        as="span"
                                        className="text-sm font-medium leading-6 "
                                        passive
                                    >
                                        Publish to everyone
                                    </Switch.Label>
                                    <Switch.Description
                                        as="span"
                                        className="text-sm text-gray-400"
                                    >
                                        Share this template with all creators,
                                        accross all tenants
                                    </Switch.Description>
                                </span>
                                <Switch
                                    disabled={
                                        areSwitchesDisabled ||
                                        !!templateToPublish.published_to_all_tenants_at
                                    }
                                    checked={
                                        !!templateToPublish.published_to_all_tenants_at
                                    }
                                    onChange={() => {
                                        SapienInertia.post(
                                            "admin.design.content-block-template.publish.all-tenants",
                                            {
                                                content_block_template_id:
                                                    templateToPublish.id,
                                            },
                                            {},
                                            {
                                                onStart: () => {
                                                    setAreSwitchesDisabled(
                                                        true
                                                    );
                                                },
                                                onFinish: () => {
                                                    setAreSwitchesDisabled(
                                                        false
                                                    );
                                                    queryClient.invalidateQueries(
                                                        {
                                                            queryKey: [
                                                                "PublishTemplateModal",
                                                                templateToPublishId,
                                                            ],
                                                        }
                                                    );
                                                },
                                                only: ["locationInfo"],
                                            }
                                        );
                                    }}
                                    className={classNames(
                                        templateToPublish.published_to_all_tenants_at
                                            ? "bg-indigo-600"
                                            : "bg-gray-200",
                                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            templateToPublish.published_to_all_tenants_at
                                                ? "translate-x-5"
                                                : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>
                        )}
                    </div>
                </div>
            )}

            <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                <div className="flex justify-center items-center">
                    <button
                        onClick={() => {
                            closeModal();
                        }}
                        className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function PublishTemplateModal() {
    const { isAtModalUrl, closeModal, queryParams } = useModalQueryParams(
        "PublishTemplateModal"
    );

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {
                closeModal();
            }}
            size="xl"
            backgroundColor="#111928"
            styles={{ margin: "2.5vh auto" }}
        >
            {isAtModalUrl && <PublishTemplateModalContent />}
        </ModalContainer>
    );
}
