import { sapienRoute } from "@/inertia-utils/hooks";
import { ModelBlock } from "@/models";
import axios from "axios";

export async function getModelBlocks(simulationId: string) {
    const route = sapienRoute("creator.design.model-blocks.index", {
        simulationId,
    });

    const { data } = await axios.get<ModelBlock[]>(route);

    return data;
}
