import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    uploadFile,
    uploadVideo,
    setUploadingPercentage,
    setUploading,
} from "@/redux-state/uploadFileSlice";
import { setUploadedFileUrl } from "@/redux-state/contentEditorDataSlice";

export const useUploadStore = () => {
    const dispatch = useAppDispatch();

    const [isVideo, setIsVideo] = useState(false);

    const uploading = useAppSelector((state) => state.uploadStore.uploading);
    const uploadPercentage = useAppSelector(
        (state) => state.uploadStore.uploadPercentage
    );

    const setUploadPercentage = (event: ProgressEvent) => {
        const dispatch = useAppDispatch();
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        dispatch(setUploadingPercentage(percentCompleted));
        if (percentCompleted >= 100) {
            dispatch(setUploading(false));
        } else {
            dispatch(setUploading(true));
        }
    };

    const upload = useCallback(
        (files: File[]) => {
            dispatch(uploadFile(files, setUploadPercentage));
        },
        [dispatch, isVideo]
    );

    const _uploadVideo = useCallback(
        (files: File[], contentBlockId: string) => {
            dispatch(uploadVideo(files, setUploadPercentage, contentBlockId));
        },
        [dispatch, isVideo]
    );

    const uploadedFileUrl = useAppSelector(
        (state) => state?.contentEditorStore?.uploadedFileUrl
    );

    const _setUploadedFileUrl = useCallback(
        (uploadedFileUrl: string) => {
            dispatch(setUploadedFileUrl(uploadedFileUrl));
        },
        [dispatch]
    );

    return {
        uploading,
        uploadPercentage,
        upload,
        uploadVideo: _uploadVideo,
        setIsVideo,
        isVideo,
        uploadedFileUrl,
        setUploadedFileUrl: _setUploadedFileUrl,
    };
};
