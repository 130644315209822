import { useMutation } from "@tanstack/react-query";
import { updateContentBlockTitle } from "./updateContentBlockTitle";
import { designLayerPropsAtoms } from "../shared-props";
import { useAtom } from "jotai";
import { useGotContentBlock } from "@/hooks";
import { ContentBlockShape } from "@/models";

export function useUpdateTopLevelContentBlockLayer() {
    const [topLevelContentBlockLayers, setTopLevelContentBlockLayers] = useAtom(
        designLayerPropsAtoms.topLevelContentBlockLayers
    );
    const { gotContentBlock } = useGotContentBlock();
    return useMutation({
        mutationFn: ({
            contentBlockId,
            title,
        }: {
            contentBlockId: string;
            title: string;
        }) => updateContentBlockTitle(contentBlockId, title),
        onSuccess: async (contentBlock) => {
            const newTopLevelContentBlockLayers =
                topLevelContentBlockLayers.map((cb) =>
                    cb.id === contentBlock.id ? contentBlock : cb
                );

            setTopLevelContentBlockLayers(newTopLevelContentBlockLayers);
            gotContentBlock(contentBlock as ContentBlockShape);
        },
    });
}
