import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import axios from "axios";

export async function getContentBlockById(id: string) {
    const route = sapienRoute("creator.design.content-blocks.show", {
        contentBlock: id,
    });

    const { data } = await axios.get<{ contentBlock: ContentBlockShape }>(
        route
    );

    return data?.contentBlock;
}
