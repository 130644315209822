import { SapienParticipantPageProps } from "@/inertia-utils/types";
import React, { useCallback, useEffect } from "react";
import { Course, Lesson } from "../types";
import ParticipantLessonDisplay from "../LessonDisplay/LessonDisplay";
import QueryClient from "../QueryClient/QueryClient";
import { useSetupContentBlockState } from "../LessonDesign/useContentBlockState";
import LessonSideNav from "../LessonDisplay/lesson-sidenav/LessonSideNav";
import {
    useViewedContentBlockState,
    useSetupViewedContentBlockState,
} from "../LessonDisplay/viewed-content-block-tracking/useViewedContentBlockState";
import { useMutation } from "@tanstack/react-query";
import { SapienInertia, sapienAxios, sapienRoute } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useColors } from "../shared-state";
import { useSelectedSimulation } from "@/hooks";
import StudentHeader from "./StudentHeader";
import LayoutWithPageTransition from "../shared/page-transitions/PageTransitionLayout";
import usePageTransition from "../shared/page-transitions/usePageTransition";
import { Submission, useSetupSelections } from "../shared-state/useSelections";
import { Link } from "@inertiajs/react";
import { router } from '@inertiajs/react'

type Props = {
    course: Course;
    lesson: Lesson;
    teamUserRound: {
        id: string;
        viewedContentBlocks: {
            content_block_id: string;
        }[];
    };
    selections: Submission[];
    auth: SapienParticipantPageProps["auth"];
};

function LessonDisplayContent({ lesson, course, teamUserRound, auth }: Props) {
    const {} = useColors();

    const { mutate } = useMutation({
        mutationFn: async (content_block_ids: string[]) => {
            const response = await sapienAxios.post<
                any,
                "e-learning.participant.viewed-content-blocks.store"
            >("e-learning.participant.viewed-content-blocks.store", {
                content_block_ids,
            } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.participant.viewed-content-blocks.store"]);
        },
    });

    const saveViewedContentBlockState = useCallback(
        (contentBlockIds: string[]) => {
            mutate(contentBlockIds);
        },
        [lesson],
    );

    const { viewContentBlock } = useViewedContentBlockState(
        lesson,
        saveViewedContentBlockState,
    );



    return (
        <>
            <div className={"e-learning flex min-h-screen w-full"}>
                <StudentHeader auth={auth}>
                    <div className="flex items-center space-x-4">
                        <h1 className="text-lg font-semibold">
                            {lesson.title}
                        </h1>
                        <p className="uppercase text-gray-300">
                            {lesson.weight + 1 || 1} of {course.lessons.length}
                        </p>
                    </div>
                </StudentHeader>
                <LessonSideNav
                    course={course}
                    currentLessonId={lesson.id}
                    isInAdminContext={false}
                />
                <main
                    className={`lesson-designer with-transition animation-target e-learning flex w-full flex-col
                        items-center pt-16`}
                >
                    <div
                        className={
                            "animation-target-hide flex w-full flex-col items-center"
                        }
                    >
                        <div className="scroll-target h-0 w-full"></div>
                        <ParticipantLessonDisplay
                            lesson={lesson}
                            lessonCount={course.lessons.length}
                            isInAdminContext={false}
                            scrollCallback={viewContentBlock}
                        />
                    </div>
                </main>
            </div>
        </>
    );
}

export default function LessonDisplay(props: Props) {
    useSetupContentBlockState(props.lesson);
    useSetupViewedContentBlockState(
        props.course.lessons,
        props.teamUserRound.viewedContentBlocks,
    );
    const { gotSimulation } = useSelectedSimulation();
    gotSimulation(props.course);
    useSetupSelections(props.selections || []);

    return (
        <QueryClient>
            <LessonDisplayContent {...props} />
        </QueryClient>
    );
}

LessonDisplay.layout = (page: React.ReactNode) => (
    <LayoutWithPageTransition>{page}</LayoutWithPageTransition>
);
