import { ToastProps } from "@/components/toasts/StyledToast";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { setNotification } from "@/redux-state/notificationSlice";
import { useCallback } from "react";

export function useNotificationStore() {
    const dispatch = useAppDispatch();

    const _notification = useAppSelector(
        (state) => state?.notificationStore?.notification
    );

    const _setNotification = useCallback(
        (notification: ToastProps | null) => {
            const _notification = notification
                ? { ...notification, autoClose: notification.autoClose || 3000 }
                : null;
            dispatch(setNotification(_notification));
            if (_notification.autoClose) {
                setTimeout(() => {
                    dispatch(setNotification(null));
                }, _notification.autoClose + 1500);
            }
        },
        [dispatch, _notification]
    );

    return {
        notification: _notification,
        setNotification: _setNotification,
    };
}
