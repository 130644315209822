import { TimeHorizon } from "./../../models/TimeHorizon";
import { useAppDispatch, useAppSelector } from "@/redux-state/AppStore";
import { useCallback } from "react";
import { gotTimeHorizons } from "@/redux-state/timeHorizonSlice";

export function useTimeHorizonStore() {
    const dispatch = useAppDispatch();
    const timeHorizonMap = useAppSelector(
        (state) => state.timeHorizonStore.timeHorizons
    );
    const timeHorizons: TimeHorizon[] = useAppSelector((state) =>
        [...Object.values(state.timeHorizonStore.timeHorizons)].sort(
            (a, b) => a.time_index - b.time_index
        )
    );

    const _gotTimeHorizons = useCallback(
        (timeHorizons: TimeHorizon[]) =>
            dispatch(gotTimeHorizons(timeHorizons)),
        [dispatch]
    );

    return {
        timeHorizons,
        timeHorizonMap,
        gotTimeHorizons: _gotTimeHorizons,
    };
}
