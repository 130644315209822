import React from "react";
import { Field, Label, Switch } from "@headlessui/react";

type Props = {
    label: string;
    isChecked: boolean;
    isDisabled: boolean;
    handleToggle: () => void;
};

function FormSwitchComponent({
    label,
    isChecked,
    isDisabled,
    handleToggle,
}: Props) {
    return (
        <Field className="flex items-center space-x-2">
            <Label className="whitespace-nowrap text-sm">{label}</Label>
            <Switch
                disabled={isDisabled}
                defaultChecked={isChecked}
                onChange={() => {
                    handleToggle();
                }}
                className="group inline-flex h-5 w-8 items-center rounded-full bg-slate-300 transition
                    data-[checked]:bg-blue-600 disabled:animate-pulse disabled:cursor-not-allowed
                    dark:bg-slate-600"
            >
                <span
                    className="size-4 rounded-full bg-white transition translate-x-[.15rem]
                        group-data-[checked]:translate-x-[.85rem] dark:bg-slate-300"
                />
            </Switch>
        </Field>
    );
}

export const FormSwitch = React.memo(FormSwitchComponent);
