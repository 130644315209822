import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
    CSSProperties,
    HTMLAttributes,
    forwardRef,
    MutableRefObject,
} from "react";
import { createPortal } from "react-dom";
import {
    Announcements,
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragStartEvent,
    DragOverlay,
    DragMoveEvent,
    DragEndEvent,
    DragOverEvent,
    MeasuringStrategy,
    DropAnimation,
    // Modifier,
    defaultDropAnimation,
    UniqueIdentifier,
    closestCorners,
    getFirstCollision,
    KeyboardCode,
    KeyboardCoordinateGetter,
    DroppableContainer,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
    AnimateLayoutChanges,
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";

export interface ITreeItem {
    id: UniqueIdentifier;
    label?: string;
    children: ITreeItem[];
    collapsed?: boolean;
}

export interface FlattenedItem extends ITreeItem {
    parentId: UniqueIdentifier | null;
    depth: number;
    index: number;
}

type SensorContext = MutableRefObject<{
    items: FlattenedItem[];
    offset: number;
}>;

// const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

function getProjection(
    items: FlattenedItem[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    dragOffset: number,
    indentationWidth: number
) {
    const overItemIndex = items.findIndex(({ id }) => id === overId);
    const activeItemIndex = items.findIndex(({ id }) => id === activeId);
    const activeItem = items[activeItemIndex];
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.depth + dragDepth;
    const maxDepth = getMaxDepth({
        previousItem,
    });
    const minDepth = getMinDepth({ nextItem });
    let depth = projectedDepth;

    if (projectedDepth >= maxDepth) {
        depth = maxDepth;
    } else if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    return { depth, maxDepth, minDepth, parentId: getParentId() };

    function getParentId() {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.depth) {
            return previousItem.parentId;
        }

        if (depth > previousItem.depth) {
            return previousItem.id;
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find((item) => item.depth === depth)?.parentId;

        return newParent ?? null;
    }
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedItem }) {
    if (previousItem) {
        return previousItem.depth + 1;
    }

    return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
    if (nextItem) {
        return nextItem.depth;
    }

    return 0;
}

function flatten(
    items: ITreeItem[],
    parentId: UniqueIdentifier | null = null,
    depth = 0
): FlattenedItem[] {
    return items.reduce<FlattenedItem[]>((acc, item, index) => {
        return [
            ...acc,
            { ...item, parentId, depth, index },
            ...flatten(item.children, item.id, depth + 1),
        ];
    }, []);
}

function flattenTree(items: ITreeItem[]): FlattenedItem[] {
    return flatten(items);
}

function buildTree(flattenedItems: FlattenedItem[]): ITreeItem[] {
    const root: ITreeItem = { id: "root", children: [] };
    const nodes: Record<string, ITreeItem> = { [root.id]: root };
    const items = flattenedItems.map((item) => ({ ...item, children: [] }));

    for (const item of items) {
        const { id, children } = item;
        const parentId = item.parentId ?? root.id;
        const parent = nodes[parentId] ?? findItem(items, parentId);

        nodes[id] = { id, children };
        parent.children.push(item);
    }

    return root.children;
}

function findItem(items: ITreeItem[], itemId: UniqueIdentifier) {
    return items.find(({ id }) => id === itemId);
}

function findItemDeep(
    items: ITreeItem[],
    itemId: UniqueIdentifier
): ITreeItem | undefined {
    for (const item of items) {
        const { id, children } = item;

        if (id === itemId) {
            return item;
        }

        if (children.length) {
            const child = findItemDeep(children, itemId);

            if (child) {
                return child;
            }
        }
    }

    return undefined;
}

// function removeItem(items: ITreeItem[], id: UniqueIdentifier) {
//     const newItems = [];

//     for (const item of items) {
//         if (item.id === id) {
//             continue;
//         }

//         if (item.children.length) {
//             item.children = removeItem(item.children, id);
//         }

//         newItems.push(item);
//     }

//     return newItems;
// }

// function setProperty<T extends keyof ITreeItem>(
//     items: ITreeItem[],
//     id: UniqueIdentifier,
//     property: T,
//     setter: (value: ITreeItem[T]) => ITreeItem[T]
// ) {
//     for (const item of items) {
//         if (item.id === id) {
//             item[property] = setter(item[property]);
//             continue;
//         }

//         if (item.children.length) {
//             item.children = setProperty(item.children, id, property, setter);
//         }
//     }

//     return [...items];
// }

function countChildren(items: ITreeItem[], count = 0): number {
    return items.reduce((acc, { children }) => {
        if (children.length) {
            return countChildren(children, acc + 1);
        }

        return acc + 1;
    }, count);
}

function getChildCount(items: ITreeItem[], id: UniqueIdentifier) {
    const item = findItemDeep(items, id);

    return item ? countChildren(item.children) : 0;
}

function removeChildrenOf(items: FlattenedItem[], ids: UniqueIdentifier[]) {
    const excludeParentIds = [...ids];

    return items.filter((item) => {
        if (item.parentId && excludeParentIds.includes(item.parentId)) {
            if (item.children.length) {
                excludeParentIds.push(item.id);
            }
            return false;
        }

        return true;
    });
}

const directions: string[] = [
    KeyboardCode.Down,
    KeyboardCode.Right,
    KeyboardCode.Up,
    KeyboardCode.Left,
];

const horizontal: string[] = [KeyboardCode.Left, KeyboardCode.Right];

const sortableTreeKeyboardCoordinates: (
    context: SensorContext,
    indicator: boolean,
    indentationWidth: number
) => KeyboardCoordinateGetter =
    (context, indicator, indentationWidth) =>
    (
        event,
        {
            currentCoordinates,
            context: {
                active,
                over,
                collisionRect,
                droppableRects,
                droppableContainers,
            },
        }
    ) => {
        if (directions.includes(event.code)) {
            if (!active || !collisionRect) {
                return;
            }

            event.preventDefault();

            const {
                current: { items, offset },
            } = context;

            if (horizontal.includes(event.code) && over?.id) {
                const { depth, maxDepth, minDepth } = getProjection(
                    items,
                    active.id,
                    over.id,
                    offset,
                    indentationWidth
                );

                switch (event.code) {
                    case KeyboardCode.Left:
                        if (depth > minDepth) {
                            return {
                                ...currentCoordinates,
                                x: currentCoordinates.x - indentationWidth,
                            };
                        }
                        break;
                    case KeyboardCode.Right:
                        if (depth < maxDepth) {
                            return {
                                ...currentCoordinates,
                                x: currentCoordinates.x + indentationWidth,
                            };
                        }
                        break;
                }

                return undefined;
            }

            const containers: DroppableContainer[] = [];

            droppableContainers.forEach((container) => {
                if (container?.disabled || container.id === over?.id) {
                    return;
                }

                const rect = droppableRects.get(container.id);

                if (!rect) {
                    return;
                }

                switch (event.code) {
                    case KeyboardCode.Down:
                        if (collisionRect.top < rect.top) {
                            containers.push(container);
                        }
                        break;
                    case KeyboardCode.Up:
                        if (collisionRect.top > rect.top) {
                            containers.push(container);
                        }
                        break;
                }
            });

            const collisions = closestCorners({
                active,
                collisionRect,
                pointerCoordinates: null,
                droppableRects,
                droppableContainers: containers,
            });
            let closestId = getFirstCollision(collisions, "id");

            if (closestId === over?.id && collisions.length > 1) {
                closestId = collisions[1].id;
            }

            if (closestId && over?.id) {
                const activeRect = droppableRects.get(active.id);
                const newRect = droppableRects.get(closestId);
                const newDroppable = droppableContainers.get(closestId);

                if (activeRect && newRect && newDroppable) {
                    const newIndex = items.findIndex(
                        ({ id }) => id === closestId
                    );
                    const newItem = items[newIndex];
                    const activeIndex = items.findIndex(
                        ({ id }) => id === active.id
                    );
                    const activeItem = items[activeIndex];

                    if (newItem && activeItem) {
                        const { depth } = getProjection(
                            items,
                            active.id,
                            closestId,
                            (newItem.depth - activeItem.depth) *
                                indentationWidth,
                            indentationWidth
                        );
                        const isBelow = newIndex > activeIndex;
                        const modifier = isBelow ? 1 : -1;
                        const offset = indicator
                            ? (collisionRect.height - activeRect.height) / 2
                            : 0;

                        const newCoordinates = {
                            x: newRect.left + depth * indentationWidth,
                            y: newRect.top + modifier * offset,
                        };

                        return newCoordinates;
                    }
                }
            }
        }

        return undefined;
    };

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const dropAnimationConfig: DropAnimation = {
    keyframes({ transform }) {
        return [
            {
                opacity: 1,
                transform: CSS.Transform.toString(transform.initial),
            },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: "ease-out",
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};

interface TreeItemProps extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
    childCount?: number;
    clone?: boolean;
    collapsed?: boolean;
    depth: number;
    disableInteraction?: boolean;
    disableSelection?: boolean;
    ghost?: boolean;
    handleProps?: any;
    indicator?: boolean;
    indentationWidth: number;
    value: string;
    onCollapse?(): void;
    onRemove?(): void;
    wrapperRef?(node: HTMLLIElement): void;
}

interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
    active?: {
        fill: string;
        background: string;
    };
    cursor?: CSSProperties["cursor"];
}

const Action = forwardRef<HTMLButtonElement, ActionProps>(
    ({ active, className, cursor, style, ...props }, ref) => {
        return (
            <button
                ref={ref}
                {...props}
                // className={classNames(styles.Action, className)}
                tabIndex={0}
                style={
                    {
                        ...style,
                        cursor,
                        "--fill": active?.fill,
                        "--background": active?.background,
                    } as CSSProperties
                }
            />
        );
    }
);

const Handle = forwardRef<HTMLButtonElement, ActionProps>((props, ref) => {
    return (
        <Action
            ref={ref}
            cursor="grab"
            data-cypress="draggable-handle"
            {...props}
        >
            <svg viewBox="0 0 20 20" width="12">
                <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
            </svg>
        </Action>
    );
});

const StyledTreeItemWrapper = styled.li`
    list-style: none;
    box-sizing: border-box;
    padding-left: var(--spacing);
    margin-bottom: -1px;

    &.clone {
        display: inline-block;
        pointer-events: none;
        padding: 0;
        padding-left: 10px;
        padding-top: 5px;

        .TreeItem {
            --vertical-padding: 5px;

            padding-right: 24px;
            border-radius: 4px;
            box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
        }
    }

    &.ghost {
        &.indicator {
            opacity: 1;
            position: relative;
            z-index: 1;
            margin-bottom: -1px;

            .TreeItem {
                position: relative;
                padding: 0;
                height: 8px;
                border-color: #2389ff;
                background-color: #56a1f8;

                &:before {
                    position: absolute;
                    left: -8px;
                    top: -4px;
                    display: block;
                    content: "";
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #2389ff;
                    background-color: #ffffff;
                }

                > * {
                    /* Items are hidden using height and opacity to retain focus */
                    opacity: 0;
                    height: 0;
                }
            }
        }

        &:not(.indicator) {
            opacity: 0.5;
        }

        .TreeItem > * {
            box-shadow: none;
            background-color: transparent;
        }
    }
`;

const StyledTreeItem = styled.div`
    --vertical-padding: 4px;
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--vertical-padding) 4px;
    background-color: #1f1f1f;
    border: 1px solid #ffffff24;
    color: #fff;
    box-sizing: border-box;
`;

const StyledTreeItemText = styled.span`
    flex-grow: 1;
    padding-left: 0.5rem;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const TreeItem = forwardRef<HTMLDivElement, TreeItemProps>(
    (
        {
            childCount,
            clone,
            depth,
            disableSelection,
            disableInteraction,
            ghost,
            handleProps,
            indentationWidth,
            indicator,
            collapsed,
            onCollapse,
            onRemove,
            style,
            value,
            wrapperRef,
            ...props
        },
        ref
    ) => {
        return (
            <StyledTreeItemWrapper
                // className={classNames(
                //     styles.Wrapper,
                //     clone && styles.clone,
                //     ghost && styles.ghost,
                //     indicator && styles.indicator,
                //     disableSelection && styles.disableSelection,
                //     disableInteraction && styles.disableInteraction
                // )}
                ref={wrapperRef}
                style={
                    {
                        "--spacing": `${indentationWidth * depth}px`,
                    } as React.CSSProperties
                }
                {...props}
            >
                <StyledTreeItem ref={ref} style={style}>
                    <Handle {...handleProps} style={{ fill: "white" }} />
                    {/* {onCollapse && (
                        <Action
                            onClick={onCollapse}
                            className={classNames(
                                styles.Collapse,
                                collapsed && styles.collapsed
                            )}
                        >
                            {collapseIcon}
                        </Action>
                    )} */}
                    <StyledTreeItemText className="text-sm">
                        {value}
                    </StyledTreeItemText>
                    {/* {!clone && onRemove && <Remove onClick={onRemove} />} */}
                    {/* {clone && childCount && childCount > 1 ? (
                        <span //className={styles.Count}
                        >
                            {childCount}
                        </span>
                    ) : null} */}
                </StyledTreeItem>
            </StyledTreeItemWrapper>
        );
    }
);

const animateLayoutChanges: AnimateLayoutChanges = ({
    isSorting,
    wasDragging,
}) => (isSorting || wasDragging ? false : true);

interface SortableTreeItemProps extends TreeItemProps {
    id: UniqueIdentifier;
}

function SortableTreeItem({ id, depth, ...props }: SortableTreeItemProps) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges,
    });
    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <TreeItem
            ref={setDraggableNodeRef}
            wrapperRef={setDroppableNodeRef}
            style={style}
            depth={depth}
            ghost={isDragging}
            // disableSelection={iOS}
            disableInteraction={isSorting}
            handleProps={{
                ...attributes,
                ...listeners,
            }}
            {...props}
        />
    );
}

// const adjustTranslate: Modifier = ({ transform }) => {
//     return {
//         ...transform,
//         y: transform.y - 25,
//     };
// };

interface Props {
    // collapsible?: boolean;
    defaultItems?: ITreeItem[];
    indentationWidth?: number;
    // indicator?: boolean;
    // removable?: boolean;
    handleUpdate?: (itemTree: ITreeItem[]) => void;
}

export function SortableTree({
    // collapsible,
    defaultItems = [],
    // indicator = false,
    indentationWidth = 20,
    handleUpdate,
}: // removable,
Props) {
    const [items, setItems] = useState(defaultItems);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState<{
        parentId: UniqueIdentifier | null;
        overId: UniqueIdentifier;
    } | null>(null);

    useEffect(() => {
        setItems(defaultItems);
    }, [defaultItems]);

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items);
        const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
            (acc, { children, collapsed, id }) =>
                collapsed && children.length ? [...acc, id] : acc,
            []
        );

        return removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems
        );
    }, [activeId, items]);

    const projected =
        activeId && overId
            ? getProjection(
                  flattenedItems,
                  activeId,
                  overId,
                  offsetLeft,
                  indentationWidth
              )
            : null;
    const sensorContext: SensorContext = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });
    const [coordinateGetter] = useState(() =>
        sortableTreeKeyboardCoordinates(
            sensorContext,
            false, // indicator,
            indentationWidth
        )
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        })
    );

    const sortedIds = useMemo(
        () => flattenedItems.map(({ id }) => id),
        [flattenedItems]
    );
    const activeItem = activeId
        ? flattenedItems.find(({ id }) => id === activeId)
        : null;

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);

    const announcements: Announcements = {
        onDragStart({ active }) {
            return `Picked up ${active.id}.`;
        },
        onDragMove({ active, over }) {
            return getMovementAnnouncement("onDragMove", active.id, over?.id);
        },
        onDragOver({ active, over }) {
            return getMovementAnnouncement("onDragOver", active.id, over?.id);
        },
        onDragEnd({ active, over }) {
            return getMovementAnnouncement("onDragEnd", active.id, over?.id);
        },
        onDragCancel({ active }) {
            return `Moving was cancelled. ${active.id} was dropped in its original position.`;
        },
    };

    return (
        <DndContext
            accessibility={{ announcements }}
            sensors={sensors}
            collisionDetection={closestCenter}
            measuring={measuring}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext
                items={sortedIds}
                strategy={verticalListSortingStrategy}
            >
                {flattenedItems.map(
                    ({ id, label, children, collapsed, depth }) => (
                        <SortableTreeItem
                            key={id}
                            id={id}
                            value={label || (id as string)}
                            depth={
                                id === activeId && projected
                                    ? projected.depth
                                    : depth
                            }
                            indentationWidth={indentationWidth}
                            // indicator={indicator}
                            collapsed={Boolean(collapsed && children.length)}
                            // onCollapse={
                            //     collapsible && children.length
                            //         ? () => handleCollapse(id)
                            //         : undefined
                            // }
                            // onRemove={
                            //     removable ? () => handleRemove(id) : undefined
                            // }
                        />
                    )
                )}
                {createPortal(
                    <DragOverlay
                        dropAnimation={dropAnimationConfig}
                        // modifiers={indicator ? [adjustTranslate] : undefined}
                    >
                        {activeId && activeItem ? (
                            <SortableTreeItem
                                id={activeId}
                                depth={activeItem.depth}
                                clone
                                childCount={getChildCount(items, activeId) + 1}
                                value={activeItem.label} //{activeId.toString()}
                                indentationWidth={indentationWidth}
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )}
            </SortableContext>
        </DndContext>
    );

    function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
        setActiveId(activeId);
        setOverId(activeId);

        const activeItem = flattenedItems.find(({ id }) => id === activeId);

        if (activeItem) {
            setCurrentPosition({
                parentId: activeItem.parentId,
                overId: activeId,
            });
        }

        document.body.style.setProperty("cursor", "grabbing");
    }

    function handleDragMove({ delta }: DragMoveEvent) {
        setOffsetLeft(delta.x);
    }

    function handleDragOver({ over }: DragOverEvent) {
        setOverId(over?.id ?? null);
    }

    function handleDragEnd({ active, over }: DragEndEvent) {
        resetState();

        if (projected && over) {
            const { depth, parentId } = projected;
            const clonedItems: FlattenedItem[] = JSON.parse(
                JSON.stringify(flattenTree(items))
            );
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
            const activeIndex = clonedItems.findIndex(
                ({ id }) => id === active.id
            );
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);

            setItems(newItems);

            if (!!handleUpdate) handleUpdate(newItems);
        }
    }

    function handleDragCancel() {
        resetState();
    }

    function resetState() {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty("cursor", "");
    }

    // function handleRemove(id: UniqueIdentifier) {
    //     setItems((items) => removeItem(items, id));
    // }

    // function handleCollapse(id: UniqueIdentifier) {
    //     setItems((items) =>
    //         setProperty(items, id, "collapsed", (value) => {
    //             return !value;
    //         })
    //     );
    // }

    function getMovementAnnouncement(
        eventName: string,
        activeId: UniqueIdentifier,
        overId?: UniqueIdentifier
    ) {
        if (overId && projected) {
            if (eventName !== "onDragEnd") {
                if (
                    currentPosition &&
                    projected.parentId === currentPosition.parentId &&
                    overId === currentPosition.overId
                ) {
                    return;
                } else {
                    setCurrentPosition({
                        parentId: projected.parentId,
                        overId,
                    });
                }
            }

            const clonedItems: FlattenedItem[] = JSON.parse(
                JSON.stringify(flattenTree(items))
            );
            const overIndex = clonedItems.findIndex(({ id }) => id === overId);
            const activeIndex = clonedItems.findIndex(
                ({ id }) => id === activeId
            );
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];

            let announcement;
            const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
            const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
                } else {
                    let previousSibling: FlattenedItem | undefined =
                        previousItem;
                    while (
                        previousSibling &&
                        projected.depth < previousSibling.depth
                    ) {
                        const parentId: UniqueIdentifier | null =
                            previousSibling.parentId;
                        previousSibling = sortedItems.find(
                            ({ id }) => id === parentId
                        );
                    }

                    if (previousSibling) {
                        announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
                    }
                }
            }

            return announcement;
        }

        return;
    }
}
