import React, { useState } from "react";
import {
    FormGroup,
    FormInputToggle,
    FormToggleDiv,
    FormToggleLabel,
    FormToggleLeftLabel,
} from "../../components";
import { RoundShape } from "../../models";
import { ScrollableTableHeaderEntry } from "@/modules/shared";
import Icon from "react-icons-kit";
import { check } from "react-icons-kit/feather/check";
import { x } from "react-icons-kit/feather/x";
import { mapPin } from "react-icons-kit/feather/mapPin";
import { minus } from "react-icons-kit/feather/minus";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";

const ProgressRing = ({
    radius,
    stroke,
    progress,
    color,
}: {
    radius: number;
    stroke: number;
    progress: number;
    color?: string;
}) => {
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <g>
                <circle
                    className="text-gray-300"
                    stroke="currentColor"
                    fill="transparent"
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke={color || "#0374fe"}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + " " + circumference}
                    style={{
                        strokeDashoffset,
                        transition: "stroke-dashoffset 0.35s",
                        transform: "rotate(-90deg)",
                        transformOrigin: "50% 50%",
                    }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontWeight={500}
                    fontSize={radius * 0.42}
                    fill="black"
                >
                    {`${progress}%`}
                </text>
            </g>
        </svg>
    );
};

const LegendItem = (props: { icon: any; color: string; text: string }) => {
    return (
        <span
            style={{
                padding: "0 1rem",
                display: "inline-flex",
                flexWrap: "nowrap",
            }}
        >
            <Icon
                icon={props.icon}
                style={{
                    cursor: "default",
                    paddingRight: "0.2rem",
                    color: props.color,
                }}
            />

            {props.text}
        </span>
    );
};

const PlacementIndicator = ({
    participantCount,
}: {
    participantCount: number;
}) => {
    // if (!participantCount) return;
    return (
        <span
            style={{
                paddingLeft: "10px",
                display: "inline-flex",
                alignItems: "center",
            }}
        >
            <Icon
                icon={mapPin}
                style={{
                    cursor: "default",
                    color: !!participantCount ? "#545454" : "#54545400",
                }}
            />
            <span
                style={{
                    color: !!participantCount ? "#545454" : "#54545400",
                }}
            >
                {!!participantCount ? participantCount : 0}
            </span>
        </span>
    );
};

export const CohortProgressDisplay = (props: {
    teams: TeamWithProgressData[];
    rounds: RoundShape[];
}) => {
    const { teams, rounds } = props;

    const [showPlacement, setShowPlacement] = useState<boolean>(true);

    return (
        <div className="px-2 pb-2">
            <div className="pb-2">
                <FormGroup>
                    <div className="flex flex-row items-center">
                        <FormToggleLeftLabel>
                            {"Show Placement"}
                        </FormToggleLeftLabel>
                        <FormToggleDiv>
                            <FormInputToggle
                                type="checkbox"
                                id="toggle"
                                checked={showPlacement}
                                onChange={() =>
                                    setShowPlacement(!showPlacement)
                                }
                            />
                            <FormToggleLabel htmlFor="toggle" />
                        </FormToggleDiv>
                    </div>
                </FormGroup>
                <div
                    style={{
                        fontSize: "0.75rem",
                    }}
                >
                    <LegendItem
                        icon={check}
                        color={"#28a745"}
                        text={"Team has completed round"}
                    />
                    <LegendItem
                        icon={x}
                        color={"#dc3545"}
                        text={"Team has not completed round"}
                    />
                    {showPlacement && (
                        <>
                            <LegendItem
                                icon={minus}
                                color={"#aaaaaa"}
                                text={"No questions in round"}
                            />
                            <LegendItem
                                icon={mapPin}
                                color={"#545454"}
                                text={"Participants in round"}
                            />
                        </>
                    )}
                </div>
            </div>
            <div
                style={{
                    overflowX: "auto",
                }}
            >
                {teams !== undefined && rounds !== undefined && (
                    <table
                        className="w-full table-auto text-left text-sm text-gray-500"
                        style={{
                            fontSize: "0.8rem",
                        }}
                    >
                        <thead className="border-b text-xs uppercase text-gray-700">
                            <tr>
                                <ScrollableTableHeaderEntry></ScrollableTableHeaderEntry>
                                {teams.map((team) => (
                                    <th
                                        key={team.id}
                                        className="px-6 py-3 text-center"
                                    >
                                        {team.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rounds
                                .filter(
                                    (round) =>
                                        showPlacement ||
                                        round.prompts_count > 0,
                                )
                                .map((round) => (
                                    <tr
                                        key={round.id}
                                        className={`table-row odd:bg-[#fcfdfe] even:bg-[#f8f9fe]`} //#fcfdfe//#fbfcfe
                                    >
                                        <td
                                            key={round.id}
                                            className={`table-col border-r`}
                                            style={{
                                                fontWeight: 600,
                                                padding: "0.25rem 0.5rem",
                                                position: "sticky",
                                                left: 0,
                                                background: "inherit",
                                                maxWidth: "250px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "inline-flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    width: "100%",
                                                }}
                                            >
                                                <span>{round.title}</span>
                                                {!!round.prompts_count && (
                                                    <span>{"*"}</span>
                                                )}
                                            </div>
                                        </td>
                                        {teams.map((team) => (
                                            <td
                                                style={{
                                                    padding: "0.25rem 0.5rem",
                                                    textAlign: "center",
                                                    verticalAlign: "inherit",
                                                }}
                                                key={team.id}
                                            >
                                                <div className="flex justify-center">
                                                    {!!round.prompts_count &&
                                                        !!team.participants
                                                            ?.length &&
                                                        !!team.progress_map &&
                                                        !!team.progress_map[
                                                            round.id
                                                        ] && (
                                                            <ProgressRing
                                                                radius={28}
                                                                stroke={4}
                                                                progress={
                                                                    team
                                                                        .progress_map[
                                                                        round.id
                                                                    ]
                                                                        .percent_complete
                                                                }
                                                                color={
                                                                    team
                                                                        .progress_map[
                                                                        round.id
                                                                    ]
                                                                        .percent_complete <
                                                                    100
                                                                        ? "#dc3545"
                                                                        : "#28a745"
                                                                }
                                                            />
                                                        )}
                                                    {!round.prompts_count && (
                                                        <span
                                                            style={{
                                                                minWidth:
                                                                    "56px",
                                                            }}
                                                        >
                                                            <Icon
                                                                icon={minus}
                                                                style={{
                                                                    cursor: "default",
                                                                    color: "#aaaaaa",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                    {showPlacement &&
                                                        !!team.participants
                                                            ?.length && (
                                                            <PlacementIndicator
                                                                participantCount={
                                                                    (!!team.progress_map &&
                                                                        team
                                                                            .progress_map[
                                                                            round
                                                                                .id
                                                                        ]
                                                                            ?.participantsInRound) ||
                                                                    0
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};
