import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape, Tag } from "@/models";

export function useGetContentBlockTemplateCreationData(contentBlockId: string) {
    const getTemplateCreationData = async (contentBlockId: string) => {

        const route = sapienRoute("creator.design.templates.create", {
            contentBlockId,
        });

        const { data } = await axios.get<{
            contentBlock: ContentBlockShape;
            tags: Tag[];
        }>(route);

        const { contentBlock, tags } = data;
        return { contentBlock, tags };
    };
    return useQuery({
        queryFn: () => getTemplateCreationData(contentBlockId),
        queryKey: ["contentBlockTemplateCreation", contentBlockId],
        initialData: {
            tags: [],
            contentBlock: null,
        },
        enabled: !!contentBlockId,
    });
}
