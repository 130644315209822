import React, { useState } from "react";
import { CalculatorIcon } from "@heroicons/react/24/solid";
import { SapienInertia } from "@/inertia-utils/hooks";

export const DiscoveryCalculateButton = ({
    cohortId,
    teamId,
    simulationSlug,
}: {
    cohortId: string;
    teamId?: string;
    simulationSlug: string;
}) => {
    const [isCalculating, setIsCalculating] = useState(false);

    return (
        <button
            onClick={() => {
                setIsCalculating(true);
                SapienInertia.post(
                    "admin.projects.discovery.calculate",
                    {
                        cohort_id: cohortId,
                        team_id: teamId,
                    },
                    {
                        simulationSlug: simulationSlug,
                    },
                    {
                        onSuccess() {
                            setIsCalculating(false);
                        },
                        preserveScroll: true,
                    },
                );
            }}
            type="submit"
            value="Submit"
            className="ml-3 rounded-md bg-blue-700 px-2 py-1 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
            disabled={isCalculating}
        >
            {isCalculating ? (
                <svg
                    className="h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            ) : (
                <CalculatorIcon className="h-4 w-4 text-white" />
            )}
        </button>
    );
};
