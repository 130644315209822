import React, { useMemo } from "react";
import {
    ModelVariable,
    ModelVariableDataType,
    ModelVariableScope,
    ModelVariableType,
    TimeHorizon,
} from "../../models";
import { groupBy } from "lodash";
import { useFormatVariableValue } from "@/hooks";
import { UserIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/solid";

export const DesignVariableTableRowNotEditable = ({
    modelVariable,
    timeHorizons,
}: {
    modelVariable: ModelVariable;
    timeHorizons: TimeHorizon[];
}) => {
    const formatVariableValue = useFormatVariableValue();

    const variableValuesGroupedByTimeHorizonId = useMemo(() => {
        return !!modelVariable.uses_time
            ? groupBy(modelVariable.baseVariableValues, "time_horizon_id")
            : undefined;
    }, [modelVariable]);

    return (
        <tr
            key={modelVariable.id}
            className={`table-row border odd:bg-indigo-50 odd:bg-opacity-75 even:bg-indigo-50
                even:bg-opacity-10`}
        >
            <th className={"table-col px-1 py-1"}>
                <span
                    className="text-xs"
                    title={
                        modelVariable.variable_type ===
                        ModelVariableType["Selection Data"]
                            ? "SELECTION DATA"
                            : modelVariable.variable_type
                    }
                >
                    {modelVariable.label}
                </span>
            </th>
            <td className="px-1 py-1">
                <div
                    className="flex justify-center"
                    title={modelVariable.scope}
                >
                    {modelVariable.scope === ModelVariableScope.User ? (
                        <UserIcon className="h-4 w-4 text-neutral-500" />
                    ) : modelVariable.scope === ModelVariableScope.Team ? (
                        <UsersIcon className="h-4 w-4 text-neutral-500" />
                    ) : (
                        <UserGroupIcon className="h-4 w-4 text-neutral-500" />
                    )}
                </div>
            </td>
            {!modelVariable.uses_time ? (
                <td
                    className={"border-l px-1 py-1"}
                    colSpan={timeHorizons?.length || 1}
                >
                    {modelVariable.data_type ===
                    ModelVariableDataType.Number ? (
                        <div className="flex items-center justify-between">
                            <>
                                {formatVariableValue(
                                    modelVariable.unit,
                                    modelVariable.baseVariableValues[0]
                                        .numerical_value,
                                    modelVariable.is_integer,
                                )}
                            </>
                        </div>
                    ) : (
                        modelVariable.baseVariableValues[0].boolean_value.toString()
                    )}
                </td>
            ) : (
                <>
                    {!!modelVariable.uses_time &&
                        timeHorizons.map((timeHorizon) => (
                            <td
                                className="border-l px-1 py-1 text-right"
                                key={timeHorizon.id}
                            >
                                {!!variableValuesGroupedByTimeHorizonId &&
                                    !!variableValuesGroupedByTimeHorizonId[
                                        timeHorizon.id
                                    ] && (
                                        <span>
                                            {modelVariable.data_type ===
                                            ModelVariableDataType.Number
                                                ? formatVariableValue(
                                                      modelVariable.unit,
                                                      variableValuesGroupedByTimeHorizonId[
                                                          timeHorizon.id
                                                      ][0].numerical_value,
                                                      modelVariable.is_integer,
                                                  )
                                                : variableValuesGroupedByTimeHorizonId[
                                                      timeHorizon.id
                                                  ][0].boolean_value.toString()}
                                        </span>
                                    )}
                            </td>
                        ))}
                </>
            )}
        </tr>
    );
};
