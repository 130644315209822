import React, { useMemo } from "react";
import {
    ModelVariable,
    ModelVariableDataType,
    ModelVariableUnit,
    VariableValue,
} from "@/models";

export const SandboxVariableRows = ({
    i,
    variable,
    targetValueRow,
    pythonValueRow,
    numberOfTimeHorizons,
    hasDifference,
}: {
    i: number;
    variable: ModelVariable;
    targetValueRow: VariableValue[];
    pythonValueRow: VariableValue[];
    numberOfTimeHorizons: number;
    hasDifference: boolean;
}) => {
    const isEven = !(i % 2);

    const differencesMap = useMemo(() => {
        if (hasDifference && targetValueRow?.length == pythonValueRow?.length) {
            return targetValueRow.reduce(
                (acc, targetValue, i) => {
                    return {
                        ...acc,
                        [i]:
                            variable.data_type === ModelVariableDataType.Number
                                ? targetValue.numerical_value !=
                                  pythonValueRow[i].numerical_value
                                : targetValue.boolean_value !=
                                  pythonValueRow[i].boolean_value,
                    };
                },
                {} as { [index: number]: boolean },
            );
        } else {
            return {};
        }
    }, [hasDifference, targetValueRow, pythonValueRow, variable]);

    return (
        <React.Fragment key={variable.id}>
            <tr
                className={`${
                    hasDifference
                        ? "border-t border-t-red-700 bg-red-200"
                        : isEven
                          ? "bg-gray-200"
                          : "bg-gray-100"
                } border-b border-b-gray-300`}
            >
                <th
                    rowSpan={2}
                    className="max-w-[12rem] border-r border-r-gray-300 p-2 text-left text-xs"
                >
                    {variable.label}
                </th>
                <td
                    rowSpan={2}
                    className="border-r border-r-gray-300 p-2 text-left text-xs"
                >
                    {variable.variable_type}
                </td>
                {pythonValueRow?.map((value, i) => (
                    <td
                        key={i}
                        className={`w-4 p-2 text-indigo-800 ${
                            !!differencesMap[i]
                                ? "bg-red-200"
                                : isEven
                                  ? "bg-gray-200"
                                  : "bg-gray-100"
                        }`}
                        colSpan={
                            pythonValueRow?.length > 1
                                ? 1
                                : numberOfTimeHorizons
                        }
                    >
                        {variable.data_type === ModelVariableDataType.Number
                            ? value.numerical_value
                            : value.boolean_value.toString()}
                        {variable.unit == ModelVariableUnit.Percent ? "%" : ""}
                    </td>
                ))}
            </tr>
            <tr
                className={
                    hasDifference
                        ? "border-b border-b-red-700 bg-red-200"
                        : isEven
                          ? "bg-gray-200"
                          : "bg-gray-100"
                }
            >
                {targetValueRow?.map((value, i) => (
                    <td
                        key={i}
                        className={`w-4 p-2 ${
                            !!differencesMap[i]
                                ? "bg-red-200"
                                : isEven
                                  ? "bg-gray-200"
                                  : "bg-gray-100"
                        }`}
                        colSpan={
                            targetValueRow?.length > 1
                                ? 1
                                : numberOfTimeHorizons
                        }
                    >
                        {variable.data_type === ModelVariableDataType.Number
                            ? value.numerical_value
                            : value.boolean_value.toString()}
                        {variable.unit == ModelVariableUnit.Percent ? "%" : ""}
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};
