import React, { useState } from "react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/Select";
import { CheckIcon } from "lucide-react";
import { ContentBlockShape } from "@/models";
import { transformRichTextContentBlock } from "./transformRichTextContentBlock";
import { useContentBlockState } from "../../../useContentBlockState";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";

const variants = [
    "paragraph",
    "heading",
    "paragraph with heading",
    "image on left",
    "image on right",
] as const;

export type RichTextVariant = (typeof variants)[Exclude<
    keyof typeof variants,
    keyof Array<string>
>];
type Props = {
    width?: number;
    contentBlock: ContentBlockShape;
};

export default function RichTextDropDown({ contentBlock }: Props) {
    const { setContentBlock } = useContentBlockState();

    const [variant, setVariant] = useState<RichTextVariant>(
        (contentBlock.theme?.variant as RichTextVariant) ||
            "paragraph with heading",
    );

    const { mutate } = useSaveContentBlock();

    return (
        <Select
            onValueChange={(value) => {
                setVariant(value as RichTextVariant);
                const updatedBlock = transformRichTextContentBlock(
                    contentBlock,
                    value as RichTextVariant,
                );
                setContentBlock(updatedBlock);
                mutate(updatedBlock);
            }}
        >
            <SelectTrigger className="w-56 bg-white shadow-md">
                <SelectValue placeholder={variant} />
            </SelectTrigger>
            <SelectContent className="cursor-pointer bg-white">
                {variants.map((item) => (
                    <SelectItem value={item} key={item}>
                        {item}{" "}
                        {variant === item && (
                            <CheckIcon className="text-accent absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
                        )}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}
