import { sapienRoute } from "@/inertia-utils/hooks";
import axios from "axios";

type Photo = {
    id: number;
    width: number;
    height: number;
    url: string;
    photographer: string;
    photographer_url: string;
    photographer_id: string;
    avg_color: string;
    src: {
        original: string;
        large2x: string;
        large: string;
        medium: string;
        small: string;
        portait: string;
        landscape: string;
        tiny: string;
    };
    liked: boolean;
    alt: string;
};

export type Video = Photo & { link: string };

export async function getImageApiResponse(query: {
    mediaType: "image" | "video" | "backgroundImage";
    orientation: "all" | "landscape" | "portrait" | "square";
    page: number;
    query: string;
    activeContentBlock: any;
    color: string;
    size: "all" | "large" | "medium" | "small";
}) {
    const route = sapienRoute(
        query.mediaType !== "video"
            ? "creator.design.file-search"
            : "creator.design.file-search.video",
    );

    const response = await axios.post<{
        apiResponse: {
            videos?: Video[];
            photos?: Photo[];
            nextPage: string;
            prevPage: string;
            totalResults: number;
            page: number;
        };
    }>(route, query);

    return response.data;
}
