import React, { useEffect, useState } from "react";
// import {
//     Body2Span,
//     Box,
//     BoxFlexColumn,
//     BoxFlexRow,
//     ButtonSpan,
//     CaptionSpan,
//     H6Span,
//     Subtitle1Span,
// } from "@/components";
// import { IconButton } from "@/modules/shared";
// import { Icon } from "react-icons-kit";
import {
    // TemplateCategory,
    // TemplateCategoryObject,
    Templates,
    TemplateType,
} from "@/templates";
// import { plus } from "react-icons-kit/feather/plus";
// import { layout } from "react-icons-kit/feather/layout";
// import styled from "styled-components";

// const HoverBox = styled(Box)`
//     background: rgba(255, 255, 255, 0.15);
//     color: #e5e7eb;
//     .hover-container-child {
//         display: none;
//     }
//     :hover {
//         cursor: pointer;
//         background-color: rgba(255, 255, 255, 0.25);
//         color: white;
//         .hover-container-child {
//             display: flex;
//         }
//     }
//     &.selected {
//         background: rgba(255, 255, 255, 0.25);
//         color: white;
//         &:hover {
//             cursor: auto;
//             background: rgba(255, 255, 255, 0.25);
//             color: white;
//         }
//     }
// `;

export const TemplateForm = (props: { simulationId: string }) => {
    const [selectedTemplateType, setSelectedTemplateType] =
        useState<TemplateType>();

    useEffect(() => {
        if (selectedTemplateType) {
            console.log(Templates[selectedTemplateType].contentBlocks);
        }
    }, [selectedTemplateType]);

    return <div></div>;
    // return (
    //     <div overflow="hidden" height="100%">
    //         <div height="100%" display="flex" flexDirection="column">
    //             <div px={5} py={4}>
    //                 <H6Span>{`Templates`}</H6Span>
    //             </div>
    //             <div overflow="auto" display="flex" flexDirection="column">
    //                 <div className="flex flex-row items-center" px={5} pb={4} justifyContent={"center"}>
    //                     <ButtonSpan
    //                         style={{ paddingRight: "4px" }}
    //                     >{`Blank Round`}</ButtonSpan>
    //                     <IconButton
    //                         icon={plus}
    //                         handler={async () => {
    //                             // let roundNumber =
    //                             //     rounds !== undefined
    //                             //         ? rounds.length + 1
    //                             //         : 1;
    //                             // let newRound = new Round({
    //                             //     title: `Round ${roundNumber}`,
    //                             //     weight: roundNumber,
    //                             // });
    //                             // await saveRoundFromTemplate(
    //                             //     simulationId,
    //                             //     0,
    //                             //     newRound,
    //                             //     []
    //                             // );
    //                             setSelectedTemplateType(undefined);
    //                         }}
    //                         palette={"grayscaleOpaque"}
    //                         noMargin={true}
    //                     />
    //                 </div>
    //                 <div className="flex flex-col justify-center" px={5}>
    //                     {Object.keys(TemplateCategory).map((key, index) => (
    //                         <React.Fragment key={key}>
    //                             <div lineHeight={1} mb={4}>
    //                                 <div pb={3} fontWeight="bold">
    //                                     <Subtitle1Span>{key}</Subtitle1Span>
    //                                 </div>
    //                                 <div>
    //                                     <Body2Span>
    //                                         {
    //                                             TemplateCategoryObject[key]
    //                                                 .description
    //                                         }
    //                                     </Body2Span>
    //                                 </div>
    //                             </div>
    //                             <div mb={4}>
    //                                 {TemplateCategoryObject[
    //                                     key
    //                                 ].templateTypes.map((templateType) => (
    //                                     <div
    //                                         key={templateType}
    //                                         width={[1]}
    //                                         my={3}
    //                                         style={{
    //                                             boxSizing: "border-box",
    //                                             border: `${
    //                                                 templateType ==
    //                                                 selectedTemplateType
    //                                                     ? "1px solid rgba(255,255,255,.5)"
    //                                                     : "1px solid rgba(255,255,255,.25)"
    //                                             }`,
    //                                         }}
    //                                         onClick={() => {
    //                                             setSelectedTemplateType(
    //                                                 templateType,
    //                                             );
    //                                         }}
    //                                     >
    //                                         <HoverBox
    //                                             display="flex"
    //                                             alignItems="center"
    //                                             className={
    //                                                 templateType ==
    //                                                     selectedTemplateType &&
    //                                                 "selected"
    //                                             }
    //                                         >
    //                                             <div className="flex w-full flex-col justify-center">
    //                                                 <div className="flex w-full flex-row items-center justify-between">
    //                                                     <div className="flex max-w-[270px] flex-row items-center">
    //                                                         <div className="flex flex-col justify-center"
    //                                                             p={"12px"}
    //                                                         >
    //                                                             {false ? (
    //                                                                 "preview image"
    //                                                             ) : (
    //                                                                 <Icon
    //                                                                     icon={
    //                                                                         // icon ||
    //                                                                         layout
    //                                                                     }
    //                                                                     size={
    //                                                                         28
    //                                                                     }
    //                                                                 />
    //                                                             )}
    //                                                         </div>
    //                                                         <div className="flex flex-col justify-center"
    //                                                             p={4}
    //                                                         >
    //                                                             <ButtonSpan>
    //                                                                 {
    //                                                                     Templates[
    //                                                                         templateType
    //                                                                     ].label
    //                                                                 }
    //                                                             </ButtonSpan>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="flex w-full flex-row items-center">
    //                                                     {templateType ==
    //                                                         selectedTemplateType && (
    //                                                         <div className="flex flex-col justify-center"
    //                                                             width={1}
    //                                                             px={5}
    //                                                             pt={4}
    //                                                             pb={4}
    //                                                         >
    //                                                             <CaptionSpan>
    //                                                                 {
    //                                                                     Templates[
    //                                                                         templateType
    //                                                                     ]
    //                                                                         .description
    //                                                                 }
    //                                                             </CaptionSpan>
    //                                                         </div>
    //                                                     )}
    //                                                 </div>
    //                                                 <div className="flex flex-row items-center justify-center">
    //                                                     {templateType ==
    //                                                         selectedTemplateType && (
    //                                                         <div pb={4}>
    //                                                             <IconButton
    //                                                                 icon={plus}
    //                                                                 handler={async () => {
    //                                                                     // let template =
    //                                                                     //     Templates[
    //                                                                     //         selectedTemplateType
    //                                                                     //     ];
    //                                                                     // let roundNumber =
    //                                                                     //     rounds !==
    //                                                                     //     undefined
    //                                                                     //         ? rounds.length +
    //                                                                     //           1
    //                                                                     //         : 1;
    //                                                                     // let newRound =
    //                                                                     //     new Round(
    //                                                                     //         {
    //                                                                     //             title: `Round ${roundNumber}: ${template.label}`,
    //                                                                     //             weight: roundNumber,
    //                                                                     //         }
    //                                                                     //     );
    //                                                                     // await saveRoundFromTemplate(
    //                                                                     //     simulationId,
    //                                                                     //     0,
    //                                                                     //     newRound,
    //                                                                     //     template.contentBlocks
    //                                                                     // );
    //                                                                     setSelectedTemplateType(
    //                                                                         undefined,
    //                                                                     );
    //                                                                 }}
    //                                                                 palette={
    //                                                                     "grayscaleOpaque"
    //                                                                 }
    //                                                                 noMargin={
    //                                                                     true
    //                                                                 }
    //                                                                 // isDisabled={
    //                                                                 //     true
    //                                                                 // }
    //                                                             />
    //                                                         </div>
    //                                                     )}
    //                                                 </div>
    //                                             </div>
    //                                         </HoverBox>
    //                                     </div>
    //                                 ))}
    //                             </div>
    //                         </React.Fragment>
    //                     ))}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
};
