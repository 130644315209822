import React, { useMemo } from "react";
import { useModelBuilderPage } from "@/inertia-utils/hooks";
import { PromptType, Round } from "@/models";

export const QuestionPanel = (props: {
    selectedBuilderRoundId: string;
    setSelectedBuilderRoundId: (roundId: string) => void;
}) => {
    const { selectedBuilderRoundId, setSelectedBuilderRoundId } = props;

    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { rounds } = modelBuilderPageProps;

    const roundsWithFilteredPrompts = useMemo(() => {
        return rounds.map(
            (round, i) =>
                ({
                    ...round,
                    prompts: round.prompts.filter(
                        (prompt) =>
                            prompt.prompt_type !== PromptType["Short Text"] &&
                            prompt.prompt_type !== PromptType["Long Text"]
                    ),
                } as Round)
        );
    }, [rounds]);

    return (
        <div>
            <div className="text-white w-full">
                <div className="p-1 my-1">
                    <div className="flex items-center text-sm justify-between font-bold">
                        <div>ROUND</div>
                        <div>QUESTIONS</div>
                    </div>
                </div>
            </div>
            {roundsWithFilteredPrompts?.map((round, index) => (
                <div
                    key={round.id}
                    className={`text-white w-full ${
                        !!round.prompts?.length
                            ? "cursor-pointer"
                            : "cursor-default"
                    }`}
                    onClick={() =>
                        !!round.prompts?.length &&
                        setSelectedBuilderRoundId(
                            round.id === selectedBuilderRoundId ? "" : round.id
                        )
                    }
                >
                    <div
                        className={`p-2 my-1 ${
                            round.id === selectedBuilderRoundId
                                ? "bg-[rgba(255,255,255,0.24)]"
                                : !!round.prompts?.length
                                ? "bg-[rgba(255,255,255,0.1)]"
                                : "bg-[rgba(255,255,255,0.03)]"
                        }`}
                    >
                        <div className="flex items-center text-sm justify-between">
                            <div className="pr-1 w-[12.5%]">{`${
                                index + 1
                            }`}</div>
                            <div className="w-[80%]">{round.title}</div>
                            <div className="pl-1 w-[12.5%] text-right">{`${
                                round.prompts?.length || "-"
                            }`}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
