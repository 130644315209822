import React from "react";
import { ModelVariableType } from "@/models";

const displayStyledTable = (
    headers: string[],
    rows: (string | React.ReactNode)[][]
) => {
    return (
        <div className="relative overflow-x-auto">
            <table
                className="table-auto w-full text-sm text-left text-gray-500"
                style={{
                    fontSize: "0.8rem",
                }}
            >
                <thead className="text-xs text-gray-700 bg-white uppercase">
                    <tr className="border">
                        {headers?.map((header) => (
                            <th key={header} className="py-3 px-1">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((row: (string | React.ReactNode)[], i) => (
                        <tr
                            key={`row-${i}`}
                            className={`table-row border odd:bg-[#f8f9fe] even:bg-[#fcfdfe]`}
                        >
                            {row?.map(
                                (rowCell: string | React.ReactNode, j) => (
                                    <td
                                        key={`row-${i}-cell-${j}`}
                                        className="py-1 px-1"
                                    >
                                        {rowCell}
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// const getByModelVariableType = (
//     modelVariableType: ModelVariableType
// ): string => {
//     switch (modelVariableType) {
//         case ModelVariableType.Independent:
//         case ModelVariableType["Selection Data"]:
//         case ModelVariableType.Iterative:
//         case ModelVariableType.Aggregate:
//         case ModelVariableType.Algebraic:
//         case ModelVariableType.Conditional:
//         case ModelVariableType.Logical:
//         case ModelVariableType.Comparison:
//         default:
//             return "";
//     }
// };

export const UserGuide = () => {
    return (
        <div>
            <div className="mb-2 text-l">Model Variables</div>
            <div>
                {displayStyledTable(
                    ["Variable Type"],
                    Object.keys(ModelVariableType).map((key) => [key])
                )}
            </div>
        </div>
    );
};
