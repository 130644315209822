import { PromptType } from "@/models";
import { displayPromptType } from "@/util";
import React, { useMemo } from "react";

type Props = {
    promptType: PromptType;
};

export default function PromptTypeTag({ promptType }: Props) {

    const readablePromptType = useMemo(() => {
        return displayPromptType(promptType)
    },[promptType])

    return (
        <span data-testid="prompt-type-tag" className="inline-flex items-center rounded-full bg-green-100/70 px-3 py-0.5 text-sm font-medium text-green-800">
            {readablePromptType}
        </span>
    );
}
