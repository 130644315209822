import React, { ReactNode, useEffect } from "react";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { Form } from "../components";

interface FormContainerProps {
    defaultValues?: any;
    children?: ReactNode;
    onSubmit?: any;
    onChange?: (formState: FieldValues) => void;
}

export const FormContainer = (props: FormContainerProps) => {
    const { defaultValues, children, onSubmit } = props;

    type FormObject = typeof defaultValues;

    const methods = useForm<FormObject>({
        defaultValues,
    });
    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (defaultValues !== undefined) {
            reset(defaultValues);
        }
    }, [defaultValues]);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)} className="relative">
                {children}
            </Form>
        </FormProvider>
    );
};
