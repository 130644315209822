import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { ContentBlockShape } from "@/models";
import { BrushIcon } from "lucide-react";
import React, { useCallback } from "react";
import { useSaveContentBlock, useUpdateStyles } from "../../content-block-management/useSaveContentBlock";
import { debounce } from "@/hooks";
import { useContentBlockState } from "../../useContentBlockState";
import PaddingSlider from "./PaddingSlider";

type Props = { contentBlock: ContentBlockShape };


export function DefaultSpacingMenu({ contentBlock }: Props) {
    const { mutateAsync } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();

    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            mutateAsync(updatedBlock);
        }, 100),
        [contentBlock],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        setContentBlock(newBlock);
        update(newBlock);
    }, []);

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-4">
                <PaddingSlider contentBlock={contentBlock} handleChange={handleChange} />
            </div>
        </div>
    );
}
export function DefaultColorMenu({ contentBlock }: Props) {
    return (
        <Popover>
            <PopoverTrigger>
                <BrushIcon className="h-6 w-6" />
            </PopoverTrigger>
            <PopoverContent></PopoverContent>
        </Popover>
    );
}
