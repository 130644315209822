import React from "react";

type LogoProps = { className?: string; logoPath?: string };
const defaultSapienLogoPath =
    "https://images.squarespace-cdn.com/content/v1/5773e82bd482e9d3fe229130/1538638228283-WUAEMOHTWZWX1PE0H3UU/LOGO+gradation+BLUE-GREY+transparent.png?format=1500w";

export default function ApplicationLogo({ className, logoPath }: LogoProps) {
    return (
        <div className={className}>
            <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={logoPath || defaultSapienLogoPath}
            />
        </div>
    );
}
