import React from "react";

type Props = {
    numberOfSteps: number;
    currentStep: number;
};

export default function Steps({
    numberOfSteps,
    currentStep,
}: Props) {
    return (
        <span className="inline-flex items-center rounded-full bg-[#ffffff11] px-4 py-1 text-sm font-medium text-[#3F83F8]">
            Step {currentStep} of {numberOfSteps}
        </span>
    );
}
