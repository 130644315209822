import React, { useMemo } from "react";
import { ModelVariable, TimeHorizon } from "../../models";
import { DesignVariableTableRowNotEditable } from "./DesignVariableTableRowNotEditable";
import { DesignVariableTableRowEditable } from "./DesignVariableTableRowEditable";

const DesignVariableTableDisplayComponent = ({
    modelVariables,
    timeHorizons,
    isEditable,
}: {
    modelVariables: ModelVariable[];
    timeHorizons: TimeHorizon[];
    isEditable?: boolean;
}) => {
    const someVariablesUseTime = useMemo(() => {
        return (
            !!modelVariables?.length &&
            modelVariables.some((modelVariable) => modelVariable?.uses_time)
        );
    }, [modelVariables]);

    return (
        <div className="relative overflow-x-auto bg-white">
            <table
                className="table-auto w-full text-sm text-left text-neutral-600 border-2 border-indigo-100"
                style={{
                    fontSize: "0.8rem",
                }}
            >
                <thead className="text-xs text-neutral-700 uppercase border-b-2 border-indigo-100">
                    <tr className="border">
                        <th
                            className="py-3 px-1"
                            rowSpan={someVariablesUseTime ? 2 : 1}
                        >
                            variable
                        </th>
                        <th
                            className="py-3 px-1 text-center"
                            rowSpan={someVariablesUseTime ? 2 : 1}
                        >
                            scope
                        </th>
                        <th
                            className="py-3 px-1 border-l"
                            colSpan={timeHorizons?.length || 1}
                            rowSpan={1}
                        >
                            values
                        </th>
                    </tr>
                    {!!someVariablesUseTime && (
                        <tr className="border">
                            {timeHorizons.map((timeHorizon) => (
                                <td
                                    className="py-1 px-1 border-l text-right min-w-[36px]"
                                    key={timeHorizon.id}
                                >
                                    {timeHorizon.time_index}
                                </td>
                            ))}
                        </tr>
                    )}
                </thead>
                <tbody>
                    {[
                        ...modelVariables.filter(
                            (modelVariable) => modelVariable.uses_time
                        ),
                        ...modelVariables.filter(
                            (modelVariable) => !modelVariable.uses_time
                        ),
                    ].map((modelVariable) => (
                        <React.Fragment key={modelVariable.id}>
                            {!!isEditable ? (
                                <DesignVariableTableRowEditable
                                    key={modelVariable.id}
                                    modelVariable={modelVariable}
                                    timeHorizons={timeHorizons}
                                />
                            ) : (
                                <DesignVariableTableRowNotEditable
                                    key={modelVariable.id}
                                    modelVariable={modelVariable}
                                    timeHorizons={timeHorizons}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export const DesignVariableTableDisplay = React.memo(
    DesignVariableTableDisplayComponent
);
