import React from "react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { ModelVariable } from "@/models";
import { VariableValueFormatFunction } from "@/hooks";
import { InputDataItem } from "../types";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { SettingsVariableSlider } from "./SettingsVariableSlider";

const SettingsVariableStepPlusMinusButtonComponent = ({
    isDisabled,
    isIncrease,
    handleOnClick,
}: {
    isDisabled: boolean;
    isIncrease: boolean;
    handleOnClick: () => void;
}) => {
    return (
        <button
            type="button"
            className="mx-1 inline-flex items-center rounded-md bg-transparent text-center text-sm
                font-medium text-slate-400 transition-all hover:text-slate-900
                focus:outline-none focus:ring-0 dark:text-slate-600 dark:hover:text-slate-400"
            disabled={isDisabled}
            onClick={(e) => {
                e.stopPropagation();
                handleOnClick();
            }}
        >
            {isIncrease ? (
                <PlusIcon className="h-3 w-3" />
            ) : (
                <MinusIcon className="h-3 w-3" />
            )}
        </button>
    );
};
const SettingsVariableStepPlusMinusButton = React.memo(
    SettingsVariableStepPlusMinusButtonComponent,
);

const SettingsVariableStepIntervalComponent = ({
    itemKey,
    index,
    modelVariable,
    inputDataItem,
    setInputDataItem,
    isBusy,
    setIsBusy,
    editedInputDataItem,
    setEditedInputDataItem,
    rangeStep,
    formatVariableValue,
}: {
    itemKey: string;
    index: number;
    modelVariable: ModelVariable;
    inputDataItem: InputDataItem;
    setInputDataItem: (inputDataItem: InputDataItem) => void;
    isBusy: boolean;
    setIsBusy: (isBusy: boolean) => void;
    editedInputDataItem: InputDataItem;
    setEditedInputDataItem: (editedInputDataItem: InputDataItem) => void;
    rangeStep: number;
    formatVariableValue: VariableValueFormatFunction;
}) => {
    return (
        <div
            key={`${itemKey}-${index}`}
            title={`time horizon ${index + 1}`}
            className="flex rounded-md border border-transparent hover:border-slate-400"
        >
            <SettingsVariableStepPlusMinusButton
                isDisabled={
                    isBusy ||
                    (!!editedInputDataItem
                        ? Number(editedInputDataItem[itemKey][index])
                        : Number(inputDataItem[itemKey][index])) ==
                        modelVariable.minimum
                }
                isIncrease={false}
                handleOnClick={() => {
                    setIsBusy(true);
                    if (editedInputDataItem == undefined) {
                        setInputDataItem({
                            ...inputDataItem,
                            [itemKey]: (inputDataItem[itemKey] as number[]).map(
                                (item, i) =>
                                    i === index
                                        ? Math.max(
                                              modelVariable.minimum,
                                              Number(item) - rangeStep,
                                          )
                                        : Number(item),
                            ),
                        });
                    }
                }}
            />
            <Popover>
                <PopoverTrigger
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        className={`w-[1/${(inputDataItem[itemKey] as number[])?.length}] relative inline-flex
                        rounded-md bg-opacity-50 px-2 py-1 font-semibold transition-all
                        hover:bg-slate-200 hover:text-slate-600 dark:hover:bg-slate-600
                        dark:hover:text-slate-300`}
                    >
                        <span className="sr-only">{`${itemKey}-${index}`}</span>
                        {formatVariableValue(
                            modelVariable.unit,
                            !!editedInputDataItem
                                ? Number(editedInputDataItem[itemKey][index])
                                : Number(inputDataItem[itemKey][index]),
                            modelVariable.is_integer,
                        )}
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align={"center"}
                    sideOffset={0}
                    style={{
                        backgroundColor: "white",
                        padding: "8px",
                        width: "auto",
                    }}
                >
                    <div className="-mx-6 flex justify-center">
                        <SettingsVariableSlider
                            rangeMin={modelVariable.minimum}
                            rangeMax={modelVariable.maximum}
                            rangeStep={
                                rangeStep
                                // modelVariable.is_integer
                                //     ? 1
                                //     : getRangeStep(
                                //           Number(
                                //               inputDataItem.numerical_value,
                                //           ),
                                //       )
                            }
                            isDisabled={isBusy}
                            value={
                                !!editedInputDataItem
                                    ? Number(
                                          editedInputDataItem[itemKey][index],
                                      )
                                    : Number(inputDataItem[itemKey][index])
                            }
                            handleOnChange={(value: number) =>
                                setEditedInputDataItem({
                                    ...inputDataItem,
                                    [itemKey]: (
                                        inputDataItem[itemKey] as number[]
                                    ).map((item, i) =>
                                        i === index ? value : item,
                                    ),
                                })
                            }
                            handleOnClick={() => {
                                if (!!editedInputDataItem) {
                                    setIsBusy(true);
                                    setInputDataItem(editedInputDataItem);
                                }
                            }}
                        />
                    </div>
                </PopoverContent>
            </Popover>
            <SettingsVariableStepPlusMinusButton
                isDisabled={
                    isBusy ||
                    (!!editedInputDataItem
                        ? Number(editedInputDataItem[itemKey][index])
                        : Number(inputDataItem[itemKey][index])) ==
                        modelVariable.maximum
                }
                isIncrease={true}
                handleOnClick={() => {
                    setIsBusy(true);
                    if (editedInputDataItem == undefined) {
                        setInputDataItem({
                            ...inputDataItem,
                            [itemKey]: (inputDataItem[itemKey] as number[]).map(
                                (item, i) =>
                                    i === index
                                        ? Math.min(
                                              modelVariable.maximum,
                                              Number(item) + rangeStep,
                                          )
                                        : Number(item),
                            ),
                        });
                    }
                }}
            />
        </div>
    );
};

export const SettingsVariableStepInterval = React.memo(
    SettingsVariableStepIntervalComponent,
);
