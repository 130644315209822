import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { Tenant } from "@/models";
import { ModalContainer } from "@/modules/shared";
import React, { useState } from "react";

type Props = {
    tenant: Tenant;
    cancel: () => void;
};

export default function DeleteTenantModal({ tenant, cancel }: Props) {
    const [isDeleting, setIsDeleting] = useState(false);
    function deleteTenant() {
        // delete tenant
        setIsDeleting(() => true);
        const route = sapienRoute("tenants.destroy.schema", {
            tenant: tenant.id,
        });
        SapienInertia.delete(route, {
            onSuccess: () => {
                setIsDeleting(() => false);
                cancel();
            },
        });
    }

    return (
        <ModalContainer isModalOpen={!!tenant} size="m">
            {!!tenant && (
                <div className="flex flex-col space-y-4 px-4 py-6">
                    <h3 className=" text-lg font-bold">
                        Are you sure you want to delete {tenant.name}?
                    </h3>
                    <p>This can't be undone</p>
                    <div
                        className={`flex justify-end space-x-2 ${isDeleting && "animate-pulse"}`}
                    >
                        <button
                            onClick={cancel}
                            disabled={isDeleting}
                            className="rounded-md border border-green-500 px-4 py-2 text-green-500"
                        >
                            Keep Tenant
                        </button>
                        <button
                            onClick={deleteTenant}
                            disabled={isDeleting}
                            className="rounded-md border border-red-500 px-4 py-2 text-red-500 transition-colors hover:bg-red-500 hover:text-white"
                        >
                            Delete Tenant
                        </button>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
}
