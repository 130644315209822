import React from "react";
import AddContentBlockButton from "../AddContentBlockButton";
import { Folder, ListCollapse } from "lucide-react";
import { createAccordion } from "./content-block-functions/createAccordion";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { createTabs } from "./content-block-functions/createTabs";

type Props = {
    roundId: string;
    weight: number;
};

export default function AccordionOrTabsCreator({ roundId, weight }: Props) {
    return (
        <Popover>
            <PopoverTrigger
                className="flex h-16 flex-col items-center justify-center rounded-md p-2 px-4
                    transition-colors hover:bg-slate-200 disabled:cursor-not-allowed
                    disabled:bg-slate-200 disabled:text-slate-700"
            >
                <ListCollapse className="h-6 w-6" />
                <p>Accordion/Tabs</p>
            </PopoverTrigger>
            <PopoverContent align="start" className="flex gap-4 bg-white p-4">
                <AddContentBlockButton
                    buildContentBlock={() => createAccordion(roundId, weight)}
                >
                    <ListCollapse className="h-6 w-6" />
                    <p>Accordion</p>
                </AddContentBlockButton>
                <AddContentBlockButton
                    buildContentBlock={() => createTabs(roundId, weight)}
                >
                    <Folder className="h-6 w-6" />
                    <p>Tabs</p>
                </AddContentBlockButton>
            </PopoverContent>
        </Popover>
    );
}
