import { ContentBlockShape } from "@/models";
import { ContentBlockType } from "@/models/ContentBlockType";

const types = [
    ContentBlockType.Tabs,
    ContentBlockType.Table,
    ContentBlockType.Accordion,
    ContentBlockType["Flip Card"],
    ContentBlockType.Image,
    ContentBlockType.Question,
    ContentBlockType["Rich Text"],
    ContentBlockType.Video,
];

export function getType(contentBlock: ContentBlockShape) {
    for (let i = 0; i < contentBlock?.contentBlocks?.length; i++) {
        const child = contentBlock.contentBlocks[i];
        if (types.includes(child.content_block_type)) {
            return child.content_block_type;
        }
        return getType(child);
    }

    return contentBlock.content_block_type;
}
