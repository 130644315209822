import React from "react";
import {
    useFormContext,
    RegisterOptions,
    Controller,
    FieldValues,
} from "react-hook-form";
import { RankingComponent, RankingOption } from "./RankingComponent";
import { ErrorDisplay } from "./ErrorDisplay";
import {
    FormGroup,
    FormLabel,
    FormInput,
    FormSelect,
    FormOption,
    FormTextArea,
    FormInputRadioCheckbox,
    FormInputRange,
    FormInputToggle,
    FormToggleLabel,
    FormToggleDiv,
    FormToggleLeftLabel,
    FormToggleRightLabel,
    FormRadioOrCheckboxGroup,
} from "../components";
import { ColorScheme } from "../models";
import { ThemeObject } from "../styles";

interface FormFieldThemeProps {
    spaced?: "spaced" | "not spaced";
    highLightStyle?: "none" | "grayscale" | "color";
    bordered?: "bordered" | "not bordered";
    collapsed?: boolean;
}

// row count and column count
// number of children: rows * columns
// child widths at four breakpoints, e.g., [1, 1 / 2, 1 / 3, 1 / 4]
// (last being 1 / column count)

type FormFieldTheme = Partial<ThemeObject> & FormFieldThemeProps;

export enum FormFieldType {
    "Input" = "INPUT",
    "Number" = "NUMBER",
    "Dropdown" = "DROPDOWN",
    "Text Area" = "TEXT_AREA",
    "Radio" = "RADIO",
    "Checkbox" = "CHECKBOX",
    "Slider" = "SLIDER",
    "Ranking" = "RANKING",
    "Toggle" = "TOGGLE",
    "Email" = "EMAIL", // incomplete
    "Field Set" = "FIELD_SET",
}

export type FormFieldDisplayProps = {
    name?: string;
    formFieldType?: FormFieldType;
    label?: string;
    validationRules?: RegisterOptions;
    step?: number;
    options?: { [index: string]: any };
    updateOptions?: (rankingOptions: RankingOption[]) => void;
    onChange?: (formState: FieldValues) => void;
    palette?: ColorScheme;
    theme?: FormFieldTheme;
    contentBlockId?: string;
    fields?: FormFieldDisplayProps;
};

export const FormField = (props: FormFieldDisplayProps) => {
    const {
        name,
        formFieldType,
        label,
        validationRules,
        step,
        options,
        updateOptions,
        palette,
        theme,
    } = props;

    const { register, control, formState, watch } = useFormContext();
    const { errors } = formState;
    const error = errors[name] || "";

    switch (formFieldType) {
        case FormFieldType.Input:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormInput
                        type="text"
                        name={name}
                        {...register(name, validationRules)}
                    />
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Number:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormInput
                        type="number"
                        name={name}
                        {...register(name, validationRules)}
                        min={
                            typeof validationRules?.min === "number"
                                ? validationRules.min
                                : undefined
                        }
                        max={
                            typeof validationRules?.max === "number"
                                ? validationRules.max
                                : undefined
                        }
                        step={step !== undefined && step !== 0 ? step : "any"}
                    />
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Dropdown:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormSelect
                        name={name}
                        {...register(name, validationRules)}
                    >
                        {options !== undefined &&
                            Object.keys(options).map((key: any) => (
                                <FormOption
                                    key={options[key]}
                                    value={options[key]}
                                >
                                    {key}
                                </FormOption>
                            ))}
                    </FormSelect>
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType["Text Area"]:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormTextArea
                        name={name}
                        {...register(name, validationRules)}
                    />
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Radio:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormRadioOrCheckboxGroup
                        spaced={theme?.spaced || "not spaced"}
                        palette={palette}
                        bordered={theme?.bordered || "not bordered"}
                        collapsed={theme?.collapsed}
                    >
                        <>
                            {options !== undefined &&
                                Object.keys(options).map((key: any) => (
                                    <FormLabel
                                        key={key}
                                        isSelected={
                                            options[key] === watch(name)
                                        }
                                        palette={palette}
                                        highLightStyle={
                                            theme?.highLightStyle || "none"
                                        }
                                    >
                                        <FormInputRadioCheckbox
                                            type="radio"
                                            name={name}
                                            value={options[key]}
                                            {...register(name, validationRules)}
                                        />
                                        {key}
                                    </FormLabel>
                                ))}
                        </>
                    </FormRadioOrCheckboxGroup>
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Checkbox:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormRadioOrCheckboxGroup
                        spaced={theme?.spaced || "spaced"}
                        palette={palette}
                        bordered={theme?.bordered || "not bordered"}
                    >
                        <>
                            {options !== undefined &&
                                Object.keys(options).map((key: any) => (
                                    <FormLabel
                                        key={key}
                                        isSelected={
                                            options[key] === watch(name)
                                        }
                                        palette={palette}
                                        highLightStyle={
                                            theme?.highLightStyle || "none"
                                        }
                                    >
                                        <FormInputRadioCheckbox
                                            type="checkbox"
                                            name={name}
                                            value={options[key]}
                                            {...register(name, validationRules)}
                                        />
                                        {key}
                                    </FormLabel>
                                ))}
                        </>
                    </FormRadioOrCheckboxGroup>
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Slider:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <FormInputRange
                        type="range"
                        name={name}
                        {...register(name, validationRules)}
                        min={
                            typeof validationRules?.min === "number"
                                ? validationRules.min
                                : undefined
                        }
                        max={
                            typeof validationRules?.max === "number"
                                ? validationRules.max
                                : undefined
                        }
                        step={step !== undefined && step !== 0 ? step : 1}
                    />
                </FormGroup>
            );
        case FormFieldType.Ranking:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    {updateOptions !== undefined && options !== undefined && (
                        <Controller
                            name={name || ""}
                            control={control}
                            // defaultValue={false}
                            rules={{ required: false }}
                            render={(props) => (
                                <RankingComponent
                                    {...props}
                                    options={Object.values(options)}
                                    updateOptions={updateOptions}
                                />
                            )} // props contains: onChange, onBlur and value
                        />
                    )}
                </FormGroup>
            );
        case FormFieldType.Toggle:
            return (
                <FormGroup palette={palette}>
                    <FormLabel>{label}</FormLabel>
                    <div className="flex flex-row items-center">
                        <FormToggleLeftLabel>
                            {options !== undefined &&
                                Object.keys(options).length > 0 &&
                                Object.keys(options)[0]}
                        </FormToggleLeftLabel>
                        <FormToggleDiv>
                            <FormInputToggle
                                type="checkbox"
                                id="toggle"
                                name={name}
                                {...register(name, validationRules)}
                            />
                            <FormToggleLabel htmlFor="toggle" />
                        </FormToggleDiv>
                        <FormToggleRightLabel>
                            {options !== undefined &&
                                Object.keys(options).length > 1 &&
                                Object.keys(options)[1]}
                        </FormToggleRightLabel>
                    </div>
                    <ErrorDisplay error={error} />
                </FormGroup>
            );
        case FormFieldType.Email:
            return <FormGroup palette={palette}></FormGroup>;
        case FormFieldType["Field Set"]:
            <FormGroup palette={palette}></FormGroup>;
        default:
            return null;
    }
};
