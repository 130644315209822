import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import axios from "axios";

export async function reorderLayers({
    page_id,
    destination_index,
}: {
    page_id: string;
    destination_index: number;
}) {
    const route = sapienRoute("admin.design.reorder-pages");
    const {data} = await axios.post<{topLevelContentBlocks:ContentBlockShape[]}>(route, {
        page_id,
        destination_index,
    });

    return data.topLevelContentBlocks;
}
