import React from "react";
import { VariableSettingsNumericalProps } from "./props";
import { SettingsVariableSlider } from "./SettingsVariableSlider";
import { SettingsVariableNumberInput } from "./SettingsVariableNumberInput";

const WizardNumericVariableDisplayComponent = ({
    label,
    formattedValue,
    isBusy,
    isActive,
    handleSetActive,
    rangeMin,
    rangeMax,
    rangeStep,
    isDisabled,
    value,
    handleOnChange,
    handleOnClick,
    handleOnBlur,
}: {
    label: string;
    formattedValue: string;
    isBusy: boolean;
    isActive: boolean;
    handleSetActive: () => void;
} & VariableSettingsNumericalProps) => {
    return (
        <div className="flex items-center space-x-2">
            <div className="w-12 min-w-12 whitespace-nowrap font-normal capitalize">
                {label}
            </div>
            <div className="flex items-center">
                <SettingsVariableSlider
                    rangeMin={rangeMin}
                    rangeMax={rangeMax}
                    rangeStep={rangeStep}
                    isDisabled={isDisabled}
                    value={value}
                    handleOnChange={handleOnChange}
                    handleOnClick={handleOnClick}
                />

                {!isActive ? (
                    <div
                        className={`min-w-[140px] rounded-md border border-slate-200 bg-slate-100/50 px-2 py-1
                            text-sm font-semibold text-slate-700 transition-colors hover:border-blue-600
                            dark:border-slate-800 dark:bg-slate-800/20 dark:text-slate-300
                            dark:hover:border-blue-700 ${
                                isDisabled
                                    ? "hover:border-slate-200 dark:hover:border-slate-800"
                                    : ""
                            }`}
                        onClick={() => {
                            handleSetActive();
                        }}
                    >
                        {formattedValue}
                    </div>
                ) : (
                    <div>
                        <SettingsVariableNumberInput
                            rangeMin={rangeMin}
                            rangeMax={rangeMax}
                            rangeStep={rangeStep}
                            isDisabled={isBusy}
                            value={value}
                            handleOnChange={handleOnChange}
                            handleOnBlur={handleOnBlur}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const WizardNumericVariableDisplay = React.memo(
    WizardNumericVariableDisplayComponent,
);
