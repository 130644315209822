import { ContentBlock } from "../models";
import { TemplateContentBlocks } from "./TemplateContentBlocks";
import { TemplateCategory, TemplateType } from "./TemplateModels";

// export const TemplateCategoryDescriptions: {
//     [index in keyof typeof TemplateCategory]?: string;
// } = {
//     Design: "Visual components with styling and placeholders",
//     Content: "Pre-formatted rounds with genre-specific elements",
//     Activity: "Stand-alone challenges with questions",
//     Module: "Sets of rounds with themed gameplay and integrated model behavior",
// };

interface Template {
    template_category: TemplateCategory;
    template_type: TemplateType;
    label: string;
    description: string;
    contentBlocks: ContentBlock[];
}

export const Templates: {
    [index in TemplateType]: Template;
} = {
    [TemplateType["Trends Assessment"]]: {
        template_category: TemplateCategory.Activity,
        template_type: TemplateType["Trends Assessment"],
        label: "Trends Assessment",
        description: "Description of trends assessment template",
        contentBlocks: TemplateContentBlocks[TemplateType["Trends Assessment"]],
    },
    [TemplateType["Resource Allocation"]]: {
        template_category: TemplateCategory.Module,
        template_type: TemplateType["Resource Allocation"],
        label: "Resource Allocation",
        description: "Description of resource allocation template",
        contentBlocks:
            TemplateContentBlocks[TemplateType["Resource Allocation"]],
    },
    [TemplateType["Welcome Page"]]: {
        template_category: TemplateCategory.Design,
        template_type: TemplateType["Welcome Page"],
        label: "Welcome Page",
        description: "Description of welcome page template",
        contentBlocks: TemplateContentBlocks[TemplateType["Welcome Page"]],
    },
    [TemplateType["Strategic Path"]]: {
        template_category: TemplateCategory.Module,
        template_type: TemplateType["Strategic Path"],
        label: "Strategic Path",
        description: "Description of strategic path template",
        contentBlocks: TemplateContentBlocks[TemplateType["Strategic Path"]],
    },
    [TemplateType["Event Decision"]]: {
        template_category: TemplateCategory.Activity,
        template_type: TemplateType["Event Decision"],
        label: "Event Decision",
        description: "Description of event decision template",
        contentBlocks: TemplateContentBlocks[TemplateType["Event Decision"]],
    },
    [TemplateType["Organization Overview"]]: {
        template_category: TemplateCategory.Content,
        template_type: TemplateType["Organization Overview"],
        label: "Organization Overview",
        description: "Description of organization overview template",
        contentBlocks:
            TemplateContentBlocks[TemplateType["Organization Overview"]],
    },
};

export const TemplateCategoryObject: {
    [index in keyof typeof TemplateCategory]: {
        description: string;
        templateTypes: TemplateType[];
    };
} = {
    Design: {
        description: "Visual components with styling and placeholders",
        templateTypes: [TemplateType["Welcome Page"]],
    },
    Content: {
        description: "Pre-formatted rounds with genre-specific elements",
        templateTypes: [TemplateType["Organization Overview"]],
    },
    Activity: {
        description: "Stand-alone challenges with questions",
        templateTypes: [
            TemplateType["Trends Assessment"],
            TemplateType["Event Decision"],
        ],
    },
    Module: {
        description:
            "Rounds with themed gameplay and integrated model behavior",
        templateTypes: [
            TemplateType["Resource Allocation"],
            TemplateType["Strategic Path"],
        ],
    },
};
