import { useAtom } from "jotai";
import React from "react";
import { updatedContentBlockIdAtom } from "../content-block-management/useSaveContentBlock";

type Props = { contentBlockId: string };

export default function UpdateContentBlockOverlay({ contentBlockId }: Props) {
    const [updatedContentBlockId] = useAtom(updatedContentBlockIdAtom);

    if (!updatedContentBlockId || updatedContentBlockId !== contentBlockId)
        return null;

    return <div className="absolute inset-0 animate-pulse bg-white/50"></div>;
}
