import React from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormOption,
    EditorFormSelectSmall,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";

export const FormSelectObject = (props: {
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    optionObject: { [index: string]: string | number };
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    displayProperty?: (property: string | number) => string;
}) => {
    const {
        value,
        label,
        themeObjectKey,
        optionObject,
        handleChange,
        displayProperty,
    } = props;

    return (
        <EditorFormGroup>
            <EditorFormLabel>{label}</EditorFormLabel>
            <EditorFormSelectSmall
                value={value}
                onChange={(e) => handleChange(themeObjectKey, e.target.value)}
            >
                {optionObject !== undefined &&
                    Object.keys(optionObject)
                        .sort((a, b) => {
                            if (!isNaN(Number(a)) && !isNaN(Number(b))) {
                                return Number(a) - Number(b);
                            }

                            return 1;
                        })
                        .map((key: string) => (
                            <EditorFormOption
                                key={key}
                                value={key}
                                className="bg-[#29303e]"
                            >
                                {displayProperty !== undefined
                                    ? displayProperty(optionObject[key])
                                    : optionObject[key]}
                            </EditorFormOption>
                        ))}
            </EditorFormSelectSmall>
        </EditorFormGroup>
    );
};
