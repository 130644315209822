import {
    ModelBlock,
    ModelVariable,
    ModelVariableAggregateOperation,
    ModelVariableDataType,
    ModelVariableType,
    TimeHorizon,
    VariableRelationship,
    VariableRelationshipOperation,
    VariableValue,
} from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state/AppStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { modelBlockSchema } from "@/redux-state/modelBuilder/schema";
import {
    setActiveModelVariable,
    gotVariables,
} from "@/redux-state/modelBuilder/modelVariableSlice";
import { gotModelBlocks } from "@/redux-state/modelBuilder/modelBlockSlice";
import { gotVariableRelationships } from "@/redux-state/modelBuilder/variableRelationshipSlice";
import { denormalize } from "normalizr";
import {
    createNestedTree,
    getDownstreamVariableIds,
    getNewVariableRelationship,
    getNewVariableValue,
    getUpstreamVariableIds,
} from "@/util";
import { mapById } from "@/redux-state/util";
import { Dictionary } from "lodash";
import {
    setAvailableVariablesMap,
    setSourceVariableRelationships,
    setSourceVariables,
    setTargetVariable,
} from "@/redux-state/modelBuilder/builderWorkspaceSlice";

export function useModelBuilderStore() {
    const dispatch = useAppDispatch();

    const _gotModelBlocks = useCallback(
        (modelBlocks: ModelBlock[]) => dispatch(gotModelBlocks(modelBlocks)),
        [dispatch],
    );

    const _gotVariableRelationships = useCallback(
        (variableRelationships: VariableRelationship[]) =>
            dispatch(gotVariableRelationships(variableRelationships)),
        [dispatch],
    );

    return {
        gotModelBlocks: _gotModelBlocks,
        gotVariableRelationships: _gotVariableRelationships,
    };
}

export function useModelBlockArray() {
    const modelBlockArray = useAppSelector((state) =>
        Object.values(state.modelBuilderStore.modelBlockStore.modelBlocks),
    );

    return {
        modelBlockArray,
    };
}

export function useModelBlockStore() {
    const modelBlocks: { [index: string]: ModelBlock } = useAppSelector(
        (state) => {
            const denormalized = denormalize(
                {
                    modelBlocks: Object.keys(
                        state.modelBuilderStore.modelBlockStore.modelBlocks,
                    ),
                },
                { modelBlocks: [modelBlockSchema] },
                {
                    modelBlocks:
                        state.modelBuilderStore.modelBlockStore.modelBlocks,
                    modelVariables:
                        state.modelBuilderStore.modelVariableStore
                            .modelVariables,
                },
            );

            const sortedModelBlocks = [...denormalized.modelBlocks].sort(
                (a, b) => a.weight - b.weight,
            );

            return mapById(
                createNestedTree(
                    "parent_model_block_id",
                    "modelBlocks",
                    sortedModelBlocks,
                ),
            );
        },
    );

    return {
        modelBlocks,
    };
}

export function useModelVariableStore() {
    const dispatch = useAppDispatch();

    const _activeModelVariable = useAppSelector(
        (state) =>
            state.modelBuilderStore.modelVariableStore.activeModelVariable,
    );

    const _setActiveModelVariable = useCallback(
        (modelVariable: ModelVariable) =>
            dispatch(setActiveModelVariable(modelVariable)),
        [dispatch],
    );

    const _modelVariables = useAppSelector(
        (state) => state.modelBuilderStore.modelVariableStore.modelVariables,
    );

    const timeSeriesModelVariables = useAppSelector((state) =>
        Object.values(
            state.modelBuilderStore.modelVariableStore.modelVariables,
        ).filter((variable) => variable.uses_time),
    );

    const nonTimeSeriesModelVariables = useAppSelector((state) =>
        Object.values(
            state.modelBuilderStore.modelVariableStore.modelVariables,
        ).filter((variable) => !variable.uses_time),
    );

    return {
        activeModelVariable: _activeModelVariable,
        setActiveModelVariable: _setActiveModelVariable,
        modelVariables: _modelVariables,
        timeSeriesModelVariables,
        nonTimeSeriesModelVariables,
    };
}

export function useGotModelVariables() {
    const dispatch = useAppDispatch();

    const _gotModelVariables = useCallback(
        (modelVariables) => {
            dispatch(gotVariables(modelVariables));
        },
        [dispatch],
    );

    return {
        gotModelVariables: _gotModelVariables,
    };
}

export function useVariableRelationshipStore() {
    const relationshipsBySourceId = useAppSelector(
        (state) =>
            state.modelBuilderStore.variableRelationshipStore
                .relationshipsBySourceId,
    );

    const relationshipsByTargetId = useAppSelector(
        (state) =>
            state.modelBuilderStore.variableRelationshipStore
                .relationshipsByTargetId,
    );

    return {
        relationshipsBySourceId,
        relationshipsByTargetId,
    };
}

export function useVariableValueForm(
    modelVariable: ModelVariable,
    timeHorizons: TimeHorizon[],
) {
    const [variableValue, setVariableValue] = useState<VariableValue>();
    const [variableValueMap, setVariableValueMap] = useState<{
        [index: string]: VariableValue;
    }>();

    useEffect(() => {
        if (
            !!modelVariable &&
            !!modelVariable.variable_type &&
            modelVariable.variable_type === ModelVariableType.Independent
        ) {
            if (!modelVariable.uses_time) {
                if (!variableValue) {
                    if (!!modelVariable.id) {
                        let value = !!modelVariable.baseVariableValues?.length
                            ? modelVariable.baseVariableValues[0]
                            : getNewVariableValue(modelVariable.id);
                        setVariableValue(value);
                    } else {
                        let value = getNewVariableValue(modelVariable.id);
                        setVariableValue(value);
                        setVariableValueMap(undefined);
                    }
                }
            } else {
                if (!variableValueMap) {
                    if (!!modelVariable.id) {
                        let map = timeHorizons?.reduce(
                            (valueMap, timeHorizon) => ({
                                ...valueMap,
                                [timeHorizon.id]:
                                    modelVariable.baseVariableValues?.find(
                                        (variableValue) =>
                                            variableValue.time_horizon_id ===
                                            timeHorizon.id,
                                    ) ||
                                    getNewVariableValue(
                                        modelVariable.id,
                                        timeHorizon.id,
                                    ),
                            }),
                            {},
                        );
                        setVariableValueMap(map);
                    } else {
                        let map = timeHorizons?.reduce(
                            (valueMap, timeHorizon) => ({
                                ...valueMap,
                                [timeHorizon.id]: getNewVariableValue(
                                    modelVariable.id,
                                    timeHorizon.id,
                                ),
                            }),
                            {},
                        );
                        setVariableValueMap(map);
                        setVariableValue(undefined);
                    }
                }
            }
        } else {
            setVariableValue(undefined);
            setVariableValueMap(undefined);
        }
    }, [modelVariable]);

    const getBaseValuesToSave = useCallback(() => {
        let baseVariableValues: VariableValue[];
        if (!!variableValue) {
            baseVariableValues = [variableValue];
        } else if (!!variableValueMap) {
            baseVariableValues = Object.values(variableValueMap);
        } else if (
            !modelVariable.id &&
            (modelVariable.variable_type === ModelVariableType.Iterative ||
                modelVariable.variable_type === ModelVariableType.Aggregate ||
                modelVariable.variable_type === ModelVariableType.Algebraic ||
                modelVariable.variable_type === ModelVariableType.Conditional ||
                modelVariable.variable_type === ModelVariableType.Logical ||
                modelVariable.variable_type === ModelVariableType.Comparison ||
                modelVariable.variable_type === ModelVariableType["Time Shift"])
        ) {
            if (modelVariable.uses_time) {
                baseVariableValues = timeHorizons?.map((timeHorizon) =>
                    getNewVariableValue(
                        modelVariable.id,
                        timeHorizon.id,
                        modelVariable.default_numerical_value,
                        modelVariable.default_boolean_value,
                    ),
                );
            } else {
                baseVariableValues = [
                    getNewVariableValue(
                        modelVariable.id,
                        null,
                        modelVariable.default_numerical_value,
                        modelVariable.default_boolean_value,
                    ),
                ];
            }
        }
        return baseVariableValues;
    }, [modelVariable, timeHorizons, variableValue, variableValueMap]);

    return {
        variableValue,
        setVariableValue,
        variableValueMap,
        setVariableValueMap,
        getBaseValuesToSave,
    };
}

export function useModelBlockAccordionState() {
    const [activeModelBlockAccordionKeys, setActiveModelBlockAccordionKeys] =
        useState({});

    const toggleModelBlockAccordionKey = useCallback(
        (key: string) => {
            const newKeys = !!activeModelBlockAccordionKeys[key]
                ? Object.keys(activeModelBlockAccordionKeys).reduce(
                      (map, accordionKey) => {
                          return accordionKey === key
                              ? map
                              : { ...map, [accordionKey]: true };
                      },
                      {},
                  )
                : { ...activeModelBlockAccordionKeys, [key]: true };
            setActiveModelBlockAccordionKeys(newKeys);
        },
        [activeModelBlockAccordionKeys],
    );

    const expandMultipleModelBlockAccordionKeys = useCallback(
        (keys: string[]) => {
            const newKeys = keys.reduce(
                (map, accordionKey) => {
                    return { ...map, [accordionKey]: true };
                },
                { ...activeModelBlockAccordionKeys },
            );
            setActiveModelBlockAccordionKeys(newKeys);
        },
        [activeModelBlockAccordionKeys],
    );

    const collapseMultipleModelBlockAccordionKeys = useCallback(
        (keys?: string[]) => {
            if (!!keys?.length) {
                // if we have a set of keys, collapse just those
                const newKeys = Object.keys(
                    activeModelBlockAccordionKeys,
                ).reduce((map, accordionKey) => {
                    return keys.includes(accordionKey)
                        ? map
                        : { ...map, [accordionKey]: true };
                }, {});
                setActiveModelBlockAccordionKeys(newKeys);
            } else {
                // otherwise collapse all
                setActiveModelBlockAccordionKeys({});
            }
        },
        [activeModelBlockAccordionKeys],
    );

    const getIsModelBlockExpanded = useCallback(
        (modelBlockId: string) => {
            return !!activeModelBlockAccordionKeys[modelBlockId];
        },
        [activeModelBlockAccordionKeys],
    );

    return {
        toggleModelBlockAccordionKey,
        expandMultipleModelBlockAccordionKeys,
        collapseMultipleModelBlockAccordionKeys,
        getIsModelBlockExpanded,
    };
}

export function useBuilderWorkspaceStore() {
    const dispatch = useAppDispatch();

    const _targetVariable = useAppSelector(
        (state) => state.builderWorkspaceStore.targetVariable,
    );

    const _sourceVariables = useAppSelector(
        (state) => state.builderWorkspaceStore.sourceVariables,
    );

    const _sourceVariableRelationships = useAppSelector(
        (state) => state.builderWorkspaceStore.sourceVariableRelationships,
    );

    const _modelVariables = useAppSelector(
        (state) => state.modelBuilderStore.modelVariableStore.modelVariables,
    );

    const relationshipsByTargetId = useAppSelector(
        (state) =>
            state.modelBuilderStore.variableRelationshipStore
                .relationshipsByTargetId,
    );

    const _sourceVariableRelationshipMap = useAppSelector(
        (state) => state.builderWorkspaceStore.sourceVariableRelationshipMap,
    );

    const _availableVariablesMap = useAppSelector(
        (state) => state.builderWorkspaceStore.availableVariablesMap,
    );

    const _setTargetVariable = useCallback(
        (targetVariable: ModelVariable) =>
            dispatch(setTargetVariable(targetVariable)),
        [dispatch],
    );

    const _setSourceVariables = useCallback(
        (sourceVariables: { [variableId: string]: ModelVariable }) =>
            dispatch(setSourceVariables(sourceVariables)),
        [dispatch],
    );

    const _setSourceVariableRelationships = useCallback(
        (sourceVariableRelationships: VariableRelationship[]) =>
            dispatch(
                setSourceVariableRelationships(sourceVariableRelationships),
            ),
        [dispatch],
    );

    const _setAvailableVariablesMap = useCallback(
        (availableVariablesMap: { [variableId: string]: ModelVariable }) =>
            dispatch(setAvailableVariablesMap(availableVariablesMap)),
        [dispatch],
    );

    const getCanToggleTargetVariable = useCallback(
        (variable: ModelVariable) => {
            // toggle as target only if variable isn't source (confusing otherwise)
            // tbd: other rules (based on target and sources)?
            // for now we're setting/unsetting everything as appropriate (no fancy stuff)
            return !_sourceVariables[variable.id];
        },
        [_sourceVariables],
    );

    const toggleTargetVariable = useCallback(
        (variable: ModelVariable) => {
            if (!!_targetVariable && _targetVariable.id === variable.id) {
                // if we have a target and it's this variable, unset it
                _setTargetVariable(undefined);

                // unset source variables and relationships (just based on existing relationships? preserve some? TBD)
                _setSourceVariables({});
                _setSourceVariableRelationships([]);
            } else {
                // otherwise, either we don't have a target or we do and it isn't this variable
                // so change the target to this variable
                _setTargetVariable(variable);

                const existingRelationships =
                    !!relationshipsByTargetId &&
                    relationshipsByTargetId[variable.id];
                // if new target has relationships, set source variables and relationships accordingly
                // otherwise, unset source variables and relationships (for now, but behavior TBD)
                if (existingRelationships?.length > 0) {
                    _setSourceVariables(
                        existingRelationships.reduce((map, relationship) => {
                            if (
                                !!_modelVariables &&
                                _modelVariables[relationship.source_variable_id]
                            ) {
                                return {
                                    ...map,
                                    [relationship.source_variable_id]:
                                        _modelVariables[
                                            relationship.source_variable_id
                                        ],
                                };
                            } else {
                                return map;
                            }
                        }, {}),
                    );
                    _setSourceVariableRelationships([
                        ...existingRelationships.map((relationship, index) => ({
                            ...relationship,
                            weight: index,
                        })),
                    ]);
                } else {
                    _setSourceVariables({});
                    _setSourceVariableRelationships([]);
                }
            }
        },
        [
            _targetVariable,
            _setTargetVariable,
            _setSourceVariables,
            _setSourceVariableRelationships,
            relationshipsByTargetId,
            _modelVariables,
        ],
    );

    const getCanToggleSourceVariable = useCallback(
        (variable: ModelVariable) => {
            // toggle as source only if variable isn't target
            // plus other rules (based on target and sources)
            // e.g., for simplicity, if already-saved relationship exists, don't allow removal
            // also, check type/etc of target/sources if we want to add it
            const isTarget = variable.id === _targetVariable?.id;
            const isSource = !!_sourceVariables[variable.id];
            const hasSavedRelationship = _sourceVariableRelationships.find(
                (relationship) =>
                    relationship.source_variable_id === variable.id &&
                    !!relationship.id,
            );
            let isAddableAsNewSource = true;
            // if we have a target, we need to check conditions
            if (!!_targetVariable && !!_availableVariablesMap) {
                isAddableAsNewSource =
                    !!_availableVariablesMap[variable.id] &&
                    variable.scope === _targetVariable.scope; // for now, to make less confusing
            } else {
                isAddableAsNewSource = false; // remove when new target creation is implemented
            }
            // otherwise, we don't have a target and can assume we don't have other sources either
            // (otherwise a target would have been generated)

            return (
                !isTarget && // not target and
                ((isSource && !hasSavedRelationship) || // either is source but not saved relationship
                    (!isSource && isAddableAsNewSource)) // or is not source (therefore no relationship) and is addable given conditions
            );
        },
        [
            _targetVariable,
            _sourceVariables,
            _sourceVariableRelationships,
            _availableVariablesMap,
        ],
    );

    const toggleSourceVariable = useCallback(
        (variable: ModelVariable) => {
            if (!getCanToggleSourceVariable(variable)) return;

            if (!!_sourceVariables[variable.id]) {
                // if this variable is already a source, we want to remove it
                // with above check, we know there isn't an already-saved relationship
                _setSourceVariables(
                    Object.keys(_sourceVariables).reduce(
                        (map, sourceVariableId) => {
                            return variable.id === sourceVariableId
                                ? map
                                : {
                                      ...map,
                                      [sourceVariableId]:
                                          _sourceVariables[sourceVariableId],
                                  };
                        },
                        {},
                    ),
                );
                _setSourceVariableRelationships(
                    [..._sourceVariableRelationships]
                        .filter(
                            (relationship) =>
                                relationship.source_variable_id !== variable.id,
                        )
                        .map((relationship, i) => ({
                            ...relationship,
                            weight: i,
                        })),
                );
            } else {
                // otherwise, this variable isn't already a source, so we want to add it in
                // with above check, we know it's addable

                // for now, only add source variable/relationship if we have a target
                if (!!_targetVariable) {
                    _setSourceVariables({
                        ..._sourceVariables,
                        [variable.id]: variable,
                    });
                    _setSourceVariableRelationships([
                        ..._sourceVariableRelationships,
                        {
                            ...getNewVariableRelationship(
                                _targetVariable.id,
                                _targetVariable.variable_type,
                                _sourceVariableRelationships.length || 0,
                            ),
                            source_variable_id: variable.id,
                        },
                    ]);
                } else {
                    // TO DO: if we don't have a target variable already, we want to generate a new one
                    // const newTargetVariable = {};
                    // _setTargetVariable(newTargetVariable);
                    // _setSourceVariables({
                    //     ..._sourceVariables,
                    //     [variable.id]: variable,
                    // });
                    // create new relationship based on conditions
                    // _setSourceVariableRelationships([
                    //     ..._sourceVariableRelationships,
                    //     {
                    //         ...getNewVariableRelationship(
                    //             null,
                    //             newTargetVariable.variable_type,
                    //             _sourceVariableRelationships.length || 0
                    //         ),
                    //         source_variable_id: variable.id,
                    //     },
                    // ]);
                }
            }
        },
        [
            _targetVariable,
            _sourceVariables,
            _sourceVariableRelationships,
            _setSourceVariables,
            _setSourceVariableRelationships,
            getCanToggleSourceVariable,
        ],
    );

    const getIsSelectedAsSourceVariable = useCallback(
        (variableId: string) => {
            return !!_sourceVariables[variableId];
        },
        [_sourceVariables],
    );

    const updateSourceRelationship = useCallback(
        (index: number, prop: keyof VariableRelationship, value: any) => {
            const updatedRelationships = [..._sourceVariableRelationships].map(
                (vr, i) =>
                    i === index
                        ? {
                              ...vr,
                              [prop]: value,
                          }
                        : vr,
            );
            _setSourceVariableRelationships(updatedRelationships);
        },
        [_sourceVariableRelationships, _setSourceVariableRelationships],
    );

    const resetBuilderWorkspace = useCallback(() => {
        _setTargetVariable(undefined);
        _setSourceVariables({});
        _setSourceVariableRelationships([]);
    }, [
        _setTargetVariable,
        _setSourceVariableRelationships,
        _setSourceVariableRelationships,
    ]);

    return {
        targetVariable: _targetVariable,
        sourceVariables: _sourceVariables,
        sourceVariableRelationships: _sourceVariableRelationships,
        sourceVariableRelationshipMap: _sourceVariableRelationshipMap,
        toggleTargetVariable,
        toggleSourceVariable,
        getCanToggleTargetVariable,
        getCanToggleSourceVariable,
        getIsSelectedAsSourceVariable,
        modelVariables: _modelVariables,
        updateSourceRelationship,
        resetBuilderWorkspace,
        setAvailableVariablesMap: _setAvailableVariablesMap,
    };
}

export const useRelationshipForm = (
    targetVariable: ModelVariable,
    variableRelationships: VariableRelationship[],
    setVariableRelationships: (
        variableRelationships: VariableRelationship[],
    ) => void,
) => {
    const updateRelationship = useCallback(
        (index: number, prop: keyof VariableRelationship, value: any) => {
            const updatedRelationships = variableRelationships.map((vr, i) =>
                i === index
                    ? {
                          ...vr,
                          [prop]: value,
                      }
                    : vr,
            );
            setVariableRelationships(updatedRelationships);
        },
        [variableRelationships],
    );

    const addNewVariableRelationship = useCallback(() => {
        if (
            targetVariable.variable_type === ModelVariableType["Time Shift"] &&
            variableRelationships?.length > 0
        ) {
            return;
        }

        let newRelationshipArray = [
            ...variableRelationships,
            getNewVariableRelationship(
                targetVariable.id,
                targetVariable.variable_type,
                variableRelationships.length || 0,
            ),
        ];
        if (
            targetVariable.variable_type === ModelVariableType.Conditional ||
            (targetVariable.variable_type === ModelVariableType["Time Shift"] &&
                variableRelationships?.length == 0)
        ) {
            newRelationshipArray = [
                ...newRelationshipArray,
                getNewVariableRelationship(
                    targetVariable.id,
                    targetVariable.variable_type,
                    (variableRelationships.length || 0) + 1,
                ),
            ];
        }
        setVariableRelationships(newRelationshipArray);
    }, [targetVariable, variableRelationships]);

    const removeUnsavedVariableRelationship = useCallback(
        (relationship: VariableRelationship, index: number) => {
            if (
                !relationship.id &&
                targetVariable.variable_type !== ModelVariableType.Conditional
            ) {
                setVariableRelationships(
                    variableRelationships.filter((_, i) => i !== index),
                );
            }
        },
        [targetVariable, variableRelationships],
    );

    return {
        updateRelationship,
        addNewVariableRelationship,
        removeUnsavedVariableRelationship,
    };
};

// to do: refine logic of variables available for source relationships
export const useAvailableVariables = (
    targetVariable: ModelVariable,
    variableRelationshipMap: {
        [index: string]: VariableRelationship;
    },
    modelVariables: { [index: string]: ModelVariable },
    relationshipsBySourceId: Dictionary<VariableRelationship[]>,
    relationshipsByTargetId: Dictionary<VariableRelationship[]>,
) => {
    const downstreamVariableIds = useMemo<{
        [index: string]: boolean;
    }>(() => {
        if (!!targetVariable && !!targetVariable.id) {
            return getDownstreamVariableIds(
                targetVariable.id,
                relationshipsBySourceId,
            );
        }
    }, [targetVariable?.id, relationshipsBySourceId]);

    const upstreamVariableIds = useMemo<{
        [index: string]: boolean;
    }>(() => {
        if (!!targetVariable && !!targetVariable.id) {
            return getUpstreamVariableIds(
                targetVariable.id,
                relationshipsByTargetId,
            );
        }
    }, [targetVariable?.id, relationshipsByTargetId]);

    const availableVariables = useMemo<ModelVariable[]>(() => {
        if (
            !!variableRelationshipMap &&
            !!downstreamVariableIds &&
            !!upstreamVariableIds &&
            !!modelVariables &&
            !!targetVariable
        ) {
            // take out...
            //  - downstream variables
            //  - upstream variables
            //  - self
            //  - variables already having relationship (to do: revisit)
            //  - variables with incompatible time horizon usage
            //  - to do: consider removing selection data types (and their connection siblings)
            //  - to do: consider removing current ancestors by default
            let baseVariableSet = Object.values(modelVariables).filter(
                (variable) =>
                    !downstreamVariableIds[variable.id] &&
                    (!upstreamVariableIds[variable.id] ||
                        (!!upstreamVariableIds[variable.id] &&
                            !!variableRelationshipMap[variable.id])) && // either not upstream or a direct source (for dropdown)
                    variable.id !== targetVariable.id &&
                    // !variableRelationshipMap[variable.id] && // needed to show in dropdown
                    (!variable.uses_time || targetVariable.uses_time),
            );

            // filter further based on type (to do: further restrictions based on unit, etc)
            switch (targetVariable.variable_type) {
                case ModelVariableType["Iterative"]:
                case ModelVariableType.Algebraic:
                case ModelVariableType.Comparison:
                case ModelVariableType["Time Shift"]:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type ===
                                ModelVariableDataType.Number,
                        ),
                    ];
                    break;
                case ModelVariableType.Aggregate:
                    baseVariableSet = [
                        ...baseVariableSet.filter((variable) =>
                            targetVariable.aggregate_operation ===
                            ModelVariableAggregateOperation["Count If"]
                                ? variable.data_type ===
                                  ModelVariableDataType.Boolean
                                : variable.data_type ===
                                  ModelVariableDataType.Number,
                        ),
                    ];
                    break;
                case ModelVariableType.Conditional:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type !==
                                ModelVariableDataType["Dictionary Entry"],
                        ),
                    ];
                    break;
                case ModelVariableType.Logical:
                    baseVariableSet = [
                        ...baseVariableSet.filter(
                            (variable) =>
                                variable.data_type ===
                                    ModelVariableDataType.Boolean ||
                                variable.variable_type ===
                                    ModelVariableType["Selection Data"],
                        ),
                    ];
                    break;
                default:
                    baseVariableSet = [];
            }

            return baseVariableSet;
        }
    }, [
        variableRelationshipMap,
        downstreamVariableIds,
        upstreamVariableIds,
        modelVariables,
        targetVariable,
    ]);

    const availableVariablesMap = useMemo<{
        [index: string]: ModelVariable;
    }>(() => {
        if (!!availableVariables?.length) {
            return mapById(availableVariables);
        }
    }, [availableVariables]);

    return {
        availableVariablesMap,
    };
};

export const getAreRelationshipsValid = (
    targetVariable: ModelVariable,
    variableRelationships: VariableRelationship[],
    modelVariables: { [index: string]: ModelVariable },
) => {
    if (!!targetVariable && !!targetVariable.variable_type) {
        let canSubmitByRelationships = true;

        // to do...
        if (!!targetVariable.id && !!variableRelationships?.length) {
            let everyRelationshipHasSource = variableRelationships.every(
                (relationship) => !!relationship.source_variable_id,
            );

            let relationshipTargetTimeHorizonsArePresentWhenNecessary =
                targetVariable.uses_time === false ||
                variableRelationships.every(
                    (relationship) =>
                        modelVariables[relationship.source_variable_id]
                            ?.uses_time ||
                        !!relationship.target_time_horizon_id,
                );

            let conditionalSourcesAreValid =
                targetVariable.variable_type === ModelVariableType.Conditional
                    ? variableRelationships.length % 2 === 0 &&
                      variableRelationships.every(
                          (relationship, i) =>
                              (i % 2 === 1 &&
                                  modelVariables[
                                      relationship.source_variable_id
                                  ]?.data_type ===
                                      ModelVariableDataType.Number) ||
                              (i % 2 === 0 &&
                                  (modelVariables[
                                      relationship.source_variable_id
                                  ]?.data_type ===
                                      ModelVariableDataType.Boolean ||
                                      modelVariables[
                                          relationship.source_variable_id
                                      ]?.variable_type ===
                                          ModelVariableType["Selection Data"])),
                      )
                    : true;

            let timeShiftSourcesAreValid =
                targetVariable.variable_type === ModelVariableType["Time Shift"]
                    ? variableRelationships.length === 2 &&
                      variableRelationships.some(
                          (relationship) =>
                              relationship.operation_type ===
                              VariableRelationshipOperation["Shift Index"],
                      ) &&
                      variableRelationships.some(
                          (relationship) =>
                              relationship.operation_type ===
                              VariableRelationshipOperation["Shift Input"],
                      )
                    : true;

            canSubmitByRelationships =
                everyRelationshipHasSource &&
                relationshipTargetTimeHorizonsArePresentWhenNecessary &&
                conditionalSourcesAreValid &&
                timeShiftSourcesAreValid;
        }

        return canSubmitByRelationships;
    } else {
        return false;
    }
};
