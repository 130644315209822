import React from "react";
import { BodyInnerWrapperWide, BodyContentContainer } from "../../components";

export const AdminPageSection = ({
    children,
    backgroundColor,
}: {
    children?: React.ReactNode;
    backgroundColor?: string;
}) => {
    return (
        <div className={`bg-[${backgroundColor}]`}>
            <BodyInnerWrapperWide>
                <BodyContentContainer>{children}</BodyContentContainer>
            </BodyInnerWrapperWide>
        </div>
    );
};
