export function flatten(objects: any[], property: string, idProperty = "id") {
    return objects.reduce((carry, object) => {
        if (property in object && Array.isArray(object[property])) {
            carry = [
                ...carry,
                ...flatten(object[property], property, idProperty),
            ];
        }

        const transformedObject =
            object[property] && Array.isArray(object[property])
                ? {
                      ...object,
                      ...{
                          [property]: object[property].map(
                              (childObject: typeof object) =>
                                  childObject[idProperty]
                          ),
                      },
                  }
                : object;

        return [...carry, transformedObject];
    }, []);
}
