import React from "react";
import { useModalQueryParams, useSelectedRoundId } from "@/hooks";
import { ModalContainer } from "@/modules/shared";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useForm } from "@inertiajs/react";
import { useRoundStore } from "@/hooks/store";

function MovePageModalContents() {
    const { queryParams, closeModal } = useModalQueryParams("MovePageModal");

    const { rounds } = useRoundStore();
    const { selectedRoundId } = useSelectedRoundId();
    const { data, setData } = useForm({
        round_id: selectedRoundId,
        page_id: queryParams?.pageId,
    });

    return (
        <div className="py-6 text-white" data-testid="move-page-modal">
            <div className={`px-6 flex flex-col space-y-4`}>
                <div className="flex flex-col justify-between">
                    <label>Select Round</label>
                    <select
                        value={data?.round_id}
                        onChange={(e) =>
                            setData({
                                round_id: e.target.value,
                                page_id: queryParams?.pageId,
                            })
                        }
                        className="w-full max-w-3xl block rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                    >
                        {rounds.map((round) => (
                            <option
                                className="bg-[#29303e]"
                                key={round.id}
                                value={round.id}
                            >
                                {round.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                    <div className="flex justify-center items-center">
                        <button
                            onClick={closeModal}
                            className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex justify-center items-center">
                        <button
                            onClick={() => {
                                console.log("HELLO", data);
                                SapienInertia.post(
                                    "admin.design.move-page.store",
                                    data,
                                    {
                                        pageId: queryParams?.pageId,
                                    }
                                );
                            }}
                            className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function MovePageModal() {
    const { isAtModalUrl, closeModal } = useModalQueryParams("MovePageModal");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={closeModal}
            size="s"
            backgroundColor="#111928"
            classNames="transform-y-1/2"
        >
            {isAtModalUrl && <MovePageModalContents />}
        </ModalContainer>
    );
}
