import React, { isValidElement, useMemo } from "react";
import { ButtonCircle } from "../../components";
import { Icon } from "react-icons-kit";
import { PaletteType } from "../../styles";

export const IconButton = (props: {
    icon: any;
    handler: () => void;
    isDisabled?: boolean;
    palette?: PaletteType;
    noMargin?: boolean;
    size?: number;
}) => {
    const { icon, handler, isDisabled, palette, noMargin, size } = props;

    const iconIsElement = useMemo(() => {
        return isValidElement(icon);
    }, [icon]);

    return (
        <ButtonCircle
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handler();
            }}
            style={{ margin: !noMargin && "0 4px" }}
            disabled={isDisabled}
            palette={palette || "grayscale"}
        >
            {iconIsElement ? (
                icon
            ) : (
                <span>
                    <Icon
                        icon={icon}
                        size={size || 16}
                        style={{ display: "flex" }}
                    />
                </span>
            )}
        </ButtonCircle>
    );
};
