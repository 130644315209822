import { usePage as inertiaUsePage } from "@inertiajs/react";
import {
    SapienDiscoveryPage,
    SapienModelBuilderPage,
    SapienModelDesignPage,
    SapienPage,
} from "../types";
import { Page, Errors } from "@inertiajs/core";
import toast from "react-hot-toast";

export function onSuccess(page: Page) {
    const sapienPage = page as SapienPage;
    const { props } = sapienPage;
    if (props.toast) {
        if (props.toast.type === "success") {
            toast.success(props.toast.message);
        } else {
            toast(props.toast.message);
        }
    }
}

export function onError(errors: Errors) {
    console.log("ERRORS", errors);
}

export function usePage<T extends SapienPage>() {
    const page = inertiaUsePage() as T;

    //handle setting intial state here?
    return { ...page, props: page.props };
}

export function useModelBuilderPage<T extends SapienModelBuilderPage>() {
    const page = inertiaUsePage() as T;

    return { ...page, props: page.props };
}

export function useDiscoveryPage<T extends SapienDiscoveryPage>() {
    const page = inertiaUsePage() as T;

    return { ...page, props: page.props };
}

export function useModelDesignPage<T extends SapienModelDesignPage>() {
    const page = inertiaUsePage() as T;

    return { ...page, props: page.props };
}
