import React, { useEffect, useMemo, useState } from "react";
import { ModelVariable, VariableValue } from "@/models";
import { SandboxVariableRows } from "./SandboxVariableRows";
import { isEqual } from "lodash";

export type SandboxModelComparisonProps = {
    targetVariables: ModelVariable[];
    pythonValues: {
        [index: string]: VariableValue[];
    };
    targetValues: {
        [index: string]: VariableValue[];
    };
    time?: number;
};

const getDifferencesMap = (
    variables: ModelVariable[],
    targetValues: {
        [index: string]: VariableValue[];
    },
    pythonValues: {
        [index: string]: VariableValue[];
    },
) => {
    return variables?.reduce(
        (map, variable) => ({
            ...map,
            [variable.id]: !isEqual(
                targetValues[variable.id]?.map((value) => ({
                    numerical_value: value.numerical_value,
                    boolean_value: value.boolean_value,
                    model_variable_id: value.model_variable_id,
                    time_horizon_id: value.time_horizon_id,
                })),
                pythonValues[variable.id]?.map((value) => ({
                    numerical_value: value.numerical_value,
                    boolean_value: value.boolean_value,
                    model_variable_id: value.model_variable_id,
                    time_horizon_id: value.time_horizon_id,
                })),
            ),
        }),
        {},
    );
};

export default function SandboxVariablesDisplay({
    targetVariables,
    pythonValues,
    targetValues,
}: SandboxModelComparisonProps) {
    const [selectedVariables, setSelectedVariables] = useState<{
        [index: string]: ModelVariable;
    }>({});

    useEffect(() => {
        if (!targetVariables) return;
        const newSelectedVariables = targetVariables.reduce(
            (acc, curr) => {
                return { ...acc, [curr.id]: curr };
            },
            {} as { [index: string]: ModelVariable },
        );
        setSelectedVariables(newSelectedVariables);
    }, [targetVariables]);

    const numberOfTimeHorizons = useMemo(() => {
        if (!pythonValues) return 0;
        const valueRowThatUsesTime = Object.values(pythonValues)?.find(
            (valueArray) => valueArray?.length > 1,
        );
        return valueRowThatUsesTime?.length || 0;
    }, [pythonValues]);

    const differencesMap = useMemo(() => {
        if (!targetVariables || !pythonValues || !targetValues) return {};
        return getDifferencesMap(targetVariables, targetValues, pythonValues);
    }, [targetVariables, pythonValues, targetValues]);

    return (
        <React.Fragment>
            {/* {!!targetVariables?.length && (
                <div>
                    <label
                        htmlFor="model_variable"
                        className="sr-only block text-sm font-medium leading-6 text-gray-900"
                    >
                        Select a variable
                    </label>
                    <select
                        id="model_variable"
                        name="model_variable"
                        onChange={(e) => {
                            const variable = targetVariables.find(
                                (variable) => variable.id === e.target.value,
                            );
                            if (!variable) return;
                            setSelectedVariables((variables) => {
                                const newVariables = { ...variables };

                                if (
                                    Object.values(newVariables).length ===
                                    targetVariables.length
                                ) {
                                    return { [variable.id]: variable };
                                }

                                return {
                                    ...newVariables,
                                    [variable.id]: variable,
                                };
                            });
                        }}
                        className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1
                            ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                            sm:leading-6"
                    >
                        <option>Select a variable to filter</option>
                        {targetVariables.map((variable) => (
                            <option key={variable.id} value={variable.id}>
                                {variable.label}
                            </option>
                        ))}
                    </select>
                </div>
            )} */}
            {/* {!!Object.values(selectedVariables)?.length &&
                Object.values(selectedVariables)?.length !==
                    targetVariables.length && (
                    <div className="mt-2 flex flex-wrap gap-2 py-2">
                        {Object.values(selectedVariables).map((variable) => (
                            <span
                                key={variable.id}
                                className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs
                                    font-medium text-red-700"
                            >
                                {variable.label}
                                <button
                                    key={variable.id}
                                    type="button"
                                    className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                            Object.values(selectedVariables)
                                                .length === 1
                                        ) {
                                            setSelectedVariables(
                                                targetVariables.reduce(
                                                    (acc, curr) => ({
                                                        ...acc,
                                                        [curr.id]: curr,
                                                    }),
                                                    {},
                                                ),
                                            );
                                            return;
                                        }

                                        setSelectedVariables((variables) => {
                                            const newVariables = {
                                                ...variables,
                                            };
                                            delete newVariables[variable.id];
                                            return newVariables;
                                        });
                                    }}
                                >
                                    <span className="sr-only">
                                        Remove {variable.label}
                                    </span>
                                    <svg
                                        viewBox="0 0 14 14"
                                        className="h-3.5 w-3.5 stroke-red-700/50 group-hover:stroke-red-700/75"
                                    >
                                        <path d="M4 4l6 6m0-6l-6 6" />
                                    </svg>
                                    <span className="absolute -inset-1" />
                                </button>
                            </span>
                        ))}
                        <span
                            className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs
                                font-medium text-red-700"
                        >
                            Reset
                            <button
                                type="button"
                                className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedVariables(
                                        targetVariables.reduce(
                                            (acc, curr) => ({
                                                ...acc,
                                                [curr.id]: curr,
                                            }),
                                            {},
                                        ),
                                    );
                                }}
                            >
                                <span className="sr-only">Reset filters</span>
                                <svg
                                    viewBox="0 0 14 14"
                                    className="h-3.5 w-3.5 stroke-red-700/50 group-hover:stroke-red-700/75"
                                >
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                </svg>
                                <span className="absolute -inset-1" />
                            </button>
                        </span>
                    </div>
                )} */}
            <div className="w-full overflow-x-auto">
                {!!differencesMap &&
                    Object.values(differencesMap).filter((value) => !!value)
                        .length > 0 && (
                        <div className="my-2 text-xs text-red-700">
                            {`Variables with differences: ${
                                Object.values(differencesMap).filter(
                                    (value) => !!value,
                                ).length
                            }`}
                        </div>
                    )}
                <table className="table min-w-full table-fixed rounded-md border border-gray-300">
                    <tbody>
                        {Object.values(selectedVariables)
                            .filter((variable) => !variable.uses_time)
                            .map((row, i) => (
                                <SandboxVariableRows
                                    key={row.id}
                                    i={i}
                                    variable={row}
                                    targetValueRow={targetValues[row.id]}
                                    pythonValueRow={pythonValues[row.id]}
                                    numberOfTimeHorizons={numberOfTimeHorizons}
                                    hasDifference={differencesMap[row.id]}
                                />
                            ))}
                        {!!numberOfTimeHorizons &&
                            Object.values(selectedVariables).filter(
                                (variable) => variable.uses_time,
                            )?.length > 0 && (
                                <tr>
                                    <td
                                        colSpan={2}
                                        className="border-r border-r-gray-300 p-2 text-sm"
                                    >
                                        Time Horizon
                                    </td>
                                    {[
                                        ...Array(numberOfTimeHorizons).keys(),
                                    ].map((_, i) => (
                                        <td key={i} className="w-4 p-2">
                                            {i}
                                        </td>
                                    ))}
                                </tr>
                            )}
                        {Object.values(selectedVariables)
                            .filter((variable) => variable.uses_time)
                            .map((row, i) => (
                                <SandboxVariableRows
                                    key={row.id}
                                    i={i}
                                    variable={row}
                                    targetValueRow={targetValues[row.id]}
                                    pythonValueRow={pythonValues[row.id]}
                                    numberOfTimeHorizons={numberOfTimeHorizons}
                                    hasDifference={differencesMap[row.id]}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
