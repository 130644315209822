import React, { useMemo } from "react";
import { Head } from "@inertiajs/react";
import { useSelectedSimulation } from "@/hooks";
import { useAuthStore } from "@/hooks/store";
import SimVariantModal from "@/Pages/Admin/sim-variants/SimVariantModal";
import { BodyOuterWrapper } from "../../components";
import { SimBuilderTopNav } from "./SimBuilderTopNav";

export const AdminContainer = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    const { selectedSimulation } = useSelectedSimulation();
    const { tenant } = useAuthStore();

    const title = useMemo(() => {
        if (selectedSimulation) return selectedSimulation.title;
        if (tenant) return tenant.name;
        return "Admin"
    }, [selectedSimulation, tenant]);

    return (
        <>
            <Head title={title} />
            <SimBuilderTopNav />
            <BodyOuterWrapper>{children}</BodyOuterWrapper>
            <SimVariantModal />
        </>
    );
};
