import React, { useMemo } from "react";
import { Prompt, PromptType, SelectionShape, Team } from "@/models";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Dictionary, groupBy } from "lodash";

const numericTypes = [
    PromptType["Numerical Slider"],
    PromptType["Numerical Input"],
];

const multipleSelectionTypes = [
    PromptType["Multiple Select"],
    PromptType["Drag and Drop"],
    PromptType["Rank Order"],
];

function getStandardDeviation(array: number[]) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
        array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
    );
}

const SelectionRow = ({
    teamName,
    participantName,
    prompt,
    selections,
    isMultipleSelectionType,
    isNumericType,
}: {
    teamName: string;
    participantName?: string;
    prompt: Prompt;
    selections: SelectionShape[];
    isMultipleSelectionType: boolean;
    isNumericType: boolean;
}) => {
    return (
        <tr className="odd:bg-white/10 even:bg-white/5">
            <td className="px-2 py-1">{`${teamName}`}</td>
            {!!participantName && (
                <td className="px-2 py-1">{`${participantName}`}</td>
            )}
            {isMultipleSelectionType ? (
                prompt.options.map((option) => (
                    <th className="px-2 py-1" key={option.id}>
                        {prompt.prompt_type === PromptType["Rank Order"] ? (
                            `${
                                Number(
                                    selections.find(
                                        (selection) =>
                                            selection.option_id == option.id,
                                    )?.numerical_value || 0,
                                ) + 1
                            }`
                        ) : selections.find(
                              (selection) => selection.option_id == option.id,
                          )?.is_selected ? (
                            <span className="inline-flex items-center">
                                <CheckIcon className="h-3 w-3 text-gray-700" />
                                {"true"}
                            </span>
                        ) : (
                            <span className="inline-flex items-center text-gray-300">
                                <XMarkIcon className="h-3 w-3" />
                                {"false"}
                            </span>
                        )}
                    </th>
                ))
            ) : (
                <th className="px-2 py-1">
                    {isNumericType
                        ? selections[0]?.numerical_value
                        : selections[0]?.string_value}
                </th>
            )}
        </tr>
    );
};

const NumericTypeStatsTable = ({
    numericTypeStats,
}: {
    numericTypeStats: {
        min: number;
        max: number;
        mean: string;
        std_dev: string;
    };
}) => {
    return (
        <table className="w-full table-auto rounded-md bg-indigo-100/80 text-center text-xs">
            <thead>
                <tr className="border-b border-indigo-200/50">
                    <th className="px-2 py-1 uppercase">min</th>
                    <th className="px-2 py-1 uppercase">max</th>
                    <th className="px-2 py-1 uppercase">mean</th>
                    <th className="px-2 py-1 uppercase">std dev</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="px-2 py-1">{numericTypeStats.min}</td>
                    <td className="px-2 py-1">{numericTypeStats.max}</td>
                    <td className="px-2 py-1">{numericTypeStats.mean}</td>
                    <td className="px-2 py-1">{numericTypeStats.std_dev}</td>
                </tr>
            </tbody>
        </table>
    );
};

const BasicOptionStatsTable = ({
    prompt,
    selectionsByOptionId,
    entityCount,
}: {
    prompt: Prompt;
    selectionsByOptionId: Dictionary<SelectionShape[]>;
    entityCount: number;
}) => {
    return (
        <table className="w-full table-auto rounded-md bg-indigo-100/80 text-right text-xs">
            <thead>
                <tr className="border-b border-indigo-200/50">
                    <th className="px-2 py-1 text-left uppercase">option</th>
                    <th className="px-2 py-1 uppercase">count</th>
                    <th className="px-2 py-1 uppercase">percent</th>
                </tr>
            </thead>
            <tbody>
                {prompt.options.map((option) => (
                    <tr
                        key={option.id}
                        className="border-b border-indigo-200/50 last:border-b-0"
                    >
                        <td className="px-2 py-1 text-left">
                            {option.content}
                        </td>
                        <td className="px-2 py-1">
                            {!!selectionsByOptionId[option.id]
                                ? selectionsByOptionId[option.id].length
                                : 0}
                        </td>
                        <td className="px-2 py-1">
                            {!!selectionsByOptionId[option.id]
                                ? (
                                      100 *
                                      (selectionsByOptionId[option.id].length /
                                          entityCount)
                                  ).toFixed(1) + "%"
                                : "0.0%"}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const RankOrderStatsTable = ({
    prompt,
    selectionsByOptionId,
    entityCount,
}: {
    prompt: Prompt;
    selectionsByOptionId: Dictionary<SelectionShape[]>;
    entityCount: number;
}) => {
    return (
        <table className="w-full table-auto rounded-md bg-indigo-100/80 text-right text-xs">
            <thead>
                <tr className="border-b border-indigo-200/50">
                    <th className="px-2 py-1 text-left uppercase">option</th>
                    <th className="px-2 py-1 uppercase">mean position</th>
                    {prompt.options.map((o, i) => (
                        <th className="px-2 py-1" key={o.id}>
                            COUNT rank = {i + 1}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {prompt.options.map((option) => (
                    <tr
                        key={option.id}
                        className="border-b border-indigo-200/50 last:border-b-0"
                    >
                        <td className="px-2 py-1 text-left">
                            {option.content}
                        </td>
                        <td className="px-2 py-1">
                            {!!selectionsByOptionId[option.id]
                                ? (
                                      selectionsByOptionId[option.id].reduce(
                                          (acc, selection) =>
                                              acc +
                                              Number(
                                                  selection.numerical_value,
                                              ) +
                                              1,
                                          0,
                                      ) / selectionsByOptionId[option.id].length
                                  ).toFixed(1)
                                : ""}
                        </td>
                        {prompt.options.map((o, i) => (
                            <td className="px-2 py-1" key={o.id}>
                                {!!selectionsByOptionId[option.id]
                                    ? selectionsByOptionId[option.id].filter(
                                          (selection) =>
                                              Number(
                                                  selection.numerical_value,
                                              ) == i,
                                      ).length
                                    : 0}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

// TO DO: fix underrepresentation of multi select question types

export const DiscoverySelectionDisplay = ({
    prompt,
    teams,
    selections,
    groupedSelections,
}: {
    prompt: Prompt;
    teams: Team[];
    selections: SelectionShape[];
    groupedSelections: Dictionary<SelectionShape[]>;
}) => {
    const isMultipleSelectionType = useMemo(() => {
        return multipleSelectionTypes.includes(prompt.prompt_type);
    }, [prompt]);

    const isNumericType = useMemo(() => {
        return numericTypes.includes(prompt.prompt_type);
    }, [prompt]);

    const showBasicOptionStats = useMemo(() => {
        return [
            PromptType["Multiple Choice"],
            PromptType["Dropdown List"],
            PromptType["Multiple Select"],
            PromptType["Drag and Drop"],
        ].includes(prompt.prompt_type);
    }, [prompt]);

    const selectionsByOptionId = useMemo(() => {
        if (!!selections?.length) {
            return groupBy(selections, "option_id");
        } else {
            return;
        }
    }, [selections]);

    const numericTypeStats = useMemo(() => {
        if (isNumericType && !!selections?.length) {
            const valueArray: number[] = selections.map((selection) =>
                Number(selection.numerical_value),
            );

            if (!valueArray.length) return;

            const mean =
                valueArray.reduce((acc, c) => acc + c, 0) / valueArray.length;

            return {
                min: Math.min(...valueArray),
                max: Math.max(...valueArray),
                mean: mean.toFixed(1),
                std_dev: getStandardDeviation(valueArray).toFixed(1),
            };
        } else {
            return;
        }
    }, [selections, isNumericType]);

    return (
        <div key={prompt.id}>
            <div className="relative overflow-x-auto">
                {isNumericType &&
                    !!numericTypeStats &&
                    Object.keys(numericTypeStats).length == 4 && (
                        <div className="pb-4">
                            <NumericTypeStatsTable
                                numericTypeStats={numericTypeStats}
                            />
                        </div>
                    )}
                {showBasicOptionStats &&
                    !!selectionsByOptionId &&
                    !!groupedSelections && (
                        <div className="pb-4">
                            <BasicOptionStatsTable
                                selectionsByOptionId={selectionsByOptionId}
                                prompt={prompt}
                                entityCount={
                                    Object.keys(groupedSelections).length
                                }
                            />
                        </div>
                    )}
                {prompt.prompt_type === PromptType["Rank Order"] &&
                    !!selectionsByOptionId &&
                    !!groupedSelections && (
                        <div className="pb-4">
                            <RankOrderStatsTable
                                selectionsByOptionId={selectionsByOptionId}
                                prompt={prompt}
                                entityCount={
                                    Object.keys(groupedSelections).length
                                }
                            />
                        </div>
                    )}
            </div>
            <div className="relative overflow-x-auto">
                <table
                    className="w-full table-auto rounded-md bg-indigo-900 text-left text-sm text-white"
                    style={{
                        fontSize: "0.8rem",
                    }}
                >
                    <thead className="text-xs">
                        <tr>
                            {prompt.scope === "Participant" && (
                                <th className="px-2 py-1 uppercase">
                                    {"Team"}
                                </th>
                            )}
                            <th className="px-2 py-1 uppercase">
                                {prompt.scope}
                            </th>
                            {isMultipleSelectionType ? (
                                prompt.options.map((option) => (
                                    <th className="px-2 py-1" key={option.id}>
                                        {option.content}
                                    </th>
                                ))
                            ) : (
                                <th className="px-2 py-1">{`Response`}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {!!groupedSelections &&
                            prompt.scope === "Team" &&
                            teams
                                .filter((team) => !!groupedSelections[team.id])
                                .map((team) => (
                                    <SelectionRow
                                        key={team.id}
                                        teamName={team.name}
                                        prompt={prompt}
                                        selections={groupedSelections[team.id]}
                                        isMultipleSelectionType={
                                            isMultipleSelectionType
                                        }
                                        isNumericType={isNumericType}
                                    />
                                ))}
                        {!!groupedSelections &&
                            prompt.scope === "Participant" &&
                            teams.map((team) => (
                                <React.Fragment key={team.id}>
                                    {!!team.participants?.length &&
                                        team.participants
                                            .filter(
                                                (participant) =>
                                                    !!groupedSelections[
                                                        participant.id
                                                    ],
                                            )
                                            .map((participant) => (
                                                <SelectionRow
                                                    key={participant.id}
                                                    teamName={team.name}
                                                    participantName={
                                                        participant.name
                                                    }
                                                    prompt={prompt}
                                                    selections={
                                                        groupedSelections[
                                                            participant.id
                                                        ]
                                                    }
                                                    isMultipleSelectionType={
                                                        isMultipleSelectionType
                                                    }
                                                    isNumericType={
                                                        isNumericType
                                                    }
                                                />
                                            ))}
                                </React.Fragment>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
