import React, { useCallback, useState, useMemo } from "react";
import { AsideContents } from "../../Aside";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { debounce, useModalQueryParams } from "@/hooks";
import { useEditedContentBlock } from "../../useEditedContentBlock";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import {
    useGetContentBlockWithChildren,
    useContentBlockState,
} from "../../../useContentBlockState";
import { PlusIcon, Trash } from "lucide-react";
import { createAccordionItem } from "../../../content-block-management/content-block-functions/createAccordion";
import { createTab } from "../../../content-block-management/content-block-functions/createTabs";
import DeleteContentBlockOverlay from "../../DeleteContentBlockOverlay";

export default function AccordionAndTabsSidebar() {
    const { openModal } = useModalQueryParams();
    const { editedContentBlock } = useEditedContentBlock();

    const editedContentBlockWithChildren = useGetContentBlockWithChildren(
        editedContentBlock?.id,
    );

    const tabsOrAccordion = useMemo(() => {
        return editedContentBlockWithChildren?.contentBlocks?.[0];
    }, [editedContentBlock]);

    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 1000), [tabsOrAccordion]);
    const update = useCallback(
        debounce((contentBlock: ContentBlockShape) => {
            setContentBlock(contentBlock);
            save(contentBlock);
        }, 100),
        [tabsOrAccordion],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        console.log("newBlock", newBlock);
        update(newBlock);
    }, []);

    const [pendingDeleteId, setPendingDeleteId] = useState("");

    if (!tabsOrAccordion) return null;

    return (
        <AsideContents title="Change Image">
            <div className="flex flex-col gap-4 pb-24">
                {tabsOrAccordion?.contentBlocks.map(
                    (tabOrAccordionItem, index) => (
                        <div
                            className="relative flex flex-col gap-4 p-4 odd:bg-gray-100"
                            key={tabOrAccordionItem.id}
                        >
                            {pendingDeleteId === tabOrAccordionItem.id && (
                                <DeleteContentBlockOverlay
                                    contentBlock={tabOrAccordionItem}
                                    cancelDelete={() => setPendingDeleteId("")}
                                />
                            )}
                            <div>
                                <h4 className="flex justify-between text-xs font-bold">
                                    Item {index + 1}
                                    <button
                                        onClick={() =>
                                            setPendingDeleteId(
                                                tabOrAccordionItem.id,
                                            )
                                        }
                                    >
                                        <span className="sr-only">
                                            Delete Content Block
                                        </span>
                                        <Trash className="h-6 w-6" />
                                    </button>
                                </h4>
                            </div>
                            <div
                                key={tabOrAccordionItem.id}
                                className="relative gap-2"
                            >
                                <label
                                    htmlFor={`label-${tabOrAccordionItem.id}`}
                                    className="inline-block px-1 text-xs font-medium text-gray-900"
                                >
                                    Label
                                </label>
                                <input
                                    defaultValue={tabOrAccordionItem.content}
                                    onChange={(e) => {
                                        handleChange({
                                            ...tabsOrAccordion,
                                            contentBlocks:
                                                tabsOrAccordion.contentBlocks.map(
                                                    (item) =>
                                                        item.id ===
                                                        tabOrAccordionItem.id
                                                            ? {
                                                                  ...item,
                                                                  content:
                                                                      e.target
                                                                          .value,
                                                              }
                                                            : item,
                                                ),
                                        });
                                    }}
                                    type="text"
                                    name="label"
                                    id={`label-${tabOrAccordionItem.id}`}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                                        ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                        focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Label"
                                />
                            </div>
                            {tabOrAccordionItem?.contentBlocks
                                ?.filter(
                                    (block) =>
                                        block.content_block_type ===
                                        ContentBlockType.Image,
                                )
                                .map((imageBlock) => (
                                    <div
                                        key={imageBlock.id}
                                        className="flex justify-between space-y-2"
                                    >
                                        <img
                                            className="w-64 object-cover"
                                            src={imageBlock.image_url}
                                            alt={imageBlock.title}
                                        />
                                        <div className="flex items-center justify-center gap-4">
                                            <button
                                                className="text-sm"
                                                onClick={() =>
                                                    openModal({
                                                        modalComponent:
                                                            "ImageModal",
                                                        imageUrl:
                                                            imageBlock.image_url,
                                                        imageContentBlockId:
                                                            imageBlock.id,
                                                    })
                                                }
                                            >
                                                Change Image
                                            </button>
                                            <button
                                                className="text-sm"
                                                onClick={() =>
                                                    handleChange({
                                                        ...tabsOrAccordion,
                                                        contentBlocks:
                                                            tabsOrAccordion.contentBlocks.map(
                                                                (item) =>
                                                                    item.id ===
                                                                    tabOrAccordionItem.id
                                                                        ? {
                                                                              ...item,
                                                                              contentBlocks:
                                                                                  item.contentBlocks.map(
                                                                                      (
                                                                                          child,
                                                                                      ) =>
                                                                                          child.id !==
                                                                                          imageBlock.id
                                                                                              ? child
                                                                                              : {
                                                                                                    ...child,
                                                                                                    theme: {
                                                                                                        ...child.theme,
                                                                                                        display:
                                                                                                            child
                                                                                                                .theme
                                                                                                                .display !==
                                                                                                            "none"
                                                                                                                ? "none"
                                                                                                                : "block",
                                                                                                    },
                                                                                                },
                                                                                  ),
                                                                          }
                                                                        : item,
                                                            ),
                                                    })
                                                }
                                            >
                                                {imageBlock?.theme.display ===
                                                "none"
                                                    ? "Show"
                                                    : "Hide"}{" "}
                                                Image
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ),
                )}
                <div className="fixed bottom-0 left-0 flex w-full max-w-[41.5rem] border-t bg-white p-4">
                    <button
                        className="flex w-full items-center justify-center gap-2 rounded-md border
                            border-sapien-blue bg-white px-4 py-1 text-sapien-blue transition-colors
                            hover:bg-sapien-blue hover:text-white"
                        onClick={() => {
                            const newItem =
                                tabsOrAccordion.content_block_type ===
                                ContentBlockType.Accordion
                                    ? createAccordionItem(
                                          tabsOrAccordion.round_id,
                                          tabsOrAccordion.contentBlocks?.length,
                                      )
                                    : createTab(
                                          tabsOrAccordion.round_id,
                                          tabsOrAccordion.contentBlocks?.length,
                                      );

                            const theme =
                                tabsOrAccordion.contentBlocks?.[0]?.theme;
                            if (theme) {
                                newItem.theme = theme;
                            }
                            mutate({
                                ...newItem,
                                parent_content_block_id: tabsOrAccordion.id,
                            });
                        }}
                    >
                        <span>Add Item</span>
                        <PlusIcon size={16} />
                    </button>
                </div>
            </div>
        </AsideContents>
    );
}
