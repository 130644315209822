import { useState, useCallback, useMemo, useEffect } from "react";
import { groupBy, uniq, map } from "lodash";
import {
    SelectionShape,
    Prompt,
    Round,
    Team,
    Participant,
    PromptType,
    PromptShape,
} from "@/models";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";

type PromptScope = "Participant" | "Team";

export type DiscoveryStatisticsObject = {
    selectionMap: { [promptId: string]: SelectionShape[] };
    promptMap: { [promptId: string]: Prompt };
    teamCount: number;
    participantCount: number;
    // selectedPromptIds: {};
    selectedRoundIds: {};
    togglePromptId: (promptId?: string) => void;
    toggleRoundId: (roundId?: string) => void;
    teamMap: { [teamId: string]: Team };
    participantMap: { [participantId: string]: Participant };
    promptTypeAndScopeMap: {
        [promptType: string]: {
            [promptScope: string]: Prompt[];
        };
    };
    promptScopeAndTypeMap: {
        [promptScope: string]: {
            [promptType: string]: Prompt[];
        };
    };
    selectionsGrouping: "question" | "people" | "type";
    setSelectionsGrouping: (
        selectionGrouping: "question" | "people" | "type",
    ) => void;
    teamsHaveMultipleParticipants: boolean;
    promptScopes: PromptScope[];
    promptTypes: (keyof typeof PromptType)[];
    selectedPromptScopes: PromptScope[];
    togglePromptScope: (promptScope: PromptScope | string) => void;
    selectedPromptTypes: (keyof typeof PromptType)[];
    togglePromptType: (promptType: keyof typeof PromptType | string) => void;
    disabledPrompts: {
        [index: string]: Prompt;
    };
};

export function useDiscoveryStatistics(
    rounds: Round[],
    teams: (Team | TeamWithProgressData)[],
    selections: SelectionShape[],
): DiscoveryStatisticsObject {
    // const [selectedRoundIds, setSelectedRoundIds] = useState(
    //     rounds?.reduce((map, round) => {
    //         return round.prompts_count > 0 ? { ...map, [round.id]: true } : map;
    //     }, {}) || {},
    // );

    const [selectedPromptIds, setSelectedPromptIds] = useState(
        rounds
            ?.filter((round) => round.prompts_count > 0)
            .reduce((map, round) => {
                return {
                    ...map,
                    ...round.prompts.reduce(
                        (pMap, prompt) => ({
                            ...pMap,
                            [prompt.id]: true,
                        }),
                        {},
                    ),
                };
            }, {}) || {},
    );

    const promptScopes = useMemo<PromptScope[]>(() => {
        const promptArray =
            rounds?.reduce((map, round) => [...map, ...round.prompts], []) ||
            [];
        return uniq(map(promptArray, "scope"));
    }, [rounds]);

    const [selectedPromptScopes, setSelectedPromptScopes] =
        useState(promptScopes);

    const togglePromptScope = useCallback(
        (promptScope?: PromptScope | string) => {
            if (!promptScope) {
                setSelectedPromptScopes([]);
            } else if (promptScope == "ALL") {
                setSelectedPromptScopes(promptScopes);
            } else {
                const newSelectedPromptScopes = promptScopes.filter((scope) =>
                    promptScope == scope
                        ? !selectedPromptScopes.includes(scope)
                        : selectedPromptScopes.includes(scope),
                );
                setSelectedPromptScopes(newSelectedPromptScopes);
            }
        },
        [selectedPromptScopes, promptScopes],
    );

    const promptTypes = useMemo<(keyof typeof PromptType)[]>(() => {
        const promptArray =
            rounds?.reduce((map, round) => [...map, ...round.prompts], []) ||
            [];
        const promptTypeValues = uniq(map(promptArray, "prompt_type"));
        return Object.keys(PromptType).filter((key) =>
            promptTypeValues.includes(PromptType[key]),
        ) as (keyof typeof PromptType)[];
    }, [rounds]);

    const [selectedPromptTypes, setSelectedPromptTypes] = useState(promptTypes);

    const togglePromptType = useCallback(
        (promptType?: keyof typeof PromptType | string) => {
            if (!promptType) {
                setSelectedPromptTypes([]);
            } else if (promptType == "ALL") {
                setSelectedPromptTypes(promptTypes);
            } else {
                const newSelectedPromptTypes = promptTypes.filter((type) =>
                    promptType == type
                        ? !selectedPromptTypes.includes(type)
                        : selectedPromptTypes.includes(type),
                );
                setSelectedPromptTypes(newSelectedPromptTypes);
            }
        },
        [selectedPromptTypes, promptTypes],
    );

    const selectedPromptTypeValues = useMemo<{
        [index: string]: keyof typeof PromptType;
    }>(() => {
        return selectedPromptTypes.reduce(
            (map, key) => ({ ...map, [PromptType[key]]: key }),
            {},
        );
    }, [selectedPromptTypes]);

    const disabledPrompts = useMemo<{
        [index: string]: Prompt;
    }>(() => {
        return (
            rounds
                ?.filter((round) => round.prompts_count > 0)
                .reduce((map, round) => {
                    return {
                        ...map,
                        ...round.prompts
                            .filter(
                                (prompt) =>
                                    !selectedPromptScopes.includes(
                                        prompt.scope as PromptScope,
                                    ) ||
                                    !selectedPromptTypeValues[
                                        prompt.prompt_type
                                    ],
                            )
                            .reduce(
                                (pMap, prompt) => ({
                                    ...pMap,
                                    [prompt.id]: prompt,
                                }),
                                {},
                            ),
                    };
                }, {}) || {}
        );
    }, [rounds, selectedPromptScopes, selectedPromptTypeValues]);

    useEffect(() => {
        // new approach that just keeps selected in sync with disabled
        // according to the question scope and type filters
        setSelectedPromptIds(
            rounds
                ?.filter((round) => round.prompts_count > 0)
                .reduce((map, round) => {
                    return {
                        ...map,
                        ...round.prompts.reduce((pMap, prompt) => {
                            return !!disabledPrompts[prompt.id]
                                ? pMap
                                : { ...pMap, [prompt.id]: true };
                        }, {}),
                    };
                }, {}) || {},
        );

        // // when disabledPrompts change...
        // if (Object.keys(disabledPrompts).length > 0) {
        //     // some prompts are still disabled, so remove them from selectedPromptIds
        //     setSelectedPromptIds(
        //         Object.keys(selectedPromptIds).reduce((map, key) => {
        //             return !!disabledPrompts[key]
        //                 ? map
        //                 : { ...map, [key]: true };
        //         }, {}),
        //     );
        // } else if (Object.keys(selectedPromptIds).length == 0) {
        //     // no prompts are disabled, and no prompts are selected,
        //     // so reset selectedPromptIds to include all prompts
        //     setSelectedPromptIds(
        //         rounds
        //             ?.filter((round) => round.prompts_count > 0)
        //             .reduce((map, round) => {
        //                 return {
        //                     ...map,
        //                     ...round.prompts.reduce(
        //                         (pMap, prompt) => ({
        //                             ...pMap,
        //                             [prompt.id]: true,
        //                         }),
        //                         {},
        //                     ),
        //                 };
        //             }, {}) || {},
        //     );
        // }
    }, [disabledPrompts]);

    const toggleRoundId = useCallback(
        (roundId?: string) => {
            if (!roundId) {
                // deselect all prompts
                setSelectedPromptIds({});
            } else if (roundId == "ALL") {
                // select all prompts
                setSelectedPromptIds(
                    rounds
                        ?.filter((round) => round.prompts_count > 0)
                        .reduce((map, round) => {
                            return {
                                ...map,
                                ...round.prompts
                                    .filter(
                                        (prompt) => !disabledPrompts[prompt.id],
                                    )
                                    .reduce(
                                        (pMap, prompt) => ({
                                            ...pMap,
                                            [prompt.id]: true,
                                        }),
                                        {},
                                    ),
                            };
                        }, {}) || {},
                );
            } else {
                const round = rounds?.find((round) => round.id === roundId);
                if (
                    round.prompts.every(
                        (prompt) =>
                            selectedPromptIds[prompt.id] ||
                            disabledPrompts[prompt.id],
                    )
                ) {
                    // if round is selected, deselect all prompts in it
                    setSelectedPromptIds(
                        Object.keys(selectedPromptIds).reduce((map, key) => {
                            return !!round?.prompts.find(
                                (prompt) => prompt.id === key,
                            )
                                ? map
                                : { ...map, [key]: true };
                        }, {}),
                    );
                } else {
                    // otherwise, select any prompts in it that aren't selected
                    setSelectedPromptIds({
                        ...selectedPromptIds,
                        ...round?.prompts
                            .filter((prompt) => !disabledPrompts[prompt.id])
                            .reduce(
                                (pMap, prompt) => ({
                                    ...pMap,
                                    [prompt.id]: true,
                                }),
                                {},
                            ),
                    });
                }
            }
        },
        [rounds, selectedPromptIds, disabledPrompts],
    );

    const togglePromptId = useCallback(
        (promptId: string) => {
            if (!!selectedPromptIds[promptId]) {
                // if prompt is selected, deselect it
                const newSelectedPromptIds = Object.keys(
                    selectedPromptIds,
                ).reduce((map, key) => {
                    return key === promptId ? map : { ...map, [key]: true };
                }, {});
                setSelectedPromptIds(newSelectedPromptIds);
            } else if (!disabledPrompts[promptId]) {
                // otherwise, select the prompt
                const newSelectedPromptIds = {
                    ...selectedPromptIds,
                    [promptId]: true,
                };
                setSelectedPromptIds(newSelectedPromptIds);
            }
        },
        [rounds, selectedPromptIds, disabledPrompts],
    );

    const selectedRoundIds = useMemo(() => {
        if (!selectedPromptIds || Object.keys(selectedPromptIds).length == 0) {
            return {};
        }
        return (
            rounds?.reduce((map, round) => {
                return round.prompts?.length > 0 &&
                    round.prompts.every(
                        (prompt) =>
                            selectedPromptIds[prompt.id] ||
                            disabledPrompts[prompt.id],
                    )
                    ? { ...map, [round.id]: true }
                    : map;
            }, {}) || {}
        );
    }, [selectedPromptIds, rounds, disabledPrompts]);

    const promptMap = useMemo<{
        [promptId: string]: Prompt;
    }>(() => {
        return (
            rounds
                ?.filter((round) => round.prompts?.length > 0)
                .reduce((map, round) => {
                    return {
                        ...map,
                        ...round.prompts
                            .filter(
                                (prompt) =>
                                    !!selectedPromptIds &&
                                    selectedPromptIds[prompt.id],
                            )
                            .reduce(
                                (pMap, prompt) => ({
                                    ...pMap,
                                    [prompt.id]: {
                                        ...prompt,
                                        round_id: round.id,
                                        time_horizon_id: round.time_horizon_id,
                                    },
                                }),
                                {},
                            ),
                    };
                }, {}) || {}
        );
    }, [
        rounds,
        selectedPromptIds,
        selectedPromptScopes,
        selectedPromptTypeValues,
    ]);

    const selectionMap = useMemo(() => {
        return !!selections?.length ? groupBy(selections, "prompt_id") : {};
    }, [selections]);

    const teamsHaveMultipleParticipants = useMemo(() => {
        return (
            (teams?.reduce((count, team) => {
                return count + (team?.participants?.length > 1 ? 1 : 0);
            }, 0) || 0) > 0
        );
    }, [teams]);

    const teamCount = useMemo(() => {
        return teams?.length || 0;
    }, [teams]);

    const participantCount = useMemo(() => {
        return (
            teams?.reduce((count, team) => {
                return count + (team?.participants?.length || 0);
            }, 0) || 0
        );
    }, [teams]);

    const teamMap = useMemo<{
        [teamId: string]: Team;
    }>(() => {
        return teams.reduce((map, team) => ({ ...map, [team.id]: team }), {});
    }, [teams]);

    const participantMap = useMemo<{
        [participantId: string]: Participant;
    }>(() => {
        return teams.reduce(
            (map, team) => ({
                ...map,
                ...team.participants.reduce(
                    (pMap, participant) => ({
                        ...pMap,
                        [participant.id]: participant,
                    }),
                    {},
                ),
            }),
            {},
        );
    }, [teams]);

    const promptTypeAndScopeMap = useMemo<{
        [promptType: string]: { [promptScope: string]: Prompt[] };
    }>(() => {
        return Object.values(promptMap)
            .filter(
                (prompt) =>
                    // !!selectedPromptIds[prompt.id] &&
                    !!selectionMap[prompt.id]?.length,
            )
            .reduce((map, prompt) => {
                return map[prompt.prompt_type]
                    ? {
                          ...map,
                          [prompt.prompt_type]: map[prompt.prompt_type][
                              prompt.scope
                          ]
                              ? {
                                    ...map[prompt.prompt_type],
                                    [prompt.scope]: [
                                        ...map[prompt.prompt_type][
                                            prompt.scope
                                        ],
                                        prompt,
                                    ],
                                }
                              : {
                                    ...map[prompt.prompt_type],
                                    [prompt.scope]: [prompt],
                                },
                      }
                    : {
                          ...map,
                          [prompt.prompt_type]: { [prompt.scope]: [prompt] },
                      };
            }, {});
    }, [promptMap, selectionMap]);

    const promptScopeAndTypeMap = useMemo<{
        [promptScope: string]: { [promptType: string]: Prompt[] };
    }>(() => {
        return Object.values(promptMap)
            .filter(
                (prompt) =>
                    // !!selectedPromptIds[prompt.id] &&
                    !!selectionMap[prompt.id]?.length,
            )
            .reduce((map, prompt) => {
                return map[prompt.scope]
                    ? {
                          ...map,
                          [prompt.scope]: map[prompt.scope][prompt.prompt_type]
                              ? {
                                    ...map[prompt.scope],
                                    [prompt.prompt_type]: [
                                        ...map[prompt.scope][
                                            prompt.prompt_type
                                        ],
                                        prompt,
                                    ],
                                }
                              : {
                                    ...map[prompt.scope],
                                    [prompt.prompt_type]: [prompt],
                                },
                      }
                    : {
                          ...map,
                          [prompt.scope]: { [prompt.prompt_type]: [prompt] },
                      };
            }, {});
    }, [promptMap, selectionMap]);

    const [selectionsGrouping, setSelectionsGrouping] = useState<
        "question" | "people" | "type"
    >("question");

    return {
        selectionMap,
        promptMap,
        teamCount,
        participantCount,
        // selectedPromptIds,
        selectedRoundIds,
        togglePromptId,
        toggleRoundId,
        teamMap,
        participantMap,
        promptTypeAndScopeMap,
        promptScopeAndTypeMap,
        selectionsGrouping,
        setSelectionsGrouping,
        teamsHaveMultipleParticipants,
        promptScopes,
        promptTypes,
        selectedPromptScopes,
        togglePromptScope,
        selectedPromptTypes,
        togglePromptType,
        disabledPrompts,
    };
}

export function useSelectionsGroupedByPromptProperty(
    prompts: Prompt[],
    selectionMap: { [index: string]: SelectionShape[] },
    property: keyof PromptShape,
): { [index: string]: SelectionShape[] } {
    const selectionsGroupedByPromptProperty: {
        [index: string]: SelectionShape[];
    } = useMemo(() => {
        if (
            !!prompts?.length &&
            !!selectionMap &&
            !!Object.keys(selectionMap)?.length
        ) {
            const promptsGroupedByText = groupBy(prompts, property);
            return Object.keys(promptsGroupedByText).reduce(
                (map, promptText) => ({
                    ...map,
                    [promptText]: promptsGroupedByText[promptText].reduce(
                        (selections, prompt) =>
                            !!selectionMap[prompt.id]
                                ? [...selections, ...selectionMap[prompt.id]]
                                : selections,
                        [],
                    ),
                }),
                {},
            );
        }
    }, [prompts, selectionMap]);

    return selectionsGroupedByPromptProperty;
}
