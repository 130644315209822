import React from "react";
import { Icon } from "react-icons-kit";
import { caretRight } from "react-icons-kit/fa/caretRight";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";
import ContentBlockSelectorButton from "./ContentBlockSelectorButton";
import {
    AddableCategory,
    AddableSubcategory,
    useContentBlockMetadata,
} from "@/model-configs";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { useAddContentBlockState } from "../content-block-buttons/useAddContentBlockState";

export function AddContentBlockMenu({
    selectedCategory,
    setSelectedCategory,
    openSubcategories,
    setOpenSubcategories,
    currentPageId,
}: {
    selectedCategory: AddableCategory;
    setSelectedCategory: (category: AddableCategory) => void;
    openSubcategories: {
        [index in AddableSubcategory]: boolean;
    };
    setOpenSubcategories: (map: {
        [index in AddableSubcategory]: boolean;
    }) => void;
    currentPageId: string;
}) {
    const { setAddableContentBlockMetaData, addContentBlockState, reset } =
        useAddContentBlockState();
    const { addableContentBlockMetaData } = addContentBlockState;
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { categories } = useContentBlockMetadata();
    return (
        <div
            className="w-full"
            id="add-content-menu"
            data-testid="add-content-menu"
        >
            <div className="flex justify-center pb-4">
                <>
                    {Object.keys(categories).map((category) => (
                        <button
                            className={
                                selectedCategory === category
                                    ? "bg-[rgba(255,255,255,0.25)] px-3 py-2 text-xs text-white"
                                    : "bg-[rgba(255,255,255,0.15)] px-3 py-2 text-xs text-white"
                            }
                            key={category}
                            onClick={() =>
                                setSelectedCategory(category as AddableCategory)
                            }
                        >
                            {category}
                        </button>
                    ))}
                    {(!!activeContentBlock || !!currentPageId) && (
                        <Link
                            href={sapienRoute(
                                "creator.design.create.from-template",
                                {
                                    parentContentBlockId:
                                        activeContentBlock?.id || currentPageId,
                                },
                            )}
                            only={["locationInfo"]}
                            className={
                                "bg-[rgba(255,255,255,0.15)] px-3 py-2 text-xs text-white"
                            }
                        >
                            Templates
                        </Link>
                    )}
                </>
            </div>
            <div>
                {Object.keys(categories[selectedCategory]).map(
                    (subcategory) => (
                        <div key={subcategory}>
                            <div
                                onClick={() =>
                                    setOpenSubcategories({
                                        ...openSubcategories,
                                        ...{
                                            [subcategory]:
                                                !openSubcategories[subcategory],
                                        },
                                    })
                                }
                                className="flex items-center bg-[rgba(255,255,255,0.03)] px-2 py-1"
                                style={{
                                    borderTop:
                                        "1px solid rgba(255,255,255,0.15)",
                                    borderBottom:
                                        "0.5px solid rgba(255,255,255,0.15)",
                                }}
                            >
                                <Icon
                                    icon={
                                        openSubcategories[subcategory]
                                            ? caretDown
                                            : caretRight
                                    }
                                    style={{
                                        display: "flex",
                                        paddingRight: "4px",
                                    }}
                                />
                                {subcategory}
                            </div>
                            {openSubcategories[subcategory] && (
                                <div
                                    className={`grid gap-2 p-4 ${
                                        openSubcategories[subcategory]
                                            ? ""
                                            : "h-0"
                                    }`}
                                    style={{
                                        gridTemplateColumns: Object.keys(
                                            categories[selectedCategory][
                                                subcategory
                                            ],
                                        ).some((addable) => {
                                            return categories[selectedCategory][
                                                subcategory
                                            ][addable].isFullWidth;
                                        })
                                            ? "1fr"
                                            : "1fr 1fr",
                                    }}
                                >
                                    {Object.keys(
                                        categories[selectedCategory][
                                            subcategory
                                        ],
                                    ).map((addable) => (
                                        <ContentBlockSelectorButton
                                            key={addable}
                                            label={addable}
                                            contentBlockMeta={
                                                categories[selectedCategory][
                                                    subcategory
                                                ][addable]
                                            }
                                            callback={
                                                addableContentBlockMetaData?.key ===
                                                addable
                                                    ? reset
                                                    : setAddableContentBlockMetaData
                                            }
                                            isSelected={
                                                addableContentBlockMetaData?.key ===
                                                addable
                                            }
                                            othersAreSelected={
                                                !!addableContentBlockMetaData &&
                                                addableContentBlockMetaData.key !==
                                                    addable
                                            }
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ),
                )}
            </div>
        </div>
    );
}
