import { useAppDispatch, useAppSelector } from "@/redux-state";
import { useCallback } from "react";
import { setDevice, setOrientation } from "./mobileViewSlice";
import { device, orientation } from "./MobileWrapperTypes";

export const useMobileViewState = () => {
    const dispatch = useAppDispatch();

    const _orientation = useAppSelector(
        (state) => state?.mobileViewState.orientation
    );
    const _device = useAppSelector((state) => state?.mobileViewState.device);

    const _setDevice = useCallback(
        (device: device | null) => {
            dispatch(setDevice(device));
        },
        [dispatch]
    );

    const _setOrientation = useCallback(
        (orientation: orientation | null) => {
            dispatch(setOrientation(orientation));
        },
        [dispatch]
    );

    return {
        orientation: _orientation,
        device: _device,
        setOrientation: _setOrientation,
        setDevice: _setDevice,
    };
};
