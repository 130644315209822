import { ContentBlockShape, ContentBlockType } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";

export const barData = [
    {
        x: 1,
        y: 2,
    },
    {
        x: 2,
        y: 3,
    },
    {
        x: 3,
        y: 5,
    },
    {
        x: 4,
        y: 5,
    },
    {
        x: 5,
        y: 8,
    },
];

export const lineData = [
    { x: 1, y: 2 },
    { x: 2, y: 3 },
    { x: 3, y: 5 },
    { x: 4, y: 4 },
    { x: 5, y: 7 },
];

export const pieData = [
    {
        label: "Category 1",
        displayText: "Category 1 (35)",
        x: 0,
        y: 35,
        theme: {
            fill: "tomato",
        },
    },
    {
        label: "Category 2",
        displayText: "Category 2 (40)",
        x: 1,
        y: 40,
        theme: {
            fill: "orange",
        },
    },
    {
        label: "Category 3",
        displayText: "Category 3 (55)",
        x: 2,
        y: 55,
        theme: {
            fill: "gold",
        },
    },
];

export function createChart(
    round_id: string,
    weight: number,
    chart_data: FormattedChartValue[] | {[index:string]: FormattedChartValue[]},
): ContentBlockShape {
    return {
        content_block_type: ContentBlockType.Section,
        round_id,
        theme: {
            tailwindClasses: [
                "flex",
                "flex-wrap",
                "flex-row",
                "justify-center",
                "items-center",
                "gap-4",
                "p-4",
            ],
        },
        weight,
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Chart Container"],
                round_id,
                theme: {
                    tailwindClasses: ["w-full", "min-h-96"],
                },
                weight: 0,
                content: "Chart",
                chart_data,
            },
        ],
    };
}
