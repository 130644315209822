import React from "react";

export default function LogoImage({
    size,
    opacity,
}: {
    size: string;
    opacity: string;
}) {
    let height: string, width: string;

    height = width = `${size}px`;

    return (
        <img
            src="https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/sapien_logo.png"
            alt="Sapien Experience logo"
            style={{ width, height, opacity }}
        />
    );
}
