import { Minimize2, Maximize2 } from "lucide-react";
import React from "react";

type Props = {
    allAreCollapsed: boolean;
    setAllAreCollapsed: (allAreCollapsed: boolean) => void;
};

function CollapseAllControlButtonComponent({
    allAreCollapsed,
    setAllAreCollapsed,
}: Props) {
    return (
        <button
            type="button"
            className={`group relative inline-flex h-[1.6rem] w-28 items-center overflow-hidden
                rounded-md border border-slate-300 p-1 text-center text-xs transition-all
                dark:border-slate-600`}
            data-is-collapsed={allAreCollapsed ? "true" : "false"}
            onClick={() => {
                setAllAreCollapsed(!allAreCollapsed);
            }}
        >
            <span
                className={`absolute top-1/2 flex w-full items-center gap-1 rounded-full px-1 py-0.5 text-xs
                font-semibold text-slate-500 transition-all -translate-y-1/2
                group-data-[is-collapsed="false"]:left-1/2
                group-data-[is-collapsed="true"]:left-full
                group-data-[is-collapsed="false"]:-translate-x-[47%] dark:text-slate-400`}
            >
                <Minimize2 className="h-4 w-4" />
                Collapse All
            </span>
            <span
                className={`absolute top-1/2 flex w-full items-center gap-1 rounded-full px-1 py-0.5 text-xs
                font-semibold text-slate-500 transition-all -translate-y-1/2
                group-data-[is-collapsed="false"]:right-full
                group-data-[is-collapsed="true"]:right-1/2
                group-data-[is-collapsed="true"]:translate-x-[57%] dark:text-slate-400`}
            >
                <Maximize2 className="h-4 w-4" />
                Expand All
            </span>
        </button>
    );
}

export const CollapseAllControlButton = React.memo(
    CollapseAllControlButtonComponent,
);
