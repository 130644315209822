import React, { useEffect, useRef } from "react";
import { ModelVariable, VariableValue } from "@/models";
import { InteractiveSectionSettings } from "./settings";
import { InteractiveSectionData } from "./data";
import { InteractiveSectionDashboard } from "./dashboard";
import { InteractiveSectionDesign } from "./design";
import { InteractiveSectionInsights } from "./insights";
import { InteractiveSectionGuides } from "./guides";
import { FavoritesDrawerDisplay } from "./favorites";
import { BottomDrawerWrapper, CenterPanelPageWrapper } from "./shared";
import {
    InputDataItem,
    InteractiveModelBlock,
    InteractivePageSection,
} from "./types";
import { useGetArchetypeValues } from "./useCalculateInteractiveModel";
import {
    useHandleChartAndDataCardValueMaps,
    useInteractiveModule,
} from "./useInteractiveModule";

type Props = {
    pageSection: InteractivePageSection;
    isDrawerOpen: boolean;
    setIsDrawerOpen: (isDrawerOpen: boolean) => void;
    modelBlock: InteractiveModelBlock;
    inputDataObject: Record<string, InputDataItem>;
    targetVariables: ModelVariable[];
    valuesMonthly: Record<string, VariableValue[]>;
    valuesYearly: Record<string, VariableValue[]>;
    archetypeValuesYearly: Record<string, VariableValue[]>;
};

const InteractiveDetailDisplayComponent = ({
    pageSection,
    isDrawerOpen,
    setIsDrawerOpen,
    modelBlock,
    inputDataObject,
    targetVariables,
    valuesMonthly,
    valuesYearly,
    archetypeValuesYearly,
}: Props) => {
    const containerRef = useRef(null);

    useInteractiveModule({
        modelBlock,
        inputDataObject,
        targetVariables,
        valuesMonthly,
        valuesYearly,
        archetypeValuesYearly,
    });

    useGetArchetypeValues();

    useHandleChartAndDataCardValueMaps();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
            });
        }
    }, [pageSection]);

    return (
        <CenterPanelPageWrapper containerRef={containerRef}>
            <div className="mb-8 flex flex-col">
                {pageSection === "Dashboard" && <InteractiveSectionDashboard />}
                {pageSection === "Settings" && <InteractiveSectionSettings />}
                {pageSection === "Data" && <InteractiveSectionData />}
                {pageSection === "Insights" && <InteractiveSectionInsights />}
                {pageSection === "Design" && <InteractiveSectionDesign />}
                {pageSection === "Guides" && <InteractiveSectionGuides />}
            </div>
            <BottomDrawerWrapper
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
            >
                <FavoritesDrawerDisplay />
            </BottomDrawerWrapper>
        </CenterPanelPageWrapper>
    );
};

export const InteractiveDetailDisplay = React.memo(
    InteractiveDetailDisplayComponent,
);
