import React, { useCallback, useMemo, useReducer } from "react";
import { ModalContainer } from "@/modules/shared";
import { initialState, reducer } from "./TableWizardReducer";
import Steps from "../Steps";
import WizardFooter from "../WizardFooter";
import ModelTableBuilder from "./ModelTableBuilder";
import {
    useFindContentBlockMethods,
    useModalQueryParams,
    useSelectedRoundId,
    useGotContentBlock,
} from "@/hooks";
import { ArrowRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { saveTable } from "./saveTable";
import { ContentBlockType } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";

function TableWizardContent() {
    const { selectedRoundId } = useSelectedRoundId();
    const { queryParams, closeModal } = useModalQueryParams("TableWizard");
    const { parentContentBlockId, tableId, type } = queryParams;
    const { getContentBlock } = useFindContentBlockMethods();
    const table = useMemo(() => {
        return getContentBlock(tableId);
    }, [getContentBlock, tableId]);

    const [state, dispatch] = useReducer(reducer, initialState);
    if (!state.dataSource)
        dispatch({
            type: "SetTableDataSourceAction",
            payload: { dataSource: "manual" },
        });

    if (table?.id && !state?.table?.id) {
        dispatch({
            type: "SetInitialTableAction",
            payload: { table },
        });
    }

    const saveTableCallback = useCallback(async () => {
        const { table } = await saveTable({
            ...state.table,
            parent_content_block_id: parentContentBlockId,
            round_id: selectedRoundId,
            weight: 0,
            modelVariableIds: state.modelVariableIds,
            timeHorizonIds: state.timeHorizonIds,
            content_block_type: type as ContentBlockType,
        });
        SapienInertia.reload({
            onSuccess: () => {
                closeModal();
            },
        });
    }, [
        state.table,
        parentContentBlockId,
        selectedRoundId,
        state.modelVariableIds,
        state.timeHorizonIds,
    ]);

    // const { editedIndex, editedRow } = state;
    return (
        <div
            className="flex flex-col items-center space-y-4 overflow-x-hidden py-6 text-white"
            data-testid="table-wizard"
            id="test-table-wizard"
        >
            <div
                className={`flex w-full px-6 ${!table?.id ? "justify-between" : "justify-end"}`}
            >
                {!table?.id && (
                    <Steps numberOfSteps={3} currentStep={state?.step} />
                )}
                {
                    <span
                        className="inline-flex items-center rounded-full bg-green-100/70 px-3 py-0.5 text-sm
                            font-medium capitalize text-green-800"
                    >
                        {state.dataSource === "model"
                            ? "Model Data"
                            : "Manually Created"}{" "}
                        Table
                    </span>
                }
            </div>

            {state.step === 1 && (
                <div
                    className="flex w-full flex-col items-start justify-center space-y-4 border-t
                        border-[#374151] p-6 px-8"
                >
                    <h3 className="text-center text-lg font-bold">
                        What kind of data would you like to use for your table?
                    </h3>
                    <div className="flex w-full space-x-6">
                        <div
                            data-testid="table-wizard-set-data-source-manual"
                            onClick={() => {
                                dispatch({
                                    type: "SetTableDataSourceAction",
                                    payload: { dataSource: "model" },
                                });
                            }}
                            role="button"
                            className="flex w-1/2 flex-col items-center justify-between space-y-4 rounded-md bg-white/5
                                p-6 shadow transition-all hover:bg-white/10"
                        >
                            <div className="flex flex-col gap-2">
                                <h4 className="pt-4 text-lg font-bold">
                                    Enter your own text in the table
                                </h4>
                                <p className="text-small text-stone-300">
                                    Set the size of your table. You'll be able
                                    to add and remove rows and columns later.
                                </p>
                            </div>
                            <div className="flex w-full flex-col sm:space-y-2 md:flex-row md:justify-between md:space-y-0">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium leading-6 sm:pt-1.5"
                                >
                                    Number of Rows
                                </label>
                                <div className="mt-2 flex w-32 sm:col-span-2 sm:mt-0">
                                    <button
                                        data-testid="remove-row-button"
                                        className="rounded-l-md border p-2"
                                        onClick={() => {
                                            dispatch({
                                                type: "RemoveRowAction",
                                            });
                                        }}
                                    >
                                        <MinusIcon className="h-4 w-4" />
                                    </button>
                                    <input
                                        data-testid="number-of-rows"
                                        onChange={(e) => {
                                            const rowCount = parseInt(
                                                e.target.value,
                                            );
                                            if (isNaN(rowCount)) return;
                                            dispatch({
                                                type: "SetNumberOfRowsAction",
                                                payload: {
                                                    rowCount,
                                                },
                                            });
                                        }}
                                        value={
                                            state.table?.table_data?.length || 3
                                        }
                                        type="text"
                                        name="rows"
                                        className="block w-full border-0 border-gray-300 bg-white/10 py-1.5 text-center
                                            text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300
                                            placeholder:text-gray-400 focus:text-white focus:outline-offset-0
                                            focus:outline-[#1d4ed8] focus:ring-2 focus:ring-inset focus:ring-indigo-600
                                            sm:max-w-xs sm:text-sm sm:leading-6"
                                    />
                                    <button
                                        data-testid="add-row-button"
                                        className="rounded-r-md border p-2"
                                        onClick={() => {
                                            dispatch({
                                                type: "AddRowAction",
                                            });
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex w-full flex-col sm:space-y-2 md:flex-row md:justify-between md:space-y-0">
                                <label className="block text-sm font-medium leading-6 sm:pt-1.5">
                                    Number of Columns
                                </label>
                                <div className="mt-2 flex w-32 sm:col-span-2 sm:mt-0">
                                    <button
                                        data-testid="remove-column-button"
                                        className="rounded-l-md border p-2"
                                        onClick={() => {
                                            dispatch({
                                                type: "RemoveColumnAction",
                                            });
                                        }}
                                    >
                                        <MinusIcon className="h-4 w-4" />
                                    </button>
                                    <input
                                        data-testid="number-of-columns"
                                        onChange={(e) => {
                                            const columnCount = parseInt(
                                                e.target.value,
                                            );
                                            if (isNaN(columnCount)) return;
                                            dispatch({
                                                type: "SetNumberOfColumnsAction",
                                                payload: {
                                                    columnCount,
                                                },
                                            });
                                        }}
                                        value={
                                            state.table?.table_data?.[0]
                                                ?.length || 3
                                        }
                                        type="text"
                                        name="columns"
                                        className="block w-full border-0 border-gray-300 bg-white/10 py-1.5 text-center
                                            text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300
                                            placeholder:text-gray-400 focus:text-white focus:outline-offset-0
                                            focus:outline-[#1d4ed8] focus:ring-2 focus:ring-inset focus:ring-indigo-600
                                            sm:max-w-xs sm:text-sm sm:leading-6"
                                    />
                                    <button
                                        data-testid="add-column-button"
                                        className="rounded-r-md border p-2"
                                        onClick={() => {
                                            dispatch({
                                                type: "AddColumnAction",
                                            });
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>
                            <button
                                onClick={saveTableCallback}
                                className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                    py-2.5 text-center text-sm font-medium text-white transition-all
                                    hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                    disabled:cursor-not-allowed disabled:opacity-75"
                            >
                                Create Table
                            </button>
                        </div>
                        <div
                            data-testid="table-wizard-set-data-source-model"
                            className="flex w-1/2 flex-col items-center justify-between rounded-md bg-white/5 p-6
                                shadow transition-all hover:bg-white/10"
                        >
                            <div className="flex flex-col gap-2">
                                <h4 className="pt-4 text-lg font-bold">
                                    Use data from the sim's model
                                </h4>
                                <p className="text-small text-stone-300">
                                    Build a table by selecting model variables
                                    and time horizons
                                </p>
                            </div>
                            <div className="flex w-full flex-col sm:space-y-2 md:flex-row md:justify-between md:space-y-0">
                                <button
                                    data-testid="model-table-builder-button"
                                    onClick={() => {
                                        dispatch({
                                            type: "SetTableDataSourceAction",
                                            payload: { dataSource: "model" },
                                        });
                                        dispatch({
                                            type: "IncrementStepAction",
                                        });
                                    }}
                                    className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                        py-2.5 text-center text-sm font-medium text-white transition-all
                                        hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                        disabled:cursor-not-allowed disabled:opacity-75"
                                >
                                    Build Table
                                    <ArrowRightIcon className="ml-2 h-4 w-4" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {state.step === 2 && state.dataSource === "model" && (
                <ModelTableBuilder
                    table={state.table}
                    modelVariableIds={state.modelVariableIds}
                    setModelVariableIds={(modelVariableIds) => {
                        dispatch({
                            type: "SetModelVariableIds",
                            payload: {
                                modelVariableIds,
                            },
                        });
                    }}
                    timeHorizonIds={state.timeHorizonIds}
                    setTimeHorizonIds={(timeHorizonIds) => {
                        const horizonSet = new Set(timeHorizonIds);
                        dispatch({
                            type: "SetTimeHorizonIds",
                            payload: {
                                timeHorizonIds: Array.from(horizonSet),
                            },
                        });
                    }}
                    moveRowDown={(rowIndex) => {
                        dispatch({
                            type: "MoveRowDownAction",
                            payload: {
                                rowIndex,
                            },
                        });
                    }}
                    moveRowUp={(rowIndex) => {
                        dispatch({
                            type: "MoveRowUpAction",
                            payload: {
                                rowIndex,
                            },
                        });
                    }}
                    deleteRow={(rowIndex) => {
                        console.log("delete row", rowIndex);
                        // dispatch({
                        //     type: "MoveRowUpAction",
                        //     payload: {
                        //         rowIndex,
                        //     },
                        // });
                    }}
                />
            )}
            <WizardFooter
                leftButtonHandler={() => {
                    if (state.step === 1) {
                        closeModal();
                        return;
                    }
                    dispatch({ type: "DecrementStepAction" });
                }}
                rightButtonHandler={
                    state.step === 2 ? saveTableCallback : undefined
                }
                isLastStep={state.step === 2 || !!table?.id}
                isFirstStep={state.step === 1 || !!table?.id}
            />
        </div>
    );
}

export default function TableWizard({}: {}) {
    const { closeModal, isAtModalUrl } = useModalQueryParams("TableWizard");
    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => closeModal}
            size="xl"
            backgroundColor="#111928"
            modalId="table-wizard-wrapper"
        >
            {isAtModalUrl && <TableWizardContent />}
        </ModalContainer>
    );
}
