import React from "react";
import { Icon } from "react-icons-kit";
import { chevronUp } from "react-icons-kit/feather/chevronUp";
import { chevronDown } from "react-icons-kit/feather/chevronDown";

export interface RankingOption {
    id: string;
    value: number;
    text: string;
}

export const RankingComponent = (props: {
    options: RankingOption[];
    updateOptions: (rankingOptions: RankingOption[]) => void;
}) => {
    const { options, updateOptions } = props;

    const handleAction = (start: number | string, end: number | string) => {
        let arr = options;
        [arr[Number(start)], arr[Number(end)]] = [
            arr[Number(end)],
            arr[Number(start)]
        ];
        let newOptions = arr.map((option, i) => {
            return {
                id: option.id,
                value: i + 1,
                text: option.text
            } as RankingOption;
        });

        updateOptions(newOptions);
    };

    return (
        <>
            {options
                .sort((a, b) => a.value - b.value)
                .map((option, index) => (
                    <div
                        className={`form-check grid`}
                        key={option.id.toString()}
                        style={{
                            gridTemplateColumns: "50px 1fr",
                            gridGap: 0,
                            marginTop: 0,
                            display: "grid",
                            padding: "10px 5px"
                        }}
                    >
                        <label
                            style={{
                                marginLeft: 0,
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: 0
                            }}
                        >
                            {index !== 0 && (
                                <Icon
                                    icon={chevronUp}
                                    onClick={() => {
                                        handleAction(index, index - 1);
                                    }}
                                />
                            )}
                            {index !== options.length - 1 && (
                                <Icon
                                    icon={chevronDown}
                                    onClick={() => {
                                        handleAction(index, index + 1);
                                    }}
                                />
                            )}
                        </label>
                        <label
                            style={{
                                marginLeft: 0,
                                display: "flex",
                                alignItems: "center"
                            }}
                            className="form-check-label"
                            htmlFor={option.id.toString()}
                            // onClick={(e) => {
                            //   null;
                            // }}
                        >
                            {option.text}
                        </label>
                    </div>
                ))}
        </>
    );
};
