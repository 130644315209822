import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape, VariableValue } from "@/models";
import axios from "axios";

export async function saveContentBlockModelDataSources(postBody: {
    contentBlockId: string;
    timeHorizonIds: string[];
    modelVariableIds: string[];
}) {
    const route = sapienRoute(
        "creator.design.content-block-model-data-sources.store"
    );

    const response = await axios.post<{
        contentBlock: ContentBlockShape;
        variableValues: VariableValue[];
    }>(route, postBody);

    return response.data;
}
