import { useQuery } from "@tanstack/react-query";
import { getModelBlocks } from "./getModelBlocks";
import { useSelectedSimulation } from "@/hooks";
import { ModelBlock } from "@/models";

export function useGetModelBlocksBySimulationId() {
    const { selectedSimulation } = useSelectedSimulation();

    return useQuery({
        queryKey: ["model-blocks", selectedSimulation?.id],
        queryFn: () => getModelBlocks(selectedSimulation?.id),
        enabled: !!selectedSimulation?.id,
    });
}
