import React, { FC, ReactNode } from "react";
import { TopNavContainer } from "../TopNavContainer";
import { User } from "@/models";

export const SimBuilderTopNav: FC<{ children?: ReactNode; user?: User }> = ({
    children,
    user,
}) => {
    return (
        <TopNavContainer header={"Sim Builder"}>
            {children !== undefined && children}
        </TopNavContainer>
    );
};
