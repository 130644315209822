import React, { useState } from "react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/Select";
import { ContentBlockShape } from "@/models";
import { transformAccordionAndTabsBlocks } from "./transformAccordionAndTabsBlocks";
import { useContentBlockState } from "../../../useContentBlockState";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";

const variants = [
    "accordion",
    "tabs",
] as const;

export type AccordionAndTabsVariant = (typeof variants)[Exclude<
    keyof typeof variants,
    keyof Array<string>
>];
type Props = {
    width?: number;
    contentBlock: ContentBlockShape;
};

export default function AccordionDropDown({ contentBlock }: Props) {
    const { setContentBlock } = useContentBlockState();

    const [variant, setVariant] = useState<AccordionAndTabsVariant>(
        (contentBlock.theme?.variant as AccordionAndTabsVariant) || "accordion",
    );

    const { mutate } = useSaveContentBlock();

    return (
        <Select
            onValueChange={(value) => {
                setVariant(value as AccordionAndTabsVariant);
                const updatedBlock = transformAccordionAndTabsBlocks(
                    contentBlock,
                    value as AccordionAndTabsVariant,
                );
                setContentBlock(updatedBlock);
                mutate(updatedBlock);
            }}
        >
            <SelectTrigger className="w-56 bg-white shadow-md">
                <SelectValue placeholder={variant} />
            </SelectTrigger>
            <SelectContent className="cursor-pointer bg-white pl-0">
                {variants.map((item) => (
                    <SelectItem
                        value={item}
                        key={item}
                        className="flex flex-row w-full relative"
                    >
                        <span>{item}</span>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}
