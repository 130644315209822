import {
    ContentBlockShape,
    ContentBlockType,
    RichText,
    TimeHorizonShape,
} from "@/models";
import { ContentBlockModelDataSource } from "@/models/ContentBlockModelDataSource";
import { defaultTableTheme } from "@/styles";
export function switchArrayElements(
    arr: any[],
    index1: number,
    index2: number
): any[] {
    const copy = [...arr];
    if (
        index1 >= 0 &&
        index1 < copy.length &&
        index2 >= 0 &&
        index2 < copy.length
    ) {
        [copy[index1], copy[index2]] = [copy[index2], copy[index1]];
    } else {
        console.error("Invalid indices provided");
    }
    return copy;
}
export const defaultCell: RichText = {
    type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                typographyType: "PARAGRAPH",
                textAlign: "left",
            },
            content: [
                {
                    type: "text",
                    text: " ",
                },
            ],
        },
    ],
};
export function addRow(columnCount: number, rowNumber: number): RichText[] {
    return [...Array(columnCount).keys()].map((_, i) => {
        return defaultCell;
    });
}

export const steps = [1, 2] as const;

type State = {
    step: (typeof steps)[number];
    table: ContentBlockShape;
    editedRow: ContentBlockModelDataSource[] | RichText[];
    dataSource: "model" | "manual" | null;
    editedIndex: number;
    rowCount: number;
    columnCount: number;
    selectedTimeHorizons: TimeHorizonShape[];
    modelVariableIds: string[];
    timeHorizonIds: string[];
};

export const initialState: State = {
    step: 1,
    table: {
        theme: defaultTableTheme,
        contentBlocks: [
            {
                content_block_type: ContentBlockType["Table Head"],
            },
            {
                content_block_type: ContentBlockType["Table Body"],
            },
        ],
    } as ContentBlockShape,
    editedRow: [],
    dataSource: null,
    editedIndex: -1,
    rowCount: 3,
    columnCount: 3,
    selectedTimeHorizons: [],
    modelVariableIds: [],
    timeHorizonIds: [],
};

export type Actions = {
    SetInitialTableAction: {
        type: "SetInitialTableAction";
        payload: {
            table: ContentBlockShape;
        };
    };
    SetTableDataSourceAction: {
        type: "SetTableDataSourceAction";
        payload: {
            dataSource: "model" | "manual";
        };
    };
    IncrementStepAction: {
        type: "IncrementStepAction";
    };
    DecrementStepAction: {
        type: "DecrementStepAction";
    };
    SetEditedIndexAction: {
        type: "SET_EDITED_INDEX";
        payload: {
            editedIndex: number;
        };
    };
    CancelEditAction: {
        type: "CancelEditAction";
    };
    CommitEditsAction: {
        type: "CommitEditsAction";
        payload: {
            table: ContentBlockShape;
        };
    };
    AddRowAction: {
        type: "AddRowAction";
    };
    AddColumnAction: {
        type: "AddColumnAction";
    };
    SetNumberOfColumnsAction: {
        type: "SetNumberOfColumnsAction";
        payload: {
            columnCount: number;
        };
    };
    RemoveRowAction: {
        type: "RemoveRowAction";
    };
    RemoveColumnAction: {
        type: "RemoveColumnAction";
    };
    SetNumberOfRowsAction: {
        type: "SetNumberOfRowsAction";
        payload: {
            rowCount: number;
        };
    };
    SetTimeHorizonIds: {
        type: "SetTimeHorizonIds";
        payload: {
            timeHorizonIds: string[];
        };
    };
    SetModelVariableIds: {
        type: "SetModelVariableIds";
        payload: {
            modelVariableIds: string[];
        };
    };
    MoveRowUpAction: {
        type: "MoveRowUpAction";
        payload: {
            rowIndex: number;
        };
    };
    MoveRowDownAction: {
        type: "MoveRowDownAction";
        payload: {
            rowIndex: number;
        };
    };
};

// Create an enum based on the keys of MyType
type ActionTypeEnum = keyof Actions;

// Create a mapped type to get the value types
export type ActionMap = {
    [K in ActionTypeEnum]: Actions[K];
};
export function reducer<T extends keyof ActionMap>(
    state: State,
    action: ActionMap[T]
): State {
    switch (action.type) {
        case "SetInitialTableAction": {
            return {
                ...state,
                table: action.payload.table,
                step: action.payload.table?.id ? 2 : 1,
                //only model tables are edited in the wizard,
                //so we can assume a table with an id is a model table
                //we always default to manual tables
                dataSource: action.payload.table?.id ? "model" : "manual",
            };
        }
        case "SetTableDataSourceAction": {
            if (
                !state.table.table_data?.length &&
                action.payload.dataSource === "manual"
            ) {
                state.table.table_data = [...Array(state.rowCount).keys()].map(
                    (_, i) => addRow(state.columnCount, i)
                );
            }

            return { ...state, dataSource: action.payload.dataSource };
        }
        case "IncrementStepAction": {
            if (state.step === steps[steps.length - 1]) return state;
            return { ...state, step: (state.step + 1) as 1 | 2 };
        }
        case "DecrementStepAction": {
            if (state.step === steps[0]) return state;
            return { ...state, step: (state.step - 1) as 1 | 2 };
        }
        case "CommitEditsAction": {
            return { ...state, table: action.payload.table };
        }
        case "RemoveRowAction": {
            return {
                ...state,
                rowCount: state.rowCount - 1,
                table: {
                    ...state.table,
                    table_data: state.table.table_data?.slice(0, -1),
                },
            };
        }
        case "AddRowAction": {
            console.log("columnCount", state.columnCount);
            return {
                ...state,
                rowCount: state.rowCount + 1,
                table: {
                    ...state.table,
                    table_data: [
                        ...state.table.table_data,
                        addRow(
                            state.columnCount,
                            state.table.table_data?.length || 0
                        ),
                    ],
                },
            };
        }
        case "SetNumberOfRowsAction": {
            return {
                ...state,
                rowCount: action.payload.rowCount,
                table: {
                    ...state.table,
                    table_data: [...Array(action.payload.rowCount).keys()].map(
                        (_, i) => addRow(state.columnCount, i)
                    ),
                },
            };
        }
        case "AddColumnAction": {
            return {
                ...state,
                columnCount: state.columnCount + 1,
                table: {
                    ...state.table,
                    table_data: state.table.table_data?.map((row) => [
                        ...row,
                        defaultCell,
                    ]),
                },
            };
        }
        case "RemoveColumnAction": {
            return {
                ...state,
                columnCount: state.columnCount - 1,
                table: {
                    ...state.table,
                    table_data: state.table.table_data?.map((row) =>
                        row.slice(0, -1)
                    ),
                },
            };
        }
        case "SetNumberOfColumnsAction": {
            return {
                ...state,
                columnCount: action.payload.columnCount,
                table: {
                    ...state.table,
                    table_data: [
                        ...Array(state.table.table_data.length).keys(),
                    ].map((_, i) => addRow(action.payload.columnCount, i)),
                },
            };
        }
        case "SetModelVariableIds": {
            return {
                ...state,
                modelVariableIds: action.payload.modelVariableIds,
            };
        }
        case "SetTimeHorizonIds": {
            return {
                ...state,
                timeHorizonIds: action.payload.timeHorizonIds,
            };
        }
        case "MoveRowDownAction": {
            return {
                ...state,
                modelVariableIds: switchArrayElements(
                    state.modelVariableIds,
                    action.payload.rowIndex,
                    action.payload.rowIndex + 1
                ),
            };
        }
        case "MoveRowUpAction": {
            return {
                ...state,
                modelVariableIds: switchArrayElements(
                    state.modelVariableIds,
                    action.payload.rowIndex,
                    action.payload.rowIndex - 1
                ),
            };
        }
    }
    return state;
}
