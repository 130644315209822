import React, { useCallback, useEffect } from "react";
import { ContentBlockShape, Option, OptionShape } from "@/models";
import {
    PencilIcon,
    CheckIcon,
    XMarkIcon,
    PlusIcon,
} from "@heroicons/react/24/solid";
import {
    DependentOption,
    DynamicValidationRule,
} from "@/models/DynamicValidationRule";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import {} from "react-hot-toast";

type Props = {
    form: ContentBlockShape;
    closeModal:()=>void
};

function buildOptionDependencyMap(form: ContentBlockShape) {
    return form.contentBlocks.reduce((carry, contentBlock) => {
        const promptOptionMap = contentBlock.prompt.options.reduce(
            (acc, option) => {
                const relaventRules = form?.dynamicValidationRules?.filter(
                    (rule) => {
                        return rule.options.some((_option) => {
                            return (
                                _option.id === option.id &&
                                _option.relation_type === "child"
                            );
                        });
                    }
                );

                const dependencies = relaventRules.reduce((acc, rule) => {
                    return [
                        ...acc,
                        ...rule.options
                            .filter(
                                (_option) => _option.relation_type === "parent"
                            )
                            .map((_option) => _option),
                    ];
                }, []);
                return {
                    ...acc,
                    [option.id]: dependencies,
                };
            },
            {}
        );
        return { ...carry, ...promptOptionMap };
    }, {});
}

function buildValidationRuleLabelsByOptionId(form: ContentBlockShape) {
    return form.dynamicValidationRules.reduce((carry, rule) => {
        const childOption = rule.options.find(
            (option) => option.relation_type === "child"
        );
        if (!childOption) return carry;
        return { ...carry, [childOption.id]: rule.label };
    }, {});
}

type State = {
    optionDependencyMap: { [index: string]: DependentOption[] };
    validationRuleLabelsByOptionId: { [index: string]: string };
    editedValidationRuleIds: string[];
    validationRules: DynamicValidationRule[];
    selectedOption: Option | null;
};

const initialState: State = {
    optionDependencyMap: {},
    validationRuleLabelsByOptionId: {},
    editedValidationRuleIds: [],
    validationRules: [],
    selectedOption: null,
};

type Action =
    | {
          type: "SET_OPTION_DEPENDENCY_MAP";
          optionDependencyMap: { [index: string]: DependentOption[] };
      }
    | {
          type: "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID";
          validationRuleLabelsByOptionId: { [index: string]: string };
      }
    | {
          type: "SET_VALIDATION_RULES";
          validationRules: DynamicValidationRule[];
      }
    | {
          type: "SET_FORM";
          form: ContentBlockShape;
      }
    | { type: "SET_SELECTED_OPTION"; option: Option }
    | { type: "EDIT_VALIDATION_RULE_LABEL"; id: string };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_OPTION_DEPENDENCY_MAP":
            return {
                ...state,
                optionDependencyMap: action.optionDependencyMap,
            };
        case "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID":
            return {
                ...state,
                validationRuleLabelsByOptionId:
                    action.validationRuleLabelsByOptionId,
            };
        case "SET_VALIDATION_RULES":
            return { ...state, validationRules: action.validationRules };
        case "SET_SELECTED_OPTION":
            return { ...state, selectedOption: action.option };
        case "EDIT_VALIDATION_RULE_LABEL":
            return {
                ...state,
                editedValidationRuleIds: [
                    ...state.editedValidationRuleIds,
                    action.id,
                ],
            };
        case "SET_FORM":
            return {
                ...state,
                editedValidationRuleIds: [],
                validationRules: action.form.dynamicValidationRules,
                optionDependencyMap: buildOptionDependencyMap(action.form),
                validationRuleLabelsByOptionId:
                    buildValidationRuleLabelsByOptionId(action.form),
            };
    }
};

export function DynamicValidationDependencyForm({ form }: Props) {
    const [
        {
            optionDependencyMap,
            validationRules,
            validationRuleLabelsByOptionId,
            selectedOption,
            editedValidationRuleIds,
        },
        dispatch,
    ] = React.useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "SET_FORM", form });
    }, [form]);

    const addDependency = useCallback(
        (
            parentOption: OptionShape,
            childOption: OptionShape & { relation_type: string }
        ) => {
            const oldValidationRule = validationRules.find((rule) => {
                return rule.options.some((option) => {
                    return (
                        option.id === childOption.id &&
                        option.relation_type === "child"
                    );
                });
            });

            console.log(
                "OLD RULE",
                oldValidationRule,
                childOption,
                parentOption
            );

            const validationRule = oldValidationRule
                ? ({
                      ...oldValidationRule,
                      options: [
                          ...oldValidationRule.options,
                          { ...parentOption, relation_type: "parent" },
                      ],
                      label:
                          validationRuleLabelsByOptionId[childOption.id] ||
                          `${parentOption.content} -> ${childOption.content}`,
                      rule_type: "dependency",
                      formId: form.id,
                  } as DynamicValidationRule & { formId: string })
                : ({
                      options: [
                          { ...parentOption, relation_type: "parent" },
                          { ...childOption, relation_type: "child" },
                      ],
                      label:
                          validationRuleLabelsByOptionId[childOption.id] ||
                          `${parentOption.content} -> ${childOption.content}`,
                      rule_type: "dependency",
                      formId: form.id,
                  } as DynamicValidationRule & { formId: string });

            save(validationRule);
        },
        [
            form,
            validationRules,
            optionDependencyMap,
            validationRuleLabelsByOptionId,
        ]
    );

    const save = useCallback(
        (validationRule: DynamicValidationRule & { formId: string }) => {
            const childOption = validationRule.options.find(
                (option) => option.relation_type === "child"
            );

            if (validationRule.id) {
                SapienInertia.put(
                    "admin.design.dynamic-validation.update",
                    {
                        ...validationRule,
                        label:
                            validationRuleLabelsByOptionId[childOption.id] ||
                            validationRule.label,
                    },
                    {
                        dynamic_validation: validationRule.id,
                    },
                    {
                        preserveState: true,
                        preserveScroll: true,
                    }
                );
            } else {
                SapienInertia.post("admin.design.dynamic-validation.store", {
                    ...validationRule,
                    label:
                        validationRuleLabelsByOptionId[childOption.id] ||
                        validationRule.label,
                });
            }
        },
        [
            validationRuleLabelsByOptionId,
            form,
            validationRules,
            optionDependencyMap,
        ]
    );

    return (
        <div className="p-4 flex flex-col">
            <h6 className="text-lg p-2">Questions</h6>
            <div className="flex flex-col space-y-2">
                {form?.contentBlocks?.map((contentBlock) => (
                    <div className="flex flex-col border border-[#374151] rounded-md w-full overflow-hidden">
                        <p className="font-bold p-4 border-b border-[#374151]">
                            {contentBlock.prompt.content}
                        </p>
                        {contentBlock.prompt.options.map((option) => (
                            <div className="bg-white/5 grid grid-cols-2">
                                <div
                                    className={`w-full flex flex-col space-y-2 border-b border-l border-[#374151] p-4 justify-end ${
                                        selectedOption?.id === option.id &&
                                        "bg-[#1F2A37]"
                                    }`}
                                >
                                    <div className="flex flex-col  divide-y-2 divide-[#374151]">
                                        {!!optionDependencyMap &&
                                            !!optionDependencyMap[option.id] &&
                                            optionDependencyMap[option.id].map(
                                                (
                                                    depedency: DependentOption
                                                ) => (
                                                    <p className="flex w-full space-x-2 p-2 items-center ">
                                                        <button
                                                            className="bg-white rounded-full p-1 text-red-600 hover:text-red-800 shadow"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                SapienInertia.delete(
                                                                    sapienRoute(
                                                                        "admin.design.dynamic-validation.destroy",
                                                                        {
                                                                            dynamic_validation:
                                                                                depedency.dynamic_validation_rule_option_id,
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <XMarkIcon className="h-4 w-4" />
                                                        </button>
                                                        <span>
                                                            {depedency.content}
                                                        </span>
                                                    </p>
                                                )
                                            )}
                                    </div>
                                    <button
                                        className="rounded flex space-x-2 items-center justify-center bg-white/5 px-2 py-1 text-white/80 hover:text-white hover:bg-white/10 transition-all"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch({
                                                type: "SET_SELECTED_OPTION",
                                                option,
                                            });
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4 text-blue-600" />
                                        <span>Add Dependency</span>{" "}
                                    </button>
                                </div>
                                <div
                                    role="button"
                                    className={`w-full flex flex-col space-y-2 border-b border-l border-[#374151] p-4 ${
                                        selectedOption?.id === option.id &&
                                        "bg-[#1F2A37]"
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                            !selectedOption ||
                                            selectedOption.id === option.id
                                        )
                                            return;
                                        addDependency(option, {
                                            ...selectedOption,
                                            relation_type: "child",
                                        });
                                    }}
                                >
                                    <div>{option.content}</div>

                                    <div className="flex items-center space-x-2">
                                        <textarea
                                            disabled={
                                                !editedValidationRuleIds.includes(
                                                    option.id
                                                )
                                            }
                                            value={
                                                validationRuleLabelsByOptionId[
                                                    option.id
                                                ]
                                            }
                                            placeholder="Enter label"
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID",
                                                    validationRuleLabelsByOptionId:
                                                        {
                                                            ...validationRuleLabelsByOptionId,
                                                            [option.id]:
                                                                e.target.value,
                                                        },
                                                });
                                            }}
                                            className="block w-full rounded-md border-0 py-1.5 px-3 border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200 sm:leading-6"
                                        />
                                        {editedValidationRuleIds.includes(
                                            option.id
                                        ) ? (
                                            <button
                                                className="bg-white rounded-full p-1 text-green-600 hover:text-green-800 shadow"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const validationRule =
                                                        validationRules.find(
                                                            (rule) =>
                                                                rule.options.some(
                                                                    (_option) =>
                                                                        _option.id ===
                                                                            option.id &&
                                                                        _option.relation_type ===
                                                                            "child"
                                                                )
                                                        );

                                                    if (validationRule) {
                                                        const childOption =
                                                            validationRule.options.find(
                                                                (option) =>
                                                                    option.relation_type ===
                                                                    "child"
                                                            );
                                                        if (childOption) {
                                                            save({
                                                                ...validationRule,
                                                                formId: form.id,
                                                                label: validationRuleLabelsByOptionId[
                                                                    option.id
                                                                ],
                                                            });
                                                        }
                                                    }
                                                }}
                                            >
                                                <CheckIcon className="h-4 w-4" />
                                            </button>
                                        ) : (
                                            <button
                                                className="text-blue-600 hover:text-blue-800 bg-white rounded-full p-1 shadow"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const validationRule =
                                                        validationRules.find(
                                                            (rule) =>
                                                                rule.options.some(
                                                                    (_option) =>
                                                                        _option.id ===
                                                                            option.id &&
                                                                        _option.relation_type ===
                                                                            "child"
                                                                )
                                                        );

                                                    if (validationRule) {
                                                        dispatch({
                                                            type: "EDIT_VALIDATION_RULE_LABEL",
                                                            id: option.id,
                                                        });
                                                    }
                                                }}
                                            >
                                                <PencilIcon className="h-4 w-4" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6">
                <div></div>
                <button
                    onClick={() => {
                        SapienInertia.reload();
                    }}
                    className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                >
                    Close
                </button>
            </div>
        </div>
    );
}
