import React from 'react'

type Props = {}

function DynamicValidationSumModalContents(){}

export default function DynamicValidationSumModal({}: Props) {
  return (
    <div>DynamicValidationSumForm</div>
  )
}
