import { useNotificationStore } from "@/hooks/useNotificationStore";
import React, { FC, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";
import ToastSvg from "./helpers/ToastSvg";

export type ToastProps = {
    isInline?: boolean;
    isClosedButton?: boolean;
    iconType?: string;
    border?: string;
    title?: string;
    text: string;
    backgroundColor?: string;
    textColor?: string;
    boxShadow?: string;
    position?:
        | "top-right"
        | "top-left"
        | "top-center"
        | "bottom-right"
        | "bottom-left"
        | "bottom-center";
    autoClose?: number;
    newestOnTop?: boolean;
    closeOnClick?: boolean;
    infoButton?: boolean;
    infoButtonIcon?: string;
    infoButtonText?: string;
    infoButtonTextColor?: string;
    infoButtonBackgroundColor?: string;
    cancelButton?: string;
    cancelButtonTextColor?: string;
    cancelButtonBackgroundColor?: string;
    confirmButton?: string;
    confirmButtonTextColor?: string;
    confirmButtonBackgroundColor?: string;
    backgroundIcon?: string;
    backgroundIconColor?: string;
    backgroundIconBackgroundColor?: string;
};

interface IToast {
    props: ToastProps;
}

const StyledToast: FC<IToast> = ({ props }) => {
    const {} = useNotificationStore();

    const notify = () => {
        toast(
            props.isInline ? (
                <div
                    style={{
                        display: "flex",
                        gap: "5px",
                    }}
                >
                    {props.iconType ? (
                        <ToastSvg
                            svg={props.iconType}
                            color={props.textColor || "#4B5563"}
                        />
                    ) : (
                        ""
                    )}
                    <p
                        style={{
                            color: props.textColor,
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 600,
                                color: props.textColor,
                            }}
                        >
                            {props.title}
                        </span>{" "}
                        {props.text}
                    </p>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}
                >
                    {props.backgroundIcon && (
                        <div
                            style={{
                                backgroundColor:
                                    props.backgroundIconBackgroundColor,
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ToastSvg
                                svg={props.backgroundIcon}
                                color={props.backgroundIconColor || "#fff"}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            gap: "5px",
                        }}
                    >
                        {props.iconType && !!props.title ? (
                            <>
                                <ToastSvg
                                    svg={props.iconType}
                                    color={props.textColor || "#4B5563"}
                                />
                            </>
                        ) : (
                            ""
                        )}

                        <span
                            style={{
                                fontWeight: 600,
                                color: props.textColor,
                            }}
                        >
                            {props.title}
                        </span>
                    </div>
                    <div className="flex item-center justify-between">
                        {!props.title && !!props.iconType && (
                            <ToastSvg
                                svg={props.iconType}
                                color={props.textColor || "#4B5563"}
                                className="pr-2 mr-2 border-right border-gray-300"
                            />
                        )}
                        <p
                            style={{
                                color: props.textColor,
                                lineHeight: 1,
                            }}
                        >
                            {props.text}
                        </p>
                    </div>
                    {props.infoButton && (
                        <button
                            style={{
                                color: props.infoButtonTextColor,
                                backgroundColor:
                                    props.infoButtonBackgroundColor,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px 8px",
                                gap: "8px",
                                borderRadius: "6px",
                                width: "40%",
                                fontWeight: 600,
                            }}
                        >
                            {props.infoButtonIcon && (
                                <ToastSvg
                                    svg={props.infoButtonIcon}
                                    color={props.infoButtonTextColor || "#fff"}
                                />
                            )}
                            {props.infoButtonText}
                        </button>
                    )}
                    {props.cancelButton || props.confirmButton ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                gap: "8px",
                            }}
                        >
                            {props.cancelButton && (
                                <button
                                    style={{
                                        color: props.cancelButtonTextColor,
                                        backgroundColor:
                                            props.cancelButtonBackgroundColor,
                                        padding: "4px 12px",
                                        borderRadius: "6px",
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.cancelButton}
                                </button>
                            )}
                            {props.confirmButton && (
                                <button
                                    style={{
                                        color: props.confirmButtonTextColor,
                                        backgroundColor:
                                            props.confirmButtonBackgroundColor,
                                        padding: "4px 12px",
                                        borderRadius: "6px",
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.confirmButton}
                                </button>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ),
            {
                autoClose: props?.autoClose || 3000,
            }
        );
    };

    useEffect(() => {
        notify();
    }, [props]);
    return (
        <div>
            <StyledToastContainer
                {...props}
                position={"top-right"}
                autoClose={props.autoClose}
                closeOnClick={props.closeOnClick}
                newestOnTop={true}
                style={{ marginTop: "3rem", zIndex: 9999 }}
            />
        </div>
    );
};

const StyledToastContainer = styled(ToastContainer).attrs({
    className: "toast-container mt-4 lg:mt-0",
    toastClassName: "toast",
    bodyClassName: "body",
    progressClassName: "progress",
})<ToastProps>`
    .toast {
        background-color: ${(props) =>
            props.backgroundColor || "#fff"} !important;
        box-shadow: ${(props) => props.boxShadow} !important;
        border: ${(props) => props.border} !important;
    }

    button[aria-label="close"] {
        display: ${(props) =>
            props.isClosedButton ? "block" : "none"} !important;
        margin-top: 7px !important;
        color: ${(props) => props.textColor} !important;
        opacity: 1 !important;
    }

    .body {
        display: flex;
    }

    .progress {
        opacity: 0 !important;
    }
`;

// const Button = styled.button`
//     text-decoration: none;
//     display: inline-block;
//     color: white;
//     padding: 15px 20px;
//     margin: 10px 20px;
//     border-radius: 10px;
//     font-family: "Montserrat", sans-serif;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//     background-image: linear-gradient(
//         to right,
//         #9eefe1 0%,
//         #4830f0 51%,
//         #9eefe1 100%
//     );
//     background-size: 200% auto;
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
//     transition: 0.5s;
//     &:hover {
//         background-position: right center;
//     }
// `;

export default StyledToast;
