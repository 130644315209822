import React, { useState, useEffect, FC, ReactNode } from "react";
import { ModelBlock } from "../../models";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import {
    PlusIcon,
    TrashIcon,
    PencilIcon,
    CheckIcon,
} from "@heroicons/react/24/outline";

export const ModelBlockDisplay = (props: {
    modelBlock: ModelBlock;
    styleValue?: number;
    isInteractive?: boolean;
}) => {
    const { modelBlock, styleValue, isInteractive } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editedBlock, setEditedBlock] = useState<ModelBlock>(
        {} as ModelBlock,
    );

    useEffect(() => {
        if (modelBlock !== undefined) {
            setEditedBlock(modelBlock);
        }
    }, [modelBlock, styleValue]);

    useEffect(() => {
        if (isEditing) {
            document.getElementById(`block-${modelBlock.id}`).focus();
        }
    }, [isEditing]);

    return (
        <div className="w-full text-white">
            <div className="my-0.5 bg-[rgba(255,255,255,0.05)] py-0.5">
                <div className="flex items-center text-sm">
                    {!isEditing ? (
                        <div className="block w-full border-[1px] border-[rgba(0,0,0,0)] px-1.5 py-1">
                            <div>{modelBlock.label}</div>
                        </div>
                    ) : (
                        <div className="w-full px-1">
                            <div>
                                <input
                                    className="block w-full rounded-md border-gray-300 px-1.5 py-1 text-gray-800 shadow-sm
                                        focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    type="text"
                                    placeholder="Text"
                                    value={editedBlock.label}
                                    onClick={(e) => e.stopPropagation()}
                                    id={`block-${modelBlock.id}`}
                                    onChange={(e) => {
                                        setEditedBlock({
                                            ...editedBlock,
                                            label: e.target.value,
                                        } as ModelBlock);
                                    }}
                                />
                            </div>
                            {!!isInteractive && (
                                <>
                                    <span className="text-xs font-light">
                                        {"Description"}
                                    </span>
                                    <textarea
                                        rows={1}
                                        className="block min-h-[54px] w-full min-w-[200px] rounded-lg border border-gray-300
                                            bg-gray-50 p-2 text-xs text-gray-700 focus:border-blue-500 focus:ring-blue-500"
                                        value={editedBlock.description ?? ""}
                                        onChange={(e) => {
                                            setEditedBlock({
                                                ...editedBlock,
                                                description: e.target.value,
                                            } as ModelBlock);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    {!modelBlock.has_connections && (
                        <BlockItemButton
                            onClick={() => {
                                if (isEditing) {
                                    SapienInertia.put(
                                        "admin.model-blocks.update",
                                        editedBlock,
                                        {},
                                        {
                                            preserveState: true,
                                            preserveScroll: true,
                                        },
                                    );
                                } else {
                                    setEditedBlock(modelBlock);
                                }
                                setIsEditing(!isEditing);
                            }}
                        >
                            {isEditing ? (
                                <CheckIcon className="h-3 w-3" />
                            ) : (
                                <PencilIcon className="h-3 w-3" />
                            )}
                        </BlockItemButton>
                    )}
                    {modelBlock !== undefined &&
                        (modelBlock.modelBlocks === undefined ||
                            modelBlock.modelBlocks.length === 0) &&
                        !modelBlock.model_variables_count &&
                        !modelBlock.has_connections && (
                            <BlockItemButton
                                onClick={() => {
                                    if (window.confirm("Delete model block?")) {
                                        SapienInertia.delete(
                                            sapienRoute(
                                                "admin.model-blocks.destroy",
                                                {
                                                    id: modelBlock.id,
                                                },
                                            ),
                                            { preserveScroll: true },
                                        );
                                    }
                                }}
                            >
                                <TrashIcon className="h-3 w-3" />
                            </BlockItemButton>
                        )}
                    {!modelBlock.has_connections &&
                        (!modelBlock.is_interactive ||
                            !modelBlock.parent_model_block_id) && (
                            <BlockItemButton
                                onClick={() =>
                                    SapienInertia.post(
                                        "admin.model-blocks.store",
                                        {
                                            label: "New Block",
                                            parent_model_block_id:
                                                modelBlock.id,
                                            simulation_id:
                                                modelBlock.simulation_id,
                                            weight:
                                                modelBlock?.modelBlocks
                                                    ?.length || 0,
                                            is_interactive:
                                                modelBlock.is_interactive,
                                        },
                                        {},
                                        {
                                            preserveState: true,
                                            preserveScroll: true,
                                        },
                                    )
                                }
                            >
                                <PlusIcon className="h-3 w-3" />
                            </BlockItemButton>
                        )}
                </div>
            </div>
            {modelBlock.modelBlocks && modelBlock.modelBlocks.length !== 0 && (
                <div className="pl-4">
                    {modelBlock.modelBlocks.map((child, i) => (
                        <ModelBlockDisplay
                            modelBlock={child}
                            key={child.id}
                            styleValue={styleValue ? styleValue + 1 : 1}
                            isInteractive={isInteractive}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const BlockItemButton: FC<{
    onClick?: () => void;
    children?: ReactNode;
}> = ({ onClick, children }) => {
    return (
        <button
            type="button"
            className="m-0.5 inline-flex items-center rounded-full bg-neutral-800 p-1 text-center
                text-sm font-medium text-neutral-100 hover:bg-neutral-700 focus:outline-none
                focus:ring-0"
            onClick={() => onClick()}
        >
            {children}
        </button>
    );
};
