import { sapienRoute } from "@/inertia-utils/hooks";
import { PromptWithRoundData } from "@/redux-state/modalComponentSlice";
import axios from "axios";

export async function getAllPromptsInSimulationByContentBlock(
    contentBlockId: string
) {
    if (!contentBlockId) {
        return Promise.resolve({ allPrompts: [] } as {
            allPrompts: PromptWithRoundData[];
        });
    }

    const route = sapienRoute("creator.design.questions.modal.show", {
        contentBlock: contentBlockId,
    });


    const { data } = await axios.get<{ allPrompts: PromptWithRoundData[] }>(route);
    return data;
}
