import React from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormInputDiv,
    EditorFormInputRadioCheckbox,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";

export const FormInputRadioArrayElement = (props: {
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    optionArray: Array<string | number>;
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    displayProperty?: (property: string | number) => string;
}) => {
    const {
        value,
        label,
        themeObjectKey,
        optionArray,
        handleChange,
        displayProperty,
    } = props;

    return (
        <EditorFormGroup style={{ alignItems: "flex-start" }}>
            <EditorFormLabel>{label}</EditorFormLabel>
            <div>
                {optionArray !== undefined &&
                    optionArray.map((option) => (
                        <EditorFormInputDiv key={option}>
                            <EditorFormLabel>
                                <EditorFormInputRadioCheckbox
                                    type="radio"
                                    value={option}
                                    checked={option === value}
                                    onChange={(e) =>
                                        handleChange(
                                            themeObjectKey,
                                            e.target.value,
                                        )
                                    }
                                />
                                {displayProperty !== undefined
                                    ? displayProperty(option)
                                    : option}
                            </EditorFormLabel>
                        </EditorFormInputDiv>
                    ))}
            </div>
        </EditorFormGroup>
    );
};
