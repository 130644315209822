import React from "react";
import { ScrollableWrapper } from "./WidgetWrappers";

const CenterPanelPageWrapperComponent = ({
    fullWidth,
    children,
    containerRef,
}: {
    fullWidth?: boolean;
    children: React.ReactNode;
    containerRef?: React.MutableRefObject<any>;
}) => {
    return (
        <ScrollableWrapper containerRef={containerRef}>
            <div className="interactive-center-panel h-full w-full transition-all">
                <div
                    className={`mx-auto my-0 w-full min-w-80 ${fullWidth ? "max-w-7xl" : "max-w-screen-2xl"}`}
                >
                    <div className="p-6">{children}</div>
                </div>
            </div>
        </ScrollableWrapper>
    );
};

export const CenterPanelPageWrapper = React.memo(
    CenterPanelPageWrapperComponent,
);
