import { ContentBlockShape, ContentBlockType } from "@/models";
import { defaultRichTextTheme } from "@/styles/themes/RichTextTheme";
import { cnToArray } from "@/util";
import { defaultRichText } from "../../../content-block-management/defaultRichtext";
import { ImageVariant } from "./ImageDropdown";

export function findHeading(
    contentBlock: ContentBlockShape,
): ContentBlockShape | void {
    for (let i = 0; i < contentBlock?.contentBlocks.length; i++) {
        const child = contentBlock?.contentBlocks[i];
        if (
            child?.contentBlocks?.some(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Rich Text"],
            )
        ) {
            return child.contentBlocks[0];
        }
        return findHeading(child);
    }
}

export function findParagraph(
    contentBlock: ContentBlockShape,
): ContentBlockShape | void {
    for (let i = 0; i < contentBlock?.contentBlocks.length; i++) {
        const child = contentBlock?.contentBlocks[i];
        if (
            child?.contentBlocks?.length > 1 &&
            child?.contentBlocks?.some(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Rich Text"],
            )
        ) {
            return child.contentBlocks[1];
        }
        return findParagraph(child);
    }
}

export function transformToParagraphWithHeading(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    const heading = findHeading(contentBlock);
    const paragraph = findParagraph(contentBlock);

    if (!heading || !paragraph) {
        console.log("No heading or paragraph found", { heading, paragraph });
        return contentBlock;
    }

    return {
        ...contentBlock,
        theme: {
            flexDirection: "row",
            tailwindClasses: [
                ...contentBlock.theme?.tailwindClasses,
                "justify-start",
                "items-center",
                "gap-4",
                "w-full",
            ],
            variant: "paragraph with heading",
        },
        contentBlocks: [
            {
                ...contentBlock.contentBlocks[0],
                weight: 0,
                theme: {
                    tailwindClasses: [
                        "flex",
                        "flex-col",
                        "justify-start",
                        "items-start",
                        "gap-4",
                        "p-4",
                        "w-full",
                    ],
                },
                contentBlocks: [
                    {
                        ...heading,
                        theme: {
                            ...heading.theme,
                            tailwindClasses:
                                heading.theme.tailwindClasses?.filter(
                                    (className) => className !== "hidden",
                                ),
                        },
                    },
                    {
                        ...heading,
                        theme: {
                            ...heading.theme,
                            tailwindClasses:
                                heading.theme.tailwindClasses?.filter(
                                    (className) => className !== "hidden",
                                ),
                        },
                    },
                ],
            },
            {
                ...contentBlock.contentBlocks[1],
                theme: {
                    ...contentBlock.contentBlocks[1].theme,
                    tailwindClasses: [
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-start",
                        "gap-4",
                        "p-4",
                        "hidden",
                    ],
                },
            },
        ],
    };
}

export const getWrappingBlocks = (contentBlock: ContentBlockShape) => {
    const wrappingBlocks = contentBlock.contentBlocks.filter(
        (child) =>
            child.content_block_type === ContentBlockType.Box &&
            child.contentBlocks?.some(
                (grandChild) =>
                    grandChild.content_block_type === ContentBlockType.Box,
            ),
    );

    return wrappingBlocks?.length > 0
        ? wrappingBlocks
        : [
              {
                  parent_content_block_id: contentBlock.id,
                  contentBlocks: contentBlock.contentBlocks,
                  content_block_type: ContentBlockType.Box,
                  theme: {} as any,
              },
          ];
};

export const getTextBoxBlock = (
    contentBlock: ContentBlockShape,
): ContentBlockShape => {
    const { round_id } = contentBlock;
    return (
        (contentBlock.contentBlocks.find(
            (block) =>
                block.content_block_type === ContentBlockType.Box &&
                block.contentBlocks?.some(
                    (block) =>
                        block.content_block_type ===
                        ContentBlockType["Rich Text"],
                ),
        ) as ContentBlockShape) || {
            round_id,
            content_block_type: ContentBlockType.Box,
            theme: {
                tailwindClasses: [
                    "p-0",
                    "flex",
                    "flex-col",
                    "justify-center",
                    "items-start",
                ],
            },
            contentBlocks: [
                {
                    round_id,
                    content_block_type: ContentBlockType["Rich Text"],
                    rich_text: defaultRichText,
                    weight: 0,
                    theme: {
                        ...defaultRichTextTheme,
                        width: "100%",
                    },
                },
            ],
        }
    );
};

export const getTextBoxBlocks = (contentBlock: ContentBlockShape) => {
    const textBlocks = contentBlock.contentBlocks.filter(
        (child) =>
            child.content_block_type === ContentBlockType.Box &&
            child.contentBlocks?.some(
                (grandChild) =>
                    grandChild.content_block_type ===
                    ContentBlockType["Rich Text"],
            ),
    );

    return textBlocks?.length > 0
        ? textBlocks
        : [getTextBoxBlock(contentBlock)];
};

export function getImageBlock(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    for (let i = 0; i < contentBlock.contentBlocks.length; i++) {
        const child = contentBlock.contentBlocks[i];
        if (child.content_block_type === ContentBlockType.Image) {
            return child;
        }
        return getImageBlock(child);
    }
}

export function getImageBoxBlock(contentBlock: ContentBlockShape) {
    const { round_id } = contentBlock;
    return (
        contentBlock.contentBlocks.find(
            (block) =>
                block.content_block_type === ContentBlockType.Box &&
                block.contentBlocks?.some(
                    (block) =>
                        block.content_block_type === ContentBlockType.Image,
                ),
        ) || {
            round_id,
            content_block_type: ContentBlockType.Box,
            theme: {
                tailwindClasses: [
                    "p-0",
                    "flex",
                    "flex-col",
                    "justify-center",
                    "items-start",
                ],
            },
            contentBlocks: [getImageBlock(contentBlock)],
        }
    );
}

export function transformToCenteredWithText(
    contentBlock: ContentBlockShape,
    newVariant: ImageVariant,
): ContentBlockShape {
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            variant: newVariant,
            backgroundImage: null,
            tailwindClasses: cnToArray([
                ...(contentBlock?.theme?.tailwindClasses || []),
                "justify-center",
                "items-center",
                "gap-4",
                "p-0",
                "sm:max-w-4xl",
                "lg:max-w-5xl",
            ]),
        },
        contentBlocks: getWrappingBlocks(contentBlock).map((block) => {
            const textBlock = getTextBoxBlock(block);
            const imageBoxBlock = getImageBoxBlock(block);
            imageBoxBlock.theme.tailwindClasses = [
                ...imageBoxBlock.theme.tailwindClasses,
                "hidden",
            ];

            return {
                ...block,
                theme: {
                    ...contentBlock.theme,
                    backgroundImage: null,
                    variant: newVariant,
                    tailwindClasses: cnToArray([
                        ...(contentBlock?.theme?.tailwindClasses || []),
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "p-0",
                        "w-full",
                    ]),
                },
                contentBlocks: [
                    { ...imageBoxBlock },
                    {
                        ...textBlock,
                        theme: {
                            ...textBlock.theme,
                            backgroundImage: `url(${imageBoxBlock.contentBlocks[0].image_url})`,
                            tailwindClasses: cnToArray([
                                ...(textBlock?.theme?.tailwindClasses || []),
                                "h-full",
                                "min-h-64",
                                "w-full",
                                "bg-cover",
                                "bg-no-repeat",
                                "bg-center",
                                "flex",
                                "flex-col",
                                "items-center",
                                "justify-center",
                            ]),
                        },
                    },
                ],
            };
        }),
    };
}
export function transformToCentered(
    contentBlock: ContentBlockShape,
    newVariant: ImageVariant,
): ContentBlockShape {
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            variant: newVariant,
            backgroundImage: null,
            tailwindClasses: cnToArray([
                ...(contentBlock?.theme?.tailwindClasses || []),
                "justify-center",
                "items-center",
                "gap-4",
                "p-0",
                "sm:max-w-4xl",
                "lg:max-w-5xl",
            ]),
        },
        contentBlocks: getWrappingBlocks(contentBlock).map((block) => {
            const imageBoxBlock = getImageBoxBlock(block);
            const textBlock = getTextBoxBlock(block);
            return {
                ...block,
                theme: {
                    ...contentBlock.theme,
                    variant: newVariant,
                    backgroundImage: null,
                    tailwindClasses: cnToArray([
                        ...(contentBlock?.theme?.tailwindClasses || []),
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "p-4",
                    ]),
                },
                contentBlocks: [
                    {
                        ...imageBoxBlock,
                        theme: {
                            ...imageBoxBlock.theme,
                            tailwindClasses: cnToArray([
                                ...(imageBoxBlock.theme.tailwindClasses?.filter(
                                    (className) =>
                                        !className.includes("p-") &&
                                        !className.includes("px-") &&
                                        !className.includes("py-") &&
                                        !className.includes("w-") &&
                                        !className.includes("h-") &&
                                        !className.includes("hidden"),
                                ) || []),
                                "w-full",
                                "p-0",
                                "min-h-32",
                            ]),
                        },
                    },
                    {
                        ...textBlock,
                        theme: {
                            ...textBlock.theme,
                            tailwindClasses: cnToArray([
                                ...textBlock.theme.tailwindClasses,
                                "hidden",
                            ]),
                        },
                    },
                ],
            };
        }),
    };
}

export function transformToFullWidthImage(
    contentBlock: ContentBlockShape,
    showText: boolean = false,
): ContentBlockShape {
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            variant: showText
                ? "full width image with text"
                : "full width image",
            backgroundImage: null,
            tailwindClasses: cnToArray([
                ...(contentBlock?.theme?.tailwindClasses || []),
                "justify-center",
                "items-center",
                "gap-4",
                "bg-cover",
                "bg-no-repeat",
                "bg-center",
                "max-w-none",
                "sm:max-w-none",
                "lg:max-w-none",
                "min-h-96",
                "p-0",
            ]),
        },
        contentBlocks: getWrappingBlocks(contentBlock).map((block) => {
            const textBlock = getTextBoxBlock(block);
            const imageBoxBlock = getImageBoxBlock(block);
            imageBoxBlock.theme.tailwindClasses = [
                ...(imageBoxBlock.theme.tailwindClasses || []),
                "hidden",
            ];
            textBlock.theme.backgroundImage = null;
            textBlock.theme.tailwindClasses = showText
                ? [
                      ...(textBlock.theme.tailwindClasses?.filter(
                          (className) => className !== "hidden",
                      ) || []),
                      "w-full",
                      "max-w-4xl",
                      "p-4",
                      "flex",
                      "flex-col",
                      "justify-center",
                      "items-center",
                      "gap-4",
                  ]
                : [
                      ...(textBlock.theme.tailwindClasses?.filter(
                          (className) => className !== "hidden",
                      ) || []),
                      "hidden",
                  ];

            return {
                ...block,
                contentBlocks: [imageBoxBlock, textBlock],
                theme: {
                    ...contentBlock.theme,
                    variant: showText
                        ? "full width image with text"
                        : "full width image",
                    backgroundImage: `url(${imageBoxBlock.contentBlocks[0].image_url})`,
                    tailwindClasses: cnToArray([
                        ...(contentBlock?.theme?.tailwindClasses || []),
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-center",
                        "gap-4",
                        "bg-cover",
                        "bg-no-repeat",
                        "bg-center",
                        "max-w-none",
                        "sm:max-w-none",
                        "lg:max-w-none",
                        "min-h-96",
                        "p-0",
                        "w-full",
                    ]),
                },
            };
        }),
    };
}

export function transformToImage(
    contentBlock: ContentBlockShape,
    imageSide: "left" | "right",
): ContentBlockShape {
    return {
        ...contentBlock,
        contentBlocks: getWrappingBlocks(contentBlock).map((block) => {
            const textBlock = getTextBoxBlock(block);
            const imageBox = getImageBoxBlock(block);
            return {
                ...block,
                theme: {
                    ...contentBlock.theme,
                    tailwindClasses: [
                        ...(contentBlock.theme?.tailwindClasses || []),
                        "grid",
                        "grid-cols-2",
                        "gap-4",
                        "p-4",
                        "w-full",
                    ],
                    backgroundImage: null,
                    variant: `image on ${imageSide}`,
                },
                contentBlocks: [
                    {
                        ...imageBox,
                        theme: {
                            ...imageBox.theme,
                            tailwindClasses: [
                                ...(
                                    imageBox.theme.tailwindClasses || []
                                ).filter((className) => className !== "hidden"),
                                imageSide === "left"
                                    ? "order-first"
                                    : "order-last",
                            ],
                        },
                    },
                    {
                        ...textBlock,
                        theme: {
                            ...textBlock.theme,
                            tailwindClasses: [
                                ...(
                                    textBlock.theme.tailwindClasses || []
                                ).filter((className) => className !== "hidden"),
                                imageSide === "left"
                                    ? "order-last"
                                    : "order-first",
                            ],
                        },
                    },
                ],
            };
        }),
        theme: {
            ...contentBlock.theme,
            tailwindClasses: [
                ...(contentBlock.theme?.tailwindClasses || []),
                "w-full",
            ],
            backgroundImage: null,
            variant: `image on ${imageSide}`,
        },
    };
}

export function transformToTextWithoutImage(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    return {
        ...contentBlock,
        contentBlocks: getWrappingBlocks(contentBlock).map((block) => {
            const richTextBlock = getTextBoxBlock(block);
            const imageBlock = getImageBoxBlock(block);
            return {
                ...block,
                theme: {
                    ...contentBlock.theme,
                    tailwindClasses: cnToArray([
                        ...(contentBlock.theme?.tailwindClasses || []),
                        "flex",
                        "flex-row",
                        "justify-center",
                        "items-center",
                        "gap-4",
                    ]),
                    backgroundImage: null,
                    variant: "text without image",
                },
                contentBlocks: [
                    {
                        ...imageBlock,
                        theme: {
                            ...imageBlock?.theme,
                            tailwindClasses: [
                                cnToArray([
                                    ...(imageBlock?.theme?.tailwindClasses ||
                                        []),
                                    "hidden",
                                ]),
                            ],
                        },
                    },
                    {
                        ...richTextBlock,
                        weight: 0,
                        theme: {
                            tailwindClasses: cnToArray([
                                ...(richTextBlock.theme?.taiwindClasses || []),
                                "flex",
                                "flex-col",
                                "justify-start",
                                "items-start",
                                "gap-4",
                                "p-4",
                                "w-full",
                                "max-w-4xl",
                            ]),
                        },
                    },
                ],
            };
        }),
        theme: {
            ...contentBlock.theme,
            tailwindClasses: cnToArray([
                ...(contentBlock.theme?.tailwindClasses || []),
                "justify-center",
                "items-center",
                "gap-4",
            ]),
            backgroundImage: null,
            variant: "text without image",
        },
    };
}

export function textIsShown(contentBlock: ContentBlockShape): boolean {
    const textBox = getTextBoxBlock(contentBlock);
    return textBox.theme.tailwindClasses.includes("hidden") ? false : true;
}

export function setTextIsShown(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    const textBox = getTextBoxBlock(contentBlock);
    if (!textIsShown(contentBlock)) {
        textBox.theme.tailwindClasses = textBox.theme.tailwindClasses.filter(
            (className) => className !== "hidden",
        );
    } else {
        textBox.theme.tailwindClasses.push("hidden");
    }
    return contentBlock;
}
