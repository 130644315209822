import React, { useMemo } from "react";
import {
    useSelectedRoundId,
    useSelectedSimulation,
    useFindContentBlockMethods,
} from "@/hooks";
import { sapienRoute } from "@/inertia-utils/hooks";
import { Prompt, PromptType } from "@/models";
import { EyeIcon } from "@heroicons/react/24/solid";
import { Link } from "@inertiajs/react";
import { createPortal } from "react-dom";

type Props = {
    prompt: Prompt;
};

export default function OptionConditionalDisplayTrigger({ prompt }: Props) {
    const { selectedSimulation } = useSelectedSimulation();
    const { selectedRoundId } = useSelectedRoundId();
    const { getContentBlock } = useFindContentBlockMethods();

    const optionsWithDisplayRules = useMemo(
        () =>
            prompt.options
                .filter(
                    (option) =>
                        !!option.content_block_id &&
                        getContentBlock(option.content_block_id)?.displayRule
                )
                .map((option) => option.id),
        [prompt]
    );

    return (
        <>
            {prompt.options
                .filter((option) => !!option.content_block_id)
                .map((option) => {
                    const optionElement = document.querySelector(
                        `[data-option-id="${option.id}"]`
                    ) as HTMLInputElement;

                    const element =
                        prompt.prompt_type === PromptType["Drag and Drop"]
                            ? optionElement
                            : optionElement?.parentElement;

                    if (element) {
                        element.style.position = "relative";

                        if (
                            prompt.prompt_type === PromptType["Drag and Drop"]
                        ) {
                            return createPortal(
                                <Link
                                    href={sapienRoute(
                                        "admin.design.display-rules.show",
                                        {
                                            simulationSlug:
                                                selectedSimulation.id,
                                            roundId: selectedRoundId,
                                            contentBlockId:
                                                option.content_block_id,
                                        }
                                    )}
                                    className="absolute -top-2 -right-6 rounded-full bg-white shadow p-2 cursor-pointer opacity-50 hover:opacity-100"
                                >
                                    <EyeIcon
                                        className={`h-4 w-4  ${
                                            optionsWithDisplayRules.includes(
                                                option.id
                                            )
                                                ? "text-green-600"
                                                : "text-blue-600"
                                        }`}
                                    />
                                </Link>,
                                element
                            );
                        }

                        return createPortal(
                            <Link
                                href={sapienRoute(
                                    "admin.design.display-rules.show",
                                    {
                                        simulationSlug: selectedSimulation.id,
                                        roundId: selectedRoundId,
                                        contentBlockId: option.content_block_id,
                                    }
                                )}
                                className="absolute top-1/2 right-2 -translate-y-1/2 rounded-full bg-white shadow p-2 cursor-pointer"
                            >
                                <EyeIcon
                                    className={`h-5 w-5  ${
                                        optionsWithDisplayRules.includes(
                                            option.id
                                        )
                                            ? "text-green-600"
                                            : "text-blue-600"
                                    }`}
                                />
                            </Link>,
                            element
                        );
                    }

                    return null;
                })}
        </>
    );
}
