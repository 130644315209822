import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ModelVariable } from "@/models";


export function useDynamicContentBlockQueries() {
    const getModelVariables = async (pageId: string) => {
        if (!pageId) {
            return Promise.resolve({ modelVariablesForDisplayRules: [] } as {
                modelVariablesForDisplayRules: ModelVariable[];
            });
        }
        const route = sapienRoute(
            "creator.design.dynamic-content.model-variables",
            {
                pageId,
            }
        );

        const { data } = await axios.get<{
            modelVariablesForDisplayRules: ModelVariable[];
        }>(route, { timeout: 10000 });

        const { modelVariablesForDisplayRules } = data;
        return { modelVariablesForDisplayRules };
    };

    return {
        getModelVariables,
    };
}
