import { getIsCollectionType, createChildObject } from "@/model-configs";
import React, { ReactNode, useMemo, useState } from "react";
import {
    useParticipantStore,
    useParticipantBlockFormProps,
    useIsSubmitting,
} from "../redux-state/hooks";
import { ContentBlock, ContentBlockShape, ContentBlockType } from "@/models";
import { ContentBlockDisplay } from "@/Pages/Admin/editor/blocks";
import { FieldValues } from "react-hook-form";
import { useSelectedSimulation } from "@/hooks";
import { sapienRoute } from "@/inertia-utils/hooks";
import { useReflectionJournalEntryStore } from "@/hooks/store/useReflectionJournalEntryStore";

type Props = {
    contentBlock: ContentBlockShape;
    indexWithinParent: number;
    children?: ReactNode;
    childObject?: { [index: string]: string };
    onChange?: (formState: FieldValues) => void;
};

export function NestedContentBlockDisplay({
    contentBlock,
    children,
    indexWithinParent,
}: Props) {
    const {
        selectContentBlockChildren,
        selectTableDataByContentBlockId,
        simulationTheme,
        selectTableRowsFromTable,
    } = useParticipantStore();

    const contentBlocks =
        contentBlock.content_block_type === ContentBlockType.Table
            ? selectTableRowsFromTable(contentBlock.id)
            : selectContentBlockChildren(contentBlock.id);

    const tableData = selectTableDataByContentBlockId(contentBlock.id);

    const filledBlock = { ...contentBlock, ...{ contentBlocks } };

    const formProps = useParticipantBlockFormProps(contentBlock.id);
    const { isSubmitting, isValid } = useIsSubmitting();

    const [activeChildIndex, setActiveChildIndex] = useState<number>(0);
    const { selectedSimulation } = useSelectedSimulation();

    const { reflectionJournalEntries } = useReflectionJournalEntryStore();

    const homeRoute = useMemo(() => {
        if (selectedSimulation)
            return sapienRoute("sim-content", {
                simulationSlug: selectedSimulation.slug,
            });
    }, [selectedSimulation]);

    const optionRenderMap = useMemo(() => {
        return filledBlock.prompt
            ? filledBlock.prompt.options
                  .filter((option) => !!option.contentBlock)
                  .reduce((map, option) => {
                      return {
                          ...map,
                          [option.id]: (
                              <NestedContentBlockDisplay
                                  contentBlock={option.contentBlock}
                                  indexWithinParent={0}
                              />
                          ),
                      };
                  }, {})
            : {};
    }, [filledBlock]);

    const modalChildRenderMap = useMemo(() => {
        return filledBlock.content_block_type === ContentBlockType.Modal
            ? (contentBlocks as ContentBlockShape[]).reduce(
                  (map, contentBlock, i) => {
                      return {
                          ...map,
                          [contentBlock.id]: (
                              <NestedContentBlockDisplay
                                  contentBlock={contentBlock}
                                  indexWithinParent={i}
                              />
                          ),
                      };
                  },
                  {}
              )
            : {};
    }, [contentBlocks]);

    // const childBlocksMap = useMemo(() => {
    //     return contentBlocks;
    // }, [contentBlocks]);

    return contentBlocks && contentBlocks.length ? (
        <>
            <ContentBlockDisplay
                simulationTheme={simulationTheme}
                key={contentBlock.id}
                contentBlock={filledBlock as ContentBlock}
                childObject={
                    getIsCollectionType(contentBlock.content_block_type) &&
                    contentBlocks.length
                        ? createChildObject({
                              ...contentBlock,
                              ...{
                                  contentBlocks: contentBlocks,
                              },
                          } as ContentBlock)
                        : {}
                }
                isSelected={false}
                isEditable={false}
                isInEditorContext={false}
                tableData={tableData}
                activeChildIndex={activeChildIndex}
                onClick={(_) => {}}
                onHover={(_) => {}}
                formProps={{ ...formProps, isSubmitting }}
                childBlocks={contentBlocks}
                homeRoute={homeRoute}
                renderMap={
                    Object.keys(modalChildRenderMap).length
                        ? modalChildRenderMap
                        : optionRenderMap
                }
                reflectionJournalEntries={reflectionJournalEntries}
            >
                {contentBlocks.map((child, childIndex) => (
                    <NestedContentBlockDisplay
                        key={child.id}
                        contentBlock={child}
                        indexWithinParent={childIndex}
                    />
                ))}
            </ContentBlockDisplay>
        </>
    ) : (
        <ContentBlockDisplay
            contentBlock={filledBlock as ContentBlock}
            isSelected={false}
            isEditable={false}
            isInEditorContext={false}
            tableData={tableData}
            simulationTheme={simulationTheme}
            activeChildIndex={activeChildIndex}
            onClick={(_) => {}}
            onHover={(_) => {}}
            formProps={{ ...formProps, isSubmitting, isValid }}
            homeRoute={homeRoute}
            renderMap={
                Object.keys(modalChildRenderMap).length
                    ? modalChildRenderMap
                    : optionRenderMap
            }
            reflectionJournalEntries={reflectionJournalEntries}
        />
    );
}
