import React from "react";

export const DiscoveryMenuItemCheckbox = ({
    itemKey,
    label,
    checked,
    handleOnChange,
    disabled,
    inputType = "checkbox",
    subLabel,
}: {
    itemKey: string;
    label: string;
    checked: boolean;
    handleOnChange: () => void;
    disabled?: boolean;
    inputType?: "checkbox" | "radio";
    subLabel?: string;
}) => {
    return (
        <label
            htmlFor={itemKey}
            className="flex w-full items-center justify-between text-sm"
        >
            <span>
                <input
                    id={itemKey}
                    type={inputType}
                    checked={checked}
                    className={`mr-2 h-4 w-4 border-gray-300 bg-gray-100 ${
                        inputType === "checkbox" && "rounded"
                    } text-primary-600 focus:ring-primary-500 focus:ring-2`}
                    onChange={() => {
                        handleOnChange();
                    }}
                    disabled={disabled}
                />
                {label}
            </span>
            {!!subLabel && <span className="pl-2 text-xs">{subLabel}</span>}
        </label>
    );
};
