import React from "react";
import { useFormatVariableValue } from "@/hooks";
import { CarouselCardWrapper, DefaultTableWrapper } from "../shared";
import { DefaultVariableDataTableRow } from "./DefaultVariableDataTableRow";
import { DataTableProps } from "./props";

const CarouselCardDataTableComponent = ({
    modelBlock,
    modelVariables,
    valuesMap,
    showResampleFunction,
}: DataTableProps) => {
    const formatVariableValue = useFormatVariableValue();

    return (
        <CarouselCardWrapper modelBlockLabel={modelBlock.label}>
            <div>
                {!!modelBlock.description?.length &&
                    modelVariables[modelBlock.id]?.some(
                        (variable) => variable.is_key_metric,
                    ) && (
                        <div className="px-4 pt-4 text-slate-600 transition-all dark:text-slate-400">
                            {modelBlock.description}
                        </div>
                    )}
                <DefaultTableWrapper
                    header={"variable"}
                    columnLabels={
                        Object.keys(valuesMap)?.length > 0
                            ? Object.values(valuesMap)[0]?.map((_, index) =>
                                  (index + 1).toString(),
                              )
                            : []
                    }
                >
                    {modelVariables[modelBlock.id]?.map((modelVariable, i) => (
                        <DefaultVariableDataTableRow
                            key={modelVariable.id}
                            modelVariable={modelVariable}
                            variableValues={
                                (Object.keys(valuesMap)?.length > 0 &&
                                    valuesMap[modelVariable.id]) ||
                                []
                            }
                            index={i}
                            showResampleFunction={showResampleFunction}
                            formatVariableValue={formatVariableValue}
                        />
                    ))}
                </DefaultTableWrapper>
            </div>
        </CarouselCardWrapper>
    );
};

export const CarouselCardDataTable = React.memo(CarouselCardDataTableComponent);
