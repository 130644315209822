import { ContentBlockTemplateShape } from "@/models";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import React from "react";
import ContentBlockTemplateCard from "./ContentBlockTemplateCard";

type Props = {
    templates: ContentBlockTemplateShape[];
    category: string;
    handleClickAdd: (template: ContentBlockTemplateShape) => void;
    parentContentBlockId?: string;
    emptyLabel: string;
    selectedTemplate?: ContentBlockTemplateShape;
};

export default function TemplateMapCategoryDisplay({
    templates,
    category,
    handleClickAdd,
    parentContentBlockId,
    emptyLabel,
    selectedTemplate,
}: Props) {
    return (
        <div key={category} className="flex flex-col space-y-2 p-4">
            <h3 className="text-white font-bold">{category}</h3>
            {!templates.length && (
                <div className="relative opacity-50 flex flex-col items-center justify-center w-full rounded-lg text-white/70 p-6 text-center">
                    <DocumentDuplicateIcon className="w-16 h-16" />
                    <span className="mt-2 block text-sm font-semibold">
                        {emptyLabel ||
                            "There aren't any templates in this category."}
                    </span>
                </div>
            )}
            <div className="grid lg:grid-cols-3 grid-cols-2 gap-4">
                {templates.map((template: ContentBlockTemplateShape) => (
                    <React.Fragment key={`${category}_${template.id}`}>
                        <ContentBlockTemplateCard
                            key={template.id}
                            template={template}
                            isSelected={template.id === selectedTemplate?.id}
                            handleClickAdd={handleClickAdd}
                            parentContentBlockId={parentContentBlockId}
                            colorClasses={{
                                selected:
                                    "bg-white shadow-md border-2 border-blue-600",
                                unselected:
                                    "bg-white/80 border-2 border-white/80 hover:bg-white hover:border-white ",
                            }}
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
