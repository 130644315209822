export enum TemplateCategory {
    "Design" = "DESIGN",
    "Content" = "CONTENT",
    "Activity" = "ACTIVITY",
    "Module" = "MODULE",
}

export enum TemplateType {
    // modules
    // "Alignment",
    "Resource Allocation" = "RESOURCE_ALLOCATION",
    // "Element Ranking",
    "Strategic Path" = "STRATEGIC_PATH",
    // "Innovation Sequence",
    // "Investment Decisions",
    // "Vision and Mission",
    // "Metric Assessments",

    // activities
    "Trends Assessment" = "TRENDS_ASSESSMENT",
    // "Quiz",
    // "SWOT Analysis",
    // "Insights",
    // "Poll",
    // "Present and Defend",
    // "Confidence Ratings",
    "Event Decision" = "EVENT_DECISION",

    // content
    // "Profiles",
    // "Memo",
    "Organization Overview" = "ORGANIZATION_OVERVIEW",
    // "News Bulletin",

    // design
    "Welcome Page" = "WELCOME_PAGE",
    // "Feature List",
    // "Gallery",
    // "Dashboard",
}
