import React, { useCallback } from "react";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { Lesson, Course } from "../types";
import { Auth } from "@/inertia-utils/types";
import AuthenticatedHeader from "../shared/AuthenticatedHeader";
import { Link } from "@inertiajs/react";

type Props = {
    lesson: Lesson;
    course: Course;
    auth: Auth;
    children: React.ReactNode | React.ReactNode[];
};

export default function LessonDesignHeader({
    lesson,
    course,
    auth,
    children,
}: Props) {
    const isPublished = !!course.courseMetadata?.published_at;

    const handlePublish = useCallback(() => {
        SapienInertia.put("e-learning.course.publish", {
            course_id: course.id,
            publish: !isPublished,
        });
    }, [course]);

    return (
        <AuthenticatedHeader auth={auth}>
            <div className="flex w-full items-center justify-between gap-4">
                <div className="flex w-full flex-1 items-center gap-4">
                    {children}
                </div>
                <div className="flex items-center gap-2">
                    {isPublished ? (
                        <button
                            className="rounded-md border border-slate-500 bg-sapien-blue px-4 py-2 text-white
                                transition-colors hover:bg-white hover:text-sapien-blue"
                            onClick={handlePublish}
                        >
                            Unpublish
                        </button>
                    ) : (
                        <button
                            className="rounded-md border border-slate-500 bg-white px-4 py-2 text-sapien-blue
                                transition-colors hover:bg-sapien-blue hover:text-white"
                            onClick={handlePublish}
                        >
                            Publish
                        </button>
                    )}
                </div>
            </div>
        </AuthenticatedHeader>
    );
}
