import { ContentBlockShape } from "@/models";
import { ImageVariant } from "./ImageDropdown";
import {
    transformToCentered,
    transformToCenteredWithText,
    transformToFullWidthImage,
    transformToImage,
    transformToTextWithoutImage,
} from "./transformFunctions";

export const variants = [
    "centered image without text",
    "text over centered image",
    "full width image without text",
    "full width image with text",
    "image on left of text",
    "image on right of text",
    "text without image",
] as const;

export function transformImageBlock(
    contentBlock: ContentBlockShape,
    newVariant: ImageVariant,
): ContentBlockShape {
    switch (newVariant) {
        case "centered image without text":
            return transformToCentered(contentBlock, newVariant);
        case "text over centered image":
            return transformToCenteredWithText(contentBlock, newVariant);
        case "full width image without text":
            console.log(contentBlock);
            return transformToFullWidthImage(contentBlock, false);
        case "full width image with text":
            return transformToFullWidthImage(contentBlock, true);
        case "image on left of text":
            return transformToImage(contentBlock, "left");
        case "image on right of text":
            return transformToImage(contentBlock, "right");
        case "text without image":
            return transformToTextWithoutImage(contentBlock);
        default:
            return contentBlock;
    }
}
