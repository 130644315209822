import React from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const ShowHideButtonComponent = ({
    show,
    setShow,
    label,
}: {
    show: boolean;
    setShow: (show: boolean) => void;
    label: string;
}) => {
    return (
        <button
            type="button"
            className={`inline-flex items-center rounded-md border p-1 text-center text-xs font-normal
            transition-all hover:bg-slate-50 focus:outline-none focus:ring-0
            dark:hover:bg-slate-800/50 ${
                show
                    ? "border-blue-700 text-blue-700"
                    : "border-slate-300 text-slate-500 dark:border-slate-500 dark:text-slate-300"
            }`}
            onClick={(e) => {
                e.stopPropagation();
                setShow(!show);
            }}
        >
            {show ? (
                <EyeIcon className="mr-1 h-4 w-4" />
            ) : (
                <EyeSlashIcon className="mr-1 h-4 w-4" />
            )}
            {label}
        </button>
    );
};

export const ShowHideButton = React.memo(ShowHideButtonComponent);
