import React from "react";
import { FormInputColorPicker } from "@/styles/editor-components";
import { useColorStore } from "@/hooks/store";
import { EditableChartSeries } from "./useChartState";

type Props = {
    updateEditedSeries: (color: string) => void;
    color: string;
    index: number;
    label: string;
};

export default function ChartSeriesColorForm({
    color,
    index,
    updateEditedSeries,
    label,
}: Props) {
    const { colorGroups } = useColorStore();

    return (
        <>
            <label className="capitalize">{label} Color</label>
            <div className="w-full flex space-x-4 items-stretch">
                <div className="w-full flex items-stretch px-4 pb-2 rounded-md border border-[#374151]">
                    <FormInputColorPicker
                        value={
                            color ||
                            colorGroups[0].colors[index].css_color ||
                            "#000000"
                        }
                        handleChange={(_, color: string) => updateEditedSeries(color)}
                        label={""}
                        themeObjectKey={""}
                        hideOpacity={false}
                    />
                </div>
            </div>
        </>
    );
}
