import React, { FC, ReactNode } from "react";
import {
    Table,
    TableContainer,
    TableStickyLeftCell,
    TablePaddedCell
} from "../../components";

export const ScrollableTableHeaderEntry: FC<{
    children?: ReactNode;
}> = ({ children }) => {
    return (
        <TableStickyLeftCell
            className={`table-col`}
            style={{ maxWidth: "250px" }}
        >
            {children}
        </TableStickyLeftCell>
    );
};

export const ScrollableTableEntry: FC<{
    centerText?: boolean;
    children?: ReactNode;
}> = ({ centerText, children }) => {
    return (
        <TablePaddedCell
            className={`table-col`}
            style={{
                textAlign: centerText ? "center" : "right"
            }}
        >
            {children}
        </TablePaddedCell>
    );
};

export const ScrollableTableRow: FC<{
    children?: ReactNode;
}> = ({ children }) => {
    return <tr className={`table-row`}>{children}</tr>;
};

export const ScrollableTable: FC<{
    fullWidth?: boolean;
    children?: ReactNode;
}> = ({ fullWidth, children }) => {
    return (
        <TableContainer>
            <Table
                className={`table vertical-striped v-gray table-striped h-blue min-w-[300px]`}
                style={{ width: `${fullWidth ? "100%" : ""}` }}
            >
                <tbody>{children}</tbody>
            </Table>
        </TableContainer>
    );
};
