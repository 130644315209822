import React, { useCallback, useMemo } from "react";
import { AsideContents } from "../../Aside";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { debounce } from "@/hooks";
import { useEditedContentBlock } from "../../useEditedContentBlock";
import { useGetContentBlockWithChildren } from "../../../useContentBlockState";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import { ChartType } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import ColorSelector from "@/components/rich-text/ColorSelector";
import LineChartForm from "./LineChartForm";

export function getChartType(formattedChartValues: FormattedChartValue[]): ChartType {
    const chartTypes = formattedChartValues.reduce(
        (carry: ChartType[], value) => {
            return [...carry, value.chartType];
        },
        [],
    );

    if (chartTypes.some((type) => type === "pie" || type === "doughnut")) {
        return chartTypes.every((type) => type === "doughnut")
            ? "doughnut"
            : "pie";
    }

    if (chartTypes.every((type) => type === "bar")) return "bar";

    //multi-mode charts should render as area or line
    return chartTypes.some((type) => type === "area") ? "area" : "line";
}

export type ChartContentBlockWithObjectData = ContentBlockShape & {
    chart_data: { [index: string]: FormattedChartValue[] };
};

export default function EditchartSidebar() {
    const { editedContentBlock } = useEditedContentBlock();
    const editedContentBlockWithChildren = useGetContentBlockWithChildren(
        editedContentBlock?.id,
    );
    const { mutate, isPending } = useSaveContentBlock();

    if (!editedContentBlockWithChildren) return null;

    const save = useCallback(debounce(mutate, 1000), [
        editedContentBlockWithChildren,
    ]);
    const update = useCallback(
        debounce((contentBlock: ContentBlockShape) => {
            // setContentBlock(contentBlock);
            save(contentBlock);
        }, 500),
        [editedContentBlockWithChildren],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        update(newBlock);
    }, []);

    const chart = editedContentBlockWithChildren?.contentBlocks?.find(
        (block) =>
            block.content_block_type === ContentBlockType["Chart Container"],
    ) as ContentBlockShape & { chart_data: FormattedChartValue[] };

    const chartType = useMemo(() => {
        if (!!chart?.chart_data && !Array.isArray(chart.chart_data)) {
            return "line";
        }
        return getChartType(chart?.chart_data);
    }, [editedContentBlockWithChildren]);
    return (
        <AsideContents title="Chart">
            <div className="relative flex flex-col divide-y-2">
                {!!isPending && (
                    <div className="absolute inset-0 animate-pulse bg-white/50">
                        {" "}
                    </div>
                )}
                {(chartType === "line" || chartType === "area") && (
                    <LineChartForm
                        chart={chart as ChartContentBlockWithObjectData}
                        handleChange={(chart) => handleChange({
                            ...editedContentBlockWithChildren,
                            contentBlocks: [chart],
                        })}
                    />
                )}
                {(chartType === "bar" || chartType === "pie") && (
                    <div className="flex flex-col space-y-1">
                        {chart.chart_data.map((data, index) => (
                            <div
                                className="flex justify-between p-2"
                                key={data.label}
                            >
                                <div className="flex space-x-2">
                                    <input
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                                            ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Label"
                                        defaultValue={data.label}
                                        onChange={(e) => {
                                            handleChange({
                                                ...editedContentBlockWithChildren,
                                                contentBlocks: [
                                                    {
                                                        ...chart,
                                                        chart_data:
                                                            chart.chart_data.map(
                                                                (d, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...d,
                                                                              label: e
                                                                                  .target
                                                                                  .value,
                                                                              displayText: `${e.target.value} (${data.y})`,
                                                                          }
                                                                        : d,
                                                            ),
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                    <input
                                        className="block w-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                                            ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Label"
                                        defaultValue={data.y}
                                        onChange={(e) => {
                                            handleChange({
                                                ...editedContentBlockWithChildren,
                                                contentBlocks: [
                                                    {
                                                        ...chart,
                                                        chart_data:
                                                            chart.chart_data.map(
                                                                (d, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...d,
                                                                              y: Number(
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                              ),
                                                                              displayText: `${data.label} (${e.target.value})`,
                                                                          }
                                                                        : d,
                                                            ),
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                    <div className="flex items-center">
                                        <ColorSelector
                                            selectedColor={data.theme.fill}
                                            selectColor={(fill) => {
                                                handleChange({
                                                    ...editedContentBlockWithChildren,
                                                    contentBlocks: [
                                                        {
                                                            ...chart,
                                                            chart_data:
                                                                chart.chart_data.map(
                                                                    (d, i) =>
                                                                        i ===
                                                                        index
                                                                            ? {
                                                                                  ...d,
                                                                                  theme: {
                                                                                      ...data.theme,
                                                                                      fill,
                                                                                  },
                                                                              }
                                                                            : d,
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <button
                                    disabled={isPending}
                                    className="rounded-md border border-red-500 p-2 text-red-500 transition-colors
                                        hover:bg-red-500 hover:text-white"
                                    onClick={() => {
                                        handleChange({
                                            ...editedContentBlockWithChildren,
                                            contentBlocks: [
                                                {
                                                    ...chart,
                                                    chart_data:
                                                        chart.chart_data.filter(
                                                            (_, i) =>
                                                                i !== index,
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                        <div className="mt-4">
                            <button
                                className="w-full rounded-md border border-sapien-blue bg-white p-4 text-sapien-blue
                                    transition-colors hover:bg-sapien-blue hover:text-white"
                                onClick={() => {
                                    handleChange({
                                        ...editedContentBlockWithChildren,
                                        contentBlocks: [
                                            {
                                                ...chart,
                                                chart_data: [
                                                    ...chart.chart_data,
                                                    {
                                                        y: 8,
                                                        x:
                                                            chart.chart_data
                                                                ?.length + 1 ||
                                                            1,
                                                        theme: {
                                                            fill: "#000000",
                                                        },
                                                        label: `Category ${chart.chart_data?.length + 1 || 1}`,
                                                        displayText: `Category ${chart.chart_data?.length + 1 || 1} (8)`,
                                                        chartType,
                                                    },
                                                ],
                                            },
                                        ],
                                    });
                                }}
                            >
                                Add Data
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </AsideContents>
    );
}
