import { ContentBlockShape } from "@/models";
import { EditorFormInputRange } from "@/styles/editor-components/StyledEditorComponents";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    handleChange: (contentBlock: ContentBlockShape) => void;
};

const verticalPaddingClassNames = [
    "py-0",
    "py-1",
    "py-2",
    "py-3",
    "py-4",
    "py-5",
    "py-6",
    "py-8",
    "py-10",
    "py-12",
    "py-16",
    "py-20",
    "py-24",
    "py-32",
    "py-40",
    "py-48",
    "py-56",
    "py-64",
    "py-72",
    "py-80",
    "py-96",
];

const horizontalPaddingClassNames = [
    "px-0",
    "px-1",
    "px-2",
    "px-2 lg:px-3",
    "sm:px-3 lg:px-4",
    "px-4 lg:px-5",
    "px-5 lg:px-6",
    "xl:px-3 sm:px-4 md:px-5 lg:px-6 xl:px-8",
    "xs:px-4 sm:px-5 md:px-6 lg:px-8 xl:px-10",
    "xs:px-5 sm:px-6 md:px-8 lg:px-10 xl:px-12",
    "xs:px-6 sm:px-8 md:px-10 lg:px-12 xl:px-16",
    "xs:px-8 sm:px-10 md:px-12 lg:px-16 xl:px-20",
    "xs:px-10 sm:px-12 md:px-16 lg:px-20 xl:px-24",
    "xs:px-12 sm:px-16 md:px-20 lg:px-24 xl:px-32",
    "xs:px-16 sm:px-20 md:px-24 lg:px-32 xl:px-40",
    "xs:px-20 sm:px-24 md:px-32 lg:px-40 xl:px-48",
    "xs:px-24 sm:px-32 md:px-40 lg:px-48 xl:px-56",
    "xs:px-32 sm:px-40 md:px-48 lg:px-56 xl:px-64",
    "xs:px-40 sm:px-48 md:px-56 lg:px-64 xl:px-72",
    "xs:px-48 sm:px-56 md:px-64 lg:px-72 xl:px-80",
    "xs:px-56 sm:px-64 md:px-72 lg:px-80 xl:px-96",
];

export default function PaddingSlider({ contentBlock, handleChange }: Props) {
    const verticalClassname = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("py-"),
    );
    const horizontalClassname = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("px-"),
    );
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <label>Vertical Padding</label>
                <EditorFormInputRange
                className="w-full text-sapien-blue"
                    type="range"
                    defaultValue={
                        !!verticalClassname
                            ? verticalPaddingClassNames.indexOf(
                                  verticalClassname,
                              ) || 0
                            : 0
                    }
                    onChange={(e) => {
                        handleChange({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...contentBlock.theme.tailwindClasses?.filter(
                                        (className: string) =>
                                            !className.includes("py-"),
                                    ) || [],
                                    verticalPaddingClassNames[e.target.value],
                                ],
                            },
                        });
                    }}
                    min={0}
                    max={verticalPaddingClassNames.length - 1}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label>Horizontal Padding</label>
                <EditorFormInputRange
                    type="range"
                    defaultValue={
                        !!horizontalClassname
                            ? horizontalPaddingClassNames.indexOf(
                                  horizontalClassname,
                              ) || 0
                            : 0
                    }
                    onChange={(e) => {
                        handleChange({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                tailwindClasses: [
                                    ...contentBlock.theme?.tailwindClasses?.filter(
                                        (className) =>
                                            !className.includes("px-"),
                                    ) || [],
                                    horizontalPaddingClassNames[e.target.value],
                                ],
                            },
                        });
                    }}
                    min={0}
                    max={horizontalPaddingClassNames.length - 1}
                />
            </div>
        </div>
    );
}
