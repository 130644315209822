import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import { Simulation } from "@/models";

export const RoundListButton = (props: {
    isDisabled: boolean;
    selectedSimulation: Partial<Simulation>;
    handleDiscard: () => boolean;
}) => {
    const { isDisabled, selectedSimulation, handleDiscard } = props;

    return selectedSimulation ? (
        <InertiaLink
            href={route("admin.design.index", {
                simulationSlug: selectedSimulation.slug,
            })}
        >
            <button
                onClick={() => {
                    handleDiscard();
                }}
                disabled={isDisabled}
                className="mx-1 flex items-center rounded-sm border-white/30 bg-white/5 px-3 py-1 text-sm font-semibold text-white hover:bg-white/10 focus:outline-none"
            >
                Rounds
            </button>
        </InertiaLink>
    ) : (
        <></>
    );
};
