import React from "react";
import { PanelControls } from "./panel";

type Props = {
    rightMenuSection: "style";
    isOpen: boolean;
};

const innerClassName =
    "w-full overflow-auto scrollbar scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2";

const outerClassName =
    "EDITOR-RIGHT-DETAIL-MENU overflow-hidden bg-[#111928] text-white data-[open=false]:w-[0px] data-[open=true]:w-[360px] data-[open=false]:min-w-[0px] data-[open=true]:min-w-[360px]";

export default function EditorRightMenu({ rightMenuSection, isOpen }: Props) {
    return (
        <>
            <div
                data-open={isOpen}
                data-testid="right-panel"
                className={`${outerClassName} absolute bottom-0 right-0 top-[36px] z-[10000] flex h-auto lg:relative lg:top-0 lg:z-0 lg:h-full`}
            >
                <div className={innerClassName}>
                    {rightMenuSection === "style" && <PanelControls />}
                </div>
            </div>
        </>
    );
}
