import React, { useMemo } from "react";
import { PromptType, Round, TimeHorizon } from "@/models";
import { displayPromptType } from "@/util";
import { DiscoveryLayerObject } from "@/hooks/useDiscoveryLayer";
import { DiscoveryWidgetWrapper } from "./DiscoveryWidgetWrapper";
import { DiscoveryStatisticsObject } from "@/hooks/useDiscoveryStatistics";
import { DiscoveryDataCharts } from "./DiscoveryDataCharts";

export const DiscoverySelectionsByType = ({
    discoveryLayerObject,
    hideCategoryQuestionScopeGrouping,
    rounds,
    timeHorizons,
    displayTypeScopeMap,
    handleDisplayTypeScopeMap,
}: {
    discoveryLayerObject: DiscoveryLayerObject | DiscoveryStatisticsObject;
    hideCategoryQuestionScopeGrouping?: boolean;
    rounds: Round[];
    timeHorizons: TimeHorizon[];
    displayTypeScopeMap: {
        [promptType: string]: {
            [promptScope: string]: boolean;
        };
    };
    handleDisplayTypeScopeMap: (
        promptType: string,
        promptScope: string,
    ) => void;
}) => {
    const { selectionMap, promptTypeAndScopeMap, teamMap, participantMap } =
        discoveryLayerObject;

    const roundMap = useMemo<{
        [id: string]: Partial<Round>;
    }>(() => {
        return rounds?.reduce(
            (map, round) => ({
                ...map,
                [round.id]: {
                    id: round.id,
                    weight: round.weight,
                    title: round.title,
                    time_horizon_id: round.time_horizon_id,
                },
            }),
            {},
        );
    }, [rounds]);

    const timeHorizonMap = useMemo<{
        [id: string]: Partial<TimeHorizon>;
    }>(() => {
        return timeHorizons?.reduce(
            (map, timeHorizon) => ({
                ...map,
                [timeHorizon.id]: {
                    id: timeHorizon.id,
                    time_index: timeHorizon.time_index,
                },
            }),
            {},
        );
    }, [timeHorizons]);

    return (
        <div className="w-full">
            {!!promptTypeAndScopeMap &&
                Object.keys(promptTypeAndScopeMap)
                    .filter(
                        (promptType) =>
                            promptType !== PromptType["Long Text"] &&
                            promptType !== PromptType["Short Text"],
                    )
                    .map((promptType) => (
                        <React.Fragment key={promptType}>
                            {Object.keys(promptTypeAndScopeMap[promptType]).map(
                                (promptScope) => (
                                    <div
                                        key={promptType + "-" + promptScope}
                                        className="w-full text-sm"
                                    >
                                        <DiscoveryWidgetWrapper className="mb-4 w-full border-orange-100 bg-orange-100/20">
                                            <div
                                                className="-m-4 mb-0 flex cursor-pointer items-center justify-between rounded-md
                                                    bg-orange-100/50 p-4 text-sm hover:bg-orange-100"
                                                onClick={() =>
                                                    handleDisplayTypeScopeMap(
                                                        promptType,
                                                        promptScope,
                                                    )
                                                }
                                            >
                                                <div className="font-semibold">
                                                    {displayPromptType(
                                                        promptType as PromptType,
                                                    )}
                                                </div>
                                                <div className="font-light">
                                                    {`Questions: ${
                                                        promptTypeAndScopeMap[
                                                            promptType
                                                        ][promptScope]
                                                            ?.length || 0
                                                    }`}
                                                </div>
                                                <div>{promptScope}</div>
                                            </div>
                                            {(!displayTypeScopeMap ||
                                                !displayTypeScopeMap[
                                                    promptType
                                                ] ||
                                                !!displayTypeScopeMap[
                                                    promptType
                                                ][promptScope]) && (
                                                <DiscoveryDataCharts
                                                    prompts={
                                                        promptTypeAndScopeMap[
                                                            promptType
                                                        ][promptScope]
                                                    }
                                                    selectionMap={promptTypeAndScopeMap[
                                                        promptType
                                                    ][promptScope].reduce(
                                                        (map, prompt) =>
                                                            !!selectionMap[
                                                                prompt.id
                                                            ]
                                                                ? {
                                                                      ...map,
                                                                      [prompt.id]:
                                                                          selectionMap[
                                                                              prompt
                                                                                  .id
                                                                          ],
                                                                  }
                                                                : map,
                                                        {},
                                                    )}
                                                    teamMap={teamMap}
                                                    participantMap={
                                                        participantMap
                                                    }
                                                    promptScope={
                                                        promptScope as
                                                            | "Team"
                                                            | "Participant"
                                                    }
                                                    promptType={
                                                        promptType as PromptType
                                                    }
                                                    hideCategoryQuestionScopeGrouping={
                                                        hideCategoryQuestionScopeGrouping
                                                    }
                                                    roundMap={roundMap}
                                                    timeHorizonMap={
                                                        timeHorizonMap
                                                    }
                                                />
                                            )}
                                        </DiscoveryWidgetWrapper>
                                    </div>
                                ),
                            )}
                        </React.Fragment>
                    ))}
        </div>
    );
};
