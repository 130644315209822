import React from "react";
import { useMobileViewState } from "./useMobileViewState";
import {
    DevicePhoneMobileIcon,
    DeviceTabletIcon,
    ComputerDesktopIcon,
} from "@heroicons/react/24/outline";

export function MobileViewButtons() {
    const { device, orientation, setOrientation, setDevice } =
        useMobileViewState();

    return (
        <div className="mr-3 hidden flex-row items-center justify-center md:flex">
            <span className="isolate mr-2 inline-flex rounded-md shadow-sm">
                <button
                    onClick={() => {
                        setDevice(null);
                    }}
                    className={`relative inline-flex items-center  p-1 text-sm font-medium  ${
                        device === null
                            ? " text-white"
                            : "text-gray-500 hover:text-white"
                    }`}
                >
                    <ComputerDesktopIcon className="h-5 w-5" />
                </button>
                {/*  */}
                <button
                    onClick={() => {
                        setDevice(device === "tablet" ? null : "tablet");
                    }}
                    type="button"
                    className={`relative inline-flex items-center  p-1 text-sm font-medium  ${
                        device === "tablet"
                            ? " text-white"
                            : "text-gray-500 hover:text-white"
                    }`}
                >
                    <DeviceTabletIcon
                        className={`h-5 w-5 ${
                            orientation === "horizontal" && "-rotate-90"
                        }`}
                    />
                </button>
                <button
                    onClick={() => {
                        setDevice(device === "phone" ? null : "phone");
                    }}
                    type="button"
                    className={`relative inline-flex items-center  p-1 text-sm font-medium  ${
                        device === "phone"
                            ? " text-white"
                            : "text-gray-500 hover:text-white"
                    }`}
                >
                    <DevicePhoneMobileIcon
                        className={`h-5 w-5 ${
                            orientation === "horizontal" && "-rotate-90"
                        }`}
                    />
                </button>
            </span>
            <button
                onClick={() => {
                    setOrientation(
                        orientation === "vertical" ? "horizontal" : "vertical",
                    );
                }}
                type="button"
                className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:text-white"
            >
                <svg
                    className={`h-5 w-5 ${
                        orientation === "horizontal" && "-rotate-90"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    ></path>
                </svg>
            </button>
        </div>
    );
}
