import React from "react";
import { contentBlockFunctionMap } from "./content-block-functions/contentBlockFunctionMap";
import { Divide } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { useContentBlockState } from "../useContentBlockState";
import { useIsAddingContentBlock } from "../content-block-editing/useIsAddingContentBlock";

type Props = {
    roundId: string;
    weight: number;
};

export default function CreateDividerButton({ roundId, weight }: Props) {
    const { setContentBlock } = useContentBlockState();
    const { setIsAddingBlock } = useIsAddingContentBlock();
    const { mutate, isPending } = useMutation({
        mutationFn: async (divider: ContentBlockShape) => {
            const { data } = await sapienAxios.post<
                { contentBlocks: ContentBlockShape[] },
                "e-learning.dividers.store"
            >("e-learning.dividers.store", {
                ...divider,
                position: "before",
            } as any);
            return data?.contentBlocks;
        },
        onSuccess: (contentBlocks) => {
            contentBlocks.forEach((contentBlock) => {
                setContentBlock(contentBlock);
                setIsAddingBlock(false);
            });
        },
    });

    return (
        <button
            disabled={isPending}
            onClick={() => {
                const divider = contentBlockFunctionMap.createDividerBlock(
                    roundId,
                    weight,
                );
                mutate(divider);
            }}
            className="flex h-16 flex-col items-center justify-center rounded-md p-2 px-4
                transition-colors hover:bg-slate-200 disabled:cursor-not-allowed
                disabled:bg-slate-200 disabled:text-slate-700"
        >
            <Divide className="h-6 w-6" />
            <p>Divder</p>
        </button>
    );
}
