import React, { useCallback, useState } from "react";
import { ContentBlockShape } from "@/models";
import {
    Table,
    Row,
    Cell,
    HeaderCell,
} from "@/styles/themes/tables/TableTheme";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    PencilIcon,
    PlusIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { addRow, defaultCell, switchArrayElements } from "./TableWizardReducer";
import { TextDisplay, TextEditor } from "@/modules/text-editor";

type Props = {
    table: ContentBlockShape;
    commitEdits: (table: ContentBlockShape) => void;
};

export default function ManualTableEntry({
    table,
    commitEdits,
}: Props) {
    const [editedRowIndex, setEditedRowIndex] = useState(-1);
    const [editedColumnIndex, setEditedColumnIndex] = useState(-1);
    const theme = table?.theme;

    const addColumn = useCallback(
        (columnIndex: number) => {
            const updatedTable = {
                ...table,
                table_data: table.table_data.map((row) => {
                    return [
                        ...row.slice(0, columnIndex),
                        defaultCell,
                        ...row.slice(columnIndex),
                    ];
                }),
            };
            commitEdits(updatedTable);
        },
        [table, commitEdits]
    );

    const removeColumn = useCallback(
        (columnIndex: number) => {
            const updatedTable = {
                ...table,
                table_data: table.table_data.map((row) => {
                    return row.filter((_, index) => index !== columnIndex);
                }),
            };
            commitEdits(updatedTable);
        },
        [table, commitEdits]
    );

    const moveRow = useCallback(
        (direction: "up" | "down", rowIndex: number) => {
            const destinationIndex =
                direction === "up" ? rowIndex - 1 : rowIndex + 1;
            commitEdits({
                ...table,
                table_data: switchArrayElements(
                    table.table_data,
                    rowIndex,
                    destinationIndex
                ),
            });
        },
        [table, commitEdits]
    );

    return (
        <div
            data-testid="manual-table-form"
            className="w-full manual-table-form active-content-block"
            data-content-block-id={table?.id}
        >
            <Table className="w-full table-fixed" {...theme}>
                <tbody>
                    <Row>
                        <td className="w-56"></td>
                        {table?.table_data[0].map((_, columnIndex) => {
                            return (
                                <td
                                    key={`column-manager-${columnIndex}`}
                                    className="odd:bg-black/10 border-l border-gray-300"
                                >
                                    <div className="flex items-center justify-center">
                                        <button
                                            className="text-rose-700 hover:text-rose-800 relative inline-flex items-center justify-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                            data-testid={`remove-column-${columnIndex}`}
                                            onClick={() =>
                                                removeColumn(columnIndex)
                                            }
                                        >
                                            <span className="sr-only">
                                                Remove Column
                                            </span>
                                            <TrashIcon className="h-4 w-4" />
                                        </button>
                                        <button
                                            className="text-teal-700 hover:text-teal-800 relative inline-flex items-center justify-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                            data-testid={`add-column-${columnIndex}`}
                                            onClick={() => {
                                                addColumn(columnIndex + 1);
                                            }}
                                        >
                                            <span className="sr-only">
                                                Add Column
                                            </span>
                                            <PlusIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                </td>
                            );
                        })}
                    </Row>
                    {table?.table_data.map((row, rowIndex) => {
                        return (
                            <Row key={rowIndex}>
                                <td className="w-56 bg-transparent border-r border-gray-300">
                                    <div className="flex items-center px-2">
                                        <span className="isolate inline-flex rounded-md shadow-sm">
                                            <>
                                                <button
                                                    data-testid={`remove-row-${rowIndex}`}
                                                    className="text-rose-700 hover:text-rose-800 relative inline-flex items-center justify-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    onClick={() => {
                                                        commitEdits({
                                                            ...table,
                                                            table_data:
                                                                table.table_data.filter(
                                                                    (_, i) =>
                                                                        i !==
                                                                        rowIndex
                                                                ),
                                                        });
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                    }}
                                                >
                                                    <TrashIcon className="h-4 w-4" />
                                                </button>
                                                <button
                                                    data-testid={`move-row-up-${rowIndex}`}
                                                    className=" relative inline-flex items-center justify-center  bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    onClick={() => {
                                                        moveRow("up", rowIndex);
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                    }}
                                                >
                                                    <ArrowUpIcon className="h-4 w-4" />
                                                </button>
                                                <button
                                                    data-testid={`move-row-down-${rowIndex}`}
                                                    className=" relative inline-flex items-center justify-center  bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    onClick={() => {
                                                        moveRow(
                                                            "down",
                                                            rowIndex
                                                        );
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                        setEditedColumnIndex(
                                                            -1
                                                        );
                                                    }}
                                                >
                                                    <ArrowDownIcon className="h-4 w-4" />
                                                </button>
                                                <button
                                                    data-testid={`add-row-${rowIndex}`}
                                                    className="text-teal-700 hover:text-teal-800 relative inline-flex items-center justify-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    onClick={() => {
                                                        const numColumns =
                                                            table.table_data[0]
                                                                .length;
                                                        const updatedTable = {
                                                            ...table,
                                                            table_data: [
                                                                ...table.table_data.slice(
                                                                    0,
                                                                    rowIndex + 1
                                                                ),
                                                                addRow(
                                                                    numColumns,
                                                                    rowIndex + 1
                                                                ),
                                                                ...table.table_data.slice(
                                                                    rowIndex + 1
                                                                ),
                                                            ],
                                                        }
                                                        commitEdits(updatedTable);
                                                    }}
                                                >
                                                    <PlusIcon className="h-4 w-4" />{" "}
                                                </button>
                                            </>
                                        </span>
                                    </div>
                                </td>
                                {row.map((cell, columnIndex) => {
                                    return rowIndex === 0 ? (
                                        <HeaderCell
                                            key={`${rowIndex}_${columnIndex}`}
                                            role="button"
                                            onClick={() => {
                                                setEditedRowIndex(rowIndex);
                                                setEditedColumnIndex(
                                                    columnIndex
                                                );
                                            }}
                                            className="group hover:bg-blue-100/50"
                                        >
                                            {editedRowIndex !== rowIndex ||
                                            editedColumnIndex !==
                                                columnIndex ? (
                                                <span className="relative">
                                                    <PencilIcon className="h-6 w-6 opacity-0 group-hover:opacity-100 text-blue-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all " />
                                                    <TextDisplay
                                                        richText={cell}
                                                        theme={undefined}
                                                        editedId={`${rowIndex}_${columnIndex}`}
                                                    />
                                                </span>
                                            ) : (
                                                <>
                                                    <TextEditor
                                                        richText={cell}
                                                        editedId={`${rowIndex}_${columnIndex}`}
                                                        theme={undefined}
                                                        onUpdate={(
                                                            richText
                                                        ) => {
                                                            console.log(
                                                                "updated",
                                                                richText
                                                            );
                                                            const updatedTable =
                                                                {
                                                                    ...table,
                                                                    table_data:
                                                                        [
                                                                            ...table.table_data.slice(
                                                                                0,
                                                                                rowIndex
                                                                            ),
                                                                            [
                                                                                ...table.table_data[
                                                                                    rowIndex
                                                                                ].slice(
                                                                                    0,
                                                                                    columnIndex
                                                                                ),
                                                                                richText,
                                                                                ...table.table_data[
                                                                                    rowIndex
                                                                                ].slice(
                                                                                    columnIndex +
                                                                                        1
                                                                                ),
                                                                            ],
                                                                            ...table.table_data.slice(
                                                                                rowIndex +
                                                                                    1
                                                                            ),
                                                                        ],
                                                                };
                                                            commitEdits(
                                                                updatedTable
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </HeaderCell>
                                    ) : (
                                        <Cell
                                            {...theme}
                                            key={`${rowIndex}_${columnIndex}`}
                                            role="button"
                                            onClick={() => {
                                                setEditedRowIndex(rowIndex);
                                                setEditedColumnIndex(
                                                    columnIndex
                                                );
                                            }}
                                            className="group hover:bg-blue-100/50"
                                        >
                                            {editedRowIndex !== rowIndex ||
                                            editedColumnIndex !==
                                                columnIndex ? (
                                                <span className="relative">
                                                    <PencilIcon className="h-6 w-6 opacity-0 group-hover:opacity-100 text-blue-800 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all " />
                                                    <TextDisplay
                                                        richText={cell}
                                                        theme={undefined}
                                                        editedId={`${rowIndex}_${columnIndex}`}
                                                    />
                                                </span>
                                            ) : (
                                                <>
                                                    <TextEditor
                                                        richText={cell}
                                                        // editor={editor}
                                                        theme={undefined}
                                                        editedId={`${rowIndex}_${columnIndex}`}
                                                        onUpdate={(
                                                            richText
                                                        ) => {
                                                            console.log(
                                                                "updated",
                                                                richText
                                                            );
                                                            const updatedTable =
                                                                {
                                                                    ...table,
                                                                    table_data:
                                                                        [
                                                                            ...table.table_data.slice(
                                                                                0,
                                                                                rowIndex
                                                                            ),
                                                                            [
                                                                                ...table.table_data[
                                                                                    rowIndex
                                                                                ].slice(
                                                                                    0,
                                                                                    columnIndex
                                                                                ),
                                                                                richText,
                                                                                ...table.table_data[
                                                                                    rowIndex
                                                                                ].slice(
                                                                                    columnIndex +
                                                                                        1
                                                                                ),
                                                                            ],
                                                                            ...table.table_data.slice(
                                                                                rowIndex +
                                                                                    1
                                                                            ),
                                                                        ],
                                                                };

                                                            commitEdits(
                                                                updatedTable
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Cell>
                                    );
                                })}
                            </Row>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
