import React, { FC } from "react";
import { Icon } from "react-icons-kit";
import { plusSquare } from "react-icons-kit/feather/plusSquare";
import { layers } from "react-icons-kit/feather/layers";
import { folder } from "react-icons-kit/feather/folder";
import { info } from "react-icons-kit/feather/info";
import { search } from "react-icons-kit/feather/search";
import { chevronsLeft } from "react-icons-kit/feather/chevronsLeft";
import { chevronsRight } from "react-icons-kit/feather/chevronsRight";
import { LeftMenuSectionType, editorColors } from "./EditorContainer";

const leftMenuItemObject: { [index in LeftMenuSectionType]: any } = {
    layers: layers,
    create: plusSquare,
    assets: folder,
};

const extraIconObject: { [index: string]: any } = {
    search: search,
    info: info,
};

type LeftIconMenuProps = {
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    toggleLeftMenu: () => void;
    isLeftMenuOpen: boolean;
    leftMenuSection: LeftMenuSectionType;
    setLeftMenuSection: (section: LeftMenuSectionType) => void;
};

export const LeftIconMenu: FC<LeftIconMenuProps> = (props) => {
    const {
        editorSizes,
        toggleLeftMenu,
        isLeftMenuOpen,
        leftMenuSection,
        setLeftMenuSection,
    } = props;

    return (
        <div
            className="EDITOR-LEFT-ICON-MENU flex h-full flex-col justify-between"
            style={{
                width: editorSizes.toolbar,
                minWidth: editorSizes.toolbar,
                background: editorColors.bgLeft,
            }}
        >
            <div className={`text-white bg-[${editorColors.bgLeft}]`}>
                <div
                    key={"toggle"}
                    onClick={() => {
                        toggleLeftMenu();
                    }}
                    className="flex cursor-pointer items-center justify-center"
                    style={{
                        width: editorSizes.toolbar,
                        height: editorSizes.toolbar,
                    }}
                >
                    <Icon
                        icon={isLeftMenuOpen ? chevronsLeft : chevronsRight}
                        size={18}
                        style={{ display: "flex" }}
                    />
                </div>
                {Object.keys(leftMenuItemObject).map((section) => {
                    return (
                        <div
                            key={section}
                            onClick={() => {
                                setLeftMenuSection(
                                    section as LeftMenuSectionType,
                                );
                                if (!isLeftMenuOpen) toggleLeftMenu();
                            }}
                            className="flex cursor-pointer items-center justify-center"
                            style={{
                                width: editorSizes.toolbar,
                                height: editorSizes.toolbar,
                                background:
                                    section === leftMenuSection &&
                                    isLeftMenuOpen &&
                                    "rgba(255,255,255,.15)",
                            }}
                            id={`left-menu-${section}`}
                            data-testid={`left-menu-${section}`}
                        >
                            <Icon
                                icon={leftMenuItemObject[section]}
                                size={18}
                                style={{ display: "flex" }}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={`text-white bg-[${editorColors.bgLeft}]`}>
                {Object.keys(extraIconObject).map((iconKey) => (
                    <div
                        key={iconKey}
                        onClick={() => {
                            null;
                        }}
                        className="flex cursor-pointer items-center justify-center"
                        style={{
                            width: editorSizes.toolbar,
                            height: editorSizes.toolbar,
                        }}
                    >
                        <Icon
                            icon={extraIconObject[iconKey]}
                            size={18}
                            style={{ display: "flex" }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
