import React, { useEffect, useMemo } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Cohort, CohortShape, Simulation, TeamShape } from "@/models";
import {
    useSelectedSimulation,
    useInitAuthFromInertiaProps,
    useSelectedCohort,
    useAllCohorts,
} from "@/hooks";
import { Icon } from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { TopNavLinkDiv } from "@/components";
import { Head, Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import { useRoundStore } from "@/hooks/store";
import { CohortDetail, CohortList } from "@/Layouts/facilitation";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import ParticipantLinkModal from "./ParticipantLinkModal";
import { useModalComponentStore } from "@/hooks/useModalComponentStore";
import LogoImage from "@/components/LogoImage";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";

const toolbarHeight = 42;
const facilitationColors = {
    bgTop: "#15171a",
    bgMenu: "#2d2f31",
    bgPanel: "#1f1f1f",
    menuColor: "white",
};

export type TeamWithProgressData = TeamShape & {
    progress_map: {
        [round_id: string]: {
            participantsInRound: number;
            percent_complete: number;
        };
    };
    team_user_rounds?: {
        teamSelections: any[];
    }[];
};

export type CohortWithProgressData = Omit<CohortShape, "teams"> & {
    teams: TeamWithProgressData[];
};

type Props = SapienPageProps & {
    simulation: Simulation;
    cohort: CohortWithProgressData | null;
};

export default function FacilitationContainer(props: Props) {
    const { simulation, cohort, auth, tenant } = props;
    const { user } = useInitAuthFromInertiaProps(auth, null, null, tenant);
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotCohort } = useSelectedCohort();
    const { gotCohorts } = useAllCohorts();
    const { gotRounds } = useRoundStore();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
        gotRounds(simulation.rounds || []);
        gotCohorts(simulation.cohorts);
    }, [simulation]);

    function reloadOnNotify(_: any) {
        console.log("doing reload");
        SapienInertia.reload({
            preserveScroll: true,
            preserveState: true,
        });
    }

    useEffect(() => {
        gotCohort(cohort as Cohort);
    }, [cohort]);

    const { setUpFacilitatorSockets } = useWebSockets();

    useEffect(() => {
        if (cohort) {
            setUpFacilitatorSockets(cohort.id, {
                "facilitator.notify": reloadOnNotify,
            });
        }
    }, [cohort?.id]);


    const { setModalProps } = useModalComponentStore();
    setModalProps(props);

    const title = useMemo(() => {
        if (selectedSimulation) return selectedSimulation.title;
        if (tenant) return tenant.name;
        return "Admin";
    }, [selectedSimulation, tenant]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <Head title={title} />
                <div
                    className="facilitation-container"
                    style={{
                        display: "flex",
                        height: "100vh",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <div
                        className="facilitation-top"
                        style={{
                            display: "flex",
                            height: `${toolbarHeight}px`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="facilitation-top-logo"
                            style={{
                                width: `${toolbarHeight}px`,
                                minWidth: `${toolbarHeight}px`,
                                height: `${toolbarHeight}px`,
                                background: facilitationColors.bgTop,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TopNavLinkDiv style={{ padding: 0 }}>
                                <InertiaLink href={route("admin.index")}>
                                    <LogoImage size={"22"} opacity={"0.9"} />
                                </InertiaLink>
                            </TopNavLinkDiv>
                        </div>
                        <div
                            className="facilitation-top-center"
                            style={{
                                width: `calc(100% - ${toolbarHeight}px)`,
                                minWidth: `calc(100% - ${toolbarHeight}px)`,
                                height: `${toolbarHeight}px`,
                                background: facilitationColors.bgTop,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: facilitationColors.menuColor,
                                transition: "all .25s ease",
                            }}
                        >
                            <div className="flex flex-row flex-wrap items-center">
                                <TopNavLinkDiv
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <InertiaLink
                                        href={
                                            !!simulation &&
                                            route("admin.projects.show", {
                                                sim: simulation.slug,
                                            })
                                        }
                                    >
                                        <div
                                            className="px-3 text-sm font-bold"
                                            style={{
                                                color: facilitationColors.menuColor,
                                            }}
                                        >
                                            {`${simulation.title}`}
                                        </div>
                                    </InertiaLink>
                                </TopNavLinkDiv>
                                <div className="">
                                    <Icon
                                        icon={chevronRight}
                                        size={12}
                                        style={{
                                            display: "flex",
                                            color: facilitationColors.menuColor,
                                            opacity: 0.6,
                                        }}
                                    />
                                </div>
                                {!cohort && (
                                    <div className="px-3 text-sm font-semibold">
                                        Facilitation
                                    </div>
                                )}
                                {!!cohort && (
                                    <>
                                        <TopNavLinkDiv
                                            style={{
                                                padding: 0,
                                            }}
                                        >
                                            <InertiaLink
                                                href={route(
                                                    "admin.projects.facilitate.index",
                                                    {
                                                        simulationSlug:
                                                            selectedSimulation?.slug,
                                                    },
                                                )}
                                            >
                                                <div
                                                    className="px-3 text-sm font-semibold"
                                                    style={{
                                                        color: facilitationColors.menuColor,
                                                    }}
                                                >
                                                    Facilitation
                                                </div>
                                            </InertiaLink>
                                        </TopNavLinkDiv>
                                        <Icon
                                            icon={chevronRight}
                                            size={12}
                                            style={{
                                                display: "flex",
                                                color: facilitationColors.menuColor,
                                                opacity: 0.6,
                                            }}
                                        />
                                        <div className="px-3 text-sm font-normal">
                                            {`${cohort.title}`}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="facilitation-top-right-links"
                                style={{
                                    height: "100%",
                                    background: facilitationColors.bgTop,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: facilitationColors.menuColor,
                                    transition: "all .25s ease",
                                }}
                            >
                                {user && (
                                    <UserNavDropdown userName={user.name} />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* {!!cohort && (
                        <div
                            className="facilitation-middle border-box flex flex-row items-center justify-center border-b border-[#c3c3c3] bg-neutral-50 px-3 text-sm"
                            style={{
                                height: `${toolbarHeight}px`,
                            }}
                        >
                            menu
                        </div>
                    )} */}
                    <div
                        className="facilitation-bottom"
                        style={{
                            display: "flex",
                            height: `calc(100vh  - ${
                                (!!cohort ? 1 : 1) * toolbarHeight // (!!cohort ? 2 : 1) * toolbarHeight
                            }px)`,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="facilitation-center-panel w-full"
                            style={{
                                transition: "all .25s ease",
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    maxWidth: "1290px", // !cohort && "1290px",
                                    margin: "0 auto",
                                    minWidth: "300px",
                                }}
                            >
                                <div className="">
                                    {!cohort ? (
                                        <CohortList />
                                    ) : (
                                        <CohortDetail />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ParticipantLinkModal />
            </>
        )
    );
}
