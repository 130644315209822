import React from 'react'
import { queryClient } from './Client'
import { QueryClientProvider } from '@tanstack/react-query'

type Props = {
    children: React.ReactNode
}

export default function QueryClient({children}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>
  )
}
