import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType } from "@/models";
import { TypographyType } from "@/styles";
import {
    createBasicHeroAndBox,
    createBasicPage,
    createBasicTextSection,
} from "./TemplateHelpers";

export const WelcomePageContentBlocks: FragmentContentBlock[] = [
    createBasicPage({
        childContentBlocks: [
            createBasicHeroAndBox({
                childContentBlocks: [
                    {
                        content: "Welcome!",
                        theme: {
                            color: "rgba(255,255,255,1)",
                            typographyType: "HEADING_1",
                            backgroundColor: "rgba(0,0,0,0.01)",
                        },
                        content_block_type: ContentBlockType.Typography,
                    },
                ],
            }),
            createBasicTextSection({ headingText: "Introduction" }),
        ],
    }),
];
