import React from "react";
import { useContentBlockMetadata } from "@/model-configs";
import { ContentBlock, ContentBlockShape, ContentBlockType } from "@/models";
import styled from "styled-components";
import { titleCase } from "@/util";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

type Props = {
    contentBlock: ContentBlockShape;
    depth?: number;
};

const Icon = styled.div`
    > svg,
    > img {
        height: auto;
        width: 50%;
        > path {
            display: none;
        }
    }
`;

const extraIcons = {
    [ContentBlockType.Page]: <EyeIcon className="h-32 w-32 inline-block" />,
    [ContentBlockType.Card]: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100pt"
            height="100pt"
            version="1.1"
            viewBox="0 0 1200 1200"
            className="ml-4 fill-white/70"
        >
            <g>
                <path d="m226.8 828h322.8c15.602 0 27.602-12 27.602-27.602v-50.398c0-56.398-36-108-91.199-123.6-30-8.3984-168-8.3984-196.8 0-54 16.801-91.199 67.199-91.199 123.6v50.398c1.1953 15.602 13.195 27.602 28.797 27.602z" />
                <path d="m496.8 480c0 59.648-48.355 108-108 108-59.648 0-108-48.352-108-108s48.352-108 108-108c59.645 0 108 48.352 108 108" />
                <path d="m679.2 493.2h286.8c20.398 0 37.199-16.801 37.199-37.199s-16.801-37.199-37.199-37.199h-286.8c-20.398 0-37.199 16.801-37.199 37.199s16.801 37.199 37.199 37.199z" />
                <path d="m679.2 637.2h286.8c20.398 0 37.199-16.801 37.199-37.199s-16.801-37.199-37.199-37.199h-286.8c-20.398 0-37.199 16.801-37.199 37.199s16.801 37.199 37.199 37.199z" />
                <path d="m679.2 782.4h286.8c20.398 0 37.199-16.801 37.199-37.199 0-20.398-16.801-37.199-37.199-37.199h-286.8c-20.398 0-37.199 16.801-37.199 37.199 0 20.402 16.801 37.199 37.199 37.199z" />
                <path d="m1132.8 206.4h-1065.6c-20.398 0-37.199 16.801-37.199 37.199v711.6c0 20.398 16.801 37.199 37.199 37.199h1065.6c20.398 0 37.199-16.801 37.199-37.199v-710.4c0-21.602-16.801-38.402-37.199-38.402zm-37.199 711.6h-991.2v-636h990v636z" />
            </g>
        </svg>
    ),
};

function ContentBlockIcon({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { flattenedCategories } = useContentBlockMetadata();

    const icon =
        extraIcons[contentBlock.content_block_type] ||
        flattenedCategories.find(
            (category) =>
                category.blockOrLayoutType === contentBlock.content_block_type
        )?.icon ||
        null;
    return (
        <Icon className="max-h-[72px] overflow-visible flex items-center justify-start pl-5">
            <span className="-mt-2 inline-flex items-center rounded-full bg-indigo-100 px-1.5 py-0.5 text-xs font-medium text-indigo-700">
                {titleCase(contentBlock.content_block_type)}
            </span>
            {icon}
        </Icon>
    );
}

export default function ContentBlocksToWireframe({
    contentBlock,
    depth = 0,
}: Props) {
    const [isCollapsed, setIsCollapsed] = React.useState(depth > 0);

    const { flattenedCategories } = useContentBlockMetadata();
    const icon = React.useMemo(() => {
        return (
            extraIcons[contentBlock.content_block_type] ||
            flattenedCategories.find(
                (category) =>
                    category.blockOrLayoutType ===
                    contentBlock.content_block_type
            )?.icon ||
            null
        );
    }, [flattenedCategories, contentBlock]);

    const hasVisibleChildren = React.useMemo(() => {
        if (!icon) return false;
        if (!contentBlock?.contentBlocks?.length) return false;
        return contentBlock.contentBlocks.some((childBlock) => {
            return (
                !!extraIcons[childBlock.content_block_type] ||
                !!flattenedCategories.find(
                    (category) =>
                        category.blockOrLayoutType ===
                        childBlock.content_block_type
                )
            );
        });
    }, [flattenedCategories, contentBlock, icon]);

    if (!icon) {
        return <></>;
    }
    if (depth === 0) {
        return (
            <div
                className="ml-8 flex flex-col items-start justify-start relative"
                data-testid="content-block-wireframe"
            >
                <ContentBlockIcon contentBlock={contentBlock} />
                {contentBlock.contentBlocks?.map((childBlock, i) => (
                    <ContentBlocksToWireframe
                        key={childBlock.id}
                        contentBlock={childBlock}
                        depth={depth + 1}
                    />
                ))}
            </div>
        );
    }

    return (
        <div
            role="button"
            className="ml-8 flex flex-col items-start justify-start relative last:before:h-[49px] before:absolute before:block before:-top-4  before:h-full before:border-l before:border-current before:w-[2px]  after:block after:absolute after:border-current after:border-t after:left-px after:w-4 after:top-8 after:h-[2px]"
        >
            {hasVisibleChildren && (
                <button
                    className="flex items-start absolute -left-5 top-8 -translate-y-1/2 text-current"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsCollapsed(!isCollapsed);
                    }}
                >
                    {isCollapsed ? (
                        <EyeSlashIcon className="h-4 w-4 inline-block" />
                    ) : (
                        <EyeIcon className="h-4 w-4 inline-block" />
                    )}
                </button>
            )}
            <ContentBlockIcon contentBlock={contentBlock} />
            {!isCollapsed &&
                contentBlock.contentBlocks?.map((childBlock, i) => (
                    <ContentBlocksToWireframe
                        key={childBlock.id}
                        contentBlock={childBlock}
                        depth={depth + 1}
                    />
                ))}
        </div>
    );
}
