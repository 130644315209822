import React, { useMemo } from "react";
import { device } from "./MobileWrapperTypes";
import { useMobileViewState } from "./useMobileViewState";

type Props = {
    unavailableHeightPx: number;
    children: JSX.Element | JSX.Element[];
};

const dimensions: { [key in device]: [number, number] } = {
    phone: [375, 812],
    tablet: [703, 1024],
};

export default function MobileWrapper({
    unavailableHeightPx,
    children,
}: Props) {
    const { device, orientation } = useMobileViewState();

    const currentDimensions = useMemo(() => {
        if (!device)
            return {
                height: 0,
                width: 0,
            };

        const dimensionToUse = dimensions[device];

        const heightToSubtract =
            orientation === "horizontal" ? 0 : unavailableHeightPx + 108;

        return {
            height:
                orientation === "vertical"
                    ? Math.max(...dimensionToUse) - heightToSubtract
                    : Math.min(...dimensionToUse),
            width:
                orientation === "vertical"
                    ? Math.min(...dimensionToUse)
                    : Math.max(...dimensionToUse),
        };
    }, [unavailableHeightPx, orientation, device]);

    if (!device) return <> {children}</>;

    return (
        <>
            {!!device && (
                <div className="absolute top-0 left-0">
                    <div className="relative" id="popover-portal"></div>
                </div>
            )}
            {device === "phone" && (
                <div className="absolute top-20 left-6">
                    <div className="relative w-80" id="add-content-block-menu-portal"></div>
                </div>
            )}
            <div
                className={`flex flex-col mt-6 overflow-y-auto border-4 shadow-md rounded-lg mobile-wrapper max-w-[95%]`}
                style={{
                    height: currentDimensions.height,
                    width: currentDimensions.width,
                }}
            >
                {children}
            </div>
        </>
    );
}
