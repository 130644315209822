import React from "react";
import {
    VictoryBar,
    VictoryChart,
    VictoryContainer,
    VictoryLine,
    VictoryPie,
    VictoryTheme,
} from "victory";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";
import {
    Transition,
    Dialog,
    TransitionChild,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
    barData,
    createChart,
    lineData,
    pieData,
} from "./createChartFunctions";
import { defaultAreaChartTheme } from "@/styles/themes/charts/themes";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import { ContentBlockShape } from "@/models";
import { ChartTypes } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    roundId: string;
    weight: number;
};

export function CreateChartSidebarWrapper({
    isOpen,
    setIsOpen,
    roundId,
    weight,
}: Props) {
    const contentEl = useGetElementAsync("#aside-slot");
    return (
        <>
            {!!contentEl &&
                createPortal(
                    <CreateChartSidebar
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        roundId={roundId}
                        weight={weight}
                    />,
                    contentEl,
                )}
        </>
    );
}
function CreateChartSidebar({ isOpen, setIsOpen, roundId, weight }: Props) {
    const { mutateAsync, isPending } = useSaveContentBlock();

    const saveChart = async (block: ContentBlockShape) => {
        await mutateAsync(block);
        setIsOpen(false);
    };

    return (
        <Transition show={isOpen}>
            <Dialog
                className="relative z-[9999]"
                onClose={() => setIsOpen(false)}
            >
                <div className="fixed inset-0 bg-black/25" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 top-16 flex max-w-full pr-10">
                            <TransitionChild
                                enter="transform transition-all ease-in-out duration-500 sm:duration-700"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition-all ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <DialogPanel className="pointer-events-auto min-w-48">
                                    <div
                                        className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl scrollbar
                                            scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-w-2"
                                    >
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <DialogTitle
                                                    id="dialog-title"
                                                    className="mt-16 text-base font-semibold leading-6 text-gray-900"
                                                ></DialogTitle>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500
                                                            focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() =>
                                                            setIsOpen(false)
                                                        }
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">
                                                            Close panel
                                                        </span>
                                                        <XMarkIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`-mt-6 flex flex-col space-y-4 rounded-md p-4
                                            data-[ispending="true"]:animate-pulse`}
                                            data-ispending={isPending}
                                        >
                                            <button
                                                className="flex flex-col items-center justify-center space-y-2 rounded-sm bg-gray-50 p-4
                                                    shadow transition-all hover:bg-white hover:shadow-lg"
                                                onClick={() => {
                                                    const block = createChart(
                                                        roundId,
                                                        weight,
                                                        barData.map((d, i) => ({
                                                            x: d.x,
                                                            y: d.y,
                                                            theme: {
                                                                fill: "#c43a31",
                                                            },
                                                            label: `Category ${i + 1}`,
                                                            chartType:
                                                                ChartTypes.BAR,
                                                        })),
                                                    );
                                                    saveChart(block);
                                                }}
                                            >
                                                <h3 className="w-full text-left">
                                                    Bar Chart
                                                </h3>
                                                <VictoryChart
                                                    width={300}
                                                    height={200}
                                                    containerComponent={
                                                        <VictoryContainer
                                                            responsive={false}
                                                        />
                                                    }
                                                    domainPadding={{ x: 20 }}
                                                >
                                                    <VictoryBar
                                                        style={{
                                                            data: {
                                                                fill: "#c43a31",
                                                            },
                                                        }}
                                                        barRatio={1}
                                                        standalone={false}
                                                        width={200}
                                                        height={150}
                                                        padding={{
                                                            left: 30,
                                                            right: 10,
                                                        }}
                                                        data={barData}
                                                    />
                                                </VictoryChart>
                                            </button>
                                            <button
                                                className="flex flex-col items-center justify-center space-y-2 rounded-sm bg-gray-50 p-4
                                                    shadow transition-all hover:bg-white hover:shadow-lg"
                                                onClick={() => {
                                                    const block = createChart(
                                                        roundId,
                                                        weight,
                                                        {
                                                            "category_0": lineData.map(
                                                                (d, i) =>
                                                                    ({
                                                                        ...d,
                                                                        theme: {
                                                                            stroke: "#c43a31",
                                                                        },
                                                                        label: `Category 1`,
                                                                        chartType:
                                                                            ChartTypes.LINE,
                                                                    }) as FormattedChartValue,
                                                            ),
                                                        },
                                                    );
                                                    console.log("BLOCK", block)
                                                    saveChart(block);
                                                }}
                                            >
                                                <h3 className="w-full text-left">
                                                    Line Chart
                                                </h3>
                                                <VictoryChart
                                                    theme={
                                                        VictoryTheme.material
                                                    }
                                                    height={200}
                                                    width={300}
                                                    containerComponent={
                                                        <VictoryContainer
                                                            responsive={false}
                                                        />
                                                    }
                                                >
                                                    <VictoryLine
                                                        style={{
                                                            data: {
                                                                stroke: "#c43a31",
                                                            },
                                                            parent: {
                                                                border: "1px solid #ccc",
                                                            },
                                                        }}
                                                        data={lineData}
                                                    />
                                                </VictoryChart>
                                            </button>
                                            <button
                                                className="flex flex-col items-center justify-center space-y-2 rounded-sm bg-gray-50 p-4
                                                    shadow transition-all hover:bg-white hover:shadow-lg"
                                                onClick={() => {
                                                    const block = createChart(
                                                        roundId,
                                                        weight,
                                                        pieData.map(
                                                            (d, i) =>
                                                                ({
                                                                    ...d,
                                                                    theme: {
                                                                        fill: d
                                                                            ?.theme
                                                                            ?.fill,
                                                                    },
                                                                    label: `Category ${i + 1}`,
                                                                    chartType:
                                                                        ChartTypes.PIE,
                                                                }) as FormattedChartValue,
                                                        ),
                                                    );
                                                    saveChart(block);
                                                }}
                                            >
                                                <h3 className="w-full text-left">
                                                    Pie Chart
                                                </h3>

                                                <VictoryPie
                                                    colorScale={[
                                                        "tomato",
                                                        "orange",
                                                        "gold",
                                                        "cyan",
                                                        "navy",
                                                    ]}
                                                    data={pieData}
                                                    containerComponent={
                                                        <VictoryContainer
                                                            responsive={false}
                                                        />
                                                    }
                                                    height={250}
                                                    width={350}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
