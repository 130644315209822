import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType, PromptType } from "@/models";
import { TypographyType } from "@/styles";
import {
    createBasicHeroAndBox,
    createBasicPage,
    createBasicTextSection,
} from "./TemplateHelpers";

export const EventDecisionContentBlocks: FragmentContentBlock[] = [
    createBasicPage({
        childContentBlocks: [
            createBasicHeroAndBox({
                childContentBlocks: [
                    {
                        content: "Event",
                        theme: {
                            color: "rgba(255,255,255,1)",
                            typographyType:"HEADING_1",
                            backgroundColor: "rgba(0,0,0,0.01)",
                        },
                        content_block_type: ContentBlockType.Typography,
                    },
                ],
            }),
            createBasicTextSection({ headingText: "Context" }),
            {
                content: "Section",
                theme: {
                    backgroundColor: "rgba(255,255,255,1)",
                },
                content_block_type: ContentBlockType.Section,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Question,
                        content: "Question content block",
                        prompt: {
                            prompt_type: PromptType["Multiple Choice"],
                            content: "Event Response",
                            options: [
                                { content: "Option A" },
                                { content: "Option B" },
                            ],
                        },
                    },
                ],
            },
        ],
    }),
];
