import { useMemo, useState } from "react";
import { ContentBlockTemplateShape, UserShape } from "@/models";
import { ContentBlockConfigObject } from "@/model-configs/ContentBlockConfigObject";
import { ContentBlockConfig, LayoutConfig } from "@/model-configs";

export const useTemplateMap = (
    templates: {
        user: ContentBlockTemplateShape[];
        simulation: ContentBlockTemplateShape[];
    },
    user: UserShape,
    contentBlockConfig?: ContentBlockConfig
) => {
    const [query, setQuery] = useState("");

    function filterTemplates(
        template: ContentBlockTemplateShape,
        query: string,
        config?: ContentBlockConfig
    ) {
        if (
            config &&
            !config?.addableChildBlocks?.includes(
                template.content_block.content_block_type
            )
        ) {
            return false;
        }
        return (
            template.label?.toLowerCase().includes(query.toLowerCase()) ||
            template.description?.toLowerCase().includes(query.toLowerCase()) ||
            template.tags?.some((tag) =>
                tag.name.en.toLowerCase().includes(query.toLowerCase())
            )
        );
    }

    const templateMap = useMemo(() => {
        return {
            "Your Templates":
                templates?.user.filter(
                    (template) =>
                        template.original_user_id === user?.id &&
                        filterTemplates(template, query, contentBlockConfig)
                ) || [],
            "Templates for this simulation":
                templates?.simulation.filter((template) =>
                    filterTemplates(template, query, contentBlockConfig)
                ) || [],
            "Templates Shared With You":
                templates?.user.filter(
                    (template) =>
                        template.original_user_id !== user?.id &&
                        filterTemplates(template, query, contentBlockConfig)
                ) || [],
        };
    }, [templates, user, query, contentBlockConfig]);

    return { templateMap, query, setQuery };
};
