import React from "react";
import { CohortShape, TeamUserRoundPivot } from "@/models";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../Admin/editor/QueryClient";
import CompareTables from "./CompareTables";

//TODO: do we want to add or remove anything from this type?

type CohortWithTeamUserRounds = CohortShape & {
    team_user_rounds: (TeamUserRoundPivot & {
        user: { name: string };
        id: string;
    })[];
};
type Props = {
    cohorts: { [index: string]: CohortWithTeamUserRounds };
    simulation: string;
    teamUserRoundId?: string;
};

export default function CompareVariableCalculations({
    cohorts,
    simulation,
    teamUserRoundId,
}: Props) {
    return (
        <QueryClientProvider client={queryClient}>
            <CompareTables
                cohorts={cohorts}
                simulation={simulation}
                teamUserRoundId={teamUserRoundId}
            />
        </QueryClientProvider>
    );
}
