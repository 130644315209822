import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import axios from "axios";

type PostBody =
    | (typeof LaravelRequestBodyShapes)["creator.design.dynamic-validation.store"];

export async function saveDynamicValidationRule({
    postBody,
    dynamic_validation,
}: {
    postBody: PostBody;
    dynamic_validation?: string;
}) {
    console.log("postBody", postBody);
    const route = !!dynamic_validation
        ? sapienRoute("creator.design.dynamic-validation.update", {
              dynamic_validation,
          })
        : sapienRoute("creator.design.dynamic-validation.store");
    const method = !!dynamic_validation ? "put" : "post";
    const { data } = await axios[method]<{ contentBlock: ContentBlockShape }>(
        route,
        postBody
    );

    return data;
}
