import React, { useMemo } from "react";
import {
    Cohort,
    ModelBlock,
    ModelVariable,
    ModelVariableScope,
    ModelVariableType,
    ModelVariableUnit,
    PromptType,
    Round,
    Simulation,
} from "@/models";
import { displayPromptType } from "@/util";
import { DiscoveryLayerObject } from "@/hooks/useDiscoveryLayer";
import { DiscoveryMenuItemCheckbox } from "./DiscoveryMenuItemCheckbox";
import { DiscoveryCalculateButton } from "./DiscoveryCalculateButton";

type Props = {
    cohort: Cohort | null;
    simulation: Simulation;
    dropdownMenuSection: string;
    rounds: Round[];
    modelVariables?: ModelVariable[] | null;
    modelBlocks: ModelBlock[];
    discoveryLayerObject: DiscoveryLayerObject;
};

export const DiscoveryMenu = ({
    cohort,
    simulation,
    dropdownMenuSection,
    rounds,
    modelBlocks,
    discoveryLayerObject,
}: Props) => {
    const {
        variableMap,
        valueMap,
        lineageBlockIdsObject,
        blockVariableMap,
        selectedBlockIds,
        selectedPromptIds,
        selectedRoundIds,
        toggleBlockId,
        togglePromptId,
        toggleRoundId,
        selectionsGrouping,
        setSelectionsGrouping,
        resultsGrouping,
        setResultsGrouping,
    } = discoveryLayerObject;

    return (
        <div className="min-w-[632px]">
            {/* TO DO: source filters */}
            {dropdownMenuSection == "source" && (
                <div className="p-2">
                    <div className="flex min-w-[600px] flex-row justify-center">
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`People`}</div>
                            <div
                                key={cohort.id}
                                className="mb-1 flex items-center font-medium italic text-gray-900"
                            >
                                <DiscoveryMenuItemCheckbox
                                    key={cohort.id}
                                    itemKey={cohort.id}
                                    label={`Anyone in Cohort`}
                                    checked={true}
                                    handleOnChange={() => {}}
                                />
                                <DiscoveryCalculateButton
                                    cohortId={cohort.id}
                                    simulationSlug={simulation.slug}
                                />
                            </div>
                            <ul className="space-y-1 text-sm">
                                {!!cohort?.teams?.length &&
                                    cohort.teams.map((team) => (
                                        <React.Fragment key={team.id}>
                                            <li
                                                key={team.id}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={team.id}
                                                    itemKey={team.id}
                                                    label={team.name}
                                                    checked={true}
                                                    handleOnChange={() => {}}
                                                />
                                                <DiscoveryCalculateButton
                                                    cohortId={cohort.id}
                                                    teamId={team.id}
                                                    simulationSlug={
                                                        simulation.slug
                                                    }
                                                />
                                            </li>
                                            {!!team.participants?.length &&
                                                team.participants.map(
                                                    (participant) => (
                                                        <li
                                                            key={participant.id}
                                                            className="flex items-center pl-7 font-medium text-gray-600"
                                                        >
                                                            <DiscoveryMenuItemCheckbox
                                                                key={
                                                                    participant.id
                                                                }
                                                                itemKey={
                                                                    participant.id
                                                                }
                                                                label={
                                                                    participant.name
                                                                }
                                                                checked={true}
                                                                handleOnChange={() => {}}
                                                            />
                                                        </li>
                                                    ),
                                                )}
                                        </React.Fragment>
                                    ))}
                            </ul>
                        </div>
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`Progress`}</div>
                            <div
                                key={"rounds"}
                                className="mb-1 flex items-center font-medium italic text-gray-900"
                            >
                                <DiscoveryMenuItemCheckbox
                                    key={"rounds"}
                                    itemKey={"rounds"}
                                    label={`No Round or Question Completion Requirements`}
                                    checked={true}
                                    handleOnChange={() => {}}
                                />
                            </div>
                            {!!rounds?.length && (
                                <RoundAndQuestionCheckboxList
                                    rounds={rounds}
                                    selectedRoundIds={{}}
                                    selectedPromptIds={{}}
                                    toggleRoundId={() => null}
                                    togglePromptId={() => null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
            {/* TO DO: data filters */}
            {dropdownMenuSection == "data" && (
                <div className="p-2">
                    <div className="flex flex-row justify-center">
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`Questions`}</div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"scope"}
                                        itemKey={"scope"}
                                        label={`Any Prompt Scope`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    <li
                                        key={"Participant"}
                                        className="flex items-center font-semibold text-gray-800"
                                    >
                                        <DiscoveryMenuItemCheckbox
                                            key={"Participant"}
                                            itemKey={"Participant"}
                                            label={"Participant"}
                                            checked={false}
                                            handleOnChange={() => {}}
                                        />
                                    </li>
                                    <li
                                        key={"Team"}
                                        className="flex items-center font-semibold text-gray-800"
                                    >
                                        <DiscoveryMenuItemCheckbox
                                            key={"Team"}
                                            itemKey={"Team"}
                                            label={"Team"}
                                            checked={false}
                                            handleOnChange={() => {}}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"scope"}
                                        itemKey={"scope"}
                                        label={`Any Prompt Type`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {Object.keys(PromptType).map((key) => (
                                        <li
                                            key={key}
                                            className="flex items-center font-semibold text-gray-800"
                                        >
                                            <DiscoveryMenuItemCheckbox
                                                key={key}
                                                itemKey={key}
                                                label={key}
                                                checked={false}
                                                handleOnChange={() => {}}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <div className="mb-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"selections"}
                                        itemKey={"selections"}
                                        label={`Any Round`}
                                        checked={
                                            // all rounds are selected
                                            rounds?.filter(
                                                (round) =>
                                                    !!round.prompts?.length,
                                            ).length ===
                                            Object.keys(selectedRoundIds).length
                                        }
                                        handleOnChange={() => {
                                            if (
                                                rounds?.filter(
                                                    (round) =>
                                                        !!round.prompts?.length,
                                                ).length ===
                                                Object.keys(selectedRoundIds)
                                                    .length
                                            ) {
                                                // if all rounds are selected, deselect all
                                                toggleRoundId();
                                            } else {
                                                // otherwise select all rounds that aren't
                                                toggleRoundId("ALL");
                                            }
                                        }}
                                    />
                                </div>
                                {!!rounds?.length && (
                                    <RoundAndQuestionCheckboxList
                                        rounds={rounds}
                                        selectedRoundIds={selectedRoundIds}
                                        selectedPromptIds={selectedPromptIds}
                                        toggleRoundId={toggleRoundId}
                                        togglePromptId={togglePromptId}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`Variables`}</div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"variable-type"}
                                        itemKey={"variable-type"}
                                        label={`Any Variable Type`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {Object.keys(ModelVariableType)
                                        .filter((key) => key !== "Independent")
                                        .map((key) => (
                                            <li
                                                key={key}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={key}
                                                    itemKey={key}
                                                    label={key}
                                                    checked={false}
                                                    handleOnChange={() => {}}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"variable-scope"}
                                        itemKey={"variable-scope"}
                                        label={`Any Variable Scope`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {Object.keys(ModelVariableScope)
                                        .filter((key) => key !== "Simulation")
                                        .map((key) => (
                                            <li
                                                key={key}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={key}
                                                    itemKey={key}
                                                    label={key}
                                                    checked={false}
                                                    handleOnChange={() => {}}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"variable-unit"}
                                        itemKey={"variable-unit"}
                                        label={`Any Variable Unit`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {Object.keys(ModelVariableUnit)
                                        .filter((key) => key !== "Custom")
                                        .map((key) => (
                                            <li
                                                key={key}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={key}
                                                    itemKey={key}
                                                    label={
                                                        key == "Dollars"
                                                            ? "Currency"
                                                            : key
                                                    }
                                                    checked={false}
                                                    handleOnChange={() => {}}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className="border-box mb-2 border-b border-[#c3c3c340] pb-2">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"variable-time"}
                                        itemKey={"variable-time"}
                                        label={`Any Variable Time Usage`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {["Time Horizons", "No Time Horizons"].map(
                                        (key) => (
                                            <li
                                                key={key}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={key}
                                                    itemKey={key}
                                                    label={key}
                                                    checked={false}
                                                    handleOnChange={() => {}}
                                                />
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                            <div>
                                <div className="mb-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"results"}
                                        itemKey={"results"}
                                        label={`Any Model Block`}
                                        checked={
                                            Object.keys(blockVariableMap)
                                                .length ===
                                            Object.keys(selectedBlockIds).length
                                        }
                                        handleOnChange={() => {
                                            if (
                                                Object.keys(blockVariableMap)
                                                    .length ===
                                                Object.keys(selectedBlockIds)
                                                    .length
                                            ) {
                                                // if all blocks are selected, deselect all
                                                toggleBlockId();
                                            } else {
                                                // otherwise select all blocks that aren't
                                                toggleBlockId("ALL");
                                            }
                                        }}
                                    />
                                </div>
                                <ul className="space-y-1 text-sm">
                                    {!!modelBlocks &&
                                        !!blockVariableMap &&
                                        !!selectedBlockIds &&
                                        !!lineageBlockIdsObject &&
                                        !!variableMap &&
                                        !!Object.keys(variableMap)?.length &&
                                        !!valueMap &&
                                        modelBlocks
                                            .filter(
                                                (modelBlock) =>
                                                    !!lineageBlockIdsObject[
                                                        modelBlock.id
                                                    ], // filter out blocks without variable values
                                            )
                                            .map((modelBlock) => (
                                                <ModelBlockCheckboxList
                                                    key={modelBlock.id}
                                                    modelBlock={modelBlock}
                                                    selectedBlockIds={
                                                        selectedBlockIds
                                                    }
                                                    toggleBlockId={
                                                        toggleBlockId
                                                    }
                                                    blockVariableMap={
                                                        blockVariableMap
                                                    }
                                                    lineageIdMap={
                                                        lineageBlockIdsObject
                                                    }
                                                />
                                            ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* TO DO: display options */}
            {dropdownMenuSection == "display" && (
                <div className="p-2">
                    <div className="flex flex-row justify-center">
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`Selections`}</div>
                            <div className="mb-3">
                                <div className="mb-1 flex items-center text-sm font-medium text-gray-900">
                                    Group By
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"selection-group-question"}
                                        itemKey={"selection-group-question"}
                                        label={`Question`}
                                        checked={
                                            selectionsGrouping === "question"
                                        }
                                        handleOnChange={() => {
                                            setSelectionsGrouping("question");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"selection-group-people"}
                                        itemKey={"selection-group-people"}
                                        label={`Participant/Team`}
                                        checked={
                                            selectionsGrouping === "people"
                                        }
                                        handleOnChange={() => {
                                            setSelectionsGrouping("people");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"selection-group-type-scope"}
                                        itemKey={"selection-group-type-scope"}
                                        label={`Type/Scope`}
                                        checked={selectionsGrouping === "type"}
                                        handleOnChange={() => {
                                            setSelectionsGrouping("type");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="m-2 min-w-[300px] p-2">
                            <div className="border-box mb-2 border-b border-[#c3c3c3] font-semibold">{`Results`}</div>
                            <div className="mb-3">
                                <div className="mb-1 flex items-center text-sm font-medium italic text-gray-900">
                                    Group By
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"result-group-variable"}
                                        itemKey={"result-group-variable"}
                                        label={`Variable`}
                                        checked={resultsGrouping === "variable"}
                                        handleOnChange={() => {
                                            setResultsGrouping("variable");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"result-group-people"}
                                        itemKey={"result-group-people"}
                                        label={`Participant/Team`}
                                        checked={resultsGrouping === "people"}
                                        handleOnChange={() => {
                                            setResultsGrouping("people");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                                <div className="mb-1 ml-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"result-group-time"}
                                        itemKey={"result-group-time"}
                                        label={`Time Horizon`}
                                        checked={resultsGrouping === "time"}
                                        handleOnChange={() => {
                                            setResultsGrouping("time");
                                        }}
                                        inputType={"radio"}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="mb-1 flex items-center font-medium italic text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"time-horizons"}
                                        itemKey={"time-horizons"}
                                        label={`Show All Time Horizons`}
                                        checked={true}
                                        handleOnChange={() => {}}
                                    />
                                </div>
                                <ul className="flex items-center space-y-1 text-sm">
                                    {simulation?.timeHorizons.map(
                                        (timeHorizon) => (
                                            <li
                                                key={timeHorizon.id}
                                                className="flex items-center pr-3 font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={timeHorizon.id}
                                                    itemKey={timeHorizon.id}
                                                    label={timeHorizon.time_index.toString()}
                                                    checked={false}
                                                    handleOnChange={() => {}}
                                                />
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const RoundAndQuestionCheckboxList = ({
    rounds,
    selectedRoundIds,
    selectedPromptIds,
    toggleRoundId,
    togglePromptId,
}: {
    rounds: Round[];
    selectedRoundIds: {};
    selectedPromptIds: {};
    toggleRoundId: (roundId?: string) => void;
    togglePromptId: (promptId?: string) => void;
}) => {
    return (
        <ul className="space-y-1 text-sm">
            {rounds
                .filter((round) => !!round.prompts?.length)
                .map((round) => (
                    <React.Fragment key={round.id}>
                        <li
                            key={round.id}
                            className="flex items-center font-semibold text-gray-800"
                        >
                            <DiscoveryMenuItemCheckbox
                                key={round.id}
                                itemKey={round.id}
                                label={round.title}
                                checked={!!selectedRoundIds[round.id]}
                                handleOnChange={() => toggleRoundId(round.id)}
                            />
                        </li>
                        {round.prompts.map((prompt) => (
                            <li
                                key={prompt.id}
                                className="flex items-center pl-7 font-medium text-gray-600"
                            >
                                <DiscoveryMenuItemCheckbox
                                    key={prompt.id}
                                    itemKey={prompt.id}
                                    label={
                                        (prompt.content.length < 40
                                            ? prompt.content
                                            : prompt.content.substring(0, 37) +
                                              "...") +
                                        ` — ${displayPromptType(
                                            prompt.prompt_type,
                                        ).toUpperCase()}`
                                    }
                                    checked={!!selectedPromptIds[prompt.id]}
                                    handleOnChange={() =>
                                        togglePromptId(prompt.id)
                                    }
                                />
                            </li>
                        ))}
                    </React.Fragment>
                ))}
        </ul>
    );
};

const ModelBlockCheckboxList = ({
    selectedBlockIds,
    toggleBlockId,
    modelBlock,
    blockVariableMap,
    lineageIdMap,
}: {
    selectedBlockIds: { [index: string]: boolean };
    toggleBlockId: (blockId?: string) => void;
    modelBlock: ModelBlock;
    blockVariableMap: { [index: string]: ModelVariable[] };
    lineageIdMap: { [index: string]: boolean };
}) => {
    const childBlocksToShow = useMemo(() => {
        return (
            !!modelBlock.modelBlocks?.length &&
            modelBlock.modelBlocks.filter((child) => !!lineageIdMap[child.id])
        );
    }, [modelBlock, blockVariableMap]);

    return (
        <>
            {!!blockVariableMap[modelBlock.id]?.length && (
                <>
                    <li
                        key={modelBlock.id}
                        className="flex items-center font-semibold text-gray-800"
                    >
                        <DiscoveryMenuItemCheckbox
                            key={modelBlock.id}
                            itemKey={modelBlock.id}
                            label={modelBlock.label}
                            checked={!!selectedBlockIds[modelBlock.id]}
                            handleOnChange={() =>
                                !!blockVariableMap[modelBlock.id]?.length &&
                                toggleBlockId(modelBlock.id)
                            }
                        />
                    </li>
                    {blockVariableMap[modelBlock.id].map((variable) => (
                        <li
                            key={variable.id}
                            className="flex items-center pl-7 font-medium italic text-gray-600"
                        >
                            <DiscoveryMenuItemCheckbox
                                key={variable.id}
                                itemKey={variable.id}
                                label={variable.label}
                                checked={!!selectedBlockIds[modelBlock.id]} // to do
                                handleOnChange={() => {}} // to do
                            />
                        </li>
                    ))}
                </>
            )}
            {!!childBlocksToShow?.length &&
                childBlocksToShow.map((child) => (
                    <ModelBlockCheckboxList
                        key={child.id}
                        modelBlock={child}
                        selectedBlockIds={selectedBlockIds}
                        toggleBlockId={toggleBlockId}
                        blockVariableMap={blockVariableMap}
                        lineageIdMap={lineageIdMap}
                    />
                ))}
        </>
    );
};
