import React from "react";
import { CheckIcon } from "lucide-react";

export const Progress = ({
    percentage,
    size,
    strokeColor,
    strokeWidth,
    trailColor,
    textColor,
    showPercentage = true,
}: {
    percentage: number;
    size: number; // diameter of the circle
    strokeWidth: number;
    strokeColor?: string;
    trailColor?: string;
    textColor?: string;
    showPercentage?: boolean;
}) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    if (percentage >= 100) {
        return (
            <div
                style={{ width: size, height: size }}
                className={
                    "flex items-center justify-center rounded-full bg-current"
                }
            >
                <CheckIcon size={size * 0.5} className="text-white" />
            </div>
        );
    }
    return (
        <svg width={size} height={size} className="mx-auto block">
            <circle
                className={`stroke-current ${trailColor}`}
                strokeWidth={strokeWidth}
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={radius}
            />
            <circle
                className={`stroke-current ${strokeColor}`}
                strokeWidth={strokeWidth}
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                style={{ transition: "stroke-dashoffset 0.35s" }}
            />
            {!!showPercentage && (
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    className={`font-bold ${textColor}`}
                    fontSize={size * 0.2}
                >
                    {`${percentage}%`}
                </text>
            )}
        </svg>
    );
};
