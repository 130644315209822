import React from "react";
import {
    useModelBlockArray,
    useModelBlockStore,
    useModelVariableStore,
} from "../../hooks/store";
import { SortableModelVariableTree } from "./SortableModelVariableTree";

export const VariableOrganization = () => {
    const { modelBlocks } = useModelBlockStore();
    const { modelBlockArray } = useModelBlockArray();
    const { modelVariables } = useModelVariableStore();

    return (
        <div>
            <SortableModelVariableTree
                modelBlocks={modelBlocks}
                modelBlockArray={modelBlockArray}
                modelVariables={modelVariables}
            />
        </div>
    );
};
