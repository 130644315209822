import {
    ContentBlock,
    ContentBlockType,
    Option,
    Prompt,
    PromptType,
} from "../models";
import { TemplateType } from "./TemplateModels";
import {
    EventDecisionContentBlocks,
    OrganizationOverviewContentBlocks,
    ResourceAllocationContentBlocks,
    StrategicPathContentBlocks,
    TrendsAssessmentContentBlocks,
    WelcomePageContentBlocks,
} from "./content-blocks";
import { ContentBlockConfigObject } from "../model-configs/ContentBlockConfigObject";
//TODO: make background size and position adjustable, and maybe even adjustable for diff screen sizes
//allow background color/gradiant on top of image
interface FragmentOption {
    content: string;
}
interface FragmentPrompt {
    prompt_type: PromptType;
    content: string;
    options: FragmentOption[];
    min?: number;
    max?: number;
    increment?: number;
}
export interface FragmentContentBlock {
    content_block_type: ContentBlockType;
    content?: string;
    title?: string;
    route?: string;
    contentBlocks?: FragmentContentBlock[];
    prompt?: FragmentPrompt;
    image_url?: string;
    theme?: any;
}

const setThemedContentBlock = (contentBlock: ContentBlock) => {
    if (!contentBlock) return null;
    // fill in theme with default if properties are missing
    return {
        ...contentBlock,
        theme: {
            ...ContentBlockConfigObject[contentBlock.content_block_type]
                .defaultTheme,
            ...contentBlock.theme,
        },
    } as ContentBlock;
};

const createNestedContentBlocks = (
    fragmentContentBlocks: FragmentContentBlock[]
) => {
    let contentBlocks = fragmentContentBlocks.map((cb, i) => {
        let contentBlock = setThemedContentBlock(
            new ContentBlock({ ...cb, weight: i })
        );

        if (cb.contentBlocks) {
            let children = createNestedContentBlocks(cb.contentBlocks);
            contentBlock = {
                ...contentBlock,
                contentBlocks: children,
            } as ContentBlock;
        }

        if (cb.prompt) {
            let prompt = new Prompt({
                ...cb.prompt,
                options: cb.prompt.options.map(
                    (option, j) => new Option({ ...option, weight: j })
                ),
            });
            contentBlock = {
                ...contentBlock,
                prompt: prompt,
            } as ContentBlock;
        }

        return contentBlock;
    });

    return contentBlocks;
};

export const TemplateContentBlocks: {
    [index in TemplateType]: ContentBlock[];
} = {
    [TemplateType["Trends Assessment"]]: createNestedContentBlocks(
        TrendsAssessmentContentBlocks
    ),

    [TemplateType["Resource Allocation"]]: createNestedContentBlocks(
        ResourceAllocationContentBlocks
    ),

    [TemplateType["Welcome Page"]]: createNestedContentBlocks(
        WelcomePageContentBlocks
    ),

    [TemplateType["Strategic Path"]]: createNestedContentBlocks(
        StrategicPathContentBlocks
    ),

    [TemplateType["Event Decision"]]: createNestedContentBlocks(
        EventDecisionContentBlocks
    ),

    [TemplateType["Organization Overview"]]: createNestedContentBlocks(
        OrganizationOverviewContentBlocks
    ),
};
