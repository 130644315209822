import React, { useMemo } from "react";
import { ContentBlockType } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { useAddContentBlockState } from "../content-block-buttons/useAddContentBlockState";

export default function ChooseModalTriggerModal() {
    const {
        addContentBlockState,
        setIsAddingModalToExistingContentBlock,
        setNewModalTriggerType,
        reset,
    } = useAddContentBlockState();

    const isOpen = useMemo(() => {
        return (
            !addContentBlockState.isAddingModalToExistingContentBlock &&
            !addContentBlockState.newModalTriggerType &&
            addContentBlockState?.addableContentBlockMetaData
                ?.blockOrLayoutType === ContentBlockType.Modal
        );
    }, [addContentBlockState]);

    return (
        <ModalContainer
            size="s"
            isModalOpen={isOpen}
            backgroundColor="#111928"
            classNames="transform-y-1/2"
        >
            {!!isOpen && (
                <div className="p-6 text-white flex flex-col space-y-4 cursor-auto">
                    <div>
                        <h3>How should the modal be opened</h3>
                        <p className="text-sm text-gray-100">
                            You can decide how players open the modal to see
                            your content.
                        </p>
                    </div>
                    <ul className="flex-col space-y-2 list-none py-4 px-0">
                        <li className="list-none flex flex-col space-y-2">
                            <button
                                role="button"
                                onClick={() =>
                                    setNewModalTriggerType(
                                        ContentBlockType.Button
                                    )
                                }
                                className="rounded-md cursor-pointer bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Create a Button
                            </button>
                            <button
                                role="button"
                                onClick={() =>
                                    setNewModalTriggerType(
                                        ContentBlockType.Link
                                    )
                                }
                                className="rounded-md cursor-pointer bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Create a Link
                            </button>
                            <button
                                onClick={() =>
                                    setIsAddingModalToExistingContentBlock(true)
                                }
                                className="rounded-md cursor-pointer bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Choose an existing content block
                            </button>
                        </li>
                    </ul>
                    <div className="border-t border-[#374151] pt-6 flex justify-end">
                        <button
                            onClick={() => {
                                reset();
                            }}
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
}
