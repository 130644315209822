import { ModelBlock } from "@/models";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { dataModelBlocksAtom } from "./state";
import { useEffect } from "react";

// MODULE DETAIL INTERFACE STATE/HOOKS

const colorModeClassAtom = atom<"" | "dark">("");
export const useColorModeClass = () => {
    const colorModeClass = useAtomValue(colorModeClassAtom);
    return colorModeClass;
};
export const useSetColorModeClass = () => {
    const setColorModeClass = useSetAtom(colorModeClassAtom);
    return setColorModeClass;
};

// settings section state

const settingsTabIndexAtom = atom(0);
const useSettingsTabIndex = () => {
    const settingsTabIndex = useAtomValue(settingsTabIndexAtom);
    return settingsTabIndex;
};
const useSetSettingsTabIndex = () => {
    const setSettingsTabIndex = useSetAtom(settingsTabIndexAtom);
    return setSettingsTabIndex;
};

const showSettingsChartsAtom = atom(true);
const useShowSettingsCharts = () => {
    const showSettingsCharts = useAtomValue(showSettingsChartsAtom);
    return showSettingsCharts;
};
const useSetShowSettingsCharts = () => {
    const setShowSettingsCharts = useSetAtom(showSettingsChartsAtom);
    return setShowSettingsCharts;
};

const selectedSettingsModelBlockAtom = atom<ModelBlock>(
    undefined as ModelBlock,
);
const useSelectedSettingsModelBlock = () => {
    const selectedSettingsModelBlock = useAtomValue(
        selectedSettingsModelBlockAtom,
    );
    return selectedSettingsModelBlock;
};
const useSetSelectedSettingsModelBlock = () => {
    const setSelectedSettingsModelBlock = useSetAtom(
        selectedSettingsModelBlockAtom,
    );
    return setSelectedSettingsModelBlock;
};

const modelBlockDisplayMapSettingsAtom = atom<Record<string, boolean>>({});
const useModelBlockDisplayMapSettings = () => {
    const modelBlockDisplayMapSettings = useAtomValue(
        modelBlockDisplayMapSettingsAtom,
    );
    return modelBlockDisplayMapSettings;
};
const useSetModelBlockDisplayMapSettings = () => {
    const setModelBlockDisplayMapSettings = useSetAtom(
        modelBlockDisplayMapSettingsAtom,
    );
    return setModelBlockDisplayMapSettings;
};

const hasOpenSettingsBlocksAtom = atom<boolean | undefined>((get) => {
    const modelBlockDisplayMapSettings = get(modelBlockDisplayMapSettingsAtom);
    return (
        Object.values(modelBlockDisplayMapSettings).some(
            (setting) => !!setting,
        ) ?? false
    );
});
const useHasOpenSettingsBlocks = () => {
    const hasOpenSettingsBlocks = useAtomValue(hasOpenSettingsBlocksAtom);
    return hasOpenSettingsBlocks;
};

// settings section hook

export const useInterfaceStateSettings = () => {
    const settingsTabIndex = useSettingsTabIndex();
    const setSettingsTabIndex = useSetSettingsTabIndex();
    const showSettingsCharts = useShowSettingsCharts();
    const setShowSettingsCharts = useSetShowSettingsCharts();
    const selectedSettingsModelBlock = useSelectedSettingsModelBlock();
    const setSelectedSettingsModelBlock = useSetSelectedSettingsModelBlock();
    const modelBlockDisplayMapSettings = useModelBlockDisplayMapSettings();
    const setModelBlockDisplayMapSettings =
        useSetModelBlockDisplayMapSettings();
    const hasOpenSettingsBlocks = useHasOpenSettingsBlocks();

    return {
        settingsTabIndex,
        setSettingsTabIndex,
        showSettingsCharts,
        setShowSettingsCharts,
        selectedSettingsModelBlock,
        setSelectedSettingsModelBlock,
        modelBlockDisplayMapSettings,
        setModelBlockDisplayMapSettings,
        hasOpenSettingsBlocks,
    };
};

// data section state

const dataTabIndexAtom = atom(0);
const useDataTabIndex = () => {
    const dataTabIndex = useAtomValue(dataTabIndexAtom);
    return dataTabIndex;
};
const useSetDataTabIndex = () => {
    const setDataTabIndex = useSetAtom(dataTabIndexAtom);
    return setDataTabIndex;
};

export const showSettingsTableRowsAtom = atom(false);
const useShowSettingsTableRows = () => {
    const showSettingsTableRows = useAtomValue(showSettingsTableRowsAtom);
    return showSettingsTableRows;
};
const useSetShowSettingsTableRows = () => {
    const setShowSettingsTableRows = useSetAtom(showSettingsTableRowsAtom);
    return setShowSettingsTableRows;
};

const showDataInMonthsNotYearsAtom = atom(0);
const useShowDataInMonthsNotYears = () => {
    const showDataInMonthsNotYears = useAtomValue(showDataInMonthsNotYearsAtom);
    return showDataInMonthsNotYears;
};
const useSetShowDataInMonthsNotYears = () => {
    const setShowDataInMonthsNotYears = useSetAtom(
        showDataInMonthsNotYearsAtom,
    );
    return setShowDataInMonthsNotYears;
};

const selectedDataModelBlockAtom = atom<ModelBlock>(undefined as ModelBlock);
const useSelecteDataModelBlock = () => {
    const selectedDataModelBlock = useAtomValue(selectedDataModelBlockAtom);
    return selectedDataModelBlock;
};
const useSetSelecteDataModelBlock = () => {
    const setSelectedDataModelBlock = useSetAtom(selectedDataModelBlockAtom);
    return setSelectedDataModelBlock;
};

const modelBlockDisplayMapDataAtom = atom<Record<string, boolean>>({});
const useModelBlockDisplayMapData = () => {
    const modelBlockDisplayMapData = useAtomValue(modelBlockDisplayMapDataAtom);
    return modelBlockDisplayMapData;
};
const useSetModelBlockDisplayMapData = () => {
    const setModelBlockDisplayMapData = useSetAtom(
        modelBlockDisplayMapDataAtom,
    );
    return setModelBlockDisplayMapData;
};

const hasOpenDataBlocksAtom = atom<boolean | undefined>((get) => {
    const dataModelBlocks = get(dataModelBlocksAtom);
    const modelBlockDisplayMapData = get(modelBlockDisplayMapDataAtom);
    return dataModelBlocks.some(
        (block) => !!modelBlockDisplayMapData[block.id],
    );
});
const useHasOpenDataBlocks = () => {
    const hasOpenDataBlocks = useAtomValue(hasOpenDataBlocksAtom);
    return hasOpenDataBlocks;
};

// data section hook

export const useInterfaceStateData = () => {
    const dataTabIndex = useDataTabIndex();
    const setDataTabIndex = useSetDataTabIndex();
    const showSettingsTableRows = useShowSettingsTableRows();
    const setShowSettingsTableRows = useSetShowSettingsTableRows();
    const showDataInMonthsNotYears = useShowDataInMonthsNotYears();
    const setShowDataInMonthsNotYears = useSetShowDataInMonthsNotYears();
    const selectedDataModelBlock = useSelecteDataModelBlock();
    const setSelectedDataModelBlock = useSetSelecteDataModelBlock();
    const modelBlockDisplayMapData = useModelBlockDisplayMapData();
    const setModelBlockDisplayMapData = useSetModelBlockDisplayMapData();
    const hasOpenDataBlocks = useHasOpenDataBlocks();

    return {
        dataTabIndex,
        setDataTabIndex,
        showSettingsTableRows,
        setShowSettingsTableRows,
        showDataInMonthsNotYears,
        setShowDataInMonthsNotYears,
        selectedDataModelBlock,
        setSelectedDataModelBlock,
        modelBlockDisplayMapData,
        setModelBlockDisplayMapData,
        hasOpenDataBlocks,
    };
};

// design section state

const showDesignChartControlsAtom = atom(false);
const useShowDesignChartControls = () => {
    const showDesignChartControls = useAtomValue(showDesignChartControlsAtom);
    return showDesignChartControls;
};
const useSetShowDesignChartControls = () => {
    const setShowDesignChartControls = useSetAtom(showDesignChartControlsAtom);
    return setShowDesignChartControls;
};

// design section hook

export const useInterfaceStateDesign = () => {
    const showDesignChartControls = useShowDesignChartControls();
    const setShowDesignChartControls = useSetShowDesignChartControls();

    return {
        showDesignChartControls,
        setShowDesignChartControls,
    };
};

// insights section state

const insightsTabIndexAtom = atom(0);
const useInsightsTabIndex = () => {
    const insightsTabIndex = useAtomValue(insightsTabIndexAtom);
    return insightsTabIndex;
};
const useSetInsightsTabIndex = () => {
    const setInsightsTabIndex = useSetAtom(insightsTabIndexAtom);
    return setInsightsTabIndex;
};

// insights section hook

export const useInterfaceStateInsights = () => {
    const insightsTabIndex = useInsightsTabIndex();
    const setInsightsTabIndex = useSetInsightsTabIndex();

    return {
        insightsTabIndex,
        setInsightsTabIndex,
    };
};

// module detail interface state reset

export const useResetInterfaceState = () => {
    const setSettingsTabIndex = useSetSettingsTabIndex();
    const setShowSettingsCharts = useSetShowSettingsCharts();
    const setSelectedSettingsModelBlock = useSetSelectedSettingsModelBlock();
    const setModelBlockDisplayMapSettings =
        useSetModelBlockDisplayMapSettings();
    const setDataTabIndex = useSetDataTabIndex();
    const setShowSettingsTableRows = useSetShowSettingsTableRows();
    const setShowDataInMonthsNotYears = useSetShowDataInMonthsNotYears();
    const setSelectedDataModelBlock = useSetSelecteDataModelBlock();
    const setModelBlockDisplayMapData = useSetModelBlockDisplayMapData();
    const setShowDesignChartControls = useSetShowDesignChartControls();
    const setInsightsTabIndex = useSetInsightsTabIndex();

    useEffect(() => {
        // settings
        setSettingsTabIndex(0);
        setShowSettingsCharts(true);
        setSelectedSettingsModelBlock(undefined);
        setModelBlockDisplayMapSettings({});
        // data
        setDataTabIndex(0);
        setShowSettingsTableRows(false);
        setShowDataInMonthsNotYears(0);
        setSelectedDataModelBlock(undefined);
        setModelBlockDisplayMapData({});
        // design
        setShowDesignChartControls(false);
        // insights
        setInsightsTabIndex(0);
    }, []);
};
