import React, { useEffect, useState } from "react";
import { Head } from "@inertiajs/react";
import { InteractiveModuleTopNav } from "./InteractiveModuleTopNav";
import {
    getThemeInStorage,
    setThemeInStorage,
} from "./InteractiveColorModeButton";
import { useSetColorModeClass } from "./useInterfaceState";

const InteractiveModuleWrapperComponent = ({
    simulationTitle,
    simulationSlug,
    modelBlockLabel,
    children,
}: {
    simulationTitle: string;
    simulationSlug: string;
    modelBlockLabel?: string;
    children: React.ReactNode;
}) => {
    const setColorModeClassAtom = useSetColorModeClass();
    const [colorModeClass, setColorModeClass] = useState<"" | "dark">(
        getThemeInStorage() ?? "dark",
    );

    useEffect(() => {
        setColorModeClassAtom(colorModeClass);
    }, []);

    useEffect(() => {
        if (colorModeClass !== getThemeInStorage()) {
            setThemeInStorage(colorModeClass);
            setColorModeClassAtom(colorModeClass);
        }
    }, [colorModeClass]);

    return (
        <>
            <Head title={simulationTitle} />
            <div
                className={`interactive-container ${colorModeClass} flex-flex-col relative h-[100vh]
                overflow-hidden transition-all dark:bg-slate-900`}
            >
                <InteractiveModuleTopNav
                    simulationTitle={simulationTitle}
                    simulationSlug={simulationSlug}
                    modelBlockLabel={modelBlockLabel}
                    colorModeClass={colorModeClass}
                    setColorModeClass={setColorModeClass}
                />
                <div className="interactive-bottom flex h-[calc(100vh-44px)]">
                    {children}
                </div>
            </div>
        </>
    );
};

export const InteractiveModuleWrapper = React.memo(
    InteractiveModuleWrapperComponent,
);
