import React, { useCallback, useEffect, useMemo } from "react";
import {
    ModelBlock,
    ModelVariable,
    ModelVariableDataType,
    ModelVariableType,
    VariableRelationship,
    VariableRelationshipOperation,
} from "@/models";
import { H6Span } from "@/components";
import {
    getAreRelationshipsValid,
    useAvailableVariables,
    useModelBlockStore,
    useModelVariableStore,
    useRelationshipForm,
    useVariableRelationshipStore,
} from "../../hooks/store";
import { IconButton } from "../../modules/shared";
import { plus } from "react-icons-kit/feather/plus";
import {
    displayVariableRelationshipOperation,
    getVariableRelationshipOperationKeySubset,
} from "@/util";
import { Icon } from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useModelBuilderPage } from "@/inertia-utils/hooks";

export const VariableRelationshipForm = ({
    variableRelationships,
    setVariableRelationships,
    setAreRelationshipsValid,
}: {
    variableRelationships: VariableRelationship[];
    setVariableRelationships: (
        variableRelationships: VariableRelationship[],
    ) => void;
    setAreRelationshipsValid: (areRelationshipsValid: boolean) => void;
}) => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { timeHorizons } = modelBuilderPageProps;

    const { activeModelVariable, modelVariables } = useModelVariableStore();
    const { relationshipsBySourceId, relationshipsByTargetId } =
        useVariableRelationshipStore();
    const { modelBlocks } = useModelBlockStore();

    const variableRelationshipMap = useMemo<{
        [index: string]: VariableRelationship;
    }>(() => {
        if (!!variableRelationships && !!variableRelationships.length) {
            return variableRelationships.reduce(
                (map, variableRelationship, index) => ({
                    ...map,
                    [variableRelationship.source_variable_id || index]:
                        variableRelationship,
                }),
                {},
            );
        }
    }, [variableRelationships]);

    const {
        updateRelationship,
        addNewVariableRelationship,
        removeUnsavedVariableRelationship,
    } = useRelationshipForm(
        activeModelVariable,
        variableRelationships,
        setVariableRelationships,
    );

    const { availableVariablesMap } = useAvailableVariables(
        activeModelVariable,
        variableRelationshipMap,
        modelVariables,
        relationshipsBySourceId,
        relationshipsByTargetId,
    );

    useEffect(() => {
        if (
            !!activeModelVariable &&
            !!activeModelVariable.id &&
            !!relationshipsByTargetId &&
            !!relationshipsByTargetId[activeModelVariable.id]
        ) {
            setVariableRelationships(
                relationshipsByTargetId[activeModelVariable.id].map(
                    (relationship, index) => ({
                        ...relationship,
                        weight: index,
                    }),
                ),
            );
        } else {
            setVariableRelationships([]);
        }
    }, [activeModelVariable?.id, relationshipsByTargetId]);

    useEffect(() => {
        setAreRelationshipsValid(
            getAreRelationshipsValid(
                activeModelVariable,
                variableRelationships,
                modelVariables,
            ),
        );
    }, [activeModelVariable, variableRelationships]);

    const getIsVariableOptionDisabled = useCallback(
        (variableId: string) => {
            return !!variableRelationshipMap[variableId];
        },
        [variableRelationshipMap],
    );

    const getVariablesToDisplayInSelectMenu = useCallback(
        (modelBlockVariables: ModelVariable[], index: number) => {
            return modelBlockVariables.filter(
                (variable: ModelVariable) =>
                    !!availableVariablesMap[variable.id] &&
                    (activeModelVariable.variable_type !==
                        ModelVariableType.Conditional ||
                        (index % 2 === 0
                            ? variable.data_type ===
                              ModelVariableDataType.Boolean // || variable.variable_type === ModelVariableType["Selection Data"]
                            : variable.data_type ===
                              ModelVariableDataType.Number)),
            );
        },
        [activeModelVariable, availableVariablesMap],
    );

    return (
        <>
            <div className="flex flex-row items-center py-4">
                <H6Span>Variable Relationships</H6Span>
                <span>
                    <IconButton
                        icon={plus}
                        handler={() => addNewVariableRelationship()}
                        isDisabled={false}
                    />
                </span>
            </div>
            <div className="pb-4 pl-4">
                {!!modelVariables &&
                    !!variableRelationshipMap &&
                    !!variableRelationships &&
                    variableRelationships.map((relationship, index) => (
                        <div
                            className="mb-1 flex flex-row items-center"
                            key={`${relationship.id || index}`}
                        >
                            {!!relationship.operation_type &&
                                (!(
                                    activeModelVariable.variable_type ===
                                    ModelVariableType.Comparison
                                ) ||
                                    index > 0) && (
                                    <>
                                        <div className="text-md mr-2 flex font-semibold">
                                            {getVariableRelationshipOperationKeySubset(
                                                activeModelVariable.variable_type,
                                            ).map((key) => (
                                                <div
                                                    key={
                                                        VariableRelationshipOperation[
                                                            key
                                                        ]
                                                    }
                                                >
                                                    <input
                                                        type="radio"
                                                        className="peer hidden"
                                                        id={`${VariableRelationshipOperation[key]}-${index}`}
                                                        value={`${VariableRelationshipOperation[key]}-${index}`}
                                                        checked={
                                                            relationship.operation_type ===
                                                            VariableRelationshipOperation[
                                                                key
                                                            ]
                                                        }
                                                        onChange={(e) =>
                                                            updateRelationship(
                                                                index,
                                                                "operation_type",
                                                                VariableRelationshipOperation[
                                                                    key
                                                                ],
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="inline-flex w-full cursor-pointer items-center justify-between whitespace-nowrap
                                                            border border-gray-200 bg-white px-3 py-2 text-gray-500
                                                            peer-checked:border-blue-600 peer-checked:bg-blue-50 peer-checked:text-blue-600
                                                            hover:bg-gray-100 hover:text-gray-600"
                                                        htmlFor={`${VariableRelationshipOperation[key]}-${index}`}
                                                    >
                                                        {displayVariableRelationshipOperation(
                                                            VariableRelationshipOperation[
                                                                key
                                                            ],
                                                        )}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            <>
                                <select
                                    value={
                                        relationship.source_variable_id || ""
                                    }
                                    // disabled={!!relationship.id}
                                    onChange={(e) =>
                                        updateRelationship(
                                            index,
                                            "source_variable_id",
                                            e.target.value,
                                        )
                                    }
                                    className="block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm
                                        text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                                >
                                    <option
                                        key={""}
                                        value={undefined}
                                        disabled={true}
                                    />
                                    {!!availableVariablesMap &&
                                        !!modelBlocks &&
                                        Object.values(modelBlocks)
                                            .filter(
                                                (modelBlock) =>
                                                    !modelBlock.has_connections,
                                            )
                                            .map((modelBlock) => (
                                                <OrderedModelVariableSelectOptions
                                                    key={modelBlock.id}
                                                    modelBlock={modelBlock}
                                                    index={index}
                                                    getVariablesToDisplayInSelectMenu={
                                                        getVariablesToDisplayInSelectMenu
                                                    }
                                                    getIsVariableOptionDisabled={
                                                        getIsVariableOptionDisabled
                                                    }
                                                />
                                            ))}
                                    {!!availableVariablesMap &&
                                        !!modelBlocks &&
                                        Object.values(modelBlocks)
                                            .filter(
                                                (modelBlock) =>
                                                    modelBlock.has_connections,
                                            )
                                            .map((modelBlock) => (
                                                <OrderedModelVariableSelectOptions
                                                    key={modelBlock.id}
                                                    modelBlock={modelBlock}
                                                    index={index}
                                                    getVariablesToDisplayInSelectMenu={
                                                        getVariablesToDisplayInSelectMenu
                                                    }
                                                    getIsVariableOptionDisabled={
                                                        getIsVariableOptionDisabled
                                                    }
                                                />
                                            ))}
                                </select>
                            </>
                            {activeModelVariable.uses_time &&
                                !!relationship.source_variable_id &&
                                modelVariables[relationship.source_variable_id]
                                    .uses_time === false && (
                                    <>
                                        <select
                                            value={
                                                relationship.target_time_horizon_id ||
                                                ""
                                            }
                                            onChange={(e) =>
                                                updateRelationship(
                                                    index,
                                                    "target_time_horizon_id",
                                                    e.target.value,
                                                )
                                            }
                                            style={{ minWidth: "75px" }}
                                            className="ml-2 block min-w-[75px] max-w-[250px] rounded border border-gray-300 bg-gray-50
                                                p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                                        >
                                            <option
                                                key={""}
                                                value={undefined}
                                                disabled={true}
                                            />
                                            {timeHorizons?.map(
                                                (timeHorizon) => (
                                                    <option
                                                        key={timeHorizon.id}
                                                        value={timeHorizon.id}
                                                    >
                                                        {timeHorizon.time_index}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                    </>
                                )}
                            <button
                                type="button"
                                className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                    text-gray-800 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0
                                    focus:ring-blue-300"
                                disabled={
                                    !!relationship.id ||
                                    activeModelVariable.variable_type ===
                                        ModelVariableType.Conditional
                                }
                                onClick={() =>
                                    removeUnsavedVariableRelationship(
                                        relationship,
                                        index,
                                    )
                                }
                            >
                                <Icon
                                    icon={x}
                                    size={16}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </button>
                        </div>
                    ))}
            </div>
        </>
    );
};

const OrderedModelVariableSelectOptions = ({
    modelBlock,
    index,
    getVariablesToDisplayInSelectMenu,
    getIsVariableOptionDisabled,
}: {
    modelBlock: ModelBlock;
    index: number;
    getVariablesToDisplayInSelectMenu: (
        modelBlockVariables: ModelVariable[],
        index: number,
    ) => ModelVariable[];
    getIsVariableOptionDisabled: (variableId: string) => boolean;
}) => {
    const variablesToDisplay = useMemo(
        () =>
            getVariablesToDisplayInSelectMenu(modelBlock.modelVariables, index),
        [modelBlock, index, getVariablesToDisplayInSelectMenu],
    );

    return (
        <>
            {!!modelBlock.modelVariables &&
                !!modelBlock.modelVariables.length && (
                    <>
                        {!!variablesToDisplay?.length && (
                            <option
                                key={modelBlock.id}
                                value={modelBlock.id}
                                disabled={true}
                            >
                                {`----- Model Block: ${modelBlock.label} -----`}
                            </option>
                        )}
                        {variablesToDisplay?.map((variable: ModelVariable) => (
                            <option
                                key={variable.id}
                                value={variable.id}
                                disabled={getIsVariableOptionDisabled(
                                    variable.id,
                                )}
                            >
                                {variable.label}
                            </option>
                        ))}
                    </>
                )}
            {modelBlock.modelBlocks && modelBlock.modelBlocks.length !== 0 && (
                <>
                    {modelBlock.modelBlocks.map((child) => (
                        <OrderedModelVariableSelectOptions
                            key={child.id}
                            modelBlock={child}
                            index={index}
                            getVariablesToDisplayInSelectMenu={
                                getVariablesToDisplayInSelectMenu
                            }
                            getIsVariableOptionDisabled={
                                getIsVariableOptionDisabled
                            }
                        />
                    ))}
                </>
            )}
        </>
    );
};
