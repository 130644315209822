import React, { useCallback, useEffect } from "react";
import { useForm } from "@inertiajs/react";
import { OptionShape } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared/ModalContainer";
import { buildTimelineCard } from "@/model-configs/content-block-layouts/CardLayouts";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useSelectedSimulation } from "@/hooks";
import {
    OptionContainer,
    SelectionContainer,
} from "@/styles/themes/Question/drag-and-drop/types";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import {
    CreateContentBlockBody,
    createContentBlock,
} from "../../../content-block-buttons/create-content-block-button/createContentBlock";

type Props = {
    parentContentBlockId: string;
    weight: number;
    option: OptionShape;
    setOption: (option: OptionShape | undefined) => void;
    container: OptionContainer | SelectionContainer;
};

export default function TimelineOrDragAndDropCreateOptionModal({
    option,
    setOption,
    parentContentBlockId,
    weight,
    container,
}: Props) {
    const { setData, data } = useForm(option);
    useEffect(() => {
        setData(option);
    }, [option]);

    const { selectedSimulation } = useSelectedSimulation();

    const saveOption = useCallback(async () => {
        //first, make a content block for the option
        const block = {
            ...option?.contentBlock,
            ...buildTimelineCard(data?.content, parentContentBlockId, weight),
        };

        if (block?.id) {
            SapienInertia.put(
                "admin.design.update",
                {
                    ...block,
                    theme: JSON.stringify(block.theme),
                    table_data: {},
                } as (typeof LaravelRequestBodyShapes)["admin.design.update"],
                {
                    simulationSlug: selectedSimulation.slug,
                    roundId: block.round_id,
                    contentBlock: block.id,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: (_) => {
                        //now that we have the content block, we can save the option
                        //we can assume it's not new, since we just updated the content block
                        SapienInertia.put(
                            "admin.design.drag-and-drop-prompt-containers.options.update",
                            data,
                            {
                                option: data.id,
                            },
                        );
                    },
                },
            );
        } else {
            const { contentBlock: savedBlock } = await createContentBlock({
                body: {
                    ...block,
                    theme: JSON.stringify(block.theme),
                    position: "after",
                } as CreateContentBlockBody,
                selectedSimulation,
            });
            SapienInertia.post(
                "admin.design.drag-and-drop-prompt-containers.options.store",
                {
                    ...data,
                    prompt_id: container.prompt_id,
                    drag_and_drop_prompt_container_id: container.id,
                    content_block_id: savedBlock?.id,
                    weight,
                },
            );
        }
    }, [data]);

    return (
        <ModalContainer
            isModalOpen={!!option}
            setIsModalOpen={() => {
                setOption(undefined);
            }}
            size="s"
            backgroundColor="#111928"
            styles={{ margin: "4em auto" }}
        >
            {!!data && (
                <div className="py-6 text-white">
                    <div className="flex justify-between p-6">
                        <label>{data.id ? "Update" : "Create"} Option</label>
                    </div>
                    <form
                        className="flex flex-col space-y-4 border-t border-[#374151] p-6 text-white"
                        onSubmit={(e) => {
                            e.preventDefault();
                            saveOption();
                        }}
                    >
                        <div className="flex flex-col space-y-2">
                            <label>Label</label>
                            <input
                                disabled={!!data.id}
                                autoFocus={true}
                                type="text"
                                className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-xs`}
                                value={data.content}
                                onChange={(e) =>
                                    setData("content", e.target.value)
                                }
                            />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <label>Length of time</label>
                            <input
                                type="number"
                                className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-xs`}
                                value={data.size}
                                onChange={(e) =>
                                    setData("size", Number(e.target.value))
                                }
                            />
                        </div>
                        <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                            <div className="flex items-center justify-center">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOption(undefined);
                                    }}
                                    className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    disabled={!data.content}
                                    className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </ModalContainer>
    );
}
