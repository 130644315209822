import React from "react";
import { useSimVariantStore } from "@/hooks/store/useSimVariantState";
import { SimulationShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { updateSimVariant } from "@/redux-state/simVariantSlice";
import { useForm } from "@inertiajs/react";

// type SimVariantModalProps = {
//     simulation: Simulation;
// };

export default function SimVariantModal() {
    const {
        simVariants,
        addSimVariant,
        variantSimulation,
        setVariantSimulation,
        setSelectedVariantIndex,
        selectedVariantIndex,
    } = useSimVariantStore();

    const { data, setData } = useForm(
        simVariants &&
            variantSimulation &&
            simVariants[variantSimulation.id] &&
            simVariants[variantSimulation.id][selectedVariantIndex]
            ? simVariants[variantSimulation.id][selectedVariantIndex]
            : ({} as Partial<SimulationShape>)
    );

    return (
        <ModalContainer
            isModalOpen={!!variantSimulation}
            // setIsModalOpen={() => setContentBlockShape(null)}
            size="s"
        >
            <form
                className="flex flex-col py-4 px-6 space-y-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (selectedVariantIndex !== -1) {
                        updateSimVariant(data as SimulationShape);
                    } else {
                        addSimVariant({ ...variantSimulation, ...data });
                    }
                    setVariantSimulation(null);
                    setSelectedVariantIndex(-1);
                }}
            >
                <div>
                    <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Title
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            value={data.title}
                            onChange={(e) => {
                                // setData("slug", e.target.value);
                                setData({
                                    ...data,
                                    ...{
                                        title: e.target.value,
                                        slug: e.target.value
                                            .replace(/\s+/g, "-")
                                            .toLowerCase(),
                                    },
                                });
                            }}
                            name="title"
                            id="title"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Variant Title"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2">
                    <button className="inline-flex items-center  bg-blue-600 px-4 py-2 rounded text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 2bluefocus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        Save
                    </button>
                    <button
                        onClick={() => {
                            setVariantSimulation(null);
                            setSelectedVariantIndex(-1);
                        }}
                        className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </ModalContainer>
    );
}
