import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType, PromptType } from "@/models";

export const ResourceAllocationContentBlocks: FragmentContentBlock[] = [
    {
        content_block_type: ContentBlockType.Page,
        title: "Page",
        route: "/",
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Hero,
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Typography,
                        content: "Typography content block",
                    },
                ],
            },
            // LayoutConfigObject[LayoutType.Hero].layoutContentBlock,
            {
                content_block_type: ContentBlockType.Question,
                content: "Question content block",
                prompt: {
                    prompt_type: PromptType["Multiple Choice"],
                    content: "Prompt content",
                    options: [
                        { content: "Option 1 content" },
                        { content: "Option 2 content" },
                    ],
                },
            },
        ],
    },
];
