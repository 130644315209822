import React from "react";
import { CompactVariableSettingsTableRow } from "./CompactVariableSettingsTableRow";
import { useFormatVariableValue } from "@/hooks";
import { AccordionTableRow, CompactTableWrapper } from "../shared";
import { SettingsDisplayProps } from "./props";

const CompactSettingsTableComponent = ({
    modelBlocks,
    modelVariables,
    inputDataObject,
    setInputDataItem,
    showSettingsCharts,
    modelBlockDisplayMap,
    setModelBlockDisplayMap,
}: SettingsDisplayProps) => {
    const formatVariableValue = useFormatVariableValue();

    return (
        <CompactTableWrapper
            header={"input"}
            columnLabels={
                showSettingsCharts ? ["type", "values", ""] : ["type", "values"]
            }
            colSpanValues={[2, 1, 1]}
        >
            {!!modelVariables &&
                modelBlocks?.length > 0 &&
                modelBlocks
                    .filter((block) => !!modelVariables[block.id])
                    .map((block) => (
                        <React.Fragment key={block.id}>
                            <AccordionTableRow
                                key={block.id}
                                modelBlockLabel={block.label}
                                handleSetModelBlockDisplayMap={() =>
                                    setModelBlockDisplayMap({
                                        ...modelBlockDisplayMap,
                                        [block.id]:
                                            !modelBlockDisplayMap[block.id],
                                    })
                                }
                                isExpanded={!!modelBlockDisplayMap[block.id]}
                                colSpan={showSettingsCharts ? 6 : 5}
                            />
                            {!!modelBlockDisplayMap[block.id] &&
                                modelVariables[block.id]
                                    ?.filter(
                                        (modelVariable) =>
                                            !!inputDataObject[modelVariable.id],
                                    )
                                    .map((modelVariable, i) => (
                                        <CompactVariableSettingsTableRow
                                            key={modelVariable.id}
                                            modelVariable={modelVariable}
                                            inputDataItem={
                                                inputDataObject[
                                                    modelVariable.id
                                                ]
                                            }
                                            setInputDataItem={setInputDataItem}
                                            showSettingsCharts={
                                                showSettingsCharts
                                            }
                                            index={i}
                                            formatVariableValue={
                                                formatVariableValue
                                            }
                                        />
                                    ))}
                        </React.Fragment>
                    ))}
        </CompactTableWrapper>
    );
};

export const CompactSettingsTable = React.memo(CompactSettingsTableComponent);
