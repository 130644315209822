import React from "react";
import { ChartType, ChartTypes } from "./ChartWizardReducer";

export default function ChartIcon({
    chartType,
    color,
    includeText = true,
}: {
    chartType: ChartType;
    color?: string;
    includeText?: boolean;
}) {
    let description: JSX.Element;
    let icon: JSX.Element;
    switch (chartType) {
        case ChartTypes.BAR:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m1088.7 1107.4h-996.09v-996.09h35.156v960.94h960.94z" />
                        <path d="m425.51 1104.5h-221.95v-475.08c0-18.164 14.883-33.047 33.047-33.047h155.86c18.164 0 33.047 14.883 33.047 33.047zm-186.8-35.156h151.64v-437.81h-151.64z" />
                        <path d="m722.46 1104.4h-221.95v-725.51c0-18.164 14.883-33.047 33.047-33.047h155.86c18.164 0 33.047 14.883 33.047 33.047zm-186.8-35.156h151.64v-688.24h-151.64z" />
                        <path d="m1020.5 1104.5h-221.95v-617.58c0-18.164 14.883-33.047 33.047-33.047h155.86c18.164 0 33.047 14.883 33.047 33.047zm-186.8-35.156h151.64v-580.31h-151.64z" />
                    </g>
                </svg>
            );
            description = (
                <>
                    "Use to compare values across categories. Can be used for
                    time series"
                </>
            );
            break;
        case ChartTypes.LINE:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m1088.4 1106h-994.45v-994.45h35.156v959.3h959.3z" />
                        <path d="m254.53 927.42-153.52-115.9 21.094-28.125 129.61 97.852 248.91-242.46 162.19 111.21 291.56-453.16 128.32-44.062 11.484 33.281-116.84 40.078-304.45 473.32-168.28-115.43z" />
                    </g>
                </svg>
            );
            description = <>Use to compare values over time</>;

            break;
        case ChartTypes.AREA:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m1088.4 1105.4h-994.45v-994.34h35.156v959.18h959.3z" />
                        <path d="m1091.4 1106.2h-610.08l25.195-29.062c43.477-50.273 71.719-175.66 91.406-279.38 7.9688-42.07 15.586-86.25 22.969-129.02 38.555-224.3 71.953-417.89 165.7-417.89 93.75 0.11719 127.03 193.83 165.59 418.12 29.062 169.1 59.18 343.95 114.26 408.16zm-538.01-35.156h466.05c-19.57-33.75-36.914-81.797-53.32-147.42-18.75-74.766-33.984-163.24-48.633-248.67-17.93-104.06-34.805-202.27-57.305-276.56-22.617-74.531-47.461-112.38-73.711-112.38s-51.094 37.734-73.711 112.38c-22.5 74.18-39.375 172.38-57.305 276.33-7.3828 42.891-15 87.305-23.086 129.61-14.062 74.18-27.07 129.38-40.781 173.67-11.836 38.555-24.492 69.375-38.203 93.047z" />
                        <path d="m121.29 1102.9-19.336-29.414c65.508-43.008 101.6-161.6 136.52-276.21 22.148-72.539 43.008-141.09 71.484-194.3 34.219-63.867 75.234-94.922 125.39-94.922 50.039 0 90.938 30.82 125.16 94.336 28.711 53.203 50.391 124.45 71.367 193.36l-33.633 10.195c-20.391-67.031-41.602-136.64-68.555-186.8-27.891-51.797-57.773-75.82-94.219-75.82s-66.445 24.258-94.453 76.406c-26.836 50.039-47.227 117.07-68.789 187.85-36.797 120.59-74.766 245.39-150.94 295.31z" />
                    </g>
                </svg>
            );
            description = (
                <>
                    Use to compare values over time. Fills the area below the
                    line
                </>
            );
            break;
        case ChartTypes.PIE:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m566.84 1133.7c-276.56 0-501.68-224.77-501.68-501.09 0-67.617 13.242-133.12 39.258-194.88 25.195-59.648 61.172-113.32 106.99-159.26 45.938-46.055 99.258-82.266 158.79-107.58 61.758-26.25 127.15-39.609 194.65-39.609h35.156v467.58h467.58v35.156c0 67.5-13.359 132.89-39.492 194.53-25.195 59.531-61.406 112.97-107.34 158.79-45.938 45.82-99.492 81.914-159.14 106.99-61.641 26.133-127.27 39.375-194.77 39.375zm-37.148-930.59c-220.43 18.047-394.22 203.67-394.22 429.38 0 237.54 193.48 430.78 431.37 430.78 225.47 0 410.98-173.79 428.91-394.22h-466.05z" />
                        <path d="m1134.4 567.19h-502.73v-502.73h35.156c62.578 0 123.52 12.773 181.17 37.266 55.547 23.672 105.47 57.656 148.48 100.55 43.008 43.008 77.109 93.047 100.78 148.48 24.609 57.539 37.266 118.59 37.266 181.17v35.273zm-432.42-70.312h360c-16.875-190.78-169.22-343.12-360-360z" />
                    </g>
                </svg>
            );
            description = <>Use to show the proportion of each value.</>;
            break;
        case ChartTypes.DOUGHNUT:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m1103.8 677.34h-321.56v-17.578c0-131.13-110.86-241.99-241.99-241.99h-17.578v-321.56h17.578c75.703 0 149.3 15 218.79 44.531 67.031 28.477 127.27 69.258 179.18 121.17 51.914 51.914 92.578 112.15 121.17 179.18 29.531 69.492 44.531 143.09 44.531 218.79v17.461zm-286.99-35.156h251.48c-9.0234-277.38-233.09-501.45-510.47-510.59v251.6c66.797 4.2188 129.38 32.695 177.77 81.211 48.398 48.516 76.992 110.98 81.211 177.77z" />
                        <path d="m328.01 545.62-159.14-99.609-65.391-40.195 9.375-15c44.648-71.25 106.64-130.78 179.18-172.03 75.234-42.773 161.02-65.273 248.2-65.273h17.578v264.26h-17.578c-82.734 0-158.55 42.188-202.85 112.97zm-175.55-151.05 35.156 21.562 129.84 81.328c48.281-67.383 123.05-109.1 205.31-114.26v-194.18c-149.77 5.5078-285.94 81.094-370.31 205.55z" />
                        <path d="m542.11 1104.7c-60.234 0-118.59-11.836-173.55-35.156-53.086-22.5-100.78-54.727-141.68-95.742s-73.125-88.711-95.508-141.91c-23.203-55.078-35.039-113.44-35.039-173.55 0-83.906 23.32-165.59 67.617-236.37l9.2578-14.883 174.61 108.87-9.2578 14.883c-23.789 38.086-36.328 82.031-36.328 127.15 0 132.89 107.7 241.05 240.12 241.05s240.12-107.34 240.12-239.3v-17.578h205.78v17.578c0 60.234-11.836 118.59-35.039 173.55-22.5 52.969-54.609 100.55-95.508 141.45-40.898 40.781-88.594 72.773-141.68 95.156-55.312 23.086-113.67 34.805-173.91 34.805zm-357.42-649.1c-34.922 61.641-53.32 131.37-53.32 202.73 0 226.76 184.22 411.21 410.74 411.21 220.66 0 401.13-174.49 410.39-392.34h-135.7c-4.2188 67.148-32.227 129.38-80.039 176.95-51.914 51.562-120.94 79.922-194.65 79.922-151.76 0-275.27-123.87-275.27-276.21 0-45.703 11.367-90.469 32.812-130.55z" />
                    </g>
                </svg>
            );
            description = (
                <>
                    Like a pie chart, but with a hole in the middle. Arguably
                    more aesthetically pleasing, and more delicious
                </>
            );
            break;

        case ChartTypes.MULTI_MODE:
            icon = (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    fill="currentColor"
                    className="w-full"
                >
                    <g>
                        <path d="m259.34 628.95-24.727-24.961 208.95-206.6 69.609 69.609 123.05-123.28 61.758 61.758 263.91-263.79 24.844 24.844-288.75 288.75-61.758-61.758-123.05 123.16-69.727-69.727z" />
                        <path d="m128.2 93.164h35.156v1011.2h-35.156z" />
                        <path d="m392.81 1071.4h-163.36v-325.78c0-11.719 9.4922-21.211 21.211-21.211h121.05c11.719 0 21.211 9.4922 21.211 21.211v325.78zm-128.2-35.156h93.164v-276.68h-93.164z" />
                        <path d="m614.06 1071.4h-163.48v-472.03c0-11.719 9.4922-21.211 21.211-21.211h121.05c11.719 0 21.211 9.4922 21.211 21.211zm-128.32-35.156h93.164v-422.93h-93.164z" />
                        <path d="m835.31 1071.4h-163.48v-404.53c0-11.719 9.4922-21.211 21.211-21.211h121.05c11.719 0 21.211 9.4922 21.211 21.211zm-128.32-35.156h93.164v-355.43h-93.164z" />
                        <path d="m1056.4 1071.4h-163.48v-607.03c0-11.719 9.4922-21.211 21.211-21.211h121.05c11.719 0 21.211 9.4922 21.211 21.211zm-128.32-35.156h93.164v-557.81h-93.164z" />
                        <path d="m991.88 266.72h-35.156v-95.039h-95.039v-35.156h130.2z" />
                        <path d="m95.977 188.09h49.805v35.156h-49.805z" />
                        <path d="m95.977 357.77h49.805v35.156h-49.805z" />
                        <path d="m95.977 527.34h49.805v35.156h-49.805z" />
                        <path d="m95.977 697.03h49.805v35.156h-49.805z" />
                        <path d="m95.977 866.6h49.805v35.156h-49.805z" />
                        <path d="m297.77 1054.7h35.156v49.805h-35.156z" />
                        <path d="m467.46 1054.7h35.156v49.805h-35.156z" />
                        <path d="m637.03 1054.7h35.156v49.805h-35.156z" />
                        <path d="m806.72 1054.7h35.156v49.805h-35.156z" />
                        <path d="m976.29 1054.7h35.156v49.805h-35.156z" />
                        <path d="m95.977 1036.3h1010.4v35.156h-1010.4z" />
                    </g>
                </svg>
            );
            description = (
                <>
                    Advanced chart type.
                    <br />
                    Combine multiple chart types in one chart. Use to compare
                    values over time, or across categories. Chart types don't
                    include pie or doughnut charts.
                </>
            );
            break;
    }
    return includeText ? (
        <span style={{ color }}>
            {icon}
            <p className="capitalize w-full">{chartType}</p>
            <p className="text-xs">{description}</p>
        </span>
    ) : (
        <span style={{ color }}> {icon}</span>
    );
}
