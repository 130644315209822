import React, { useState } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import { useModalQueryParams } from "@/hooks";
import { useGetContentBlockById } from "../../modals/shared-queries/useGetContentBlockById";
// import { Transition } from "@headlessui/react";
// import { DynamicValidationDependencyForm } from "./DynamicValidationDependencyForm";
import DynamicValidationQuantityForm from "./DynamicValidationQuantityForm";
import { ContentBlockType, ModelVariableDataType } from "@/models";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";

type ValidationRuleType = "sum" | "dependency";

function SkeletonWrapper() {
    return (
        <div className="gap-6 p-6 text-white flex flex-col">
            <div className="flex justify-between items-center py-6 border-b border-[#374151]">
                <h6 className="text-xl">Manage Validation Rules</h6>
            </div>

            <div className="flex flex-col animate-pulse gap-4">
                <div className="bg-gray-100/50 w-full h-12 rounded-md"></div>
                <div className="bg-gray-100/50 w-full h-12 rounded-md"></div>
                <div className="bg-gray-100/50 w-full h-12 rounded-md"></div>
            </div>

            <div className="flex flex-col animate-pulse gap-4">
                <div className="flex gap-4">
                    <div className="bg-gray-100/50 w-8 h-12 rounded-md"></div>
                    <div className="bg-gray-100/50 w-36 h-12 rounded-md"></div>
                </div>
                <div className="flex gap-4">
                    <div className="bg-gray-100/50 w-8 h-12 rounded-md"></div>
                    <div className="bg-gray-100/50 w-36 h-12 rounded-md"></div>
                </div>
                <div className="flex gap-4">
                    <div className="bg-gray-100/50 w-8 h-12 rounded-md"></div>
                    <div className="bg-gray-100/50 w-36 h-12 rounded-md"></div>
                </div>
            </div>
        </div>
    );
}

function DynamicValidationRuleModalForm() {
    const { queryParams, closeModal } = useModalQueryParams(
        "DynamicValidationRuleModal"
    );
    const { contentBlockId } = queryParams;
    const { data: contentBlock, isFetching } = useGetContentBlockById(
        contentBlockId,
        (form) => {
            return {
                ...form,
                contentBlocks:
                    form?.contentBlocks?.filter(
                        (block) =>
                            block.content_block_type ===
                                ContentBlockType.Question && !!block.prompt
                    ) || [],
            };
        }
    );

    const { modelVariables } = useModelVariableStore();
    const { timeHorizons } = useTimeHorizonStore();

    const [validationType, setValidationType] =
        useState<ValidationRuleType>("sum");

    if (isFetching) return <SkeletonWrapper />;
    return (
        <div
            className="p-6 text-white flex flex-col"
            data-testid="dynamic-validation-rule-modal"
        >
            <div className="flex justify-between items-center py-6 border-b border-[#374151]">
                <h6 className="text-xl">Manage Validation Rules</h6>
            </div>
            {/* <div className="md:grid md:grid-cols-2 border-b border-[#374151]">
                <div
                    className={`flex items-center px-6 py-4 ${
                        validationType === "sum" && "bg-[#1F2A37]"
                    }`}
                >
                    <input
                        id="copy-old-prompt"
                        type="radio"
                        value=""
                        name="copy-old-prompt"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        checked={validationType === "sum"}
                        onChange={(_) => setValidationType("sum")}
                    />
                    <label
                        htmlFor="copy-old-prompt"
                        className="ml-3.5 w-full text-sm font-medium flex flex-col"
                    >
                        Quantitative
                        <span className="mt-1 text-[#6B7280]">
                            Numeric values of selections must add up to, or be
                            less than, a specified value from the sim's model
                        </span>
                    </label>
                </div>
                <div
                    className={`flex items-center  px-6 py-4 ${
                        validationType === "dependency" && "bg-[#1F2A37]"
                    }`}
                >
                    <input
                        id="create-new"
                        type="radio"
                        value=""
                        name="create-new"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        checked={validationType === "dependency"}
                        onChange={(_) => setValidationType("dependency")}
                    />
                    <label
                        htmlFor="create-new"
                        className="ml-3.5 w-full text-sm font-medium flex flex-col"
                    >
                        Dependency
                        <span className="mt-1 text-[#6B7280]">
                            Some options can't be selected unless other options
                            are selected
                        </span>
                    </label>
                </div>
            </div> */}
            <div className="flex flex-col space-y-6 transition">
                <DynamicValidationQuantityForm
                    form={contentBlock}
                    modelVariables={Object.values(modelVariables).filter(
                        (variable) =>
                            variable.data_type === ModelVariableDataType.Number
                    )}
                    timeHorizons={timeHorizons}
                    closeModal={closeModal}
                />
            </div>
        </div>
    );
}

export default function DynamicValidationRuleModal() {
    const { isAtModalUrl } = useModalQueryParams("DynamicValidationRuleModal");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {
                SapienInertia.reload();
            }}
            size="xl"
            backgroundColor="#111928"
        >
            {isAtModalUrl && <DynamicValidationRuleModalForm />}
        </ModalContainer>
    );
}
