import ColorSelector from "@/components/rich-text/ColorSelector";
import { ContentBlockShape } from "@/models";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";

type Props = {
    contentBlock: ContentBlockShape;
};

export default function ColorForm({contentBlock}: Props) {
    const colorClassname = contentBlock.theme?.backgroundColor
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 1000), [contentBlock]);
    const update = useCallback(
        debounce((contentBlock: ContentBlockShape) => {
            setContentBlock(contentBlock);
            save(contentBlock);
        }, 100),
        [contentBlock],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        update(newBlock);
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <label>Background Color</label>
                <ColorSelector
                    selectedColor={
                        colorClassname  || "white"
                    }
                    selectColor={(color) => {
                        handleChange({
                            ...contentBlock,
                            theme: {
                                ...contentBlock.theme,
                                backgroundColor: color,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
