import { useEffect } from "react";
import { Auth } from "@/inertia-utils/types";
import { Simulation, Team, Tenant } from "@/models";
import { useAllSimulations } from "./useSimulationStore";
import { useAuthStore } from "./store";

export const useInitAuthFromInertiaProps = (
    auth: Auth,
    simulations?: Simulation[],
    team?: Team,
    tenant?: Tenant
) => {

    const { gotSimulations, simulations: _simulations } = useAllSimulations();
    const { signedIn, setTeam, setTenant,signedOut, user, isNotParticipant } = useAuthStore();

    useEffect(() => {
        if (auth?.user && (!user || user.id !== auth.user.id)) {
            signedIn(auth);
        }
    }, [auth]);

    useEffect(() => {
        if (simulations) {
            if (Array.isArray(simulations)) {
                gotSimulations(simulations);
            } else {
                gotSimulations(Object.values(simulations));
            }
        }
    }, [simulations]);

    useEffect(() => {
        setTeam(team);
    }, [team]);

    useEffect(() => {
        setTenant(tenant);
    }, [tenant]);


    return {
        user,
        isNotParticipant,
        signedIn,
        signedOut,
    };
};
