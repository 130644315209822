import { VideoEditor } from "@/Pages/Admin/editor/VideoEditor";
import QuestionModal from "@/Pages/Admin/editor/question-forms/QuestionModal";
import { ImageEditor } from "@/Pages/Admin/editor/uploads/ImageEditor";
import React from "react";

export default function DesignModals() {
    return (
        <>
            <VideoEditor />
            <ImageEditor />
            <QuestionModal />
        </>
    );
}
