import React from "react";
import { H3Span } from "../../components";
import { AdminPageSection } from "./AdminPageSection";

export const AdminPageHeader = ({
    children,
    pageTitle,
}: {
    children?: React.ReactNode;
    pageTitle?: string;
}) => {
    return (
        <AdminPageSection backgroundColor="#E5E7EB">
            <div className="flex flex-row items-center justify-between">
                <div>
                    <H3Span>{pageTitle || "SimBuilder"}</H3Span>
                </div>
                {children !== undefined && <div>{children}</div>}
            </div>
        </AdminPageSection>
    );
};
