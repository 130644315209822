import React, { useEffect, useState } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { titleCase } from "@/util";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";

const CategoryHeader = ({
    type,
    children,
}: {
    type: string;
    children?: React.ReactNode;
}) => (
    <div className="mt-1 w-full">
        <div className="bg-[rgba(255,255,255,0.03) py-1] flex items-center justify-between border-x-0 border-y-[0.5px] border-[rgba(255,255,255,0.15)] px-2">
            <span className="text-sm font-medium uppercase tracking-wide opacity-60">
                {type}
            </span>
            {children}
        </div>
    </div>
);

export const RoundOutlineDisplay = ({
    topLevelContentBlockLayers,
}: {
    topLevelContentBlockLayers: ContentBlockShape[];
}) => {
    const [navbarContentBlock, setNavbarContentBlock] =
        useState<ContentBlockShape>();
    const [bottomDrawerContentBlock, setBottomDrawerContentBlock] =
        useState<ContentBlockShape>();
    const [topDrawerContentBlock, setTopDrawerContentBlock] =
        useState<ContentBlockShape>();
    const [pageContentBlocks, setPageContentBlocks] =
        useState<ContentBlockShape[]>();
    const [modalContentBlocks, setModalContentBlocks] =
        useState<ContentBlockShape[]>();

    useEffect(() => {
        if (topLevelContentBlockLayers?.length) {
            let newNavbar;
            let newBottomDrawer;
            let newTopDrawer;
            let newPages = [];
            let newModals = [];

            topLevelContentBlockLayers.forEach((contentBlock) => {
                switch (contentBlock.content_block_type) {
                    case ContentBlockType.Navbar:
                        newNavbar = contentBlock;
                        break;
                    case ContentBlockType["Bottom Drawer"]:
                        newBottomDrawer = contentBlock;
                        break;
                    case ContentBlockType["Top Drawer"]:
                        newTopDrawer = contentBlock;
                        break;
                    case ContentBlockType.Page:
                        newPages = [...newPages, contentBlock];
                        break;
                    case ContentBlockType.Modal:
                        newModals = [...newModals, contentBlock];
                        break;
                }
            });

            setNavbarContentBlock(newNavbar);
            setBottomDrawerContentBlock(newBottomDrawer);
            setPageContentBlocks(newPages);
            setModalContentBlocks(newModals);
            setTopDrawerContentBlock(newTopDrawer);
        }
    }, [topLevelContentBlockLayers]);

    return (
        <div className="-mt-1">
            {!!navbarContentBlock && (
                <CategoryHeader type={"NAVBAR"}>
                    <div className="flex items-center">
                        <span className="text-xs leading-[1.1]">
                            {navbarContentBlock.title ||
                                titleCase(
                                    navbarContentBlock.content_block_type,
                                )}
                        </span>
                    </div>
                </CategoryHeader>
            )}
            {!!topDrawerContentBlock && (
                <CategoryHeader type={"TOP DRAWER"}>
                    <div className="flex items-center">
                        <span className="text-xs leading-[1.1]">
                            {topDrawerContentBlock.title ||
                                titleCase(
                                    topDrawerContentBlock.content_block_type,
                                )}
                        </span>
                    </div>
                </CategoryHeader>
            )}
            {!!bottomDrawerContentBlock && (
                <CategoryHeader type={"BOTTOM DRAWER"}>
                    <div className="flex items-center">
                        <span className="text-xs leading-[1.1]">
                            {bottomDrawerContentBlock.title ||
                                titleCase(
                                    bottomDrawerContentBlock.content_block_type,
                                )}
                        </span>
                    </div>
                </CategoryHeader>
            )}
            {!!modalContentBlocks?.length && (
                <CategoryHeader type={"MODALS"}>
                    <div>
                        {modalContentBlocks?.map((contentBlock) => (
                            <div
                                key={contentBlock.id}
                                className="flex items-center"
                            >
                                <span className="text-xs leading-[1.1]">
                                    {contentBlock.title ||
                                        titleCase(
                                            contentBlock.content_block_type,
                                        )}
                                </span>
                            </div>
                        ))}
                    </div>
                </CategoryHeader>
            )}
            {!!pageContentBlocks?.length && (
                <CategoryHeader type={"PAGES"}>
                    <div>
                        {pageContentBlocks?.map((contentBlock) => (
                            <div
                                key={contentBlock.id}
                                className="flex items-center"
                            >
                                <button
                                    className="text-xs font-semibold"
                                    role="link"
                                    onClick={() => {
                                        SapienInertia.visit(
                                            sapienRoute(
                                                "admin.design.show.page",
                                                {
                                                    roundId:
                                                        contentBlock.round_id,
                                                    currentPageId:
                                                        contentBlock.id,
                                                },
                                            ),
                                        );
                                    }}
                                >
                                    {contentBlock.title ||
                                        titleCase(
                                            contentBlock.content_block_type,
                                        )}
                                </button>
                            </div>
                        ))}
                    </div>
                </CategoryHeader>
            )}
        </div>
    );
};
