import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockTemplateShape } from "@/models";

export async function getContentBlockTemplates(simulationId: string) {
    const route = sapienRoute("creator.design.templates.json", {
        simulationId,
    });

    const { data } = await axios.get<{
        templates: {
            user: ContentBlockTemplateShape[];
            simulation: ContentBlockTemplateShape[];
        };
    }>(route);

    return data;
}
