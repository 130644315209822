import React, { useCallback, useState } from "react";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import PlaceHolder from "@tiptap/extension-placeholder";
import { RichText } from "@/models";
import { Course } from "../types";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import CoursePageWrapper from "./CoursePageWrapper";
import { SapienInertia, sapienAxios, sapienRoute } from "@/inertia-utils/hooks";
import { useEditor, EditorContent } from "@tiptap/react";
import { TextType, LineHeight, TextStyleExtended } from "@/modules/text-editor";
import { Check, MoveDown, MoveUp, XIcon } from "lucide-react";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import LessonForm from "./LessonForm";
import { Link } from "@inertiajs/react";
import DownloadScormExport from "./DownloadScormExport";
// define your extension array
type Props = { course: Course } & SapienAdminPageProps;

export default function CourseForm({ course, auth, errors }: Props) {
    const [title, setTitle] = useState(
        course?.courseMetadata.course_title || "",
    );

    const editor = useEditor({
        content: course?.courseMetadata.description,
        editable: true,

        extensions: [
            StarterKit,
            TextType,
            Color,
            LineHeight,
            Underline,
            TextStyleExtended,
            FontFamily,
            TextAlign.configure({
                types: ["heading", "paragraph", "typography"],
            }),
            PlaceHolder.configure({
                placeholder: "Course description",
            }),
        ],
    });
    const updateCourse = useCallback(() => {
        SapienInertia.put(
            "e-learning.courses.metadata.update",
            {
                course_title: title,
                description: editor.getJSON() as RichText,
                id: course?.courseMetadata?.id,
            } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.courses.metadata.update"],
            { courseMetadata: course.courseMetadata.id },
        );
    }, [title, editor, course]);

    const reorderLesson = useCallback(
        (lesson_id: string, destination: number) => {
            SapienInertia.put("e-learning.reorder-lessons", {
                lesson_id,
                destination,
            });
        },
        [course],
    );

    return (
        <CoursePageWrapper
            auth={auth}
            errors={errors}
            showDefaultHeader={false}
        >
            <header className="bg-white shadow-sm">
                <div className="mx-auto flex max-w-4xl items-center justify-between px-4 py-4 sm:px-6 lg:px-6">
                    <h1 className="flex space-x-2 text-lg font-semibold leading-6 text-gray-900">
                        <input
                            className="w-96 rounded-md border-none text-lg font-semibold leading-6 text-gray-900 shadow"
                            type="text"
                            value={title}
                            placeholder="Course Title"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {!!title &&
                            title !== course.courseMetadata.course_title && (
                                <>
                                    <button
                                        className="inline-flex items-center rounded-full border border-transparent bg-sapien-blue
                                            p-2 text-sm font-medium text-white shadow-sm hover:bg-sapien-blue
                                            focus:outline-none focus:ring-2 focus:ring-sapien-blue focus:ring-offset-2"
                                        onClick={() => updateCourse()}
                                    >
                                        <Check className="h-4 w-4" />
                                    </button>
                                    <button
                                        className="inline-flex items-center rounded-full border border-transparent bg-red-500 p-2
                                            text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none
                                            focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setTitle(
                                                course.courseMetadata
                                                    .course_title,
                                            );
                                        }}
                                    >
                                        <XIcon className="h-4 w-4" />
                                    </button>
                                </>
                            )}
                    </h1>
                    <div className="flex h-full items-center space-x-2">
                        <DownloadScormExport course={course} />

                        <Link
                            className="flex items-center rounded-full bg-sapien-blue px-4 py-2 text-sm font-semibold
                                text-white hover:bg-blue-900"
                            href={sapienRoute(
                                "admin.projects.facilitate.index",
                                {
                                    simulationSlug: course.slug,
                                },
                            )}
                        >
                            Facilitation
                        </Link>
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto flex max-w-4xl flex-col py-6 sm:px-6 lg:px-8">
                    <div className="space-y-6">
                        <EditorContent editor={editor} />
                        {/* <FloatingMenu editor={editor}>
                            This is the floating menu
                        </FloatingMenu>
                        <BubbleMenu editor={editor}>
                            This is the bubble menu
                        </BubbleMenu> */}
                    </div>
                    <div className="flex flex-col divide-y-2">
                        {course?.lessons?.map((lesson, index) => (
                            <div
                                key={lesson.id}
                                className="flex justify-between px-2 py-4"
                            >
                                <div className="flex gap-4">
                                    <div className="flex w-12 gap-1">
                                        {index !== 0 && (
                                            <button
                                                onClick={() => {
                                                    reorderLesson(
                                                        lesson.id,
                                                        index - 1,
                                                    );
                                                }}
                                            >
                                                <MoveUp />
                                            </button>
                                        )}
                                        {index !==
                                            course.lessons.length - 1 && (
                                            <button
                                                onClick={() => {
                                                    reorderLesson(
                                                        lesson.id,
                                                        index + 1,
                                                    );
                                                }}
                                            >
                                                <MoveDown />
                                            </button>
                                        )}
                                    </div>
                                    <LessonForm lesson={lesson} />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Link
                                        href={sapienRoute("e-learning.design", {
                                            lesson: lesson.id,
                                        })}
                                    >
                                        Edit Content
                                    </Link>
                                    <button
                                        onClick={() => {
                                            if (
                                                confirm(
                                                    "Are you sure you want to delete this lesson?",
                                                )
                                            ) {
                                                SapienInertia.delete(
                                                    sapienRoute(
                                                        "e-learning.lessons.destroy",
                                                        { lesson: lesson.id },
                                                    ),
                                                    { preserveScroll: true },
                                                );
                                            }
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="flex px-2 py-4">
                            <LessonForm
                                lesson={{
                                    simulation_id: course.id,
                                    title: "",
                                    weight: course.lessons?.length || 0,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </CoursePageWrapper>
    );
}
