import React from "react";
import { Course } from "../types";
import { SapienParticipantPageProps } from "@/inertia-utils/types";
import { Progress } from "../LessonDisplay/ProgressTracker";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import StudentHeader from "./StudentHeader";

type Props = {
    course: Course;
    teamUserRound: {
        lesson_completions: {
            [index: string]: number;
        };
    };
} & SapienParticipantPageProps;

export default function CourseHome({ course, auth, teamUserRound }: Props) {
    return (
        <div className="min-h-screen e-learning flex flex-col items-center">
            <StudentHeader auth={auth}>
                <div className="flex items-center">
                    <h1 className="text-lg font-semibold">{course.courseMetadata?.course_title || course.title}</h1>
                </div>
            </StudentHeader>
            <div className="flex h-full w-full flex-1 flex-col items-center bg-gray-100 px-4 py-6">
                <div className="w-full max-w-3xl rounded-md mt-16 bg-white px-4 py-6 lg:max-w-4xl">
                    <h1 className="text-2xl font-semibold">Lessons</h1>
                    <div className="flex flex-col">
                        {course?.lessons?.map((lesson) => (
                            <Link
                                href={sapienRoute(
                                    "e-learning.participant.lesson.show",
                                    { lesson: lesson.id },
                                )}
                                key={lesson.id}
                                className="hover:shadow:lg group flex flex-row items-center justify-between px-2 py-4
                                    shadow-sm transition-all"
                            >
                                <h2 className="text-sm font-bold group-hover:underline">
                                    {lesson.title}
                                </h2>
                                <div className="text-green-500">
                                    <Progress
                                        percentage={Math.round(
                                            (teamUserRound.lesson_completions?.[
                                                lesson.id
                                            ] || 0) * 100,
                                        )}
                                        size={50}
                                        strokeWidth={3}
                                        strokeColor="text-green-500"
                                        trailColor="text-slate-300"
                                        textColor="text-green-700"
                                    />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
