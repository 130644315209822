import React from "react";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/feather/plus";
import { Draggable } from "./Draggable";
import { Droppable } from "./Droppable";
import { Option, OptionShape } from "@/models";
import { ErrorBag, Errors } from "@inertiajs/core";
import { ArrowUturnLeftIcon, TrashIcon } from "@heroicons/react/24/solid";
import { OptionPopover } from "./OptionPopover";

type Props = {
    sortedOptionIds: string[];
    setSortedOptionIds: (sortedOptionIds: string[]) => void;
    optionMap: {
        [index: string]: OptionShape;
    };
    setOption: (option: Option) => void;
    modalPageErrors: Errors & ErrorBag;
    addOption: () => void;
    markOptionDeletionState: (option: Option | OptionShape) => void;
    deletedOptionIds: string[];
};

export function OptionList({
    sortedOptionIds,
    setSortedOptionIds,
    optionMap,
    setOption,
    modalPageErrors,
    addOption,
    markOptionDeletionState,
    deletedOptionIds,
}: Props) {
    return (
        <>
            <div className="flex justify-between">
                <label>Options</label>
                <div className="flex flex-col space-y-4 w-full max-w-3xl">
                    <DndContext
                        onDragMove={(e) => {
                            const from = e?.active?.data?.current?.weight;
                            const to = e.over?.data?.current?.weight;

                            if (to === undefined || from === undefined) return;

                            const element = sortedOptionIds.splice(
                                Number(from),
                                1
                            )[0];

                            const copy = [...sortedOptionIds];
                            copy.splice(Number(to), 0, element);
                            setSortedOptionIds(copy);
                        }}
                    >
                        <SortableContext
                            items={
                                sortedOptionIds.map(
                                    (id) => optionMap[id]
                                ) as (Option & {
                                    id: string;
                                })[]
                            }
                        >
                            <>
                                {sortedOptionIds
                                    .map((id, i) => optionMap[id])
                                    .map((option, i) => (
                                        <Droppable
                                            key={option.id}
                                            id={option.id}
                                            weight={i}
                                            className={`w-full max-w-3xl ${
                                                deletedOptionIds.includes(
                                                    option.id
                                                )
                                                    ? "line-through"
                                                    : ""
                                            }`}
                                        >
                                            <Draggable
                                                handle
                                                id={option.id}
                                                weight={i}
                                                className="w-full max-w-3xl"
                                            >
                                                <div className="block w-full">
                                                    <div className="flex space-x-2 w-full">
                                                        <input
                                                            type="text"
                                                            className={`w-full border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0 text-white placeholder:text-gray-200 ${
                                                                !!modalPageErrors &&
                                                                !!modalPageErrors[
                                                                    `options.${i}.content`
                                                                ]
                                                                    ? " border-pink-500 rounded-t-md"
                                                                    : "rounded-md"
                                                            }`}
                                                            value={
                                                                option.content
                                                            }
                                                            onChange={(e) => {
                                                                setOption({
                                                                    ...optionMap[
                                                                        option
                                                                            .id
                                                                    ],
                                                                    ...{
                                                                        content:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    },
                                                                } as Option);
                                                            }}
                                                        />
                                                        <input
                                                            className={`w-28 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0 text-white placeholder:text-gray-200 `}
                                                            type="number"
                                                            value={
                                                                option.numerical_value
                                                            }
                                                            placeholder="Numerical Value"
                                                            onChange={(e) => {
                                                                setOption({
                                                                    ...optionMap[
                                                                        option
                                                                            .id
                                                                    ],
                                                                    numerical_value:
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        ),
                                                                } as Option);
                                                            }}
                                                        />
                                                        {!!option.selectionDataModelVariable ? (
                                                            <div className="relative flex items-center">
                                                                <OptionPopover />
                                                            </div>
                                                        ) : (
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    markOptionDeletionState(
                                                                        option
                                                                    );
                                                                }}
                                                                className={`transition-all px-2 rounded ${
                                                                    deletedOptionIds.includes(
                                                                        option.id
                                                                    )
                                                                        ? "bg-blue-600 hover:bg-blue-700"
                                                                        : "bg-red-500 hover:bg-red-700"
                                                                }`}
                                                            >
                                                                {deletedOptionIds.includes(
                                                                    option.id
                                                                ) ? (
                                                                    <ArrowUturnLeftIcon className="h-5 w-5 text-white" />
                                                                ) : (
                                                                    <TrashIcon className="h-5 w-5 text-white" />
                                                                )}
                                                            </button>
                                                        )}
                                                    </div>
                                                    {!!modalPageErrors &&
                                                        !!modalPageErrors[
                                                            `options.${i}.content`
                                                        ] && (
                                                            <p className="p-2 bg-pink-600 text-xs rounded-b-md">
                                                                Enter some text
                                                            </p>
                                                        )}
                                                </div>
                                            </Draggable>
                                        </Droppable>
                                    ))}
                            </>
                        </SortableContext>
                    </DndContext>
                </div>
            </div>

            <div className="flex justify-end">
                <div className="w-full max-w-3xl">
                    <button
                        type="button"
                        className={`flex text-white focus:ring-0 focus:outline-none focus:ring-[rgba(255, 255, 255, 0.65)] font-medium rounded text-xs px-2 py-1 text-center ${`hover:text-white hover:bg-gray-800`}`}
                        onClick={() => {
                            addOption();
                        }}
                        disabled={false}
                    >
                        <span
                            style={{
                                padding: "2px 4px 2px 0",
                            }}
                        >
                            <Icon
                                icon={plus}
                                size={16}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </span>
                        <span
                            style={{
                                padding: "2px 0",
                            }}
                        >
                            ADD OPTION
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
}
