import React from "react";
import { Lesson } from "../types";
import { useViewedContentBlockState } from "./viewed-content-block-tracking/useViewedContentBlockState";
import { Link } from "@inertiajs/react";
import { Progress } from "./ProgressTracker";
import { sapienRoute } from "@/inertia-utils/hooks";
import { useAtom } from "jotai";
import { transitionDirectionAtom } from "../shared/page-transitions/usePageTransition";

type Props = {
    lesson: Lesson;
    isCurrentLesson: boolean;
    route: "e-learning.preview.show" | "e-learning.participant.lesson.show";
    weight: number;
    currentWeight: number;
};

export default function LessonLink({
    lesson,
    isCurrentLesson,
    route,
    weight,
    currentWeight,
}: Props) {
    const { viewedContentBlockPercentage } = useViewedContentBlockState(lesson);
    const [_, setDirection] = useAtom(transitionDirectionAtom);

    return (
        <Link
            onClick={() => setDirection(weight > currentWeight ? "up" : "down")}
            href={sapienRoute(route, { lesson: lesson.id })}
            className="flex items-center justify-between border-b border-l-8 border-slate-200
                bg-slate-200 px-2 py-4 transition-colors last:border-b-0
                data-[is-current='false']:border-l-transparent
                data-[is-current='true']:border-l-sapien-blue data-[is-current='true']:bg-white
                hover:bg-white"
            data-is-current={isCurrentLesson ? "true" : "false"}
        >
            <div>
                {lesson.title}
            </div>
            <div className="text-green-500">
                <Progress
                    percentage={viewedContentBlockPercentage}
                    size={25}
                    strokeWidth={3}
                    strokeColor="text-green-500"
                    trailColor="text-slate-300"
                    textColor="text-green-700"
                    showPercentage={false}
                />
            </div>
        </Link>
    );
}
