import React, { useEffect } from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@radix-ui/react-popover";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";
import { CompactDataTable } from "./CompactDataTable";
import { AccordionCardDataTable } from "./AccordionCardDataTable";
import {
    ButtonGroupMenu,
    CollapseAllControlButton,
    ModelBlockCarouselMenu,
    ShowHideButton,
} from "../shared";
import { CarouselCardDataTable } from "./CarouselCardDataTable";
import {
    useDataModelBlocks,
    useDataModelVariableMap,
    useOrderedKeyMetricTargetVariables,
    usePythonValuesMonthly,
    usePythonValuesYearly,
} from "../state";
import { useInterfaceStateData } from "../useInterfaceState";

const InteractiveSectionDataComponent = ({}: {}) => {
    const {
        dataTabIndex,
        setDataTabIndex,
        showSettingsTableRows,
        setShowSettingsTableRows,
        showDataInMonthsNotYears,
        setShowDataInMonthsNotYears,
        selectedDataModelBlock,
        setSelectedDataModelBlock,
        modelBlockDisplayMapData,
        setModelBlockDisplayMapData,
        hasOpenDataBlocks,
    } = useInterfaceStateData();

    const orderedKeyMetricTargetVariables =
        useOrderedKeyMetricTargetVariables();
    const pythonValuesMonthly = usePythonValuesMonthly();
    const pythonValuesYearly = usePythonValuesYearly();
    const dataModelBlocks = useDataModelBlocks();
    const dataModelVariableMap = useDataModelVariableMap();

    useEffect(() => {
        if (
            dataModelBlocks?.length > 0 &&
            (!selectedDataModelBlock ||
                !dataModelBlocks?.some(
                    (block) => block.id === selectedDataModelBlock.id,
                ))
        ) {
            setSelectedDataModelBlock(dataModelBlocks[0]);
        }
        if (!Object.keys(modelBlockDisplayMapData)?.length) {
            setModelBlockDisplayMapData(
                dataModelBlocks?.reduce(
                    (map, block) => ({ ...map, [block.id]: true }),
                    {},
                ) || {},
            );
        }
    }, [dataModelBlocks]);

    useEffect(() => {
        if (
            dataModelBlocks?.length > 0 &&
            Object.keys(modelBlockDisplayMapData)?.length > 0 &&
            showSettingsTableRows &&
            dataModelBlocks?.length >
                Object.keys(modelBlockDisplayMapData)?.length
        ) {
            const blocksMissingKeys = dataModelBlocks?.filter(
                (block) => modelBlockDisplayMapData[block.id] === undefined,
            );
            if (blocksMissingKeys?.length > 0) {
                setModelBlockDisplayMapData({
                    ...modelBlockDisplayMapData,
                    ...blocksMissingKeys.reduce(
                        (map, block) => ({ ...map, [block.id]: true }),
                        {},
                    ),
                });
            }
        }
    }, [showSettingsTableRows, dataModelBlocks]);

    return (
        <div className="flex w-full flex-col space-y-6">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Calculations"}
                </h1>
                <ButtonGroupMenu
                    selectedIndex={dataTabIndex}
                    setSelectedIndex={setDataTabIndex}
                    buttonItems={["Default View", "List View", "Compact View"]}
                    isPrimary={false}
                />
            </div>

            <div
                className="flex flex-col items-start justify-between space-y-2 sm:flex-row sm:items-center
                    sm:space-y-0"
            >
                <span className="flex items-center space-x-2">
                    <ButtonGroupMenu
                        selectedIndex={showDataInMonthsNotYears}
                        setSelectedIndex={setShowDataInMonthsNotYears}
                        buttonItems={["Yearly Data", "Monthly Data"]}
                        isPrimary={true}
                    />
                    <Popover>
                        <PopoverTrigger
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                title={"Data Display"}
                                className="relative inline-flex items-center text-sm font-medium"
                            >
                                <span className="sr-only">Data Display</span>
                                <Icon
                                    icon={info}
                                    size={20}
                                    className="flex h-8 items-center justify-center text-slate-400 transition-all
                                        hover:text-slate-600 focus:outline-none focus:ring-0 dark:text-slate-500
                                        dark:hover:text-slate-300"
                                    style={{ display: "flex" }}
                                />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent
                            align={"center"}
                            sideOffset={0}
                            className="mt-2 min-w-[340px] max-w-[340px] rounded-md border border-slate-200 bg-slate-100
                                p-4 text-sm font-normal text-slate-600 dark:border-slate-700 dark:bg-slate-800
                                dark:text-slate-400"
                            style={{
                                zIndex: 9999,
                            }}
                        >
                            <div className="">
                                The
                                <span className="font-semibold text-blue-700 dark:text-blue-700">{` Monthly Data `}</span>
                                display simply presents calculations by month
                                across the model timespan.
                                <br />
                                The
                                <span className="font-semibold text-blue-700 dark:text-blue-700">{` Yearly Data `}</span>
                                display provides summary calculations by year.
                                <br />
                                <br />
                                <span className="font-light">
                                    Note: The nature of a variable determines
                                    the function used to create a yearly summary
                                    value from monthly values. For example, we
                                    might take the
                                    <span className="font-semibold text-slate-700 dark:text-slate-300">{` mean `}</span>
                                    of a percentage, the
                                    <span className="font-semibold text-slate-700 dark:text-slate-300">{` sum `}</span>
                                    of a monetary metric, and the
                                    <span className="font-semibold text-slate-700 dark:text-slate-300">{` last `}</span>
                                    value of the year for a cumulative metric.
                                </span>
                            </div>
                        </PopoverContent>
                    </Popover>
                </span>
                <span className="flex items-center space-x-2">
                    {dataTabIndex > 0 && (
                        <CollapseAllControlButton
                            allAreCollapsed={!hasOpenDataBlocks}
                            setAllAreCollapsed={(hasOpenOutputs) => {
                                setModelBlockDisplayMapData(
                                    Object.keys(
                                        modelBlockDisplayMapData,
                                    ).reduce(
                                        (map, key) => ({
                                            ...map,
                                            [key]: !hasOpenOutputs,
                                        }),
                                        {},
                                    ),
                                );
                            }}
                        />
                    )}
                    <ShowHideButton
                        show={showSettingsTableRows}
                        setShow={setShowSettingsTableRows}
                        label={"Input Data"}
                    />
                </span>
            </div>

            {dataTabIndex === 0 &&
                !!selectedDataModelBlock &&
                dataModelBlocks?.length > 0 && (
                    <div className="text-slate-700 transition-all dark:text-slate-300">
                        <ModelBlockCarouselMenu
                            selectedModelBlock={selectedDataModelBlock}
                            setSelectedModelBlock={setSelectedDataModelBlock}
                            modelBlocks={dataModelBlocks}
                        />
                    </div>
                )}

            {dataTabIndex === 0 &&
                !!selectedDataModelBlock &&
                orderedKeyMetricTargetVariables?.length > 0 && (
                    <div className="text-slate-700 transition-all dark:text-slate-300">
                        {!showDataInMonthsNotYears ? (
                            <CarouselCardDataTable
                                modelBlock={selectedDataModelBlock}
                                modelVariables={
                                    dataModelVariableMap &&
                                    dataModelVariableMap[
                                        selectedDataModelBlock.id
                                    ]
                                        ? {
                                              [selectedDataModelBlock.id]:
                                                  dataModelVariableMap[
                                                      selectedDataModelBlock.id
                                                  ],
                                          }
                                        : {}
                                }
                                valuesMap={pythonValuesYearly ?? {}}
                                showResampleFunction={true}
                            />
                        ) : (
                            <CarouselCardDataTable
                                modelBlock={selectedDataModelBlock}
                                modelVariables={
                                    dataModelVariableMap &&
                                    dataModelVariableMap[
                                        selectedDataModelBlock.id
                                    ]
                                        ? {
                                              [selectedDataModelBlock.id]:
                                                  dataModelVariableMap[
                                                      selectedDataModelBlock.id
                                                  ],
                                          }
                                        : {}
                                }
                                valuesMap={pythonValuesMonthly ?? {}}
                            />
                        )}
                    </div>
                )}

            {dataTabIndex === 1 &&
                orderedKeyMetricTargetVariables?.length > 0 && (
                    <div className="text-slate-700 transition-all dark:text-slate-300">
                        {!showDataInMonthsNotYears ? (
                            <AccordionCardDataTable
                                modelBlocks={dataModelBlocks}
                                modelVariables={dataModelVariableMap ?? {}}
                                valuesMap={pythonValuesYearly ?? {}}
                                showResampleFunction={true}
                                modelBlockDisplayMap={modelBlockDisplayMapData}
                                setModelBlockDisplayMap={
                                    setModelBlockDisplayMapData
                                }
                            />
                        ) : (
                            <AccordionCardDataTable
                                modelBlocks={dataModelBlocks}
                                modelVariables={dataModelVariableMap ?? {}}
                                valuesMap={pythonValuesMonthly ?? {}}
                                modelBlockDisplayMap={modelBlockDisplayMapData}
                                setModelBlockDisplayMap={
                                    setModelBlockDisplayMapData
                                }
                            />
                        )}
                    </div>
                )}

            {dataTabIndex === 2 &&
                orderedKeyMetricTargetVariables?.length > 0 && (
                    <div>
                        {!showDataInMonthsNotYears ? (
                            <CompactDataTable
                                modelBlocks={dataModelBlocks}
                                modelVariables={dataModelVariableMap ?? {}}
                                valuesMap={pythonValuesYearly ?? {}}
                                showResampleFunction={true}
                                modelBlockDisplayMap={modelBlockDisplayMapData}
                                setModelBlockDisplayMap={
                                    setModelBlockDisplayMapData
                                }
                            />
                        ) : (
                            <CompactDataTable
                                modelBlocks={dataModelBlocks}
                                modelVariables={dataModelVariableMap ?? {}}
                                valuesMap={pythonValuesMonthly ?? {}}
                                modelBlockDisplayMap={modelBlockDisplayMapData}
                                setModelBlockDisplayMap={
                                    setModelBlockDisplayMapData
                                }
                            />
                        )}
                    </div>
                )}
        </div>
    );
};

export const InteractiveSectionData = React.memo(
    InteractiveSectionDataComponent,
);
