import React, { ReactNode, useContext } from "react";
import { createHeadManager, router } from "@inertiajs/core";

import { StyleSheetManager, ThemeProvider } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {
    ColorGroup,
    ContentBlockShape,
    ContentBlockType,
    ModelBlock,
    ModelVariableDataType,
    ModelVariableType,
    SimulationShape,
    Tenant,
    TimeHorizon,
} from "@/models";
import { GloablStyledComponent, GlobalTheme } from "@/styles";
import EditorContainer from "../EditorContainer";
import { mode } from "../../../../tests/vitest.setup";
export const mockSimulation: SimulationShape = {
    id: "1",
    title: "Test Simulation",
    description: "Test Simulation Description",
    created_at: "2021-01-01 00:00:00",
    updated_at: new Date().toISOString(),
    slug: "test-simulation",
    rounds: [],
    weight: 0,
    theme: {
        palettes: {
            dark: {
                backgroundColor: "#1f2a37",
                textColor: "#9ca3af",
                borderColor: "#6b7280",
                highlightBackgroundColor: "#374151",
                highlightColor: "#d1d5db",
                selectedColor: "#9AE6B4",
                unSelectedColor: "#d1d5db",
            },
            light: {
                backgroundColor: "#fff",
                textColor: "#4b5563",
                borderColor: "#e5e7eb",
                highlightBackgroundColor: "#f3f4f6",
                highlightColor: "#1f2a37",
                selectedColor: "#9AE6B4",
                unSelectedColor: "#d1d5db",
            },
        },
    },
    colorGroups: [
        {
            id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
            label: "Deloitte Color Scheme",
            created_at: "2023-03-19T17:54:26.000000Z",
            updated_at: "2023-03-19T17:54:26.000000Z",
            colors: [
                {
                    id: "466d3a56-1cdf-40fc-bb96-049a00ea6b60",
                    css_color: "#DA291C",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "31d92854-640c-4e8f-989b-9711ec5573b4",
                    css_color: "#ED8B00",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "881167ba-a472-41a0-908e-9658227aa309",
                    css_color: "#FFCD00",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "3e6a35df-f926-46fb-8438-d6c5d76b488f",
                    css_color: "#E3E48D",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "d19798be-21ad-42fc-a6d7-c32a4d64528c",
                    css_color: "#6FC2B4",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "c6c8492e-65df-4df9-85e3-b6a752196f9b",
                    css_color: "#A0DCFF",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "cd1c4a50-dc75-4094-a159-daa8826194c8",
                    css_color: "#63666A",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "66fe6ebf-8dae-43fb-aee2-978abb09177a",
                    css_color: "#009A44",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "45037f9d-2328-46a3-9c00-1c624fc96227",
                    css_color: "#00ABAB",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "f3b52d19-3067-49a2-adc8-8f09a9888119",
                    css_color: "#005587",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "e2fed1dc-4cc7-42c6-ac68-76964af6bec9",
                    css_color: "#DDEFE8",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "5f3516a8-3759-46ec-8e16-54a79954f8a7",
                    css_color: "#007680",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "e915fcf7-a5a8-476b-b07b-3caa617f3a8f",
                    css_color: "#041E42",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "ae8377b7-b7a4-4c82-a90e-946741546a09",
                    css_color: "#9DD4CF",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "316ae34f-adb2-495b-ba97-a1521c370e24",
                    css_color: "#004F59",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "e524410a-a802-4ecf-a56e-59807d534740",
                    css_color: "#A7A8AA",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "ff31e072-b7b5-4b71-82dd-cd115e242d60",
                    css_color: "#ffffff",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "584ae3fb-56dc-4925-9c10-d05c7568605d",
                    css_color: "#C4D600",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "9271dbec-5249-4941-8808-8de59066f9f6",
                    css_color: "#0097A9",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "08e5ad84-0ad6-4d2d-b47e-4a344db9ea39",
                    css_color: "#012169",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "18d72b7e-be85-49e6-b830-2a88416ecd82",
                    css_color: "#75787B",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "80ed1e89-c7e7-42f5-8261-4448fa437506",
                    css_color: "#43B02A",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "720f4ce0-6fa9-42b6-8b8c-ade244dd0fcd",
                    css_color: "#62B5E5",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "fe34b756-1adf-4561-8353-f5ca14e216fd",
                    css_color: "#D0D0CE",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "824f79f7-40b1-41c9-add8-db47cff7bf19",
                    css_color: "#53565A",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "22552f2d-d93c-44ba-9bef-27ef8348ed6b",
                    css_color: "#046A38",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "ac3e2518-06c0-4018-80df-34d9c319b5d1",
                    css_color: "#00A3E0",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "9184014c-a895-4017-afbe-a7b93660578f",
                    css_color: "#BBBCBC",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "9d8cbf70-f895-43ee-a1e1-b43266ee3ecd",
                    css_color: "#2C5234",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "1da50210-effb-48dd-a2e0-38668ef16766",
                    css_color: "#0076A8",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "ee3527c5-6913-4a82-97ca-b3b9fc0fe209",
                    css_color: "#97999B",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "0cc61114-d7ff-45ed-99bd-b33a59435644",
                    css_color: "#000000",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "642907e9-b1cf-4ae1-accb-14cae00dcf1c",
                    css_color: "#86BC25",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "547b4c86-6fe6-4133-a007-379d525db97f",
                    css_color: "#1A56DB",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "c01157ad-2628-476c-bcf6-a093ef04b3dc",
                    css_color: "#374151",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "7a0efa71-c59a-4495-903b-ab61e7ff12dd",
                    css_color: "rgba(0, 0, 0, 0.16)",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
                {
                    id: "72124062-3a6d-4803-8b1b-6b8a543c8286",
                    css_color: "rgba(0, 0, 0, 0.06)",
                    color_group_id: "1db0986b-0e3e-4588-8d78-d7283eb289b9",
                    created_at: "2023-03-19T17:54:26.000000Z",
                    updated_at: "2023-03-19T17:54:26.000000Z",
                },
            ],
        } as ColorGroup,
    ],
    modelBlocks: [
        {
            weight: 0,
            label: "",
            description: "",
            simulation_id: "1",
            created_at: "2021-01-01 00:00:00",
            updated_at: new Date().toISOString(),
            id: "1",
            modelVariables: [
                {
                    id: "1",
                    model_block_id: "1",
                    label: "your_label",
                    weight: 0,
                    variable_type: ModelVariableType.Logical,
                    data_type: ModelVariableDataType.Boolean,
                    uses_time: false,
                    default_numerical_value: 0,
                    default_boolean_value: false,
                    is_integer: false,
                    is_absolute_value: false,
                    expose_to_facilitator: false,
                    expose_to_designer: false,
                    is_key_metric: false,
                    // Add the remaining properties here
                },
                {
                    id: `2`,
                    model_block_id: "1",
                    label: "numeric 1",
                    weight: 1,
                    variable_type: ModelVariableType.Independent,
                    data_type: ModelVariableDataType.Number,
                    uses_time: true,
                    default_numerical_value: 0,
                    default_boolean_value: false,
                    is_integer: false,
                    is_absolute_value: false,
                    expose_to_facilitator: false,
                    expose_to_designer: false,
                    is_key_metric: false,
                },
                {
                    id: `3`,
                    model_block_id: "1",
                    label: "numeric 2",
                    weight: 1,
                    variable_type: ModelVariableType.Independent,
                    data_type: ModelVariableDataType.Number,
                    uses_time: true,
                    default_numerical_value: 0,
                    default_boolean_value: false,
                    is_integer: false,
                    is_absolute_value: false,
                    expose_to_facilitator: false,
                    expose_to_designer: false,
                    is_key_metric: false,
                },
            ],
            modelBlocks: [],
            has_connections: false,
        } as ModelBlock,
    ],
};

import { createContext } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const PageContext = createContext(undefined);
PageContext.displayName = "InertiaPageContext";
export const mockTimeHorizons = [
    {
        id: "1",
        created_at: "2021-01-01 00:00:00",
        updated_at: new Date().toISOString(),
        weight: 0,
        simulation_id: mockSimulation.id,
        time_index: 0,
    },
    {
        id: "2",
        created_at: "2021-01-01 00:00:00",
        updated_at: new Date().toISOString(),
        weight: 0,
        simulation_id: mockSimulation.id,
        time_index: 1,
    },
] as TimeHorizon[];
export function renderEditorContainer(
    additionalContentBlocks: ContentBlockShape[] = [],
    additionalProps?: Partial<React.ComponentProps<typeof EditorContainer>>,
    additionalChildren: React.ReactNode = <></>
) {
    if (mode !== "test") return;

    const contentBlocks: ContentBlockShape[] = [
        {
            content_block_type: ContentBlockType.Page,
            id: "page-1",
            title: "Test Page",
        },
        ...additionalContentBlocks,
    ];

    const props = {
        auth: {
            user: {
                id: "1",
                name: "John Doe 1",
                email: "john@test.com",
                roles: [],
            },
            isAdmin: true,
        },
        errors: undefined,
        toast: undefined,
        progress_messages: [],
        contentBlocks,
        timeHorizons: mockTimeHorizons,
        rounds: [],
        topLevelContentBlockLayers: [],
        simulation: mockSimulation,
        round: {
            id: "1",
            title: "Test Round",
            created_at: "2021-01-01 00:00:00",
            updated_at: new Date().toISOString(),
            weight: 0,
            simulation_id: "1",
            time_horizon_id: "1",
            has_content_blocks: true,
            contentBlocks: [],
            rounds: [],
        },
        tenant: {
            logo_url: "logo",
            name: "tenant name",
            slug: "/tenant-slug",
            tenant_name: "tenant name",
            domains: [{ domain: "tenant-domain.com" }],
        } as Tenant,
        ...additionalProps,
    };

    return (
        <EditorContainerWrapper
            {...props}
            additionalChilren={additionalChildren}
        />
    );
}

function EditorContainerWrapper(
    props: Partial<React.ComponentProps<typeof EditorContainer>> & {
        additionalChilren: React.ReactNode;
    }
) {
    const textPageContext = useContext(PageContext);

    const locationInfo = props?.locationInfo || {
        location: "",
        query: {},
    };

    const mockedProps = props as React.ComponentProps<typeof EditorContainer>;

    return (
        <StyleSheetManager
            shouldForwardProp={(prop) => {
                return isPropValid(prop);
            }}
        >
            <GloablStyledComponent />
            <ThemeProvider theme={GlobalTheme}>
                <PageContext.Provider value={locationInfo}>
                    <EditorContainer {...mockedProps}>
                        {props?.additionalChildren}
                    </EditorContainer>
                </PageContext.Provider>
            </ThemeProvider>
        </StyleSheetManager>
    );
}

const queryClient = new QueryClient();

export const renderSimpleQueryClient = () => {
    return SimpleQueryClientWrapper({ children: <></> });
};
export const SimpleQueryClientWrapper = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};
