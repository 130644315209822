import React, { useMemo } from "react";
import {
    useInsightVariables,
    useModelTimespan,
    usePythonValuesYearly,
} from "../state";
import {
    ButtonGroupMenu,
    CardWidgetWrapper,
    ScrollableWrapper,
} from "../shared";
import { useInterfaceStateInsights } from "../useInterfaceState";

// assume max of 20 model years
const gridColumnsTimespanArray = [
    "grid-cols-2",
    "grid-cols-3",
    "grid-cols-4",
    "grid-cols-5",
    "grid-cols-6",
    "grid-cols-7",
    "grid-cols-8",
    "grid-cols-9",
    "grid-cols-10",
    "grid-cols-11",
    "grid-cols-12",
    "grid-cols-13",
    "grid-cols-14",
    "grid-cols-15",
    "grid-cols-16",
    "grid-cols-17",
    "grid-cols-18",
    "grid-cols-19",
    "grid-cols-20",
    "grid-cols-21",
    "grid-cols-22",
];

const InteractiveSectionInsightsComponent = ({}: {}) => {
    const { insightsTabIndex, setInsightsTabIndex } =
        useInterfaceStateInsights();
    const modelTimespan = useModelTimespan();
    const insightVariables = useInsightVariables();
    const pythonValuesYearly = usePythonValuesYearly();

    const modelYears = useMemo(() => {
        return Array(modelTimespan)
            .fill(0)
            .map((_, i) => `Year ${i + 1}`);
    }, [modelTimespan]);

    const displayMap: {
        [yearLabel: string]: { [variableId: string]: string };
    } = useMemo(() => {
        return modelYears?.reduce((yearMap, label, index) => {
            if (
                !!pythonValuesYearly &&
                Object.keys(pythonValuesYearly)?.length > 0
            ) {
                return {
                    ...yearMap,
                    [label]: insightVariables?.reduce(
                        (variableMap, variable) => {
                            if (
                                !!pythonValuesYearly &&
                                pythonValuesYearly[variable.id]?.length ==
                                    modelTimespan &&
                                !!pythonValuesYearly[variable.id][index]
                                    .boolean_value
                            ) {
                                return {
                                    ...variableMap,
                                    [variable.id]: variable.label,
                                };
                            } else {
                                return variableMap;
                            }
                        },
                        {},
                    ),
                };
            }
        }, {});
    }, [modelYears, insightVariables, pythonValuesYearly]);

    const gridColumns = useMemo(
        () =>
            modelTimespan >= 0 &&
            modelTimespan < gridColumnsTimespanArray?.length
                ? gridColumnsTimespanArray[modelTimespan]
                : undefined,
        [modelTimespan],
    );

    return (
        <div className="flex w-full flex-col space-y-6 text-slate-700 dark:text-slate-300">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold">{"Insights"}</h1>
                <ButtonGroupMenu
                    selectedIndex={insightsTabIndex}
                    setSelectedIndex={setInsightsTabIndex}
                    buttonItems={["Timeline View", "List View"]}
                    isPrimary={false}
                />
            </div>

            {insightsTabIndex === 0 && (
                <CardWidgetWrapper>
                    <ScrollableWrapper>
                        <div className="min-w-[640px] sm:w-full">
                            {modelYears?.length > 0 && !!gridColumns && (
                                <div
                                    className={`grid ${gridColumns} gap-y-8 p-4`}
                                >
                                    <div className="col-span-2"></div>
                                    {modelYears.map((label) => (
                                        <div
                                            className="mx-2 text-center text-sm font-extrabold tracking-wide text-slate-600
                                                transition-all dark:text-slate-400"
                                            key={label}
                                        >
                                            {label}
                                        </div>
                                    ))}
                                    {!!displayMap &&
                                        Object.keys(displayMap)?.length > 0 &&
                                        insightVariables?.map((variable) => (
                                            <React.Fragment key={variable.id}>
                                                <div className="col-span-2 flex items-center pr-2 text-sm">
                                                    {variable.label}
                                                </div>
                                                {modelYears.map((label, i) => (
                                                    <div
                                                        className="flex items-center"
                                                        key={
                                                            variable.id + label
                                                        }
                                                    >
                                                        <div
                                                            className={`flex min-h-4 w-full justify-center border-y border-blue-700 border-opacity-50
                                                            text-xs text-white transition-all dark:border-blue-800 dark:border-opacity-50 ${
                                                                !!displayMap[
                                                                    label
                                                                ][variable.id]
                                                                    ? "bg-blue-700 bg-opacity-75 dark:bg-blue-800 dark:bg-opacity-100"
                                                                    : ""
                                                            } ${
                                                                i == 0
                                                                    ? "ml-2 rounded-l-full border-l"
                                                                    : i ==
                                                                        modelTimespan -
                                                                            1
                                                                      ? "mr-2 rounded-r-full border-r"
                                                                      : ""
                                                            }`}
                                                        >
                                                            {""}
                                                        </div>
                                                    </div>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </div>
                            )}
                        </div>
                    </ScrollableWrapper>
                </CardWidgetWrapper>
            )}

            {insightsTabIndex === 1 && (
                <div className="grid grid-cols-1 gap-5">
                    {modelYears?.map((label, i) => (
                        <div
                            className="interactive-widget flex flex-col rounded-md border border-slate-200
                                bg-slate-50/50 transition-all dark:border-slate-800 dark:bg-slate-800/25"
                            key={label}
                        >
                            <div className="flex items-center space-x-8 p-4">
                                <div
                                    className={`w-20 min-w-20 text-lg font-extrabold tracking-wide text-slate-600 transition-all
                                    dark:text-slate-400 ${
                                        !(
                                            !!displayMap &&
                                            Object.keys(displayMap)?.length > 0
                                        )
                                            ? "animate-pulse"
                                            : ""
                                    }`}
                                >
                                    {label}
                                </div>
                                <div
                                    className="flex w-full flex-col space-y-2 text-sm text-slate-600 transition-all
                                        dark:text-slate-400"
                                >
                                    {!!displayMap &&
                                        Object.keys(displayMap)?.length > 0 && (
                                            <>
                                                {Object.keys(displayMap[label])
                                                    ?.length > 0 ? (
                                                    Object.keys(
                                                        displayMap[label],
                                                    ).map((key) => (
                                                        <div
                                                            key={key}
                                                            className="w-full rounded-md bg-blue-700 bg-opacity-75 px-3 py-1.5 font-semibold
                                                                tracking-wide text-white transition-all dark:bg-blue-800 dark:bg-opacity-100"
                                                        >
                                                            {
                                                                displayMap[
                                                                    label
                                                                ][key]
                                                            }
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="px-3 py-1 text-slate-400 transition-all dark:text-slate-600">{`No highlights`}</div>
                                                )}
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export const InteractiveSectionInsights = React.memo(
    InteractiveSectionInsightsComponent,
);
