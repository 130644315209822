import React, { useCallback, useEffect } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { PromptType } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { H6Span } from "@/components";

import PromptTypeTag from "./PromptTypeTag";
import QuestionModalFooter from "./QuestionModalFooter";
import { useForm } from "@inertiajs/react";
import { buildDragAndDropCard } from "@/model-configs/content-block-layouts/CardLayouts";
import { useModalQueryParams, useSelectedRoundId } from "@/hooks";
import { useGetContentBlockById } from "../modals/shared-queries/useGetContentBlockById";
import { useDesignLayerProps } from "../shared-props";

function DragAndDropBuilderContents() {
    const { queryParams, closeModal } = useModalQueryParams(
        "DragAndDropQuestionModal",
    );
    const { contentBlockId } = queryParams;
    const { modalPageErrors } = useDesignLayerProps()?.designLayerProps;

    const { isLoading, data: contentBlock } =
        useGetContentBlockById(contentBlockId);

    const { prompt } = contentBlock || { prompt: null };
    const { selectedRoundId } = useSelectedRoundId();
    const scopes = ["Participant", "Team"];

    const leftButtonHandler = useCallback(closeModal, []);

    const { data, setData } = useForm({
        id: prompt?.id || "",
        content: prompt?.content || "",
        scope: prompt?.scope || scopes[0],
        prompt_type: PromptType["Drag and Drop"],
        content_block_id: contentBlockId || "content_block_id",
        numberOfOptions: 1,
        contentBlockShape: buildDragAndDropCard(
            "",
            contentBlockId || "content_block_id",
            0,
            0,

            "",
            {},
        ),
    });

    useEffect(() => {
        setData({
            id: prompt?.id || "",
            content: prompt?.content || "",
            scope: prompt?.scope || scopes[0],
            prompt_type: PromptType["Drag and Drop"],
            content_block_id: contentBlockId || "content_block_id",
            numberOfOptions: data.numberOfOptions,
            contentBlockShape: data.contentBlockShape,
        });
    }, [prompt?.id]);

    const saveDragAndDrop = useCallback(() => {
        SapienInertia.put(
            "admin.design.questions.drag-and-drop.update",
            data,
            {
                drag_and_drop: prompt?.id,
            },
            {
                onSuccess: () => {
                    let blocksToSave = [];
                    for (let i = 0; i < data.numberOfOptions; i++) {
                        console.log(i);
                        const block = buildDragAndDropCard(
                            `Option ${i + 1}`,
                            contentBlockId || "content_block_id",
                            i,
                            i,
                            "",
                            {},
                        );
                        block.round_id = selectedRoundId;
                        blocksToSave = [
                            ...blocksToSave,
                            { ...block, theme: JSON.stringify(block.theme) },
                        ];
                    }
                    SapienInertia.post(
                        "admin.design.drag-and-drop-prompt-containers.multiple-options.store",
                        {
                            promptId: prompt.id,
                            contentBlocks: blocksToSave,
                        },
                    );
                },
            },
        );
    }, [data, prompt]);

    if (!prompt) return <></>;

    return (
        <div className="py-6 text-white">
            <div
                className={`flex px-6 ${
                    !prompt.id ? "justify-between" : "justify-end"
                }`}
            >
                <PromptTypeTag promptType={prompt.prompt_type} />
            </div>

            <div className="flex flex-row items-center justify-between p-6 text-white">
                <H6Span>
                    {!!prompt?.id ? "Edit " : "Create "} Drag and Drop
                </H6Span>
            </div>

            <form
                className="flex flex-col space-y-4 border-t border-[#374151] p-6 text-white"
                onSubmit={() => {
                    saveDragAndDrop();
                }}
            >
                <div className="flex justify-between">
                    <label>Scope</label>
                    <select
                        value={data.scope}
                        onChange={(e) => setData("scope", e.target.value)}
                        className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm"
                    >
                        {scopes.map((scope) => (
                            <option
                                className="bg-[#29303e]"
                                key={scope}
                                value={scope}
                            >
                                {scope}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-between">
                    <label>Prompt</label>
                    <div className="w-full max-w-3xl">
                        <textarea
                            value={data.content}
                            onChange={(e) => {
                                setData("content", e.target.value);
                            }}
                            className={`block w-full max-w-3xl  border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm
                        ${
                            modalPageErrors?.content
                                ? " rounded-t-md border-pink-500"
                                : "rounded-md"
                        }`}
                        ></textarea>
                        {!!modalPageErrors?.content && (
                            <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                {modalPageErrors?.content}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex justify-between">
                    <label>Number of Options</label>
                    <div className="w-full max-w-xl">
                        <input
                            className="block w-full rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm"
                            type="number"
                            value={data?.numberOfOptions}
                            onChange={(e) => {
                                setData(
                                    "numberOfOptions",
                                    parseInt(e.target.value),
                                );
                            }}
                        />
                        {!!modalPageErrors?.content && (
                            <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                {modalPageErrors?.content}
                            </p>
                        )}
                    </div>
                </div>
            </form>
            <QuestionModalFooter
                isLastStep={true}
                isFirstStep={true}
                rightButtonHandler={saveDragAndDrop}
                leftButtonHandler={leftButtonHandler}
            />
        </div>
    );
}

export default function DragAndDropBuilder() {
    const { isAtModalUrl, closeModal } = useModalQueryParams(
        "DragAndDropQuestionModal",
    );

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={closeModal}
            size="l"
            backgroundColor="#111928"
        >
            {isAtModalUrl && <DragAndDropBuilderContents />}
        </ModalContainer>
    );
}
