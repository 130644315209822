import React from "react";
import {
    ThemeObject,
    ConfigComponentType,
    themePropertyConfig,
    ThemeProperty,
} from "./ThemeObjects";
import {
    FormSelectArrayIndex,
    FormSelectObject,
    FormInputRadioArrayElement,
    FormInputToggleArrayElement,
    FormInputColorPicker,
    FormInputRangeSlider,
    FormReponsiveProperty,
    FormSelectEnumType,
} from "./editor-components";
import { FormGroup } from "@/components";
import { VariantOption } from "@/model-configs";

export const ThemeConfigDisplay = (props: {
    id: string;
    value: any;
    themeObjectKey: keyof ThemeObject;
    changeHandler: (themeObjectKey: keyof ThemeObject, value: any) => void;
    groupProperties?: { [index in keyof ThemeObject]: any };
    variantOptions?: VariantOption;
    displayNameOverride?: string;
}) => {
    const {
        id,
        value,
        themeObjectKey,
        changeHandler,
        groupProperties,
        variantOptions,
    } = props;

    const configProperties =
        !!variantOptions && !!Object.keys(variantOptions).length
            ? (variantOptions as ThemeProperty)
            : themePropertyConfig[themeObjectKey];

    const label = props.displayNameOverride || configProperties?.label;

    if (configProperties !== undefined) {
        switch (configProperties.configComponentType) {
            case ConfigComponentType.FormInputColorPicker:
                return (
                    <>
                        <FormInputColorPicker
                            value={value}
                            label={label}
                            themeObjectKey={configProperties.themeObjectKey}
                            handleChange={changeHandler}
                        />
                    </>
                );
            case ConfigComponentType.FormInputRadioArrayElement:
                return (
                    <FormInputRadioArrayElement
                        value={value}
                        label={label}
                        themeObjectKey={configProperties.themeObjectKey}
                        handleChange={changeHandler}
                        displayProperty={
                            configProperties.displayFunction || undefined
                        }
                        optionArray={configProperties.optionArray}
                    />
                );
            case ConfigComponentType.FormInputRangeSlider:
                return configProperties.isGrouped ? (
                    <>
                        {groupProperties !== undefined &&
                            configProperties.groupObject !== undefined && (
                                <>
                                    <div className="mb-2 text-sm">{label}</div>
                                    <FormGroup className="flex flex-col space-y-2">
                                        {Object.keys(
                                            configProperties.groupObject,
                                        ).map(
                                            (key) =>
                                                groupProperties[key] !==
                                                    undefined && (
                                                    <div
                                                        className="flex gap-1"
                                                        key={key}
                                                    >
                                                        <FormInputRangeSlider
                                                            key={key}
                                                            value={
                                                                groupProperties[
                                                                    key
                                                                ]
                                                            }
                                                            label={
                                                                configProperties
                                                                    .groupObject[
                                                                    key
                                                                ]
                                                            }
                                                            themeObjectKey={
                                                                key as keyof ThemeObject
                                                            }
                                                            handleChange={
                                                                changeHandler
                                                            }
                                                            {...configProperties.sliderData}
                                                            name={key}
                                                        />
                                                    </div>
                                                ),
                                        )}
                                    </FormGroup>
                                </>
                            )}
                    </>
                ) : (
                    <FormGroup>
                        <FormInputRangeSlider
                            value={value}
                            label={label}
                            themeObjectKey={configProperties.themeObjectKey}
                            handleChange={changeHandler}
                            {...configProperties.sliderData}
                        />
                    </FormGroup>
                );
            case ConfigComponentType.FormInputToggleArrayElement:
                return (
                    <>
                        {configProperties.optionArray !== undefined &&
                            configProperties.optionArray.length > 0 && (
                                <FormInputToggleArrayElement
                                    id={`${id}-${configProperties.themeObjectKey}`}
                                    value={value}
                                    label={label}
                                    themeObjectKey={
                                        configProperties.themeObjectKey
                                    }
                                    handleChange={changeHandler}
                                    uncheckedOption={
                                        configProperties.optionArray[0]
                                    }
                                    checkedOption={
                                        configProperties.optionArray.length >
                                            1 && configProperties.optionArray[1]
                                    }
                                />
                            )}
                    </>
                );
            case ConfigComponentType.FormSelectArrayIndex:
                return (
                    <FormSelectArrayIndex
                        value={value}
                        label={label}
                        themeObjectKey={configProperties.themeObjectKey}
                        handleChange={changeHandler}
                        displayProperty={
                            configProperties.displayFunction || undefined
                        }
                        optionArray={configProperties.optionArray}
                    />
                );
            case ConfigComponentType.FormSelectObject:
                return (
                    <FormSelectObject
                        value={value}
                        label={label}
                        themeObjectKey={configProperties.themeObjectKey}
                        handleChange={changeHandler}
                        displayProperty={
                            configProperties.displayFunction || undefined
                        }
                        optionObject={configProperties.optionObject}
                    />
                );
            case ConfigComponentType.FormResponsiveProperty:
                return (
                    <FormReponsiveProperty
                        value={value}
                        label={label}
                        themeObjectKey={configProperties.themeObjectKey}
                        handleChange={changeHandler}
                        responsiveOptions={configProperties.responsiveOptions}
                    />
                );
            case ConfigComponentType.FormSelectEnumType:
                return (
                    <FormSelectEnumType
                        value={value}
                        label={label}
                        themeObjectKey={configProperties.themeObjectKey}
                        handleChange={changeHandler}
                        displayProperty={
                            configProperties.displayFunction || undefined
                        }
                        optionObject={configProperties.optionObject}
                    />
                );
            default:
                return null;
        }
    } else {
        return null;
        // <>
        //     <pre>
        //         hey
        //         {JSON.stringify(
        //             {
        //                 configProperties,
        //                 id,
        //                 value,
        //                 themeObjectKey,
        //                 changeHandler,
        //                 variantOptions,
        //                 theme: themePropertyConfig[themeObjectKey],
        //             },
        //             null,
        //             4
        //         )}
        //     </pre>
        // </>
    }
};
