import { SapienInertia } from "@/inertia-utils/hooks";
import { Switch } from "@headlessui/react";
import React from "react";

export enum AdvanceAllowedStatus {
    ALL_ALLOWED = "All allowed",
    SOME_ALLOWED = "Some allowed",
    NONE_ALLOWED = "None allowed",
}

export const allowAdvanceStatusMap = Object.keys(AdvanceAllowedStatus);
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
type Props = {
    status: keyof typeof AdvanceAllowedStatus;
    targetType: "Cohort" | "Team" | "User";
};

export function AllowTeamOrCohortAdvance({
    status,
    team_id,
    cohort_id,
    targetType,
}: Props & { team_id?: string; cohort_id?: string }) {
    function setAllowTeam(is_allowed: boolean) {
        SapienInertia.post("admin.allow-advance.team", {
            team_id,
            is_allowed,
        });
    }
    function setAllowCohort(is_allowed: boolean) {
        SapienInertia.post("admin.allow-advance.cohort", {
            cohort_id,
            is_allowed,
        });
    }

    return (
        <div className="flex flex-row items-center justify-center space-x-2">
            <button
                type="submit"
                value="Submit"
                className={`border-1 rounded-md border border-red-600 px-2 py-1 text-sm font-medium transition-all focus:outline-none focus:ring-2 focus:ring-red-500
                    ${
                        status === "NONE_ALLOWED"
                            ? "bg-red-600 text-white hover:border-red-700 hover:bg-red-700"
                            : "bg-none text-red-600 hover:bg-red-600 hover:text-white"
                    }`}
                onClick={(e) => {
                    e.preventDefault();
                    if (targetType === "Team") {
                        setAllowTeam(false);
                    } else {
                        setAllowCohort(false);
                    }
                }}
            >
                No
            </button>
            <button
                type="button"
                className={`border-1 rounded-md border border-green-700 px-2 py-1 text-sm font-medium transition-all focus:outline-none focus:ring-2 focus:ring-green-600
                    ${
                        status === "ALL_ALLOWED"
                            ? "bg-green-700 text-white hover:border-green-800 hover:bg-green-800"
                            : "bg-none text-green-700 hover:bg-green-700 hover:text-white"
                    }`}
                onClick={(e) => {
                    e.preventDefault();
                    if (targetType === "Team") {
                        setAllowTeam(true);
                    } else {
                        setAllowCohort(true);
                    }
                }}
            >
                Yes
            </button>
        </div>
    );
}

export function AllowParticipantAdvance({
    status,
    team_user_round_id,
}: Omit<Props, "targetType"> & { team_user_round_id: string }) {
    return (
        <Switch.Group as="div" className="flex items-center justify-start">
            <Switch
                checked={status === "ALL_ALLOWED"}
                onChange={() => {
                    SapienInertia.post("admin.allow-advance.participant", {
                        is_allowed: status !== "ALL_ALLOWED",
                        team_user_round_id,
                    });
                }}
                className={classNames(
                    status === "ALL_ALLOWED" ? "bg-indigo-600" : "bg-slate-300",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        status === "ALL_ALLOWED"
                            ? "translate-x-5"
                            : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                />
            </Switch>
        </Switch.Group>
    );
}
