import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react";
import { useUploadStore } from "@/hooks";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";
import { useColorStore } from "@/hooks/store";
import { Transition, Menu } from "@headlessui/react";
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon,
    XMarkIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { getImageApiResponse } from "./getImageApiResponse";
import { PexelsVideoSelector } from "./PexelsVideoSelector";

type orientation = "all" | "landscape" | "portrait" | "square";
type size = "all" | "large" | "medium" | "small";

type SearchState = {
    orientation: orientation;
    size: size;
    color: string;
    page: number;
};

const initialSearchState: SearchState = {
    orientation: "all",
    size: "all",
    color: "",
    page: 1,
};

type Action =
    | { type: "orientation"; orientation: orientation }
    | { type: "size"; size: size }
    | { type: "color"; color: string }
    | { type: "reset" }
    | { type: "page"; page: number };

type Props = {
    mediaType: "image" | "video" | "backgroundImage";
};

export default function ImageOrVideoSearch({ mediaType }: Props) {
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { colorGroups } = useColorStore();

    const { setUploadedFileUrl, uploadedFileUrl } = useUploadStore();

    const colors = useMemo(() => {
        return colorGroups.reduce((colors, colorGroup) => {
            return [...colors, ...colorGroup.colors];
        }, []);
    }, [colorGroups]);

    const [searchTerm, setSearchTerm] = useState("");
    const [areFiltersShown, setAreFiltersShown] = useState(false);

    const searchStateReducer = useCallback(
        (state: SearchState, action: Action): SearchState => {
            switch (action.type) {
                case "orientation":
                    return {
                        ...state,
                        orientation: action.orientation,
                        page: 1,
                    };
                case "size":
                    return { ...state, size: action.size, page: 1 };
                case "color":
                    return { ...state, color: action.color, page: 1 };
                case "page":
                    return { ...state, page: action.page };
                case "reset":
                    return initialSearchState;
            }
        },
        [searchTerm],
    );

    const [{ page, orientation, size, color }, dispatch] = useReducer(
        searchStateReducer,
        initialSearchState,
    );

    const modalProperty = mediaType === "image" ? "ImageModal" : "VideoModal";

    const fileType = useMemo(() => {
        if (mediaType.toLocaleLowerCase().includes("image")) return "image";
        return "video";
    }, [mediaType]);

    const hasFilters = useMemo(() => {
        return orientation !== "all" || size !== "all" || !!color;
    }, [orientation, size, color]);

    const { data, isLoading, refetch } = useQuery({
        queryKey: [
            modalProperty,
            { page, searchTerm, mediaType, orientation, size, color },
        ],
        queryFn: () =>
            getImageApiResponse({
                page,
                query: searchTerm,
                mediaType,
                orientation,
                size,
                color,
                activeContentBlock,
            }),
        enabled: false,
        refetchOnWindowFocus: false,
    });

    const search = useCallback(() => {
        refetch();
    }, [page, searchTerm, mediaType, orientation, size, color]);

    useEffect(() => {
        if (searchTerm) search();
    }, [page, color, size, orientation]);

    return (
        <div className="flex w-full flex-col space-y-6 py-6">
            <h1>{mediaType}</h1>
            <div className="flex items-center justify-between space-x-2">
                <div className="relative w-3/5 max-w-2xl rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        data-testid="search-input"
                        type="text"
                        name="email"
                        id="email"
                        value={searchTerm}
                        className="block w-full rounded-md border-0 border-gray-300 bg-white/10 py-1.5 pl-10
                            text-gray-200 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                            sm:text-sm sm:leading-6"
                        placeholder={`Search for ${fileType}s`}
                        onChange={(event) => {
                            setSearchTerm(event.target.value);
                        }}
                    />
                </div>
                <div className="flex">
                    <button
                        onClick={() =>
                            setAreFiltersShown(
                                (areFiltersShown) => !areFiltersShown,
                            )
                        }
                        className={`inline-flex items-center justify-center border px-5 py-1.5 text-center text-sm
                        font-medium text-white transition-all focus:outline-none focus:ring-2
                        focus:ring-blue-300 ${hasFilters ? "rounded-l-md" : "rounded-md"}`}
                    >
                        <span>Filters</span>
                    </button>
                    {hasFilters && (
                        <button
                            onClick={() => dispatch({ type: "reset" })}
                            className={`inline-flex items-center justify-center rounded-r-md border border-l-0 px-4
                                py-1.5 text-center text-sm font-medium text-white transition-all
                                focus:outline-none focus:ring-2 focus:ring-blue-300`}
                        >
                            <XMarkIcon className="h-4 w-4" />
                        </button>
                    )}
                </div>
            </div>
            <Transition
                className="grid w-full max-w-2xl grid-cols-3"
                as={"div"}
                show={areFiltersShown}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100 z-50"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                {areFiltersShown && (
                    <>
                        <select
                            className="block w-full max-w-xl rounded-l-md border-0 border-r border-gray-300 bg-white/10
                                text-gray-200 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                                sm:text-sm"
                            value={orientation}
                            onChange={(e) =>
                                dispatch({
                                    type: "orientation",
                                    orientation: e.target.value as orientation,
                                })
                            }
                        >
                            <option className="bg-[#29303e]" value={"all"}>
                                All Orientations
                            </option>
                            <option className="bg-[#29303e]" value="landscape">
                                Landscape
                            </option>
                            <option className="bg-[#29303e]" value="portrait">
                                Portrait
                            </option>
                            <option className="bg-[#29303e]" value="square">
                                Square
                            </option>
                        </select>
                        <select
                            className="block w-full max-w-xl border-0 border-r border-gray-300 bg-white/10
                                text-gray-200 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                                sm:text-sm"
                            value={size}
                            onChange={(e) =>
                                dispatch({
                                    type: "size",
                                    size: e.target.value as size,
                                })
                            }
                        >
                            <option className="bg-[#29303e]" value={"all"}>
                                All Sizes
                            </option>
                            <option className="bg-[#29303e]" value="large">
                                Large
                            </option>
                            <option className="bg-[#29303e]" value="portrait">
                                Medium
                            </option>
                            <option className="bg-[#29303e]" value="square">
                                Small
                            </option>
                        </select>
                        <div
                            className="relative block w-full max-w-xl rounded-r-md border-0 border-gray-300 bg-white/10
                                text-gray-200 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                                sm:text-sm"
                        >
                            <Menu>
                                <Menu.Button
                                    className={
                                        "flex h-full w-full items-center justify-between space-x-2 px-2"
                                    }
                                >
                                    <div className="flex items-center space-x-2">
                                        {color && (
                                            <span
                                                className="h-4 w-4 rounded-full"
                                                style={{
                                                    background: color,
                                                }}
                                            ></span>
                                        )}
                                        <span>{color || "All Colors"} </span>
                                    </div>
                                    <ChevronDownIcon className="h-3 w-3" />
                                </Menu.Button>
                                <Menu.Items
                                    className={
                                        "absolute mt-2 flex max-w-sm flex-wrap gap-2 rounded-md bg-[#111928] p-4 shadow"
                                    }
                                >
                                    {colors
                                        ?.filter((color) =>
                                            color.css_color.startsWith("#"),
                                        )
                                        .map((color) => (
                                            <Menu.Item key={color.css_color}>
                                                <button
                                                    className="h-6 w-8 border border-white/10"
                                                    style={{
                                                        background:
                                                            color.css_color,
                                                    }}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: "color",
                                                            color: color.css_color,
                                                        });
                                                    }}
                                                ></button>
                                            </Menu.Item>
                                        ))}
                                </Menu.Items>
                            </Menu>
                        </div>
                    </>
                )}
            </Transition>

            <div>
                <button
                    data-testid="search-button"
                    className="rounded-full bg-blue-700 px-5 py-1.5 text-center text-sm font-medium text-white
                        transition-all hover:bg-blue-800 focus:outline-none focus:ring-2
                        focus:ring-blue-300"
                    onClick={search}
                >
                    <span>Search</span>
                </button>
            </div>
            {isLoading && (
                <div className="flex gap-2">
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                    <div className="h-20 w-20 animate-pulse rounded-md border border-gray-200"></div>
                </div>
            )}

            {!isLoading && data?.apiResponse?.photos?.length > 0 && (
                <div className="flex flex-wrap gap-2 border-t border-[#374151] py-6">
                    {data?.apiResponse?.photos?.map((photo) => {
                        return (
                            <button
                                data-testid={`photo-${photo.id}`}
                                key={photo.id}
                                onClick={() => {
                                    setUploadedFileUrl(photo.src.original);
                                }}
                                className={`overflow-hidden rounded-md border transition-all hover:scale-100
                                hover:border-white hover:opacity-100 ${
                                    uploadedFileUrl === photo.src.original
                                        ? "scale-105 border-white hover:scale-105"
                                        : "border-white/40"
                                } ${
                                    !!uploadedFileUrl &&
                                    uploadedFileUrl !== photo.src.original
                                        ? "scale-95 opacity-50"
                                        : ""
                                }`}
                            >
                                <img src={photo.src.small} alt={photo.alt} />
                            </button>
                        );
                    })}
                </div>
            )}
            {!!data?.apiResponse?.videos?.length && (
                <div
                    data-hasselection={!!uploadedFileUrl ? "yes" : "no"}
                    className="grid-flow-row grid-cols-1 items-center justify-center gap-2
                        data-[hasselection=yes]:flex data-[hasselection=no]:grid md:grid-cols-2
                        lg:grid-cols-3"
                >
                    {data.apiResponse.videos.map((video) => (
                        <PexelsVideoSelector
                            key={video.id}
                            video={video}
                            setUploadedFileUrl={setUploadedFileUrl}
                            uploadedFileUrl={uploadedFileUrl}
                        />
                    ))}
                </div>
            )}
            {!!data?.apiResponse && (
                <div
                    className="flex items-center border-t border-gray-200 py-3"
                    data-testid="pagingation"
                >
                    <button
                        onClick={() => dispatch({ type: "page", page: 1 })}
                        disabled={!data?.apiResponse.prevPage}
                        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white
                            px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50
                            disabled:cursor-not-allowed"
                    >
                        <ChevronDoubleLeftIcon className="h-4 w-4" />
                    </button>
                    <button
                        onClick={() =>
                            dispatch({ type: "page", page: page - 1 })
                        }
                        disabled={!data?.apiResponse.prevPage}
                        className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2
                            text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed"
                    >
                        <ChevronLeftIcon className="h-4 w-4" />
                    </button>
                    <button
                        data-testid="next-page-button"
                        onClick={() =>
                            dispatch({ type: "page", page: page + 1 })
                        }
                        disabled={!data?.apiResponse.nextPage}
                        className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2
                            text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed"
                    >
                        <ChevronRightIcon className="h-4 w-4" />
                    </button>
                    <button
                        onClick={() =>
                            dispatch({
                                type: "page",
                                page: Math.floor(
                                    data?.apiResponse.totalResults / 15,
                                ),
                            })
                        }
                        disabled={!data?.apiResponse.nextPage}
                        className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white
                            px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50
                            disabled:cursor-not-allowed"
                    >
                        <ChevronDoubleRightIcon className="h-4 w-4" />
                    </button>
                    <p className="ml-2 text-sm text-white/90">
                        Showing{" "}
                        <span className="font-medium">
                            {Math.min(
                                data?.apiResponse.totalResults - 15,
                                15 * Number(data?.apiResponse.page),
                            )}
                        </span>{" "}
                        to{" "}
                        <span className="font-medium">
                            {Math.min(
                                15 * Number(data?.apiResponse.page) + 15,
                                data?.apiResponse.totalResults,
                            )}
                        </span>{" "}
                        of{" "}
                        <span className="font-medium">
                            {data?.apiResponse.totalResults}
                        </span>
                        results
                    </p>
                </div>
            )}
        </div>
    );
}
