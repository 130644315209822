import {
    ContentBlockShape,
    ContentBlockType,
    Prompt,
    PromptShape,
} from "@/models";
import { TypographyType } from "@/styles";

export const testBlocks: ContentBlockShape[] = [
    {
        id: "6caa705f-0b96-4c88-b684-d4d28230d4f8123",
        content: "Accelerated Digital Practice",
        theme: {
            pb: 0,
            pt: 0,
            px: 5,
            color: "#ffffff",
            minHeight: "3rem",
            bottomBorder: "none",
            backgroundColor: "#009ACE",
        },
        created_at: "2023-09-22T03:23:08.000000Z",
        updated_at: "2023-09-22T03:24:01.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: null,
        title: "Navbar",
        weight: -1000,
        content_block_type: ContentBlockType.Navbar,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "3a2371a0-21a8-4c91-811f-a8e0d76a422b",
        content: "Open Modal",
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            px: 4,
            py: 0,
            color: "#000000",
            cursor: "pointer",
            display: "inline-flex",
            fontSize: 3,
            fontStyle: "normal",
            alignItems: "center",
            fontFamily: "0",
            fontWeight: 4,
            activeColor: "#0000ff",
            iconPosition: "before",
            letterSpacing: 4,
        },
        created_at: "2023-09-22T03:23:51.000000Z",
        updated_at: "2023-09-22T03:24:42.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Link,
        route: null,
        image_url: null,
        rich_text: {
            type: "doc",
            content: [
                {
                    type: "typography",
                    attrs: {
                        lineHeight: "100%",
                        indent: 0,
                        textAlign: "left",
                        typographyType: "PARAGRAPH",
                        default: null,
                    },
                    content: [
                        {
                            type: "text",
                            marks: [
                                {
                                    type: "textStyle",
                                    attrs: {
                                        color: "#ffffff",
                                        lineHeight: "100%",
                                        fontFamily: null,
                                        indent: 0,
                                        fontSize: 20,
                                    },
                                },
                            ],
                            text: "Decision Log",
                        },
                    ],
                },
            ],
        },
        video_urls: null,
        behaviors: {
            click: {
                ShowModalContentBlock: {
                    modalContentBlockId: "6600cf64-e6a5-4290-89da-d2533a323236",
                },
            },
        },
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "6600cf64-e6a5-4290-89da-d2533a323236",
        content: "MODAL",
        theme: {
            modalSize: "lg",
            backgroundColor: "rgba(255,255,255,1)",
        },
        created_at: "2023-09-22T03:23:51.000000Z",
        updated_at: "2023-09-22T03:23:51.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: null,
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Modal,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "6d6fa9fb-3b90-4af0-99fe-f29f2f647fa8",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-22T03:23:51.000000Z",
        updated_at: "2023-09-22T03:23:51.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "e3a24cc8-b9de-4207-95fc-58812581d3f3",
        content: "DECISION_LOG",
        theme: {
            mx: 0,
            my: 0,
            px: 2,
            py: 2,
            type: "default",
            background: "rgba(255,255,255,1)",
            hoverColor: "rgba(0,0,0,1)",
            hoverBackgroundColor: "rgba(255,255,255,0)",
        },
        created_at: "2023-09-22T03:24:22.000000Z",
        updated_at: "2023-09-22T03:24:22.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6d6fa9fb-3b90-4af0-99fe-f29f2f647fa8",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Decision log"],
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "f38f16dc-218e-4856-bc1b-a5b5a77f68ef",
        content: null,
        theme: {
            stroke: "#00ABAB",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-23T16:58:19.000000Z",
        updated_at: "2023-09-23T16:58:19.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "58ca3fde-8fc0-45f7-88e8-e33f61c18e27",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "495c2931-9143-479e-80dd-d038d1f103c7",
                created_at: "2023-09-23T17:24:15.000000Z",
                updated_at: "2023-09-23T17:24:15.000000Z",
                content_block_id: "f38f16dc-218e-4856-bc1b-a5b5a77f68ef",
                model_variable_id: "22f46430-288c-4801-ab08-81da999f943f",
                weight: 3,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        copied_from_id: null,
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "acae0da7-18d7-47d6-ab12-b0fa1d11e088",
        content: "Model Value Display",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            opacity: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T19:15:00.000000Z",
        updated_at: "2023-09-22T07:41:28.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "8e09be6e-8d03-4457-a45a-7731aa10f583",
        title: "Model Value Display",
        weight: 0,
        content_block_type: ContentBlockType["Model Value Display"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "ac1ab327-b601-420f-8b31-adc7342a7637",
                created_at: "2023-09-20T19:15:27.000000Z",
                updated_at: "2023-09-20T19:15:27.000000Z",
                content_block_id: "acae0da7-18d7-47d6-ab12-b0fa1d11e088",
                model_variable_id: "a5c9475e-b844-4644-bad8-50774f23fcb3",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "ac1ab327-b601-420f-8b31-adc7342a7637",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "c012d124-a92b-4fb9-8308-c4cbd22e92d0",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:09:24.000000Z",
        updated_at: "2023-09-20T19:11:20.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "807bb16d-ef54-4db6-9b84-74738424ff75",
        title: "Conversion Rate: Lead to Exam",
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "aee4f940-2aa1-4900-be1d-d5d253697e42",
                created_at: "2023-09-22T02:15:50.000000Z",
                updated_at: "2023-09-22T02:15:50.000000Z",
                content_block_id: "c012d124-a92b-4fb9-8308-c4cbd22e92d0",
                model_variable_id: "8cebcc07-1edc-41d8-98a3-3b054a6b3a9c",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "f17a6fce-ffc5-4454-a675-b86fdcba29e7",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:06.000000Z",
        updated_at: "2023-09-23T17:07:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "c3cec805-0743-48b0-be7f-b3046b885427",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Table Head"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "a92642f2-6c40-44bb-a6bd-5b30a2e08750",
        content: "Model Value Display",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            opacity: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-22T12:42:20.000000Z",
        updated_at: "2023-09-22T12:42:36.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "38b17617-9f7a-44c8-8e66-390276b548e7",
        title: "Model Value Display",
        weight: 0,
        content_block_type: ContentBlockType["Model Value Display"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "75fb1a23-ab44-4b0a-92d6-9af894773b46",
                created_at: "2023-09-22T12:42:49.000000Z",
                updated_at: "2023-09-22T12:42:49.000000Z",
                content_block_id: "a92642f2-6c40-44bb-a6bd-5b30a2e08750",
                model_variable_id: "5ac35e1e-ac7e-40de-8796-60e0b7075565",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "d444aa5c-8db5-440c-82eb-5a94bfd88b02",
        content: "RICH_TEXT",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            color: "#000000",
            opacity: 1,
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T19:03:52.000000Z",
        updated_at: "2023-09-22T15:25:14.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "60c900c2-2b49-43e9-9e8d-44d7bf78bd2c",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Rich Text"],
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: {
            type: "doc",
            content: [
                {
                    type: "paragraph",
                    attrs: {
                        lineHeight: "100%",
                        indent: 0,
                        textAlign: "left",
                    },
                    content: [
                        {
                            type: "text",
                            marks: [
                                {
                                    type: "textStyle",
                                    attrs: {
                                        color: null,
                                        lineHeight: "100%",
                                        fontFamily: null,
                                        indent: 0,
                                        fontSize: "30",
                                    },
                                },
                            ],
                            text: "Financial Performance",
                        },
                    ],
                },
            ],
        },
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "8578592c-23a9-4407-9741-a7b6dca3e483",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:09:24.000000Z",
        updated_at: "2023-09-20T19:09:24.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "78a465dc-724d-4751-ad5c-cbbfdc9560b3",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "4aa457d4-e7c3-4c61-b23e-0052deb693cb",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:03.000000Z",
        updated_at: "2023-09-20T19:05:44.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Net Production",
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "4aa457d4-e7c3-4c61-b23e-0052deb693cb",
                model_variable_id: "26bceddf-6271-427a-9ca9-7abdf1312041",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "cef7a762-a65d-41aa-8f19-b7532b6a1a4f",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "c10a7a06-a28a-41f9-9636-342cd2ded98d",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:03.000000Z",
        updated_at: "2023-09-20T19:05:03.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "aac40a83-693f-47e8-abbf-159dba131a07",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "860bf6c5-90dd-4862-85ec-d56ec4498568",
        content: "Model Value Display",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            opacity: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-22T12:41:34.000000Z",
        updated_at: "2023-09-22T12:42:00.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ad018e75-eaf2-4e91-b902-f28886c37f75",
        title: "Model Value Display",
        weight: 0,
        content_block_type: ContentBlockType["Model Value Display"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "4bf27ce2-01ba-4a6a-bf1a-e59fb8d2cc75",
                created_at: "2023-09-22T12:42:08.000000Z",
                updated_at: "2023-09-22T12:42:08.000000Z",
                content_block_id: "860bf6c5-90dd-4862-85ec-d56ec4498568",
                model_variable_id: "26bceddf-6271-427a-9ca9-7abdf1312041",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "4bf27ce2-01ba-4a6a-bf1a-e59fb8d2cc75",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "939971fe-eb99-4807-8f2d-59217943401b",
        content: "RICH_TEXT",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            color: "#000000",
            opacity: 1,
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T19:08:47.000000Z",
        updated_at: "2023-09-22T15:25:02.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "9ea0a7ea-8bc7-4f10-9d56-eeb5a1fb3816",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Rich Text"],
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: {
            type: "doc",
            content: [
                {
                    type: "paragraph",
                    attrs: {
                        lineHeight: "100%",
                        indent: 0,
                        textAlign: "left",
                    },
                    content: [
                        {
                            type: "text",
                            marks: [
                                {
                                    type: "textStyle",
                                    attrs: {
                                        color: null,
                                        lineHeight: "100%",
                                        fontFamily: null,
                                        indent: 0,
                                        fontSize: "30",
                                    },
                                },
                            ],
                            text: "Operational Performance",
                        },
                    ],
                },
            ],
        },
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "db1a5030-f9b1-465e-8deb-e1cfa0330942",
        content: null,
        theme: {
            fill: "#6FC2B4",
            stroke: "red",
            padAngle: 3,
            labelProps: {
                x: 0,
                y: 0,
            },
            innerRadius: 30,
            strokeWidth: 3,
            cornerRadius: 5,
            interpolation: "linear",
        },
        created_at: "2023-09-22T02:20:08.000000Z",
        updated_at: "2023-09-22T12:44:08.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ca7bd0e3-c7fe-449f-a4f7-b83af4e60b00",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.DOUGHNUT_SERIES,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "ecdba3ce-81d1-4512-ae1b-10ad69c101eb",
                created_at: "2023-09-22T12:44:08.000000Z",
                updated_at: "2023-09-22T12:44:08.000000Z",
                content_block_id: "db1a5030-f9b1-465e-8deb-e1cfa0330942",
                model_variable_id: "82846bd4-f029-43d9-8e79-63141f6d98bb",
                weight: 1,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "ecdba3ce-81d1-4512-ae1b-10ad69c101eb",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "b1d09d1d-9a2d-41d3-82c1-75cc63b77817",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:06.000000Z",
        updated_at: "2023-09-23T17:07:48.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Total Staff Members",
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "3470018f-7907-4445-baf1-18a3be52770a",
                created_at: "2023-09-23T17:36:12.000000Z",
                updated_at: "2023-09-23T17:36:12.000000Z",
                content_block_id: "b1d09d1d-9a2d-41d3-82c1-75cc63b77817",
                model_variable_id: "03a7b516-569f-4dc6-93ac-1015dffce640",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3470018f-7907-4445-baf1-18a3be52770a",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3470018f-7907-4445-baf1-18a3be52770a",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "683ab741-8d4a-44ab-8655-13a5ece4b88b",
        content: null,
        theme: {
            fill: "#046A38",
            stroke: "red",
            padAngle: 3,
            labelProps: {
                x: 0,
                y: 0,
            },
            innerRadius: 30,
            strokeWidth: 3,
            cornerRadius: 5,
            interpolation: "linear",
        },
        created_at: "2023-09-22T02:20:08.000000Z",
        updated_at: "2023-09-22T12:44:08.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ca7bd0e3-c7fe-449f-a4f7-b83af4e60b00",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.DOUGHNUT_SERIES,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "16435d16-19d9-44db-8d61-708e7d9c3949",
                created_at: "2023-09-22T12:44:08.000000Z",
                updated_at: "2023-09-22T12:44:08.000000Z",
                content_block_id: "683ab741-8d4a-44ab-8655-13a5ece4b88b",
                model_variable_id: "e51dd7f6-b14a-447c-a9fe-b021a7110cfb",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "16435d16-19d9-44db-8d61-708e7d9c3949",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "40b0bddb-3bd2-4cef-9563-57a79f81be84",
        content: "IMAGE",
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            color: "#000000",
            width: 0.5,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            borderColor: "#00000000",
            borderStyle: "solid",
            borderWidth: 1,
            alignContent: "center",
            borderRadius: 0,
            justifyItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#ffffff00",
        },
        created_at: "2023-09-20T17:28:44.000000Z",
        updated_at: "2023-09-20T17:28:44.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "f86e8902-423b-4d10-8ff6-b544ace823c1",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Image,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/qnX2lvm3VmNrR5Thqp3T1y6aBD7phqajzGOnGlg2.svg",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,

        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "a97e734f-3ff8-474d-9eb4-ab5faacb33fc",
        content: null,
        theme: {
            stroke: "#6FC2B4",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-22T02:18:39.000000Z",
        updated_at: "2023-09-22T02:23:58.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "fe6e1494-3244-4966-8455-5ff40b684b44",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "4c4d0286-68e9-4b94-af97-b20fe121f78d",
                created_at: "2023-09-22T02:23:58.000000Z",
                updated_at: "2023-09-22T02:23:58.000000Z",
                content_block_id: "a97e734f-3ff8-474d-9eb4-ab5faacb33fc",
                model_variable_id: "82846bd4-f029-43d9-8e79-63141f6d98bb",
                weight: 1,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "4d7b0346-8db5-4d16-a55a-bdb95acdb5eb",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:06.000000Z",
        updated_at: "2023-09-23T17:07:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "f17a6fce-ffc5-4454-a675-b86fdcba29e7",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "745de219-3ef0-4755-b407-d7f6f6f14d65",
        content: "BOX",
        theme: {
            mb: 3,
            ml: 0,
            mr: 0,
            mt: 3,
            pb: 3,
            pl: 0,
            pr: 0,
            pt: 3,
            color: "#000000",
            width: "100%",
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "stretch",
            borderColor: "#000000",
            borderStyle: "solid",
            borderWidth: 0,
            alignContent: "normal",
            borderRadius: 0,
            justifyItems: "stretch",
            flexDirection: "row",
            justifyContent: "normal",
            backgroundColor: "rgba(0,0,0,0)",
        },
        created_at: "2023-09-22T02:26:57.000000Z",
        updated_at: "2023-09-22T02:28:13.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "1238b302-b15d-4c24-ae8b-fcb510abdbf7",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "072761bf-b116-4652-8a4b-9e82c325e7e1",
        content: null,
        theme: {
            stroke: "#046A38",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-22T02:18:39.000000Z",
        updated_at: "2023-09-22T02:18:39.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "fe6e1494-3244-4966-8455-5ff40b684b44",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "568e5f82-2967-48cd-8bbd-0f654a965712",
                created_at: "2023-09-22T02:23:58.000000Z",
                updated_at: "2023-09-22T02:23:58.000000Z",
                content_block_id: "072761bf-b116-4652-8a4b-9e82c325e7e1",
                model_variable_id: "e51dd7f6-b14a-447c-a9fe-b021a7110cfb",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "568e5f82-2967-48cd-8bbd-0f654a965712",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "a933d739-e3e6-4500-a9f7-911ee3d49926",
        content: null,
        theme: {
            width: 0.33,
            height: "fit-content",
            margin: "5px",
            display: "flex",
            palette: "light",
            isOption: false,
            fillStyle: "outline",
            borderRadius: "12px",
            hasNoPadding: false,
            imagePlacement: "left",
        },
        created_at: "2023-09-22T12:41:34.000000Z",
        updated_at: "2023-09-22T12:41:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        title: "Data Display Card",
        weight: 0,
        content_block_type: ContentBlockType.Card,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,

        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "58ca3fde-8fc0-45f7-88e8-e33f61c18e27",
        content: null,
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            width: 0.5,
            borderColor: "#E5E7EB",
            borderWidth: 0,
            backgroundColor: "transparent",
        },
        created_at: "2023-09-23T16:58:19.000000Z",
        updated_at: "2023-09-23T17:37:41.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "99bb0e47-967a-498f-9d40-66d6b9b654ee",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Chart Container"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "fde32ce6-5933-42b3-bd9d-a283790bf7a0",
        content: "RICH_TEXT",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            color: "#000000",
            opacity: 1,
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-23T17:20:13.000000Z",
        updated_at: "2023-09-23T17:20:33.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "459ba85a-a177-4a12-84b4-146dba83d8a0",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Rich Text"],
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: {
            type: "doc",
            content: [
                {
                    type: "paragraph",
                    attrs: {
                        lineHeight: "100%",
                        indent: 0,
                        textAlign: "left",
                    },
                    content: [
                        {
                            type: "text",
                            marks: [
                                {
                                    type: "textStyle",
                                    attrs: {
                                        color: null,
                                        lineHeight: "100%",
                                        fontFamily: null,
                                        indent: 0,
                                        fontSize: "30",
                                    },
                                },
                            ],
                            text: "Practice Structure",
                        },
                    ],
                },
            ],
        },
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "441fa968-ca2c-4abd-b24b-cfe2220254e7",
        content: "Model Value Display",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            opacity: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T17:35:25.000000Z",
        updated_at: "2023-09-22T07:41:20.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6f1dc5ad-6510-4934-83e3-c8e2c75529a0",
        title: "Model Value Display",
        weight: 0,
        content_block_type: ContentBlockType["Model Value Display"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "3155278b-4d4a-4af2-ab47-f7a3e0027452",
                created_at: "2023-09-20T19:14:49.000000Z",
                updated_at: "2023-09-20T19:14:49.000000Z",
                content_block_id: "441fa968-ca2c-4abd-b24b-cfe2220254e7",
                model_variable_id: "c4947299-0139-4933-bc5e-6c890fbea9ef",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3155278b-4d4a-4af2-ab47-f7a3e0027452",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "c6c9c766-cce9-4004-9163-f29d120d695a",
        content: null,
        theme: {
            fill: "#0097A9",
            stroke: "red",
            padAngle: 2,
            labelProps: {
                x: 0,
                y: 0,
            },
            innerRadius: 40,
            strokeWidth: 3,
            cornerRadius: 2,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:05:21.000000Z",
        updated_at: "2023-09-20T18:07:16.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "f6ed9e5e-ce32-4550-939e-cd9469af6152",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.DOUGHNUT_SERIES,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "c9aba753-7a4d-483e-bf0c-df2aff65e70e",
                created_at: "2023-09-20T18:07:16.000000Z",
                updated_at: "2023-09-20T18:07:16.000000Z",
                content_block_id: "c6c9c766-cce9-4004-9163-f29d120d695a",
                model_variable_id: "2f07b629-6f78-473a-90c3-cd53b3bf78ce",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "c9aba753-7a4d-483e-bf0c-df2aff65e70e",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "dd9845a5-4cbe-492a-9e13-e0d7ac5e6313",
        content: null,
        theme: {
            fill: "#005587",
            stroke: "red",
            padAngle: 2,
            labelProps: {
                x: 0,
                y: 0,
            },
            innerRadius: 40,
            strokeWidth: 3,
            cornerRadius: 2,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:05:21.000000Z",
        updated_at: "2023-09-20T18:07:16.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "f6ed9e5e-ce32-4550-939e-cd9469af6152",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.DOUGHNUT_SERIES,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "43ce823d-9ab1-4789-8aca-a976d5283daf",
                created_at: "2023-09-20T18:07:16.000000Z",
                updated_at: "2023-09-20T18:07:16.000000Z",
                content_block_id: "dd9845a5-4cbe-492a-9e13-e0d7ac5e6313",
                model_variable_id: "c478ec8a-4f48-4d5e-81be-3a8d7061b884",
                weight: 1,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "43ce823d-9ab1-4789-8aca-a976d5283daf",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "81fe2e2e-d05e-445e-9536-212a5cc5c0c7",
        content: null,
        theme: {
            stroke: "#0076A8",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-22T02:15:27.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "292a3974-1b8d-49ca-a648-c41aff431bd0",
                created_at: "2023-09-22T02:15:27.000000Z",
                updated_at: "2023-09-22T02:15:27.000000Z",
                content_block_id: "81fe2e2e-d05e-445e-9536-212a5cc5c0c7",
                model_variable_id: "26bceddf-6271-427a-9ca9-7abdf1312041",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "292a3974-1b8d-49ca-a648-c41aff431bd0",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "eec36165-cf92-4db6-a0c3-bc1c530328e9",
        content: null,
        theme: {
            stroke: "#009A44",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-20T18:51:42.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                created_at: "2023-09-22T02:15:27.000000Z",
                updated_at: "2023-09-22T02:15:27.000000Z",
                content_block_id: "eec36165-cf92-4db6-a0c3-bc1c530328e9",
                model_variable_id: "581a1a44-a58d-43bb-a08f-1fad19239634",
                weight: 2,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "78a62278-f7bc-4e75-9dc9-585ae83bc141",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "2024dcb6-0e9e-47df-936a-fa6f5c3e0173",
        content: null,
        theme: {
            stroke: "#0097A9",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-20T18:51:42.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "61324613-7997-433e-8131-4ad39ac2bd7f",
                created_at: "2023-09-22T02:15:27.000000Z",
                updated_at: "2023-09-22T02:15:27.000000Z",
                content_block_id: "2024dcb6-0e9e-47df-936a-fa6f5c3e0173",
                model_variable_id: "5f1545ec-c985-4da5-b55c-a46f5c19bd90",
                weight: 3,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "61324613-7997-433e-8131-4ad39ac2bd7f",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "53b07071-28c5-4b2f-be96-e8e1e8aa7c83",
        content: null,
        theme: {
            stroke: "#ED8B00",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-22T02:15:27.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                created_at: "2023-09-22T02:15:27.000000Z",
                updated_at: "2023-09-22T02:15:27.000000Z",
                content_block_id: "53b07071-28c5-4b2f-be96-e8e1e8aa7c83",
                model_variable_id: "9dbb9f16-334e-4ef6-aca2-fc0320f9e85e",
                weight: 4,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "e7cb7cfb-3cb2-4a17-bfb8-6b40f06796cd",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "ad018e75-eaf2-4e91-b902-f28886c37f75",
        content: null,
        theme: {
            pb: 5,
            pl: 5,
            pr: 5,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        created_at: "2023-09-22T12:41:34.000000Z",
        updated_at: "2023-09-22T12:41:54.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a933d739-e3e6-4500-a9f7-911ee3d49926",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "aac40a83-693f-47e8-abbf-159dba131a07",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:03.000000Z",
        updated_at: "2023-09-20T19:05:03.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "355cd7a3-ff4b-44dd-a313-3efce166055c",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Table Head"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "f86e8902-423b-4d10-8ff6-b544ace823c1",
        content: "SECTION",
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-20T17:28:44.000000Z",
        updated_at: "2023-09-20T17:28:44.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Logo",
        weight: 0,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    // {
    //     id: "78a465dc-724d-4751-ad5c-cbbfdc9560b3",
    //     content: null,
    //     theme: [],
    //     created_at: "2023-09-20T19:09:24.000000Z",
    //     updated_at: "2023-09-20T19:09:24.000000Z",
    //     deleted_at: null,
    //     copied_from_id: null,
    //     round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
    //     parent_content_block_id: "4fb4e042-157f-4fb4-a86e-ec652e2ca394",
    //     title: null,
    //     weight: 0,
    //     content_block_type: ContentBlockType["Table Head"],
    //     route: null,
    //     image_url: null,
    //     rich_text: null,
    //     video_urls: null,
    //     behaviors: null,
    //     chart_data: null,
    //     table_data: null,
    //     prompt: null,
    //     contentBlockModelDataSources: [],
    //     displayRule: null,
    // },
    {
        id: "01f8728f-f23c-40c8-8994-9a9662bba1f3",
        content: null,
        theme: {
            stroke: "#1A56DB",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-23T17:23:24.000000Z",
        updated_at: "2023-09-23T17:23:24.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "58ca3fde-8fc0-45f7-88e8-e33f61c18e27",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "38f5836f-d320-4284-818e-750f034e70ba",
                created_at: "2023-09-23T17:24:15.000000Z",
                updated_at: "2023-09-23T17:24:15.000000Z",
                content_block_id: "01f8728f-f23c-40c8-8994-9a9662bba1f3",
                model_variable_id: "d94135dc-309f-4eb4-9d84-5be2ecb72d2b",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "38f5836f-d320-4284-818e-750f034e70ba",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "8e09be6e-8d03-4457-a45a-7731aa10f583",
        content: null,
        theme: {
            pb: 5,
            pl: 5,
            pr: 5,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        created_at: "2023-09-20T19:15:00.000000Z",
        updated_at: "2023-09-22T07:41:03.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "5a9847a0-6377-4104-859f-fd35075b8095",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "28f613b0-fae6-43c8-bb0c-9f6e09b53859",
        content: null,
        theme: {
            pb: 5,
            pl: 5,
            pr: 5,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        created_at: "2023-09-20T19:15:51.000000Z",
        updated_at: "2023-09-22T07:41:12.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "8d20a195-0db9-4bc2-b14d-bbf39d4316bc",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "6f1dc5ad-6510-4934-83e3-c8e2c75529a0",
        content: null,
        theme: {
            pb: 5,
            pl: 5,
            pr: 5,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        created_at: "2023-09-20T17:35:25.000000Z",
        updated_at: "2023-09-22T07:40:56.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "61a36141-909c-436e-9134-7ef3f873d4ca",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "c71a2c59-f6c0-4970-acb9-d3d25e1cb1e0",
        content: null,
        theme: {
            stroke: "#C4D600",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-22T02:15:27.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "f1eb09aa-f683-4631-88aa-47b1a8fdf229",
                created_at: "2023-09-22T02:15:27.000000Z",
                updated_at: "2023-09-22T02:15:27.000000Z",
                content_block_id: "c71a2c59-f6c0-4970-acb9-d3d25e1cb1e0",
                model_variable_id: "b489ab3b-5033-487c-99f4-9e47520a45cf",
                weight: 1,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "f1eb09aa-f683-4631-88aa-47b1a8fdf229",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "f1eb09aa-f683-4631-88aa-47b1a8fdf229",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "40116638-e2da-4798-b503-3100d2fa9ac1",
        content: "Model Value Display",
        theme: {
            mb: 4,
            ml: 4,
            mr: 4,
            mt: 4,
            pb: 5,
            pl: 3,
            pr: 3,
            pt: 5,
            opacity: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T19:15:51.000000Z",
        updated_at: "2023-09-22T07:41:35.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "28f613b0-fae6-43c8-bb0c-9f6e09b53859",
        title: "Model Value Display",
        weight: 0,
        content_block_type: ContentBlockType["Model Value Display"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "c59c621e-130f-4e7e-9925-5072d709374f",
                created_at: "2023-09-20T19:16:11.000000Z",
                updated_at: "2023-09-20T19:16:11.000000Z",
                content_block_id: "40116638-e2da-4798-b503-3100d2fa9ac1",
                model_variable_id: "9e8b436f-dfaa-4d71-825c-a2a7deae672d",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "c59c621e-130f-4e7e-9925-5072d709374f",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "38b17617-9f7a-44c8-8e66-390276b548e7",
        content: null,
        theme: {
            pb: 5,
            pl: 5,
            pr: 5,
            pt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        created_at: "2023-09-22T12:42:20.000000Z",
        updated_at: "2023-09-22T12:42:30.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "1f3fec90-9748-46b4-bc86-bffd88938056",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType.Box,
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "9c3731b7-830f-4c14-bd1f-7cad6eee56eb",
        content: null,
        theme: {
            stroke: "#ED8B00",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-23T17:03:59.000000Z",
        updated_at: "2023-09-23T17:03:59.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "58ca3fde-8fc0-45f7-88e8-e33f61c18e27",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "bfb40f80-d5ee-4982-8546-e79983cd92c8",
                created_at: "2023-09-23T17:24:15.000000Z",
                updated_at: "2023-09-23T17:24:15.000000Z",
                content_block_id: "9c3731b7-830f-4c14-bd1f-7cad6eee56eb",
                model_variable_id: "03a7b516-569f-4dc6-93ac-1015dffce640",
                weight: 1,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "853c7e42-6b29-4a93-b3ab-b2aa6871e1c7",
        content: null,
        theme: {
            stroke: "#009A44",
            labelProps: {
                x: 0,
                y: 0,
            },
            strokeWidth: 3,
            interpolation: "linear",
        },
        created_at: "2023-09-23T16:58:19.000000Z",
        updated_at: "2023-09-23T16:58:19.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "58ca3fde-8fc0-45f7-88e8-e33f61c18e27",
        title: null,
        weight: 0,
        content_block_type: ContentBlockType["Line Series"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "3c8ee474-216b-4c48-8435-4282436536fa",
                created_at: "2023-09-23T17:24:15.000000Z",
                updated_at: "2023-09-23T17:24:15.000000Z",
                content_block_id: "853c7e42-6b29-4a93-b3ab-b2aa6871e1c7",
                model_variable_id: "adcf595d-e533-4dbd-8504-241d95fa501c",
                weight: 2,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "3c8ee474-216b-4c48-8435-4282436536fa",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "e75d53b9-f80b-4f27-9413-f83d0836bae0",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:28.000000Z",
        updated_at: "2023-09-23T17:10:45.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Practice Chair Count",
        weight: 1,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "4a937248-4973-41b9-911e-1a72a0b241d7",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "e75d53b9-f80b-4f27-9413-f83d0836bae0",
                model_variable_id: "adcf595d-e533-4dbd-8504-241d95fa501c",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "4a937248-4973-41b9-911e-1a72a0b241d7",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "4a937248-4973-41b9-911e-1a72a0b241d7",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-20T17:31:51.000000Z",
        updated_at: "2023-09-22T12:48:23.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Metrics",
        weight: 1,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "d20a3401-e7d4-41db-b458-70f671247b7e",
        content: null,
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            width: 0.5,
            borderColor: "transparent",
            borderWidth: 0,
            backgroundColor: "transparent",
        },
        created_at: "2023-09-20T18:51:42.000000Z",
        updated_at: "2023-09-20T19:26:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "45b3a875-7b2d-4e14-bced-4b181cf2d24f",
        title: null,
        weight: 1,
        content_block_type: ContentBlockType["Chart Container"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "61a36141-909c-436e-9134-7ef3f873d4ca",
        content: "DATA_DISPLAY_CARD",
        theme: {
            width: 0.33,
            height: "fit-content",
            margin: "5px",
            display: "flex",
            palette: "light",
            isOption: false,
            fillStyle: "outline",
            borderRadius: "12px",
            hasNoPadding: false,
            imagePlacement: "left",
        },
        created_at: "2023-09-20T17:35:25.000000Z",
        updated_at: "2023-09-22T12:41:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        title: "Data Display Card",
        weight: 1,
        content_block_type: ContentBlockType.Card,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:03.000000Z",
        updated_at: "2023-09-20T19:05:03.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "355cd7a3-ff4b-44dd-a313-3efce166055c",
        title: null,
        weight: 1,
        content_block_type: ContentBlockType["Table Body"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    // {
    //     id: "807bb16d-ef54-4db6-9b84-74738424ff75",
    //     content: null,
    //     theme: [],
    //     created_at: "2023-09-20T19:09:24.000000Z",
    //     updated_at: "2023-09-20T19:09:24.000000Z",
    //     deleted_at: null,
    //     copied_from_id: null,
    //     round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
    //     parent_content_block_id: "4fb4e042-157f-4fb4-a86e-ec652e2ca394",
    //     title: null,
    //     weight: 1,
    //     content_block_type: ContentBlockType["Table Body"],
    //     route: null,
    //     image_url: null,
    //     rich_text: null,
    //     video_urls: null,
    //     behaviors: null,
    //     chart_data: null,
    //     table_data: null,
    //     prompt: null,
    //     contentBlockModelDataSources: [],
    //     displayRule: null,
    // },
    {
        id: "fe6e1494-3244-4966-8455-5ff40b684b44",
        content: null,
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            width: 0.6,
            borderColor: "transparent",
            borderWidth: 0,
            backgroundColor: "transparent",
        },
        created_at: "2023-09-22T02:18:39.000000Z",
        updated_at: "2023-09-22T02:26:57.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "745de219-3ef0-4755-b407-d7f6f6f14d65",
        title: null,
        weight: 1,
        content_block_type: ContentBlockType["Chart Container"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "ec03de55-98fa-444a-aa52-5788200af844",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:06.000000Z",
        updated_at: "2023-09-23T17:07:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "c3cec805-0743-48b0-be7f-b3046b885427",
        title: null,
        weight: 1,
        content_block_type: ContentBlockType["Table Body"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        content: null,
        theme: '"{\\"color\\":\\"#000000\\",\\"backgroundColor\\":\\"#ffffff\\",\\"pt\\":0,\\"pb\\":5,\\"px\\":0,\\"my\\":0,\\"mx\\":0}"',
        created_at: "2023-09-07T21:12:00.000000Z",
        updated_at: "2023-09-07T21:12:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: null,
        title: "Phase 4 Results",
        weight: 1,
        content_block_type: ContentBlockType.Page,
        route: "/phase-4-results",
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "form-id",
        content: null,
        theme: '"{\\"color\\":\\"#000000\\",\\"backgroundColor\\":\\"#ffffff\\",\\"pt\\":0,\\"pb\\":5,\\"px\\":0,\\"my\\":0,\\"mx\\":0}"',
        created_at: "2023-09-07T21:12:00.000000Z",
        updated_at: "2023-09-07T21:12:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Phase 4 Results",
        weight: 1,
        content_block_type: ContentBlockType.Form,
        route: "/phase-4-results",
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "question-id",
        content: null,
        theme: '"{\\"color\\":\\"#000000\\",\\"backgroundColor\\":\\"#ffffff\\",\\"pt\\":0,\\"pb\\":5,\\"px\\":0,\\"my\\":0,\\"mx\\":0}"',
        created_at: "2023-09-07T21:12:00.000000Z",
        updated_at: "2023-09-07T21:12:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "form-id",
        title: "Phase 4 Results",
        weight: 1,
        content_block_type: ContentBlockType.Question,
        route: "/phase-4-results",
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: {
            content: "What is the answer to this question?",
            options: [],
            content_block_id: "question-id",
            id: "prompt-id",
        } as Prompt,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "6e5c1b3f-90e5-41d2-9c43-0cec27d835f4",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:50.000000Z",
        updated_at: "2023-09-20T19:06:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Net Collections",
        weight: 1,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "5483ca45-fc80-4017-8980-4ece1d005283",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "6e5c1b3f-90e5-41d2-9c43-0cec27d835f4",
                model_variable_id: "5f1545ec-c985-4da5-b55c-a46f5c19bd90",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "5483ca45-fc80-4017-8980-4ece1d005283",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "95a63c33-d950-4bec-8cdc-b79e46290cad",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:09:27.000000Z",
        updated_at: "2023-09-20T19:11:20.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "807bb16d-ef54-4db6-9b84-74738424ff75",
        title: "Conversion Rate: Exam to Start",
        weight: 1,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "8707d7a2-f804-4d01-b5d0-8771a22af375",
                created_at: "2023-09-22T02:15:50.000000Z",
                updated_at: "2023-09-22T02:15:50.000000Z",
                content_block_id: "95a63c33-d950-4bec-8cdc-b79e46290cad",
                model_variable_id: "2fcd57ec-a3c0-4a9e-91a3-e3460cf341ca",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "8707d7a2-f804-4d01-b5d0-8771a22af375",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "87bbfdca-4de5-4363-baef-78ef95cfcd6e",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:05:57.000000Z",
        updated_at: "2023-09-20T19:06:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Total Expenses",
        weight: 2,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "87bbfdca-4de5-4363-baef-78ef95cfcd6e",
                model_variable_id: "9dbb9f16-334e-4ef6-aca2-fc0320f9e85e",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7523d5b5-72d7-4a07-87a3-611b2ebe47d2",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "f54c69fd-b893-42b1-a63e-51fdb79a9c66",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:07:53.000000Z",
        updated_at: "2023-09-23T17:10:45.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Exam Chair Count",
        weight: 2,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "4d481a98-8c6e-4575-91e4-8ad45490972f",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "f54c69fd-b893-42b1-a63e-51fdb79a9c66",
                model_variable_id: "22f46430-288c-4801-ab08-81da999f943f",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "4d481a98-8c6e-4575-91e4-8ad45490972f",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "4d481a98-8c6e-4575-91e4-8ad45490972f",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "45b3a875-7b2d-4e14-bced-4b181cf2d24f",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-20T19:25:40.000000Z",
        updated_at: "2023-09-22T14:24:08.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Charts",
        weight: 2,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,

        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "2ab9d349-e08f-4441-beef-5eada02384d9",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:09:30.000000Z",
        updated_at: "2023-09-20T19:11:20.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "807bb16d-ef54-4db6-9b84-74738424ff75",
        title: "Conversion Rate: Lead to Exam to Start",
        weight: 2,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "670264e3-358a-4362-ae2b-383ca8e98099",
                created_at: "2023-09-22T02:15:50.000000Z",
                updated_at: "2023-09-22T02:15:50.000000Z",
                content_block_id: "2ab9d349-e08f-4441-beef-5eada02384d9",
                model_variable_id: "c20c5715-3fa5-4227-b89a-74dccf9f6d55",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "670264e3-358a-4362-ae2b-383ca8e98099",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "ca7bd0e3-c7fe-449f-a4f7-b83af4e60b00",
        content: null,
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            width: 0.4,
            borderColor: "transparent",
            borderWidth: 0,
            backgroundColor: "transparent",
        },
        created_at: "2023-09-22T02:20:08.000000Z",
        updated_at: "2023-09-22T02:21:18.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "745de219-3ef0-4755-b407-d7f6f6f14d65",
        title: null,
        weight: 2,
        content_block_type: ContentBlockType["Chart Container"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "c3cec805-0743-48b0-be7f-b3046b885427",
        content: null,
        theme: {
            mx: 0,
            my: 2,
            px: 4,
            py: 4,
            color: "#000000",
            width: 0.5,
            isStriped: true,
            isBordered: true,
            borderColor: "#ffffff",
            borderRadius: 1,
            isSpreadsheet: true,
            backgroundColor: "#ED8B002b",
        },
        created_at: "2023-09-23T17:07:06.000000Z",
        updated_at: "2023-09-23T17:38:23.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "99bb0e47-967a-498f-9d40-66d6b9b654ee",
        title: null,
        weight: 2,
        content_block_type: ContentBlockType.Table,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "4fb4e042-157f-4fb4-a86e-ec652e2ca394",
        content: null,
        theme: {
            mx: 0,
            my: 2,
            px: 4,
            py: 5,
            color: "#000000",
            width: "100%",
            isStriped: true,
            isBordered: true,
            borderColor: "#ffffff",
            borderRadius: 1,
            isSpreadsheet: true,
            backgroundColor: "#046A382e",
        },
        created_at: "2023-09-20T19:09:24.000000Z",
        updated_at: "2023-09-23T17:16:01.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Operational Results Table",
        weight: 2,
        content_block_type: ContentBlockType.Table,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
        table_data: [
            [
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                lineHeight: "100%",
                                indent: 0,
                                textAlign: "left",
                                typographyType: "PARAGRAPH",
                                default: null,
                            },
                            content: [
                                {
                                    type: "text",
                                    text: "text goasdfes here asdf asdfasdf asdfasdf",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                lineHeight: "100%",
                                indent: 0,
                                textAlign: "left",
                                typographyType: "PARAGRAPH",
                                default: null,
                            },
                            content: [
                                {
                                    type: "text",
                                    marks: [
                                        { type: "bold" },
                                        {
                                            type: "textStyle",
                                            attrs: {
                                                color: "black99f0",
                                                lineHeight: "100%",
                                                fontFamily: null,
                                                indent: 0,
                                                fontSize: null,
                                            },
                                        },
                                    ],
                                    text: "text goes and again here",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
            ],
            [
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
            ],
            [
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
                {
                    type: "doc",
                    content: [
                        {
                            type: "typography",
                            attrs: {
                                typographyType: "PARAGRAPH",
                                textAlign: "left",
                            },
                            content: [{ type: "text", text: "text goes here" }],
                        },
                    ],
                },
            ],
        ],
    },
    // {
    //     id: "table_body",
    //     parent_content_block_id: "4fb4e042-157f-4fb4-a86e-ec652e2ca394",
    //     content_block_type: ContentBlockType["Table Body"],
    //     table_data: null,
    // },
    {
        id: "5a9847a0-6377-4104-859f-fd35075b8095",
        content: null,
        theme: {
            width: 0.33,
            height: "fit-content",
            margin: "5px",
            display: "flex",
            palette: "light",
            isOption: false,
            fillStyle: "outline",
            borderRadius: "12px",
            hasNoPadding: false,
            imagePlacement: "left",
        },
        created_at: "2023-09-20T19:15:00.000000Z",
        updated_at: "2023-09-22T12:41:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        title: "Data Display Card",
        weight: 2,
        content_block_type: ContentBlockType.Card,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "355cd7a3-ff4b-44dd-a313-3efce166055c",
        content: null,
        theme: {
            mx: 0,
            my: 2,
            px: 5,
            py: 5,
            color: "#4B5563",
            width: "100%",
            isStriped: true,
            isBordered: true,
            borderColor: "#ffffff",
            borderRadius: 1,
            isSpreadsheet: true,
            backgroundColor: "#00A3E024",
        },
        created_at: "2023-09-20T19:05:03.000000Z",
        updated_at: "2023-09-22T15:19:05.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "60c900c2-2b49-43e9-9e8d-44d7bf78bd2c",
        title: null,
        weight: 2,
        content_block_type: ContentBlockType.Table,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "8d20a195-0db9-4bc2-b14d-bbf39d4316bc",
        content: null,
        theme: {
            width: 0.33,
            height: "fit-content",
            margin: "5px",
            display: "flex",
            palette: "light",
            isOption: false,
            fillStyle: "outline",
            borderRadius: "12px",
            hasNoPadding: false,
            imagePlacement: "left",
        },
        created_at: "2023-09-20T19:15:51.000000Z",
        updated_at: "2023-09-22T12:41:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        title: "Data Display Card",
        weight: 3,
        content_block_type: ContentBlockType.Card,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "fed4c0b8-66d1-4ed9-807b-06b1b16446b4",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:06:01.000000Z",
        updated_at: "2023-09-20T19:06:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Net Income",
        weight: 3,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "df24d6aa-7762-4229-9722-dfaa715ebb01",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "fed4c0b8-66d1-4ed9-807b-06b1b16446b4",
                model_variable_id: "b489ab3b-5033-487c-99f4-9e47520a45cf",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "df24d6aa-7762-4229-9722-dfaa715ebb01",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "1238b302-b15d-4c24-ae8b-fcb510abdbf7",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            alignItems: "flex-start",
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-22T02:17:21.000000Z",
        updated_at: "2023-09-22T14:24:08.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Chart Time Comparison",
        weight: 3,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "4f80ecbe-e12c-430d-954a-efe404427f78",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:09:09.000000Z",
        updated_at: "2023-09-23T17:10:45.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Scanner Count",
        weight: 3,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "64318114-7f65-4189-8d67-2a05f8a0ab9d",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "4f80ecbe-e12c-430d-954a-efe404427f78",
                model_variable_id: "d94135dc-309f-4eb4-9d84-5be2ecb72d2b",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "64318114-7f65-4189-8d67-2a05f8a0ab9d",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "64318114-7f65-4189-8d67-2a05f8a0ab9d",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "f6ed9e5e-ce32-4550-939e-cd9469af6152",
        content: null,
        theme: {
            mb: 0,
            ml: 0,
            mr: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 0,
            width: 0.5,
            borderColor: "transparent",
            borderWidth: 0,
            backgroundColor: "transparent",
        },
        created_at: "2023-09-20T18:05:21.000000Z",
        updated_at: "2023-09-20T19:26:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "45b3a875-7b2d-4e14-bced-4b181cf2d24f",
        title: null,
        weight: 3,
        content_block_type: ContentBlockType["Chart Container"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "c6c24849-fda2-4093-bbad-f99d583b7534",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:09:37.000000Z",
        updated_at: "2023-09-20T19:11:20.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "807bb16d-ef54-4db6-9b84-74738424ff75",
        title: "Capacity Utilization (100 * total used chair time / total available clinical hours)",
        weight: 3,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                created_at: "2023-09-22T02:15:50.000000Z",
                updated_at: "2023-09-22T02:15:50.000000Z",
                content_block_id: "c6c24849-fda2-4093-bbad-f99d583b7534",
                model_variable_id: "2fa707b0-c250-4498-8080-ae6492fa0886",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "7bfbde0b-122d-4df2-a7b2-2d7e572f81a7",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "53a9a6d1-1d2a-43ce-8594-be2b3949f903",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:25:12.000000Z",
        updated_at: "2023-09-23T17:26:02.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Starts",
        weight: 4,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "32a27c5c-3c79-4883-82b0-15c4720e0926",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "53a9a6d1-1d2a-43ce-8594-be2b3949f903",
                model_variable_id: "9e8b436f-dfaa-4d71-825c-a2a7deae672d",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "32a27c5c-3c79-4883-82b0-15c4720e0926",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "32a27c5c-3c79-4883-82b0-15c4720e0926",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "1f3fec90-9748-46b4-bc86-bffd88938056",
        content: null,
        theme: {
            width: 0.33,
            height: "fit-content",
            margin: "5px",
            display: "flex",
            palette: "light",
            isOption: false,
            fillStyle: "outline",
            borderRadius: "12px",
            hasNoPadding: false,
            imagePlacement: "left",
        },
        created_at: "2023-09-22T12:42:20.000000Z",
        updated_at: "2023-09-22T12:42:25.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "a9eef4a3-e953-4931-a93d-5e239b9cff5a",
        title: "Data Display Card",
        weight: 4,
        content_block_type: ContentBlockType.Card,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "60c900c2-2b49-43e9-9e8d-44d7bf78bd2c",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#4B5563",
            stacking: "column",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffff",
        },
        created_at: "2023-09-20T17:30:40.000000Z",
        updated_at: "2023-09-23T16:56:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Finacial Results",
        weight: 4,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "5aa97d75-22e5-48ba-b59c-0d4979a1f472",
        content: null,
        theme: [],
        created_at: "2023-09-20T19:06:06.000000Z",
        updated_at: "2023-09-20T19:06:43.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Cash",
        weight: 4,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "5aa97d75-22e5-48ba-b59c-0d4979a1f472",
                model_variable_id: "581a1a44-a58d-43bb-a08f-1fad19239634",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b2d5faef-313b-49e3-9ed3-84d1304e36ba",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "4d27b97f-4d3a-4e7a-acb4-24493a32b96b",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:25:23.000000Z",
        updated_at: "2023-09-23T17:26:02.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Share of Chair (Aligner)",
        weight: 5,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "b0145705-0bb3-4880-a086-86334c662116",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "4d27b97f-4d3a-4e7a-acb4-24493a32b96b",
                model_variable_id: "75f8fb95-f456-478e-ad0e-a103c44b4d24",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b0145705-0bb3-4880-a086-86334c662116",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "b0145705-0bb3-4880-a086-86334c662116",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "9ea0a7ea-8bc7-4f10-9d56-eeb5a1fb3816",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "column",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-20T17:32:18.000000Z",
        updated_at: "2023-09-23T16:56:47.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: "Operational Results",
        weight: 5,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "ce908c1e-f7d1-4a53-8a74-c26b99a6da7e",
        content: null,
        theme: [],
        created_at: "2023-09-22T14:22:51.000000Z",
        updated_at: "2023-09-22T14:23:06.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "3fe5528f-e95e-48a8-9ebc-65bcedfd100f",
        title: "Cash Reserve ($K)",
        weight: 5,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "2baebd48-97b5-48f3-b97a-960224022d27",
                created_at: "2023-09-22T14:23:06.000000Z",
                updated_at: "2023-09-22T14:23:06.000000Z",
                content_block_id: "ce908c1e-f7d1-4a53-8a74-c26b99a6da7e",
                model_variable_id: "58d6ac6e-b859-4feb-a727-28807a993e32",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        time_index: 1,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:04:59.000000Z",
                        updated_at: "2023-09-07T20:04:59.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "ed25e2b0-69b4-451c-9b46-e2b01bc763ca",
                        },
                    },
                    {
                        id: "402c7af9-33fc-456e-a271-e480397b987e",
                        time_index: 2,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "402c7af9-33fc-456e-a271-e480397b987e",
                        },
                    },
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "2641a660-67a9-4959-9c04-07b19b4601dc",
                        time_index: 4,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:02.000000Z",
                        updated_at: "2023-09-07T20:05:02.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "2641a660-67a9-4959-9c04-07b19b4601dc",
                        },
                    },
                    {
                        id: "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        time_index: 5,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:03.000000Z",
                        updated_at: "2023-09-07T20:05:03.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "510588ef-9f7f-40e3-96d2-d085fbb66e89",
                        },
                    },
                    {
                        id: "1bb52222-8795-44a8-8eb8-907326f36618",
                        time_index: 6,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:04.000000Z",
                        updated_at: "2023-09-07T20:05:04.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "1bb52222-8795-44a8-8eb8-907326f36618",
                        },
                    },
                    {
                        id: "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        time_index: 7,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:05.000000Z",
                        updated_at: "2023-09-07T20:05:05.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "ee70ecda-7afa-4d6d-bffa-fd43d35b111e",
                        },
                    },
                    {
                        id: "0eab980b-7d8e-4908-845a-0571de97ca30",
                        time_index: 8,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:28.000000Z",
                        updated_at: "2023-09-07T20:05:28.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "0eab980b-7d8e-4908-845a-0571de97ca30",
                        },
                    },
                    {
                        id: "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        time_index: 9,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:30.000000Z",
                        updated_at: "2023-09-07T20:05:30.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "dd2b08a1-1352-4cf2-8ef0-b5d8bf5090af",
                        },
                    },
                    {
                        id: "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        time_index: 10,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:31.000000Z",
                        updated_at: "2023-09-07T20:05:31.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "bbc560df-7106-45e4-a2c3-ab8100833e08",
                        },
                    },
                    {
                        id: "577234b6-fb4b-4224-ae68-d596c839f754",
                        time_index: 11,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:48.000000Z",
                        updated_at: "2023-09-07T20:05:48.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "577234b6-fb4b-4224-ae68-d596c839f754",
                        },
                    },
                    {
                        id: "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        time_index: 12,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:50.000000Z",
                        updated_at: "2023-09-07T20:05:50.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "a95d72cd-b3cf-4e84-9e16-ad84b6f6eb49",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "2baebd48-97b5-48f3-b97a-960224022d27",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "333c8b1c-09c9-4a44-b476-4b97476852bd",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:25:30.000000Z",
        updated_at: "2023-09-23T17:26:02.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Value per Visit",
        weight: 6,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "d1e6d06e-2696-492d-96c6-182f870c73da",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "333c8b1c-09c9-4a44-b476-4b97476852bd",
                model_variable_id: "a5c9475e-b844-4644-bad8-50774f23fcb3",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "d1e6d06e-2696-492d-96c6-182f870c73da",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "d1e6d06e-2696-492d-96c6-182f870c73da",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "459ba85a-a177-4a12-84b4-146dba83d8a0",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 0,
            pl: 0,
            pr: 0,
            pt: 6,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-23T17:20:01.000000Z",
        updated_at: "2023-09-23T17:20:40.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: null,
        weight: 6,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    {
        id: "7a533095-41df-4503-b07e-6e516a57d9a6",
        content: null,
        theme: [],
        created_at: "2023-09-23T17:35:12.000000Z",
        updated_at: "2023-09-23T17:36:13.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "ec03de55-98fa-444a-aa52-5788200af844",
        title: "Net Production",
        weight: 7,
        content_block_type: ContentBlockType["Table Row"],
        route: null,
        image_url: null,
        rich_text: null,
        video_urls: null,
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [
            {
                id: "88c2b6f6-2b8a-423c-983a-b878f82fe45d",
                created_at: "2023-09-23T17:36:13.000000Z",
                updated_at: "2023-09-23T17:36:13.000000Z",
                content_block_id: "7a533095-41df-4503-b07e-6e516a57d9a6",
                model_variable_id: "26bceddf-6271-427a-9ca9-7abdf1312041",
                weight: 0,
                deleted_at: null,
                label: null,
                timeHorizons: [
                    {
                        id: "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        time_index: 3,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:05:01.000000Z",
                        updated_at: "2023-09-07T20:05:01.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "88c2b6f6-2b8a-423c-983a-b878f82fe45d",
                            time_horizon_id:
                                "47b9d06e-79b1-4475-b1d9-cfc007a591a0",
                        },
                    },
                    {
                        id: "9a8cc52f-f811-4919-8f75-13972345f643",
                        time_index: 13,
                        unit: null,
                        simulation_id: "d52d4511-cecb-406e-9e93-fbb6ec4c2121",
                        created_at: "2023-09-07T20:21:27.000000Z",
                        updated_at: "2023-09-07T20:21:27.000000Z",
                        deleted_at: null,
                        copied_from_id: null,
                        pivot: {
                            content_block_model_data_source_id:
                                "88c2b6f6-2b8a-423c-983a-b878f82fe45d",
                            time_horizon_id:
                                "9a8cc52f-f811-4919-8f75-13972345f643",
                        },
                    },
                ],
            },
        ],
        displayRule: null,
    },
    {
        id: "99bb0e47-967a-498f-9d40-66d6b9b654ee",
        content: null,
        theme: {
            mb: 0,
            mt: 0,
            pb: 6,
            pl: 0,
            pr: 0,
            pt: 0,
            color: "#000000ff",
            stacking: "row",
            alignment: "center",
            fullBleed: false,
            backgroundColor: "#ffffffff",
        },
        created_at: "2023-09-23T16:56:47.000000Z",
        updated_at: "2023-09-23T17:20:53.000000Z",
        deleted_at: null,
        copied_from_id: null,
        round_id: "5228a36e-e0cd-4ae9-85c2-2ff86ad8dd11",
        parent_content_block_id: "6caa705f-0b96-4c88-b684-d4d28230d4f8",
        title: null,
        weight: 8,
        content_block_type: ContentBlockType.Section,
        route: null,
        image_url:
            "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
        rich_text: null,
        video_urls: [],
        behaviors: null,
        chart_data: null,
        table_data: null,
        prompt: null,
        contentBlockModelDataSources: [],
        displayRule: null,
    },
    // {
    //     "id": "4666041d-a103-40c6-ac2b-0058bc3fb318",
    //     "content": null,
    //     "theme": {
    //       "mx": 0,
    //       "my": 2,
    //       "px": 4,
    //       "py": 3,
    //       "color": "#4B5563",
    //       "width": 1,
    //       "isStriped": false,
    //       "isBordered": true,
    //       "borderColor": "#E5E7EB",
    //       "borderRadius": 1,
    //       "isSpreadsheet": false,
    //       "backgroundColor": "#E5E7EB"
    //     },
    //     "created_at": "2023-10-20T17:10:53.000000Z",
    //     "updated_at": "2023-10-20T17:57:42.000000Z",
    //     "deleted_at": null,
    //     "copied_from_id": null,
    //     "round_id": "02de63fe-8e77-4206-9932-8594c816a339",
    //     "parent_content_block_id": "99bb0e47-967a-498f-9d40-66d6b9b654ee",
    //     "title": null,
    //     "weight": 0,
    //     "content_block_type": ContentBlockType.Table,
    //     "route": null,
    //     "image_url": null,
    //     "rich_text": {
    //       "type": "doc",
    //       "content": [
    //         {
    //           "type": "paragraph",
    //           "attrs": {
    //             "lineHeight": "100%",
    //             "indent": 0,
    //             "textAlign": "left"
    //           },
    //           "content": [
    //             {
    //               "type": "text",
    //               "text": "updating thing again and once more"
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     "video_urls": null,
    //     "behaviors": null,
    //     "chart_data": null,
    //     "table_data": [
    //       [
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 1 Cell 1"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 1 Cell 2"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 1 Cell 3"
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ],
    //       [
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 2 Cell 1"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 2 Cell 2"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 2 Cell 3"
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ],
    //       [
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 3 Cell 1"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 3 Cell 2"
    //                 }
    //               ]
    //             }
    //           ]
    //         },
    //         {
    //           "type": "doc",
    //           "content": [
    //             {
    //               "type": "typography",
    //               "attrs": {
    //                 "typographyType": "PARAGRAPH",
    //                 "textAlign": "left"
    //               },
    //               "content": [
    //                 {
    //                   "type": "text",
    //                   "text": "Row 3 Cell 3"
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     ],
    //     "prompt": null,
    //     "contentBlockModelDataSources": [],
    //     "displayRule": null
    //   }
];
