import React from "react";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";

interface AddButtonProps {
    showAddButton: boolean;
    addContentBlock: () => void | Promise<void>;
    isDisabled?: boolean;
}

export const AddButton = (props: AddButtonProps) => {
    const { showAddButton, addContentBlock, isDisabled } = props;

    return showAddButton ? (
        <ContentBlockButtonContainer
            onClick={() => !isDisabled && addContentBlock()}
            hoverColor="bg-blue-600 hover:text-white"
        >
            <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </ContentBlockButtonContainer>
    ) : null;
};
