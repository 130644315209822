import React, { useCallback } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import { debounce } from "@/hooks";
import ButtonGroup from "../ButtonGroup";
import { cnToArray } from "@/util";

export function ChartContainerSpacingMenu({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    const chart = contentBlock.contentBlocks?.find(
        (block) =>
            block.content_block_type === ContentBlockType["Chart Container"],
    );

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 pb-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Border Radius{" "}
                    {(chart.theme?.tailwindClasses as string[])
                        ?.filter((className) => className.includes("rounded"))
                        .join(" ")}
                </label>
                <ButtonGroup
                    selectedValue={(chart.theme?.tailwindClasses as string[])
                        ?.filter((className) => className.includes("rounded"))
                        .join(" ")}
                    values={[
                        { label: "None", value: "rounded-0" },
                        { label: "S", value: "rounded-sm" },
                        { label: "M", value: "rounded-md" },
                        { label: "L", value: "rounded-lg" },
                    ]}
                    handleChange={(value) => {
                        const updatedChart = {
                            ...chart,
                            theme: {
                                ...chart.theme,
                                tailwindClasses: cnToArray([
                                    ...chart.theme?.tailwindClasses.filter(
                                        (className) =>
                                            !className.includes("rounded"),
                                    ),
                                    value as string,
                                ]),
                            },
                        }
                        setContentBlock(updatedChart);
                        update(updatedChart);
                    }}
                />
            </div>
        </div>
    );
}

export function ChartContainerColorStyling({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const chart = contentBlock.contentBlocks?.find(
        (child) =>
            child.content_block_type === ContentBlockType["Chart Container"],
    );

    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            console.log("deb");
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Chart Background Color
                </label>
                <ColorSelector
                    selectedColor={chart.theme?.backgroundColor || "white"}
                    selectColor={(backgroundColor, color) => {
                        update({
                            ...chart,
                            theme: {
                                ...chart.theme,
                                backgroundColor,
                                backgroundColorId: color.id,
                                tailwindClasses: [
                                    ...(chart.theme?.tailwindClasses || []),
                                    "rounded-md p-0",
                                ],
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
