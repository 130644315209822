import { useMutation } from "@tanstack/react-query";
import { reorderLayers } from "./reorderLayers";
import { useAtom } from "jotai";
import { designLayerPropsAtoms } from "../shared-props";

export function useReorderLayers() {
    const [_, setTopLevelContentBlockLayers] = useAtom(
        designLayerPropsAtoms.topLevelContentBlockLayers
    );
    return useMutation({
        mutationFn: ({
            page_id,
            destination_index,
        }: {
            page_id: string;
            destination_index: number;
        }) => reorderLayers({ page_id, destination_index }),
        onSuccess: (topLevelContentBlocks) => {
            setTopLevelContentBlockLayers(topLevelContentBlocks);
        },
    });
}
