import React from "react";
import { FormSmallLabel, CaptionSpan } from "../components";

export const ErrorDisplay = (props: { error: any }) => {
    const { error } = props;

    return error !== undefined && Object.keys(error).length !== 0 ? (
        <FormSmallLabel>
            <CaptionSpan>Invalid input</CaptionSpan>
        </FormSmallLabel>
    ) : null;
};
