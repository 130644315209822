import { useCallback, useEffect, useState } from "react";
import { AddSiblingBlockData } from "@/model-configs";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    setAddSiblingBlockData,
} from "@/redux-state/contentSelectorSlice";

import { useMovedContentBlock } from "@/modules/moved-content-block/useMovedContentBlock";
import { useAddContentBlockState } from "@/Pages/Admin/editor/content-block-buttons/useAddContentBlockState";

export const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    const { addContentBlockState } = useAddContentBlockState();
    const { movedContentBlock } = useMovedContentBlock();

    useEffect(() => {
        const updateMousePosition = (ev: MouseEvent) => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        if (
            !!addContentBlockState?.addableContentBlockMetaData ||
            !!movedContentBlock
        ) {
            window.addEventListener("mousemove", updateMousePosition);
        } else {
            window.removeEventListener("mousemove", updateMousePosition);
        }
        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, [addContentBlockState?.addableContentBlockMetaData, movedContentBlock]);

    return { mousePosition };
};

export const useAddSiblingBlockData = () => {
    const dispatch = useAppDispatch();

    const addSiblingBlockData = useAppSelector(
        (state) => state?.contentSelectorStore.addSiblingBlockData
    );

    const _setAddSiblingBlockData = useCallback(
        (addSiblingBlockData: AddSiblingBlockData | null) => {
            dispatch(setAddSiblingBlockData(addSiblingBlockData));
        },
        [dispatch]
    );

    return {
        addSiblingBlockData,
        setAddSiblingBlockData: _setAddSiblingBlockData,
    };
};
