import { sapienAxios } from "@/inertia-utils/hooks";
import { UserShape } from "@/models";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";

type Props = {
    user: UserShape;
};

export default function AllowAccess({ user }: Props) {

    const [isChecked, setIsChecked] = useState(false)

    const { data, isPending:loading } = useQuery({
        queryKey: ["user_permissions", { user_id: user.id }],
        queryFn: async () => {
            const { data } = await sapienAxios.get<
                { has_permission: boolean },
                "e-learning.permission.show"
            >("e-learning.permission.show", { user: user.id });
            setIsChecked(data.has_permission)
            return data;
        },
        initialData: { has_permission: false },
    });

    const {mutate, isPending:saving} = useMutation({
        mutationFn: async (allow: boolean) => {
            await sapienAxios.put("e-learning.permission.update", { user_id: user.id, allow});
            setIsChecked(allow)
        }
    })

    const isPending = loading || saving;
    return (
        <Switch.Group>
            <Switch
                defaultChecked={isChecked}
                onChange={(checked) => {
                    mutate(checked)
                    setIsChecked(checked)
                }}
                className={`${isPending && 'animate-pulse'} ${isChecked ? "bg-blue-600" : "bg-gray-500"} relative
                inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Enable e-learning</span>
                <span
                    className={`${isChecked ? "translate-x-6" : "translate-x-1"}
                    inline-block h-4 w-4 rounded-full bg-white transform`}
                />
            </Switch>
        </Switch.Group>
    );
}
