import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
    as?: "div" | "section";
    tailwindClasses?: string[];
    children: React.ReactNode;
};

export default function DefaultLessonContentBlockDisplay({
    as,
    contentBlock,
    children,
    tailwindClasses,
}: Props) {
    const Component = as || "div";

    return (
        <Component
            className={cn(tailwindClasses)}
            data-content-block-id={contentBlock.id}
        >
            {children}
        </Component>
    );
}
