import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    useSaveArchetype,
    useToggleArchetypePublishedAt,
} from "./useMutateArchetypes";
import { useAuthStore } from "@/hooks/store";
import {
    useInputDataObject,
    useModelBlockId,
    useArchetypes,
    useSetArchetypes,
    useArchetypeTimespanMap,
} from "../state";
import { CardWidgetWrapper } from "../shared";
import { ArchetypeDisplay } from "./ArchetypeDisplay";
import { useHandleSetInputDataObjectFull } from "../useInteractiveModule";
import { InteractiveModuleArchetype } from "../types";

const InteractiveSectionGuidesComponent = ({}: {}) => {
    const { isNotParticipant } = useAuthStore();
    const inputDataObject = useInputDataObject();
    const modelBlockId = useModelBlockId();
    const archetypes = useArchetypes();
    const setArchetypes = useSetArchetypes();
    const archetypeTimespanMap = useArchetypeTimespanMap();
    const { handleSetInputDataObjectFull } = useHandleSetInputDataObjectFull();
    const { mutateAsync: saveArchetype, isPending: isSaving } =
        useSaveArchetype();
    const { mutateAsync: toggleArchetypePublishedAt, isPending: isToggling } =
        useToggleArchetypePublishedAt();

    const listRef = useRef(null);
    const [scrollToBottom, setScrollToBottom] = useState(false);

    const handleSaveArchetype = useCallback(
        async (
            archetype: InteractiveModuleArchetype,
            simpleUpdate?: boolean,
        ) => {
            const savedArchetype = await saveArchetype({
                ...archetype,
                simple_update: simpleUpdate ?? false,
            });
            if (!!archetype.id) {
                setArchetypes(
                    archetypes.map((archetype) =>
                        archetype.id === savedArchetype.id
                            ? savedArchetype
                            : archetype,
                    ),
                );
            } else {
                setArchetypes([...archetypes, savedArchetype]);
                setScrollToBottom(true);
            }
        },
        [archetypes, setArchetypes],
    );

    const handleToggleArchetypePublishedAt = useCallback(
        async (archetype: InteractiveModuleArchetype) => {
            const savedArchetype = await toggleArchetypePublishedAt(archetype);
            setArchetypes(
                archetypes.map((archetype) =>
                    archetype.id === savedArchetype.id
                        ? savedArchetype
                        : archetype,
                ),
            );
        },
        [archetypes, setArchetypes],
    );

    useEffect(() => {
        if (scrollToBottom && listRef.current) {
            const lastItem = listRef.current.lastChild;
            lastItem.scrollIntoView({ behavior: "smooth" });
            setScrollToBottom(false);
        }
    }, [scrollToBottom]);

    return (
        <div className="flex w-full flex-col space-y-6">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Archetypes"}
                </h1>
                {isNotParticipant && (
                    <div className="flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md border border-slate-200 bg-transparent px-3
                                py-1.5 text-center text-sm font-normal text-slate-500 transition-all
                                hover:bg-slate-50 hover:text-slate-600 focus:outline-none focus:ring-0
                                dark:border-slate-800 dark:text-slate-500 dark:hover:bg-slate-800/25
                                dark:hover:text-slate-400"
                            disabled={isSaving || isToggling}
                            onClick={async () => {
                                await handleSaveArchetype({
                                    name:
                                        archetypes?.length > 0
                                            ? "New Archetype"
                                            : "Default Model Archetype",
                                    archetype: {
                                        inputDataObject: inputDataObject,
                                    },
                                    model_block_id: modelBlockId,
                                    weight:
                                        archetypes?.length > 0
                                            ? Math.max(
                                                  ...archetypes.map(
                                                      (archetype) =>
                                                          archetype.weight,
                                                  ),
                                              ) + 1
                                            : 0,
                                    is_default:
                                        archetypes?.length > 0 ? false : true,
                                } as InteractiveModuleArchetype);
                            }}
                        >
                            {`Create New Archetype with Settings`}
                        </button>
                        <select
                            className="min-w-[260px] rounded-md border-slate-200 bg-transparent px-3 py-1.5 text-sm
                                font-normal text-slate-500 transition-all marker:border hover:cursor-pointer
                                hover:bg-slate-50 hover:text-slate-600 focus:border-slate-300 focus:outline-none
                                focus:ring-0 dark:border-slate-800 dark:text-slate-500
                                dark:hover:bg-slate-800/25 dark:hover:text-slate-400 dark:focus:border-slate-700"
                            value={""}
                            disabled={isSaving || isToggling}
                            onChange={async (e) => {
                                const archetypeToUpdate = archetypes.find(
                                    (archetype) =>
                                        archetype.id == e.target.value,
                                );
                                if (
                                    window.confirm(
                                        `Overwrite the archetype settings of "${archetypeToUpdate.name}" with local inputs?`,
                                    )
                                ) {
                                    await handleSaveArchetype({
                                        ...archetypeToUpdate,
                                        archetype: {
                                            inputDataObject: inputDataObject,
                                        },
                                    });
                                }
                            }}
                        >
                            <option key={""} value={""} disabled>
                                {`Update Archetype with Settings`}
                            </option>
                            {archetypes?.map((archetype) => (
                                <option key={archetype.id} value={archetype.id}>
                                    {`${archetype.name}`}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 gap-5" ref={listRef}>
                {archetypes?.map((archetype) => (
                    <CardWidgetWrapper key={archetype.id}>
                        <div
                            className={`flex w-full justify-between space-x-6 rounded-md border p-5 transition-all ${
                                archetype.is_default
                                    ? "border-slate-300 dark:border-slate-700"
                                    : "border-transparent"
                            }`}
                        >
                            {isNotParticipant ? (
                                <ArchetypeDisplay
                                    archetype={archetype}
                                    timespan={
                                        !!archetypeTimespanMap &&
                                        archetypeTimespanMap[archetype.id]
                                    }
                                    isPending={isSaving || isToggling}
                                    saveArchetype={handleSaveArchetype}
                                    toggleArchetypePublishedAt={
                                        handleToggleArchetypePublishedAt
                                    }
                                    setInputDataObject={
                                        handleSetInputDataObjectFull
                                    }
                                    requiredInputDataItemCount={
                                        Object.keys(inputDataObject)?.length ??
                                        0
                                    }
                                />
                            ) : (
                                <div className="flex flex-col space-y-2">
                                    <div
                                        className={`text-lg font-semibold tracking-wide text-slate-700 transition-all
                                            dark:text-slate-300`}
                                    >
                                        {archetype.name}
                                    </div>
                                    {!!archetype.description && (
                                        <div className="text-base text-slate-600 transition-all dark:text-slate-400">
                                            {archetype.description}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </CardWidgetWrapper>
                ))}
            </div>
        </div>
    );
};

export const InteractiveSectionGuides = React.memo(
    InteractiveSectionGuidesComponent,
);
