import React from "react";
import { VariableSettingsNumericalProps } from "./props";

const SettingsVariableSliderComponent = ({
    rangeMin,
    rangeMax,
    rangeStep,
    isDisabled,
    value,
    handleOnChange,
    handleOnClick,
}: VariableSettingsNumericalProps) => {
    return (
        <span className="mx-6 inline-flex items-center">
            <span className="text-slate-400">{Number(rangeMin)}</span>
            <input
                className="mx-2 h-2 w-full min-w-[200px] cursor-pointer rounded-md accent-blue-700
                    transition-all dark:opacity-75"
                type="range"
                min={rangeMin}
                max={rangeMax}
                step={rangeStep}
                disabled={isDisabled}
                value={value}
                onChange={(e) => {
                    e.stopPropagation();
                    handleOnChange(Number(e.target.value));
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleOnClick();
                }}
            />
            <span className="text-slate-400">{Number(rangeMax)}</span>
        </span>
    );
};

export const SettingsVariableSlider = React.memo(
    SettingsVariableSliderComponent,
);
