import React from "react";
import {
    Cohort,
    ModelVariable,
    ModelVariableDataType,
    ModelVariableScope,
    Simulation,
    Team,
    TimeHorizon,
    VariableValue,
} from "@/models";
import { useFormatVariableValue } from "@/hooks";

const DataCells = ({
    modelVariable,
    timeHorizons,
    variableValueOrMap,
}: {
    modelVariable: ModelVariable;
    timeHorizons: TimeHorizon[];
    variableValueOrMap:
        | VariableValue
        | { [timeHorizonId: string]: VariableValue };
}) => {
    const formatVariableValue = useFormatVariableValue();

    return !!modelVariable.uses_time ? (
        <>
            {!!timeHorizons?.length &&
                timeHorizons.map((timeHorizon) => (
                    <td
                        className="border-r border-white/10 px-2 py-1"
                        key={timeHorizon.id}
                    >
                        {modelVariable.data_type ===
                        ModelVariableDataType.Number
                            ? formatVariableValue(
                                  modelVariable.unit,
                                  (
                                      variableValueOrMap[
                                          timeHorizon.id
                                      ] as VariableValue
                                  )?.numerical_value,
                                  modelVariable.is_integer,
                              )
                            : variableValueOrMap[
                                  timeHorizon.id
                              ]?.boolean_value.toString()}
                    </td>
                ))}
        </>
    ) : (
        <td className="px-2 py-1">
            {modelVariable.data_type === ModelVariableDataType.Number
                ? formatVariableValue(
                      modelVariable.unit,
                      (variableValueOrMap as VariableValue).numerical_value,
                      modelVariable.is_integer,
                  )
                : variableValueOrMap.boolean_value.toString()}
        </td>
    );
};

export const DiscoveryVariableTable = ({
    selectedSimulation,
    selectedCohort,
    modelVariable,
    teams,
    variableValueMap,
}: {
    selectedSimulation: Partial<Simulation>;
    selectedCohort: Cohort;
    modelVariable: ModelVariable;
    teams: Team[];
    variableValueMap: {
        [scopeId: string]:
            | VariableValue
            | { [timeHorizonId: string]: VariableValue };
    };
}) => {
    return (
        <div className="relative overflow-x-auto">
            <table
                className="w-full table-auto rounded-md bg-rose-950 text-left text-sm text-white"
                style={{
                    fontSize: "0.8rem",
                }}
            >
                <thead className="text-xs">
                    <tr className="uppercase">
                        <th className="border-r border-white/10 px-2 py-2">
                            {modelVariable.scope === ModelVariableScope.User
                                ? "PARTICIPANT"
                                : modelVariable.scope}
                        </th>
                        {!!modelVariable.uses_time &&
                        !!selectedSimulation &&
                        !!selectedSimulation.timeHorizons?.length ? (
                            selectedSimulation.timeHorizons.map(
                                (timeHorizon) => (
                                    <th
                                        className="border-r border-white/10 px-2 py-2"
                                        key={timeHorizon.id}
                                    >
                                        {timeHorizon.time_index}
                                    </th>
                                ),
                            )
                        ) : (
                            <th className="px-2 py-2">{``}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {modelVariable.scope === ModelVariableScope.Cohort &&
                        Object.keys(variableValueMap).map((scopeKey) => (
                            <tr
                                className={
                                    "table-row odd:bg-white/10 even:bg-white/5"
                                }
                                key={scopeKey}
                            >
                                <td className="border-r border-white/10 px-2 py-1">
                                    {`${selectedCohort?.title}`}
                                </td>
                                <DataCells
                                    modelVariable={modelVariable}
                                    timeHorizons={
                                        !!selectedSimulation?.timeHorizons
                                            ?.length
                                            ? selectedSimulation.timeHorizons
                                            : []
                                    }
                                    variableValueOrMap={
                                        variableValueMap[scopeKey]
                                    }
                                />
                            </tr>
                        ))}

                    {modelVariable.scope === ModelVariableScope.Team &&
                        !!teams?.length &&
                        teams
                            .filter((team) => !!variableValueMap[team.id])
                            .map((team) => (
                                <tr
                                    className={
                                        "table-row odd:bg-white/10 even:bg-white/5"
                                    }
                                    key={team.id}
                                >
                                    <td className="border-r border-white/10 px-2 py-1">
                                        {`${team.name}`}
                                    </td>
                                    <DataCells
                                        modelVariable={modelVariable}
                                        timeHorizons={
                                            !!selectedSimulation?.timeHorizons
                                                ?.length
                                                ? selectedSimulation.timeHorizons
                                                : []
                                        }
                                        variableValueOrMap={
                                            variableValueMap[team.id]
                                        }
                                    />
                                </tr>
                            ))}

                    {modelVariable.scope === ModelVariableScope.User &&
                        !!teams?.length &&
                        teams.map((team) => (
                            <React.Fragment key={team.id}>
                                {!!team?.participants?.length &&
                                    team.participants
                                        .filter(
                                            (participant) =>
                                                !!variableValueMap[
                                                    participant.id
                                                ],
                                        )
                                        .map((participant) => (
                                            <tr
                                                className={
                                                    "table-row odd:bg-white/10 even:bg-white/5"
                                                }
                                                key={participant.id}
                                            >
                                                <td className="border-r border-white/10 px-2 py-1">
                                                    {`Team ${team.team_number}: ${participant.name}`}
                                                </td>
                                                <DataCells
                                                    modelVariable={
                                                        modelVariable
                                                    }
                                                    timeHorizons={
                                                        !!selectedSimulation
                                                            ?.timeHorizons
                                                            ?.length
                                                            ? selectedSimulation.timeHorizons
                                                            : []
                                                    }
                                                    variableValueOrMap={
                                                        variableValueMap[
                                                            participant.id
                                                        ]
                                                    }
                                                />
                                            </tr>
                                        ))}
                            </React.Fragment>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
