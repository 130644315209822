import React, { useState } from "react";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { ContentBlockTemplateShape } from "@/models";
import { usePage } from "@/inertia-utils/hooks";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import ContentBlockTemplateCard from "../editor/content-block-templates/ContentBlockTemplateCard";
import ConfirmDeleteTemplateModal from "./ConfirmDeleteTemplateModal";

type Props = SapienAdminPageProps & {
    templates: ContentBlockTemplateShape[];
};

export default function ContentBlockTemplateList({ templates }: Props) {
    const [templateToDelete, setTemplateToDelete] =
        useState<ContentBlockTemplateShape>();
    return (
        <AdminContainer>
            <AdminPageHeader pageTitle="Templates"></AdminPageHeader>
            <AdminPageSection backgroundColor="rgb(243, 244, 246)">
                <div className="flex flex-col space-y-4">
                    <div className="@container">
                        <div className="p-4 grid gap-4 @sm:grid-cols-1 @md:grid-cols-2 @lg:grid-cols-3 bg-gray-100">
                            {templates.map((template) => (
                                <div
                                    className="relative rounded-md"
                                    key={template.id}
                                >
                                    <ContentBlockTemplateCard
                                        template={template}
                                        isSelected={false}
                                        handleClickAdd={function () {}}
                                        colorClasses={{
                                            selected:
                                                "shadow-md bg-white h-full",
                                            unselected:
                                                "bg-gray-50 shadow-sm hover:shadow-sm hover:bg-white hover:scale-[1.03] h-full",
                                        }}
                                        onClickDelete={() => {
                                            setTemplateToDelete(template);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </AdminPageSection>{" "}
            <ConfirmDeleteTemplateModal
                template={templateToDelete}
                cancelDelete={() => {
                    setTemplateToDelete(undefined);
                }}
            />
        </AdminContainer>
    );
}
