import React from "react";
import { useFormatVariableValue } from "@/hooks";
import { AccordionCardWrapper, DefaultTableWrapper } from "../shared";
import { DefaultVariableDataTableRow } from "./DefaultVariableDataTableRow";
import { DataTableProps } from "./props";

const AccordionCardDataTableComponent = ({
    modelBlocks,
    modelVariables,
    valuesMap,
    showResampleFunction,
    modelBlockDisplayMap,
    setModelBlockDisplayMap,
}: DataTableProps) => {
    const formatVariableValue = useFormatVariableValue();

    return (
        <div className="space-y-6">
            {!!modelVariables &&
                modelBlocks?.length > 0 &&
                modelBlocks
                    .filter((block) => !!modelVariables[block.id])
                    .map((block) => (
                        <AccordionCardWrapper
                            key={block.id}
                            modelBlockLabel={block.label}
                            handleSetModelBlockDisplayMap={() =>
                                setModelBlockDisplayMap({
                                    ...modelBlockDisplayMap,
                                    [block.id]: !modelBlockDisplayMap[block.id],
                                })
                            }
                            isExpanded={!!modelBlockDisplayMap[block.id]}
                        >
                            {!!modelBlockDisplayMap[block.id] && (
                                <>
                                    {!!block.description?.length &&
                                        modelVariables[block.id]?.some(
                                            (variable) =>
                                                variable.is_key_metric,
                                        ) && (
                                            <div className="px-4 pt-4 text-slate-600 transition-all dark:text-slate-400">
                                                {block.description}
                                            </div>
                                        )}
                                    <DefaultTableWrapper
                                        header={"variable"}
                                        columnLabels={
                                            Object.keys(valuesMap)?.length > 0
                                                ? Object.values(
                                                      valuesMap,
                                                  )[0]?.map((_, index) =>
                                                      (index + 1).toString(),
                                                  )
                                                : []
                                        }
                                    >
                                        {modelVariables[block.id].map(
                                            (modelVariable, i) => (
                                                <DefaultVariableDataTableRow
                                                    key={modelVariable.id}
                                                    modelVariable={
                                                        modelVariable
                                                    }
                                                    variableValues={
                                                        (Object.keys(valuesMap)
                                                            ?.length > 0 &&
                                                            valuesMap[
                                                                modelVariable.id
                                                            ]) ||
                                                        []
                                                    }
                                                    index={i}
                                                    showResampleFunction={
                                                        showResampleFunction
                                                    }
                                                    formatVariableValue={
                                                        formatVariableValue
                                                    }
                                                />
                                            ),
                                        )}
                                    </DefaultTableWrapper>
                                </>
                            )}
                        </AccordionCardWrapper>
                    ))}
        </div>
    );
};

export const AccordionCardDataTable = React.memo(
    AccordionCardDataTableComponent,
);
