import React, { useState } from "react";
import { ChartBlock, FormattedChartValue } from "../types";
import { ChartDisplay } from "../shared";

const InteractiveDashboardChartComponent = ({
    modelTimespan,
    chartBlock,
    chartValues,
    handleSetDashboardChartValuesMap,
}: {
    modelTimespan: number;
    chartBlock: ChartBlock;
    chartValues: Record<string, FormattedChartValue[]>;
    handleSetDashboardChartValuesMap: (
        chartBlockId: string,
        timeIndex: number,
    ) => void;
}) => {
    const [chartTimeIndex, setChartTimeIndex] = useState<number>(
        chartBlock?.time_index ?? 0,
    );

    return (
        <ChartDisplay
            modelTimespan={modelTimespan}
            chartBlock={{ ...chartBlock, time_index: chartTimeIndex }}
            setChartTimeIndex={(timeIndex: number) => {
                setChartTimeIndex(timeIndex);
                handleSetDashboardChartValuesMap(chartBlock?.id, timeIndex);
            }}
            chartValues={chartValues}
        />
    );
};

export const InteractiveDashboardChart = React.memo(
    InteractiveDashboardChartComponent,
);
