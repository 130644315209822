import React, { useMemo } from "react";
import {
    VictoryChart,
    VictoryBoxPlot,
    VictoryAxis,
    VictoryPie,
    VictoryLegend,
    VictoryTheme,
    Tuple,
} from "victory";

const wrapText = (text, maxLength) => {
    if (!text) return text;
    const words = text.split(" ");
    let wrappedText = "";
    let line = "";
    words.forEach((word) => {
        if ((line + word).length > maxLength) {
            wrappedText += line + "\n";
            line = "";
        }
        line += word + " ";
    });
    return wrappedText + line.trim();
};

export const DiscoveryBoxPlot = ({
    data,
    height,
    title,
    domain,
}: {
    data: { x: string; y: number[] }[];
    height?: number;
    title?: string;
    domain?: { x?: Tuple<number>; y?: Tuple<number> };
}) => {
    const boxSize = useMemo<number>(() => {
        return data?.length === 1
            ? 20
            : data?.length >= 14
              ? 5
              : data?.length >= 10
                ? 8
                : data?.length >= 6
                  ? 10
                  : 14;
    }, [data]);

    const fontSize = useMemo<number>(() => {
        return 6; // data?.length >= 10 ? 6 : 8;
    }, [data]);

    return (
        <div className="">
            {!!title && <div className="px-2">{title}</div>}
            <VictoryChart
                horizontal
                domainPadding={{ x: [boxSize, boxSize], y: [0, 0] }}
                padding={{
                    left: data?.length === 1 && data[0].x === " " ? 25 : 75,
                    top: data?.length === 1 && data[0].x === " " ? 10 : 25,
                    right: 25,
                    bottom: 25,
                }}
                height={height || Math.max(100, 3 * boxSize * data.length)}
                domain={domain}
            >
                <VictoryBoxPlot
                    labels
                    labelOrientation="top"
                    data={data}
                    boxWidth={boxSize}
                    whiskerWidth={boxSize}
                    style={{
                        min: { stroke: "#005787" },
                        q1: { fill: "#005787" },
                        median: { stroke: "#000000" },
                        q3: { fill: "#002244" },
                        max: { stroke: "#002244" },
                        minLabels: {
                            fontSize: fontSize - 1,
                            padding: 1,
                            fill: "#00000080",
                        },
                        q1Labels: {
                            fontSize: fontSize - 1,
                            padding: 1,
                            fill: "#00000040",
                        },
                        maxLabels: {
                            fontSize: fontSize - 1,
                            padding: 1,
                            fill: "#00000080",
                        },
                        q3Labels: {
                            fontSize: fontSize - 1,
                            padding: 1,
                            fill: "#00000040",
                        },
                        medianLabels: { fontSize: fontSize - 1, padding: 1 },
                    }}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#00000040" },
                        tickLabels: { fontSize: fontSize - 2, padding: 5 },
                    }}
                    tickFormat={(tick) => wrapText(tick, 30)}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        axis: { stroke: "#00000040" },
                        tickLabels: { fontSize: fontSize, padding: 5 },
                    }}
                />
            </VictoryChart>
        </div>
    );
};

const colorScale = [
    "#2b908f",
    "#e4d354",
    "#f45b5b",
    "#7cb5ec",
    "#f7a35c",
    "#90ed7d",
    "#8085e9",
    "#f15c80",
    "#91e8e1",
    "#434348",
];

export const DiscoveryPieChart = ({
    title,
    useColorScale,
    data,
}: {
    title?: string;
    useColorScale?: boolean;
    data: { x: string; y: number; label?: string; formattedValue?: string }[];
}) => {
    return (
        <div className="">
            {!!title && <div className="px-2">{title}</div>}
            <div className="grid grid-cols-2">
                <div className="">
                    <VictoryPie
                        theme={VictoryTheme.material}
                        data={data
                            .filter((value) => value.y > 0)
                            .map((value) => ({
                                x: value.x,
                                y: value.y,
                                formattedValue: value.formattedValue,
                            }))}
                        height={150}
                        labels={({ datum }) => `${datum.formattedValue}`}
                        labelRadius={50}
                        cornerRadius={3}
                        padAngle={3}
                        innerRadius={20}
                        style={{
                            labels: {
                                fontSize: 8,
                                fill: "white",
                                padding: 10,
                            },
                        }}
                        padding={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                        colorScale={useColorScale ? colorScale : "grayscale"}
                    />
                </div>
                <div className="">
                    <VictoryLegend
                        theme={VictoryTheme.material}
                        orientation="vertical"
                        gutter={20}
                        height={200}
                        colorScale={useColorScale ? colorScale : "grayscale"}
                        data={data
                            .filter((value) => value.y > 0)
                            .map((d) => ({ name: d.label.substring(0, 80) }))}
                        style={{
                            labels: {
                                fontSize: 10,
                                fill: "black",
                                textOverflow: "ellipsis",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
