import React from "react";
import { HandRaisedIcon } from "@heroicons/react/24/solid";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";

type Props = {
    onClick: () => void;
    isDisabled: boolean;
};

export default function MoveButton({ onClick, isDisabled }: Props) {
    return (
        <ContentBlockButtonContainer
            onClick={() => {
                !isDisabled && onClick();
            }}
            hoverColor={"bg-blue-600"}
        >
            <HandRaisedIcon className="h-5 w-5" />
        </ContentBlockButtonContainer>
    );
}
