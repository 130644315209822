import React, { useCallback, useEffect, useState } from "react";
import { Form, H6Span } from "@/components";
import { ContentBlockShape, ContentBlockType } from "@/models";
import {
    configureThemeObject,
    ThemeConfigDisplay,
    ThemeObject,
    themePropertyConfig,
} from "@/styles";
import { variantsByPromptType } from "@/model-configs/content-block-configs/QuestionConfig";
import { Icon } from "react-icons-kit";
import { caretRight } from "react-icons-kit/fa/caretRight";
import { caretDown } from "react-icons-kit/fa/caretDown";
import {
    useUpdateActiveContentBlock,
    useGetActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { getContentBlockConfigByType } from "@/model-configs";

type CategoryType =
    | "RichText"
    | "Variants"
    | "Layout"
    | "Spacing"
    | "Size"
    | "Typography"
    | "Colors"
    | "Borders"
    | "Charting"
    | "Icon";

// const allThemePropertyConfigKeys = [
// "fontFamily",
// "fontSize",
// "fontWeight",
// "letterSpacing",
// "textAlign",
// "fontStyle",
// "color",
// "backgroundColor",
// ----- "opacity",
// "padding",
// "margin",
// "textTransform",
// "borderColor",
// "hoverColor",
// "hoverBackgroundColor",
// "hoverBorderColor",
// "borderWidth",
// "borderRadius",
// "display",
// "alignItems",
// "alignContent",
// "justifyItems",
// "justifyContent",
// "flexWrap",
// "flexDirection",
// "width",
// "widthBreakpoint1",
// "widthBreakpoint2",
// "widthBreakpoint3",
// "widthBreakpoint4",
// "typographyType",
// "palette",
// ];

const menuCategories: {
    [index in CategoryType]: {
        [index in keyof typeof themePropertyConfig]?: boolean;
    };
} = {
    RichText: {},
    Variants: { typographyType: false, palette: false },
    Layout: {
        display: false,
        alignItems: false,
        alignContent: false,
        justifyItems: false,
        justifyContent: false,
        flexWrap: false,
        flexDirection: false,
    },
    Spacing: { padding: false, margin: false },
    Size: {
        width: false,
        widthBreakpoint1: false,
        widthBreakpoint2: false,
        widthBreakpoint3: false,
        widthBreakpoint4: false,
    },
    Typography: {
        fontFamily: false,
        fontSize: false,
        fontWeight: false,
        letterSpacing: false,
        lineHeight: false,
        textAlign: false,
        fontStyle: false,
        textTransform: false,
    },
    Colors: {
        color: false,
        backgroundColor: false,
        borderColor: false,

        hoverColor: false,
        hoverBackgroundColor: false,
        hoverBorderColor: false,

        activeColor: false,
        activeBackgroundColor: false,
        activeBorderColor: false,

        disabledColor: false,
        disabledBackgroundColor: false,
        disabledBorderColor: false,

        gridColor: false,
        axisColor: false,
        toolTipBackground: false,
    },
    Charting: {
        fill: false,
        stroke: false,
        strokeWidth: false,
        interpolation: false,
    },
    Borders: { borderWidth: false, borderRadius: false },
    Icon: {
        icon: false,
        iconPosition: false,
        size: false,
    },
};

const openState = Object.keys(menuCategories).reduce((carry, category) => {
    return {
        ...carry,
        ...{ [category]: true },
    };
}, {});

export const PanelControls = () => {
    const { data: activeContentBlock } = useGetActiveContentBlock();

    const activeContentBlockConfig = getContentBlockConfigByType(
        activeContentBlock?.content_block_type,
    );
    // const { setSelectedType, setSelectedLayout } = useConfigEditor();
    const [configuredTheme, setConfiguredTheme] =
        useState<Partial<ThemeObject>>();
    const [configuredMenu, setConfiguredMenu] = useState<{
        [index in CategoryType]?: Partial<ThemeObject>;
    }>();
    const [openCategories, setOpenCategories] = useState(openState);

    useEffect(() => {
        if (activeContentBlock && activeContentBlock.theme) {
            // const defaultTheme =
            //     getContentBlockConfigByType(
            //         activeContentBlock.content_block_type
            //     )?.defaultTheme || {};

            let themeToConfigure = {
                ...activeContentBlock.theme,
            };

            if (
                activeContentBlock.content_block_type ===
                    ContentBlockType["Rich Text"] &&
                !themeToConfigure.hasOwnProperty("width")
            ) {
                themeToConfigure.width = "auto";
            }

            if (
                activeContentBlock.content_block_type ===
                    ContentBlockType["Rich Text"] &&
                themeToConfigure.hasOwnProperty("color")
            ) {
                const { color: _, ...filteredProps } = themeToConfigure;
                themeToConfigure = { ...filteredProps };
            }

            // remove font family, size, and style for buttons/links
            // remove text transform from buttons?

            let variantOptionsByPromptType = null;
            if (
                activeContentBlock.content_block_type ===
                ContentBlockType.Question
            ) {
                variantOptionsByPromptType =
                    activeContentBlock.prompt &&
                    variantsByPromptType[activeContentBlock.prompt.prompt_type]
                        ? variantsByPromptType[
                              activeContentBlock.prompt.prompt_type
                          ]
                        : [];
            }

            let newConfiguredTheme = configureThemeObject(themeToConfigure, {
                ...activeContentBlockConfig,
                variantOptions: variantOptionsByPromptType?.length
                    ? variantOptionsByPromptType
                    : activeContentBlockConfig?.variantOptions,
            });

            setConfiguredTheme(newConfiguredTheme);
        } else {
            setConfiguredTheme(undefined);
            setOpenCategories(openState);
        }
    }, [activeContentBlock, activeContentBlockConfig]);

    useEffect(() => {
        if (
            configuredTheme !== undefined &&
            Object.keys(configuredTheme).length > 0
        ) {
            let newConfiguredMenu: {
                [index in CategoryType]?: Partial<ThemeObject>;
            } = {};

            Object.keys(menuCategories).forEach((menuCategory) => {
                Object.keys(menuCategories[menuCategory]).forEach(
                    (themeKey) => {
                        if (configuredTheme[themeKey] !== undefined) {
                            if (newConfiguredMenu[menuCategory] !== undefined) {
                                newConfiguredMenu = {
                                    ...newConfiguredMenu,
                                    [menuCategory as CategoryType]: {
                                        ...newConfiguredMenu[menuCategory],
                                        [themeKey]: configuredTheme[themeKey],
                                    } as Partial<ThemeObject>,
                                };
                            } else {
                                newConfiguredMenu = {
                                    ...newConfiguredMenu,
                                    [menuCategory as CategoryType]: {
                                        [themeKey]: configuredTheme[themeKey],
                                    } as Partial<ThemeObject>,
                                };
                            }
                        } else {
                        }
                    },
                );

                if (menuCategory === "Variants") {
                    Object.keys(configuredTheme).forEach((themeKey) => {
                        if (
                            configuredTheme[themeKey] &&
                            configuredTheme[themeKey].variantOptions
                        ) {
                            if (newConfiguredMenu[menuCategory] !== undefined) {
                                newConfiguredMenu = {
                                    ...newConfiguredMenu,
                                    [menuCategory as CategoryType]: {
                                        ...newConfiguredMenu[menuCategory],
                                        [themeKey]: configuredTheme[themeKey],
                                    } as Partial<ThemeObject>,
                                };
                            } else {
                                newConfiguredMenu = {
                                    ...newConfiguredMenu,
                                    [menuCategory as CategoryType]: {
                                        [themeKey]: configuredTheme[themeKey],
                                    } as Partial<ThemeObject>,
                                };
                            }
                        }
                    });
                }
            });

            setConfiguredMenu(newConfiguredMenu);
        } else {
            setConfiguredMenu(undefined);
        }
    }, [configuredTheme]);

    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const handleThemeChange = useCallback(
        (prop: keyof ThemeObject, value: any) => {
            if (activeContentBlock && activeContentBlock.theme) {
                updateActiveContentBlock({
                    ...activeContentBlock,
                    theme: {
                        ...activeContentBlock.theme,
                        ...{ [prop]: value },
                    },
                } as ContentBlockShape);
            }
        },
        [
            activeContentBlock,
            updateActiveContentBlock,
            activeContentBlock?.theme,
        ],
    );

    return (
        <>
            <div className="px-4 py-2">
                <H6Span>{`Styles`}</H6Span>
            </div>
            <div data-menu-bar={true} />
            {configuredMenu !== undefined &&
            Object.keys(configuredMenu).length > 0 &&
            configuredTheme !== undefined &&
            Object.keys(configuredTheme).length > 0 ? (
                <Form
                    key={activeContentBlock?.id}
                    data-testid="right-panel-form"
                >
                    {Object.keys(configuredMenu).map((menuCategory) => (
                        <div key={menuCategory}>
                            <div
                                className="flex items-center bg-[rgba(255,255,255,0.03)] px-2 py-1"
                                style={{
                                    marginBottom:
                                        openCategories[menuCategory] && "1rem",
                                    borderTop:
                                        "1px solid rgba(255,255,255,0.15)",
                                    borderBottom:
                                        "0.5px solid rgba(255,255,255,0.15)",
                                }}
                                onClick={() =>
                                    setOpenCategories({
                                        ...openCategories,
                                        ...{
                                            [menuCategory]:
                                                !openCategories[menuCategory],
                                        },
                                    })
                                }
                            >
                                <Icon
                                    icon={
                                        openCategories[menuCategory]
                                            ? caretDown
                                            : caretRight
                                    }
                                    style={{
                                        display: "flex",
                                        paddingRight: "4px",
                                    }}
                                />
                                {menuCategory}
                            </div>
                            {configuredMenu[menuCategory] &&
                                openCategories[menuCategory] && (
                                    <div className="px-4">
                                        {Object.keys(
                                            configuredMenu[menuCategory],
                                        ).map((key) => {
                                            //todo: find a more architecturally appropriate way to handle this

                                            let overrideLabel = "";
                                            if (key === "alignItems") {
                                                if (
                                                    configuredTheme?.flexDirection ===
                                                    "column"
                                                ) {
                                                    overrideLabel =
                                                        "Horizontal Alignment";
                                                } else {
                                                    overrideLabel =
                                                        "Vertical Alignment";
                                                }
                                            }

                                            if (key === "justifyContent") {
                                                if (
                                                    configuredTheme?.flexDirection ===
                                                    "column"
                                                ) {
                                                    overrideLabel =
                                                        "Vertical Alignment";
                                                } else {
                                                    overrideLabel =
                                                        "Horizontal Alignment";
                                                }
                                            }

                                            return (
                                                <ThemeConfigDisplay
                                                    id={"id"}
                                                    key={`${key}-${activeContentBlock?.id}`}
                                                    value={
                                                        configuredTheme[key] &&
                                                        configuredTheme[key]
                                                            .value
                                                            ? configuredTheme[
                                                                  key
                                                              ].value
                                                            : configuredTheme[
                                                                  key
                                                              ]
                                                    }
                                                    themeObjectKey={
                                                        key as keyof ThemeObject
                                                    }
                                                    changeHandler={
                                                        handleThemeChange
                                                    }
                                                    groupProperties={
                                                        typeof configuredTheme[
                                                            key
                                                        ] === "object"
                                                            ? configuredTheme[
                                                                  key
                                                              ]
                                                            : undefined
                                                    }
                                                    variantOptions={
                                                        configuredTheme[key] &&
                                                        configuredTheme[key]
                                                            .variantOptions
                                                            ? configuredTheme[
                                                                  key
                                                              ].variantOptions
                                                            : null
                                                    }
                                                    displayNameOverride={
                                                        overrideLabel
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                        </div>
                    ))}
                </Form>
            ) : (
                <div className="px-4">
                    {!!activeContentBlock
                        ? "No custom controls."
                        : "Select a component to customize."}
                </div>
            )}
        </>
    );
};
