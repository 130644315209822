import { ContentBlock, ContentBlockShape, ContentBlockType } from "@/models";
import React, { useCallback, useEffect, useMemo } from "react";
import { useGetContentBlockWithModelDataSources } from "../useGetContentBlockWithModelDataSources";
import {
    VariableValueWithLabel,
    useDefaultVariableValues,
} from "../useDefaultVariableValues";
import { Cell, Row, Table, reduceHexOpacity } from "@/styles";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import {
    TrashIcon,
    ArrowUpIcon,
    ArrowDownIcon,
} from "@heroicons/react/24/solid";
import ModelVariableComboBox from "@/components/admin-components/model-variable-selection/ModelVariableComboBox";
import TimeHorizonForm from "../TimeHorizonForm";
import { useFormatVariableValue } from "@/hooks";
import { Field, Switch, Label } from "@headlessui/react";

type Props = {
    table: ContentBlockShape;
    modelVariableIds: string[];
    setModelVariableIds: (modelVariableIds: string[]) => void;
    timeHorizonIds: string[];
    setTimeHorizonIds: (timeHorizonIds: string[]) => void;
    moveRowUp: (index: number) => void;
    moveRowDown: (index: number) => void;
    deleteRow: (index: number) => void;
};

export default function ModelTableBuilder({
    table,
    modelVariableIds,
    setModelVariableIds,
    timeHorizonIds,
    setTimeHorizonIds,
    moveRowUp,
    moveRowDown,
    deleteRow,
}: Props) {
    const formatVariableValue = useFormatVariableValue();

    const { timeHorizonMap } = useTimeHorizonStore();

    const {
        data: { contentBlockWithModelDataSources },
    } = useGetContentBlockWithModelDataSources(table?.id);

    const {
        isFetching,
        data: { values },
    } = useDefaultVariableValues<{ [index: string]: VariableValueWithLabel[] }>(
        {
            timeHorizonIds: timeHorizonIds,
            model_variable_ids: modelVariableIds,
            formatterCallback: (values) => {
                return { values };
            },
        },
    );

    //any way to do this without useEffect?
    useEffect(() => {
        if (contentBlockWithModelDataSources?.id) {
            let rows: ContentBlockShape[] = [];
            if (
                contentBlockWithModelDataSources.content_block_type ===
                ContentBlockType.Leaderboard
            ) {
                rows = [contentBlockWithModelDataSources];
            } else {
                rows = contentBlockWithModelDataSources?.contentBlocks.filter(
                    (block) =>
                        block.content_block_type ===
                        ContentBlockType["Table Row"],
                );
            }

            const modelVariableIds = rows?.reduce((carry, row) => {
                return [
                    ...carry,
                    ...row.contentBlockModelDataSources?.map(
                        (cbmds) => cbmds.model_variable_id,
                    ),
                ];
            }, []);
            const usesTime = rows?.every(
                (row) =>
                    row.contentBlockModelDataSources?.[0]?.modelVariable
                        ?.uses_time,
            );

            console.log(rows, usesTime);

            const timeHorizonIds = !usesTime
                ? []
                : rows?.reduce((carry, block) => {
                      return [
                          ...carry,
                          ...block?.contentBlockModelDataSources?.reduce(
                              (thCarry, cmbs) => [
                                  ...thCarry,
                                  ...cmbs.timeHorizons?.map((th) => th.id),
                              ],
                              [],
                          ),
                      ];
                  }, []);
            setModelVariableIds(modelVariableIds);
            setTimeHorizonIds(timeHorizonIds);
        }
    }, [contentBlockWithModelDataSources?.id, table?.id]);

    const [usesTime, setUsesTime] = React.useState(true);
    const { timeSeriesModelVariables, nonTimeSeriesModelVariables } =
        useModelVariableStore();
    const modelVariables = usesTime
        ? timeSeriesModelVariables
        : nonTimeSeriesModelVariables;

    const theme = table?.theme || {};

    const onMouseEnter = useCallback((e) => {
        (e.target as HTMLDivElement)
            .closest(".overflower")
            .classList.remove("overflow-x-auto");
        (e.target as HTMLDivElement)
            .closest(".overflower")
            .classList.add("overflow-visible", "pb-2");
    }, []);

    const onMouseLeave = useCallback((e) => {
        (e.target as HTMLDivElement)
            .closest(".overflower")
            .classList.add("overflow-x-auto");
        (e.target as HTMLDivElement)
            .closest(".overflower")
            .classList.remove("overflow-visible", "pb-2");
    }, []);

    const showVariableValue = useCallback(
        (modelVariableId: string, timeHorizonId?: string) => {
            const variableValue = values[modelVariableId]?.find(
                (variableValue) =>
                    variableValue.time_horizon_id === null ||
                    variableValue.time_horizon_id === timeHorizonId,
            );
            if (!variableValue) return "N/A";

            return (
                <span
                    data-testid={`value-display-${modelVariableId}-${timeHorizonId}`}
                >
                    {formatVariableValue(
                        variableValue.unit,
                        variableValue.numerical_value,
                    )}
                </span>
            );
        },

        [values, timeHorizonIds],
    );

    const handelSetUsesTime = useCallback(() => {
        if (usesTime) {
            setTimeHorizonIds([]);
        }
        setUsesTime(() => !usesTime);
    }, [usesTime]);

    return (
        <div className="flex flex-col items-center justify-center gap-4 py-6">
            <div className="flex w-full max-w-[996px] flex-col gap-4 rounded-md bg-white/10 p-6 ">
                <Field className="flex w-full max-w-[996px] gap-4 px-0 ">
                    <Label>Use Time Series Variables</Label>
                    <Switch
                        checked={usesTime}
                        onChange={handelSetUsesTime}
                        className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1
                            transition-colors duration-200 ease-in-out data-[checked]:bg-blue-600
                            data-[focus]:outline-1 data-[focus]:outline-white focus:outline-none"
                    >
                        <span
                            aria-hidden="true"
                            className="pointer-events-none inline-block size-5 rounded-full bg-white shadow-lg ring-0
                                transition duration-200 ease-in-out translate-x-0
                                group-data-[checked]:translate-x-7"
                        />
                    </Switch>
                </Field>
                {!!usesTime && (
                    <>
                        <h4 className="text-lg font-semibold">
                            Select Time Horizons
                        </h4>
                        <TimeHorizonForm
                            timeHorizons={Object.values(timeHorizonMap)}
                            setTimeHorizons={setTimeHorizonIds}
                            selectedTimeHorizons={timeHorizonIds
                                .filter((thid) => !!timeHorizonMap[thid])
                                .map((thid) => timeHorizonMap[thid])}
                            isSingleSelect={false}
                            isDisabled={!usesTime}
                        />
                    </>
                )}
            </div>

            <div className="max-w-full p-2">
                <div
                    className="max-w-[996px]"
                    style={{ borderRadius: theme.borderRadius }}
                >
                    <div
                        className="overflower scrollbar-rounded-full max-w-[990px] overflow-x-auto scrollbar-thin
                            scrollbar-track-slate-300 scrollbar-thumb-slate-700 scrollbar-track-rounded-full
                            scrollbar-thumb-rounded-full"
                    >
                        <Table
                            data-testid="model-table"
                            {...theme}
                            className="min-w-[992px] overflow-visible"
                            style={{ overflow: "visible" }}
                        >
                            <tbody className="overflow-visible">
                                {usesTime && (
                                    <Row>
                                        <td></td>
                                        <td>Time Horizon</td>
                                        {timeHorizonIds?.map(
                                            (timeHorizonId) => (
                                                <td key={timeHorizonId}>
                                                    {
                                                        timeHorizonMap[
                                                            timeHorizonId
                                                        ]?.time_index
                                                    }
                                                </td>
                                            ),
                                        )}
                                    </Row>
                                )}
                                {modelVariableIds?.map(
                                    (modelVariableId, rowIndex) => (
                                        <Row
                                            style={{
                                                background:
                                                    rowIndex % 2 !== 0 &&
                                                    theme.isStriped
                                                        ? reduceHexOpacity(
                                                              theme.backgroundColor ||
                                                                  "#F9FAFB",
                                                          )
                                                        : theme.backgroundColor ||
                                                          "#F9FAFB",
                                            }}
                                            key={modelVariableId}
                                            className="overflow-visible"
                                        >
                                            <Cell className="w-[125px] max-w-[125px]">
                                                <div className="flex rounded-md shadow-sm">
                                                    <button
                                                        onClick={() =>
                                                            deleteRow(rowIndex)
                                                        }
                                                        type="button"
                                                        className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400
                                                            ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    >
                                                        <span className="sr-only">
                                                            Delete Row
                                                        </span>
                                                        <TrashIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </button>

                                                    <button
                                                        onClick={() =>
                                                            moveRowUp(rowIndex)
                                                        }
                                                        type="button"
                                                        className="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1
                                                            ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    >
                                                        <span className="sr-only">
                                                            Move Row Up
                                                        </span>
                                                        <ArrowUpIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            moveRowDown(
                                                                rowIndex,
                                                            )
                                                        }
                                                        type="button"
                                                        className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2
                                                            text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                    >
                                                        <span className="sr-only">
                                                            Move Row Down
                                                        </span>
                                                        <ArrowDownIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </Cell>
                                            <Cell className="min-w-[225px] max-w-[225px] overflow-visible">
                                                <div
                                                    className="relative flex max-w-[225px] space-x-2 overflow-visible"
                                                    onMouseEnter={onMouseEnter}
                                                    onMouseLeave={onMouseLeave}
                                                >
                                                    <ModelVariableComboBox
                                                        handleSelectModelVariable={(
                                                            variable,
                                                        ) => {
                                                            console.log(
                                                                "v",
                                                                variable,
                                                            );
                                                            setModelVariableIds(
                                                                modelVariableIds.map(
                                                                    (
                                                                        mvid,
                                                                        i,
                                                                    ) => {
                                                                        if (
                                                                            i ===
                                                                            rowIndex
                                                                        ) {
                                                                            return variable.id;
                                                                        }
                                                                        return mvid;
                                                                    },
                                                                ),
                                                            );
                                                        }}
                                                        modelVariables={
                                                            modelVariables
                                                        }
                                                        selectedModelVariables={modelVariableIds
                                                            .filter(
                                                                (mvid) =>
                                                                    mvid ===
                                                                        modelVariableId &&
                                                                    !!modelVariables[
                                                                        mvid
                                                                    ],
                                                            )
                                                            .map(
                                                                (mvid) =>
                                                                    modelVariables[
                                                                        mvid
                                                                    ],
                                                            )}
                                                    />
                                                </div>
                                            </Cell>
                                            {!usesTime && (
                                                <Cell
                                                    {...theme}
                                                    className="min-w-[125px]"
                                                >
                                                    {!isFetching ? (
                                                        showVariableValue(
                                                            modelVariableId,
                                                        )
                                                    ) : (
                                                        <div
                                                            className="inline-block h-6 w-6 animate-spin rounded-full border-[3px] border-current
                                                                border-t-transparent text-blue-600 opacity-50"
                                                            role="status"
                                                            aria-label="loading"
                                                        >
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    )}
                                                </Cell>
                                            )}
                                            {timeHorizonIds?.map(
                                                (timeHorizonId) => (
                                                    <Cell
                                                        {...theme}
                                                        key={timeHorizonId}
                                                        className="min-w-[125px]"
                                                    >
                                                        {!isFetching ? (
                                                            showVariableValue(
                                                                modelVariableId,
                                                                timeHorizonId,
                                                            )
                                                        ) : (
                                                            <div
                                                                className="inline-block h-6 w-6 animate-spin rounded-full border-[3px] border-current
                                                                    border-t-transparent text-blue-600 opacity-50"
                                                                role="status"
                                                                aria-label="loading"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                        )}
                                                    </Cell>
                                                ),
                                            )}
                                        </Row>
                                    ),
                                )}
                                <Row
                                    key="add_model_variable"
                                    data-testid="add-variable-row"
                                    className="add-variable-row bg-white/10"
                                >
                                    <td
                                        colSpan={(timeHorizonIds.length || 1) + 2}
                                        className="p-4"
                                    >
                                        <div
                                            className="max-w-lg"
                                            onMouseEnter={onMouseEnter}
                                            onMouseLeave={onMouseLeave}
                                        >
                                            <ModelVariableComboBox
                                                direction="up"
                                                handleSelectModelVariable={(
                                                    variable,
                                                ) => {
                                                    console.log(
                                                        "var",
                                                        variable,
                                                    );
                                                    setModelVariableIds([
                                                        ...modelVariableIds,
                                                        variable.id,
                                                    ]);
                                                }}
                                                modelVariables={modelVariables}
                                                selectedModelVariables={[]}
                                            />
                                        </div>
                                    </td>
                                </Row>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}
