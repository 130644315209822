import React from "react";
import { Course } from "../types";
import { useDownloadScormExport } from "./queries/useDownloadScormExport";

type Props = {
    course: Course;
};

export default function DownloadScormExport({ course }: Props) {
    const { mutate, isPending } = useDownloadScormExport(course);

    return (
        <button
            data-busy={isPending}
            className="flex items-center rounded-full bg-sapien-blue px-4 py-2 text-sm font-semibold
                text-white data-[busy='true']:animate-pulse hover:bg-blue-900"
            onClick={() => mutate()}
            disabled={isPending}
        >
            Export Scorm
        </button>
    );
}
