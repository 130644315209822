import React from 'react';
import { useForm, router } from "@inertiajs/react";

type Props = {}

export default function TenantForm({ }: Props) {

    const { data, setData } = useForm({
        tenant_name: ""
    })

    function save() {
        router.post("/tenants",
            data
            , { preserveState: false })
    }

    return (
        <div className='flex flex-col space-y-2'>
            <div>TenantForm</div>
            <div><input className={"b-1"} value={data.tenant_name} onChange={e => setData("tenant_name", e.target.value)} /></div>
            <div><button className='' onClick={_ => save()}>Submit</button></div>
        </div>
    )
}
