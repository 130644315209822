import React from "react";
import { ContentBlockTemplateShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import ContentBlockTemplateCard from "../editor/content-block-templates/ContentBlockTemplateCard";

type Props = {
    template: ContentBlockTemplateShape;
    cancelDelete: () => void;
};

export default function ConfirmDeleteTemplateModal({
    template,
    cancelDelete,
}: Props) {
    return (
        <ModalContainer
            isModalOpen={!!template}
            setIsModalOpen={cancelDelete}
            styles={{
                top: "0px",
                margin: "20vh auto",
                zIndex: 99999,
                boxShadow:
                    "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1",
            }}
            backgroundColor="#111928"
            size="m"
            classNames={"z-100"}
        >
            {!!template && (
                <div className="flex flex-col space-y-4 p-6 text-white">
                    <div>
                        <h3 className="text-lg font-medium">
                            Are you sure you want to delete this template?
                        </h3>
                        <p className="text-xs text-gray-200">
                            This will delete the template from your templates,
                            but others might still have access to it.
                        </p>
                    </div>
                    <div className="w-72 mx-auto">
                        <ContentBlockTemplateCard
                            template={template}
                            isSelected={false}
                            handleClickAdd={function () {}}
                        />
                    </div>
                    <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                        <div className="flex justify-center items-center">
                            <button
                                onClick={() => {
                                    cancelDelete();
                                }}
                                className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="flex justify-center items-center">
                            <button
                                onClick={() => {
                                    SapienInertia.delete(
                                        sapienRoute(
                                            "creator.design.templates.destroy",
                                            {
                                                template: template.id,
                                            }
                                        ),
                                        {
                                            onSuccess: () => {
                                                cancelDelete();
                                            },
                                        }
                                    );
                                }}
                                className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
}
