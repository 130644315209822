import React, { useCallback } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";

interface ReorderButtonProps {
    showUpButton: boolean;
    showDownButton: boolean;
    contentBlockId: string;
    parentContentBlockId: string;
    currentPosition: number;
    isDisabled?: boolean;
}

export const ReorderButtons = (props: ReorderButtonProps) => {
    const { showUpButton, showDownButton, contentBlockId, currentPosition } =
        props;

    const { updateActiveContentBlock } = useUpdateActiveContentBlock();

    const reorderContentBlocks = useCallback(
        (indexChange: number) => {
            updateActiveContentBlock("weight", currentPosition + indexChange);

            SapienInertia.post(
                "admin.design.content-blocks.reorder",
                {
                    content_block_id: contentBlockId,
                    destination_index: currentPosition + indexChange,
                },
                {},
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: (page) => {
                        console.log(page);
                    },
                }
            );
        },
        [contentBlockId, currentPosition]
    );

    return (
        <>
            {showUpButton && (
                <ContentBlockButtonContainer
                    onClick={() => {
                        reorderContentBlocks(-1);
                    }}
                    hoverColor={"bg-blue-600 text-white"}
                >
                    <ArrowUpIcon className="h-5 w-5" />
                </ContentBlockButtonContainer>
            )}
            {showDownButton && (
                <ContentBlockButtonContainer
                    onClick={() => {
                        reorderContentBlocks(1);
                    }}
                    hoverColor={"bg-blue-600 text-white"}
                >
                    <ArrowDownIcon className="h-5 w-5" />
                </ContentBlockButtonContainer>
            )}
        </>
    );
};
