import React from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@radix-ui/react-popover";
import Icon from "react-icons-kit";
import { moreHorizontal } from "react-icons-kit/feather/moreHorizontal";
import { DefaultTableWrapper } from "../shared";
import { ModelVariable, ModelVariableDataType } from "@/models";
import { InputDataSettingType, InteractiveModuleArchetype } from "../types";
import { VariableValueFormatFunction } from "@/hooks";

const getFormFieldKeys = (settingType: InputDataSettingType) => {
    return settingType === InputDataSettingType.timespan ||
        settingType === InputDataSettingType.constant ||
        settingType === InputDataSettingType["boolean constant"]
        ? ["numerical_value"]
        : settingType === InputDataSettingType["linear interval"] ||
            settingType === InputDataSettingType["power interval"]
          ? ["initial", "final"]
          : ["step_values"];
};

const ArchetypeInputComparisonComponent = ({
    modelVariable,
    archetypes,
    formatVariableValue,
}: {
    modelVariable: ModelVariable;
    archetypes: InteractiveModuleArchetype[];
    formatVariableValue: VariableValueFormatFunction;
}) => {
    return (
        <>
            <Popover>
                <PopoverTrigger
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        title={"Archetype Values"}
                        className="relative inline-flex items-center text-sm font-medium"
                    >
                        <span className="sr-only">Archetype Values</span>
                        <Icon
                            icon={moreHorizontal}
                            size={20}
                            className="flex h-8 items-center justify-center text-slate-400 transition-all
                                hover:text-slate-600 focus:outline-none focus:ring-0 dark:text-slate-500
                                dark:hover:text-slate-300"
                            style={{ display: "flex" }}
                        />
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align={"end"}
                    sideOffset={0}
                    className="mr-2 mt-2 max-h-[24rem] min-w-96 max-w-xl overflow-y-auto rounded-md border
                        border-slate-200 bg-slate-100 p-4 text-sm font-normal text-slate-600
                        scrollbar-thin scrollbar-thumb-slate-300/75 scrollbar-track-rounded-full
                        scrollbar-thumb-rounded-full scrollbar-corner-rounded-full dark:border-slate-700
                        dark:bg-slate-900 dark:text-slate-400 dark:scrollbar-track-slate-800
                        dark:scrollbar-thumb-slate-700/75"
                    style={{
                        zIndex: 9999,
                    }}
                >
                    <div>{modelVariable.label}</div>
                    <div className="-m-4 mt-2">
                        <DefaultTableWrapper
                            header={"archetype"}
                            columnLabels={["setting", "values"]}
                        >
                            {archetypes?.length > 0 &&
                                archetypes
                                    .filter(
                                        (archetype) =>
                                            !!archetype?.archetype
                                                ?.inputDataObject[
                                                modelVariable.id
                                            ],
                                    )
                                    .map((archetype, index) => (
                                        <tr
                                            key={archetype.id}
                                            className={`table-row border-t border-slate-300/50 text-xs transition-all
                                            dark:border-slate-700/50 ${
                                                index % 2 == 0
                                                    ? "bg-slate-200/50 dark:bg-slate-900/50"
                                                    : ""
                                            }`}
                                        >
                                            <th
                                                className={
                                                    "table-col px-3 py-2"
                                                }
                                            >
                                                <span className="font-semibold">
                                                    {archetype.name}
                                                </span>
                                            </th>
                                            <td
                                                className={`border-l border-slate-200 px-3 py-2 text-right transition-all
                                                    dark:border-slate-800`}
                                            >
                                                {archetype?.archetype?.inputDataObject[
                                                    modelVariable.id
                                                ]?.setting_type
                                                    .replace("_", " ")
                                                    .toLowerCase()}
                                                {archetype?.archetype
                                                    ?.inputDataObject[
                                                    modelVariable.id
                                                ]?.setting_type ===
                                                InputDataSettingType[
                                                    "power interval"
                                                ]
                                                    ? ` (${
                                                          archetype?.archetype
                                                              ?.inputDataObject[
                                                              modelVariable.id
                                                          ]?.exponent
                                                      })`
                                                    : ""}
                                            </td>
                                            <td
                                                className={`border-l border-slate-200 px-3 py-2 text-right transition-all
                                                    dark:border-slate-800`}
                                            >
                                                {getFormFieldKeys(
                                                    archetype?.archetype
                                                        ?.inputDataObject[
                                                        modelVariable.id
                                                    ]?.setting_type,
                                                ).map((key) =>
                                                    key == "step_values" ? (
                                                        <div key={key}>
                                                            {archetype?.archetype?.inputDataObject[
                                                                modelVariable.id
                                                            ][key]?.map(
                                                                (item, i) => (
                                                                    <div
                                                                        key={i}
                                                                    >
                                                                        {modelVariable.data_type ===
                                                                        ModelVariableDataType.Number
                                                                            ? formatVariableValue(
                                                                                  modelVariable.unit,
                                                                                  item,
                                                                                  modelVariable.is_integer,
                                                                              )
                                                                            : (!!item).toString()}
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div key={key}>
                                                            {modelVariable.data_type ===
                                                            ModelVariableDataType.Number
                                                                ? formatVariableValue(
                                                                      modelVariable.unit,
                                                                      archetype
                                                                          ?.archetype
                                                                          ?.inputDataObject[
                                                                          modelVariable
                                                                              .id
                                                                      ][key],
                                                                      modelVariable.is_integer,
                                                                  )
                                                                : (!!archetype
                                                                      ?.archetype
                                                                      ?.inputDataObject[
                                                                      modelVariable
                                                                          .id
                                                                  ][
                                                                      key
                                                                  ]).toString()}
                                                        </div>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                        </DefaultTableWrapper>
                    </div>
                </PopoverContent>
            </Popover>
        </>
    );
};

export const ArchetypeInputComparison = React.memo(
    ArchetypeInputComparisonComponent,
);
