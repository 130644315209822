import React, { useCallback, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useAddSiblingBlockData } from "@/hooks";
import { ContentBlockShape } from "@/models/ContentBlock";
import { PlusIcon } from "@heroicons/react/24/solid";
import AddContentBlockModal from "./AddContentBlockModal";
import { useMobileViewState } from "../../mobile-wrapper/useMobileViewState";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

type AddSiblingProps = {
    position: "before" | "after";
    contentBlock: ContentBlockShape;
    isParentTopVisible: boolean;
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
};

export function AddSiblingButton({
    contentBlock,
    position,
    isParentTopVisible,
    editorSizes,
}: AddSiblingProps) {
    const { setAddSiblingBlockData, addSiblingBlockData } =
        useAddSiblingBlockData();
    const { device, orientation } = useMobileViewState();

    // handle what happens on key press
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setAddSiblingBlockData(null);
            }
        },
        [setAddSiblingBlockData]
    );

    //bind and unbind escape key
    useEffect(() => {
        // attach the event listener
        document.addEventListener("keydown", handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress, addSiblingBlockData]);

    const positionClasses = useMemo(() => {
        if (device) {
            return " top-14 fixed";
        }

        return isParentTopVisible ? " top-4 absolute" : " top-14 fixed";
    }, [isParentTopVisible, position, device, orientation]);

    const left = useMemo(() => {
        if (device) {
            return parseInt(editorSizes.leftMenu) + 60;
        }
        return isParentTopVisible ? 20 : parseInt(editorSizes.leftMenu) + 60;
    }, [isParentTopVisible, editorSizes, device]);

    const mobilePopoverWrapper = document.querySelector(
        "#add-content-block-menu-portal"
    );

    const topOrBottomOffsetClass = useMemo(() => {
        if (position === "before") {
            return isParentTopVisible ? "-top-6" : "-top-2";
        }
        return "-bottom-6";
    }, [position, isParentTopVisible]);

    return (
        <>
            <div
                className={`absolute z-100 uppercase flex gap-2 justify-center items-center px-3 py-1.5 rounded-lg transition-all  pointer-events-auto text-white ${topOrBottomOffsetClass}`}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {!addSiblingBlockData && (
                    <Link
                        href={sapienRoute(
                            "creator.design.add-content-block.modal",
                            {
                                contentBlockId: contentBlock.id,
                                position,
                            }
                        )}
                        only={["modalComponent", "modalPageProps"]}
                        className="btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-blue-600 rounded group cursor-pointer text-xs"
                    >
                        <span className="w-0 h-0 rounded bg-blue-800 absolute bottom-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>{" "}
                        <span className="w-full uppercase flex gap-2 justify-center items-center px-3 py-1.5 rounded-lg transition-colors duration-300 ease-in-out z-10">
                            <span>ADD CONTENT</span>{" "}
                            <PlusIcon className="h-4 w-4" />
                        </span>
                    </Link>
                )}
            </div>
        </>
    );
}
