import { ContentBlockShape, ContentBlockType } from "@/models";
import { AccordionAndTabsVariant } from "./AccordionAndTabsDropDowns";

export function transformAccordionAndTabsBlocks(
    contentBlock: ContentBlockShape,
    newVariant: AccordionAndTabsVariant,
): ContentBlockShape {
    switch (newVariant) {
        case "accordion":
            return {
                ...contentBlock,
                theme: {
                    ...contentBlock.theme,
                    variant: newVariant,
                    accordionType: "single",
                },
                contentBlocks: contentBlock.contentBlocks.map((block) => ({
                    ...block,
                    content_block_type: ContentBlockType.Accordion,
                    contentBlocks: block.contentBlocks.map((block) => ({
                        ...block,
                        content_block_type: ContentBlockType.AccordionItem,
                    })),
                })),
            };
        case "tabs":
            return {
                ...contentBlock,
                theme: {
                    ...contentBlock.theme,
                    variant: newVariant,
                },
                contentBlocks: contentBlock.contentBlocks.map((block) => ({
                    ...block,
                    content_block_type: ContentBlockType.Tabs,
                    contentBlocks: block.contentBlocks.map((block) => ({
                        ...block,
                        content_block_type: ContentBlockType["Tabs Content"],
                    })),
                })),
            };
    }

    return contentBlock;
}
