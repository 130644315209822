import { useModalQueryParams } from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import React from "react";
import { EditableManualChartSeries, fillableTypes, useChartState } from "../useChartState";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import ChartSeriesColorForm from "../ChartSeriesColorForm";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

type Props = {};

export default function ManualMultipleSeriesForm({}: Props) {
    const { queryParams } = useModalQueryParams("ChartWizard");

    const { setChartState, state } = useChartState();

    function save() {
        SapienInertia.post("admin.design.chart.store", {
            ...state.chartWizardSubmissionData,
            parentContentBlockId: queryParams.parentContentBlockId,
        } as (typeof LaravelRequestBodyShapes)["admin.design.chart.store"]);
    }

    return (
        <div className="flex flex-col p-6 space-y-4">
            <div>
                {!!state.chartWizardSubmissionData.id ? "Edit" : "Create"}{" "}
                {state.chartWizardSubmissionData.chartType} Chart
            </div>
            <div className="flex-col space-y-2">
                {state.chartWizardSubmissionData.series.map((s, i) => (
                    <div className="flex space-x-2" key={s.id || i}>
                        <ChartEntryRow
                            index={i}
                            chartSeries={s as EditableManualChartSeries}
                            updateSeries={(newSeries) => {
                                setChartState({
                                    chartWizardSubmissionData: {
                                        ...state.chartWizardSubmissionData,
                                        series: state.chartWizardSubmissionData.series.map(
                                            (s, j) => (j === i ? newSeries : s)
                                        ),
                                    },
                                });
                            }}
                        />
                    </div>
                ))}
            </div>
            <div>
                <button onClick={save}>Save</button>
            </div>
        </div>
    );
}

function ChartEntryRow({
    chartSeries,
    updateSeries,
}: {
    chartSeries: EditableManualChartSeries;
    index: number;
    updateSeries: (chartSeries: EditableManualChartSeries) => void;
}) {
    const datum = chartSeries?.chart_data?.[0];
    const colorProperty = fillableTypes.includes(chartSeries.chartType)
        ? "fill"
        : "stroke";

    return (
        <>
            <div className="w-1/3">
                <input
                    type="text"
                    className="w-full block rounded-md  sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                    value={datum?.label || ""}
                    placeholder="Enter a label for this series"
                    onChange={(e) => {}}
                />
            </div>
            <div className="w-1/2">
                <input
                    type="number"
                    className="w-full block rounded-md  sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                    value={datum?.y || ""}
                    placeholder="Enter a value for this series"
                    onChange={(e) => {}}
                />
            </div>
            <Popover>
                <PopoverTrigger>
                    {/* TODO: include this background color in tailwind styled system replacement */}
                    <button
                        className="rounded-full w-4 h-4"
                        style={{
                            backgroundColor:
                                chartSeries?.theme?.[colorProperty],
                        }}
                    ></button>
                </PopoverTrigger>
                <PopoverContent>
                    <ChartSeriesColorForm
                        updateEditedSeries={(color) => {}}
                        color={chartSeries?.theme?.[colorProperty] || "#000000"}
                        label={chartSeries.chartType}
                        index={0}
                    />
                </PopoverContent>
            </Popover>
        </>
    );
}
