import React, { useCallback, useRef, useState } from "react";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { Video } from "./getImageApiResponse";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";

export function PexelsVideoSelector({
    video,
    uploadedFileUrl,
    setUploadedFileUrl,
}: {
    video: Video;
    uploadedFileUrl: string;
    setUploadedFileUrl: (uploadedFileUrl: string) => void;
}) {
    const videoRef = useRef<HTMLVideoElement>();
    const [isPlaying, setIsPlaying] = useState(false);

    const selectedStatus = !uploadedFileUrl
        ? "none"
        : uploadedFileUrl !== video.src.small
          ? "unselected"
          : "selected";

    const videoElement = useGetElementAsync(`#video-${video.id}`) as HTMLVideoElement;
    const videoHandler = useCallback(
        (control: "play" | "pause") => {
            let retries = 10;
            if (!videoElement) {
                while (retries--) {
                    setTimeout(() => {
                        videoHandler(control);
                    }, 1000);
                }
            }

            if (control === "play") {
                videoElement?.play();
                setIsPlaying(true);
            } else if (control === "pause") {
                videoElement?.pause();
                setIsPlaying(false);
            }
        },
        [videoElement],
    );

    return (
        <div
            className="data-[selected-status=unselected]:opacity:50 group relative flex max-w-full
                items-start justify-start overflow-hidden rounded-md transition-all
                data-[orientation=landscape]:row-span-1 data-[orientation=portrait]:row-span-3
                data-[selected-status=unselected]:hidden data-[orientation=portrait]:max-w-72"
            data-selected-status={selectedStatus}
            data-orientation={
                video.height > video.width ? "portrait" : "landscape"
            }
        >
            <button
                className="absolute left-4 top-4 z-100 rounded-full bg-[#111928aa] p-2
                    group-hover:opacity-100 hover:bg-[#111928]"
                onClick={() => {
                    setUploadedFileUrl(video.link);
                    videoHandler(isPlaying ? "pause" : "play")
                }}
            >
                {isPlaying ? (
                    <PauseIcon className="h-5 w-5" />
                ) : (
                    <PlayIcon className="h-5 w-5" />
                )}
            </button>
            <button
                className="absolute right-4 top-4 z-100 rounded-full bg-[#111928aa] px-4 py-2
                    group-hover:opacity-100 hover:bg-[#111928]"
                onClick={() => {
                    setUploadedFileUrl(
                        selectedStatus === "selected" ? "" : video.link,
                    );
                }}
            >
                {selectedStatus === "selected" ? "X" : "Select"}
            </button>

            {selectedStatus === "selected" ? (
                <video
                    ref={videoRef}
                    controls={isPlaying}
                    src={video.link}
                    className="h-full w-full self-stretch"
                    id={`video-${video.id}`}
                />
            ) : (
                <img
                    src={video.src.original}
                    className="h-full w-full self-stretch"
                />
            )}
        </div>
    );
}
