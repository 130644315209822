import React from "react";
import { Provider } from "react-redux";
import { rootReducer as reducer } from "@/redux-state";
import { configureStore } from "@reduxjs/toolkit";

export function setupTestStore() {
    let refObj: {
        Wrapper: ({ children }) => React.ReactElement;
        store: any;
    } = {
        Wrapper: ({ children }) => <>{children}</>,
        store: null,
    };

    const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    ignoredActions: [
                    ],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ["payload.contentBlocks", "payload"],
                    // Ignore these paths in the state
                    ignoredPaths: ["contentBlockStore.contentBlocks"],
                },
            });
        },
    });
    refObj.store = store;
    refObj.Wrapper = function Wrapper({ children }: any) {
        return <Provider store={store}>{children}</Provider>;
    };

    return refObj;
}
