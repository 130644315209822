import React from "react";
import { usePythonValuesYearly, useWatchlistVariables } from "../state";
import { useFormatVariableValue } from "@/hooks";
import { ModelVariableDataType } from "@/models";

function FavoritesDrawerDisplayComponent({}: {}) {
    const watchlistVariables = useWatchlistVariables();
    const formatVariableValue = useFormatVariableValue();
    const pythonValuesYearly = usePythonValuesYearly();

    return (
        <div className="w-full text-sm">
            {!!watchlistVariables && (
                <>
                    {watchlistVariables?.length > 0 ? (
                        <div className="flex flex-wrap items-center gap-2">
                            {watchlistVariables.map((variable) => (
                                <div
                                    key={variable.id}
                                    className="flex items-center space-x-4 rounded-md bg-slate-50 px-4 py-2 transition-all
                                        dark:bg-slate-900"
                                >
                                    <span className="text-slate-600 transition-all dark:text-slate-400">
                                        {variable.label}
                                    </span>
                                    <span className="font-semibold tracking-wide">
                                        {Object.keys(pythonValuesYearly)
                                            ?.length > 0 &&
                                        pythonValuesYearly[variable.id]
                                            ?.length > 0
                                            ? variable.data_type ===
                                              ModelVariableDataType.Number
                                                ? formatVariableValue(
                                                      variable.unit,
                                                      pythonValuesYearly[
                                                          variable.id
                                                      ][
                                                          pythonValuesYearly[
                                                              variable.id
                                                          ]?.length - 1
                                                      ]?.numerical_value,
                                                      variable.is_integer,
                                                  )
                                                : pythonValuesYearly[
                                                      variable.id
                                                  ][
                                                      pythonValuesYearly[
                                                          variable.id
                                                      ]?.length - 1
                                                  ]?.boolean_value.toString()
                                            : "..."}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center opacity-75">
                            No starred variables to display
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export const FavoritesDrawerDisplay = React.memo(
    FavoritesDrawerDisplayComponent,
);
