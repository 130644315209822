import React from "react";
import { FormFieldDisplayProps, FormField } from "./FormField";

interface FormObjectProps {
    [index: string]: FormFieldDisplayProps;
}

export const FormFieldMap = (props: {
    formObject: any;
    formObjectProps: FormObjectProps;
    // submitFunction: (object: any) => any;
}) => {
    const { formObject, formObjectProps } = props;

    return (
        <>
            {formObject !== undefined &&
                formObjectProps !== undefined &&
                Object.keys(formObject)
                    .filter((key) => formObjectProps[key] !== undefined)
                    .map((key) => (
                        <FormField
                            key={key}
                            name={key}
                            formFieldType={formObjectProps[key].formFieldType}
                            label={formObjectProps[key].label}
                            validationRules={
                                formObjectProps[key].validationRules
                            }
                            step={formObjectProps[key].step}
                            options={formObjectProps[key].options}
                            updateOptions={formObjectProps[key].updateOptions}
                            contentBlockId={""}
                        />
                    ))}
        </>
    );
};
