import React from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@radix-ui/react-popover";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";
import { InteractiveDashboardChart } from "./InteractiveDashboardChart";
import { InteractiveDashboardCard } from "./InteractiveDashboardCard";
import { DashboardWidgetWrapper } from "../shared";
import {
    useArchetypes,
    useArchetypeTimespanMap,
    useChartBlocks,
    useComparisonArchetypeId,
    useDashboardDataCardVariables,
    useDataCardValuesMap,
    useEnableGetArchetypeValues,
    useModelTimespan,
    useSetComparisonArchetypeId,
    useSetEnableGetArchetypeValues,
} from "../state";
import { useDashboardChartValuesMap } from "../useInteractiveModule";
import { DataCardValueMapItem } from "../types";

const InteractiveSectionDashboardComponent = ({}: {}) => {
    const modelTimespan = useModelTimespan();
    const dashboardDataCardVariables = useDashboardDataCardVariables();
    const dataCardValuesMap = useDataCardValuesMap();
    const chartBlocks = useChartBlocks();
    const archetypes = useArchetypes();
    const archetypeTimespanMap = useArchetypeTimespanMap();
    const comparisonArchetypeId = useComparisonArchetypeId();
    const setComparisonArchetypeId = useSetComparisonArchetypeId();
    const enableGetArchetypeValues = useEnableGetArchetypeValues();
    const setEnableGetArchetypeValues = useSetEnableGetArchetypeValues();
    const { dashboardChartValuesMap, handleSetDashboardChartValuesMap } =
        useDashboardChartValuesMap();

    return (
        <div className="flex w-full flex-col space-y-6">
            <div
                className="flex flex-col justify-between space-x-0 space-y-2 sm:flex-row sm:items-center
                    sm:space-x-2 sm:space-y-0"
            >
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Dashboard"}
                </h1>
                <div className="flex items-center space-x-2 text-slate-600 dark:text-slate-400">
                    <select
                        className="min-w-[300px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                            text-slate-600 transition-all marker:border focus:border-slate-300
                            focus:outline-none focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20
                            dark:text-slate-400 dark:focus:border-slate-700"
                        value={comparisonArchetypeId ?? ""}
                        disabled={enableGetArchetypeValues}
                        onChange={(e) => {
                            setComparisonArchetypeId(e.target.value);
                            setEnableGetArchetypeValues(true);
                        }}
                    >
                        <option key={""} value={""} disabled>
                            {`Comparison Archetype`}
                        </option>
                        {archetypes?.map((archetype) => (
                            <option key={archetype.id} value={archetype.id}>
                                {`${archetype.name}`}
                                {!!archetypeTimespanMap &&
                                !!archetypeTimespanMap[archetype.id]
                                    ? ` (${archetypeTimespanMap[archetype.id]} years)`
                                    : ""}
                            </option>
                        ))}
                    </select>
                    <Popover>
                        <PopoverTrigger
                            style={{
                                display: "flex",
                                // height: editorSizes.toolbar,
                                alignItems: "center",
                            }}
                        >
                            <div
                                title={"Comparison Archetypes"}
                                className="relative inline-flex items-center text-sm font-medium"
                            >
                                <span className="sr-only">
                                    Comparison Archetypes
                                </span>
                                <Icon
                                    icon={info}
                                    size={20}
                                    className="flex h-8 items-center justify-center text-slate-400 transition-all
                                        hover:text-slate-600 focus:outline-none focus:ring-0 dark:text-slate-500
                                        dark:hover:text-slate-300"
                                    style={{ display: "flex" }}
                                />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent
                            align={"end"}
                            sideOffset={0}
                            className="mr-2 mt-2 min-w-[300px] max-w-[300px] rounded-md border border-slate-200
                                bg-slate-100 p-4 text-sm text-slate-600 dark:border-slate-700 dark:bg-slate-800
                                dark:text-slate-400"
                            style={{
                                zIndex: 9999,
                            }}
                        >
                            <div className="">
                                Select a model archetype to compare with your
                                results.
                                <br />
                                The difference on each metric data card
                                indicates the extent to which your final value
                                is
                                <span className="font-semibold text-green-500">{` higher `}</span>
                                or
                                <span className="font-semibold text-red-500">
                                    {` lower `}
                                </span>
                                than the final archetype value.
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <div className="space-y-4">
                <div
                    className={
                        "grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                    }
                >
                    {dashboardDataCardVariables?.length > 0 &&
                        dashboardDataCardVariables.map((variable) => (
                            <DashboardWidgetWrapper key={variable.id}>
                                <InteractiveDashboardCard
                                    key={variable.id}
                                    modelVariable={variable}
                                    dataCardValueMapItem={
                                        dataCardValuesMap[variable.id] ||
                                        ({} as DataCardValueMapItem)
                                    }
                                />
                            </DashboardWidgetWrapper>
                        ))}
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    {Object.keys(dashboardChartValuesMap)?.length > 0 &&
                        Object.values(chartBlocks)
                            ?.filter(
                                (chartBlock) =>
                                    chartBlock.variableIds?.length > 0,
                            )
                            .map((chartBlock) => (
                                <DashboardWidgetWrapper key={chartBlock.id}>
                                    <InteractiveDashboardChart
                                        key={chartBlock.id}
                                        modelTimespan={modelTimespan}
                                        chartBlock={chartBlock}
                                        chartValues={
                                            dashboardChartValuesMap[
                                                chartBlock.id
                                            ] ?? {}
                                        }
                                        handleSetDashboardChartValuesMap={
                                            handleSetDashboardChartValuesMap
                                        }
                                    />
                                </DashboardWidgetWrapper>
                            ))}
                </div>
            </div>
        </div>
    );
};

export const InteractiveSectionDashboard = React.memo(
    InteractiveSectionDashboardComponent,
);
