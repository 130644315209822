import React, { useState } from "react";
import { FormLabel } from "@/components";
import { ModelVariable } from "@/models";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useGetModelBlocksBySimulationId } from "./useGetModelBlocks";

type MultipleProps = {
    label?: string;
    handleSelectModelVariables: (modelVariables: ModelVariable[]) => void;
    modelVariables: ModelVariable[];
    selectedModelVariables: ModelVariable[];
};

type SingleProps = {
    label?: string;
    handleSelectModelVariable: (modelVariable: ModelVariable | null) => void;
    modelVariables: ModelVariable[];
    selectedModelVariables: ModelVariable[];
};

type Props = SingleProps | MultipleProps;

export default function ModelVariableComboBox(
    props: Props & { direction?: "down" | "up"; hasError?: boolean },
) {
    const {
        label,
        modelVariables,
        selectedModelVariables,
        direction = "down",
        hasError = false,
    } = props;

    const [query, setQuery] = useState("");
    const filteredModelVariables =
        query === ""
            ? modelVariables
            : modelVariables.filter((modelVariable) => {
                  return modelVariable.label
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });

    const { data: modelBlocks, isLoading } = useGetModelBlocksBySimulationId();
    const filteredModelBlocks = modelBlocks?.filter((modelBlock) => {
        return filteredModelVariables?.some(
            (variable) => variable.model_block_id === modelBlock.id,
        );
    });

    return (
        <div
            className="relative w-full"
            data-testid="model-variable-combobox-wrapper"
        >
            {label && (
                <FormLabel style={{ fontWeight: "500" }}>
                    {label || "Model Variable"}
                </FormLabel>
            )}
            <Combobox
                onChange={(modelVariableId) => {
                    if (
                        typeof (props as MultipleProps)
                            .handleSelectModelVariables === "function"
                    ) {
                        (props as MultipleProps).handleSelectModelVariables(
                            modelVariables.filter(
                                (_modelVariable) =>
                                    _modelVariable.id === modelVariableId ||
                                    selectedModelVariables
                                        .map(
                                            (selectedModelVariable) =>
                                                selectedModelVariable.id,
                                        )
                                        .includes(_modelVariable.id),
                            ),
                        );
                    } else if (
                        typeof (props as SingleProps)
                            .handleSelectModelVariable === "function"
                    ) {
                        (props as SingleProps).handleSelectModelVariable(
                            modelVariables.find(
                                (_modelVariable) =>
                                    _modelVariable.id === modelVariableId,
                            ),
                        );
                    }
                }}
                value={
                    !!selectedModelVariables?.length
                        ? selectedModelVariables[0]?.id
                        : null
                }
            >
                {direction === "up" && (
                    <div className="relative w-full" data-testid="up-box">
                        <Combobox.Options className="absolute z-100 -mt-2 flex max-h-[12rem] w-full -translate-y-full flex-col space-y-2 overflow-y-auto rounded-md border-gray-300 bg-gray-100 pl-0 pt-2 text-gray-200  shadow focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm">
                            {filteredModelBlocks?.map((modelBlock) => {
                                return (
                                    <div
                                        key={modelBlock.id}
                                        className="rounded-md bg-white shadow-sm"
                                    >
                                        <div className="p-2 font-bold text-black">
                                            {modelBlock.label}
                                        </div>
                                        {filteredModelVariables
                                            ?.filter(
                                                (variable) =>
                                                    variable.model_block_id ===
                                                    modelBlock.id,
                                            )
                                            .map(
                                                (
                                                    modelVariable: ModelVariable,
                                                ) => (
                                                    <Combobox.Option
                                                        data-testid={`model-variable-option-${modelVariable.id}`}
                                                        className={
                                                            "cursor-pointer list-none px-4 py-2 text-black transition-all hover:bg-blue-600 hover:text-white"
                                                        }
                                                        key={modelVariable.id}
                                                        value={modelVariable.id}
                                                    >
                                                        {modelVariable.label.substring(
                                                            0,
                                                            150,
                                                        )}
                                                    </Combobox.Option>
                                                ),
                                            )}
                                    </div>
                                );
                            })}
                            {!filteredModelBlocks?.length && (
                                <div className="">
                                    <div className="px-4 py-2 italic text-gray-600">
                                        {"No variables match your search"}
                                    </div>
                                </div>
                            )}
                        </Combobox.Options>
                    </div>
                )}
                <div className="relative w-full">
                    <Combobox.Input
                        className={`block w-full rounded-md  bg-white/10 focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                            hasError ? "border-pink-500" : "border-gray-300"
                        }`}
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(modelVariableId: string) => {
                            if (!modelVariableId) return query || "";
                            return modelVariables
                                .find(
                                    (_modelVariable) =>
                                        modelVariableId === _modelVariable.id,
                                )
                                ?.label.substring(0, 150);
                        }}
                        autoComplete="off"
                        placeholder="Select a model variable"
                    />
                    {!isLoading ? (
                        <>
                            <Combobox.Button
                                className={
                                    "absolute right-4 top-1/2 -translate-y-1/2"
                                }
                            >
                                <ChevronDownIcon className="h-4 w-4" />
                            </Combobox.Button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    (
                                        props as SingleProps
                                    ).handleSelectModelVariable(null);
                                    setQuery("");
                                }}
                                className="absolute right-8 top-1/2 -translate-y-1/2"
                            >
                                <XMarkIcon className="h-4 w-4" />
                            </button>
                        </>
                    ) : (
                        <div className="absolute right-4 top-1/2 -translate-y-1/2">
                            <svg
                                className="-ml-1 h-5 w-5 animate-spin text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>
                    )}
                </div>
                {direction === "down" && (
                    <div className="relative w-full">
                        <Combobox.Options
                            data-testid="down-option-list"
                            className="absolute z-100 mt-2 flex max-h-[16rem] w-full flex-col space-y-2 overflow-y-auto rounded-md border-gray-300 bg-gray-100 p-2 text-gray-200  shadow focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
                        >
                            {filteredModelBlocks?.map((modelBlock) => {
                                return (
                                    <div
                                        key={modelBlock.id}
                                        className="rounded-md bg-white shadow-sm"
                                    >
                                        <div className="px-4 py-2 font-bold text-black">
                                            {modelBlock.label}
                                        </div>
                                        {filteredModelVariables
                                            ?.filter(
                                                (variable) =>
                                                    variable.model_block_id ===
                                                    modelBlock.id,
                                            )
                                            .map(
                                                (
                                                    modelVariable: ModelVariable,
                                                ) => (
                                                    <Combobox.Option
                                                        data-testid={`model-variable-option-${modelVariable.id}`}
                                                        className={
                                                            "cursor-pointer list-none px-6 py-2 text-black transition-all hover:bg-blue-600 hover:text-white"
                                                        }
                                                        key={modelVariable.id}
                                                        value={modelVariable.id}
                                                    >
                                                        {modelVariable.label.substring(
                                                            0,
                                                            150,
                                                        )}
                                                    </Combobox.Option>
                                                ),
                                            )}
                                    </div>
                                );
                            })}
                            {!filteredModelBlocks?.length && (
                                <div className="">
                                    <div className="px-4 py-2 italic text-gray-600">
                                        {"No variables match your search"}
                                    </div>
                                </div>
                            )}
                        </Combobox.Options>
                    </div>
                )}
            </Combobox>
        </div>
    );
}
