import React from "react";
import ApplicationLogo from "../components/ApplicationLogo";
import { Link } from "@inertiajs/react";
// import { Tenant } from "../models";
// type Props = {
//     tenant: Tenant;
//     children: JSX.Element;
// };

export default function Guest({ children, tenant }) {
    return (
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 min-h-[100vh]">
            <div>
                <Link href="/">
                    <ApplicationLogo
                        logoPath={tenant?.logo_url}
                        className="w-20 h-20 fill-current text-gray-500"
                    />
                </Link>
            </div>

            <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                {children}
            </div>
        </div>
    );
}
