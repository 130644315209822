import { QuestionConfig, useDebounce } from "@/hooks";
import { Prompt, PromptType, RoundShape } from "@/models";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React, { useMemo, useState } from "react";
import { PromptWithRoundData } from "@/redux-state/modalComponentSlice";
import { EditorFormOption } from "@/styles/editor-components/StyledEditorComponents";

type Props = {
    prompts: PromptWithRoundData[];
    onSelectPrompt: (prompt: Prompt | null) => void;
    selectedPrompt: Prompt | null;
    promptType: PromptType;
    questionConfig: QuestionConfig;
    rounds: RoundShape[];
};

export default function QuestionBank({
    prompts,
    onSelectPrompt,
    promptType,
    questionConfig,
    selectedPrompt,
    rounds,
}: Props) {
    const [searchText, setSearchText] = useState("");
    const [selectedRoundTitle, setSelectedRoundTitle] = useState("");

    const filterText = useDebounce(searchText, 200);

    const filteredPrompts = useMemo(() => {
        return prompts.filter((prompt) => {
            if (prompt.prompt_type !== promptType) return false;

            if (
                selectedRoundTitle &&
                prompt.rounds.title !== selectedRoundTitle
            )
                return false;

            return (
                prompt.content
                    .toLowerCase()
                    .includes(filterText.toLowerCase()) ||
                prompt.options.some((option) =>
                    option.content
                        .toLowerCase()
                        .includes(filterText.toLowerCase())
                )
            );
        });
    }, [filterText, prompts, promptType, selectedRoundTitle]);

    return (
        <div className="text-white border-t border-[#374151] p-6">
            <div className="flex justify-between py-6">
                <div>
                    <h2>Select a question</h2>
                </div>
                <div className="w-full max-w-xl">
                    <div className="flex flex-col space-y-2 w-full">
                        <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                type="text"
                                name="search-text"
                                className="pl-10 block w-full rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0 text-white placeholder:text-gray-200"
                                placeholder="Search for a question"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                        <div className="relative mt-1 rounded-md shadow-sm w-full max-w-xl">
                            <select
                                value={selectedRoundTitle}
                                onChange={(e) =>
                                    setSelectedRoundTitle(e.target.value)
                                }
                                className="w-full max-w-xl block rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                            >
                                <EditorFormOption
                                    value=""
                                    className="bg-[#29303e]"
                                >
                                    {!!selectedRoundTitle
                                        ? "Show all rounds"
                                        : "Filter by round"}
                                </EditorFormOption>
                                {rounds.map((round) => (
                                    <EditorFormOption
                                        className="bg-[#29303e]"
                                        key={round.id}
                                        value={round.title}
                                    >
                                        {round.title}
                                    </EditorFormOption>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {!filteredPrompts?.length && (
                <div className="relative flex flex-col justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-300/50 p-12 text-center text-xl">
                    <QuestionMarkCircleIcon className="mx-auto h-12 w-12 text-gray-300 mb-2" />
                    <h3 className="text-gray-200">
                        No questions match your search criteria.
                    </h3>
                </div>
            )}
            {!!filteredPrompts?.length && (
                <>
                    <div className="grid md:grid-cols-3 p-4 gap-1 text-[#6B7280] uppercase">
                        <div>Question Text</div>
                        <div>Round</div>
                        <div>
                            {!questionConfig.hasHiddenOption && "Options"}
                            {(questionConfig.allowsNumericalData ||
                                questionConfig.requiresNumericalData) &&
                                "Metadata"}
                        </div>
                    </div>
                    {/* <div className="max-h-96 overflow-y-auto">  What do we do about the modal vertically overflowing the screen */}
                    {filteredPrompts.map((prompt) => (
                        <label
                            className={`grid md:grid-cols-3 p-4 gap-1 ${
                                prompt.id === selectedPrompt?.id &&
                                "bg-[#1F2A37]"
                            }`}
                            key={prompt.id}
                        >
                            <div className="flex">
                                <div className="mr-3">
                                    <input
                                        type="radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                                        checked={
                                            prompt.id === selectedPrompt?.id
                                        }
                                        onChange={() => {
                                            onSelectPrompt(prompt);
                                        }}
                                    />
                                </div>
                                <div>{prompt.content}</div>
                            </div>
                            <div>{prompt.rounds.title}</div>
                            <div>
                                {!questionConfig.hasHiddenOption && (
                                    <ul className="flex flex-col px-0">
                                        {prompt.options.map((option) => (
                                            <li
                                                key={option.id}
                                                className="list-none even:bg-white/5 p-2"
                                            >
                                                {option.content}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {(questionConfig.allowsNumericalData ||
                                    questionConfig.requiresNumericalData) && (
                                    <ul className="flex flex-col px-0">
                                        <li className="flex justify-between list-none p-2">
                                            <span className="text-bold">
                                                Minimum
                                            </span>
                                            {prompt.min}
                                        </li>
                                        <li className="flex justify-between list-none bg-white/5 p-2">
                                            <span className="text-bold">
                                                Maximum
                                            </span>
                                            {prompt.max}
                                        </li>
                                        <li className="flex justify-between list-none p-2">
                                            <span className="text-bold">
                                                Increment
                                            </span>
                                            {prompt.increment}
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </label>
                    ))}
                    {/* </div> */}
                </>
            )}
        </div>
    );
}
