import React, { useState } from "react";
import {
    ModelVariable,
    ModelVariableType,
    VariableRelationshipOperation,
} from "../../models";
import {
    useModelVariableStore,
    useVariableRelationshipStore,
} from "@/hooks/store";
import { displayVariableRelationshipOperation } from "@/util";

export const VariableTreeDisplay = (props: {
    modelVariable: ModelVariable;
    variableRelationshipOperation?: string;
    isCollapsible?: boolean;
}) => {
    const { modelVariable, variableRelationshipOperation, isCollapsible } =
        props;

    const { modelVariables } = useModelVariableStore();
    const { relationshipsByTargetId } = useVariableRelationshipStore();

    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <React.Fragment>
            <div className="flex flex-col justify-center">
                <div
                    className="flex w-full flex-row items-center justify-between"
                    style={{
                        background: isExpanded
                            ? "rgba(28,78,216,0.05)"
                            : "rgba(28,78,216,0.1)",
                        cursor: isCollapsible ? "pointer" : "default",
                        border: "1px solid rgba(28,78,216,0.25)",
                    }}
                    onClick={() => isCollapsible && setIsExpanded(!isExpanded)}
                >
                    <div
                        className="flex w-full items-stretch"
                        style={{
                            borderRight: "1px solid rgba(28,78,216,0.25)",
                        }}
                    >
                        {variableRelationshipOperation !== undefined && (
                            <div
                                className="flex min-w-[34px] items-center justify-center py-2 text-sm text-blue-900"
                                style={{
                                    borderRight:
                                        "1px solid rgba(28,78,216,0.25)",
                                }}
                            >
                                {displayVariableRelationshipOperation(
                                    variableRelationshipOperation as VariableRelationshipOperation,
                                ) || variableRelationshipOperation}
                            </div>
                        )}
                        <div className="flex items-center p-2">
                            {modelVariable.label}
                        </div>
                    </div>
                    <div
                        className={`whitespace-nowrap p-2 ${
                            modelVariable.variable_type ===
                            ModelVariableType["Selection Data"]
                                ? "font-bold"
                                : ""
                        }`}
                    >
                        {modelVariable.variable_type ===
                        ModelVariableType["Selection Data"]
                            ? "SELECTION DATA"
                            : modelVariable.variable_type}
                    </div>
                </div>
                {!!relationshipsByTargetId &&
                    !!relationshipsByTargetId[modelVariable.id] &&
                    isExpanded && (
                        <div className="ml-8 flex flex-col justify-center border-l-2 border-dotted border-l-[rgba(28,78,216,0.2)]">
                            {relationshipsByTargetId[modelVariable.id].map(
                                (sourceRelationship) => (
                                    <VariableTreeDisplay
                                        key={sourceRelationship.id}
                                        modelVariable={
                                            modelVariables[
                                                sourceRelationship
                                                    .source_variable_id
                                            ]
                                        }
                                        variableRelationshipOperation={
                                            sourceRelationship.operation_type ||
                                            ""
                                        }
                                        isCollapsible={
                                            !!relationshipsByTargetId[
                                                sourceRelationship
                                                    .source_variable_id
                                            ]
                                        }
                                    />
                                ),
                            )}
                        </div>
                    )}
            </div>
        </React.Fragment>
    );
};
