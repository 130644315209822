import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

type Props = {
    leftButtonHandler: () => void;
    rightButtonHandler?: () => void | null;
    advanceDisabled?: boolean;
    isLastStep: boolean;
    isFirstStep: boolean;
};

export default function WizardFooter({
    leftButtonHandler,
    rightButtonHandler,
    isLastStep,
    isFirstStep,
    advanceDisabled,
}: Props) {
    const hasRightButton = "function" === typeof rightButtonHandler;
    return (
        <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6 w-full">
            <div
                className={`flex justify-center items-center  ${
                    hasRightButton ? "order-0" : "order-2"
                }`}
            >
                <button
                    data-testid="wizard-left-button"
                    onClick={() => {
                        leftButtonHandler();
                    }}
                    className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                >
                    {isFirstStep ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <ArrowLeftIcon className="h-3 w-3 mr-2" />
                            Back
                        </>
                    )}
                </button>
            </div>

            <div className="flex justify-center items-center">
                {hasRightButton && (
                    <button
                        data-testid="wizard-right-button"
                        disabled={advanceDisabled}
                        onClick={() => {
                            rightButtonHandler();
                        }}
                        className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                    >
                        {!isLastStep ? (
                            <>
                                Next <ArrowRightIcon className="h-3 w-3 ml-2" />
                            </>
                        ) : (
                            <>Save</>
                        )}
                    </button>
                )}
            </div>
        </div>
    );
}
