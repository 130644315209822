import React from "react";
import { VariableSettingsNumericalProps } from "./props";

const SettingsVariableNumberInputComponent = ({
    rangeMin,
    rangeMax,
    rangeStep,
    isDisabled,
    value,
    handleOnChange,
    handleOnBlur,
}: VariableSettingsNumericalProps) => {
    return (
        <input
            autoFocus
            className="w-full min-w-[140px] rounded-md border-blue-600 bg-slate-50/50 px-2 py-1 text-sm
                font-semibold text-slate-700 transition-colors marker:border
                focus:border-blue-600 focus:ring-1 focus:ring-blue-600 dark:border-blue-700
                dark:bg-slate-800/20 dark:text-slate-300 dark:focus:border-blue-700
                dark:focus:ring-blue-700"
            type="number"
            min={rangeMin}
            max={rangeMax}
            step={rangeStep}
            disabled={isDisabled}
            value={value}
            onChange={(e) => {
                handleOnChange(Number(e.target.value));
            }}
            onBlur={() => {
                handleOnBlur();
            }}
        />
    );
};

export const SettingsVariableNumberInput = React.memo(
    SettingsVariableNumberInputComponent,
);
