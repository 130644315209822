import { ContentBlockShape, ContentBlockType } from "@/models";
import { RichTextVariant } from "./RichTextDropDown";

function findHeading(
    contentBlock: ContentBlockShape,
): ContentBlockShape | void {
    for (let i = 0; i < contentBlock?.contentBlocks.length; i++) {
        const child = contentBlock?.contentBlocks[i];
        if (
            child?.contentBlocks?.some(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Rich Text"],
            )
        ) {
            return child.contentBlocks[0];
        }
        return findHeading(child);
    }
}

function findParagraph(
    contentBlock: ContentBlockShape,
): ContentBlockShape | void {
    for (let i = 0; i < contentBlock?.contentBlocks.length; i++) {
        const child = contentBlock?.contentBlocks[i];
        if (
            child?.contentBlocks?.length > 1 &&
            child?.contentBlocks?.some(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Rich Text"],
            )
        ) {
            return child.contentBlocks[1];
        }
        return findParagraph(child);
    }
}

function transformToParagraphWithHeading(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    const heading = findHeading(contentBlock);
    const paragraph = findParagraph(contentBlock);

    if (!heading || !paragraph) {
        console.log("No heading or paragraph found", { heading, paragraph });
        return contentBlock;
    }

    return {
        ...contentBlock,
        theme: {
            flexDirection: "row",
            tailwindClasses: [
                ...contentBlock.theme?.tailwindClasses,
                "flex",
                "flex-row",
                "justify-start",
                "items-center",
                "gap-4",
                "w-full",
            ],
            variant: "paragraph with heading",
        },
        contentBlocks: [
            {
                ...contentBlock.contentBlocks[0],
                weight: 0,
                theme: {
                    tailwindClasses: [
                        "flex",
                        "flex-col",
                        "justify-start",
                        "items-start",
                        "gap-4",
                        "p-4",
                        "w-full"
                    ],
                },
                contentBlocks: [
                    {
                        ...heading,
                        theme: {
                            ...heading.theme,
                            tailwindClasses:
                                heading.theme.tailwindClasses?.filter(
                                    (className) => className !== "hidden",
                                ),
                        },
                    },
                    {
                        ...heading,
                        theme: {
                            ...heading.theme,
                            tailwindClasses:
                                heading.theme.tailwindClasses?.filter(
                                    (className) => className !== "hidden",
                                ),
                        },
                    },
                ],
            },
            {
                ...contentBlock.contentBlocks[1],
                theme: {
                    ...contentBlock.contentBlocks[1].theme,
                    tailwindClasses: [
                        "flex",
                        "flex-col",
                        "justify-center",
                        "items-start",
                        "gap-4",
                        "p-4",
                        "hidden",
                    ],
                },
            },
        ],
    };
}

function transformToImage(
    contentBlock: ContentBlockShape,
    imageSide: "left" | "right",
): ContentBlockShape {
    const imageBox = contentBlock.contentBlocks[1];
    return {
        ...contentBlock,
        theme: {
            ...contentBlock.theme,
            tailwindClasses: [
                ...contentBlock.theme?.tailwindClasses,
                "grid",
                "grid-cols-2",
                "gap-4",
                "p-4",
                "w-full",
            ],
            variant: `image on ${imageSide}`,
        },
        contentBlocks: [
            contentBlock.contentBlocks[0],
            {
                ...imageBox,
                theme: {
                    ...imageBox.theme,
                    tailwindClasses: [
                        ...imageBox.theme.tailwindClasses.filter(
                            (className) => className !== "hidden",
                        ),
                        imageSide === "left" ? "order-first" : "order-last",
                    ],
                },
            },
        ],
    };
}

export function transformRichTextContentBlock(
    contentBlock: ContentBlockShape,
    updatedVariant: RichTextVariant,
): ContentBlockShape {
    switch (updatedVariant) {
        case "paragraph":
        case "paragraph with heading":
            return transformToParagraphWithHeading(contentBlock);
        case "image on left":
            return transformToImage(contentBlock, "left");
        case "image on right":
            return transformToImage(contentBlock, "right");
        default:
            return contentBlock;
    }

    return contentBlock;
}
