import React, { FC, ReactNode, useMemo } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { useModelVariableStore } from "../../hooks/store";
import { displayPromptType } from "@/util";
import { ModelVariableType, PromptType } from "@/models";
import { useModelBuilderPage } from "@/inertia-utils/hooks";

export const ModelBuilderDashboard = () => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { rounds } = modelBuilderPageProps;

    const { modelVariables } = useModelVariableStore();

    const roundPieChartData = useMemo<{ name: string; value: number }[]>(() => {
        const roundsWithPromptsCount = rounds.filter(
            (round) => round.prompts?.length > 0
        ).length;
        return (
            rounds.length > 0 && [
                {
                    name: `Rounds with questions`,
                    value: roundsWithPromptsCount,
                },
                {
                    name: `Rounds without questions`,
                    value: rounds.length - roundsWithPromptsCount,
                },
            ]
        );
    }, [rounds]);

    const questionTypePieChartData = useMemo<
        { name: string; value: number }[]
    >(() => {
        let reducedObject = rounds?.reduce((map, round) => {
            if (round.prompts.length) {
                let newMap = { ...map };
                round.prompts.forEach((prompt) => {
                    if (newMap[prompt.prompt_type]) {
                        newMap = {
                            ...newMap,
                            [prompt.prompt_type]:
                                newMap[prompt.prompt_type] + 1,
                        };
                    } else {
                        newMap = {
                            ...newMap,
                            [prompt.prompt_type]: 1,
                        };
                    }
                });
                return newMap;
            } else {
                return map;
            }
        }, {});
        let data = Object.keys(reducedObject)
            .sort((a, b) => reducedObject[b] - reducedObject[a])
            .map((key) => {
                return {
                    name: displayPromptType(key as PromptType),
                    value: reducedObject[key],
                };
            });
        return data;
    }, [rounds]);

    const questionScopePieChartData = useMemo<
        { name: string; value: number }[]
    >(() => {
        let reducedObject = rounds?.reduce((map, round) => {
            if (round.prompts.length) {
                let newMap = { ...map };
                round.prompts.forEach((prompt) => {
                    if (newMap[prompt.scope]) {
                        newMap = {
                            ...newMap,
                            [prompt.scope]: newMap[prompt.scope] + 1,
                        };
                    } else {
                        newMap = {
                            ...newMap,
                            [prompt.scope]: 1,
                        };
                    }
                });
                return newMap;
            } else {
                return map;
            }
        }, {});
        let data = Object.keys(reducedObject).map((key) => {
            return {
                name: key.toLocaleUpperCase(),
                value: reducedObject[key],
            };
        });
        return data;
    }, [rounds]);

    const variableTypePieChartData = useMemo<
        { name: string; value: number }[]
    >(() => {
        let reducedObject = Object.values(modelVariables)?.reduce(
            (map, variable) => {
                let newMap = { ...map };
                if (newMap[variable.variable_type]) {
                    newMap = {
                        ...newMap,
                        [variable.variable_type]:
                            newMap[variable.variable_type] + 1,
                    };
                } else {
                    newMap = {
                        ...newMap,
                        [variable.variable_type]: 1,
                    };
                }
                return newMap;
            },
            {}
        );
        let data = Object.keys(reducedObject)
            .sort((a, b) => reducedObject[b] - reducedObject[a])
            .map((key) => {
                return {
                    name:
                        key === ModelVariableType["Selection Data"]
                            ? "SELECTION DATA"
                            : key,
                    value: reducedObject[key],
                };
            });
        return data;
    }, [modelVariables]);

    return (
        <div>
            <div className="mt-2 mb-4 flex justify-between items-end">
                <div className="text-xl">Dashboard</div>
            </div>
            <div>
                <div className="@container flex flex-wrap w-full">
                    <DashboardCard
                        header={"Rounds"}
                        subheader={`Count: ${rounds.length}`}
                    >
                        {!!roundPieChartData?.length && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "9px",
                                }}
                            >
                                <ResponsiveContainer width="100%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={roundPieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            outerRadius="70%"
                                            label={(entry) =>
                                                `${entry.name}: ${entry.value}`
                                            }
                                        >
                                            {roundPieChartData.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            [
                                                                "#f59e0b",
                                                                "#fcd34d",
                                                            ][index % 2]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </DashboardCard>
                    <DashboardCard header={"Questions"} subheader={"Type"}>
                        {!!questionTypePieChartData?.length && (
                            <div style={{ width: "100%", fontSize: "9px" }}>
                                <ResponsiveContainer width="100%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={questionTypePieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            outerRadius="70%"
                                            startAngle={0}
                                            label={(entry) =>
                                                `${entry.name}: ${entry.value}`
                                            }
                                        >
                                            {questionTypePieChartData.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            [
                                                                "#2caffe",
                                                                "#544fc5",
                                                                "#00e272",
                                                                "#fe6a35",
                                                                "#6b8abc",
                                                                "#d568fb",
                                                                "#2ee0ca",
                                                                "#fa4b42",
                                                                "#feb56a",
                                                                "#91e8e1",
                                                            ][index % 10]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </DashboardCard>
                    <DashboardCard header={"Questions"} subheader={"Scope"}>
                        {!!questionScopePieChartData?.length && (
                            <div style={{ width: "100%", fontSize: "9px" }}>
                                <ResponsiveContainer width="100%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={questionScopePieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            outerRadius="70%"
                                            label={(entry) =>
                                                `${entry.name}: ${entry.value}`
                                            }
                                        >
                                            {questionScopePieChartData.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            [
                                                                "#818cf8",
                                                                "#c7d2fe",
                                                                "#e0e7ff",
                                                            ][index % 3]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </DashboardCard>
                    <DashboardCard header={"Variables"} subheader={"Type"}>
                        {!!variableTypePieChartData?.length && (
                            <div style={{ width: "100%", fontSize: "9px" }}>
                                <ResponsiveContainer width="100%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={variableTypePieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            outerRadius="70%"
                                            startAngle={0}
                                            label={(entry) =>
                                                `${entry.name}: ${entry.value}`
                                            }
                                        >
                                            {variableTypePieChartData.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            [
                                                                "#2b908f",
                                                                "#e4d354",
                                                                "#f45b5b",
                                                                "#7cb5ec",
                                                                "#f7a35c",
                                                                "#90ed7d",
                                                                "#8085e9",
                                                                "#f15c80",
                                                                "#91e8e1",
                                                                "#434348",
                                                            ][index % 10]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </DashboardCard>
                </div>
            </div>
        </div>
    );
};

const DashboardCard: FC<{
    header: string;
    subheader: string;
    children?: ReactNode;
}> = ({ header, subheader, children }) => {
    return (
        <div className="w-[100%] @2xl:w-1/2 @6xl:w-1/3 p-2">
            <div className="bg-white rounded-md border border-gray-100 py-3 px-4 h-full">
                <div className="flex flex-row justify-between items-center border-b border-gray-200 mb-3 pb-3">
                    <div>
                        <h3 className="font-bold text-gray-800">{`${header}`}</h3>
                        <div className="text-gray-600 text-sm font-medium">
                            {`${subheader}`}
                        </div>
                    </div>
                    <div></div>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};
