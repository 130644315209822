import React from "react";
import { ChartType, ChartTypes } from "./ChartWizardReducer";
import ChartIcon from "./ChartIcon";

export default function ChartTypeSelector({
    setChartType,
    selectedChartType,
}: {
    setChartType: (chartType: ChartType) => void;
    selectedChartType: ChartType;
}) {
    console.log(ChartTypes)
    return (
        <div className="flex flex-col space-y-4 p-6 px-8 border-t border-[#374151]" id="chart-type-selector">
            <div>
                <h4>What kind of chart would you like to create?</h4>
            </div>
            <div className="flex flex-wrap justify-center gap-4 ml-[1%]">
                {Object.values(ChartTypes).map((chartType) => (
                    <div
                        data-testid={`chart-type-selector-${chartType}`}
                        key={chartType}
                        className={`w-[31%] max-w-[15rem] flex flex-col space-y-2 items-center p-2 bg-black/20 rounded-md transition-all ${
                            chartType === selectedChartType
                                ? "scale-105 hover:scale-105 bg-black/30 text-white"
                                : "scale-100 hover:scale-105 text-gray-400 hover:text-white"
                        } ${
                            selectedChartType && chartType !== selectedChartType
                                ? "opacity-50 scale-95 hover:scale-100 hover:opacity-100"
                                : ""
                        }`}
                        role="button"
                        onClick={() => {
                            setChartType(chartType);
                        }}
                    >
                        <ChartIcon chartType={chartType} />
                    </div>
                ))}
            </div>
        </div>
    );
}
