import { useEffect, useMemo, useState } from "react";
import {
    ModelVariable,
    ModelVariableDataType,
    ModelVariableType,
} from "@/models";
import { InputDataItem, InputDataSettingType } from "../types";
import { calculateRangeIncrement } from "../utils";

const useInputDataVariableProps = (modelVariable: ModelVariable) => {
    const isRangeVariable = useMemo(() => {
        return (
            modelVariable.data_type === ModelVariableDataType.Number &&
            modelVariable.minimum !== null &&
            modelVariable.maximum !== null &&
            Number(modelVariable.minimum) < Number(modelVariable.maximum)
        );
    }, [modelVariable]);

    const rangeStep = useMemo(() => {
        return !!isRangeVariable
            ? calculateRangeIncrement(
                  modelVariable.minimum,
                  modelVariable.maximum,
                  modelVariable.is_integer,
              )
            : undefined;
    }, [modelVariable, isRangeVariable]);

    const showNumericalFormFields = useMemo(() => {
        return (
            !!isRangeVariable &&
            !modelVariable.uses_time &&
            modelVariable.data_type === ModelVariableDataType.Number &&
            modelVariable.variable_type === ModelVariableType.Independent
        );
    }, [modelVariable, isRangeVariable]);

    return { rangeStep, showNumericalFormFields };
};

export const useInteractiveVariableSetting = ({
    modelVariable,
    inputDataItem,
}: {
    modelVariable: ModelVariable;
    inputDataItem: InputDataItem;
}) => {
    const { rangeStep, showNumericalFormFields } =
        useInputDataVariableProps(modelVariable);

    const [editedInputDataItem, setEditedInputDataItem] =
        useState<InputDataItem>();

    const [isBusy, setIsBusy] = useState(false);

    const [activeNumberInputKey, setActiveNumberInputKey] = useState(undefined);

    useEffect(() => {
        if (!!inputDataItem && (editedInputDataItem !== undefined || isBusy)) {
            setEditedInputDataItem(undefined);
            setActiveNumberInputKey(undefined);
            setIsBusy(false);
        }
    }, [inputDataItem]);

    const settingType = useMemo(() => {
        return editedInputDataItem !== undefined
            ? editedInputDataItem.setting_type
            : inputDataItem.setting_type;
    }, [inputDataItem, editedInputDataItem]);

    const isSingleValueSetting = useMemo(() => {
        return (
            settingType === InputDataSettingType.timespan ||
            settingType === InputDataSettingType.constant ||
            settingType === InputDataSettingType["boolean constant"]
        );
    }, [settingType]);

    const isDoubleValueSetting = useMemo(() => {
        return (
            settingType === InputDataSettingType["linear interval"] ||
            settingType === InputDataSettingType["power interval"]
        );
    }, [settingType]);

    const formFieldKeys = useMemo<(keyof InputDataItem)[]>(() => {
        return isSingleValueSetting
            ? ["numerical_value"]
            : isDoubleValueSetting
              ? ["initial", "final"]
              : ["step_values"];
    }, [isSingleValueSetting, isDoubleValueSetting, showNumericalFormFields]);

    return {
        rangeStep,
        editedInputDataItem,
        setEditedInputDataItem,
        isBusy,
        setIsBusy,
        settingType,
        showNumericalFormFields,
        formFieldKeys,
        activeNumberInputKey,
        setActiveNumberInputKey,
    };
};
