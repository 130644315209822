import { ReflectionJournalEntry } from "@/models";
import { TextType } from "@/modules/text-editor";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";

type Props = {
    reflectionJournalEntry: ReflectionJournalEntry;
};

export default function ReflectionJournalEntryDisplay({
    reflectionJournalEntry,
}: Props) {
    const editor = useEditor(
        {
            content: reflectionJournalEntry.content,
            extensions: [StarterKit, TextType],
            editable: false,
        },
        [reflectionJournalEntry]
    );

    return (
        <div className="flex flex-col space-y-2 py-4 border-b last:border-b-0">
            <h4 className="font-bold">{reflectionJournalEntry.title}</h4>
            <EditorContent
                editor={editor}
                className="border rounded-b-md p-4 min-h-[8rem] bg-white"
            />
        </div>
    );
}
