import React from "react";
import { CardWidgetWrapper } from "./WidgetWrappers";

const CarouselCardWrapperComponent = ({
    modelBlockLabel,
    children,
}: {
    modelBlockLabel: string;
    children: React.ReactNode;
}) => {
    return (
        <CardWidgetWrapper>
            <div className={"flex px-4 pt-4 text-lg font-semibold"}>
                {modelBlockLabel}
            </div>
            <div className="flex flex-col space-y-4">{children}</div>
        </CardWidgetWrapper>
    );
};

export const CarouselCardWrapper = React.memo(CarouselCardWrapperComponent);
