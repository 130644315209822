import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";
import { VariableValueWithLabel } from "./useDefaultVariableValues";


export const getDefaultVariableValues = async ({
    model_variable_ids,
    timeHorizonIds,
}: {
    model_variable_ids: string[];
    timeHorizonIds: string[];
}) => {

    if (!model_variable_ids?.length) {
        return Promise.resolve({ values: {} } as {
            values: { [index: string]: VariableValueWithLabel[] };
        });
    }
    const route = sapienRoute("creator.design.chart.variable-values.show");

    const { data } = await axios.post<{
        values: { [index: string]: VariableValueWithLabel[] };
    }>(
        route,
        {
            model_variable_ids: model_variable_ids.filter((id) => !!id),
            time_horizon_ids: timeHorizonIds,
        },
        { timeout: 10000 }
    );

    const { values } = data;
    return {
        values,
    };
};
