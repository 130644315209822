import React, { useMemo } from "react";
import { Form, FormGroup } from "@/components";
import { useEditorImageContentBlock, useUploadStore } from "@/hooks";

type Props = {
    imageUrl: string;
};
export default function FileSelector({ imageUrl }: Props) {
    const { uploadedFileUrl } = useEditorImageContentBlock();

    const { upload, uploadPercentage, uploading } = useUploadStore();
    const source = useMemo(() => {
        return uploadedFileUrl || imageUrl;
    }, [uploadedFileUrl, imageUrl]);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            upload(Array.from(files));
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragStart = (event) => {
        event.dataTransfer.setData("text/plain", event.target.id);
    };

    return (
        <div className="flex flex-1 flex-col items-start justify-start py-6">
            <div className="w-full">
                <FormGroup>
                    <div
                        className="flex flex-col items-center justify-center text-center"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <div
                            className={`relative mt-2 w-full ${source?.length ? "flex" : "hidden"}`}
                        >
                            {uploading && (
                                <div
                                    className="bg-[rgba(255, 255, 255, .7)] absolute bottom-0 left-0 right-0 top-0 flex
                                        flex-col items-center justify-center text-5xl"
                                >
                                    <div
                                        className="flex h-[48px] w-[48px] items-center justify-center rounded-full border-[3px]
                                            border-[#066ab9] bg-[#066ab9] text-white"
                                    >
                                        {uploadPercentage}%
                                    </div>
                                </div>
                            )}
                            <img
                                src={source}
                                className="relative m-auto block max-h-[24rem] max-w-[36rem] bg-contain bg-center
                                    bg-no-repeat shadow"
                            />
                        </div>
                        <input
                            type="file"
                            className="sr-only"
                            onChange={(event) => {
                                const files = Array.from(event.target.files);
                                upload(files);
                            }}
                            ref={inputRef}
                        />
                        <button
                            onClick={() => {
                                inputRef.current?.click();
                            }}
                            type="button"
                            className="mt-2 inline-flex w-96 items-center justify-center rounded-full bg-blue-700/80
                                px-5 py-1.5 text-center text-sm font-medium text-white transition-all
                                hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                disabled:cursor-not-allowed disabled:opacity-75"
                        >
                            Choose Image or drop here
                        </button>
                    </div>
                </FormGroup>
            </div>
        </div>
    );
}
