import React, { useEffect, useMemo, useState } from "react";
import { Prompt, PromptType, Round, Team } from "@/models";
import { DiscoverySelectionDisplay } from "./DiscoverySelectionDisplay";
import { ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import { UserIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { DiscoveryLayerObject } from "@/hooks/useDiscoveryLayer";
import { DiscoveryBoxPlot, DiscoveryPieChart } from "./DiscoveryCharts";
import { DiscoveryStatisticsObject } from "@/hooks/useDiscoveryStatistics";
import { displayPromptType } from "@/util";
import { groupBy } from "lodash";

export const DiscoveryQuestionDisplay = ({
    prompt,
    round,
    discoveryLayerObject,
    teams,
    questionDisplay,
    defaultToCharts,
    simpleDisplay,
}: {
    round: Round;
    prompt: Prompt;
    discoveryLayerObject: DiscoveryLayerObject | DiscoveryStatisticsObject;
    teams: Team[];
    questionDisplay: "tables" | "charts" | "";
    defaultToCharts?: boolean;
    simpleDisplay?: boolean;
}) => {
    const { selectionMap, teamCount, participantCount } = discoveryLayerObject;

    const [showCharts, setShowCharts] = useState(defaultToCharts);

    useEffect(() => {
        if (questionDisplay === "tables") {
            setShowCharts(false);
        } else if (questionDisplay === "charts") {
            setShowCharts(true);
        }
    }, [questionDisplay]);

    const groupedSelections = useMemo(() => {
        if (!!selectionMap[prompt.id]?.length && !!teams?.length) {
            if (prompt.scope === "Participant") {
                return groupBy(selectionMap[prompt.id], "user_id");
            } else {
                return groupBy(selectionMap[prompt.id], "team_id");
            }
        }
    }, [prompt, teams, selectionMap]);

    return (
        <div>
            <div className="flex flex-row items-center justify-between pb-4">
                <span className="flex items-center">
                    <span>
                        {prompt.scope === "Participant" ? (
                            <UserIcon className="h-5 w-5 text-gray-400" />
                        ) : (
                            <UserGroupIcon className="h-5 w-5 text-gray-400" />
                        )}
                    </span>
                    {!!groupedSelections && (
                        <span className="ml-2 rounded bg-indigo-100/50 px-2.5 py-0.5 text-xs font-medium">
                            {prompt.scope === "Team"
                                ? `${
                                      Object.keys(groupedSelections).length
                                  } of ${teamCount} teams`
                                : `${
                                      Object.keys(groupedSelections).length
                                  } of ${participantCount} participants`}
                        </span>
                    )}
                </span>
                <div className="flex items-center">
                    <span className="text-sm uppercase text-gray-400">
                        {displayPromptType(prompt.prompt_type)}
                    </span>
                    {prompt.prompt_type !== PromptType["Long Text"] &&
                        prompt.prompt_type !== PromptType["Short Text"] &&
                        !simpleDisplay && (
                            <button
                                type="button"
                                className={`ml-2 flex items-center rounded-md border border-neutral-200 bg-neutral-50
                                    bg-opacity-50 p-1 text-center text-xs text-neutral-800 hover:bg-neutral-200`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowCharts(!showCharts);
                                }}
                            >
                                {!showCharts ? (
                                    <ChartBarIcon className="h-4 w-4" />
                                ) : (
                                    <TableCellsIcon className="h-4 w-4" />
                                )}
                            </button>
                        )}
                </div>
            </div>
            <div className="text-md pb-4 font-semibold text-gray-800">
                {prompt.content}
            </div>
            {(!showCharts ||
                prompt.prompt_type == PromptType["Short Text"] ||
                prompt.prompt_type == PromptType["Long Text"]) && (
                <DiscoverySelectionDisplay
                    prompt={prompt}
                    teams={teams as Team[]}
                    selections={selectionMap[prompt.id]}
                    groupedSelections={groupedSelections}
                />
            )}
            {showCharts &&
                (prompt.prompt_type == PromptType["Numerical Slider"] ||
                    prompt.prompt_type == PromptType["Numerical Input"]) && (
                    <DiscoveryBoxPlot
                        data={
                            !!selectionMap[prompt.id]?.length
                                ? [
                                      {
                                          x: " ", // `${prompt.content.substring(0, 30)}`,
                                          y: selectionMap[prompt.id].map(
                                              (selection) =>
                                                  selection.numerical_value,
                                          ),
                                      },
                                  ]
                                : []
                        }
                        height={100}
                        domain={
                            prompt.min < prompt.max
                                ? {
                                      y: [prompt.min, prompt.max],
                                  }
                                : undefined
                        }
                    />
                )}
            {showCharts &&
                (prompt.prompt_type == PromptType["Multiple Choice"] ||
                    prompt.prompt_type == PromptType["Multiple Select"] ||
                    prompt.prompt_type == PromptType["Toggle Switch"] ||
                    prompt.prompt_type == PromptType["Dropdown List"]) && (
                    <DiscoveryPieChart
                        useColorScale={true}
                        data={prompt.options.reduce(
                            (map, option) => [
                                ...map,
                                {
                                    x: !!selectionMap[prompt.id].some(
                                        (selection) =>
                                            selection.option_id === option.id,
                                    )
                                        ? `${option.content.substring(
                                              0,
                                              30,
                                          )} (${
                                              selectionMap[prompt.id].filter(
                                                  (selection) =>
                                                      selection.option_id ===
                                                      option.id,
                                              ).length
                                          })`
                                        : " ",
                                    y: selectionMap[prompt.id].filter(
                                        (selection) =>
                                            selection.option_id === option.id,
                                    ).length,
                                    label: option.content,
                                    formattedValue:
                                        selectionMap[prompt.id].filter(
                                            (selection) =>
                                                selection.option_id ===
                                                option.id,
                                        ).length || "",
                                },
                            ],
                            [],
                        )}
                    />
                )}
        </div>
    );
};
