import { ContentBlockShape } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { defaultChartContainerTheme } from "@/styles";
import { defaultLineSeriesTheme } from "@/styles/themes/charts/themes";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const ChartTypes = {
    LINE: "line",
    AREA: "area",
    BAR: "bar",
    PIE: "pie",
    DOUGHNUT: "doughnut",
    MULTI_MODE: "multi-mode",
} as const;

export type ChartType = (typeof ChartTypes)[keyof typeof ChartTypes];
export const fillableTypes: ChartType[] = ["pie", "doughnut", "area", "bar"];
export const timeSeriesTypes: ChartType[] = [
    "line",
    "area",
    "bar",
    "multi-mode",
];

export type ChartSeries = {
    id: string;
    modelVariableId: string;
    label: string;
    theme: typeof defaultLineSeriesTheme;
    chartType: ChartType;
    chart_data?: FormattedChartValue[];
};
export type ChartWizardSubmissionData = {
    id?: string;
    series: EditableChartSeries[];
    parentContentBlockId: string;
    deletedSeries: number[];
    chartType: ChartType;
    theme: typeof defaultChartContainerTheme;
    timeHorizonIds: string[];
    maximumValue?: number;
};

const steps = [1, 2, 3];

type ChartSeriesWithoutId = Omit<ChartSeries, "id"> & { id?: string };
export type EditableModelChartSeries = Omit<ChartSeriesWithoutId, "chart_data"> & {
    modelVariableId: string;
};
export type EditableManualChartSeries = Omit<
    ChartSeriesWithoutId,
    "modelVariableId"
> & { chart_data: FormattedChartValue[] };
export type EditableChartSeries = EditableModelChartSeries | EditableManualChartSeries;

export interface State {
    editedIndex: number;
    editedItem: EditableChartSeries;
    step: number;
    chartWizardSubmissionData: ChartWizardSubmissionData;
    dataSource: "model" | "manual";
    contentBlocksWithModelDataSources?: ContentBlockShape[];
}

export const initialState: State = {
    editedIndex: -1,
    editedItem: {
        modelVariableId: "",
        label: "",
        theme: defaultLineSeriesTheme,
        chartType: "line",
    },
    contentBlocksWithModelDataSources: [],
    step: 1,
    chartWizardSubmissionData: {
        series: [],
        id: "",
        parentContentBlockId: "",
        chartType: undefined,
        deletedSeries: [],
        theme: defaultChartContainerTheme,
        timeHorizonIds: [],
    },
    dataSource: "model",
};

const chartStateAtom = atomWithStorage<State>("chartState", initialState);

export const useChartState = () => {
    const [state, setState] = useAtom(chartStateAtom);

    const setModelChartType = (chartType: ChartType) => {
        setState((oldState) => ({
            ...oldState,
            dataSource: "model",
            chartWizardSubmissionData: {
                ...oldState.chartWizardSubmissionData,
                chartType,
            },
        }));
    };

    const setManualChartType = (chartType: ChartType) => {
        const series = state.chartWizardSubmissionData.series?.length
            ? state.chartWizardSubmissionData.series.map((s) => ({
                  ...s,
                  chartType,
              }))
            : [
                {
                    label: "",
                    theme: defaultLineSeriesTheme,
                    chartType,
                }
            ];
        setState((oldState) => ({
            ...oldState,
            dataSource: "manual",
            chartWizardSubmissionData: {
                ...oldState.chartWizardSubmissionData,
                chartType,
            },
        }));
    };

    const setChartState = (newState: Partial<State>) => {
        setState((oldState) => ({ ...oldState, ...newState }));
    };

    console.log(state, "this is the state in useChartState")
    return {
        state,
        setChartState,
        chartStateAtom,
    };
};
