import React from "react";

const DashboardWidgetWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="interactive-widget rounded-md border border-orange-100 bg-orange-100/20
                transition-all dark:border-slate-800 dark:bg-slate-800/25"
        >
            {children}
        </div>
    );
};
export const DashboardWidgetWrapper = React.memo(
    DashboardWidgetWrapperComponent,
);

const CardWidgetWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="interactive-widget flex flex-col rounded-md border border-slate-200
                bg-slate-50/50 transition-all dark:border-slate-800 dark:bg-slate-800/25"
        >
            {children}
        </div>
    );
};
export const CardWidgetWrapper = React.memo(CardWidgetWrapperComponent);

const NestedCardWidgetWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="interactive-widget flex flex-col rounded-md border border-slate-200/50
                bg-slate-100/50 transition-all dark:border-slate-800/50 dark:bg-slate-900"
        >
            {children}
        </div>
    );
};
export const NestedCardWidgetWrapper = React.memo(
    NestedCardWidgetWrapperComponent,
);

const ScrollableWrapperComponent = ({
    children,
    containerRef,
}: {
    children: React.ReactNode;
    containerRef?: React.MutableRefObject<any>;
}) => {
    return (
        <div
            ref={containerRef}
            className="interactive-widget relative h-full w-full overflow-auto transition-all
                scrollbar-thin scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                dark:scrollbar-thumb-slate-700/75"
        >
            {children}
        </div>
    );
};
export const ScrollableWrapper = React.memo(ScrollableWrapperComponent);
