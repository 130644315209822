import React from "react";
import { Icon } from "react-icons-kit";
import { lineChart } from "react-icons-kit/fa/lineChart";
import { barChart } from "react-icons-kit/fa/barChart";
import { areaChart } from "react-icons-kit/fa/areaChart";
import { pieChart } from "react-icons-kit/fa/pieChart";
import { InteractiveModuleChartType } from "../types";

const iconObject: { [index in InteractiveModuleChartType]: any } = {
    line: lineChart,
    bar: barChart,
    area: areaChart,
    pie: pieChart,
};

const ChartCreationButtonMenuComponent = ({
    handleCreateChartBlock,
}: {
    handleCreateChartBlock: (
        interactiveModuleChartType: InteractiveModuleChartType,
    ) => Promise<void>;
}) => {
    return (
        <span className="flex items-center space-x-2 ">
            <span className="text-sm font-light text-slate-700 transition-all dark:text-slate-300">{`New Chart`}</span>
            {Object.keys(InteractiveModuleChartType).map((key) => (
                <button
                    key={key}
                    type="button"
                    className="inline-flex items-baseline rounded-md border border-blue-100 bg-blue-50
                        bg-opacity-50 px-2 py-1 text-center text-sm font-medium uppercase text-blue-700
                        transition-all hover:bg-blue-100 focus:outline-none focus:ring-0
                        dark:border-blue-900 dark:bg-slate-900 dark:hover:bg-slate-800"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCreateChartBlock(InteractiveModuleChartType[key]);
                    }}
                >
                    <Icon
                        icon={iconObject[InteractiveModuleChartType[key]]}
                        className="mr-2 h-5 w-5"
                    />
                    {InteractiveModuleChartType[key]}
                </button>
            ))}
        </span>
    );
};

export const ChartCreationButtonMenu = React.memo(
    ChartCreationButtonMenuComponent,
);
