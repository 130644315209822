import React, { useEffect, FC } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { DevTools } from "jotai-devtools";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
    useSelectedSimulation,
    useInitAuthFromInertiaProps,
    useSelectedRoundId,
} from "@/hooks";
import { useRoundStore, useTimeHorizonStore } from "@/hooks/store";
import {
    ContentBlock,
    ContentBlockShape,
    RoundShape,
    TimeHorizon,
    VariableValue,
} from "@/models";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { queryClient } from "./QueryClient";
import { useHandleSavedContentBlock } from "@/hooks/activeContentBlock";
import { useDesignLayerProps } from "./shared-props/useDesignLayerProps";
import { RoundContainer } from "./RoundContainer";
import { DesignContainer } from "./DesignContainer";

export const editorColors = {
    bgTop: "#15171a",
    bgLeft: "#1F2A37",
    bgPanel: "#111928",
};

export type LeftMenuSectionType = "create" | "layers" | "assets";
export type RightMenuSectionType = "style";

export type EditorContainerProps = SapienAdminPageProps & {
    savedBlock?: ContentBlock | ContentBlockShape;
    variableValues?: VariableValue[];
    currentPageId?: string;
    contentBlocks: ContentBlockShape[];
    topLevelContentBlockLayers: ContentBlockShape[];
    sapienErrors?: { [index: string]: any };
    rounds: RoundShape[];
};

const EditorContainerWrapped: FC<EditorContainerProps> = (props) => {
    const { simulation, timeHorizons, rounds, auth, round, tenant } = props;

    useInitAuthFromInertiaProps(auth, null, null, tenant);

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotRounds } = useRoundStore();
    const { gotTimeHorizons } = useTimeHorizonStore();
    const { setRoundId, selectedRoundId } = useSelectedRoundId();

    useEffect(() => {
        if (
            !!simulation &&
            !!simulation.id &&
            (!selectedSimulation || selectedSimulation.id !== simulation.id)
        ) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    useEffect(() => {
        if (rounds?.length > 0) {
            gotRounds(rounds);
        }
    }, [rounds]);

    useEffect(() => {
        gotTimeHorizons((timeHorizons as TimeHorizon[]) || []);
    }, [timeHorizons]);

    useEffect(() => {
        if (round && (!selectedRoundId || selectedRoundId !== round.id)) {
            setRoundId(round.id);
        } else if (!round) {
            setRoundId("");
        }
    }, [round]);

    return !selectedRoundId ? (
        <RoundContainer />
    ) : (
        <DesignContainer {...props} />
    );
};

const EditorContainer: FC<EditorContainerProps> = (props) => {
    useDesignLayerProps(props);
    //For some reason, calling this hook causes tests to freeze after most have been run.
    useHandleSavedContentBlock(props.savedBlock);
    // setDesignLayerProps(props);
    return (
        <QueryClientProvider client={queryClient}>
            <DevTools />
            <EditorContainerWrapped {...props} />
            <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
    );
};

export default EditorContainer;
