import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCursor } from "./util";

//TODO: figure out background for icon
const CursorContainer = styled.div<{
    cursor: string | false;
}>`
    height: 100%;
    overflow-y: hidden;
    > div {
        position: relative;
    }
    cursor: ${({ cursor }) => cursor || "inherit"};
    .add-content-block-button {
        cursor: ${({ cursor }) => cursor || "pointer"};
    }
    overflow-x: hidden;
    /* path,
    g {
        opacity: 1;
    } */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px rbga(0, 0, 0, 0.7);
    }
    *[data-content-block-type]:not(:has([data-content-block-id]:hover)):not(
            :has(.active-content-block)
        ):hover:not(.active-content-block):not(.fixed):not(.inbox) {
        /* cursor: ${({ cursor }) => cursor}; */

        &::after {
            content: attr(data-content-block-type);
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 0 0 0.25rem;
            color: white;
            background: rgb(30 64 175);
            padding: 0.25rem;
            text-align: center;
            z-index: 99;
            pointer-events: none;
            opacity: 0.75;
        }
    }
    *[data-content-block-id]:not(:has([data-content-block-id]:hover)):hover:not(
            .active-content-block
        ):not(:has(.active-content-block)):not(.fixed):not(.inbox) {
        opacity: 1;
        position: relative;
        /* cursor: ${({ cursor }) => cursor} !important; */

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-style: dashed;
            border-width: 2px;
            --tw-border-opacity: 0.75;
            border-color: rgb(209 213 219 / var(--tw-border-opacity));
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2.5rem;
            border-radius: inherit;
            z-index: 98;
            pointer-events: none;
        }
    }
`;

type MouseOverWrapperProps = {
    icon: JSX.Element;
    children: JSX.Element;
    className?: string;
    isForbidden?: boolean;
    additionalClassNameToWatch?: string;
};

export function MouseOverWrapper({
    icon,
    children,
    className,
}: MouseOverWrapperProps) {
    const [iconCursor, setIconCursor] = useState<string>("pointer");
    const [iconRef, setIconRef] = useState<JSX.Element | undefined>();

    useEffect(() => {
        if (iconRef) {
            getCursor(iconRef).then((cursor) => {
                setIconCursor(() => cursor);
            });
        } else {
            setIconCursor(() => "pointer");
        }
    }, [iconRef]);

    return (
        <CursorContainer
            className={`cursor-container ${className} transition-all`}
            cursor={iconCursor}
            scroll-region="scroll-region"
        >
            {!!icon && (
                <div
                    ref={() => {
                        setIconRef(() => icon);
                    }}
                    className="opacity-0 pointer-events-none icon-wrapper absolute -top-[1000] w-0 h-0"
                >
                    {icon}
                </div>
            )}
            {!icon && (
                <div
                    className="opacity-0 pointer-events-none"
                    ref={() => {
                        setIconRef(() => undefined);
                    }}
                ></div>
            )}
            {children}
        </CursorContainer>
    );
}
