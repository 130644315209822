import React, { useState } from "react";
import { Lesson } from "../types";
import { SapienInertia } from "@/inertia-utils/hooks";

type Props = {
    lesson: Pick<Lesson, "simulation_id" | "title" | "id" | "weight">;
};

export default function LessonForm({ lesson }: Props) {
    const [title, setTitle] = useState(lesson.title);

    function saveLesson(title: string) {
        if (!lesson.id) {
            SapienInertia.post(
                "e-learning.lessons.store",
                {
                    title,
                    simulation_id: lesson.simulation_id,
                    weight: lesson.weight,
                },
                {},
                {
                    preserveState: false,
                    preserveScroll: true,
                },
            );
        } else {
            SapienInertia.put(
                "e-learning.lessons.update",
                {
                    title,
                    id: lesson.id,
                    simulation_id: lesson.simulation_id,
                    weight: lesson.weight,
                },
                {
                    lesson: lesson.id,
                },
                {
                    preserveState: false,
                    preserveScroll: true,
                },
            );
        }
    }
    return (
        <div className="flex space-x-2 w-96 ">
            <input
                className="border-none rounded-md shadow w-full"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Lesson title..."
            />
            {title !== lesson?.title && (
                <>
                    <button onClick={() => saveLesson(title)}>Save</button>
                    <button onClick={() => setTitle(lesson.title)}></button>
                </>
            )}
        </div>
    );
}
