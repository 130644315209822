import React from "react";
import { ChartSeries, ChartType, fillableTypes } from "./ChartWizardReducer";
import { useModelVariableStore } from "@/hooks/store";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import ChartIcon from "./ChartIcon";
import { ModelVariable } from "@/models";

type Props = {
    chartSeries: ChartSeries[];
    addChartSeries: (chartSeries: ChartSeries) => void;
    editedIndex: number;
    setEditedIndex: (index: number) => void;
    chartType: ChartType;
};

function getLabel(
    series: ChartSeries,
    variables: { [index: string]: ModelVariable },
) {
    if (series.modelVariableId && !series.label) {
        return variables[series.modelVariableId]?.label;
    }
    return series.label;
}

export default function ChartSeriesList({
    chartSeries,
    chartType,
    addChartSeries,
    editedIndex,
    setEditedIndex,
}: Props) {
    const { modelVariables } = useModelVariableStore();

    const colorProperty = (series: ChartSeries) =>
        fillableTypes.includes(series?.chartType) ? "fill" : "stroke";

    return (
        <div className="flex w-full flex-col p-6">
            {chartSeries?.map((series, index) => (
                <div key={index} className="border-t border-[#374151] py-4">
                    {editedIndex === -1 && (
                        <div className="flex justify-between space-x-4">
                            <div className="flex items-center space-x-4">
                                <div className="h-12 w-12">
                                    <ChartIcon
                                        includeText={false}
                                        chartType={chartType}
                                        color={
                                            series.theme
                                                ? series.theme[
                                                      colorProperty(series)
                                                  ]
                                                : ""
                                        }
                                    />
                                </div>
                                <div>{getLabel(series, modelVariables)}</div>
                            </div>
                            <div>
                                <button
                                    className="rounded-full bg-gray-100 p-2 shadow-sm hover:scale-105 hover:bg-white"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditedIndex(index);
                                    }}
                                >
                                    <PencilIcon className="h-6 w-6 text-blue-600" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            ))}
            {}
            <div className="w-full">
                <button
                    className="flex items-center justify-between space-x-2 rounded-md border border-gray-300 bg-gray-700 px-4 py-2 capitalize text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    onClick={() => {
                        addChartSeries({
                            id: "",
                            label: "",
                            modelVariableId: "",
                            chartType: chartType,
                            theme: {},
                        });
                    }}
                >
                    {" "}
                    <PlusIcon className="ml-2 inline-block h-4 w-4" />
                    <span>
                        Add {chartType !== "multi-mode" ? chartType : "series"}{" "}
                    </span>
                </button>
            </div>
        </div>
    );
}
