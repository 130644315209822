import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared/ModalContainer";
import React, { useCallback, useEffect } from "react";
import { SaveableContainer } from "./TimelineAndDndForms";
import { useForm } from "@inertiajs/react";
import { FormInputColorPicker } from "@/styles/editor-components";

type Props = {
    container: SaveableContainer | null;
    setContainer: (container: SaveableContainer | undefined) => void;
};

export default function TimelineDragAndDropContainerModal({
    container,
    setContainer,
}: Props) {
    const { data, setData } = useForm(container);

    useEffect(() => {
        setData({
            ...container,
            column_count: container?.column_count || 6,
            row_count: container?.row_count || 1,
            theme: container?.theme || {
                backgroundColor: "#111928",
            },
        });
    }, [container]);

    const saveContainer = useCallback(() => {
        if (container?.id) {
            SapienInertia.put(
                "admin.design.drag-and-drop-prompt-containers.update",
                { ...data, theme: JSON.stringify(data.theme) },
                {
                    drag_and_drop_prompt_container: data.id,
                }
            );
        } else {
            SapienInertia.post(
                "admin.design.drag-and-drop-prompt-containers.store",
                { ...data, theme: JSON.stringify(data.theme) }
            );
        }
    }, [data]);

    return (
        <ModalContainer
            isModalOpen={!!container}
            setIsModalOpen={() => {
                setContainer(undefined);
            }}
            size="s"
            backgroundColor="#111928"
            styles={{ margin: "4em auto" }}
        >
            {!!data && (
                <div className="py-6 text-white">
                    <div className="flex justify-between p-6">
                        <label>
                            {data.id ? "Update" : "Create"}{" "}
                            {data.is_selection_container
                                ? "Selection"
                                : "Option"}{" "}
                            Container
                        </label>
                    </div>
                    <form
                        className="text-white border-t border-[#374151] p-6 flex flex-col space-y-4"
                        onSubmit={(e) => {
                            e.preventDefault();
                            saveContainer();
                        }}
                    >
                        <div className="flex flex-col space-y-2">
                            <label>Label</label>
                            <input
                                autoFocus={true}
                                type="text"
                                className={`w-full max-w-3xl block rounded-md border-gray-300 sm:text-xs bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                                value={data.label}
                                onChange={(e) =>
                                    setData("label", e.target.value)
                                }
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <label>Background color</label>
                            <FormInputColorPicker
                                value={data?.theme?.backgroundColor}
                                label={""}
                                themeObjectKey={""}
                                handleChange={function (_, value: any): void {
                                    setData("theme", {
                                        backgroundColor: value,
                                    });
                                }}
                            />
                        </div>
                        <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                            <div className="flex justify-center items-center">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setContainer(undefined);
                                    }}
                                    className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="flex justify-center items-center">
                                <button
                                    disabled={!data.label}
                                    className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </ModalContainer>
    );
}
