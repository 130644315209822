import React from "react";
import ModelVariableComboBox from "@/components/admin-components/model-variable-selection/ModelVariableComboBox";
import { ModelVariable } from "@/models";
import { ChartSeries, fillableTypes } from "./ChartWizardReducer";
import ChartSeriesColorForm from "./ChartSeriesColorForm";

type Props = {
    chartSeries: ChartSeries;
    updateEditedSeries: (chartSeries: ChartSeries) => void;
    modelVariables: ModelVariable[];
    selectedModelVariable: ModelVariable;
    editedIndex: number;
};

export default function ModelDataChartEditor({
    chartSeries,
    updateEditedSeries,
    modelVariables,
    selectedModelVariable,
    editedIndex,
}: Props) {
    const colorProperty = fillableTypes.includes(chartSeries?.chartType)
        ? "fill"
        : "stroke";

    return (
        <>
            <ChartSeriesColorForm
                index={editedIndex}
                color={chartSeries?.theme[colorProperty] || ""}
                label=""
                updateEditedSeries={(color) => {
                    updateEditedSeries({
                        ...chartSeries,
                        theme: { ...chartSeries.theme, [colorProperty]: color },
                    });
                }}
            />
            <div className="flex flex-col space-y-4">
                <ModelVariableComboBox
                    label={"Select a model variable for this series"}
                    handleSelectModelVariable={(
                        modelVariable: ModelVariable | null,
                    ) => {
                        updateEditedSeries({
                            ...chartSeries,
                            modelVariableId: modelVariable?.id || "",
                        });
                    }}
                    modelVariables={modelVariables}
                    selectedModelVariables={[selectedModelVariable]}
                />
                <div className="flex flex-col items-start justify-center space-y-2 ">
                    <label>Label</label>
                    <div className="flex w-full max-w-xl flex-col">
                        <input
                            value={chartSeries.label}
                            onChange={(e) => {
                                updateEditedSeries({
                                    ...chartSeries,
                                    label: e.target.value,
                                });
                            }}
                            type="text"
                            placeholder={
                                selectedModelVariable?.label ||
                                "Enter a label for this series"
                            }
                            className={`block w-full max-w-3xl rounded-md border border-gray-300 bg-white/10 p-2 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm`}
                        />
                        <p className="mt-2 text-xs">
                            If you leave this blank, it will default to the name
                            of the model variable you select.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
