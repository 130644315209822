import React from "react";

type Props = {
    isNew: boolean;
    setIsNew: (isNew: boolean) => void;
};

export default function QuestionOriginSelector({ isNew, setIsNew }: Props) {
    return (
        <div className="flex flex-col p-6 border-t border-[#374151]">
            <h2 className="text-white pb-4">
                Create new or select from question bank
            </h2>
            <div className="md:grid md:grid-cols-2 md:gap-2">
                <div
                    className={`flex items-center  px-6 py-4 ${
                        isNew && "bg-[#1F2A37]"
                    }`}
                >
                    <input
                        id="create-new"
                        type="radio"
                        value=""
                        name="create-new"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        checked={isNew}
                        onChange={(_) => setIsNew(true)}
                    />
                    <label
                        htmlFor="create-new"
                        className="ml-3.5 w-full text-sm font-medium flex flex-col"
                    >
                        Create New
                        <span className="mt-1 text-[#6B7280]">
                            Create an entirely new question
                        </span>
                    </label>
                </div>
                <div
                    className={`flex items-center px-6 py-4 ${
                        !isNew && "bg-[#1F2A37]"
                    }`}
                >
                    <input
                        id="copy-old-prompt"
                        type="radio"
                        value=""
                        name="copy-old-prompt"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        checked={!isNew}
                        onChange={(_) => setIsNew(false)}
                    />
                    <label
                        htmlFor="copy-old-prompt"
                        className="ml-3.5 w-full text-sm font-medium flex flex-col"
                    >
                        Select From Question Bank
                        <span className="mt-1 text-[#6B7280]">
                            Duplicate a question you've already designed
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
}
