import React from "react";

type Props = {
    handleChange: (value: string) => void;
    values: { label: string | React.ReactNode; value: string }[];

    selectedValue?: string;
};

export default function ButtonGroup({
    values,
    handleChange,
    selectedValue,
}: Props) {
    return (
        <span className="isolate inline-flex rounded-md">
            {values.map((option) => (
                <button
                    data-selected={selectedValue === option.value}
                    key={option.value}
                    type="button"
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    onClick={() => {
                        handleChange(option.value);
                    }}
                >
                    {option.label}
                </button>
            ))}
        </span>
    );
}
