import { useQuery } from "@tanstack/react-query";
import { getContentBlockById } from "./getContentBlockById";
import { ContentBlockShape } from "@/models";

export function useGetContentBlockById(
    id: string,
    selector?: (contentBlock: ContentBlockShape) => ContentBlockShape
) {
    return useQuery({
        queryKey: ["content-block", id],
        queryFn: () => getContentBlockById(id),
        enabled: !!id,
        select: typeof selector === "function" ? selector : undefined,
    });
}
