import React, { useEffect, useState } from "react";
import {
    FormGroup,
    FormLabel,
    FormInput,
    Subtitle2Span,
} from "../../components";
import { Participant, User } from "../../models";
import { useAddableParticipants } from "../../hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useRoundStore } from "@/hooks/store";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";

export const TeamParticipantForm = (props: {
    team: TeamWithProgressData;
    exit: () => void;
}) => {
    const { team, exit } = props;

    const { rounds } = useRoundStore();
    const { addableParticipants, getParticipantsToAdd } =
        useAddableParticipants();

    const [isBusy, setIsBusy] = useState(false);
    const [newParticipant, setNewParticipant] = useState<Participant | null>(
        null,
    );
    const [participantToAdd, setParticipantToAdd] =
        useState<Participant | null>(null);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        setNewParticipant(new Participant({}));
        getParticipantsToAdd(team.cohort_id);
    }, []);

    const handleUserChange = (prop: keyof User, value: any, user: User) => {
        const newUser = { ...user, ...{ [prop]: value } };
        setNewParticipant(newUser as Participant);
    };

    const saveUser = async (participant: User) => {
        // return SapienInertia.post(sapienRoute("admin.participants.add"), {
        //     ...participant,
        //     round_id: rounds[0]?.id,
        //     team_id: team.id,
        // });
        return SapienInertia.post(
            "admin.participant.create",
            {
                ...participant,
                round_id: rounds[0]?.id,
                team_id: team.id,
            },
            {},
            {
                preserveScroll: true,
            },
        );
    };

    const addParticipant = async (participant: User) => {
        return SapienInertia.post(
            "admin.participant.add",
            {
                ...participant,
                round_id: rounds[0]?.id,
                team_id: team.id,
            },
            {},
            {
                preserveScroll: true,
            },
        );
    };

    return (
        <div className="">
            <div className="mb-2 flex flex-row items-center text-[#3a4454]">
                <Subtitle2Span
                    as={!!newParticipant ? "span" : "button"}
                    style={{
                        marginRight: "1rem",
                        textDecoration: !!newParticipant && "underline",
                    }}
                    onClick={() =>
                        !newParticipant &&
                        setNewParticipant(new Participant({}))
                    }
                >
                    Create New User
                </Subtitle2Span>
                <Subtitle2Span
                    as={!newParticipant ? "span" : "button"}
                    style={{
                        textDecoration: !newParticipant && "underline",
                    }}
                    onClick={() => !!newParticipant && setNewParticipant(null)}
                >
                    Add Existing User
                </Subtitle2Span>
            </div>
            <div className="px-2">
                {!!newParticipant ? (
                    <form
                        className={"form"}
                        onSubmit={async (e) => {
                            e.preventDefault();
                            setIsBusy(true);
                            await saveUser(newParticipant);
                            setIsBusy(false);
                            setNewParticipant(null);
                            setParticipantToAdd(null);
                            setSearchValue("");
                            exit();
                        }}
                    >
                        <FormGroup>
                            <FormLabel>Name</FormLabel>
                            <FormInput
                                defaultValue={newParticipant.name}
                                className="form-control"
                                placeholder="Enter name"
                                onChange={(e) => {
                                    handleUserChange(
                                        "name",
                                        e.target.value,
                                        newParticipant,
                                    );
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Email</FormLabel>
                            <FormInput
                                defaultValue={newParticipant.email}
                                className="form-control"
                                placeholder="Enter email"
                                onChange={(e) => {
                                    handleUserChange(
                                        "email",
                                        e.target.value,
                                        newParticipant,
                                    );
                                }}
                            />
                        </FormGroup>
                        <button
                            type="submit"
                            value="Submit"
                            className="mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                            disabled={isBusy}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4 focus:ring-red-300"
                            disabled={isBusy}
                            onClick={(e) => {
                                e.preventDefault();
                                setNewParticipant(null);
                                setParticipantToAdd(null);
                                setSearchValue("");
                                exit();
                            }}
                        >
                            Cancel
                        </button>
                    </form>
                ) : (
                    <form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            setIsBusy(true);
                            await addParticipant(participantToAdd);
                            setIsBusy(false);
                            setNewParticipant(null);
                            setParticipantToAdd(null);
                            setSearchValue("");
                            exit();
                        }}
                    >
                        <div className="py-1">
                            <div className="relative w-full">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg
                                        className="h-5 w-5 text-gray-500 dark:text-gray-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="simple-search"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                                    defaultValue={searchValue || ""}
                                    placeholder="Search by name or email"
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            {!!addableParticipants &&
                                addableParticipants
                                    .filter(
                                        (p) =>
                                            (p.name || "")
                                                .toLowerCase()
                                                .replace(/\s+/g, "")
                                                .includes(
                                                    searchValue
                                                        .toLowerCase()
                                                        .replace(/\s+/g, ""),
                                                ) ||
                                            (p.email || "")
                                                .toLowerCase()
                                                .replace(/\s+/g, "")
                                                .includes(
                                                    searchValue
                                                        .toLowerCase()
                                                        .replace(/\s+/g, ""),
                                                ),
                                    )
                                    .map((p) => (
                                        <div
                                            key={p.id}
                                            style={{
                                                padding: "0.5em 1em",
                                                borderBottom:
                                                    "0.1px solid #545454",
                                            }}
                                            onClick={() =>
                                                setParticipantToAdd(
                                                    p as Participant,
                                                )
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            p.id ===
                                                                participantToAdd?.id &&
                                                            "bold",
                                                    }}
                                                >
                                                    {`${p.name}`}
                                                </span>
                                                <span>{`${p.email}`}</span>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                        {!!participantToAdd && (
                            <div className="mb-4 text-sm font-medium">
                                <div>{`Selected User:`}</div>
                                <div className="pl-1">{`${participantToAdd.name}`}</div>
                                <div className="pl-1">{`${participantToAdd.email}`}</div>
                            </div>
                        )}
                        <button
                            type="submit"
                            value="Submit"
                            className="mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                            disabled={isBusy || !participantToAdd}
                        >
                            Add
                        </button>
                        <button
                            type="button"
                            className="mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4 focus:ring-red-300"
                            disabled={isBusy}
                            onClick={(e) => {
                                e.preventDefault();
                                setNewParticipant(null);
                                setParticipantToAdd(null);
                                setSearchValue("");
                                exit();
                            }}
                        >
                            Cancel
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};
