import React from "react";
import { useContentBlockState } from "../useContentBlockState";
import LessonContentBlockEditor from "./LessonContentBlockEditor";

export default function ContentBlockWidgets() {
    const { parentContentBlocks } = useContentBlockState();

    return (
        <>
            {parentContentBlocks.map((block, index) => (
                <LessonContentBlockEditor
                    key={block.id}
                    parentBlock={block}
                    numberOfBlocks={parentContentBlocks.length}
                    index={index - 1}
                />
            ))}
        </>
    );
}
