import { ContentBlockShape } from "@/models";
import { Grid3X3, Menu, Square, SquareX } from "lucide-react";
import React from "react";

export type TailwindThemeContentBlockShape = ContentBlockShape & {
    theme: {
        tailwindClasses: string[];
    };
    contentBlocks: TailwindThemeContentBlockShape[];
};
type Props = {
    table: TailwindThemeContentBlockShape;
    td: TailwindThemeContentBlockShape;
    handleChange: (value: ContentBlockShape) => void;
};

export default function TableBorders({ table, td, handleChange }: Props) {
    return (
        <div className="flex flex-col gap-2 shadow-sm">
            <label htmlFor="accordionSpacing" className="text-xs">
                Borders
            </label>
            <span className="isolate inline-flex rounded-md">
                <button
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    data-selected={
                        !table.theme?.tailwindClasses?.includes("border") &&
                        !table?.contentBlocks?.[1]?.theme?.tailwindClasses?.includes(
                            "divide-y",
                        ) &&
                        !td?.theme?.tailwindClasses?.includes("border")
                    }
                    onClick={() => {
                        handleChange({
                            ...table,
                            theme: {
                                ...table.theme,
                                tailwindClasses: [
                                    ...(
                                        table.theme?.tailwindClasses || []
                                    ).filter(
                                        (className: string) =>
                                            !className.includes("border") &&
                                            !className.includes("divide"),
                                    ),
                                ],
                            },
                            contentBlocks: table.contentBlocks?.map((child) => {
                                return {
                                    ...child,
                                    theme: {
                                        ...child.theme,
                                        tailwindClasses: [
                                            ...(
                                                child.theme?.tailwindClasses ||
                                                []
                                            ).filter(
                                                (className: string) =>
                                                    !className.includes(
                                                        "border",
                                                    ) &&
                                                    !className.includes(
                                                        "divide",
                                                    ),
                                            ),
                                        ],
                                        contentBlocks: child.contentBlocks?.map(
                                            (tr) => {
                                                return {
                                                    ...tr,
                                                    contentBlocks:
                                                        tr.contentBlocks?.map(
                                                            (td) => {
                                                                return {
                                                                    ...td,
                                                                    theme: {
                                                                        ...td.theme,
                                                                        tailwindClasses:
                                                                            [
                                                                                ...(
                                                                                    td
                                                                                        .theme
                                                                                        ?.tailwindClasses ||
                                                                                    []
                                                                                ).filter(
                                                                                    (
                                                                                        className: string,
                                                                                    ) =>
                                                                                        !className.includes(
                                                                                            "border",
                                                                                        ),
                                                                                ),
                                                                            ],
                                                                    },
                                                                };
                                                            },
                                                        ),
                                                };
                                            },
                                        ),
                                    },
                                };
                            }),
                        });
                    }}
                >
                    <SquareX className="h-4 w-4" />
                </button>
                <button
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    data-selected={
                        !table?.contentBlocks?.[1]?.theme?.tailwindClasses?.includes(
                            "divide-y",
                        ) &&
                        !td?.theme?.tailwindClasses?.includes("border") &&
                        table?.theme?.tailwindClasses?.includes("border")
                    }
                    onClick={() => {
                        handleChange({
                            ...table,
                            theme: {
                                ...table.theme,
                                tailwindClasses: [
                                    ...(
                                        table.theme?.tailwindClasses || []
                                    ).filter(
                                        (className: string) =>
                                            !className.includes("border") &&
                                            !className.includes("divide"),
                                    ),
                                    "border",
                                ],
                            },
                            contentBlocks: table.contentBlocks?.map((child) => {
                                return {
                                    ...child,
                                    theme: {
                                        ...child.theme,
                                        tailwindClasses: [
                                            ...(
                                                child.theme?.tailwindClasses ||
                                                []
                                            ).filter(
                                                (className: string) =>
                                                    !className.includes(
                                                        "border",
                                                    ) &&
                                                    !className.includes(
                                                        "divide",
                                                    ),
                                            ),
                                        ],
                                    },
                                    contentBlocks: child.contentBlocks?.map(
                                        (tr) => {
                                            return {
                                                ...tr,
                                                contentBlocks:
                                                    tr.contentBlocks?.map(
                                                        (td) => {
                                                            return {
                                                                ...td,
                                                                theme: {
                                                                    ...td.theme,
                                                                    tailwindClasses:
                                                                        [
                                                                            ...(
                                                                                td
                                                                                    .theme
                                                                                    ?.tailwindClasses ||
                                                                                []
                                                                            ).filter(
                                                                                (
                                                                                    className: string,
                                                                                ) =>
                                                                                    !className.includes(
                                                                                        "border",
                                                                                    ),
                                                                            ),
                                                                        ],
                                                                },
                                                            };
                                                        },
                                                    ),
                                            };
                                        },
                                    ),
                                };
                            }),
                        });
                    }}
                >
                    <Square className="h-4 w-4" />
                </button>
                <button
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    data-selected={
                        table?.contentBlocks?.[1]?.theme?.tailwindClasses?.includes(
                            "divide-y",
                        ) && !td?.theme?.tailwindClasses?.includes("border-r")
                    }
                    onClick={() => {
                        handleChange({
                            ...table,
                            theme: {
                                ...table.theme,
                                tailwindClasses: [
                                    ...(
                                        table.theme?.tailwindClasses || []
                                    ).filter(
                                        (className: string) =>
                                            !className.includes("border") &&
                                            !className.includes("divide"),
                                    ),
                                    "divide-y",
                                ],
                            },
                            contentBlocks: table.contentBlocks?.map((child) => {
                                return {
                                    ...child,
                                    theme: {
                                        ...child.theme,
                                        tailwindClasses: [
                                            ...(
                                                child.theme?.tailwindClasses ||
                                                []
                                            ).filter(
                                                (className: string) =>
                                                    !className.includes(
                                                        "border",
                                                    ) &&
                                                    !className.includes(
                                                        "divide",
                                                    ),
                                            ),
                                            "divide-y",
                                        ],
                                    },
                                    contentBlocks: child.contentBlocks?.map(
                                        (tr) => {
                                            return {
                                                ...tr,
                                                contentBlocks:
                                                    tr.contentBlocks?.map(
                                                        (td) => {
                                                            return {
                                                                ...td,
                                                                theme: {
                                                                    ...td.theme,
                                                                    tailwindClasses:
                                                                        [
                                                                            ...(
                                                                                td
                                                                                    .theme
                                                                                    ?.tailwindClasses ||
                                                                                []
                                                                            ).filter(
                                                                                (
                                                                                    className: string,
                                                                                ) =>
                                                                                    !className.includes(
                                                                                        "border",
                                                                                    ),
                                                                            ),
                                                                        ],
                                                                },
                                                            };
                                                        },
                                                    ),
                                            };
                                        },
                                    ),
                                };
                            }),
                        });
                    }}
                >
                    <Menu className="h-4 w-4" />
                </button>

                <button
                    className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 ring-1 ring-inset ring-gray-300 transition-all first:rounded-l-md
                        last:rounded-r-md data-[selected='true']:bg-gray-100 hover:bg-gray-50 focus:z-10"
                    data-selected={
                        !table?.contentBlocks?.[1]?.theme?.tailwindClasses?.includes(
                            "divide-y",
                        ) &&
                        td?.theme?.tailwindClasses?.includes("border") &&
                        !table?.contentBlocks?.[1]?.theme?.tailwindClasses?.includes(
                            "border",
                        )
                    }
                    onClick={() => {
                        const newt = {
                            ...table,
                            theme: {
                                ...table.theme,
                                tailwindClasses: [
                                    ...(
                                        table.theme?.tailwindClasses || []
                                    ).filter(
                                        (className: string) =>
                                            !className.includes("border") &&
                                            !className.includes("divide"),
                                    ),
                                    'border'
                                ],
                            },
                            contentBlocks: table.contentBlocks?.map((child) => {
                                return {
                                    ...child,
                                    theme: {
                                        ...child.theme,
                                        tailwindClasses: [
                                            ...(
                                                child.theme?.tailwindClasses ||
                                                []
                                            ).filter(
                                                (className: string) =>
                                                    !className.includes(
                                                        "border",
                                                    ) &&
                                                    !className.includes(
                                                        "divide",
                                                    ),
                                            ),
                                        ],
                                    },
                                    contentBlocks: child.contentBlocks?.map(
                                        (tr) => {
                                            return {
                                                ...tr,
                                                contentBlocks:
                                                    tr.contentBlocks?.map(
                                                        (td) => {
                                                            return {
                                                                ...td,
                                                                theme: {
                                                                    ...td.theme,
                                                                    tailwindClasses:
                                                                        [
                                                                            ...(
                                                                                td
                                                                                    .theme
                                                                                    ?.tailwindClasses ||
                                                                                []
                                                                            ).filter(
                                                                                (
                                                                                    className: string,
                                                                                ) =>
                                                                                    className !==
                                                                                    "border",
                                                                            ),
                                                                            "border",
                                                                        ],
                                                                },
                                                            };
                                                        },
                                                    ),
                                            };
                                        },
                                    ),
                                };
                            }),
                        };
                        handleChange(newt);
                    }}
                >
                    <Grid3X3 className="h-4 w-4" />
                </button>
            </span>
        </div>
    );
}
