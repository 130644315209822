import React from "react";
import { useParticipantStore } from "./redux-state/hooks";
import { NestedContentBlockDisplay } from "./ContentBlocks";

export default function SimulationDisplay() {
    const {  rootContentBlocks } = useParticipantStore();

    // const rootContentBlocks = useMemo(() => {
    //     return contentBlockTree.filter(contentBlock => !contentBlock.parent_content_block_id)
    // }, [contentBlockTree]);

    return (
        <>
            {/* <pre>{JSON.stringify({ contentBlockTree }, null, 4)}</pre> */}
            {rootContentBlocks &&
                rootContentBlocks.map((parentBlock) => (
                    <NestedContentBlockDisplay
                        key={parentBlock.id}
                        contentBlock={parentBlock}
                        indexWithinParent={0}
                    />
                ))}
        </>
    );
}
