import React, { useEffect, useState } from "react";
import { ModalContainer } from "@/modules/shared";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useForm } from "@inertiajs/react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { buildCard } from "@/model-configs/content-block-layouts/CardLayouts";
import { defaultCardTheme } from "@/styles";
import { WidthOptions, widthToTailwindFraction } from "@/styles/ThemeObjects";
import {
    useFindContentBlockMethods,
    useModalQueryParams,
    useSelectedRoundId,
} from "@/hooks";
import { defaultCardDeckTheme } from "@/styles/themes/CardDeckTheme";

function CardDeckModalContent() {
    const { queryParams, closeModal } = useModalQueryParams("CardDeckModal");

    function cancel(e) {
        e.preventDefault();
        closeModal();
    }
    const { data, setData } = useForm({
        numberOfCardsToCreate: 4,
        parentContentBlockId: "",
        cardWidth: "0.25",
    });

    const [contentBlocks, setContentBlocks] = useState<ContentBlockShape[]>([]);

    useEffect(() => {
        if (data?.numberOfCardsToCreate && data?.cardWidth) {
            setContentBlocks(
                [...Array(Number(data?.numberOfCardsToCreate))].map(
                    (_, idx) => ({
                        ...buildCard(),
                        weight: idx,
                        theme: {
                            ...defaultCardTheme,
                            width: data?.cardWidth,
                        },
                    })
                )
            );
        }
    }, [data]);

    const { selectedRoundId } = useSelectedRoundId();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();

    return (
        <form
            data-testid="card-deck-modal-form"
            className="py-6 text-white"
            onSubmit={(e) => {
                e.preventDefault();
                const parent = getContentBlockWithChildren(
                    queryParams.parentContentBlockId
                );
                SapienInertia.post(
                    "admin.design.store.card-deck",
                    {
                        content_block_type: ContentBlockType["Card Deck"],
                        contentBlocks,
                        position: "before",
                        theme: JSON.stringify(defaultCardDeckTheme),
                        parent_content_block_id:
                            queryParams.parentContentBlockId,
                        round_id: selectedRoundId,
                        weight: parent?.contentBlocks?.length || 0,
                    } as (typeof LaravelRequestBodyShapes)["admin.design.update.card-deck"],
                    {},
                    {
                        only: ["locationInfo", "savedBlock", "errors"],
                        onSuccess() {
                            closeModal();
                        },
                    }
                );
            }}
        >
            <div className={`px-6 flex flex-col space-y-4`}>
                <div className="flex flex-col justify-between space-y-2">
                    <label>How many cards would you like to create?</label>
                    <div className="flex space-x-2">
                        <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                            <button
                                disabled={
                                    Number(data?.numberOfCardsToCreate) <= 1
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        Number(data?.numberOfCardsToCreate) <= 1
                                    )
                                        return;
                                    setData(
                                        "numberOfCardsToCreate",
                                        Number(data?.numberOfCardsToCreate) - 1
                                    );
                                }}
                                data-action="decrement"
                                className=" border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] text-center w-12 font-semibold text-md text-white md:text-basecursor-default flex items-center rounded-l"
                            >
                                <span className="m-auto text-2xl font-thin">
                                    −
                                </span>
                            </button>
                            <input
                                type="number"
                                className="border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] text-center w-24 font-semibold text-md text-white md:text-basecursor-default flex items-center"
                                name="custom-input-number"
                                value={data?.numberOfCardsToCreate}
                                min={1}
                                max={9}
                            />
                            <button
                                disabled={
                                    Number(data?.numberOfCardsToCreate) >= 9
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        Number(data?.numberOfCardsToCreate) >= 9
                                    )
                                        return;
                                    setData(
                                        "numberOfCardsToCreate",
                                        Number(data?.numberOfCardsToCreate) + 1
                                    );
                                }}
                                data-action="increment"
                                className="border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] text-center w-12 font-semibold text-md text-white md:text-basecursor-default flex items-center rounded-r"
                            >
                                <span className="m-auto text-2xl font-thin">
                                    +
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between space-y-2">
                    <label>How wide should the cards be by default?</label>
                    <select
                        className="rounded border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] text-center w-32 font-semibold text-md text-white md:text-basecursor-default flex items-center"
                        value={data?.cardWidth}
                        onChange={(e) => {
                            setData("cardWidth", e.target.value);
                        }}
                    >
                        {WidthOptions.map((option) => (
                            <option
                                className="bg-[#29303e]"
                                value={option.value}
                                key={option.label}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-wrap w-full py-4">
                    {contentBlocks.map((_, idx) => (
                        <div
                            key={idx}
                            className={`flex h-48 p-2 ${
                                data?.cardWidth !== "100%"
                                    ? `w-${
                                          widthToTailwindFraction[
                                              data.cardWidth
                                          ]
                                      }`
                                    : "w-full"
                            }`}
                        >
                            <div className="rounded-lg bg-white/10 w-full h-full p-2">
                                <div className="flex flex-col items-start h-full justify-center space-y-5">
                                    <div className="w-12 bg-white/10 h-12 rounded-full"></div>
                                    <div className="flex flex-col space-y-3 w-full">
                                        <div className="w-full bg-white/10 h-6 rounded-md "></div>
                                        <div className="w-3/4 bg-white/10 h-6 rounded-md "></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                    <div className="flex justify-center items-center">
                        <button
                            data-testid="card-deck-modal-cancel-button"
                            onClick={cancel}
                            className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex justify-center items-center">
                        <button
                            data-testid="card-deck-modal-save-button"
                            type="submit"
                            className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default function CardDeckModal({
    currentPageId,
}: {
    currentPageId?: string;
}) {
    const { isAtModalUrl, closeModal } = useModalQueryParams("CardDeckModal");

    function cancel() {
        closeModal();
    }
    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={cancel}
            size="xl"
            backgroundColor="#111928"
            classNames="transform-y-1/2"
        >
            {isAtModalUrl && <CardDeckModalContent />}
        </ModalContainer>
    );
}
