import React, { useMemo } from "react";
import { H6Span, Form, FormGroup } from "@/components";
import { useUploadStore } from "@/hooks";
import { ContentBlockShape } from "@/models";

type Props = {
    videoContentBlock: ContentBlockShape;
    videoUrl: string;
    uploadPercentage: number | null;
};

export default function VideoSelector({
    videoContentBlock,
    videoUrl,
    uploadPercentage,
}: Props) {
    const {
        uploadVideo,
        uploading,
        uploadPercentage: localUploadPercentage,
    } = useUploadStore();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const uploadPct = useMemo(() => {
        if (
            (!localUploadPercentage && uploadPercentage === null) ||
            uploadPercentage === 100
        )
            return false;

        return ((uploadPercentage || 0) + localUploadPercentage) / 2 || 0;
    }, [uploadPercentage, uploading, localUploadPercentage]);

    return (
        <div className="relative flex flex-col items-center justify-center py-2">
            <div className="flex flex-row items-center justify-between py-2">
                <H6Span>Upload Video</H6Span>
            </div>
            {!!videoContentBlock && (
                <>
                    <div className="flex flex-1 flex-col items-center justify-center py-2">
                        <div className="pb-1">
                            <Form>
                                <FormGroup>
                                    <div className="flex flex-row flex-wrap items-center">
                                        <div
                                            className="relative flex flex-col w-full min-w-[400px] flex-wrap items-center justify-center p-8"
                                            style={{
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                            }}
                                        >
                                            {!!videoUrl && (
                                                <video
                                                    className="max-h-[36rem] w-auto"
                                                    controls={true}
                                                    src={videoUrl}
                                                />
                                            )}
                                            <div className="absolute flex w-full flex-col items-center justify-center">
                                                {uploadPct !== false && (
                                                    <div className="max-h-[36rem] w-full overflow-hidden rounded-xl bg-white p-0 shadow-sm">
                                                        <div className="relative flex h-4 w-full items-center justify-center">
                                                            <div
                                                                className="absolute bottom-0 left-0 top-0 w-full rounded-lg bg-blue-200 transition-all
                                                                    duration-700"
                                                                style={{
                                                                    width: `${uploadPct}%`,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {uploadPct === false && (
                                                <div className="flex">
                                                    <input
                                                        data-testid="file-selector"
                                                        type="file"
                                                        className="sr-only"
                                                        onChange={(event) => {
                                                            const files =
                                                                Array.from(
                                                                    event.target
                                                                        .files,
                                                                );
                                                            uploadVideo(
                                                                files,
                                                                videoContentBlock.id,
                                                            );
                                                        }}
                                                        ref={inputRef}
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            inputRef.current?.click();
                                                        }}
                                                        type="button"
                                                        className="mt-2 inline-flex w-96 items-center justify-center rounded-full bg-blue-700/80
                                                            px-5 py-1.5 text-center text-sm font-medium text-white transition-all
                                                            hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                                            disabled:cursor-not-allowed disabled:opacity-75"
                                                    >
                                                        Choose Video or drop
                                                        here
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
