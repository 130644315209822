import React from "react";
// import { getSandboxData } from "./getSandboxData";
// import { SandboxQuestions } from "./SandboxQuestions";
import { CohortShape, Round, Simulation, TeamUserRoundPivot } from "@/models";
// import { useQuery } from "@tanstack/react-query";
import SandboxVariablesDisplay, {
    SandboxModelComparisonProps,
} from "./SandboxVariablesDisplay";

type CohortWithTeamUserRounds = CohortShape & {
    team_user_rounds: (TeamUserRoundPivot & {
        user: { name: string };
        id: string;
    })[];
};
type Props = {
    cohorts: { [index: string]: CohortWithTeamUserRounds };
    simulation: Simulation;
    rounds: Round[];
    baseModelCalculations?: SandboxModelComparisonProps;
};

export default function SandboxDisplay({
    // cohorts,
    // simulation,
    // rounds,
    baseModelCalculations,
}: Props) {
    // const [selectedTeamUserRoundId, setSelectedTeamUserRoundId] = useState("");

    // const filteredCohorts = useMemo(() => {
    //     return Object.values(cohorts)?.filter(
    //         (cohort) => !!cohort?.team_user_rounds?.length,
    //     );
    // }, [cohorts]);

    // const { data, isFetching } = useQuery({
    //     queryKey: [
    //         "comparisonData",
    //         {
    //             simulationSlug: simulation.slug,
    //             teamUserRoundId: selectedTeamUserRoundId,
    //         },
    //     ],
    //     queryFn: async () => {
    //         return await getSandboxData({
    //             simulationSlug: simulation.slug,
    //             teamUserRoundId: selectedTeamUserRoundId,
    //         });
    //     },
    //     select: (data) => data,
    //     initialData: {
    //         targetValues: {},
    //         pythonValues: {},
    //         time: 0,
    //         success: true,
    //         targetVariables: [],
    //         selections: [],
    //     },
    //     enabled: !!selectedTeamUserRoundId,
    //     refetchInterval: false,
    //     refetchOnWindowFocus: false,
    // });

    return (
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <h1 className="pb-8 pt-4 text-2xl">Model Sandbox</h1>
            <div>
                {!!baseModelCalculations && (
                    <>
                        <h3 className="mb-4 rounded-md bg-indigo-50 p-3">
                            <span className="font-bold">{`FULL BASE MODEL  `}</span>
                            Python API calculation time (seconds):{" "}
                            {Number(baseModelCalculations?.time).toFixed(6)}
                        </h3>
                        <SandboxVariablesDisplay
                            targetVariables={
                                baseModelCalculations?.targetVariables
                            }
                            pythonValues={baseModelCalculations?.pythonValues}
                            targetValues={baseModelCalculations?.targetValues}
                        />
                    </>
                )}
            </div>
            {/* <div>
                <SandboxQuestions
                    rounds={rounds}
                    selections={data?.selections}
                />
            </div> */}
            {/* <div className="flex flex-col">
                <div className="mb-2 mt-2 text-lg">Source of Selections</div>
                <select
                    value={selectedTeamUserRoundId || ""}
                    onChange={(e) => setSelectedTeamUserRoundId(e.target.value)}
                    className="mb-2 mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900
                        ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                        sm:leading-6"
                >
                    <option key={""} value={""} disabled={false}>
                        None
                    </option>
                    {filteredCohorts.map((cohort) => (
                        <optgroup key={cohort.id} label={cohort.title}>
                            {cohort.team_user_rounds.map((teamUserRound) => (
                                <option
                                    key={teamUserRound.id}
                                    value={teamUserRound.id}
                                >
                                    {teamUserRound?.user?.name}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </select>
                <div
                    className={`flex flex-col space-y-4 ${isFetching ? "animate-pulse" : "opacity-100"}`}
                >
                    {!!data && data.success === false && !isFetching && (
                        <div className="pb-2 text-red-600">
                            There's no data for this user
                        </div>
                    )}
                    {!!data?.time && !isFetching && (
                        <h3 className="pb-2 text-indigo-700">
                            It took the python api {data.time} seconds to run
                            the calculations
                        </h3>
                    )}
                    {!!isFetching ? (
                        <table className="mt-4 table table-auto animate-pulse rounded-md">
                            <tbody>
                                {[...Array(5).keys()].map((i) => (
                                    <tr
                                        className="odd:bg-gray-200 even:bg-gray-400"
                                        key={i}
                                    >
                                        <th className="p-4"></th>
                                        <td className="p-2"></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <SandboxVariablesDisplay
                            targetVariables={data?.targetVariables}
                            pythonValues={data?.pythonValues}
                            targetValues={data?.targetValues}
                        />
                    )}
                </div>
            </div> */}
        </div>
    );
}
