import React, { useCallback, useEffect, useState } from "react";
import { BaseModel, TimeHorizon } from "@/models";
import { FormLabel, FormOption, FormSelect } from "@/components";

const CombinedRangeSelectMenu = ({
    rangeValue,
    rangeMin,
    rangeMax,
    rangeOnChange,
    selectValue,
    selectOnChange,
    selectOptionArray,
    displaySelectOption,
}: {
    rangeValue: number;
    rangeMin: number;
    rangeMax: number;
    rangeOnChange: (value: number) => void;
    selectValue: string;
    selectOnChange: (value: string) => void;
    selectOptionArray: BaseModel[];
    displaySelectOption: (optionModel: BaseModel) => string;
}) => {
    return (
        <>
            <input
                type="range"
                className="range-lg mb-4 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                value={rangeValue}
                min={rangeMin}
                max={rangeMax}
                step={1}
                onChange={(e) => rangeOnChange(Number(e.target.value))}
            />
            <FormSelect
                value={selectValue}
                onChange={(e) => selectOnChange(e.target.value)}
            >
                <FormOption key={""} value={""} disabled={true} />
                {selectOptionArray.map((option: BaseModel) => (
                    <FormOption key={option.id} value={option.id}>
                        {displaySelectOption(option)}
                    </FormOption>
                ))}
            </FormSelect>
        </>
    );
};

export const TimeHorizonSelectionFormElement = ({
    timeHorizons,
    selectedTimeHorizons,
    setSelectedTimeHorizons,
    isSingleTimeHorizonSelection,
}: {
    timeHorizons: TimeHorizon[];
    selectedTimeHorizons: TimeHorizon[];
    setSelectedTimeHorizons: (timeHorizons: TimeHorizon[]) => void;
    isSingleTimeHorizonSelection: boolean;
}) => {
    const [sortedSelectedTimeHorizons, setSortedSelectedTimeHorizons] =
        useState<TimeHorizon[]>([]);

    useEffect(() => {
        if (!!selectedTimeHorizons?.length) {
            setSortedSelectedTimeHorizons(
                [...selectedTimeHorizons].sort(
                    (a, b) => a.time_index - b.time_index,
                ),
            );
        } else {
            setSortedSelectedTimeHorizons([]);
        }
    }, [selectedTimeHorizons]);

    const [timeIndexRangeObject, setTimeIndexRangeObject] = useState<{
        min: number;
        max: number;
    }>({ min: 0, max: 0 });

    useEffect(() => {
        if (!!timeHorizons?.length) {
            let sortedTimeIndexValues = [
                ...timeHorizons.map((timeHorizon) => timeHorizon.time_index),
            ].sort((a, b) => a - b);
            setTimeIndexRangeObject({
                min: sortedTimeIndexValues[0],
                max: sortedTimeIndexValues[sortedTimeIndexValues.length - 1],
            });
        }
    }, [timeHorizons]);

    const displayTimeHorizonOption = (timeHorizon: BaseModel) =>
        (timeHorizon as TimeHorizon).time_index.toString();

    const handleSingleRangeOnChange = useCallback(
        (value: number) =>
            setSelectedTimeHorizons(
                timeHorizons.filter(
                    (timeHorizon) => timeHorizon.time_index === value,
                ),
            ),
        [timeHorizons, setSelectedTimeHorizons],
    );

    const handleSingleSelectOnChange = useCallback(
        (value: string) =>
            setSelectedTimeHorizons(
                timeHorizons.filter((timeHorizon) => timeHorizon.id === value),
            ),
        [timeHorizons, setSelectedTimeHorizons],
    );

    const handleMultipleOnChangeHelper = useCallback(
        (minValue: number, maxValue: number) => {
            setSelectedTimeHorizons(
                timeHorizons.filter(
                    (timeHorizon) =>
                        minValue <= timeHorizon.time_index &&
                        timeHorizon.time_index <= maxValue,
                ),
            );
        },
        [timeHorizons, setSelectedTimeHorizons],
    );

    const getMinTimeIndex = useCallback(() => {
        return sortedSelectedTimeHorizons?.length
            ? sortedSelectedTimeHorizons[0].time_index
            : 0;
    }, [sortedSelectedTimeHorizons]);

    const getMaxTimeIndex = useCallback(() => {
        return sortedSelectedTimeHorizons?.length
            ? sortedSelectedTimeHorizons[sortedSelectedTimeHorizons.length - 1]
                  .time_index
            : 0;
    }, [sortedSelectedTimeHorizons]);

    const getTimeHorizonById = useCallback(
        (id: string) => {
            return timeHorizons.find((timeHorizon) => timeHorizon.id === id);
        },
        [timeHorizons],
    );

    const getHandleMultipleRangeOnChange = useCallback(
        (rangeSide: "min" | "max") => {
            if (rangeSide === "min") {
                return (value: number) => {
                    let previousMax = getMaxTimeIndex();
                    let minValue = value;
                    let maxValue = Math.max(value, previousMax);
                    handleMultipleOnChangeHelper(minValue, maxValue);
                };
            } else {
                return (value: number) => {
                    let previousMin = getMinTimeIndex();
                    let minValue = Math.min(value, previousMin);
                    let maxValue = value;
                    handleMultipleOnChangeHelper(minValue, maxValue);
                };
            }
        },
        [handleMultipleOnChangeHelper, getMinTimeIndex, getMaxTimeIndex],
    );

    const getHandleMultipleSelectOnChange = useCallback(
        (rangeSide: "min" | "max") => {
            if (rangeSide === "min") {
                return (value: string) => {
                    let previousMax = getMaxTimeIndex();
                    let minTH = getTimeHorizonById(value);
                    let minValue = minTH.time_index;
                    let maxValue = Math.max(minValue, previousMax);
                    handleMultipleOnChangeHelper(minValue, maxValue);
                };
            } else {
                return (value: string) => {
                    let previousMin = getMinTimeIndex();
                    let maxTH = getTimeHorizonById(value);
                    let maxValue = maxTH.time_index;
                    let minValue = Math.min(maxValue, previousMin);
                    handleMultipleOnChangeHelper(minValue, maxValue);
                };
            }
        },
        [
            handleMultipleOnChangeHelper,
            getMinTimeIndex,
            getMaxTimeIndex,
            getTimeHorizonById,
        ],
    );

    return isSingleTimeHorizonSelection ? (
        <div className="mt-4">
            <FormLabel
                style={{
                    fontWeight: "500",
                    marginBottom: "4px",
                }}
            >
                {"Time Horizon"}
            </FormLabel>
            <CombinedRangeSelectMenu
                rangeValue={
                    !!sortedSelectedTimeHorizons?.length
                        ? sortedSelectedTimeHorizons[0].time_index
                        : 0
                }
                rangeMin={timeIndexRangeObject.min}
                rangeMax={timeIndexRangeObject.max}
                rangeOnChange={handleSingleRangeOnChange}
                selectValue={
                    !!sortedSelectedTimeHorizons?.length
                        ? sortedSelectedTimeHorizons[0].id
                        : ""
                }
                selectOnChange={handleSingleSelectOnChange}
                selectOptionArray={timeHorizons}
                displaySelectOption={displayTimeHorizonOption}
            />
        </div>
    ) : (
        <div className="mt-4">
            <FormLabel
                style={{
                    fontWeight: "500",
                    marginBottom: "4px",
                }}
            >
                {"Time Horizons"}
            </FormLabel>
            <div className="flex flex-row items-center">
                <div className="w-6/12 p-1">
                    <span>{"Min"}</span>
                    <CombinedRangeSelectMenu
                        rangeValue={
                            !!sortedSelectedTimeHorizons?.length
                                ? sortedSelectedTimeHorizons[0].time_index
                                : 0
                        }
                        rangeMin={timeIndexRangeObject.min}
                        rangeMax={timeIndexRangeObject.max}
                        rangeOnChange={getHandleMultipleRangeOnChange("min")}
                        selectValue={
                            !!sortedSelectedTimeHorizons?.length
                                ? sortedSelectedTimeHorizons[0].id
                                : ""
                        }
                        selectOnChange={getHandleMultipleSelectOnChange("min")}
                        selectOptionArray={timeHorizons}
                        displaySelectOption={displayTimeHorizonOption}
                    />
                </div>
                <div className="w-6/12 p-1">
                    <span>{"Max"}</span>
                    <CombinedRangeSelectMenu
                        rangeValue={
                            !!sortedSelectedTimeHorizons?.length
                                ? sortedSelectedTimeHorizons[
                                      sortedSelectedTimeHorizons.length - 1
                                  ].time_index
                                : 0
                        }
                        rangeMin={timeIndexRangeObject.min}
                        rangeMax={timeIndexRangeObject.max}
                        rangeOnChange={getHandleMultipleRangeOnChange("max")}
                        selectValue={
                            !!sortedSelectedTimeHorizons?.length
                                ? sortedSelectedTimeHorizons[
                                      sortedSelectedTimeHorizons.length - 1
                                  ].id
                                : ""
                        }
                        selectOnChange={getHandleMultipleSelectOnChange("max")}
                        selectOptionArray={timeHorizons}
                        displaySelectOption={displayTimeHorizonOption}
                    />
                </div>
            </div>
        </div>
    );
};
