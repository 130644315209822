import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockTemplateShape } from "@/models/ContentBlockTemplate";
import axios from "axios";

export async function getTemplatesToAddContentBlock(
    contentBlockId: string,
    position: "before" | "after"
) {
    const route = sapienRoute("creator.design.add-content-block.modal", {
        contentBlockId,
        position,
    });

    const { data } = await axios.get<{
        weight: number;
        templates: {
            user: ContentBlockTemplateShape[];
            simulation: ContentBlockTemplateShape[];
        };
        roundId: string;
    }>(route);

    return data;
}
