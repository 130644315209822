import React from "react";
import { InputDataSettingType } from "../types";
import { VariableSettingsDisplayProps } from "./props";
import { useInteractiveVariableSetting } from "./useInteractiveVariableSetting";
import { SettingsVariableSlider } from "./SettingsVariableSlider";
import { SettingsVariableStepInterval } from "./SettingsVariableStepInterval";
import {
    getSettingsVariableChartProps,
    SettingsVariableChart,
} from "./SettingsVariableChart";
import { ModelVariableDataType } from "@/models";
import { FormSwitch } from "../shared";
import { useColorModeClass } from "../useInterfaceState";

const InputSettingBooleanKeys = ["boolean constant", "boolean series"];
const InputSettingNumericalKeys = [
    "linear interval",
    "power interval",
    "step interval",
    "constant",
];

const CompactVariableSettingsTableRowComponent = ({
    modelVariable,
    inputDataItem,
    setInputDataItem,
    showSettingsCharts,
    index,
    formatVariableValue,
}: VariableSettingsDisplayProps) => {
    const colorModeClass = useColorModeClass();

    const {
        rangeStep,
        editedInputDataItem,
        setEditedInputDataItem,
        isBusy,
        setIsBusy,
        settingType,
        showNumericalFormFields,
        formFieldKeys,
    } = useInteractiveVariableSetting({ modelVariable, inputDataItem });

    const chartProps = getSettingsVariableChartProps({
        modelVariableMin: modelVariable.minimum,
        modelVariableMax: modelVariable.maximum,
        inputDataItem,
        settingType,
    });

    return (
        <tr
            key={modelVariable.id}
            className={`table-row border border-slate-300 bg-slate-50 transition-all
            dark:border-slate-600 dark:bg-slate-900 ${
                index % 2 == 0
                    ? "bg-opacity-10 dark:bg-opacity-40"
                    : "bg-opacity-75 dark:bg-opacity-10"
            }`}
        >
            <th className={"px-1 py-1"}>
                <span className="text-xs font-normal">
                    {modelVariable.label}
                </span>
            </th>
            <td
                className={
                    "border-l border-slate-300 px-1 py-1 transition-all dark:border-slate-600"
                }
                colSpan={
                    settingType === InputDataSettingType["power interval"]
                        ? 1
                        : 2
                }
            >
                <select
                    className={`block w-full min-w-[140px] appearance-none rounded-sm border-0 border-slate-300
                        bg-transparent px-2 py-1 text-xs font-normal text-slate-700 transition-all
                        focus:border-0 focus:outline-none focus:ring-0 dark:border-slate-600
                        dark:text-slate-300`}
                    value={settingType}
                    onChange={(e) => {
                        e.stopPropagation();
                        setIsBusy(true);
                        if (editedInputDataItem == undefined) {
                            setInputDataItem({
                                ...inputDataItem,
                                setting_type: e.target
                                    .value as InputDataSettingType,
                            });
                        } else {
                            setInputDataItem({
                                ...editedInputDataItem,
                                setting_type: e.target
                                    .value as InputDataSettingType,
                            });
                        }
                    }}
                >
                    {modelVariable.data_type === ModelVariableDataType.Number
                        ? InputSettingNumericalKeys.map((key) => (
                              <option
                                  key={key}
                                  value={InputDataSettingType[key]}
                              >
                                  {key}
                              </option>
                          ))
                        : InputSettingBooleanKeys.map((key) => (
                              <option
                                  key={key}
                                  value={InputDataSettingType[key]}
                              >
                                  {key}
                              </option>
                          ))}
                </select>
            </td>
            {settingType === InputDataSettingType["power interval"] && (
                <td
                    className={
                        "border-l border-slate-300 px-1 py-1 transition-all dark:border-slate-600"
                    }
                >
                    <select
                        className="block w-full min-w-[60px] appearance-none rounded-sm border-0 border-slate-300
                            bg-transparent px-2 py-1 text-xs font-normal text-slate-700 transition-all
                            focus:border-0 focus:outline-none focus:ring-0 dark:border-slate-600
                            dark:text-slate-300"
                        value={
                            !!editedInputDataItem
                                ? editedInputDataItem.exponent
                                : inputDataItem.exponent
                        }
                        onChange={(e) => {
                            e.stopPropagation();
                            setIsBusy(true);
                            if (editedInputDataItem == undefined) {
                                setInputDataItem({
                                    ...inputDataItem,
                                    exponent: Number(e.target.value),
                                });
                            } else {
                                setInputDataItem({
                                    ...editedInputDataItem,
                                    exponent: Number(e.target.value),
                                });
                            }
                        }}
                    >
                        {[3, 2, 0.5, 0.33].map((key, i) => (
                            <option key={key} value={key}>
                                {["3", "2", "1/2", "1/3"][i]}
                            </option>
                        ))}
                    </select>
                </td>
            )}

            <td
                className={
                    "border-l border-slate-300 px-1 py-1 transition-all dark:border-slate-600"
                }
                colSpan={2}
            >
                {!!showNumericalFormFields
                    ? formFieldKeys?.map((key) => (
                          <React.Fragment key={key}>
                              {settingType !==
                              InputDataSettingType["step interval"] ? (
                                  <div className="flex items-center">
                                      <span className="min-w-[40px] font-semibold">
                                          {formatVariableValue(
                                              modelVariable.unit,
                                              !!editedInputDataItem
                                                  ? Number(
                                                        editedInputDataItem[
                                                            key
                                                        ],
                                                    )
                                                  : Number(inputDataItem[key]),
                                              modelVariable.is_integer,
                                          )}
                                      </span>
                                      {/* to do: numerical input field as alt? */}
                                      <SettingsVariableSlider
                                          rangeMin={modelVariable.minimum}
                                          rangeMax={modelVariable.maximum}
                                          rangeStep={rangeStep}
                                          isDisabled={isBusy}
                                          value={
                                              !!editedInputDataItem
                                                  ? Number(
                                                        editedInputDataItem[
                                                            key
                                                        ],
                                                    )
                                                  : Number(inputDataItem[key])
                                          }
                                          handleOnChange={(value: number) =>
                                              setEditedInputDataItem({
                                                  ...inputDataItem,
                                                  [key]: value,
                                              })
                                          }
                                          handleOnClick={() => {
                                              if (!!editedInputDataItem) {
                                                  setIsBusy(true);
                                                  setInputDataItem(
                                                      editedInputDataItem,
                                                  );
                                              }
                                          }}
                                      />
                                      {key !== "numerical_value" && (
                                          <span className="font-semibold uppercase tracking-tight">
                                              {key}
                                          </span>
                                      )}
                                  </div>
                              ) : (
                                  <div className={"flex flex-wrap"}>
                                      {(inputDataItem[key] as number[])?.map(
                                          (stepValue, index) => (
                                              <SettingsVariableStepInterval
                                                  key={`${key}-${index}`}
                                                  itemKey={key}
                                                  index={index}
                                                  modelVariable={modelVariable}
                                                  inputDataItem={inputDataItem}
                                                  setInputDataItem={
                                                      setInputDataItem
                                                  }
                                                  isBusy={isBusy}
                                                  setIsBusy={setIsBusy}
                                                  editedInputDataItem={
                                                      editedInputDataItem
                                                  }
                                                  setEditedInputDataItem={
                                                      setEditedInputDataItem
                                                  }
                                                  rangeStep={rangeStep}
                                                  formatVariableValue={
                                                      formatVariableValue
                                                  }
                                              />
                                          ),
                                      )}
                                  </div>
                              )}
                          </React.Fragment>
                      ))
                    : formFieldKeys?.map((key) => (
                          <React.Fragment key={key}>
                              {settingType ==
                              InputDataSettingType["boolean constant"] ? (
                                  <div className="flex items-center">
                                      <FormSwitch
                                          label={"True"}
                                          isChecked={inputDataItem[key] === 1}
                                          isDisabled={isBusy}
                                          handleToggle={() => {
                                              setIsBusy(true);
                                              setInputDataItem({
                                                  ...inputDataItem,
                                                  [key]:
                                                      inputDataItem[key] === 1
                                                          ? 0
                                                          : 1,
                                              });
                                          }}
                                      />
                                  </div>
                              ) : (
                                  <div className={"flex flex-wrap space-x-2"}>
                                      {(inputDataItem[key] as number[])?.map(
                                          (stepValue, index) => (
                                              <div
                                                  key={`${key}-${index}`}
                                                  className="flex items-center"
                                                  title={`time horizon ${index + 1}`}
                                              >
                                                  <FormSwitch
                                                      label={""}
                                                      isChecked={
                                                          inputDataItem[key][
                                                              index
                                                          ] === 1
                                                      }
                                                      isDisabled={isBusy}
                                                      handleToggle={() => {
                                                          setIsBusy(true);
                                                          setInputDataItem({
                                                              ...inputDataItem,
                                                              [key]: (
                                                                  inputDataItem[
                                                                      key
                                                                  ] as number[]
                                                              ).map(
                                                                  (item, i) =>
                                                                      i ===
                                                                      index
                                                                          ? item ===
                                                                            1
                                                                              ? 0
                                                                              : 1
                                                                          : item,
                                                              ),
                                                          });
                                                      }}
                                                  />
                                              </div>
                                          ),
                                      )}
                                  </div>
                              )}
                          </React.Fragment>
                      ))}
            </td>

            {showSettingsCharts && (
                <td className={""} colSpan={1}>
                    {!!showNumericalFormFields && (
                        <div className="min-w-[300px] max-w-[300px] rounded-md">
                            <SettingsVariableChart
                                {...chartProps}
                                colorModeClass={colorModeClass}
                            />
                        </div>
                    )}
                </td>
            )}
        </tr>
    );
};

export const CompactVariableSettingsTableRow = React.memo(
    CompactVariableSettingsTableRowComponent,
);
