import { BaseModel, IBaseModel } from "./BaseModel";
import { ContentBlock } from "./ContentBlock";
import { ContentBlockModelDataSource } from "./ContentBlockModelDataSource";
import { DisplayRule } from "./DisplayRule";
import { Prompt } from "./Prompt";

type RoundConfig = {
    allowSelfAdvance?: boolean;
};

export type RoundShape = IBaseModel & {
    title: string;
    config?: RoundConfig;
    simulation_id: string;
    time_horizon_id: string;
    weight: number;
    display_time_index?: number;
    effect_time_index?: number;
    parent_round_id?: string;
    has_content_blocks: boolean;
    contentBlocks: ContentBlock[];
    rounds: Round[];
    pivot?: {
        user_id: string;
        round_id: string;
        team_id: string;
    };
    prompts?: Prompt[];
    round_number?: number;
    prompts_count?: number;
    displayRules?: DisplayRule[];
    contentBlockModelDataSources?: ContentBlockModelDataSource[];
    parentContentBlocks?: ContentBlock[];
};

export class Round extends BaseModel implements RoundShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "Round";
    public static END_POINT: string = "/api/rounds/";

    title: string = "";
    config?: RoundConfig = {
        allowSelfAdvance: false,
    };
    simulation_id: string = "";
    time_horizon_id: string = "";
    weight: number = 0;
    display_time_index?: number;
    effect_time_index?: number;
    parent_round_id?: string = "";
    has_content_blocks: boolean = true;
    contentBlocks: ContentBlock[];
    rounds: Round[] = [];
    pivot?: {
        user_id: string;
        round_id: string;
        team_id: string;
    };
    prompts?: Prompt[];
    round_number?: number;
    prompts_count?: number;
    parentContentBlocks?: ContentBlock[];
    displayRules?: DisplayRule[];
    contentBlockModelDataSources?: ContentBlockModelDataSource[];
}
