import React from "react";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";
import ApplicationLogo from "@/components/ApplicationLogo";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

type Props = Pick<SapienAdminPageProps, "auth" | "errors"> & {
    children: React.ReactNode;
    showDefaultHeader?: boolean;
};
const queryClient = new QueryClient();
export default function CoursePageWrapper({
    auth,
    children,
    showDefaultHeader = true,
}: Props) {
    const user = auth?.user;
    return (
        <QueryClientProvider client={queryClient}>
            <div className="min-h-screen bg-slate-100">
                <Disclosure as="nav" className="bg-sapien-blue">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link
                                                href={sapienRoute(
                                                    "admin.index",
                                                )}
                                            >
                                                <span sr-only="Home" />
                                                <ApplicationLogo className="h-12 w-12 brightness-200 grayscale filter" />
                                            </Link>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4"></div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/* <button
                                                type="button"
                                                className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white
                                                    focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2
                                                    focus:ring-offset-gray-800"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">
                                                    View notifications
                                                </span>
                                                <BellIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button> */}

                                            {/* Profile dropdown */}
                                            <UserNavDropdown
                                                userName={user?.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <DisclosureButton
                                            className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2
                                                text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2
                                                focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </DisclosureButton>
                                    </div>
                                </div>
                            </div>

                            <DisclosurePanel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    <DisclosureButton
                                        as="a"
                                        // href={item.href}
                                        className={classNames(
                                            false
                                                ? "bg-gray-900 text-white"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                            "block rounded-md px-3 py-2 text-base font-medium",
                                        )}
                                        aria-current={false}
                                    >
                                        some link
                                    </DisclosureButton>
                                </div>
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0"></div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-white">
                                                {user.name}
                                            </div>
                                            <div className="text-sm font-medium text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400
                                                hover:text-white focus:outline-none focus:ring-2 focus:ring-white
                                                focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">
                                                View notifications
                                            </span>
                                            <BellIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        <DisclosureButton
                                            as="a"
                                            // href={item.href}
                                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700
                                                hover:text-white"
                                        >
                                            Some link
                                            {/* {item.name} */}
                                        </DisclosureButton>
                                    </div>
                                </div>
                            </DisclosurePanel>
                        </>
                    )}
                </Disclosure>
                {!!showDefaultHeader && (
                    <header className="bg-white shadow-sm">
                        <div className="mx-auto flex max-w-7xl justify-between px-4 py-4 sm:px-6 lg:px-8">
                            <h1 className="text-lg font-semibold leading-6 text-gray-900">
                                Courses
                            </h1>
                        </div>
                    </header>
                )}
                {children}
            </div>
        </QueryClientProvider>
    );
}
