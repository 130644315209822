import React from "react";
import {
    EditorFormGroup,
    EditorFormLabel,
    EditorFormToggleLeftLabel,
    EditorFormToggleDiv,
    EditorFormInputToggle,
    EditorFormToggleLabel,
    EditorFormToggleRightLabel,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";

export const FormInputToggleArrayElement = (props: {
    id: string;
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    uncheckedOption: string | number;
    checkedOption: string | number;
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
}) => {
    const {
        id,
        value,
        label,
        themeObjectKey,
        uncheckedOption,
        checkedOption,
        handleChange,
    } = props;

    return (
        <EditorFormGroup>
            <EditorFormLabel>{label}</EditorFormLabel>
            <div className="flex flex-row items-center">
                <EditorFormToggleLeftLabel>
                    {uncheckedOption}
                </EditorFormToggleLeftLabel>
                <EditorFormToggleDiv>
                    <EditorFormInputToggle
                        type="checkbox"
                        id={id}
                        checked={value === checkedOption}
                        onChange={(e) =>
                            handleChange(
                                themeObjectKey,
                                value === checkedOption
                                    ? uncheckedOption
                                    : checkedOption,
                            )
                        }
                    />
                    <EditorFormToggleLabel htmlFor={id} />
                </EditorFormToggleDiv>
                <EditorFormToggleRightLabel>
                    {checkedOption}
                </EditorFormToggleRightLabel>
            </div>
        </EditorFormGroup>
    );
};
