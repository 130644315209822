import React from "react";
import { Simulation } from "../../models";
import { Link } from "@inertiajs/react";
import {
    ArrowUpRightIcon,
    DocumentDuplicateIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { sapienRoute } from "@/inertia-utils/hooks";

interface SimulationCardProps {
    simulation: Simulation;
    setEditedSimulation?: () => void;
    setClonedSimulation?: () => void;
    deleteSimulation: (simulationId: string) => void;
}

function secondsToDuration(sec: number): string {
    const roundedSeconds = Math.round(sec / 900) * 900; // 900 seconds = 15 minutes
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds - hours * 3600) / 60);

    let duration = "";

    if (hours > 0) {
        duration += hours + "h ";
    }

    if (minutes > 0 || duration === "") {
        // always display minutes if no hours are present
        duration += minutes + "m";
    }

    return duration.trim();
}
export const SimulationCard = ({
    simulation,
    setEditedSimulation,
    deleteSimulation,
    setClonedSimulation,
}: SimulationCardProps) => {
    const backgroundImage =
        simulation?.theme?.image_url ||
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/noun-image-5275349.png";

    return (
        <div className="flex flex-col justify-between p-4 rounded-md bg-white shadow-md border">
            <div className="flex flex-col space-y-3 pb-4">
                <div
                    className={`w-full h-72 xl:h-56 rounded-md  ${
                        !!simulation?.theme?.image_url
                            ? "bg-cover bg-no-repeat bg-center shadow-lg"
                            : "bg-contain bg-no-repeat bg-center border-2"
                    }`}
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                ></div>
                <div className="flex text-[#6B7280] text-sm">
                    <p>
                        {simulation.rounds_count} Episode
                        {simulation.rounds_count > 1 && "s"}
                    </p>
                    {!!simulation.estimated_time && (
                        <>
                            <p className="mx-2">•</p>
                            <p>
                                Approx{" "}
                                {secondsToDuration(simulation.estimated_time)}
                            </p>
                        </>
                    )}
                </div>
                <h2 className="text-lg font-bold">{simulation.title}</h2>{" "}
            </div>
            <div className="flex justify-between">
                <Link
                    href={sapienRoute("admin.projects.show", {
                        sim: simulation.slug,
                    })}
                    className="border border-black hover:text-white hover:bg-black transition-all rounded-full flex items-center px-4 py-1 text-sm"
                >
                    <span>Launch</span>
                    <ArrowUpRightIcon className="h-4 w-4 ml-1" />
                </Link>
                <div className="flex space-x-2">
                    <button
                        onClick={() => {
                            setEditedSimulation();
                        }}
                        className="hover:text-blue-600 rounded-full transition-all"
                    >
                        <PencilIcon className="w-4 h-4" />
                    </button>
                    <button
                        onClick={() => {
                            setClonedSimulation();
                        }}
                        className="hover:text-blue-600 rounded-full transition-all"
                    >
                        <DocumentDuplicateIcon className="w-4 h-4" />
                    </button>
                    <button
                        onClick={() => {
                            deleteSimulation(simulation.id);
                        }}
                        className="hover:text-red-600 rounded-full transition-all"
                    >
                        <TrashIcon className="w-4 h-4" />
                    </button>
                </div>
            </div>
        </div>
    );
};
