import styled from "styled-components";

export const EditorContainerWrapper = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

`;
