import React, { useEffect, useMemo, useState } from "react";
import { Form, FormGroup, FormInput } from "../../components";
import { Participant, Team } from "../../models";
import { plus } from "react-icons-kit/feather/plus";
import { edit2 } from "react-icons-kit/feather/edit2";
import { check } from "react-icons-kit/feather/check";
import { useSelectedSimulation } from "../../hooks";
import { useRoundStore } from "@/hooks/store";
import { ParticipantRoundMenu } from "./ParticipantRoundMenu";
import { Icon } from "react-icons-kit";
import { TeamParticipantForm } from "./TeamParticipantForm";
import { ChevronRightIcon, LinkIcon } from "@heroicons/react/24/solid";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";

import {
    AllowParticipantAdvance,
    AllowTeamOrCohortAdvance,
} from "./AllowAdvanceControls";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";
import AllowAccess from "./AllowAccess";

function getTeamStatus(team: Team | TeamWithProgressData) {
    if (
        team.participants.every(
            (participant) =>
                participant?.pivot["team_user_round.advance_allowed"],
        )
    ) {
        return "ALL_ALLOWED";
    }

    if (
        team.participants.some(
            (participant) =>
                participant?.pivot["team_user_round.advance_allowed"],
        )
    ) {
        return "SOME_ALLOWED";
    }

    return "NONE_ALLOWED";
}

export const TeamDisplay = ({
    team,
    setParticipantToMove,
    showAdvanceControls = false,
    isExpanded,
    setIsExpanded,
    isCourse = false,
}: {
    team: TeamWithProgressData;
    setParticipantToMove: (participantToMove: Participant) => void;
    showAdvanceControls: boolean;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    isCourse?: boolean;
}) => {
    const { selectedSimulation } = useSelectedSimulation();
    const { rounds } = useRoundStore();

    const [editedTeam, setEditedTeam] = useState<Team | TeamWithProgressData>();

    const [isFormOpen, setIsFormOpen] = useState(false);

    const participantsCanMove = useMemo(() => {
        if (!team?.participants) return false;
        return team.team_user_rounds?.every(
            (teamUserRound) => teamUserRound.teamSelections?.length === 0,
        );
    }, [team]);

    useEffect(() => {
        if (!!editedTeam) {
            document.getElementById(`team-${editedTeam.id}`).focus();
        }
    }, [editedTeam?.id]);

    const teamStatus = useMemo(() => {
        if (!team?.participants) return "NONE_ALLOWED";
        return getTeamStatus(team);
    }, [team]);

    const teamRoundId = useMemo(() => {
        let roundIdMap = {};
        if (!!team?.participants) {
            roundIdMap = team.participants.reduce((map, participant) => {
                if (participant.pivot?.round_id) {
                    return {
                        ...map,
                        [participant.pivot.round_id]: true,
                    };
                } else {
                    return map;
                }
            }, {});
        }
        return Object.keys(roundIdMap).length === 1
            ? Object.keys(roundIdMap)[0]
            : "";
    }, [team]);

    return (
        <React.Fragment>
            <tr className="border-t border-slate-300 bg-slate-700 font-semibold">
                <>
                    {editedTeam?.id === team.id ? (
                        <td className="min-w-[200px] px-2 py-1" colSpan={1}>
                            <div className="flex flex-row items-center justify-between">
                                <Form>
                                    <FormGroup style={{ margin: 0 }}>
                                        <FormInput
                                            type="text"
                                            id={`team-${team.id}`}
                                            placeholder="Name"
                                            value={editedTeam.name}
                                            onChange={(e) => {
                                                setEditedTeam({
                                                    ...editedTeam,
                                                    name: e.target.value,
                                                } as Team);
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                                <button
                                    type="button"
                                    className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                        text-slate-200 hover:bg-slate-300 hover:bg-opacity-5 focus:outline-none
                                        focus:ring-0 focus:ring-slate-300"
                                    disabled={false}
                                    onClick={() => {
                                        SapienInertia.post(
                                            "admin.facilitate.save-team",
                                            {
                                                ...editedTeam,
                                            },
                                            {},
                                            {
                                                preserveScroll: true,
                                            },
                                        );
                                        setEditedTeam(undefined);
                                    }}
                                >
                                    <Icon
                                        icon={
                                            editedTeam?.id === team.id
                                                ? check
                                                : edit2
                                        }
                                        size={14}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </button>
                            </div>
                        </td>
                    ) : (
                        <td className="min-w-[200px] px-2 py-1 " colSpan={1}>
                            <div className="flex flex-row items-center justify-between">
                                <div
                                    className="flex items-center justify-start space-x-1 rounded border-[1px]
                                        border-[rgba(0,0,0,0)] text-left hover:bg-[rgba(0,0,0,0.01)]"
                                    onClick={() => {
                                        setIsExpanded(!isExpanded);
                                    }}
                                >
                                    <ChevronRightIcon
                                        className={`h-5 w-5 cursor-pointer transition-all ${isExpanded && "rotate-90"}`}
                                    />
                                    <div className="font-bold">{team.name}</div>
                                    <button
                                        type="button"
                                        className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                            text-slate-200 hover:bg-slate-300 hover:bg-opacity-5 focus:outline-none
                                            focus:ring-0 focus:ring-slate-300"
                                        disabled={false}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            setEditedTeam(team);
                                        }}
                                    >
                                        <Icon
                                            icon={
                                                editedTeam?.id === team.id
                                                    ? check
                                                    : edit2
                                            }
                                            size={14}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </button>
                                </div>
                            </div>
                        </td>
                    )}
                    <td className="px-2 py-1">
                        <div className="ml-1">
                            <button
                                type="button"
                                className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                    text-slate-200 hover:bg-slate-300 hover:bg-opacity-5 focus:outline-none
                                    focus:ring-0 focus:ring-slate-300"
                                disabled={isFormOpen}
                                onClick={() => {
                                    setIsExpanded(true);
                                    setIsFormOpen(true);
                                }}
                            >
                                <Icon
                                    icon={plus}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </button>
                        </div>
                    </td>
                    {!isCourse && (
                        <>
                            <td className="px-2 py-1"></td>
                            <td className="px-2 py-1"></td>
                        </>
                    )}
                    {!!team.participants && !!team.participants.length ? (
                        <>
                            <td className="px-2 py-1">
                                <select
                                    id="location"
                                    name="location"
                                    className="block w-full min-w-[200px] rounded-md border border-slate-400 bg-slate-600 p-1.5
                                        text-xs text-slate-200 focus:border-blue-500 focus:ring-blue-500"
                                    value={teamRoundId}
                                    onChange={(e) => {
                                        SapienInertia.post(
                                            "admin.projects.facilitate.set-team-round",
                                            {
                                                round_id: e.target.value,
                                                team_id: team.id,
                                            },
                                            {
                                                round_id: e.target.value,
                                                team_id: team.id,
                                            },
                                            {
                                                preserveScroll: true,
                                            },
                                        );
                                    }}
                                >
                                    <option key="" value="" disabled />
                                    {rounds.map((round) => (
                                        <option key={round.id} value={round.id}>
                                            {round.title}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            {!!showAdvanceControls && (
                                <td className="px-2 py-1">
                                    <AllowTeamOrCohortAdvance
                                        status={teamStatus}
                                        targetType="Team"
                                        team_id={team.id}
                                    />
                                </td>
                            )}
                        </>
                    ) : (
                        <>
                            <td className="px-2 py-1"></td>
                            {!!showAdvanceControls && (
                                <td className="px-2 py-1"></td>
                            )}
                        </>
                    )}
                    {/* <td className="px-2 py-1"></td> */}
                </>
            </tr>
            {isExpanded && (
                <>
                    {!!team.participants &&
                        !!team.participants.length &&
                        team.participants.map((participant) => (
                            <tr
                                key={participant.id}
                                className="border-t border-slate-300 bg-slate-200 text-slate-800"
                            >
                                {!isCourse && (
                                    <>
                                        <td className="px-2 py-1"></td>
                                        <td className="px-2 py-1 font-semibold">
                                            {participant.name}
                                        </td>
                                    </>
                                )}

                                <td className="max-w-[200px] overflow-hidden text-ellipsis px-2 py-1">
                                    {participant.email}
                                </td>
                                {isCourse && (
                                    <td className="px-2 py-1 font-semibold">
                                        <AllowAccess user={participant}/>
                                    </td>
                                )}
                                {!isCourse && (
                                    <td className="px-2 py-1 text-center align-middle">
                                        <div className="flex w-full">
                                            {!!participant.pivot
                                                .login_link_url ? (
                                                <Link
                                                    href={sapienRoute(
                                                        "participant.impersonate",
                                                    )}
                                                    as="button"
                                                    method={"post"}
                                                    data={{
                                                        participant_email:
                                                            participant.email,
                                                        simulation_slug:
                                                            selectedSimulation.slug,
                                                    }}
                                                >
                                                    {
                                                        participant.pivot
                                                            .login_link_url
                                                    }
                                                </Link>
                                            ) : (
                                                <LinkIcon
                                                    className="h-6 w-6 cursor-pointer"
                                                    onClick={() => {
                                                        SapienInertia.post(
                                                            "admin.projects.facilitate.participant.link",
                                                            {
                                                                team_user_round_id:
                                                                    participant
                                                                        ?.pivot?.[
                                                                        "team_user_round.id"
                                                                    ],
                                                                simulation_slug:
                                                                    selectedSimulation.slug,
                                                            },
                                                            {
                                                                simulationSlug:
                                                                    selectedSimulation.slug,
                                                                cohortId:
                                                                    team.cohort_id,
                                                            },
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </td>
                                )}
                                <td className="px-2 py-1">
                                    <ParticipantRoundMenu
                                        participant={participant}
                                    />
                                </td>
                                {!!showAdvanceControls && (
                                    <td className="px-2 py-1">
                                        <AllowParticipantAdvance
                                            team_user_round_id={
                                                participant?.pivot[
                                                    "team_user_round.id"
                                                ]
                                            }
                                            status={
                                                participant?.pivot[
                                                    "team_user_round.advance_allowed"
                                                ]
                                                    ? "ALL_ALLOWED"
                                                    : "NONE_ALLOWED"
                                            }
                                        />
                                    </td>
                                )}
                                {/* {participantsCanMove ? (
                                    <td className="px-2 py-1">
                                        <button
                                            onClick={() => {
                                                setParticipantToMove(
                                                    participant,
                                                );
                                            }}
                                            className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-gray-800 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 focus:ring-blue-300"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                version="1.1"
                                                viewBox="0 0 1200 1200"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <path d="m881.11 943.49c-296.62 0-281.25 219.01-281.25 219.01h562.5s15.371-219.01-281.25-219.01z" />
                                                    <path d="m1011.8 730.67c0 72.168-58.504 130.67-130.67 130.67-72.164 0-130.67-58.504-130.67-130.67 0-72.164 58.504-130.67 130.67-130.67 72.168 0 130.67 58.504 130.67 130.67" />
                                                    <path d="m318.89 380.99c-296.62 0-281.25 219.01-281.25 219.01h562.5s15.367-219.01-281.25-219.01z" />
                                                    <path d="m449.55 168.17c0 72.168-58.504 130.67-130.67 130.67-72.168 0-130.67-58.504-130.67-130.67 0-72.164 58.504-130.67 130.67-130.67 72.164 0 130.67 58.504 130.67 130.67" />
                                                    <path d="m498.56 1052.5h-123.27c-51.781 0-93.914-42.133-93.914-93.914v-227.88c0-20.711 16.789-37.5 37.5-37.5 20.711 0 37.5 16.789 37.5 37.5v227.88c0 10.438 8.4766 18.914 18.914 18.914h123.27c20.711 0 37.5 16.789 37.5 37.5s-16.789 37.5-37.5 37.5z" />
                                                    <path d="m892.75 506.84c-20.711 0-37.5-16.789-37.5-37.5v-227.88c0-10.438-8.4766-18.914-18.914-18.914h-123.27c-20.711 0-37.5-16.789-37.5-37.5s16.789-37.5 37.5-37.5h123.27c51.781 0 93.914 42.133 93.914 93.914v227.88c0 20.711-16.793 37.5-37.5 37.5z" />
                                                    <path d="m892.75 506.84c-9.9414 0-19.484-3.957-26.516-10.988l-98.273-98.273c-14.648-14.648-14.648-38.379 0-53.027 14.648-14.648 38.379-14.648 53.027 0l71.758 71.758 71.742-71.758c14.648-14.648 38.379-14.648 53.027 0 14.648 14.629 14.648 38.379 0 53.027l-98.254 98.273c-7.0312 7.0352-16.57 10.988-26.512 10.988z" />
                                                    <path d="m417.15 866.44c-9.5938 0-19.191-3.6641-26.516-10.988l-71.758-71.758-71.742 71.758c-14.648 14.648-38.379 14.648-53.027 0-14.648-14.629-14.648-38.379 0-53.027l98.254-98.273c14.062-14.062 38.965-14.062 53.027 0l98.273 98.273c14.648 14.648 14.648 38.379 0 53.027-7.3242 7.3242-16.918 10.988-26.512 10.988z" />
                                                </g>
                                            </svg>
                                        </button>
                                    </td>
                                ) : (
                                    <td className="px-2 py-1"></td>
                                )} */}
                            </tr>
                        ))}
                    {isFormOpen && (
                        <tr className="border-t border-slate-300 bg-slate-300 text-slate-800">
                            <td colSpan={7} className="px-4 py-4">
                                <TeamParticipantForm
                                    team={team}
                                    exit={() => setIsFormOpen(false)}
                                />
                            </td>
                        </tr>
                    )}
                </>
            )}
        </React.Fragment>
    );
};
