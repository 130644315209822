import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import { RoundList } from "@/Layouts/admin";
import { EditorContainerWrapper } from "./StyledEditorWrapper";
import { TopNavLinkDiv } from "@/components";
import LogoImage from "@/components/LogoImage";
import { useSelectedSimulation } from "@/hooks";

export const RoundContainer = () => {
    const { selectedSimulation } = useSelectedSimulation();

    return (
        <EditorContainerWrapper
            className="EDITOR-CONTAINER"
            style={{ position: "relative" }}
        >
            <div
                className="EDITOR-TOP z-[10] flex flex-row"
                style={{
                    height: "36px",
                    background: "#15171a",
                }}
            >
                <div
                    className="EDITOR-LOGO flex justify-center"
                    style={{
                        width: "36px",
                        minWidth: "36px",
                        height: "36px",
                        background: "#15171a",
                    }}
                >
                    <TopNavLinkDiv style={{ padding: 0 }}>
                        <InertiaLink
                            href={
                                !!selectedSimulation &&
                                route("admin.projects.show", {
                                    sim: selectedSimulation.slug,
                                })
                            }
                        >
                            <LogoImage size={"22"} opacity={"0.9"} />
                        </InertiaLink>
                    </TopNavLinkDiv>
                </div>
                <div className="flex items-center px-3 text-sm font-extrabold text-white">
                    {selectedSimulation?.title}
                </div>
            </div>
            <div
                className="EDITOR-BOTTOM flex w-full flex-row"
                style={{
                    height: `calc(100vh - 36px)`,
                }}
            >
                <div className="h-[calc(100vh-36px)] min-h-[calc(100vh-36px)] w-full overflow-y-auto bg-[#374151] text-white">
                    <div className="mx-auto my-0 max-w-[1240px] p-8">
                        <RoundList
                            simulationId={selectedSimulation?.id}
                            backgroundColor={"rgba(255,255,255,0.05)"}
                            displayOutline={true}
                        />
                    </div>
                </div>
            </div>
        </EditorContainerWrapper>
    );
};
