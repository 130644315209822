import { useCallback } from "react";
import { Cohort } from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    getParticipantsToAdd,
    gotCohort,
    gotCohorts,
} from "@/redux-state/cohortSlice";

export const useSelectedCohort = () => {
    const dispatch = useAppDispatch();

    const _cohort = useAppSelector((state) => state.cohortStore.cohort);

    const _gotCohort = useCallback(
        (cohort: Cohort) => dispatch(gotCohort(cohort)),
        [dispatch]
    );

    return {
        selectedCohort: _cohort,
        gotCohort: _gotCohort,
    };
};

export const useAllCohorts = () => {
    const dispatch = useAppDispatch();

    const _cohorts = useAppSelector((state) =>
        Object.values(state.cohortStore.cohorts)
    );

    const _gotCohorts = useCallback(
        (cohorts: Cohort[]) => dispatch(gotCohorts(cohorts)),
        [dispatch]
    );

    return {
        cohorts: _cohorts,
        gotCohorts: _gotCohorts,
    };
};

export const useTeamsOfSelectedCohort = () => {
    const _teams = useAppSelector((state) =>
        [...Object.values(state.cohortStore.teams)].sort(
            (a, b) => a.team_number - b.team_number
        )
    );

    const _teamMap = useAppSelector((state) => state.cohortStore.teams);

    return {
        teams: _teams,
        teamMap: _teamMap,
    };
};

export const useAddableParticipants = () => {
    const dispatch = useAppDispatch();

    const _addableParticipants = useAppSelector((state) =>
        Object.values(state.cohortStore.addableParticipants)
    );

    const _getParticipantsToAdd = useCallback(
        (cohortId: string) => dispatch(getParticipantsToAdd(cohortId)),
        [dispatch]
    );

    return {
        addableParticipants: _addableParticipants,
        getParticipantsToAdd: _getParticipantsToAdd,
    };
};
