import React from "react";
import { ChevronRight } from "lucide-react";

const AccordionTableRowComponent = ({
    modelBlockLabel,
    handleSetModelBlockDisplayMap,
    isExpanded,
    colSpan,
}: {
    modelBlockLabel: string;
    handleSetModelBlockDisplayMap: () => void;
    isExpanded: boolean;
    colSpan: number;
}) => {
    return (
        <tr
            className="border border-slate-300 bg-slate-200/80 transition-all hover:cursor-pointer
                hover:bg-slate-200 dark:border-slate-600 dark:bg-slate-700/80
                dark:hover:bg-slate-700"
            onClick={() => handleSetModelBlockDisplayMap()}
        >
            <th
                className="group px-1 py-1 text-xs font-semibold text-slate-700 transition-all
                    dark:text-slate-300"
                colSpan={colSpan}
                data-is-shown={isExpanded}
            >
                <div className="flex gap-2">
                    <ChevronRight className="h-4 w-4 transition-all group-data-[is-shown='true']:rotate-90" />
                    <span>{modelBlockLabel}</span>
                </div>
            </th>
        </tr>
    );
};

export const AccordionTableRow = React.memo(AccordionTableRowComponent);
