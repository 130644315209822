import React from "react";
import {
    EditorFormLabel,
    EditorFormInputRange,
} from "./StyledEditorComponents";
import { ThemeObject } from "../ThemeObjects";
const conversions = {
    0: 0,
    1: 1,
    2: 2,
    3: 4,
    4: 8,
    5: 16,
    6: 32,
    7: 64,
    8: 128,
};
export const FormInputRangeSlider = (props: {
    value: string | number;
    label: string;
    themeObjectKey: keyof ThemeObject;
    step: string | number;
    min: string | number;
    max: string | number;
    handleChange: (themeObjectKey: keyof ThemeObject, value: any) => void;
    isStacked?: boolean;
    options?: { [index: string]: string | number };
    name?: string;
}) => {
    const {
        value,
        label,
        themeObjectKey,
        step,
        min,
        max,
        handleChange,
        isStacked,
        name,
    } = props;

    const selectOptions = props.options ? props.options : conversions;

    return (
        <div className="flex w-full flex-row items-center justify-between">
            <select
                value={value}
                onChange={(e) =>
                    handleChange(themeObjectKey, Number(e.target.value))
                }
                className="mr-1 rounded-md border-gray-300/10 bg-white/10 text-center text-gray-200 focus:text-white  focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
            >
                {Object.keys(selectOptions).map((conversion) => (
                    <option
                        key={themeObjectKey + "_" + conversion}
                        value={conversion}
                        className="bg-[#29303e]"
                    >
                        {selectOptions[conversion]}
                    </option>
                ))}
            </select>
            <EditorFormLabel
                style={
                    isStacked
                        ? {
                              margin: "0 0.25rem",
                              width: "70px",
                              fontSize: "12px",
                              minWidth: "65px",
                          }
                        : { marginBottom: 0 }
                }
            >
                {label}
            </EditorFormLabel>

            <EditorFormInputRange
                type="range"
                step={step}
                min={min}
                max={max}
                value={value}
                onChange={(e) =>
                    handleChange(themeObjectKey, Number(e.target.value))
                }
                style={{ minWidth: "100px", maxWidth: "350px" }}
                name={name}
            />
        </div>
    );
};
