import { Tenant } from "@/models";
import { fireEvent } from "@testing-library/dom";
import { act } from "@testing-library/react";
export const mode = import.meta?.env?.NODE_ENV;
//configuration exposes vi as a global
if (typeof vi !== "undefined") {
    // setupTestStore();


    vi.mock("../modules/text-editor/useDebouncedEditor", () => {
        return {
            useDebouncedEditor: () => null,
        };
    });

    vi.mock("@inertiajs/react", async (originalImport) => {
        const mod: any =
            (await originalImport()) as typeof import("@inertiajs/react");
        return {
            ...mod,
            props: {
                auth: {
                    user: {
                        id: 1,
                        name: "John Doe 2",
                        email: "john@example.com",
                    },
                    isAdmin: true,
                },
                tenant: {
                    logo_url: "logo",
                    name: "tenant name",
                    slug: "/tenant-slug",
                    tenant_name: "tenant name",
                    domains: [{ domain: "tenant-domain.com" }],
                } as Tenant,
                // locationInfo: locationInfo(),
                // dynamicTestProperty: locationInfo.query
            },
            createInertiaApp: (options: any) => {
                console.log("createInertiaApp", options);
            },
            usePage() {
                /**
                 *  A NEW RETURN VALUE FOR USE PAGE CAN BE MOCKED IN ANY TEST BY DOING THE FOLLOWING:
                 *
                 *  first, re-import the module
                 *  const lib = await import("@inertiajs/react") as typeof import("@inertiajs/react");
                 *
                 *  then, mock the return value
                 *  lib.usePage = vi.fn().mockReturnValue({
                 *      props: {
                 *          something:"something else"
                 *      }
                 *  });
                 */

                return {
                    props: this.props,
                };
            },
            // createInert
        };
    });

    vi?.mock("ziggy-js", async (ziggy) => {
        return {
            default: (name: string, params: any) => "route",
        };
    });

    vi.mock("@/hooks/activeGontentBlock/getActiveContentBlock", async () => {
        return {
            getActiveContentBlock: async (id: string) => {
                console.log("triggered here and i is: ", id);
                return {
                    id,
                    name: "test block",
                    content: "test content",
                };
            },
        };
    });

    vi?.mock("@/inertia-utils/hooks/SapienInertia", async (sapienInertia) => {
        console.log("mocking SapienInertia");
        const originalSapienInertia =
            (await sapienInertia()) as typeof import("@/inertia-utils/hooks/SapienInertia");
        return {
            ...originalSapienInertia,
            SapienInertia: {
                ...originalSapienInertia.SapienInertia,
                post: (
                    route: string,
                    data?: any,
                    params?: any,
                    options?: any
                ) => {
                    console.log("called");
                    if("function" === typeof options?.onSuccess){
                        options.onSuccess()
                    }
                },
                get: (url: string, data: any, options: any) => {
                    console.log("SapienInertia.get", url, data, options);
                },
                reload: (options: any) => {
                    console.log("SapienInertia.reload", options);
                },
                visit: (url: string, options: any) => {
                    console.log("SapienInertia.visit", url, options);
                    act(() => {
                        fireEvent(
                            document,
                            new CustomEvent("inertia:navigate", {
                                detail: {
                                    page: {
                                        url,
                                    },
                                },
                            })
                        );
                    });
                },
            },
        };
    });
    // IntersectionObserver isn't available in test environment
    const mockIntersectionObserver = vi.fn();

    mockIntersectionObserver.mockReturnValue({
        observe: () => null,
        unobserve: () => null,
        disconnect: () => null,
    });

    window.IntersectionObserver = mockIntersectionObserver;
    window.confirm = vi.fn(() => true) // always click 'yes'

    //mock create inertia app or similar

    afterAll(() => {
        vi?.clearAllMocks();
    });
}
