import { useModelBuilderPage } from "@/inertia-utils/hooks";
import React from "react";
import {
    useModelVariableStore,
    useVariableRelationshipStore,
} from "../../hooks/store";
import { VariableTreeDisplay } from "./VariableTreeDisplay";

export const ModelTrees = ({}: { simulationId: string }) => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { variableDepths } = modelBuilderPageProps;

    const { modelVariables } = useModelVariableStore();
    const { relationshipsBySourceId } = useVariableRelationshipStore();

    return (
        <div>
            <div className="mb-4 mt-2">
                <div className="text-xl">Model Trees</div>
            </div>
            <div style={{ overflowX: "hidden" }} className="text-xs">
                {!!modelVariables &&
                    !!relationshipsBySourceId &&
                    !!variableDepths &&
                    variableDepths
                        .filter(
                            (variableDepthObject) =>
                                !!modelVariables[
                                    variableDepthObject.variable_id
                                ] &&
                                !relationshipsBySourceId[
                                    variableDepthObject.variable_id
                                ],
                        )
                        .map((variableDepthObject) => (
                            <div
                                key={variableDepthObject.variable_id}
                                className="mb-4"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                <VariableTreeDisplay
                                    modelVariable={
                                        modelVariables[
                                            variableDepthObject.variable_id
                                        ]
                                    }
                                    isCollapsible={true}
                                />
                            </div>
                        ))}
            </div>
        </div>
    );
};
