import { ModelBlock } from "@/models";
import React from "react";

export const ModelBlockExpandCollapseMenu = ({
    filteredNestedModelBlocks,
    modelBlockAccordionMap,
    setModelBlockAccordionMap,
}: {
    filteredNestedModelBlocks: ModelBlock[];
    modelBlockAccordionMap: {
        [modelBlockId: string]: boolean;
    };
    setModelBlockAccordionMap: (modelBlockAccordionMap: {
        [modelBlockId: string]: boolean;
    }) => void;
}) => {
    return (
        <div className="text-xs">
            <span className="mr-1 font-bold">Expand:</span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap(
                        Object.keys(modelBlockAccordionMap).reduce(
                            (map, id) => ({
                                ...map,
                                [id]: true,
                            }),
                            {}
                        )
                    )
                }
            >
                All
            </span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap({
                        ...modelBlockAccordionMap,
                        ...filteredNestedModelBlocks.reduce(
                            (map, modelBlock) => ({
                                ...map,
                                [modelBlock.id]: true,
                            }),
                            {}
                        ),
                    })
                }
            >
                Parents
            </span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap({
                        ...modelBlockAccordionMap,
                        ...filteredNestedModelBlocks.reduce(
                            (map, modelBlock) => ({
                                ...map,
                                ...modelBlock.modelBlocks?.reduce(
                                    (map, modelBlock) => ({
                                        ...map,
                                        [modelBlock.id]: true,
                                    }),
                                    {}
                                ),
                            }),
                            {}
                        ),
                    })
                }
            >
                Children
            </span>
            <span className="ml-6 mr-1 font-bold">Collapse:</span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap(
                        Object.keys(modelBlockAccordionMap).reduce(
                            (map, id) => ({
                                ...map,
                                [id]: false,
                            }),
                            {}
                        )
                    )
                }
            >
                All
            </span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap({
                        ...modelBlockAccordionMap,
                        ...filteredNestedModelBlocks.reduce(
                            (map, modelBlock) => ({
                                ...map,
                                [modelBlock.id]: false,
                            }),
                            {}
                        ),
                    })
                }
            >
                Parents
            </span>
            <span
                className="px-1 cursor-pointer hover:underline"
                onClick={() =>
                    setModelBlockAccordionMap({
                        ...modelBlockAccordionMap,
                        ...filteredNestedModelBlocks.reduce(
                            (map, modelBlock) => ({
                                ...map,
                                ...modelBlock.modelBlocks?.reduce(
                                    (map, modelBlock) => ({
                                        ...map,
                                        [modelBlock.id]: false,
                                    }),
                                    {}
                                ),
                            }),
                            {}
                        ),
                    })
                }
            >
                Children
            </span>
        </div>
    );
};
