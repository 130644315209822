import { FragmentContentBlock } from "@/templates/TemplateContentBlocks";
import { ContentBlockType } from "@/models";

export const StrategicPathContentBlocks: FragmentContentBlock[] = [
    {
        content_block_type: ContentBlockType.Page,
        title: "Page",
        route: "/",
        contentBlocks: [
            // {
            //     ...LayoutConfigObject[LayoutType.Hero].layoutContentBlock,
            //     image_url:
            //         "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png",
            // },
        ],
    },
];
