import React from "react";
import { TimeHorizon, VariableValue } from "@/models";
import { FormGroup, FormLabel, FormInputSmall } from "@/components";

export const VariableValueForm = ({
    variableValue,
    setVariableValue,
    variableValueMap,
    setVariableValueMap,
    timeHorizons,
}: {
    variableValue: VariableValue;
    setVariableValue: (variableValue: VariableValue) => void;
    variableValueMap: {
        [index: string]: VariableValue;
    };
    setVariableValueMap: (variableValueMap: {
        [index: string]: VariableValue;
    }) => void;
    timeHorizons: TimeHorizon[];
}) => {
    return (
        <>
            {!!variableValue && (
                <div>
                    <FormGroup>
                        <label className="mr-4 text-sm font-semibold text-gray-900">
                            Variable Value
                        </label>
                        <FormInputSmall
                            name="variableValue"
                            type="number"
                            placeholder=""
                            value={variableValue.numerical_value}
                            onChange={(e) => {
                                setVariableValue({
                                    ...variableValue,
                                    numerical_value: Number(e.target.value),
                                });
                            }}
                        />
                    </FormGroup>
                </div>
            )}
            {!!variableValueMap && (
                <div className="mb-2">
                    <div className="mb-2 text-sm font-semibold text-gray-900">
                        Variable Values
                    </div>
                    <div className="pl-4">
                        {timeHorizons?.map((timeHorizon) => (
                            <div className="mb-1" key={timeHorizon.id}>
                                <FormLabel
                                    style={{ fontSize: "0.875rem" }}
                                >{`Time Horizon ${timeHorizon.time_index}`}</FormLabel>
                                <FormInputSmall
                                    type="number"
                                    placeholder=""
                                    value={
                                        variableValueMap[timeHorizon.id]
                                            ?.numerical_value || 0
                                    }
                                    onChange={(e) => {
                                        setVariableValueMap({
                                            ...variableValueMap,
                                            [timeHorizon.id]: {
                                                ...variableValueMap[
                                                    timeHorizon.id
                                                ],
                                                numerical_value: Number(
                                                    e.target.value,
                                                ),
                                            },
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
