import React, { useCallback, useMemo } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { cnToArray } from "@/util";
import { debounce } from "@/hooks";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import ButtonGroup from "../ButtonGroup";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { AlignJustify, AlignLeft, AlignRight } from "lucide-react";

export function ButtonSpacingMenu({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    const button = useMemo(() => {
        return contentBlock.contentBlocks
            .find((child) => child.content_block_type === ContentBlockType.Form)
            ?.contentBlocks.find(
                (grandChild) =>
                    grandChild.content_block_type === ContentBlockType.Button,
            );
    }, [contentBlock]);

    if (!button) return null;
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Button Position
                </label>
                <ButtonGroup
                    selectedValue={
                        (button.theme?.tailwindClasses as string[])?.find(
                            (className) =>
                                !!className && className.includes("self-"),
                        ) || "self-start"
                    }
                    values={[
                        { label: <AlignLeft />, value: "self-start" },
                        { label: <AlignJustify />, value: "self-center" },
                        { label: <AlignRight />, value: "self-end" },
                    ]}
                    handleChange={(value) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                tailwindClasses: [
                                    ...(
                                        button.theme?.tailwindClasses || []
                                    ).filter(
                                        (className) =>
                                            !!className &&
                                            !className.includes("self-"),
                                    ),
                                    value as string,
                                ],
                            },
                        });
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 pb-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Button Border Radius
                </label>
                <ButtonGroup
                    selectedValue={(button.theme?.tailwindClasses as string[])
                        ?.filter(
                            (className) =>
                                !!className && className.includes("rounded"),
                        )
                        .join(" ")}
                    values={[
                        { label: "None", value: "rounded-0" },
                        { label: "S", value: "rounded-sm" },
                        { label: "M", value: "rounded-md" },
                        { label: "L", value: "rounded-lg" },
                    ]}
                    handleChange={(value) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                tailwindClasses: [
                                    ...(
                                        button.theme?.tailwindClasses || []
                                    ).filter(
                                        (className) =>
                                            !!className &&
                                            !className.includes("rounded"),
                                    ),
                                    value as string,
                                ],
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}

export function ButtonColorMenu({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    const button = useMemo(() => {
        return contentBlock.contentBlocks
            .find((child) => child.content_block_type === ContentBlockType.Form)
            ?.contentBlocks.find(
                (grandChild) =>
                    grandChild.content_block_type === ContentBlockType.Button,
            );
    }, [contentBlock]);

    if (!button) return null;
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label className="text-xs">Button Background Color</label>
                <ColorSelector
                    selectedColor={button?.theme?.backgroundColor || "white"}
                    selectColor={(backgroundColor, color) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                backgroundColor,
                            },
                        });
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 shadow-sm">
                <label className="text-xs">Button Text Color</label>
                <ColorSelector
                    selectedColor={button?.theme?.color || "white"}
                    selectColor={(color, _) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                color,
                            },
                        });
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 shadow-sm">
                <label className="text-xs">Button Hover Background Color</label>
                <ColorSelector
                    selectedColor={
                        button?.theme?.hoverBackgroundColor || "white"
                    }
                    selectColor={(hoverBackgroundColor, color) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                hoverBackgroundColor,
                            },
                        });
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 shadow-sm">
                <label className="text-xs">Button Hover Text Color</label>
                <ColorSelector
                    selectedColor={button?.theme?.hoverColor || "white"}
                    selectColor={(hoverColor, color) => {
                        update({
                            ...button,
                            theme: {
                                ...button.theme,
                                hoverColor,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}
