import React from "react";
import { ModalContainer } from "@/modules/shared/";
import { useDisplayRules } from "@/hooks/useDisplayRules";
import { Switch } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { useDynamicContentBlockQueries } from "./DynamicContentQueries";
import { usePage } from "@/inertia-utils/hooks";
import { SapienAdminPage } from "@/inertia-utils/types";
import { useModalQueryParams } from "@/hooks";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function DynamicContentHideRevealModal() {
    const { isAtModalUrl: isOpen, closeModal } = useModalQueryParams(
        "DynamicContentHideRevealModal"
    );

    const pageId = usePage<SapienAdminPage>()?.props?.currentPageId;

    const { getModelVariables } = useDynamicContentBlockQueries();
    const {
        data: { modelVariablesForDisplayRules },
        isFetching,
    } = useQuery({
        queryKey: ["modelVariablesForDisplayRules", pageId, isOpen],
        queryFn: () => getModelVariables(pageId),
        initialData: {
            modelVariablesForDisplayRules: [],
        },
        select: ({ modelVariablesForDisplayRules }) => {
            return {
                modelVariablesForDisplayRules:
                    modelVariablesForDisplayRules.sort((a, b) =>
                        a.label.localeCompare(b.label)
                    ),
            };
        },
        enabled: isOpen,
    });

    const {
        setSelectedDisplayRuleModelVariableIds,
        selectedDisplayRuleModelVariableIds,
    } = useDisplayRules();

    if (!modelVariablesForDisplayRules) return <></>;
    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={closeModal}
            size="s"
            classNames="mt-1"
            styles={{
                marginTop: "1rem",
            }}
        >
            <form
                data-testid="dynamic-content-hide-reveal-modal"
                className="flex flex-col py-4 px-6 space-y-4"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                {isFetching && !modelVariablesForDisplayRules?.length ? (
                    <div className="flex flex-col py-4 pt-0 px-6 relative">
                        <div className="relative isolate space-y-5 overflow-hidden rounded-2xl bg-black/5 p-4 shadow-xl shadow-black/5 animate-pulse">
                            <div className="h-12 rounded-lg bg-gray-500/30"></div>
                            <div className="space-y-3">
                                <div className="h-3 w-3/5 rounded-lg bg-gray-500/30"></div>
                                <div className="h-3 w-4/5 rounded-lg bg-gray-500/20"></div>
                                <div className="h-3 w-2/5 rounded-lg bg-gray-500/20"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className="text-xl">Select Model Variables</p>
                        <span className="text-sm text-gray-400">
                            Use this to view what participants would see based
                            on model values set by their selections
                        </span>
                        <div className="flex flex-col border-b border-t border-gray-100 py-2 divide-y">
                            {modelVariablesForDisplayRules.map(
                                (modelVariable) => (
                                    <Switch.Group
                                        as="div"
                                        className="flex items-center justify-between  py-2 space-x-2"
                                        key={modelVariable.id}
                                    >
                                        <span className="flex  flex-col">
                                            <Switch.Label
                                                as="span"
                                                className="text-sm font-medium text-gray-900"
                                                passive
                                            >
                                                {modelVariable.label}
                                            </Switch.Label>
                                        </span>
                                        <Switch
                                            data-testid={`select-model-variable-${modelVariable.id}`}
                                            checked={selectedDisplayRuleModelVariableIds.includes(
                                                modelVariable.id
                                            )}
                                            className={classNames(
                                                selectedDisplayRuleModelVariableIds.includes(
                                                    modelVariable.id
                                                )
                                                    ? "bg-indigo-600"
                                                    : "bg-gray-200",
                                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            )}
                                            onChange={(checked) => {
                                                if (checked) {
                                                    setSelectedDisplayRuleModelVariableIds(
                                                        [
                                                            ...selectedDisplayRuleModelVariableIds,
                                                            modelVariable.id,
                                                        ]
                                                    );
                                                } else {
                                                    setSelectedDisplayRuleModelVariableIds(
                                                        selectedDisplayRuleModelVariableIds.filter(
                                                            (id) =>
                                                                id !==
                                                                modelVariable.id
                                                        )
                                                    );
                                                }
                                            }}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    selectedDisplayRuleModelVariableIds.includes(
                                                        modelVariable.id
                                                    )
                                                        ? "translate-x-5"
                                                        : "translate-x-0",
                                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                                )}
                                            />
                                        </Switch>
                                    </Switch.Group>
                                )
                            )}
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => {
                                    closeModal();
                                }}
                                className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                Close
                            </button>
                        </div>{" "}
                    </>
                )}
            </form>
        </ModalContainer>
    );
}
