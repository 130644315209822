import React from "react";
import { useDraggable } from "@dnd-kit/core";

export function Draggable({
    children,
    id,
    className,
    weight,
    handle,
}: {
    children: JSX.Element;
    id: string;
    className?: string;
    weight: number;
    handle?: boolean;
}) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
        data: { weight },
    });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;

    if (handle) {
        return (
            <div
                className={`flex items-center space-x-2 ${className}`}
                ref={setNodeRef}
                style={style}
                {...attributes}
            >
                <div {...listeners}>
                    <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.08335 13C4.08335 14.0083 3.25835 14.8333 2.25002 14.8333C1.24169 14.8333 0.416687 14.0083 0.416687 13C0.416687 11.9916 1.24169 11.1666 2.25002 11.1666C3.25835 11.1666 4.08335 11.9916 4.08335 13ZM2.25002 5.66663C1.24169 5.66663 0.416687 6.49163 0.416687 7.49996C0.416687 8.50829 1.24169 9.33329 2.25002 9.33329C3.25835 9.33329 4.08335 8.50829 4.08335 7.49996C4.08335 6.49163 3.25835 5.66663 2.25002 5.66663ZM2.25002 0.166626C1.24169 0.166626 0.416687 0.991626 0.416687 1.99996C0.416687 3.00829 1.24169 3.83329 2.25002 3.83329C3.25835 3.83329 4.08335 3.00829 4.08335 1.99996C4.08335 0.991626 3.25835 0.166626 2.25002 0.166626ZM7.75002 3.83329C8.75835 3.83329 9.58335 3.00829 9.58335 1.99996C9.58335 0.991626 8.75835 0.166626 7.75002 0.166626C6.74169 0.166626 5.91669 0.991626 5.91669 1.99996C5.91669 3.00829 6.74169 3.83329 7.75002 3.83329ZM7.75002 5.66663C6.74169 5.66663 5.91669 6.49163 5.91669 7.49996C5.91669 8.50829 6.74169 9.33329 7.75002 9.33329C8.75835 9.33329 9.58335 8.50829 9.58335 7.49996C9.58335 6.49163 8.75835 5.66663 7.75002 5.66663ZM7.75002 11.1666C6.74169 11.1666 5.91669 11.9916 5.91669 13C5.91669 14.0083 6.74169 14.8333 7.75002 14.8333C8.75835 14.8333 9.58335 14.0083 9.58335 13C9.58335 11.9916 8.75835 11.1666 7.75002 11.1666Z"
                            fill="#9CA3AF"
                        />
                    </svg>
                </div>
                {children}
            </div>
        );
    }

    return (
        <div
            className={className}
            ref={setNodeRef}
            style={style}
            {...listeners}
            {...attributes}
        >
            {children}
        </div>
    );
}
