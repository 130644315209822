import { sapienAxios } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

export type CalculateSandboxDataBody =
    (typeof LaravelRequestBodyShapes)["model-sandbox.calculate-data"];

export async function calculateSandboxData({
    body,
}: {
    body: CalculateSandboxDataBody;
}) {
    const { data } = await sapienAxios.post<
        CalculateSandboxDataBody,
        "model-sandbox.calculate-data"
    >("model-sandbox.calculate-data", body);

    return data?.selections;
}
