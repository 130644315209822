import React, { FC, ReactNode } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import {
    TopNav,
    TopNavLinkDiv,
    TopNavDivMR,
    TopNavBase,
    TopNavBody,
    Subtitle1Span,
} from "@/components";
import route from "ziggy-js";
import { useAuthStore } from "@/hooks/store";
import { sapienRoute } from "@/inertia-utils/hooks";
import LogoImage from "@/components/LogoImage";
import { UserNavDropdown } from "./UserNavDropdown";

export const TopNavContainer: FC<{
    header?: string;
    children?: ReactNode;
}> = ({ header, children }) => {
    const { user, tenant } = useAuthStore();

    return (
        <TopNav>
            <TopNavBase>
                <TopNavBody>
                    <TopNavLinkDiv style={{ gap: "1em" }}>
                        <InertiaLink href={route("admin.index")}>
                            <TopNavDivMR>
                                <LogoImage size={"28"} opacity={"0.9"} />
                            </TopNavDivMR>
                        </InertiaLink>
                        <InertiaLink href={route("admin.index")}>
                            <Subtitle1Span>{`Simulations`}</Subtitle1Span>
                        </InertiaLink>
                        {tenant?.has_e_learning_feature && (
                            <InertiaLink
                                href={sapienRoute("e-learning.courses.index")}
                            >
                                <Subtitle1Span>{`eLearning`}</Subtitle1Span>
                            </InertiaLink>
                        )}
                        <InertiaLink href={sapienRoute("admin.users.index")}>
                            <Subtitle1Span>{`Users`}</Subtitle1Span>
                        </InertiaLink>
                        <InertiaLink
                            href={sapienRoute("creator.design.templates.index")}
                        >
                            <Subtitle1Span>{`Templates`}</Subtitle1Span>
                        </InertiaLink>
                    </TopNavLinkDiv>
                </TopNavBody>
                <TopNavBody>
                    <TopNavLinkDiv>
                        {children !== undefined && children}
                    </TopNavLinkDiv>
                    {user && <UserNavDropdown userName={user.name} />}
                </TopNavBody>
            </TopNavBase>
        </TopNav>
    );
};
