import React from "react";
import { ContentBlockShape } from "@/models";
import { useSaveContentBlock } from "./content-block-management/useSaveContentBlock";

type Props = {
    children: JSX.Element | JSX.Element[];
    buildContentBlock: () => ContentBlockShape;
    saveOveride?: (contentBlock: ContentBlockShape) => any;
    disabled?: boolean;
};

export default function AddContentBlockButton({
    children,
    buildContentBlock,
    saveOveride,
}: Props) {
    const { mutate, isPending } = useSaveContentBlock();

    function handleClick() {
        const newContentBlock = buildContentBlock();
        if ("function" === typeof saveOveride) {
            saveOveride(newContentBlock);
            return;
        }
        mutate(newContentBlock);
    }

    return (
        <button
            disabled={isPending}
            onClick={handleClick}
            className="flex h-16 flex-col items-center justify-center rounded-md p-2 px-4
                transition-colors hover:bg-slate-200 disabled:cursor-not-allowed
                disabled:bg-slate-200 disabled:text-slate-700"
        >
            {children}
        </button>
    );
}
