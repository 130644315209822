import React from "react";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";

interface DeleteButtonProps {
    showDeleteButton: boolean;
    deleteContentBlock: () => void | Promise<void>;
    topPosition: number;
    bottomPosition: number;
    isDisabled?: boolean;
}

export const DeleteButton = (props: DeleteButtonProps) => {
    const { showDeleteButton, deleteContentBlock, isDisabled } = props;

    if (!showDeleteButton) return <></>;

    return (
        <ContentBlockButtonContainer
            onClick={() => {
                if (isDisabled) return;
                if (confirm("Delete?")) deleteContentBlock();
            }}
            hoverColor={"bg-red-600"}
        >
            <svg
                className=" h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </ContentBlockButtonContainer>
    );
};
