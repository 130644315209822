import { ContentBlockShape, ContentBlockType } from "@/models";
import React, { useMemo } from "react";
import { useGetContentBlockWithChildren } from "../../useContentBlockState";
import ImageDropdown from "./images-and-text/ImageDropdown";
import AccordionAndTabsDropDowns from "./accordion-and-tabs/AccordionAndTabsDropDowns";
import { getType } from "../getType";
import QuestionDropdown from "./question/QuestionDropdown";

type Props = { contentBlock: ContentBlockShape };

export default function ThemeDropdown({ contentBlock }: Props) {
    const blockWithChildren =
        useGetContentBlockWithChildren(contentBlock?.id) || contentBlock;
    const blockType = useMemo(() => {
        if (blockWithChildren) return getType(blockWithChildren);
    }, [blockWithChildren?.updated_at]);

    switch (blockType) {
        case ContentBlockType.Accordion:
        case ContentBlockType.Tabs:
            return (
                <AccordionAndTabsDropDowns contentBlock={blockWithChildren} />
            );
        case ContentBlockType["Chart Container"]:
            return (
                <h3
                    className="border-input ring-offset-background placeholder:text-muted-foreground
                        focus:ring-ring flex h-10  items-center justify-between rounded-md border
                        bg-white px-3 py-2 text-sm shadow-md focus:outline-none focus:ring-2
                        focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
                        [&>span]:line-clamp-1"
                >
                    Chart
                </h3>
            );
        case ContentBlockType.Image:
        case ContentBlockType["Rich Text"]:
            return <ImageDropdown contentBlock={blockWithChildren} />;
        case ContentBlockType.Question:
            return <QuestionDropdown contentBlock={blockWithChildren} />;
    }

    return (
        <button className="rounded-md border border-slate-500 bg-white px-4">
            {blockType}
        </button>
    );
}
