import React, { useCallback, useEffect, useState } from "react";
import { InputDataItem, InteractiveModuleArchetype } from "../types";
import { FormSwitch } from "../shared";

const ArchetypeDisplayComponent = ({
    archetype,
    timespan,
    isPending,
    saveArchetype,
    toggleArchetypePublishedAt,
    setInputDataObject,
    requiredInputDataItemCount,
}: {
    archetype: InteractiveModuleArchetype;
    timespan: number;
    isPending: boolean;
    saveArchetype: (
        archetype: InteractiveModuleArchetype,
        simpleUpdate?: boolean,
    ) => Promise<void>;
    toggleArchetypePublishedAt: (
        archetype: InteractiveModuleArchetype,
    ) => Promise<void>;
    setInputDataObject: (
        inputDataObject: Record<string, InputDataItem>,
    ) => void;
    requiredInputDataItemCount: number;
}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [editedArchetype, setEditedArchetype] =
        useState<InteractiveModuleArchetype>(undefined);
    const [editedArchetypeProp, setEditedArchetypeProp] =
        useState<keyof InteractiveModuleArchetype>();

    useEffect(() => {
        if (!!archetype && (editedArchetype !== undefined || isBusy)) {
            setEditedArchetype(undefined);
            setEditedArchetypeProp(undefined);
            setIsBusy(false);
        }
    }, [archetype]);

    const handleOnBlur = useCallback(() => {
        if (
            !!editedArchetype &&
            editedArchetype[editedArchetypeProp] !==
                archetype[editedArchetypeProp]
        ) {
            setIsBusy(true);
            saveArchetype(editedArchetype, true);
        } else {
            setEditedArchetype(undefined);
            setEditedArchetypeProp(undefined);
        }
    }, [editedArchetype]);

    return (
        <>
            <div className="flex w-full flex-col space-y-4">
                {editedArchetype?.id !== archetype.id ||
                editedArchetypeProp !== "name" ? (
                    <div
                        className="min-w-[140px] rounded-md border border-slate-200 bg-slate-100/50 px-3 py-1.5
                            text-lg font-semibold tracking-wide text-slate-700 transition-all
                            hover:border-blue-600 dark:border-slate-800 dark:bg-slate-800/20
                            dark:text-slate-300 dark:hover:border-blue-700"
                        onClick={() => {
                            if (editedArchetype == undefined && !isPending) {
                                setEditedArchetype(archetype);
                                setEditedArchetypeProp("name");
                            }
                        }}
                    >
                        {archetype.name}
                    </div>
                ) : (
                    <div>
                        <input
                            autoFocus
                            className="w-full min-w-[140px] rounded-md border-blue-600 bg-slate-50/50 px-3 py-1.5
                                text-lg font-semibold tracking-wide text-slate-700 transition-all marker:border
                                focus:border-blue-600 focus:ring-1 focus:ring-blue-600 dark:border-blue-700
                                dark:bg-slate-800/20 dark:text-slate-300 dark:focus:border-blue-700
                                dark:focus:ring-blue-700"
                            type="text"
                            disabled={isBusy || isPending}
                            value={editedArchetype.name}
                            onChange={(e) => {
                                setEditedArchetype({
                                    ...editedArchetype,
                                    name: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                handleOnBlur();
                            }}
                        />
                    </div>
                )}
                {editedArchetype?.id !== archetype.id ||
                editedArchetypeProp !== "description" ? (
                    <div
                        className="min-w-[140px] rounded-md border border-slate-200 bg-slate-100/50 px-3 py-1.5
                            text-base text-slate-600 transition-all hover:border-blue-600
                            dark:border-slate-800 dark:bg-slate-800/20 dark:text-slate-400
                            dark:hover:border-blue-700"
                        onClick={() => {
                            if (editedArchetype == undefined && !isPending) {
                                setEditedArchetype(archetype);
                                setEditedArchetypeProp("description");
                            }
                        }}
                    >
                        {archetype.description ?? "No description"}
                    </div>
                ) : (
                    <div>
                        <textarea
                            autoFocus
                            className="w-full min-w-[140px] rounded-md border-blue-600 bg-slate-50/50 px-3 py-1.5
                                text-base text-slate-600 transition-all marker:border focus:border-blue-600
                                focus:ring-1 focus:ring-blue-600 dark:border-blue-700 dark:bg-slate-800/20
                                dark:text-slate-400 dark:focus:border-blue-700 dark:focus:ring-blue-700"
                            rows={5}
                            value={editedArchetype.description ?? ""}
                            disabled={isBusy || isPending}
                            onChange={(e) => {
                                setEditedArchetype({
                                    ...editedArchetype,
                                    description: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                handleOnBlur();
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="space-y-1 text-sm font-light text-slate-500 transition-all dark:text-slate-500">
                <div>
                    {archetype.is_default ? (
                        <span className="font-semibold">{"DEFAULT"}</span>
                    ) : (
                        <FormSwitch
                            label={"Publish"}
                            isChecked={!!archetype.published_at}
                            isDisabled={isBusy}
                            handleToggle={() => {
                                setIsBusy(true);
                                toggleArchetypePublishedAt(archetype);
                            }}
                        />
                    )}
                </div>
                <div className="font-normal">{`Timespan: ${timespan}`}</div>
                <div className="whitespace-nowrap text-xs">{`${Object.keys(archetype.archetype.inputDataObject)?.length ?? 0} of ${requiredInputDataItemCount} inputs`}</div>
                <div
                    className="cursor-pointer whitespace-nowrap text-slate-600 transition-all
                        hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300"
                    onClick={() => {
                        if (
                            window.confirm(
                                "Overwrite local settings with this archetype?",
                            )
                        ) {
                            setInputDataObject(
                                archetype.archetype.inputDataObject,
                            );
                        }
                    }}
                >
                    {"Set as Local Data"}
                </div>
            </div>
        </>
    );
};

export const ArchetypeDisplay = React.memo(ArchetypeDisplayComponent);
