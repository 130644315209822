import React, { useCallback, useMemo, useState } from "react";
import { ModalContainer } from "@/modules/shared";
import {
    useModalQueryParams,
    useUploadStore,
    useSelectedSimulation,
} from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import ImageOrVideoSearch from "./uploads/ImageOrVideoSearch";
import { H6Span } from "@/components";
import VideoSelector from "./uploads/VideoSelector";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useAuthStore } from "@/hooks/store";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import { useGetContentBlockById } from "./modals/shared-queries/useGetContentBlockById";
import { Auth } from "@/inertia-utils/types";

function VideoEditorContents() {
    const { auth } = useAuthStore();
    const { setupAdminSockets } = useWebSockets();
    const [uploadPercentage, setUploadPercentage] = useState<number | null>(
        null,
    );
    setupAdminSockets((auth as Auth)?.user, {
        "upload-progress.notify": ({
            message,
        }: {
            message: string | number;
        }) => {
            setUploadPercentage(() => Number(message));
        },
    });
    const { queryParams, closeModal } = useModalQueryParams("VideoModal");
    const { videoContentBlockId } = queryParams;
    const { uploadedFileUrl, setUploadedFileUrl } = useUploadStore();

    const { data: videoContentBlock } =
        useGetContentBlockById(videoContentBlockId);

    const [videoSourceType, setVideoSourceType] = React.useState<
        "upload" | "api"
    >("api");

    const source = useMemo(() => {
        let videoUrls: string[] | string = [];

        if (Array.isArray(uploadedFileUrl) && uploadedFileUrl?.length)
            videoUrls = uploadedFileUrl as unknown as string[];
        else if ("string" === typeof uploadedFileUrl) {
            videoUrls = [uploadedFileUrl];
        } else if (videoContentBlock && videoContentBlock?.video_urls?.length)
            videoUrls = videoContentBlock.video_urls;
        else videoUrls = [];
        return videoUrls[0];
    }, [uploadedFileUrl]);

    const { selectedSimulation } = useSelectedSimulation();

    const handleSave = useCallback(() => {
        if (!videoContentBlock) return;

        const contentBlockToSave = {
            ...videoContentBlock,
            theme: JSON.stringify(videoContentBlock.theme),
            video_urls: [uploadedFileUrl],
        };

        if (videoSourceType === "upload") {
            SapienInertia.put(
                "admin.design.update",
                {
                    ...contentBlockToSave,
                } as unknown as (typeof LaravelRequestBodyShapes)["admin.design.update"],
                {
                    simulationSlug: selectedSimulation.slug,
                    roundId: contentBlockToSave.round_id,
                    contentBlock: contentBlockToSave.id,
                },
                {
                    preserveState: true,
                    onSuccess: () => {
                        closeModal();
                        setUploadedFileUrl("");
                    },
                },
            );
        } else {
            SapienInertia.post(
                "creator.design.file-search.store",
                {
                    contentBlockId: contentBlockToSave?.id,
                    property: "video_urls",
                    url: uploadedFileUrl,
                },
                {},
                {
                    onSuccess: () => {
                        closeModal();
                        setUploadedFileUrl("");
                    },
                    onError: (err) => {
                        console.log("error", err);
                    },
                },
            );
        }
    }, [
        videoContentBlockId,
        uploadedFileUrl,
        videoContentBlock,
        videoSourceType,
    ]);

    return (
        <div className="p-6 text-white">
            <div
                className="flex flex-row items-center justify-between border-b border-[#374151] py-6
                    text-white"
            >
                <H6Span>Edit Video</H6Span>
            </div>

            <React.Fragment>
                <div className="border-b border-[#374151] md:grid md:grid-cols-2 md:gap-2">
                    <div
                        className={`flex items-center px-6 py-4 ${videoSourceType === "api" ? "bg-[#1F2A37]" : ""}`}
                    >
                        <input
                            id="copy-old-prompt"
                            type="radio"
                            value=""
                            name="copy-old-prompt"
                            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                focus:ring-blue-500"
                            checked={videoSourceType === "api"}
                            onChange={(_) => setVideoSourceType("api")}
                        />
                        <label
                            htmlFor="copy-old-prompt"
                            className="ml-3.5 flex w-full flex-col text-sm font-medium"
                        >
                            Search
                            <span className="mt-1 text-[#6B7280]">
                                Search millions of videos
                            </span>
                        </label>
                    </div>
                    <div
                        className={`flex items-center px-6 py-4 ${
                            videoSourceType === "upload" ? "bg-[#1F2A37]" : ""
                        }`}
                    >
                        <input
                            id="create-new"
                            type="radio"
                            value=""
                            name="create-new"
                            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                focus:ring-blue-500"
                            checked={videoSourceType === "upload"}
                            onChange={(_) => setVideoSourceType("upload")}
                        />
                        <label
                            htmlFor="create-new"
                            className="ml-3.5 flex w-full flex-col text-sm font-medium"
                        >
                            Upload
                            <span className="mt-1 text-[#6B7280]">
                                Choose a video from your device
                            </span>
                        </label>
                    </div>
                </div>
                {videoSourceType === "upload" && !!videoContentBlock && (
                    <VideoSelector
                        videoContentBlock={videoContentBlock}
                        videoUrl={source}
                        uploadPercentage={uploadPercentage}
                    />
                )}
                {videoSourceType === "api" && (
                    <ImageOrVideoSearch mediaType={"video"} />
                )}
                <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                    <div className="flex items-center justify-center">
                        <button
                            onClick={closeModal}
                            className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                                text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                                focus:ring-2 focus:ring-blue-300"
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            onClick={handleSave}
                            className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                py-2.5 text-center text-sm font-medium text-white transition-all
                                hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                disabled:cursor-not-allowed disabled:opacity-75"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export const VideoEditor = () => {
    const { isAtModalUrl } = useModalQueryParams("VideoModal");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {
                SapienInertia.reload();
            }}
            size="l"
            backgroundColor="#111928"
            classNames={"z-100"}
        >
            {isAtModalUrl && <VideoEditorContents />}
        </ModalContainer>
    );
};
