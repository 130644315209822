import React from "react";
import { Tenant } from "../models";

interface IndexProps {
    test: string;
    tenants: Tenant[]
}

const Index = ({ test }: IndexProps) => {
    // let foo = "React";
    // const bar = "TypeScript, my friend";

    return (
        <h1>
             {test}
        </h1>
    );
};

export default Index;
