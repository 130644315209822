import React, { FC, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { ic_palette } from "react-icons-kit/md/ic_palette";
import { BoltIcon } from "@heroicons/react/24/outline";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { RightMenuSectionType, editorColors } from "./EditorContainer";
import { useGetActiveContentBlockId } from "@/hooks/activeContentBlock";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import { BehaviorEditor } from "./behavior-editor/BehaviorEditor";
import { ModelEditor } from "./ModelEditor";

type RightIconMenuProps = {
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    rightMenuSection: RightMenuSectionType;
    setRightMenuSection: (section: RightMenuSectionType) => void;
    isRightMenuOpen: boolean;
    toggleRightMenu: () => void;
};

export const RightIconMenu: FC<RightIconMenuProps> = (props) => {
    const {
        editorSizes,
        rightMenuSection,
        setRightMenuSection,
        isRightMenuOpen,
        toggleRightMenu,
    } = props;

    const activeId = useGetActiveContentBlockId();

    useEffect(() => {
        if (activeId) {
            if (!isRightMenuOpen) toggleRightMenu();
            setRightMenuSection("style");
        }
    }, [activeId]);

    return (
        <div className="flex flex-row items-center">
            <Popover>
                <PopoverTrigger
                    style={{
                        display: "flex",
                        height: editorSizes.toolbar,
                        alignItems: "center",
                    }}
                >
                    <div
                        title={"Behavior Controls"}
                        className="relative inline-flex items-center px-2 text-sm font-medium text-gray-400 hover:text-white"
                    >
                        <span className="sr-only">Behavior Controls</span>
                        <BoltIcon className="h-5 w-5" />
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align={"end"}
                    sideOffset={0}
                    style={{
                        backgroundColor: editorColors.bgPanel,
                        borderColor: "#ffffff40",
                        minWidth: "300px",
                        padding: "8px 0 8px 0",
                        zIndex: 9999,
                    }}
                >
                    <div className="text-white">
                        <BehaviorEditor />
                    </div>
                </PopoverContent>
            </Popover>
            <Popover>
                <PopoverTrigger
                    style={{
                        display: "flex",
                        height: editorSizes.toolbar,
                        alignItems: "center",
                    }}
                >
                    <div
                        title={"Model Controls"}
                        className="relative inline-flex items-center px-2 text-sm font-medium text-gray-400 hover:text-white"
                    >
                        <span className="sr-only">Model Controls</span>
                        <AdjustmentsVerticalIcon className="h-5 w-5" />
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align={"end"}
                    sideOffset={0}
                    style={{
                        backgroundColor: editorColors.bgPanel,
                        borderColor: "#ffffff40",
                        minWidth: "300px",
                        padding: "8px 0 8px 0",
                        zIndex: 9999,
                    }}
                >
                    <div className="text-white">
                        <ModelEditor />
                    </div>
                </PopoverContent>
            </Popover>
            <div
                className="ml-0.5 flex cursor-pointer items-center justify-center"
                key={"style"}
                onClick={() => {
                    setRightMenuSection("style" as RightMenuSectionType);
                    toggleRightMenu();
                }}
                style={{
                    background:
                        "style" === rightMenuSection &&
                        rightMenuSection &&
                        "rgba(255,255,255,.1)",
                    width: editorSizes.toolbar,
                    height: editorSizes.toolbar,
                }}
            >
                <Icon icon={ic_palette} size={18} style={{ display: "flex" }} />
            </div>
        </div>
    );
};
