import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    ModalComponent,
    ModalProps,
    setModalProps,
} from "@/redux-state/modalComponentSlice";
import { usePage } from "@/inertia-utils/hooks";

export const useModalComponentStore = (
    modalComponent: ModalComponent | undefined = undefined
) => {
    const dispatch = useAppDispatch();
    const pageProps = usePage()?.props;

    const _modalPageProps = useAppSelector(
        (state) => state.modalComponentStore.modalPageProps
    );
    const _setModalProps = useCallback(
        ({
            modalComponent,
            modalPageProps,
        }: ModalProps<typeof modalComponent> | null) => {
            if (!modalComponent && !modalPageProps) {
                dispatch(
                    setModalProps({
                        modalComponent: null,
                        modalPageProps: null,
                        errors: null,
                    })
                );
            } else {
                const _modalPageProps = {
                    ...modalPageProps,
                    errors:
                        modalPageProps &&
                        modalPageProps[modalComponent] &&
                        modalPageProps[modalComponent]?.modalPageErrors
                            ? modalPageProps[modalComponent]?.modalPageErrors
                            : {},
                };

                dispatch(
                    setModalProps({
                        modalComponent,
                        modalPageProps: _modalPageProps,
                    })
                );
            }
        },
        [dispatch]
    );

    const closeModalWithoutNavigation = useCallback(() => {
        dispatch(
            setModalProps({
                ...pageProps,
                modalComponent: null,
                modalPageProps: null,
                errors: null,
            })
        );
    }, [dispatch, pageProps]);

    const _modalComponent = useAppSelector(
        (state) => state.modalComponentStore.modalComponent
    );

    const _modalPageErrors = useAppSelector(
        (state) => state.modalComponentStore.modalPageErrors
    );

    const isOpen = useMemo(() => {
        return (
            modalComponent === _modalComponent &&
            !!_modalPageProps &&
            !!_modalPageProps[_modalComponent]
        );
    }, [modalComponent, _modalComponent, _modalPageProps]);

    return {
        modalComponent: _modalComponent,
        modalPageProps: _modalPageProps,
        modalPageErrors: _modalPageErrors,
        setModalProps: _setModalProps,
        isOpen,
        closeModalWithoutNavigation,
    };
};
