import React, { useCallback, useMemo, useState } from "react";
import { AsideContents } from "../Aside";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useModalQueryParams } from "@/hooks";
import { useEditedContentBlock } from "../useEditedContentBlock";
import { flatten } from "@/util";
import {
    useGetContentBlockWithChildren,
} from "../../useContentBlockState";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
function removeIdsRecursively(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    const { id, contentBlocks, ...rest } = contentBlock;

    const updatedContentBlocks = contentBlocks?.map((block) =>
        removeIdsRecursively({
            ...block,
            id: undefined,
            parent_content_block_id: undefined,
        }),
    );

    return {
        ...rest,
        ...(updatedContentBlocks && { contentBlocks: updatedContentBlocks }),
    };
}

export default function ImageSidebar() {
    const { openModal } = useModalQueryParams();
    const { editedContentBlock } = useEditedContentBlock();
    const editedContentBlockWithChildren = useGetContentBlockWithChildren(
        editedContentBlock?.id,
    );
    const { mutate, isPending } = useSaveContentBlock();

    if (!editedContentBlockWithChildren) return null;

    const images = useMemo(() => {
        return (
            flatten(
                editedContentBlockWithChildren.contentBlocks,
                "contentBlocks",
                "id",
            ).filter(
                (block) => block.content_block_type === ContentBlockType.Image,
            ) as ContentBlockShape[]
        ).reduce(
            (carry, block) => ({ ...carry, [block.id]: block }),
            {} as Record<string, ContentBlockShape>,
        );
    }, [editedContentBlockWithChildren]);

    const addBlock = useCallback(async () => {
        const newBlock = removeIdsRecursively(
            editedContentBlockWithChildren?.contentBlocks[0],
        );
        newBlock.parent_content_block_id = editedContentBlockWithChildren.id;
        mutate(newBlock);
    }, [editedContentBlockWithChildren]);

    const handleGrid = useCallback(
        async (gridNumber = 0) => {
            const updatedBlock = { ...editedContentBlockWithChildren };

            if (gridNumber === 0) {
                updatedBlock.theme = {
                    ...updatedBlock.theme,
                    tailwindClasses: [
                        updatedBlock.theme?.tailwindClasses?.filter(
                            (className) => !className.includes("grid"),
                        ),
                    ],
                };
            } else {
                updatedBlock.theme = {
                    ...updatedBlock.theme,
                    tailwindClasses: [
                        updatedBlock.theme?.tailwindClasses?.filter(
                            (className) => !className.includes("grid"),
                        ),
                        `grid grid-cols-${gridNumber}`,
                    ],
                };
            }

            mutate(updatedBlock);
        },
        [editedContentBlockWithChildren],
    );

    return (
        <AsideContents title="Images">
            <div className="relative flex flex-col divide-y-2">
                {!!isPending && (
                    <div className="absolute inset-0 animate-pulse bg-white/50">
                        {" "}
                    </div>
                )}
                {images && Object.keys(images).length > 1 && (
                    <div className="my-8 flex flex-col gap-4 px-4">
                        <h3 className="text-lg font-bold">Layout</h3>

                        <button
                            className="rounded-md bg-slate-100 px-4 py-2 text-sapien-blue transition-colors
                                hover:bg-sapien-blue hover:text-white"
                            onClick={() => handleGrid(0)}
                        >
                            No Grid
                        </button>

                        <button
                            onClick={() => handleGrid(2)}
                            className="flex items-center gap-4 rounded-md bg-slate-100 px-4 py-2 transition-colors
                                hover:bg-slate-300"
                        >
                            <span> 2 Columns:</span>
                            <span className="flex justify-start gap-2">
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                            </span>
                        </button>

                        <button
                            onClick={() => handleGrid(3)}
                            className="flex items-center gap-4 rounded-md bg-slate-100 px-4 py-2 transition-colors
                                hover:bg-slate-300"
                        >
                            <span> 3 Columns:</span>
                            <span className="flex justify-start gap-2">
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                            </span>
                        </button>

                        <button
                            onClick={() => handleGrid(4)}
                            className="flex items-center gap-4 rounded-md bg-slate-100 px-4 py-2 transition-colors
                                hover:bg-slate-300"
                        >
                            <span> 4 Columns:</span>
                            <span className="flex justify-start gap-2">
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                                <div className="h-6 w-6 rounded-sm border bg-sapien-blue"></div>
                            </span>
                        </button>
                    </div>
                )}
                {Object.values(images)
                    ?.sort((a, b) => a.weight - b.weight)
                    ?.map((contentBlock) => (
                        <div
                            key={contentBlock.id}
                            className="relative flex justify-between space-y-2 py-4"
                        >
                            {/* {pendingDeleteId === contentBlock.id && (
                                <DeleteContentBlockOverlay
                                    contentBlock={contentBlock}
                                    cancelDelete={() => setPendingDeleteId("")}
                                />
                            )} */}
                            <img
                                className="w-64 object-cover"
                                src={contentBlock.image_url}
                                alt={contentBlock.title}
                            />
                            <div className="flex items-center gap-2">
                                <button
                                    className="rounded-md border border-slate-500 px-4 py-1"
                                    onClick={() =>
                                        openModal({
                                            modalComponent: "ImageModal",
                                            imageUrl: contentBlock.image_url,
                                            imageContentBlockId:
                                                contentBlock.id,
                                        })
                                    }
                                >
                                    Change Image
                                </button>
                                {/* <button
                                    onClick={() =>
                                        setPendingDeleteId(contentBlock.id)
                                    }
                                >
                                    <span className="sr-only">
                                        Delete Content Block
                                    </span>
                                    <Trash className="h-6 w-6" />
                                </button> */}
                            </div>
                        </div>
                    ))}
            </div>
            <div>
                <button onClick={addBlock}>Add</button>
            </div>
        </AsideContents>
    );
}
