import { ContentBlockShape } from "@/models";
import React, { useState } from "react";
import { ChartContentBlockWithObjectData } from "./EditChartSidebar";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { Plus, Trash } from "lucide-react";

type Props = {
    chart: ChartContentBlockWithObjectData;
    handleChange: (chart: ContentBlockShape) => void;
};

export default function LineChartForm({
    chart: rawChart,
    handleChange: submit,
}: Props) {
    const [chart, setChart] = useState(
        rawChart as ChartContentBlockWithObjectData,
    );

    const handleChange = (chart: ContentBlockShape) => {
        setChart(chart as ChartContentBlockWithObjectData);
        submit(chart);
    };

    const lengthOfLongestData = Object.keys(chart.chart_data).reduce(
        (carry, key) => {
            const data = chart.chart_data[key];
            return Math.max(carry, data.length);
        },
        0,
    );

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
                <input
                    className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
                        ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                        focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Label"
                    defaultValue={chart?.theme?.xAxisLabel}
                    onChange={(e) => {
                        handleChange({
                            ...chart,
                            theme: {
                                ...chart.theme,
                                xAxisLabel: e.target.value,
                            },
                        });
                    }}
                />
            </div>
            {chart?.chart_data && Object.keys(chart.chart_data).length > 0 && (
                <div
                    className="flex w-full flex-col overflow-x-auto scrollbar scrollbar-thumb-slate-300
                        scrollbar-thumb-rounded-full scrollbar-h-2"
                >
                    <table className="min-w-full">
                        <tbody>
                            <tr>
                                <td
                                    colSpan={
                                        chart.chart_data[
                                            Object.keys(chart.chart_data)[0]
                                        ].length + 2
                                    }
                                />
                                <td>
                                    <div className="p-1">
                                        <button
                                            className="rounded-md p-1 transition-colors hover:bg-slate-200"
                                            onClick={() => {
                                                handleChange({
                                                    ...chart,
                                                    chart_data: Object.keys(
                                                        chart.chart_data,
                                                    ).reduce((carry, key) => {
                                                        const data =
                                                            chart.chart_data[
                                                                key
                                                            ];
                                                        return {
                                                            ...carry,
                                                            [key]: [
                                                                ...data,
                                                                {
                                                                    ...data[
                                                                        data.length -
                                                                            1
                                                                    ],
                                                                    x: data.length,
                                                                    y: 0,
                                                                },
                                                            ],
                                                        };
                                                    }, {}),
                                                });
                                            }}
                                        >
                                            <Plus className="h-6 w-6" />
                                            <span className="sr-only">
                                                Add Point
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            {Object.keys(chart.chart_data).map((key, index) => {
                                const data = chart.chart_data[key];
                                return (
                                    <tr
                                        className="odd:bg-slate-200 even:bg-slate-100"
                                        key={key}
                                    >
                                        <td>
                                            <div className="flex items-center space-x-2 pl-2">
                                                <button
                                                    onClick={() => {
                                                        handleChange({
                                                            ...chart,
                                                            chart_data:
                                                                Object.keys(
                                                                    chart.chart_data,
                                                                ).reduce(
                                                                    (
                                                                        carry,
                                                                        key,
                                                                    ) => {
                                                                        if (
                                                                            key ===
                                                                            Object.keys(
                                                                                chart.chart_data,
                                                                            )[
                                                                                index
                                                                            ]
                                                                        )
                                                                            return carry;
                                                                        return {
                                                                            ...carry,
                                                                            [key]: chart
                                                                                .chart_data[
                                                                                key
                                                                            ],
                                                                        };
                                                                    },
                                                                    {},
                                                                ),
                                                        });
                                                    }}
                                                    className="rounded-full p-2 text-red-500 transition-colors hover:bg-slate-50"
                                                >
                                                    <Trash className="h-4 w-4" />
                                                </button>
                                                <div className="flex h-7 w-7 items-center justify-center overflow-hidden rounded-full">
                                                    <ColorSelector
                                                        className="data-[isdark=false]:bg-slate-900 data-[isdark=true]:bg-slate-100"
                                                        size="h-4 w-4"
                                                        selectedColor={
                                                            data[0]?.theme
                                                                .stroke
                                                        }
                                                        selectColor={(
                                                            stroke,
                                                        ) => {
                                                            handleChange({
                                                                ...chart,
                                                                chart_data: {
                                                                    ...chart.chart_data,
                                                                    [key]: data.map(
                                                                        (
                                                                            datum,
                                                                        ) => ({
                                                                            ...datum,
                                                                            theme: {
                                                                                ...datum.theme,
                                                                                stroke,
                                                                            },
                                                                        }),
                                                                    ),
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="min-w-32">
                                            <input
                                                className="block w-full border-0 border-none bg-transparent py-1.5 text-gray-900 ring-0
                                                    placeholder:text-gray-400 focus:border-none focus:bg-white focus:ring-0
                                                    sm:text-sm sm:leading-6"
                                                placeholder="Label"
                                                defaultValue={data?.[0]?.label}
                                                onChange={(e) => {
                                                    handleChange({
                                                        ...chart,
                                                        chart_data: {
                                                            ...chart.chart_data,
                                                            [key]: data.map(
                                                                (datum) => ({
                                                                    ...datum,
                                                                    label: e
                                                                        .target
                                                                        .value,
                                                                }),
                                                            ),
                                                        },
                                                    });
                                                }}
                                            />
                                        </td>
                                        {data.map((datum, index) => {
                                            return (
                                                <td>
                                                    <div className="group/delete-point relative flex items-center space-x-1 hover:bg-white">
                                                        <input
                                                            type="number"
                                                            className="block w-20 border-0 border-none bg-transparent py-1.5 text-gray-900 ring-0
                                                                [appearance:textfield] placeholder:text-gray-400 focus:border-none
                                                                focus:bg-white focus:ring-0 sm:text-sm sm:leading-6
                                                                [&::-webkit-inner-spin-button]:appearance-none
                                                                [&::-webkit-outer-spin-button]:appearance-none"
                                                            defaultValue={
                                                                datum?.y
                                                            }
                                                            onChange={(e) => {
                                                                const newChart =
                                                                    {
                                                                        ...chart,
                                                                    };
                                                                const existingData =
                                                                    newChart
                                                                        .chart_data[
                                                                        key
                                                                    ][
                                                                        index
                                                                    ] || {
                                                                        x: index,

                                                                        label: `Category ${index + 1}`,
                                                                        theme: {
                                                                            stroke: "#000000",
                                                                        },
                                                                    };
                                                                newChart.chart_data[
                                                                    key
                                                                ][index] = {
                                                                    ...existingData,
                                                                    y: Number(
                                                                        e.target
                                                                            .value,
                                                                    ),
                                                                };
                                                                handleChange(
                                                                    newChart,
                                                                );
                                                            }}
                                                        />
                                                        {!!datum && (
                                                            <button
                                                                className="-tranlsate-y-1/2 right-0 top-1/2 rounded-full p-1 text-red-500 opacity-0
                                                                    transition-all group-hover/delete-point:opacity-100 hover:bg-slate-100
                                                                    hover:shadow"
                                                                onClick={() => {
                                                                    const newChart =
                                                                        {
                                                                            ...chart,
                                                                        };
                                                                    const existingRow =
                                                                        newChart
                                                                            .chart_data[
                                                                            key
                                                                        ];
                                                                    newChart.chart_data[
                                                                        key
                                                                    ] =
                                                                        existingRow.filter(
                                                                            (
                                                                                _,
                                                                                i,
                                                                            ) =>
                                                                                i !==
                                                                                index,
                                                                        );

                                                                    handleChange(
                                                                        newChart,
                                                                    );
                                                                }}
                                                            >
                                                                <Trash className="h-4 w-4" />
                                                                <span className="sr-only">
                                                                    Delete Point
                                                                </span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        })}

                                        <td>
                                            {data.length <
                                                lengthOfLongestData && (
                                                <div className="group/delete-point relative flex items-center space-x-1 hover:bg-white">
                                                    <input
                                                        type="number"
                                                        className="block w-20 border-0 border-none bg-transparent py-1.5 text-gray-900 ring-0
                                                            [appearance:textfield] placeholder:text-gray-400 focus:border-none
                                                            focus:bg-white focus:ring-0 sm:text-sm sm:leading-6
                                                            [&::-webkit-inner-spin-button]:appearance-none
                                                            [&::-webkit-outer-spin-button]:appearance-none"
                                                        onChange={(e) => {
                                                            const newChart = {
                                                                ...chart,
                                                            };
                                                            newChart.chart_data[
                                                                key
                                                            ] = [
                                                                ...data,
                                                                {
                                                                    x: index,
                                                                    y: Number(
                                                                        e.target
                                                                            .value,
                                                                    ),
                                                                    label: `Category ${index + 1}`,
                                                                    theme: {
                                                                        stroke: "#000000",
                                                                    },
                                                                },
                                                            ];
                                                            handleChange(
                                                                newChart,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            <div className="mt-8">
                <button
                    className="rounded-md p-1 transition-colors hover:bg-slate-200"
                    onClick={() => {
                        const firstKey = Object.keys(
                            (chart as ChartContentBlockWithObjectData)
                                .chart_data,
                        )[0];
                        const nextKeyNumber =
                            Object.keys(
                                (chart as ChartContentBlockWithObjectData)
                                    .chart_data,
                            )
                                .map((key) => key.match(/^category_(\d+)$/)) // Step 2: Extract the `n` value
                                .filter((match) => match !== null) // Step 3: Filter out non-matches
                                .map((match) => parseInt(match![1], 10)) // Convert `n` to a number
                                .reduce((max, n) => Math.max(max, n), 0) + 1;

                        const nextKey = `category_${nextKeyNumber}`;
                        handleChange({
                            ...chart,
                            chart_data: {
                                ...chart.chart_data,
                                [nextKey]: (
                                    chart as ChartContentBlockWithObjectData
                                ).chart_data[firstKey].map((datum) => ({
                                    ...datum,
                                    y: datum.y + 1,
                                    label: `Category ${nextKeyNumber}`,
                                })),
                            },
                        } as ChartContentBlockWithObjectData);
                    }}
                >
                    <Plus className="h-6 w-6" />
                    <span className="sr-only">Add Category</span>
                </button>
            </div>
        </div>
    );
}
