import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { VariableValueWithLabel } from "../useDefaultVariableValues";
import { ChartSeries } from "./ChartWizardReducer";

export function variableValuesToChartSeries(
    values: { [index: string]: VariableValueWithLabel[] },
    series: ChartSeries[],
    editedItem: ChartSeries | undefined
) {
    return {
        values: Object.keys(values).reduce((carry, variableId, i) => {
            const variableValues = values[variableId];
            const existingSeries =
                series.find(
                    (series) => series.modelVariableId === variableId
                ) || editedItem;
            return {
                ...carry,
                [variableId]: variableValues.map((variableValue, i) => {
                    return {
                        x: variableValue.time_index || i,
                        y: variableValue.numerical_value,
                        label: variableValue.label,
                        theme: existingSeries?.theme || {},
                    } as FormattedChartValue;
                }),
            };
        }, {}),
    };
}

export function getPreviewChartValues(
    series: ChartSeries[],
    values: { [index: string]: FormattedChartValue[] },
    editedItem: ChartSeries | undefined,
    editedIndex: number
): { [index: string]: FormattedChartValue[] } {
    let chartValues: { [index: string]: FormattedChartValue[] };
    if (series?.length) {
        chartValues = series.reduce((carry, chartSeries, index) => {
            if (!editedItem || index !== editedIndex) {
                const seriesValues =
                    chartSeries?.chart_data ||
                    values[chartSeries?.modelVariableId];
                return {
                    ...carry,
                    [chartSeries?.id || index]: seriesValues?.length
                        ? seriesValues
                        : [],
                };
            } else {
                const editedItemValues =
                    editedItem.chart_data ||
                    values[editedItem.modelVariableId]?.map((value, i) => {
                        return {
                            ...value,
                            theme: editedItem.theme || {},
                        };
                    });
                return {
                    ...carry,
                    [index]: editedItemValues?.length ? editedItemValues : [],
                };
            }
        }, {});
    } else {
        chartValues = {
            [editedIndex.toString()]: values[editedItem?.modelVariableId],
        };
    }

    return Object.keys(chartValues).reduce((acc, key) => {
        const series = chartValues[key];
        if (
            !series?.length ||
            !series.some(
                (datum) =>
                    datum.x !== undefined &&
                    datum.y !== undefined &&
                    !!datum.label
            )
        ) {
            return acc;
        }

        return {
            ...acc,
            [key]: series.filter(
                (datum) =>
                    datum.x !== undefined &&
                    datum.y !== undefined &&
                    !!datum.label
            ),
        };
    }, {});
}
