import React, { useMemo } from "react";
import { Course } from "../types";
import { SapienParticipantPageProps } from "@/inertia-utils/types";
import AuthenticatedHeader from "../shared/AuthenticatedHeader";
import { Progress } from "../LessonDisplay/ProgressTracker";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = {
    course: Course;
    teamUserRound: {
        lesson_completions: {
            [index: string]: number;
        };
    };
} & SapienParticipantPageProps;

export default function Unpublished({ course, auth }: Props) {
    return (
        <div className="min-h-screen e-learning flex flex-col items-center">
            <AuthenticatedHeader auth={auth}>
                <div className="flex items-center">
                    <h1 className="text-lg font-semibold">{course.courseMetadata?.course_title || course.title}</h1>
                </div>
            </AuthenticatedHeader>
            <div className="flex h-full w-full flex-1 flex-col items-center bg-gray-100 px-4 py-6">
                <div className="w-full max-w-3xl rounded-md bg-white px-4 py-16 lg:max-w-4xl flex items-center justify-center">
                    <h3>{course.courseMetadata?.course_title || course.title} isn't available yet.</h3>
                </div>
            </div>
        </div>
    );
}
