import { ModelDataSelectionForm } from "@/components/admin-components";
import {
    useGotContentBlock,
    useModalQueryParams,
    useVariableValueStore,
} from "@/hooks";
import {
    useUpdateActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import {
    ContentBlockShape,
    ContentBlockType,
    ModelVariableDataType,
} from "@/models";
import { ModalContainer } from "@/modules/shared";
import React, { useEffect, useMemo, useState } from "react";
import { useGetContentBlockById } from "../modals/shared-queries/useGetContentBlockById";
import { queryClient } from "../QueryClient";
import { saveContentBlockModelDataSources } from "./saveContentBlockModelDataSources";
import { useMutation } from "@tanstack/react-query";

function getTimeHorizonIds(contentBlock: ContentBlockShape) {
    return (
        contentBlock?.contentBlockModelDataSources
            ?.map((modelDataSource) =>
                modelDataSource.timeHorizons.map(
                    (timeHorizon) => timeHorizon.id
                )
            )
            .flat() || []
    );
}

function ModelDataSourceModalContents({
    modelDataContentBlock,
}: {
    modelDataContentBlock: ContentBlockShape;
}) {
    const { gotVariableValues } = useVariableValueStore();
    const { queryParams, closeModal } = useModalQueryParams(
        "ModelDataSourceModal"
    );

    const { timeHorizons } = useTimeHorizonStore();
    const { modelVariables } = useModelVariableStore();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const { gotContentBlock } = useGotContentBlock();
    const [modelVariableIds, setModelVariableIds] = useState<string[]>(
        modelDataContentBlock?.contentBlockModelDataSources?.map(
            (modelDataSource) => modelDataSource.model_variable_id
        ) || []
    );
    const [timeHorizonIds, setTimeHorizonIds] = useState<string[]>(
        getTimeHorizonIds(modelDataContentBlock)
    );

    useEffect(() => {
        setModelVariableIds(
            modelDataContentBlock?.contentBlockModelDataSources?.map(
                (modelDataSource) => modelDataSource.model_variable_id
            ) || []
        );
        setTimeHorizonIds(getTimeHorizonIds(modelDataContentBlock));
    }, [modelDataContentBlock?.id, modelDataContentBlock?.updated_at]);

    const { mutate } = useMutation({
        mutationFn: saveContentBlockModelDataSources,
        onSuccess: ({ contentBlock, variableValues }) => {
            gotVariableValues(variableValues);
            updateActiveContentBlock(contentBlock);
            gotContentBlock(contentBlock);
            queryClient.invalidateQueries({
                queryKey: ["contentBlockById"],
            });
            closeModal();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    async function submit() {
        mutate({
            contentBlockId: queryParams.contentBlockId,
            modelVariableIds,
            timeHorizonIds,
        });
    }

    return (
        <form
            data-testid="model-data-source-modal"
            className="flex flex-col py-4 px-6 space-y-4 text-white"
            onSubmit={(e) => {
                e.preventDefault();
                submit();
            }}
        >
            <p className="text-xl">Select Model Variable</p>
            <div className="flex flex-col border-b border-t border-gray-300 w-full">
                <ModelDataSelectionForm
                    modelVariables={Object.values(modelVariables).filter(
                        (modelVariable) =>
                            modelVariable.data_type ===
                            ModelVariableDataType.Number
                    )}
                    timeHorizons={timeHorizons}
                    selectedModelVariables={Object.values(
                        modelVariables
                    ).filter((modelVariable) =>
                        modelVariableIds.includes(modelVariable.id)
                    )}
                    setSelectedModelVariables={(modelVariables) => {
                        setModelVariableIds(() =>
                            modelVariables.map(
                                (modelVariable) => modelVariable.id
                            )
                        );
                    }}
                    selectedTimeHorizons={timeHorizons.filter((timeHorizon) =>
                        timeHorizonIds.includes(timeHorizon.id)
                    )}
                    setSelectedTimeHorizons={(timeHorizons) => {
                        setTimeHorizonIds(() =>
                            timeHorizons.map((timeHorizon) => timeHorizon.id)
                        );
                    }}
                    isSingleModelVariableSelection={true}
                    isSingleTimeHorizonSelection={
                        modelDataContentBlock.content_block_type ===
                        ContentBlockType["Model Value Display"]
                    }
                />
            </div>
            <div className="flex justify-end space-x-2">
                <button
                    data-testid="save-model-data-source"
                    className="inline-flex items-center  bg-blue-600 px-4 py-2 rounded text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 2bluefocus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Save
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                        queryClient.invalidateQueries({
                            queryKey: ["contentBlockById"],
                        });
                    }}
                    className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default function ModelDataSourceModal() {
    const { isAtModalUrl, queryParams } = useModalQueryParams(
        "ModelDataSourceModal"
    );

    const { data, isFetching } = useGetContentBlockById(
        queryParams.contentBlockId
    );

    const contentBlock = useMemo(() => {
        return data;
    }, [data?.id, data?.updated_at]);

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            size="l"
            backgroundColor="#111928"
        >
            {!!isAtModalUrl && (
                <>
                    {!!(isFetching && !data) ? (
                        <div className="flex flex-col gap-4 animate-pulse p-6">
                            <div className="rounded-md w-full bg-gray-100 h-8"></div>
                            <div className="rounded-md w-full bg-gray-100 h-8"></div>
                            <div className="flex justify-end gap-2">
                                <div className="rounded-md w-16 bg-gray-100 h-8"></div>
                                <div className="rounded-md w-16 bg-gray-100 h-8"></div>
                            </div>
                        </div>
                    ) : (
                        <ModelDataSourceModalContents
                            modelDataContentBlock={contentBlock}
                        />
                    )}
                </>
            )}
        </ModalContainer>
    );
}
